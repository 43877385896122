import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Row, Col, Typography, Popconfirm, Card, Modal, Divider, Input } from 'antd'
import { CloseCircleOutlined, PlusOutlined, QuestionCircleOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons"
import { ACISAPIURL, MEDIAMAXWIDTH } from '../Common/SystemParameter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import TextArea from 'antd/lib/input/TextArea'
import { useMediaQuery } from 'react-responsive'

const { Title } = Typography
const { confirm } = Modal

const StockCountTaggedFishSurplusTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? 350 : 450)
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    // Readonly means viewing a completed stock count. No changes is allowed.
    const [readOnly, setReadOnly] = useState(urlParams.get("completedOn") == "" ? false : true)
    const [hide, setHide] = useState(urlParams.get("completedOn") == "" ? "" : "none")

    let surplusFishPKey = urlParams.getAll("fishPKey")
    let surplusFish = urlParams.getAll("fish")
    let surplusRemark = urlParams.getAll("remark")
        
    // Build surplus initial values.
    let prevSavedSurplus = []
    for(let i = 0; i < surplusFishPKey.length; i++) {
        prevSavedSurplus.push({
            fishPKey: surplusFishPKey[i],
            fish: surplusFish[i],
            remark: surplusRemark[i],
        })
    }

    //--------------------------------
    // Go to untagged fish stock count
    //--------------------------------
    const goToStockCountSummary = () => {
        navigate({ 
             pathname: "/stockcountsummary", 
             search: `?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}`
        }) 
    }

    //---------------------------------------
    // Create tagged fish variance adjustment
    //---------------------------------------
    const saveTaggedFishSurplus = (variances) => {
        // Disable button.
        setDisableButton("disabled")

        // Sanitize fish ID
        variances.forEach( variance => {
            variance.fish = String(variance.fish).toUpperCase()
        })

        axios.post(`${ACISAPIURL}stockcount/taggedfish/surplus/create/`, {
            stock_count: urlParams.get("pKey"),
            fish: variances
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Tagged fish stock count surplus record saved.")
            goToStockCountSummary()
        })
        .catch( error => {
            reportError(error, `Failed to save tagged marine life stock count surplus record.`)
        })
        .finally(() => {
            setDisableButton("")
            refreshUserSession()
        })
    }

    //-----------------------------------------------------
    // Delete tagged fish stock count surplus
    //-----------------------------------------------------
    const noSurplusTaggedFishStockCount = () => {
        // Disable button.
        setDisableButton("disabled")
        
        axios.patch(`${ACISAPIURL}stockcount/taggedfish/nosurplus/${urlParams.get("pKey")}/`, {}, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            goToStockCountSummary()
        })
        .catch( error => {
            reportError(error, "Failed to save no surplus tagged marine life stock count record.")
        })
        .finally(() => {
            setDisableButton("")
            refreshUserSession()
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({
            pathname: "/stockcounttaggedfish",
            search: `?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}`
        })
    }

    //-----------------------
    // On finish
    //-----------------------
    const onFinish = values => {
        if(readOnly) {
            goToStockCountSummary()
            return
        }

        if(values != undefined && values?.variances?.length > 0)
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Save tagged marine life stock count with surplus record and proceed to summary?</p></Space>,
                onOk() { saveTaggedFishSurplus(values.variances) },
                onCancel() {},
                centered: true
            })
        else
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Save tagged marine life stock count without surplus record and proceed to summary?</p></Space>,
                onOk() { noSurplusTaggedFishStockCount() },
                onCancel() {},
                centered: true
            })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        
    }, [])

    return(
        <>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Stock Count Storage ID: ${urlParams.get("storage")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Initialised On:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
            
                    { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                        </>
                    }
                    { urlParams.get("completedOn") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Completed On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("completedOn")}</Card.Grid>
                        </>
                    }
                </Card>
            </Form.Item>
        </Form>

        <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" >
            <Row justify="center">
                <Col span={8} style={{textAlign: "center"}}>
                    <Card title={<>Tagged Fish Stock Count <span style={{color: "blue"}}>{OTHERSYSPARAM("SURPLUS")}</span></>} style={{textAlign: "left"}}>
                        Enter surplus fish ID and remark.
                    </Card>
                </Col>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>
            
            <Row justify="center">
                <Col span={isTabletOrMobile ? 13 : 8}>
                    <Form.List name="variances" initialValue={prevSavedSurplus}>
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(field => (
                                <Space key={field.key} align="baseline" direction="vertical" /*wrap style={{borderBottomStyle: "dashed", borderBottomColor: "gray", borderBottomWidth: "1px", margin: 5}}*/>
                                    <span>
                                        <Divider orientation="right">
                                            <div><CloseCircleOutlined onClick={() => remove(field.name)} style={{display: hide}}/></div>
                                        </Divider>
                                    
                                        <Form.Item hidden={true}
                                            {...field}
                                            label="Fish PKey"
                                            name={[field.name, 'fishPKey']}
                                            fieldKey={[field.fieldKey, 'fishPKey']}>
                                            {/* rules={[{ required: true, message: 'Variance quantity is required.' }]}> */}
                                            <Input style={{width: optionWidth}}/>
                                        </Form.Item>

                                        <Form.Item
                                            {...field}
                                            label="Tag ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                            name={[field.name, 'fish']}
                                            fieldKey={[field.fieldKey, 'fish']}
                                            rules={[{ required: true, message: 'Variance quantity is required.' }]}>
                                            <Input maxLength={50} style={{width: optionWidth}} readOnly={readOnly} />
                                        </Form.Item>
                                                                        
                                        <Form.Item
                                            {...field}
                                            label="Remark&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                            name={[field.name, 'remark']}
                                            fieldKey={[field.fieldKey, 'remark']}
                                            rules={[{ required: true, message: 'Remark is required.' }]}>
                                            <TextArea rows={1} maxLength={500} style={{width: optionWidth}} readOnly={readOnly}/>
                                        </Form.Item>
                                        
                                    </span>
                                </Space>
                            ))}

                            <Row justify="center">
                                <Col span={24} style={{textAlign: "center"}}>
                                    <Form.Item>
                                        <Button type="dashed" block icon={<PlusOutlined />} style={{width: 300, display: hide}}
                                            onClick={() => { add() }} >
                                            Add surplus
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                            </> 
                        )}
                    </Form.List>
                </Col> 
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={10} style={{textAlign: "start"}}>
                    <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={4} />
                <Col span={10} style={{textAlign: "end"}}>
                    <Button type="primary" htmlType="submit" disabled={disableButton} icon={<RightOutlined />}>Next</Button>
                </Col>
            </Row>
        </Form>
        </>
    )
}

export default StockCountTaggedFishSurplusTable
