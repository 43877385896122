import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, message, Space, Layout, Row, Col, Popconfirm, PageHeader, Modal, Descriptions, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { LOADING, MENUPATH_LOCATION } from '../Common/SystemParameter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { ACISAPIURL } from '../Common/SystemParameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal

//----------
// Component
//----------
const LocationUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
        
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [hideDeleteButton, setHideDeleteButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
                        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------
    // Update location
    //---------------
    const updateLocation = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}location/update/${urlParams.get("pKey")}/`, {
                name: values.locationName,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Location ${urlParams.get("name")} updated.`)
                navigate({ pathname: MENUPATH_LOCATION })
            })
            .catch( error => {
                reportError(error, "Failed to update location.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //---------------
    // Delete location
    //---------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}location/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Location ${urlParams.get("name")} deleted.`)
            navigate({ pathname: MENUPATH_LOCATION })
        })
        .catch( error => {
            reportError(error, "Failed to delete location.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------------------------------------
    // Determine if storage has transaction and batch assigned
    //--------------------------------------------------------
    const toHideDeleteButton = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}locationhasbatchorstorage/${urlParams.get("pKey")}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.has_batch_or_storage)
                setHideDeleteButton(true)
        })
        .catch( error => {
            reportError(error, "Failed to determine if location has batch or storage.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------
    // Cancel update storage
    //--------------------
    const onCancel = () => {
        navigate({ pathname: MENUPATH_LOCATION })
    }

    //--------------------
    // On update
    //--------------------
    const onUpdate = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update location confirmed?</p></Space>,
            onOk() { updateLocation() },
            onCancel() {},
            centered: true
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        toHideDeleteButton()

        form.setFieldsValue({
            locationName: urlParams.get("name"),
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Location">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update location</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} {...formLayout}>
                    <Form.Item name="locationName" label="Location Name"
                        rules={[
                            { required: true, message: "Locaion name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Location Name"/>
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete location confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} hidden={hideDeleteButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} disabled={disableButton} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Location:"
                        subTitle="Update location"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default LocationUpdate