import  { useEffect, useState } from 'react'
import { Button, Col, Row, Space, Table, message, Upload, Popconfirm, Pagination, Collapse, Form, DatePicker, Spin } from "antd"
import { PlayCircleOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons"
import axios from 'axios'
import { ACISAPIURL, DATEFORMAT, DATETIMEFORMAT, LOADING, MENUPATH_STOCKCOUNT, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT } from '../Common/SystemParameter'
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { numberWithCommas, reportError, stringNumberSorter } from '../Common/Utility'
import moment from 'moment'
import { formLayout_2Columns } from '../Common/Layout'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'
import TrueFalseSelect from '../Common/TrueFalseSelect'
import { useNavigate } from 'react-router-dom'

const { Panel } = Collapse
const { RangePicker } = DatePicker

const StockCountVideoAutoStitchTable = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [dateRange, setDateRange] = useState([moment(moment().subtract(1, "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [inferenced, setInferenced] = useState("")
        
    const [disableButton, setDisableButton] = useState("")
    const [uploading, setUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false) 
    const [stockCountVideoDataSource, setStockCountVideoDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const [uploadStorageId, setUploadStorageId] = useState(0)
    const [uploadStorage, setUploadStorage] = useState("")
    const [uploadBatchId, setUploadBatchId] = useState(0)
    const [uploadBatch, setUploadBatch] = useState("")

    const [videoFiles, setVideoFiles] = useState([])
            
    //--------------------------
    // Upload video
    //--------------------------
    const uploadVideo = () => {
        // Validation.
        if(videoFiles.length == 0) {
            message.warn("Please select video file(s) before proceed.")
            return false
        }

        if(uploadBatchId == 0) {
            message.warn("Please select batch ID before proceed.")
            return false
        }

        if(uploadStorageId == 0) {
            message.warn("Please select storage name before proceed.")
            return false
        }

        setDisableButton("disabled")
        setUploading(true)
        setIsLoading(true)
        
        // Check for duplicated filenames.
        let allVideoFilenames = []
        videoFiles.forEach( file => { allVideoFilenames.push(file.name) })

        if(new Set(allVideoFilenames).size !== videoFiles.length) {
            message.warn("Duplicated video files detected.")
            return false
        }
        
        // Create temporary folder to store video files.
        const sessionId = `${getUserSiteId()}${moment().format("YYMMDDhhmmssSSS")}`
        
        axios.post(`${ACISAPIURL}stockcountvideoautostitch/newuploadsession/${sessionId}/`, {
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: { "Authorization": `Token ${getUserAuthToken()}`, }
        })
        .then( async response => {
            let counter = 0

            // Upload video files one by one. Notify if is last.
            //videoFiles.forEach( async videoFile => {
            for(const videoFile of videoFiles) {
                counter += 1
                let isLast = 0
                if(counter == videoFiles.length) isLast = 1

                let data = new FormData()
                data.append("video_file", videoFile)
                data.append("batch", uploadBatchId)
                data.append("storage", uploadStorageId)
                data.append("is_last", isLast)
                        
                await fetch(`${ACISAPIURL}stockcountvideoautostitch/upload/${sessionId}/`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Token ${getUserAuthToken()}`,
                    },
                    body: data,
                    timeout: 0
                })
                .then( response => {
                    if(response.status != 200) throw("")
                    
                    message.info(`${videoFile.name} uploaded.`)
                    
                    if(isLast) {
                        setCurrentPage(1)
                        searchStockCountVideo(1)
                        setVideoFiles([])
                        message.info(`All video(s) uploaded.`)
                    }
                })
                .catch( error => {
                    reportError(error, `Failed to upload video.`)
                })
                .finally(() => {
                })
            }
        })
        .catch( error => {
            reportError(error, `Failed to upload video.`)
        })
        .finally(() => {
            setDisableButton("")
            setUploading(false)
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Download video
    //--------------------------
    const playVideo = (e, videoUrl) => {
        e.stopPropagation()
        
        setTimeout(() => {
            const response = {
                file: videoUrl,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file)
            // you could also do:
            // window.location.href = response.file;
          }, 100)
    }
    
    //--------------------------
    // Delete environment record
    //--------------------------
    const deleteVideo = (e, record) => {
        setIsLoading(true)

        axios.delete(`${ACISAPIURL}stockcountvideo/delete/${record.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Video deleted.`)
            setStockCountVideoDataSource(stockCountVideoDataSource.filter((item) => item.key !== record.key))
        })
        .catch( error => {
            reportError(error, "Failed to delete video.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------------
    // Get stock count video
    //-------------------------
    const searchStockCountVideo = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}stockcountvideo/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                inferenced: inferenced,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            pushData(response)
        })
        .catch( error => {
            reportError(error, "Failed to search stock count video record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const pushData = (response) => {
        const data = []
        response.data.results.forEach( video => {
            data.push({
                key: video.pKey,
                batch: video.batch_data.id,
                batchId: video.batch,
                storage: video.storage_data.name,
                storagId: video.storage,
                videoUrl: video.video,
                inferencedVideoUrl: video?.inferenced_video,
                countResult: numberWithCommas(video.count_result),
                createdOn: moment(video.createdOn).format(DATETIMEFORMAT),
                updatedOn: video.createdOn.substring(0, 19) != video.updatedOn.substring(0, 19) ? moment(video.updatedOn).format(DATETIMEFORMAT) : "",
                stockCountedOn: video.stockcountedOn != null ? moment(video.stockcountedOn).format(DATETIMEFORMAT) : "",
            })
        })
        
        setStockCountVideoDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStockCountVideo(1)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStockCountVideo(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------------
    // On change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    const onBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onSearchInferencedChange = (e, value) => {
        setInferenced(e)
        //setInferencedLabel(value.children)
    }

    const onUploadBatchChange = (e, value) => {
        setUploadBatchId(e)
        setUploadBatch(value.children)
    }

    const onUploadStorageChange = (e, value) => {
        setUploadStorageId(e)
        setUploadStorage(value.children)
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                /*navigate({ 
                    pathname: "/standardgrowth", 
                    search: `?pKey=${record.key}`
                })*/ 
            }
        }
    }

    //-----------------------
    // Go to stock count page
    //-----------------------
    const goToStockCount = () => {
        navigate({ 
            pathname: MENUPATH_STOCKCOUNT
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchStockCountVideo(currentPage)
    }, [])

    //-----------------------------
    // Upload video file validation
    //-----------------------------
    const props = {
        multiple: true,
        beforeUpload: (file, fileList) => {
            setVideoFiles(videoFiles.concat(fileList))
            return false
        },
        /*onChange: info => {
            setVideoFilenames(info.fileList)
        },*/
        onRemove: file => {
            setVideoFiles(videoFiles.filter( existingFile => existingFile.uid != file.uid))
        },
        videoFiles
    }

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage ID', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Uploaded On', dataIndex: 'createdOn', key: 'createdOn', sorter: (a, b) => a.createdOn.localeCompare(b.createdOn) },
        { title: 'Inference Count On', dataIndex: 'updatedOn', key: 'updatedOn', sorter: (a, b) => a.updatedOn.localeCompare(b.updatedOn) },
        { title: 'Count Result (Tail)', dataIndex: 'countResult', key: 'countResult', align: "right", sorter: (a, b) => stringNumberSorter(a.countResult, b.countResult) },
        { title: 'Stock Count Created On', dataIndex: 'stockCountedOn', key: 'stockCountedOn', sorter: (a, b) => a.stockCountedOn.localeCompare(b.stockCountedOn) },
        { key: 'action', align: 'center',
            render: (record) => {
                return <>
                    <Button type="primary" disabled={disableButton} loading={isLoading} icon={<PlayCircleOutlined/>} onClick={e => playVideo(e, record.videoUrl)} />
                    { (record.inferencedVideoUrl != null && record.inferencedVideoUrl.length > 0) && 
                        <Button style={{backgroundColor: "green"}} type="primary" disabled={disableButton} loading={isLoading} icon={<PlayCircleOutlined/>} onClick={e => playVideo(e, record.inferencedVideoUrl)} />
                    }
                    <Popconfirm title="Delete stock count video confirmed?" 
                        onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => deleteVideo(e, record)} 
                        okText="Yes" cancelText="No">
                        <Button danger type="primary" disabled={disableButton} icon={<DeleteOutlined/>} />
                    </Popconfirm>
                </>
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Batch - Storage Stock Count Video To Be Uploaded" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <CommonSearchFormItem onBatchChange={onUploadBatchChange} onStorageChange={onUploadStorageChange} />
                        </Form>

                        <Row justify="center">
                            <Col span={17}></Col>
                            <Col span={6} style={{textAlign: "right"}}>
                                <Upload {...props} accept="video/mp4" fileList={videoFiles}>
                                    <Button type="primary" htmlType="button" loading={uploading}>Select Video Files</Button>
                                </Upload>
                            </Col>
                            <Col span={1} style={{textAlign: "right"}}>
                                <Button type="primary" htmlType="button" onClick={uploadVideo} icons={<UploadOutlined />} loading={uploading}>Upload</Button>
                            </Col>
                        </Row>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Stock Count Video Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onBatchChange={onBatchChange} onStorageChange={onStorageChange} showLockedStorage={true} defaultStorageId={storageId}/>

                            <Form.Item name="inferenced" label="Inferenced">
                                <TrueFalseSelect withBlank={true} onChange={onSearchInferencedChange}/>
                            </Form.Item>
                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={stockCountVideoDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        {/*<Row justify="center">
            <Col span={18} />
            <Col span={6} style={{textAlign: "end"}}>
                <Upload {...props} accept="video/mp4">
                    <Button type="primary" htmlType="button" icon={<UploadOutlined />} loading={uploading}>
                        <Tooltip title="Upload new stock count video.">
                            Upload stock count video
                        </Tooltip>
                    </Button>
                </Upload>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        */}
        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>

        <Row justify="center">
            <Col span={20} />
            <Col span={4} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" disabled={disableButton} onClick={goToStockCount}>Stock Count</Button>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default StockCountVideoAutoStitchTable