import  { useEffect, useState } from 'react'
import { Modal, message, Row, Col, Input } from 'antd'
import axios from "axios"
import { ACISAPIURL, UNTAGGED } from "../Common/SystemParameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { reportError } from "../Common/Utility"


const SearchStockTransactionByID = ({visible, toClose, navigationCallBack}) => {
    const [id, setId] = useState(0)

    const getTransaction = () => {
        return new Promise((resolve, reject) => {
            axios.get(`${ACISAPIURL}stocktransactionbyid/`, {
                params: {
                    pKey: id,
                    item_type_name: UNTAGGED
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                resolve(response.data)
            })
            .catch( error => {
                reject(error)
            })
            .finally(() => {
                refreshUserSession()
            })
        })
    }

    const valid = () => {
        if(isNaN(parseInt(id)) || parseInt(id) <= 0) {
            message.warning('Invalid stock transaction ID.')
            return false
        }

        return true
    }

    const search = async () => {
        if(!valid()) return
        
        getTransaction()
        .then( transaction => {
            if(transaction != '') 
                navigationCallBack(transaction)
            else {
                message.warning(`Stock transaction of ID ${id} cannot be found or its item type is not of untagged type.`)
                return
            }
        })
        .catch( error => {
            reportError(error, "Failed to search stock transaction.")
        })
    }

    const onIdChange = (e) => {
        setId(e.target.value)
    }

    const onClose = () => {
        toClose()
    }

    useEffect(() => {
    }, [visible])

    //-----------
    // Main modal
    //-----------
    return(
        <Modal
            visible={visible}
            title={<div><h3>Search Untagged Stock Transaction</h3></div>}
            icon={null}
            width={800}
            centered={true}
            closable={false}
                        
            onOk={search}
            okText={"Search"}
            onCancel={onClose}
            cancelText={"Cancel"}>
            <Row>
                <Col span={24}>
                    <Row justify='left'>
                        <Col span={4} style={{ textAlign: 'left'}}>Record ID:</Col>
                        <Col span={20} style={{ textAlign: 'left'}}>
                            <Input onChange={onIdChange} placeholder="Record ID"/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    )
}

export default SearchStockTransactionByID

