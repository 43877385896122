import { useEffect } from 'react'
import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import GrowthDevelopmentDailyAverageByBatchTable from './GrowthDevelopmentDailyAverageByBatchTable'
import { OTHERSYSPARAM } from '../Common/UserSession'
import { walltiles } from '../Common/Layout'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const GrowthDevelopmentDailyAverageByBatch = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{ minHeight: contentHeight, ...walltiles }}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader
                    onBack={null}
                    title="Growth Development">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Manage growth development by batch</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><GrowthDevelopmentDailyAverageByBatchTable /></Col>
                    <Col span={1}></Col>
                </Row>

            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader
                        onBack={null}
                        title="Growth Development:"
                        subTitle="Manage growth development by batch" />} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default GrowthDevelopmentDailyAverageByBatch