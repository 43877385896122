import { Table, Row, Col, Space, Button, message, Modal, Typography, Pagination, Spin, Tag, Collapse, Form, DatePicker } from 'antd'
import { QuestionCircleOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MEDIAMAXWIDTH, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, DATEFORMAT } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, userIsPendingTransactionApprover, SYSPARAM } from "../Common/UserSession"
import { numberWithCommas, reportError } from '../Common/Utility'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'
import { formLayout_2Columns } from "../Common/Layout"
import CommonSearchFormItem from '../Common/CommonSearchFormItem'
//import TransactionTypeSelect from '../Common/TransactionTypeSelect'
import BatchTypeSelect from '../Common/BatchTypeSelect'

const { confirm } = Modal
const { Title } = Typography
const { RangePicker } = DatePicker
const { Panel } = Collapse

//----------
// Component
//----------
const PendingStockTransactionTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)

    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [fishId, setFishId] = useState(0)
    const [fish, setFish] = useState("")
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH") / OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    // const [transactionTypeId, setTransactionTypeId] = useState(0)
    // const [transactionType, setTransactionType] = useState("")
    const [itemTypeId, setItemTypeId] = useState(0)
    const [itemType, setItemType] = useState("")
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [batchTypeId, setBatchTypeId] = useState(0)
    const [batchType, setBatchType] = useState("")

    const [stockTransactionDataSource, setStockTransactionDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    //-----------------------------------------------------
    // Approve, reject or delete pending stock transaction.
    //-----------------------------------------------------
    const updatePendingStockTransaction = (transactionPKey, action) => {
        // Disable button.
        setIsLoading(true)

        let strAction1 = ""
        let strAction2 = ""
        
        if(action == "Approve") {
            strAction1 = "approved"
            strAction2 = "approve"
        }
        else if(action == "Reject") {
            strAction1 = "rejected"
            strAction2 = "reject"
        }
        else if(action == "Delete") {
            strAction1 = "deleted"
            strAction2 = "delete"
        }
        
        axios.patch(`${ACISAPIURL}stocktransaction/pending/update/${transactionPKey}/`, {
            action: action
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Pending stock transaction ${strAction1} successfully.`)
            
            //releaseStorage(urlParams.get("batchId"), urlParams.get("batch"), urlParams.get("storageId"))

            window.location.reload()
        })
        .catch( error => {
            reportError(error, `Failed to ${strAction2} pending stock transaction.`)
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //----------------------------------
    // Search pending stock transaction.
    //----------------------------------
    const searchPendingStocktransaction = (currentPage) => {
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}stocktransaction/pending/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                //transaction_type: transactionTypeId,
                item_type: itemTypeId,
                fish: fishId,
                batch_type: batchTypeId,
                marine_life: marineLifeId,
                species: speciesId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search pending stock transaction.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( transaction => {
            // let stockBalance = 0
            // if(transaction.item_type_data.name.startsWith(EGGS))
            //     stockBalance = transaction.egg_balance_quantity
            // else if(transaction.item_type_data.name.startsWith(UNTAGGED))
            //     stockBalance = transaction.untagged_balance_quantity
            // else if(transaction.item_type_data.name.startsWith(TAGGED))
            //     stockBalance = transaction.tagged_balance_quantity

            data.push({
                key: transaction.pKey,
                dateTime: moment(transaction.transactedOn).format(DATETIMEFORMAT),
                transactionType: transaction.transaction_type_data.name,
                batch: transaction.batch_data.id,
                batchType: transaction.batch_type_data.name,
                storage: transaction.storage_data.name,
                fish: transaction?.fish_data?.id == null ? "" : transaction.fish_data.id,
                itemType: transaction.item_type_data.name,
                qty: numberWithCommas(transaction.quantity),
                // stockBalance: numberWithCommas(stockBalance),
                uom: transaction.uom_data.uom,
                qty2: numberWithCommas((transaction.quantity2 / 1000).toFixed(2)),
                averagePerUnitWeight: numberWithCommas(transaction.average_per_unit_weight_gram),
                remark: transaction.remark,
                statusId: transaction.status,
                createdBy: `${transaction.created_by_data.first_name} ${transaction.created_by_data.last_name}`
            })
        })
        
        setStockTransactionDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchPendingStocktransaction(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //--------
    // On next
    //--------
    const performAction = (record, action) => {
        confirm({
            title: <Title level={4}>Record ID: {record.key}</Title>,
            icon: <QuestionCircleOutlined />,
            content: <Space><p>{`${action} pending stock transaction confirmed?`}</p></Space>,
            onOk() { updatePendingStockTransaction(record.key, action) },
            onCancel() {},
            centered: true
        })
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchPendingStocktransaction(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onSearchFishChange = (e, value) => {
        setFishId(e)
        setFish(value.children)
    }

    // const onTransactionTypeChange = (e, value) => {
    //     setTransactionTypeId(e)
    //     setTransactionType(value.children)
    // }

    const onItemTypeChange = (e, value) => {
        setItemTypeId(e)
        setItemType(value.children)
    }
    
    const onSearchBatchTypeChange = (e, value) => {
        setBatchTypeId(e)
        setBatchType(value.children)
    }
    
    const onSearchMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchPendingStocktransaction(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    let desktopColumn1 = [
        { title: 'Avg/Unit Weight (g)', dataIndex: 'averagePerUnitWeight', key: 'averagePerUnitWeight', align: "right", },//sorter: (a, b) => stringNumberSorter(a.averagePerUnitWeight, b.averagePerUnitWeight) }
    ]

    let desktopColumn2 = [
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', }, //sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
    ]

    if(isTabletOrMobile) {
        desktopColumn1 = []
        desktopColumn2 = []
    }

    const columns = [
        { title: 'Record ID', dataIndex: 'key', key: 'key', },
        { title: 'Transacted On', dataIndex: 'dateTime', key: 'dateTime', }, 
        { title: 'Trans. Type', dataIndex: 'transactionType', key: 'transactionType', },//sorter: (a, b) => a.transactionType.localeCompare(b.transactionType) },
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', },//sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', },//sorter: (a, b) => a.storage.localeCompare(b.storage) },
        ...desktopColumn2,
        { title: 'Tag ID', dataIndex: 'fish', key: 'fish', },//sorter: (a, b) => a.fish.localeCompare(b.fish) },
        { title: 'Item Type', dataIndex: 'itemType', key: 'itemType', },//sorter: (a, b) => a.itemType.localeCompare(b.itemType)},
        { title: 'Qty.', dataIndex: 'qty', key: 'qty', align: 'right'},//sorter: (a, b) => stringNumberSorter(a.qty, b.qty) },
        // { title: 'Balance Qty.', dataIndex: 'stockBalance', key: 'stockBalance', align: 'right'},//sorter: (a, b) => stringNumberSorter(a.qty, b.qty) },
        { title: 'UoM', dataIndex: 'uom', key: 'uom', },//sorter: (a, b) => a.uom.localeCompare(b.uom) },
        ...desktopColumn1,
        { title: 'Total Weight (Kg)', dataIndex: 'qty2', key: 'qty2', align: "right", },//sorter: (a, b) => stringNumberSorter(a.qty2, b.qty2) },
        { title: 'Created By', dataIndex: 'createdBy', key: 'createdBy', align: 'center', },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', align: 'center', },
        { title: 'Status', dataIndex: 'statusId', key: 'statusId', align: 'center', 
            render: (statusId, record) => {
                if(statusId == SYSPARAM("Approved"))
                    return <Tag color="green">Approved</Tag>
                else if(statusId == SYSPARAM("Pending"))
                    return <Tag color="Orange">Pending</Tag>
                else if(statusId == SYSPARAM("Rejected"))
                    return <Tag color="Red">Rejected</Tag>
            }
        },
        { title: 'Action', key: 'action', align: 'center',
            render: (record) => {
                if(record.statusId == SYSPARAM("Pending")) {
                    if(userIsPendingTransactionApprover() == '1')
                        return <>
                            <Button type="primary" htmlType="button" style={{marginRight: "5px"}} onClick={(e) => performAction(record, "Approve")}>Approve</Button>
                            <Button type="primary" htmlType="button" style={{marginRight: "5px"}} danger onClick={(e) => performAction(record, "Reject")}>Reject</Button>
                            <Button type="primary" htmlType="button" style={{marginRight: "5px"}} danger onClick={(e) => performAction(record, "Delete")}>Delete</Button>
                            </>
                    else {
                        
                            return <>
                                <Button type="primary" htmlType="button" style={{marginRight: "5px"}} danger onClick={(e) => performAction(record, "Delete")}>Delete</Button>
                                </>
                    }
                }
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Stock Transaction" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onSearchMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} onTagIdChange={onSearchFishChange} onItemTypeChange={onItemTypeChange} showLockedStorage={true} 
                                activeTaggedOnly={false} defaultStorageId={storageId} loadTagged={false} showActiveBatchSwitch={true}/>

                            <Form.Item name="batchTypeId" label="Batch Type">
                                <BatchTypeSelect withBlank={true} defaultValue={batchType} onChange={onSearchBatchTypeChange}/>
                            </Form.Item>

                            {/* <Form.Item name="transactionTypeId" label="Transaction Type">
                                <TransactionTypeSelect
                                    withBlank={true} 
                                    type={OTHERSYSPARAM("STOCK_IN_OUT")}
                                    excludes={[SYSPARAM("Egg Sampling (In)"), SYSPARAM("Egg Sampling (Out)")]}
                                    customWidth={OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}
                                    onChange={onTransactionTypeChange} />
                            </Form.Item> */}

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} >Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={stockTransactionDataSource} pagination={false} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default PendingStockTransactionTable