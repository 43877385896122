import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, Modal, DatePicker, Input, Card, PageHeader, Descriptions, Switch, Spin } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MEDIAMAXWIDTH, MENUPATH_SPAWNING, SPAWNING, UNIDATEFORMAT } from '../Common/SystemParameter'
import { getBatchFullAquacultureStageLifecycle, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserSiteId } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import moment from 'moment'
import MoonStageSelect from './MoonStageSelect'
import FertilizationTypeSelect from './FertilizationTypeSelect'
import { useMediaQuery } from 'react-responsive'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal
const { TextArea } = Input

//----------
// Component
//----------
const SpawningNew = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
        
    const [form] = Form.useForm()
    const [moonStageId, setMoonStageId] = useState(0)
    const [fertilizationTypeId, setFertilizationTypeId] = useState(0)
    const [spawningDateTime, setSpawningDateTime] = useState(moment(moment(), UNIDATEFORMAT))
    const [useEggCollector, setUseEggCollector] = useState(false)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //-----------------------
    // Create spawning
    //-----------------------
    const createSpawning = (advanceToIncubation) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}spawning/create/`, {
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                use_egg_collector: useEggCollector,
                moon_stage: moonStageId,
                duration_days: values.day,
                duration_hours: values.hour,
                duration_minutes: values.minute,
                fertilization_type: fertilizationTypeId,
                quantity_ml: values.quantity,
                remark: values.remark,
                spawnedOn: spawningDateTime,
                advance_to_incubation: advanceToIncubation
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Spawning record saved.")
                navigate({ 
                    pathname: MENUPATH_SPAWNING
                })
            })
            .catch( error => {
                reportError(error, `Failed to save spawning record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //-------------------------------
    // Calculate days from moon stage
    //-------------------------------
    const calculateDuration = () => {
        if(spawningDateTime == null) {
            message.warning("Please enter spawning date time before proceed.")
            return
        }

        if(moonStageId == 0) {
            message.warning("Please select moon stage before proceed.")
            return
        }

        setIsLoading(true)

        axios.get(`${ACISAPIURL}spawning/calculateduration/`, {
            params: { 
                spawning_date_time: spawningDateTime,
                moon_stage: moonStageId,
                site: getUserSiteId()
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const duration = response.data.duration
            form.setFieldsValue({
                day : duration.days,
                hour: duration.hours,
                minute: duration.minutes
            })
        })
        .catch( error => {
            reportError(error, "Failed to calculate duration.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = async (e) => {
        const fullAquacultureStageLifecycle = await getBatchFullAquacultureStageLifecycle(urlParams.get("batchId"))
        
        // If batch is the FIRST in aquaculture stage and lifecycle.
        let currentAquacultureStageLifecycleIndex = -1
        if(fullAquacultureStageLifecycle[0].aquaculture_stage == urlParams.get("aquacultureStageId") && fullAquacultureStageLifecycle[0].lifecycle == urlParams.get("lifecycleId")) 
            currentAquacultureStageLifecycleIndex = 0
        
        const nextAquacultureStageLifecycleIndex = currentAquacultureStageLifecycleIndex + 1
        let nextAquacultureStage = null
        if(currentAquacultureStageLifecycleIndex == 0)
            nextAquacultureStage = fullAquacultureStageLifecycle[nextAquacultureStageLifecycleIndex]
        
        // Any aquaculture stage and lifecycle.
        if(nextAquacultureStage != null) 
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Advance batch aquaculture stage to {nextAquacultureStage.aquaculture_stage_data.name} and lifecycle of {nextAquacultureStage.lifecycle_data.name}?</p></Space>,
                okText: "Yes",
                cancelText: "No",
                onOk() { createSpawning(true) },
                onCancel() { createSpawning(false) },
                centered: true
            })
        else {
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Save spawning record confirmed?</p></Space>,
                onOk() { createSpawning(false) },
                onCancel() {},
                centered: true
            })
        }
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: "/spawningstorage"
        })
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setSpawningDateTime(datetime)
    }

    //------------------------
    // On use collector change
    //------------------------
    const onUseEggCollectorChange = (checked) => {
        setUseEggCollector(checked)
    }

    //------------------
    // Custom validation
    //------------------
    const validateSpawningDateTime = (() => {
        if(spawningDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Spawning date time is required."))
    })

    const validateMoonStage = (() => {
        if(moonStageId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Moon Stage is required."))
    })

    const validateFertilizationType = (() => {
        if(fertilizationTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Fertilization Type is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            remark: ""
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Spawning">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create spawning record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                        </Card>
                    </Form.Item>

                    <Form.Item name="spawningDateTime" label="Spawning Date Time"
                        rules={[
                            { required: true, validator: validateSpawningDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={spawningDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="quantity" label="Egg Quantity (mL)"
                        rules={[
                            { required: true, message: "Egg quantity is required."},
                        ]}>
                        <InputNumber min={1} max={10000} step={1} placeholder="Egg Quantity"/>
                    </Form.Item>

                    <Form.Item name="useEggCollector" label="Use Egg Collector">
                        <Switch defaultChecked={useEggCollector} onChange={onUseEggCollectorChange} />
                    </Form.Item>

                    <Form.Item name="moonStageId" label="Moon Stage"
                        rules={[
                            { required: true, validator: validateMoonStage },
                        ]}>
                        <MoonStageSelect onChange={setMoonStageId}/>
                    </Form.Item>

                    <Form.Item label="Duration From Moon Stage">
                        { !isTabletOrMobile ?
                            <Input.Group>
                                <Form.Item name="day" noStyle
                                    rules={[{ required: true, message: 'Duration day is required' }]}>
                                    <InputNumber min={0} max={31} step={1} placeholder="Day"/>
                                </Form.Item>
                                <Space>&nbsp;d&nbsp;</Space>

                                <Form.Item name="hour" noStyle
                                    rules={[{ required: true, message: 'Duration hour is required' }]}>
                                    <InputNumber min={0} max={23} step={1} placeholder="Hour"/>
                                </Form.Item>
                                <Space>&nbsp;hr&nbsp;</Space>

                                <Form.Item name="minute" noStyle
                                    rules={[{ required: true, message: 'Duration minute is required' }]}>
                                    <InputNumber min={0} max={59} step={1} placeholder="Minute"/>
                                </Form.Item>
                                <Space>&nbsp;min&nbsp;</Space>

                                <Button onClick={calculateDuration}>Calculate</Button>
                            </Input.Group> :
                            
                            <Input.Group>
                                <Form.Item name="day" noStyle
                                    rules={[{ required: true, message: 'Duration day is required' }]}>
                                    <InputNumber min={0} max={31} step={1} placeholder="Day" style={{width: OTHERSYSPARAM("INPUT_NUMBER_WIDTH_MOBILE")}}/>
                                </Form.Item>
                                <Space>&nbsp;d&nbsp;</Space>

                                <Form.Item name="hour" noStyle
                                    rules={[{ required: true, message: 'Duration hour is required' }]}>
                                    <InputNumber min={0} max={23} step={1} placeholder="Hour" style={{width: OTHERSYSPARAM("INPUT_NUMBER_WIDTH_MOBILE")}}/>
                                </Form.Item>
                                <Space>&nbsp;hr&nbsp;</Space>

                                <Form.Item name="minute" noStyle
                                    rules={[{ required: true, message: 'Duration minute is required' }]}>
                                    <InputNumber min={0} max={59} step={1} placeholder="Minute" style={{width: OTHERSYSPARAM("INPUT_NUMBER_WIDTH_MOBILE")}}/>
                                </Form.Item>
                                <Space>&nbsp;min&nbsp;</Space>

                                <Button onClick={calculateDuration}>Calculate</Button>
                            </Input.Group>
                        }
                    </Form.Item>

                    <Form.Item name="fertilizationType" label="Fertilization Type"
                        rules={[
                            { required: true, validator: validateFertilizationType },
                        ]}>
                        <FertilizationTypeSelect onChange={setFertilizationTypeId}/>
                    </Form.Item>

                    <Form.Item name="remark" label="Observation" >
                        <TextArea rows={3}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Spawning:"
                    subTitle="Create spawning record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default SpawningNew