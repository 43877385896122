import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Row, Col, Modal, InputNumber, Card, Typography } from 'antd'
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { formLayout } from '../Common/Layout'
import axios from 'axios'
import { ACISAPIURL, DATETIMEFORMAT } from '../Common/SystemParameter'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { reportError } from '../Common/Utility'
import moment from 'moment'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { confirm } = Modal
const { Title } = Typography

const StandardGrowthNewTable = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")

    const [aquacultureStageId, setAquacultureStageId] = useState(0)
    const [aquacultureStage, setAquacultureStage] = useState("")
                    
    const [lifecycleId, setLifecycleId] = useState(0)
    const [lifecycleOption, setLifecycleOption] = useState([])
    
    const [species, setSpecies] = useState("")
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [active, setActive] = useState(false)
    const [activeColor, setActiveColor] = useState("red")
    const [numberOfRecord, setNumberOfRecord] = useState(0)
    const [createdOn, setCreatedOn] = useState(null)
    const [updatedOn, setUpdatedOn] = useState(null)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //------------------------------
    // Create standard growth record
    //------------------------------
    const createStandardGrowthRecord = () => {
        // Disable button.
        setDisableButton("disabled")
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}standardgrowth/create/`, {
                standard_growth_table: urlParams.get("tablePKey"),
                aquaculture_stage: aquacultureStageId,
                lifecycle: lifecycleId,
                day: values.day,
                average_per_unit_weight_gram: values.averagePerUnitWeightGram,
                average_per_unit_length_mm: values.averagePerUnitLengthMm,
                mortality_percentage: values.mortalityPercentage,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Standard growth record saved.")

                let params = new URLSearchParams(`?pKey=${urlParams.get("tablePKey")}`)
                if(urlParams.has("fromArchive")) params.append("fromArchive", true)

                navigate({ 
                    pathname: "/standardgrowth",
                    search: params.toString()
                })
            })
            .catch( error => {
                reportError(error, `Failed to save standard growth record.`)
            })
            .finally(() => {
                setDisableButton("")
                refreshUserSession()
            })
        })
    }

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create standard growth record confirmed?</p></Space>,
            onOk() { createStandardGrowthRecord() },
            onCancel() {},
            centered: true
        })
    }

    //-------------------------
    // Get growth table
    //-------------------------
    const getGrowthTable = async () => {
        setDisableButton("disabled")
                
        return await axios.get(`${ACISAPIURL}standardgrowth/table/${urlParams.get("tablePKey")}/`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            setSpecies(response.data.species_data.short_name)
            setMarineLifeId(response.data.species_data.marine_life_data.pKey)
            setNumberOfRecord(response.data.record_count)
            setActive(response.data.active ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO"))    
            setActiveColor(response.data.active ? "blue" : "red")
            setCreatedOn(moment(response.data.createdOn).format(DATETIMEFORMAT))
            setUpdatedOn(moment(response.data.updatedOn).format(DATETIMEFORMAT))
        })
        .catch( error => {
            reportError(error, "Failed to get standard growth table.")
        })
        .finally(() => {
            setDisableButton("")
            refreshUserSession()
        })
    }

    //----------------------------
    // On aquaculture state change
    //----------------------------
    const onAquacultureStageChange = (e, value) => {
        setAquacultureStageId(e)
        setAquacultureStage(value.children)
    }

    //---------------------------
    // On cancel
    //---------------------------
    const onCancel = () => {
        let params = new URLSearchParams(`?pKey=${urlParams.get("tablePKey")}`)
        if(urlParams.has("fromArchive")) params.append("fromArchive", true)

        navigate({ 
            pathname: "/standardgrowth",
            search: params.toString()
        })
    }

    const onLifecycleChange = (e) => {
        setLifecycleId(e)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getGrowthTable()
    }, [])

    return(
        <>
        <Form {...formLayout}>
            <Form.Item>
                    <Card title={<Title level={5}>{`Species: ${species}`}</Title>}>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">No. of Records In Table:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberOfRecord}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Active:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{color: activeColor}}>{active}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{createdOn}</Card.Grid>
                    
                        { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                            <>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{updatedOn}</Card.Grid>
                            </>
                        }
                    </Card>
            </Form.Item>
        </Form>

        <Form form={form} onFinish={onSave} {...formLayout}>
            <CommonSearchFormItem onAquacultureStageChange={onAquacultureStageChange} onLifecycleChange={onLifecycleChange} 
                defaultSpeciesId={urlParams.get("speciesId")} formLayout={formLayout}/>

            <Form.Item name="day" label="Day"
                rules={[
                    { required: true, message: "Day is required."},
                ]}>
                <InputNumber min={0} max={10000000} step={1} placeholder="Day"/>
            </Form.Item>

            <Form.Item name="averagePerUnitWeightGram" label="Average Per Unit Weight (g)"
                rules={[
                    { required: true, message: "Average per unit weight is required."},
                ]}>
                <InputNumber min={0} max={1000000} precision={2} step={1} placeholder="Average Per Unit Weight (g)"/>
            </Form.Item>

            <Form.Item name="averagePerUnitLengthMm" label="Average Per Unit Length (mm)"
                rules={[
                    { required: true, message: "Average per unit length is required."},
                ]}>
                <InputNumber min={0} max={1000000} precision={2} step={1} placeholder="Average Per Unit Length (mm)"/>
            </Form.Item>

            <Form.Item name="mortalityPercentage" label="Mortality Rate (%)"
                rules={[
                    { required: true, message: "Mortality is required."},
                ]}>
                <InputNumber min={0} max={100} precision={2} step={1} placeholder="Mortality Rate (%)"/>
            </Form.Item>

            <Row justify="center">
                <Col span={6}></Col>
                <Col span={12} style={{textAlign: "center"}}>
                    <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Save</Button>
                    <Button type="primary" htmlType="button" disabled={disableButton} onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                </Col>
                <Col span={6}></Col>
            </Row>
        </Form>
        </>
    )
}

export default StandardGrowthNewTable