import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, PageHeader, InputNumber, Card, Popconfirm, message, Input, Descriptions, Table, Modal, DatePicker, Spin } from 'antd'
import { LeftOutlined, SaveOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, EGGS, KG, LOADING, MEDIAMAXWIDTH, MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE, PERCENT, UNIDATEFORMAT, UNIT, UNTAGGED } from "../Common/SystemParameter"
import { numberWithCommas, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, SYSPARAM, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import { releaseStorage } from '../Common/releaseStorage'
import TransactionTypeSelect from '../Common/TransactionTypeSelect'
import ItemTypeUnitOfMeasurementSelect from '../Common/ItemTypeUnitOfMeasurementSelect'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { TextArea } = Input
const { confirm } = Modal

//----------
// Component
//----------
const CollectiveFishStockOut = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [transactionTypeId, setTransactionTypeId] = useState(0)
    const [transactionType, setTransactionType] = useState("")
    const [weightSampleDataSource, setWeightSampleDataSource] = useState([])
    const [stockOutDateTime, setStockOutDateTime] = useState(moment(moment(), UNIDATEFORMAT))

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [itemTypeUomId, setItemTypeUomId] = useState(0)
    const [itemTypeUom, setItemTypeUom] = useState("")
    const [hideAverageWeight, setHideAverageWeight] = useState(urlParams.get("itemType").startsWith(UNTAGGED) ? false : true)

    //------------------------------
    // Create stock-out transaction
    //------------------------------
    const createStockOut = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        //-------------------------------------------------------------------------------------------------
        // 20220114
        // If item type uom of Kg is selected, need to convert quantity of Kg back to rounded up tail/unit.
        //-------------------------------------------------------------------------------------------------
        let quantity = form.getFieldValue("quantity")
        if(!itemTypeUom.includes(KG)) 
            quantity = Math.ceil(quantity)
        else
            quantity = Math.ceil(quantity * 1000 / parseFloat(form.getFieldValue("averagePerUnitWeightGram")))

        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}stocktransaction/out/create/`, {
                storage: urlParams.get("storageId"),
                batch: urlParams.get("batchId"),
                item_type_uom: itemTypeUomId,
                item_type: urlParams.get("itemTypeId"),
                item_type_name: urlParams.get("itemType"),
                quantity: quantity * -1, //parseInt(form.getFieldValue("quantity")) * -1,
                transaction_type: transactionTypeId,
                average_per_unit_weight_gram: values.averagePerUnitWeightGram,
                remark: values.remark,
                transactedOn: stockOutDateTime,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Stock-Out transaction was successful.")
                navigate({ 
                    pathname: MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE,
                    search: `?transactionTypeId=${urlParams.get("transactionTypeId")}&transactionType=${urlParams.get("transactionType")}`
                })

                releaseStorage(urlParams.get("batchId"), urlParams.get("batch"), urlParams.get("storageId"))
            })
            .catch( error => {
                reportError(error, `Failed to create stock-out transaction.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //-------------
    // Confirmation
    //-------------
    const stockOutConfirmation = () => {
        let msg = "Stock out confirmed?"
        if(String(transactionType.children).startsWith("Stock Adjustment"))
            msg = <Title level={5}>
                Stock adjustment requires approval from the management. The mangement may contact you to provide the reason for this adjustment.
                <br/><br/>You can view pending stock transaction in menu "Inventory/Pending Stock Transaction".
                <br/><br/>Do you want to proceed with this adjustment?
                </Title>
        
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>{msg}</p></Space>,
            onOk() { createStockOut() },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On save
    //--------
    const onNext = () => {
        form.validateFields()
        .then( values => {
            if(urlParams.get("itemType").startsWith(UNTAGGED) && parseFloat(values.averagePerUnitWeightGram) == 0) {
                message.warn("Zero average per unit weight. Please enter the weight before proceed.")
                return
            }

            // if(itemTypeUomId == SYSPARAM("EGG_UNIT") && parseInt(values.quantity) < OTHERSYSPARAM("1_ML_EGG_IN_UNIT") / 2) {
            //     message.warn(`At least ${OTHERSYSPARAM("1_ML_EGG_IN_UNIT") / 2} unit of eggs is needed to make up eggs of 1 mL`)
            //     return
            // }

            if(itemTypeUom.includes(PERCENT)) {
                // If percentage is selected. It can't be more than 100.
                if(parseInt(values.quantity) > 100) {
                    message.warn("Out quantity cannot exceeded 100 percent.")
                    return
                }
                stockOutConfirmation()
            }
            else if(itemTypeUom.startsWith(EGGS) && itemTypeUom.includes(UNIT)) {
                // If unit is selected. It can't be more than its mL equivalent.
                // 1 mL = 1000 units.
                const availableUnit = parseInt(urlParams.get("availableQuantity")) * OTHERSYSPARAM(`1_ML_EGG_IN_UNIT_${urlParams.get("marineLifeId")}`)
                
                if(parseInt(values.quantity) > availableUnit) {
                    message.warn(`Stock-Out quantity cannot exceeded ${availableUnit} unit.`)
                    return
                }
                stockOutConfirmation()
            }
            else if(itemTypeUom.includes(KG)) {
                const totalAvailableWeightKg = parseFloat(urlParams.get("averageWeight")) * parseInt(urlParams.get("availableQuantity")) / 1000
                if(parseFloat(values.quantity) > totalAvailableWeightKg) {
                    message.warn(`Transfer weight cannot exceeded ${totalAvailableWeightKg} KG.`)
                    return
                }
                stockOutConfirmation()
            }
            else {
                // Untagged fish or egg-mL is selected.
                if(values.quantity > urlParams.get("availableQuantity")) {
                    message.warn("Stock-Out quantity exceeded available quantity.")
                    return
                }
                stockOutConfirmation()
            }
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE, 
        })
    }
    
    //---------------------------
    // On transaction type change
    //---------------------------
    const onTransactionTypeChange = (e, value) => {
        setTransactionTypeId(e)
        setTransactionType(value)
    }

    //----------------------------------------
    // On item type unit of measurement change
    //----------------------------------------
    const onItemTypeUomChange = (e, value) => {
        setItemTypeUomId(e)
        setItemTypeUom(value.children)
    }

    //------------------------
    // Weight sample functions
    //------------------------
    const deleteWeightSample = (e, record) => {
        e.stopPropagation()

        let dataSource = weightSampleDataSource.filter((item) => item.key !== record.key)
        let counter = 0
        dataSource.forEach( ds => { 
            ds.key = ++counter
            ds.sampleNo = `${counter}.`
        })

        setWeightSampleDataSource(dataSource)
        calculateAverageWeight(dataSource)
    }

    const addWeightSample = () => {
        let sampleWeight = form.getFieldValue("sampleWeight")
        if(sampleWeight == null) {
            message.warn("Invalid sample weight.")
            return
        }

        const data = {
            key: weightSampleDataSource.length + 1,
            sampleNo: `${weightSampleDataSource.length + 1}.`,
            weight: sampleWeight
        }
        setWeightSampleDataSource(weightSampleDataSource.concat(data))

        calculateAverageWeight(weightSampleDataSource.concat(data))

        form.setFieldsValue({
            sampleWeight: ""
        })
    }

    const calculateAverageWeight = (weightSampleDataSource) => {
        if(weightSampleDataSource.length == 0) {
            form.setFieldsValue({
                averagePerUnitWeightGram: ""
            })
            return
        }

        const allWeight = weightSampleDataSource.map( sample => parseFloat(sample.weight))
        const reducer = (accumulator, curr) => accumulator + curr
        let averageWeight = Math.round(allWeight.reduce(reducer) / weightSampleDataSource.length)
        
        form.setFieldsValue({
            averagePerUnitWeightGram: averageWeight
        })

        message.info("Average weight updated.")
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setStockOutDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateTransactionType = (() => {
        if(transactionTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Transaction type is required."))
    })

    const validateStockOutDateTime = (() => {
        if(stockOutDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Stock-Out date time is required."))
    })

    /*const validateQuantityItemTypeUom = (() => {
        if(itemTypeUomId != 0 && parseInt(form.getFieldValue("quantity")) > 0) {
            return Promise.resolve()
        }

        if(!parseInt(form.getFieldValue("quantity")) > 0)
            return Promise.reject(new Error("Quantity is required."))

        if(itemTypeUomId == 0)
            return Promise.reject(new Error("Item type - Unit of Measurement is required."))
    })*/

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            averagePerUnitWeightGram: urlParams.get("itemType").startsWith(UNTAGGED) ? urlParams.get("averageWeight") : 0,
            remark: ""
        })
    }, [])

    const columns = [
        { title: 'Sample No.', dataIndex: 'sampleNo', key: 'sampleNo', sorter: (a, b) => a.sampleNo - b.sampleNo },
        { title: 'Weight (g)', dataIndex: 'weight', key: 'weight', sorter: (a, b) => a.weight - b.weight },
        { key: 'action', 
            render: (record) => {
                return <>
                    <Popconfirm title="Delete weight sample confirmed?" 
                        onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => deleteWeightSample(e, record)} 
                        okText="Yes" cancelText="No">
                        <Button danger type="primary" disabled={disableButton} icon={<DeleteOutlined/>} />
                    </Popconfirm>
                </>
            }
        }
    ]

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onBack()} 
                    title="Stock-Out">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create stock-out record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onNext} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Source Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                        </Card>
                    </Form.Item>

                    <Form.Item name="stockOutDateTime" label="Stock Out Date Time"
                        rules={[
                            { required: true, validator: validateStockOutDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={stockOutDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>
                    
                    <Form.Item name="transactionType" label="Transaction Type"
                        rules={[
                            { required: true, validator: validateTransactionType },
                        ]}>
                        <TransactionTypeSelect 
                            withBlank={false} 
                            value={transactionType} 
                            type={OTHERSYSPARAM("STOCK_OUT")} 
                            onChange={onTransactionTypeChange} 
                            excludes={[SYSPARAM("Mortality"), SYSPARAM("Stock Count Variance Adjustment (Out)")]} />
                    </Form.Item>

                    { urlParams.get("itemType").startsWith(EGGS) &&
                        <Form.Item label={`Note: Eggs will always be rounded up to the next integer in mL`}>
                        </Form.Item>
                    }

                    <Form.Item label={`Stock-Out Quantity Out of ${numberWithCommas(urlParams.get("availableQuantity"))} (${urlParams.get("itemTypeUom")})`}>
                        <Input.Group>
                            <Form.Item name="quantity" noStyle
                                rules={[
                                    { required: true, message: "Stock-Out quantity is required." },
                                ]}>
                                <InputNumber min={0.1} max={10000000} step={1} placeholder="Quantity"/>
                            </Form.Item>

                            <Form.Item name="itemTypeUom" noStyle
                                rules={[
                                    { required: true, message: "Item type - Unit of Measurement is required." },
                                ]}>
                                <ItemTypeUnitOfMeasurementSelect withBlank={false} marineLifeId={urlParams.get("marineLifeId")} 
                                    itemType={urlParams.get("itemType")} onChange={onItemTypeUomChange} />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item name="remark" label="Remark"
                        rules={[
                            { required: true, message: "Remark is required."},
                        ]}>
                        <TextArea rows={3} maxLength={500}/>
                    </Form.Item>

                    <Form.Item name="averagePerUnitWeightGram" label="Average Per Unit Weight (g)" hidden={hideAverageWeight}
                        rules={[
                            { required: true, message: "Average per unit weight is required."},
                        ]}>
                        <InputNumber min={0} max={1000000} precision={0} step={1} placeholder="Average Per Unit Weight (g)"/>
                    </Form.Item>
                    
                    {/* 
                        Allow user to input single or multiple untagged fish weight to obtain average weight for better reflect actual weight.
                    */}
                    { urlParams.get("itemType").startsWith(UNTAGGED) &&
                        <>
                        <Form.Item>
                            <Card title="Untagged Fish Weight Averaging" style={{textAlign: "left"}}>
                                For large quantity stock out, please enter multiple fish weight samples below to better reflect actual fish average weight.
                            </Card>
                        </Form.Item>

                        <Form.Item label="Sample Weight (g)">
                            <Input.Group>
                                <Form.Item name="sampleWeight" noStyle>
                                    <InputNumber min={0.01} max={1000000} precision={0} step={10} placeholder="Weight (g)"/>
                                </Form.Item>
                                <Button onClick={addWeightSample}>Add</Button>
                            </Input.Group>
                        </Form.Item>

                        <Row justify="center">
                            <Col span={isTabletOrMobile ? 10 : 8} style={{textAlign: "center"}}>
                                <Table bordered columns={columns} dataSource={weightSampleDataSource} pagination={false} />
                            </Col>
                        </Row>

                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }
                    
                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title={`Stock-Out:`}
                    subTitle="Create stock-out record "/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default CollectiveFishStockOut