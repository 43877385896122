import { Row, Col, Table, Space, Form, Card, Typography, Spin } from 'antd'
import { Column } from '@ant-design/charts'
import { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, LOADING, MEDIAMAXWIDTH, PAGINATIONSIZE } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { formLayout } from '../Common/Layout'

const { Title } = Typography

const InventoryChartBySpeciesWeightRangeTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    
    const [column, setColumn] = useState(null)
    const [totalDataSource, setTotalDataSource] = useState([])

    const [totalBatches, setTotalBatches] = useState(0)
    const [totalStorages, setTotalStorages] = useState(0)
        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //----------------------------
    // Search inventory by species
    //----------------------------
    const searchInventoryByBatchStorage = async () => {
        await axios.get(`${ACISAPIURL}inventory/batchstorage/`, {
            params: { 
                site: getUserSiteId(),
                species: urlParams.get("speciesId"),
                batch: urlParams.get("batchId"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const batches = new Set(response.data.results.map( batch => batch.batch_data.pKey ))
            setTotalBatches(batches.size)
            setTotalStorages(response.data.count)
            
            plotColumn(response)
        })
        .catch( error => {
            reportError(error, "Failed to load inventory by species record.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const plotColumn = (response) => {
        let data = []
        let total = []
        let counter = 0
        
        // Build weight range according to OTHERSYSTEMPARAM("DASHBOARD_SPECIES_WEIGHT_RANGE_GRAM").
        let from = 1
        let to = parseInt(OTHERSYSPARAM("DASHBOARD_SPECIES_WEIGHT_RANGE_GRAM"))

        while(to <= OTHERSYSPARAM("DASHBOARD_SPECIES_WEIGHT_RANGE_MAX_GRAM")) {
            // Get batches fall within weight range.
            const matchedBatches = response.data.results.filter( summary => 
                (summary.untagged_average_weight_gram >= from && summary.untagged_average_weight_gram <= to) ||
                (summary.tagged_average_weight_gram >= from && summary.tagged_average_weight_gram <= to))

            let quantity = 0
            let weightGram = 0
            let storageIds = []
            
            if(matchedBatches.length > 0) {
                matchedBatches.forEach( batch => {
                    if(batch.untagged_average_weight_gram >= from && batch.untagged_average_weight_gram <= to && batch.untagged_quantity > 0) {
                        quantity += batch.untagged_quantity
                        weightGram += batch.untagged_quantity * batch.untagged_average_weight_gram
                        storageIds.push(batch.untagged_storage)
                    }

                    if(batch.tagged_average_weight_gram >= from && batch.tagged_average_weight_gram <= to && batch.tagged_quantity > 0) {
                        quantity += batch.tagged_quantity
                        weightGram += batch.tagged_quantity * batch.tagged_average_weight_gram
                        storageIds.push(batch.tagged_storage)
                    }
                })
            }
            
            data.push({
                type: `${from} - ${to} (g)`,
                value: quantity,
                batchIds: new Set(matchedBatches.map( batch => batch.batch_data.pKey)),
                storageIds: new Set(storageIds),
                storageCount: new Set(storageIds).size,
                totalWeightKg: numberWithCommas((weightGram / 1000).toFixed(2)),
            })

            total.push({
                number: `${++counter}.`,
                species: urlParams.get("species"),
                speciesId: urlParams.get("speciesId"),
                weightRange: `${from} - ${to}`,
                totalQuantity: numberWithCommas(quantity),
                totalWeightKg: numberWithCommas((weightGram / 1000).toFixed(2)),
                batchIds: new Set(matchedBatches.map( batch => batch.batch_data.pKey)),
                storageIds: new Set(storageIds),
                numOfBatches: new Set(matchedBatches.map( batch => batch.batch_data.pKey)).size,
                numOfStorages: new Set(storageIds).size
            })
        
            from = to + 1
            to += parseInt(OTHERSYSPARAM("DASHBOARD_SPECIES_WEIGHT_RANGE_GRAM"))
        }
        
        const config = {
            data,
            appendPadding: 50,
            xField: 'type',
            yField: 'value',
            label: {
                position: 'top',// 'top', 'bottom', 'middle',
                style: {
                    fill: '#000000',
                    opacity: 0.6,
                },
                content: (value) => {
                    if(value.value > 0)
                        return `Qty: ${value.value}\nB: ${value.batchIds.size}\nStg: ${value.storageCount}`
                    else    
                        return ""
                }
            },
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
            },
            meta: {
                // type: {
                //     alias: '类别',
                // },
                value: {
                    alias: 'Tail/Unit',
                },
            },
        }
          
        const bindEvent = (plot) => {
            plot.on('plot:click', (evt) => {
                const { x, y } = evt
                // KEEP!
                //const { xField } = plot.options
                const tooltipData = plot.chart.getTooltipItems({ x, y })
                // tooltipData is information of a particular sector.
                if(tooltipData[0].data.value > 0) {
                    navigate({ 
                        pathname: "/dashboardbatchstorage",
                        search: `?speciesId=${urlParams.get("speciesId")}&species=${urlParams.get("species")}&weightRange=${tooltipData[0].title}&batchIds=${[...tooltipData[0].data.batchIds].join(',')}&storageIds=${[...tooltipData[0].data.storageIds].join(',')}&totalQuantity=${numberWithCommas(tooltipData[0].data.value)}&totalWeightKg=${tooltipData[0].data.totalWeightKg}`
                    })
                }
            })
        }

        setColumn(<Column {...config} onReady={bindEvent}/>)
        setTotalDataSource(total)
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        if(record.totalQuantity == 0) return

        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/dashboardbatchstorage",
                    search: `?speciesId=${urlParams.get("speciesId")}&species=${urlParams.get("species")}&weightRange=${record.weightRange} (g)&batchIds=${[...record.batchIds].join(',')}&storageIds=${[...record.storageIds].join(',')}&totalQuantity=${record.totalQuantity}&totalWeightKg=${record.totalWeightKg}`
                }) 
            }
        }
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            await searchInventoryByBatchStorage()
            setLoading(false)
        }

        init()
    }, [])

    const columns = [
        { title: 'No.', dataIndex: 'number', key: 'number', sorter: (a, b) => a.number - b.number },
        { title: 'Weight Range (g)', dataIndex: 'weightRange', key: 'weightRange', sorter: (a, b) => a.weightRange.localeCompare(b.weightRange) },
        { title: 'No. of Batch(es)', dataIndex: 'numOfBatches', key: 'numOfBatches', align: "right", sorter: (a, b) => a.numOfBatches - b.numOfBatches },
        { title: 'No. of Storage(s)', dataIndex: 'numOfStorages', key: 'numOfStorages', align: "right", sorter: (a, b) => a.numOfStorages - b.numOfStorages },
        { title: 'Total Quantity (Tail / Unit)', dataIndex: 'totalQuantity', key: 'totalQuantity', align: "right", sorter: (a, b) => stringNumberSorter(a.totalQuantity, b.totalQuantity) },
        { title: 'Total Weight (Kg)', dataIndex: 'totalWeightKg', key: 'totalWeightKg', align: "right", sorter: (a, b) => stringNumberSorter(a.totalWeightKg, b.totalWeightKg ) },
    ]

    return(
        <>
        <Spin spinning={loading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Species: ${urlParams.get("species")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Total Quantity (Tail/Unit):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("totalQuantity")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Total Weight (Kg):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("totalWeightKg")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">No. of Batch(es):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{totalBatches}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">No. of Storage(s):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{totalStorages}</Card.Grid>
                </Card>
            </Form.Item>
        </Form>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col style={{textAlign: "center", verticalAlign: "middle"}} span={24} >
                {column}
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        {<Row justify="center">
            <Col style={{textAlign: "center"}} span={isTabletOrMobile ? 24: 16} >
                <Table bordered columns={columns} dataSource={totalDataSource} pagination={{size: PAGINATIONSIZE}} onRow={onRowClick}/>
            </Col>        
        </Row>}

        <Row><Col><Space><div /></Space></Col></Row>
        </Spin>
        </>
    )
}

export default InventoryChartBySpeciesWeightRangeTable