import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL, MEDIAMAXWIDTH,  } from "./SystemParameter"
import { reportError } from "./Utility"
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from "./UserSession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select

//----------
// Component
//----------
const SiteSelect = ({withBlank, defaultValue, onChange, ownSiteOnly, excludeOwnSite}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [siteOption, setSiteOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
        
    //---------
    // Load all
    //---------
    const getAllSite = () => {
        axios.get(`${ACISAPIURL}site/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( site => <Option key={site.pKey}>{site.name}</Option>))
            if(ownSiteOnly) options = options.filter( option => option.key == getUserSiteId())
            if(excludeOwnSite) options = options = options.filter( option => option.key != getUserSiteId())
            setSiteOption(options)
        })
        .catch( error => {
            reportError(error, "<SiteSelect /> Failed to get site(s).")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        onChange(e, value)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllSite()
    }, []) 
    
    return(
        <Select style={{width: optionWidth}} onChange={onSelectionChange} allowClear showSearch optionFilterProp="children" defaultValue={defaultValue}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {siteOption}
        </Select>
    )
}

export default SiteSelect