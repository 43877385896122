import { useEffect, useState } from 'react'
import { Layout, Row, Col, Space, PageHeader, BackTop, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { CRABMARINELIFENAME, FISHMARINELIFENAME, MENUPATH_HATCHING } from "../Common/SystemParameter"
import HatchingStorageTable from "./HatchingStorageTable"
import { useNavigate } from 'react-router-dom'
import { OTHERSYSPARAM } from '../Common/UserSession'
import HatchingStorageTableCrab from './HatchingStorageTableCrab'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const HatchingStorage = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [marineLifeLabel, setMarineLifeLabel] = useState(urlParams.get("marineLife") == FISHMARINELIFENAME ? FISHMARINELIFENAME : CRABMARINELIFENAME)

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_HATCHING
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader onBack={() => onBack()} 
                    title={`Hatching (${marineLifeLabel})`}>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Select storage</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center">
                    <Col span={1}></Col>
                    { urlParams.get("marineLife") == FISHMARINELIFENAME &&
                        <Col span={22}><HatchingStorageTable /></Col>
                    }
                    { urlParams.get("marineLife") == CRABMARINELIFENAME &&
                        <Col span={22}><HatchingStorageTableCrab /></Col>
                    }
                    <Col span={1}></Col>
                </Row>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title={`Hatching (${marineLifeLabel}):`}
                    subTitle="Select storage"/>} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default HatchingStorage