import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, Modal, DatePicker, Input, Card, PageHeader, Descriptions, Switch, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MEDIAMAXWIDTH, MENUPATH_SPAWNING, SPAWNING, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { getBatchFullAquacultureStageLifecycle, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserSiteId } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import moment from 'moment'
import MoonStageSelect from './MoonStageSelect'
import FertilizationTypeSelect from './FertilizationTypeSelect'
import { useMediaQuery } from 'react-responsive'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal
const { TextArea } = Input

//----------
// Component
//----------
const SpawningUpdate = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [form] = Form.useForm()
    const [moonStageId, setMoonStageId] = useState(urlParams.get("moonStageId"))
    const [fertilizationTypeId, setFertilizationTypeId] = useState(urlParams.get("fertilizationTypeId"))
    const [spawningDateTime, setSpawningDateTime] = useState(moment(moment(urlParams.get("spawningDateTime")), UNIDATETIMEFORMAT))
    const [useEggCollector, setUseEggCollector] = useState(urlParams.get("useEggCollector") == "true")

    const days = urlParams.get("duration").split(" ")[0]
    const hours = urlParams.get("duration").split(" ")[1].split(":")[0]
    const minutes = urlParams.get("duration").split(" ")[1].split(":")[1]

    const [hideButton, setHideButton] = useState("")
    
    //-----------------------
    // Update spawning record
    //-----------------------
    const updateSpawning = (advanceToIncubation) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}spawning/update/${urlParams.get("pKey")}/`, {
                use_egg_collector: useEggCollector,
                moon_stage: moonStageId,
                duration_days: values.day,
                duration_hours: values.hour,
                duration_minutes: values.minute,
                fertilization_type: fertilizationTypeId,
                quantity_ml: values.quantity,
                remark: values.remark,
                spawnedOn: spawningDateTime,
                advance_to_incubation: advanceToIncubation
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Spawning record updated.")
                navigate({ 
                    pathname: MENUPATH_SPAWNING
                })
            })
            .catch( error => {
                reportError(error, `Failed to update spawning record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //-------------------------------
    // Calculate days from moon stage
    //-------------------------------
    const calculateDuration = () => {
        if(spawningDateTime == null) {
            message.warn("Please enter spawning date time before proceed.")
            return
        }

        if(moonStageId == 0) {
            message.warn("Please select moon stage before proceed.")
            return
        }

        setIsLoading(true)

        axios.get(`${ACISAPIURL}spawning/calculateduration/`, {
            params: { 
                spawning_date_time: spawningDateTime,
                moon_stage: moonStageId,
                site: getUserSiteId()
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const duration = response.data.duration
            form.setFieldsValue({
                day : duration.days,
                hour: duration.hours,
                minute: duration.minutes
            })
        })
        .catch( error => {
            reportError(error, "Failed to calculate duration.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //----------------------------------------------------------
    // Retrieve storage protection triggered record count.
    // If count > 0, disable update and delete.
    //----------------------------------------------------------
    const getProtectionTriggeredRecordCount = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}spawning/protectiontriggeredrecordcount/`, {
            params: { 
                pKey: urlParams.get("pKey")
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(parseInt(response.data.record_found) > 0) {
                setHideButton("none")
                message.info("Protection triggered record found. Update and delete not allowed.")
            }
        })
        .catch( error => {
            reportError(error, "Failed to retrieve protection triggered record count.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------------------
    // Delete spawning record
    //-----------------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}spawning/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Spawning record deleted.`)
            navigate({ pathname: MENUPATH_SPAWNING })
        })
        .catch( error => {
            reportError(error, "Failed to delete spawning record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = async (e) => {
        const fullAquacultureStageLifecycle = await getBatchFullAquacultureStageLifecycle(urlParams.get("batchId"))
        
        // If batch is the FIRST in aquaculture stage and lifecycle.
        let currentAquacultureStageLifecycleIndex = -1
        if(fullAquacultureStageLifecycle[0].aquaculture_stage == urlParams.get("batchAquacultureStageId") && fullAquacultureStageLifecycle[0].lifecycle == urlParams.get("batchLifecycleId")) 
            currentAquacultureStageLifecycleIndex = 0
        
        const nextAquacultureStageLifecycleIndex = currentAquacultureStageLifecycleIndex + 1
        let nextAquacultureStage = null
        if(currentAquacultureStageLifecycleIndex == 0)
            nextAquacultureStage = fullAquacultureStageLifecycle[nextAquacultureStageLifecycleIndex]

        // Any aquaculture stage and lifecycle.
        if(nextAquacultureStage != null) 
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Advance batch aquaculture stage to {nextAquacultureStage.aquaculture_stage_data.name} and lifecycle of {nextAquacultureStage.lifecycle_data.name}?</p></Space>,
                okText: "Yes",
                cancelText: "No",
                onOk() { updateSpawning(true) },
                onCancel() { updateSpawning(false) },
                centered: true
            })
        else {
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Update spawning record confirmed?</p></Space>,
                onOk() { updateSpawning(false) },
                onCancel() {},
                centered: true
            })
        }
    }

    //--------
    // On back
    //--------
    const onCancel = () => {
        navigate({ 
            pathname: MENUPATH_SPAWNING
        })
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setSpawningDateTime(datetime)
    }

    //------------------------
    // On use collector change
    //------------------------
    const onUseEggCollectorChange = (checked) => {
        setUseEggCollector(checked)
    }

    //------------------
    // Custom validation
    //------------------
    const validateSpawningDateTime = (() => {
        if(spawningDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Spawning date time is required."))
    })

    const validateMoonStage = (() => {
        if(moonStageId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Moon Stage is required."))
    })

    const validateFertilizationType = (() => {
        if(fertilizationTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Fertilization Type is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            quantity: urlParams.get("quantity"),
            day: days,
            hour: hours,
            minute: minutes,
            remark: urlParams.get("remark") != "undefined" ? urlParams.get("remark") : ""
        })

        getProtectionTriggeredRecordCount()
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Spawning">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update spawning record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Spawning ID: ${urlParams.get("id")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("storage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
                     
                            { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Form.Item>

                    <Form.Item name="spawningDateTime" label="Spawning Date Time"
                        rules={[
                            { required: true, validator: validateSpawningDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={spawningDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="quantity" label="Egg Quantity(mL)"
                        rules={[
                            { required: true, message: "Egg quantity is required."},
                        ]}>
                        <InputNumber min={1} max={10000} step={1} defaultValue={urlParams.get("quantity")} placeholder="Egg Quantity"/>
                    </Form.Item>
                    
                    <Form.Item name="useEggCollector" label="Use Egg Collector">
                        <Switch defaultChecked={useEggCollector} onChange={onUseEggCollectorChange} />
                    </Form.Item>

                    <Form.Item name="moonStageId" label="Moon Stage"
                        rules={[
                            { required: true, validator: validateMoonStage },
                        ]}>
                        <MoonStageSelect onChange={setMoonStageId} defaultValue={urlParams.get("moonStage")}/>
                    </Form.Item>

                    <Form.Item label="Duration From Moon Stage">
                        { !isTabletOrMobile ? 
                            <Input.Group>
                                <Form.Item name="day" noStyle
                                    rules={[{ required: true, message: 'Duration day is required' }]}>
                                    <InputNumber min={0} max={31} step={1} defaultValue={days} placeholder="Day" />
                                </Form.Item>
                                <Space>&nbsp;d&nbsp;</Space>

                                <Form.Item name="hour" noStyle
                                    rules={[{ required: true, message: 'Duration hour is required' }]}>
                                    <InputNumber min={0} max={23} step={1} defaultValue={hours} placeholder="Hour" />
                                </Form.Item>
                                <Space>&nbsp;hr&nbsp;</Space>

                                <Form.Item name="minute" noStyle
                                    rules={[{ required: true, message: 'Duration minute is required' }]}>
                                    <InputNumber min={0} max={59} step={1} defaultValue={minutes} placeholder="Minute" />
                                </Form.Item>
                                <Space>&nbsp;min </Space>

                                <Button onClick={calculateDuration}>Calculate</Button>
                            </Input.Group> :

                            <Input.Group>
                                <Form.Item name="day" noStyle
                                    rules={[{ required: true, message: 'Duration day is required' }]}>
                                    <InputNumber min={0} max={31} step={1} defaultValue={days} placeholder="Day" style={{width: OTHERSYSPARAM("INPUT_NUMBER_WIDTH_MOBILE")}}/>
                                </Form.Item>
                                <Space>&nbsp;d&nbsp;</Space>

                                <Form.Item name="hour" noStyle
                                    rules={[{ required: true, message: 'Duration hour is required' }]}>
                                    <InputNumber min={0} max={23} step={1} defaultValue={hours} placeholder="Hour" style={{width: OTHERSYSPARAM("INPUT_NUMBER_WIDTH_MOBILE")}}/>
                                </Form.Item>
                                <Space>&nbsp;hr&nbsp;</Space>

                                <Form.Item name="minute" noStyle
                                    rules={[{ required: true, message: 'Duration minute is required' }]}>
                                    <InputNumber min={0} max={59} step={1} defaultValue={minutes} placeholder="Minute" style={{width: OTHERSYSPARAM("INPUT_NUMBER_WIDTH_MOBILE")}}/>
                                </Form.Item>
                                <Space>&nbsp;min&nbsp;</Space>

                                <Button onClick={calculateDuration}>Calculate</Button>
                            </Input.Group>
                        }
                    </Form.Item>

                    <Form.Item name="fertilizationType" label="Fertilization Type"
                        rules={[
                            { required: true, validator: validateFertilizationType },
                        ]}>
                        <FertilizationTypeSelect onChange={setFertilizationTypeId} defaultValue={urlParams.get("fertilizationType")}/>
                    </Form.Item>

                    <Form.Item name="remark" label="Observation" >
                        <TextArea rows={3} />
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} style={{display: hideButton}} icon={<SaveOutlined/>}>Update</Button>
                            <Popconfirm title="Delete spawning record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} style={{display: hideButton}} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" disabled={disableButton} onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                    title="Spawning:"
                    subTitle="Update spawning record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default SpawningUpdate