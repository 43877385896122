import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./Login"
import StorageMain from "../Storage/StorageMain"
import StorageNew from "../Storage/StorageNew"
import StorageUpdate from "../Storage/StorageUpdate"
import BatchMain from "../Batch/BatchMain"
import BatchNew from "../Batch/BatchNew"
import BatchUpdate from "../Batch/BatchUpdate"
import FishMain from "../TaggedFish/FishMain"
import FishUpdate from "../TaggedFish/FishUpdate"
import FishSourceBatch from "../Inventory/FishSourceBatch"
import FishStorage from "../Inventory/FishStorage"
import TransferFish from "../Inventory/TransferFish"
import TransferFishStorage from "../Inventory/TransferFishStorage"
import TransferCollectiveFishSource from "../Inventory/TransferCollectiveFishSource"
import TransferCollectiveFishDestination from "../Inventory/TransferCollectiveFishDestination"
import CollectiveFishStockTransactionDestinationStorage from "../Inventory/CollectiveFishStockTransactionDestinationStorage"
import CollectiveFishStockIn from "../Inventory/CollectiveFishStockIn"
import CollectiveFishStockOut from "../Inventory/CollectiveFishStockOut"
import FishStockOut from "../Inventory/FishStockOut"
import Mortality from "../Mortality/Mortality"
import MortalityNew from "../Mortality/MortalityNew"
import MortalityMain from "../Mortality/MortalityMain"
import MortalityUpdate from "../Mortality/MortalityUpdate"
import EnvironmentMain from "../Environment/EnvironmentMain"
import EnvironmentNew from "../Environment/EnvironmentNew"
import EggDevelopmentMain from "../EggDevelopment/EggDevelopmentMain"
import EggDevelopmentNew from "../EggDevelopment/EggDevelopmentNew"
import EggDevelopmentStorage from "../EggDevelopment/EggDevelopmentStorage"
import EggDevelopmentUpdate from "../EggDevelopment/EggDevelopmentUpdate"
import SpawningMain from "../Spawning/SpawningMain"
import SpawningStorage from "../Spawning/SpawningStorage"
import SpawningNew from "../Spawning/SpawningNew"
import SpawningUpdate from "../Spawning/SpawningUpdate"
import HatchingMain from "../Hatching/HatchingMain"
import HatchingStorage from "../Hatching/HatchingStorage"
import HatchingNew from "../Hatching/HatchingNew"
import CollectiveFishStockTransactionSourceStorage from "../Inventory/CollectiveFishStockTransactionSourceStorage"
import { MENUPATH_BATCH, MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE, MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE, MENUPATH_EGGDEV, 
    MENUPATH_ENVIRONMENT, MENUPATH_FEEDING, MENUPATH_FISH, MENUPATH_GROWTHDEV, MENUPATH_GROWTHDEVELOPMENTHISTORY, MENUPATH_HATCHING, MENUPATH_INVENTORYSUMMARY, MENUPATH_INVENTORYSUMMARYV2, MENUPATH_MOONPHASESUPLOAD, MENUPATH_MORTALITY, MENUPATH_NEWTAG, 
    MENUPATH_OTHERSYSPARAM, MENUPATH_SALESSUMMARY, MENUPATH_PURCHASESSUMMARY, MENUPATH_SPAWNING, MENUPATH_STANDARDGROWTHTABLE, MENUPATH_STOCKCOUNT, MENUPATH_STOCKCOUNTREPORT, MENUPATH_STOCKTRANSACTION, 
    MENUPATH_STORAGE, MENUPATH_TRANSFERCOLLECTIVEFISHSOURCE, MENUPATH_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE, MENUPATH_FEEDINGSUMMARY, MENUPATH_TRANSFERSUMMARY, MENUPATH_INVENTORYFORECAST, 
    MENUPATH_INVENTORYFORECASTING, MENUPATH_DASHBOARD, MENUPATH_BATCHGENEALOGY, MENUPATH_SENSORSTORAGEMAP, MENUPATH_STOCKCOUNTVIDEO, MENUPATH_ENVDATAHISTORY, MENUPATH_CENTRALISEDCONSOLE, MENUPATH_DRONEVIEWDASHBOARD, MENUPATH_STOCKCOUNTVIDEOAUTOSTITCH, MENUPATH_LOCATION, MENUPATH_SITE, MENUPATH_SPECIES, MENUPATH_CHANGEPASSWORD, MENUPATH_USER, MENUPATH_HATCHINGCRAB, MENUPATH_AMENDSTOCKTRANSACTIONRECORD, MENUPATH_INVENTORYFORECAST2, MENUPATH_STANDARDGROWTHTABLE2, MENUPATH_CENTRALISEDCONSOLEWATERQUALITY, MENUPATH_PENDINGSTOCKTRANSACTION, 
    MENUPATH_FEEDINGPLAN} from "./SystemParameter"
import HatchingUpdate from "../Hatching/HatchingUpdate"
import FeedingMain from "../Feeding/FeedingMain"
import FeedingNew from "../Feeding/FeedingNew"
import FeedingStorage from "../Feeding/FeedingStorage"
import FeedingUpdate from "../Feeding/FeedingUpdate"
import FeedingPlanMain from "../Feeding/FeedingPlanMain"
import GrowthDevelopmentStorage from "../GrowthDevelopment/GrowthDevelopmentStorage"
import GrowthDevelopmentFish from "../GrowthDevelopment/GrowthDevelopmentFish"
import GrowthDevelopmentNew from "../GrowthDevelopment/GrowthDevelopmentNew"
import GrowthDevelopmentUpdate from "../GrowthDevelopment/GrowthDevelopmentUpdate"
import GrowthDevelopmentDailyAverageByBatch from "../GrowthDevelopment/GrowthDevelopmentDailyAverageByBatch"
import StockCountMain from "../StockCount/StockCountMain"
import StockCountStorage from "../StockCount/StockCountStorage"
import StockCountEgg from "../StockCount/StockCountEgg"
import StockCountUntaggedFish from "../StockCount/StockCountUntaggedFish"
import StockCountTaggedFish from "../StockCount/StockCountTaggedFish"
import StockCountTaggedFishVarianceAdjustment from "../StockCount/StockCountTaggedFishVarianceAdjustment"
import StockCountTaggedFishSurplus from "../StockCount/StockCountTaggedFishSurplus"
import StockCountSummary from "../StockCount/StockCountSummary"
import StandardGrowthNew from "../StandardGrowth/StandardGrowthNew"
import StandardGrowthUpdate from "../StandardGrowth/StandardGrowthUpdate"
import StandardGrowth from "../StandardGrowth/StandardGrowth"
import PhysicalTagging from "../PhysicalTagging/PhysicalTagging"
import PhysicalTaggingFish from "../PhysicalTagging/PhysicalTaggingFish"
import StandardGrowthMain from "../StandardGrowth/StandardGrowthMain"
import StandardGrowthTableNew from "../StandardGrowth/StandardGrowthTableNew"
import StandardGrowthTableArchive from "../StandardGrowth/StandardGrowthTableArchive"
import StockPreCountSummary from "../StockCount/StockPreCountSummary"
import MoonPhasesUploadMain from "../Miscellaneous/MoonPhasesUploadMain"
import MortalityTaggedFishNew from "../Mortality/MortalityTaggedFishNew"
import OtherSystemParameterMain from "../Miscellaneous/OtherSystemParameterMain"
import GrowthDevelopmentDailyAverageByStorage from "../GrowthDevelopment/GrowthDevelopmentDailyAverageByStorage"
import StockTransactionHistoryMain from "../Report/StockTransactionHistoryMain"
import FishStorageWithoutSourceBatch from "../Inventory/FishStorageWithoutSourceBatch"
import GrowthDevelopmentHistoryMain from "../Report/GrowthDevelopmentHistoryMain"
import StockCountReportMain from "../Report/StockCountReportMain"
import InventorySummaryMain from "../Report/InventorySummaryMain"
import InventorySummaryMainV2 from "../Report/InventorySummaryV2Main"
import GrowthDevelopmentTable from "../GrowthDevelopment/GrowthDevelopmentTable"
import SalesPurchasesSummaryMain from "../Report/SalesPurchasesSummaryMain"
import FeedingSummaryMain from "../Report/FeedingSummaryMain"
import TransferSummaryMain from "../Report/TransferSummaryMain"
import InventoryForecastMain from "../Report/InventoryForecastMain"
import InventoryForecastDetailMain from "../Report/InventoryForecastDetailMain"
import InventoryForecastingMain from "../Miscellaneous/InventoryForecastingMain"
import InventoryForecastBatchStorageMain from "../Report/InventoryForecastBatchStorageMain"
import InventoryChartBySpeciesMain from "../Dashboard/InventoryChartBySpeciesMain"
import InventoryChartByBatchStorageMain from "../Dashboard/InventoryChartByBatchStorageMain"
import BatchGenealogyBySpeciesMain from "../Analytics/BatchGenealogyBySpeciesMain"
import InventoryChartBySpeciesWeightRangeMain from "../Dashboard/InventoryChartBySpeciesWeightRangeMain"
import SensorStorageMapMain from "../Miscellaneous/SensorStorageMapMain"
import StockCountVideoMain from "../StockCount/StockCountVideoMain"
import EnvironmentDataHistoryMain from "../Report/EnvironmentDataHistoryMain"
import CentralisedConsoleMain from "../Dashboard/CentralisedConsoleMain"
import CentralisedConsoleGraphTable from "../Dashboard/CentralisedConsoleGraphTable"
import DroneViewPowerBIDashboardMain from "../Dashboard/DroneViewPowerBIDashboardMain"
import StockCountVideoAutoStitchMain from "../StockCount/StockCountVideoAutoStitchMain"
import LocationMain from "../Setup/LocationMain"
import LocationNew from "../Setup/LocationNew"
import LocationUpdate from "../Setup/LocationUpdate"
import SiteMain from "../Setup/SiteMain"
import SiteNew from "../Setup/SiteNew"
import SiteUpdate from "../Setup/SiteUpdate"
import SpeciesMain from "../Setup/SpeciesMain"
import SpeciesNew from "../Setup/SpeciesNew"
import SpeciesUpdate from "../Setup/SpeciesUpdate"
import ChangePassword from "../Setup/ChangePassword"
import UserMain from "../Setup/UserMain"
import UserNew from "../Setup/UserNew"
import UserUpdate from "../Setup/UserUpdate"
import HatchingNewCrab from "../Hatching/HatchingNewCrab"
import HatchingMainCrab from "../Hatching/HatchingMainCrab"
import HatchingUpdateCrab from "../Hatching/HatchingUpdateCrab"
import AmendedStockTransactionMain from "../Miscellaneous/AmendedStockTransactionMain"
import AmendStockTransactionNew from "../Miscellaneous/AmendStockTransactionNew"
import InventoryForecastMainV2 from "../Report/InventoryForecastMainV2"
import StandardGrowthMain2 from "../StandardGrowth2/StandardGrowthMain2"
import StandardGrowthNewTable2 from "../StandardGrowth2/StandardGrowthNewTable2"
import StandardGrowthUpdate2 from "../StandardGrowth2/StandardGrowthUpdate2"
import CentralisedConsoleWaterQualityMain from "../Dashboard/CentralisedConsoleWaterQualityMain"
import PendingStockTransaction from "../Inventory/PendingStockTransaction"


const ACISRouter = () => (
    <BrowserRouter>
        <Routes>
            <Route index path="/" element={<Login />} />
            {/* <Route path="/home" element={<Home />} /> */}
            <Route path={MENUPATH_DASHBOARD} element={<InventoryChartBySpeciesMain />} />
            <Route path="/dashboardspeciesweightrange" element={<InventoryChartBySpeciesWeightRangeMain />} />
            <Route path="/dashboardbatchstorage" element={<InventoryChartByBatchStorageMain />} />

            <Route path={MENUPATH_DRONEVIEWDASHBOARD} element={<DroneViewPowerBIDashboardMain />} />

            <Route path={MENUPATH_CENTRALISEDCONSOLE} element={<CentralisedConsoleMain />} />
            <Route path={"/centralisedconsolegraph"} element={<CentralisedConsoleGraphTable />} />
            <Route path={MENUPATH_CENTRALISEDCONSOLEWATERQUALITY} element={<CentralisedConsoleWaterQualityMain />} />
            <Route path={"/centralisedconsolegraph"} element={<CentralisedConsoleGraphTable />} />

            <Route path={MENUPATH_STORAGE} element={<StorageMain />} />
            <Route path="/storagenew" element={<StorageNew />} />
            <Route path="/storageupdate" element={<StorageUpdate />} />

            <Route path={MENUPATH_BATCH} element={<BatchMain />} />
            <Route path="/batchnew" element={<BatchNew />} />
            <Route path="/batchupdate" element={<BatchUpdate />} />
            
            <Route path={MENUPATH_FISH} element={<FishMain />} />
            <Route path={MENUPATH_NEWTAG} element={<FishSourceBatch />} />
            <Route path="/fishstorage" element={<FishStorage />} />
            <Route path="/fishstoragewithoutsourcebatch" element={<FishStorageWithoutSourceBatch />} />
            <Route path="/fishupdate" element={<FishUpdate />} />
            <Route path="/fishstockout" element={<FishStockOut />} />

            <Route path="/physicaltagging" element={<PhysicalTagging />} />
            <Route path="/physicaltaggingfish" element={<PhysicalTaggingFish />} />
                    
            <Route path={MENUPATH_STANDARDGROWTHTABLE} element={<StandardGrowthMain />} />
            <Route path={"/standardgrowthtablenew"} element={<StandardGrowthTableNew />} />
            <Route path={"/standardgrowth"} element={<StandardGrowth />} />
            <Route path={"/standardgrowthnew"} element={<StandardGrowthNew />} />
            <Route path={"/standardgrowthupdate"} element={<StandardGrowthUpdate />} />
            <Route path={"/standardgrowthtablearchive"} element={<StandardGrowthTableArchive />} />

            <Route path={MENUPATH_STANDARDGROWTHTABLE2} element={<StandardGrowthMain2 />} />
            <Route path={"/standardgrowthnew2"} element={<StandardGrowthNewTable2 />} />
            <Route path={"/standardgrowthupdate2"} element={<StandardGrowthUpdate2 />} />
            
            <Route path={MENUPATH_MOONPHASESUPLOAD} element={<MoonPhasesUploadMain />} />
            <Route path={MENUPATH_OTHERSYSPARAM} element={<OtherSystemParameterMain />} />
            <Route path={MENUPATH_INVENTORYFORECASTING} element={<InventoryForecastingMain />} />

            <Route path="/transferfish" element={<TransferFish />} />
            <Route path="/transferfishintersite" element={<TransferFish />} />
            <Route path="/transferfishstorage" element={<TransferFishStorage />} />

            <Route path={MENUPATH_TRANSFERCOLLECTIVEFISHSOURCE} element={<TransferCollectiveFishSource />} />
            <Route path={MENUPATH_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE} element={<TransferCollectiveFishSource/>} />
            <Route path="/transfercollectivefishdestination" element={<TransferCollectiveFishDestination/>} />
            
            <Route path="/collectivefishstockin" element={<CollectiveFishStockIn />} />
            <Route path="/collectivefishstockout" element={<CollectiveFishStockOut/>} />
            <Route path={MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE} element={<CollectiveFishStockTransactionDestinationStorage />} />
            <Route path={MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE} element={<CollectiveFishStockTransactionSourceStorage />} />

            <Route path={MENUPATH_PENDINGSTOCKTRANSACTION} element={<PendingStockTransaction />} />
            
            <Route path={MENUPATH_MORTALITY} element={<MortalityMain />} />
            <Route path="/mortality" element={<Mortality />} />
            <Route path="/mortalitynew" element={<MortalityNew />} />
            <Route path="/mortalityupdate" element={<MortalityUpdate />} />
            <Route path="/mortalitytaggedfishnew" element={<MortalityTaggedFishNew />} />
            
            <Route path={MENUPATH_ENVIRONMENT} element={<EnvironmentMain />} />
            <Route path="/environmentnew" element={<EnvironmentNew />} />

            <Route path={MENUPATH_SENSORSTORAGEMAP} element={<SensorStorageMapMain />} />

            <Route path={MENUPATH_EGGDEV} element={<EggDevelopmentMain />} />
            <Route path="/eggdevelopmentstorage" element={<EggDevelopmentStorage />} />
            <Route path="/eggdevelopmentnew" element={<EggDevelopmentNew />} />
            <Route path="/eggdevelopmentupdate" element={<EggDevelopmentUpdate />} />

            <Route path={MENUPATH_SPAWNING} element={<SpawningMain />} />
            <Route path="/spawningstorage" element={<SpawningStorage />} />
            <Route path="/spawningnew" element={<SpawningNew />} />
            <Route path="/spawningupdate" element={<SpawningUpdate />} />

            <Route path={MENUPATH_HATCHING} element={<HatchingMain />} />
            <Route path={MENUPATH_HATCHINGCRAB} element={<HatchingMainCrab />} />
            <Route path="/hatchingstorage" element={<HatchingStorage />} />
            <Route path="/hatchingnew" element={<HatchingNew />} />
            <Route path="/hatchingupdate" element={<HatchingUpdate />} />
            <Route path="/hatchingnewcrab" element={<HatchingNewCrab />} />
            <Route path="/hatchingupdatecrab" element={<HatchingUpdateCrab />} />

            <Route path={MENUPATH_FEEDING} element={<FeedingMain />} />
            <Route path="/feedingstorage" element={<FeedingStorage />} />
            <Route path="/feedingnew" element={<FeedingNew />} />
            <Route path="/feedingupdate" element={<FeedingUpdate />} />
            <Route path={MENUPATH_FEEDINGPLAN} element={<FeedingPlanMain />} />

            <Route path={MENUPATH_GROWTHDEV} element={<GrowthDevelopmentDailyAverageByBatch />} />
            <Route path={"/growthdevelopmentdailyaveragebystorage"} element={<GrowthDevelopmentDailyAverageByStorage />} />
            <Route path="/growthdevelopmenttable" element={<GrowthDevelopmentTable />} />
            <Route path="/growthdevelopmentstorage" element={<GrowthDevelopmentStorage />} />
            <Route path="/growthdevelopmentfish" element={<GrowthDevelopmentFish />} />
            <Route path="/growthdevelopmentnew" element={<GrowthDevelopmentNew />} />
            <Route path="/growthdevelopmentupdate" element={<GrowthDevelopmentUpdate />} />

            <Route path={MENUPATH_STOCKCOUNT} element={<StockCountMain />} />
            <Route path={"/stockcountstorage"} element={<StockCountStorage />} />
            <Route path={"/stockcountegg"} element={<StockCountEgg />} />
            <Route path={"/stockcountuntaggedfish"} element={<StockCountUntaggedFish />} />
            <Route path={"/stockcountuntagged"} element={<StockCountUntaggedFish />} />
            <Route path={"/stockcounttaggedfish"} element={<StockCountTaggedFish />} />
            <Route path={"/stockcounttaggedfishvarianceadjustment"} element={<StockCountTaggedFishVarianceAdjustment />} />
            <Route path={"/stockcounttaggedfishsurplus"} element={<StockCountTaggedFishSurplus />} />
            <Route path={"/stockcountsummary"} element={<StockCountSummary />} />
            <Route path={"/stockprecountsummary"} element={<StockPreCountSummary />} />
            <Route path={MENUPATH_STOCKCOUNTVIDEO} element={<StockCountVideoMain />} />
            <Route path={MENUPATH_STOCKCOUNTVIDEOAUTOSTITCH} element={<StockCountVideoAutoStitchMain />} />

            <Route path={MENUPATH_GROWTHDEVELOPMENTHISTORY} element={<GrowthDevelopmentHistoryMain />} />
            
            <Route path={MENUPATH_INVENTORYFORECAST} element={<InventoryForecastMain />} />
            <Route path={"/inventoryforecastbatchstorage"} element={<InventoryForecastBatchStorageMain />} />
            <Route path={"/inventoryforecastdetail"} element={<InventoryForecastDetailMain />} />
            <Route path={MENUPATH_INVENTORYFORECAST2} element={<InventoryForecastMainV2 />} />
            
            <Route path={MENUPATH_INVENTORYSUMMARY} element={<InventorySummaryMain />} />
            <Route path={MENUPATH_INVENTORYSUMMARYV2} element={<InventorySummaryMainV2 />} />
            <Route path={MENUPATH_STOCKTRANSACTION} element={<StockTransactionHistoryMain />} />
            <Route path={MENUPATH_STOCKCOUNTREPORT} element={<StockCountReportMain />} />
            
            <Route path={MENUPATH_SALESSUMMARY} element={<SalesPurchasesSummaryMain />} />
            <Route path={MENUPATH_PURCHASESSUMMARY} element={<SalesPurchasesSummaryMain />} />
            <Route path={MENUPATH_FEEDINGSUMMARY} element={<FeedingSummaryMain />} />
            <Route path={MENUPATH_TRANSFERSUMMARY} element={<TransferSummaryMain />} />
            <Route path={MENUPATH_ENVDATAHISTORY} element={<EnvironmentDataHistoryMain />} />

            <Route path={MENUPATH_BATCHGENEALOGY} element={<BatchGenealogyBySpeciesMain />} />

            <Route path={MENUPATH_LOCATION} element={<LocationMain />} />
            <Route path="/locationnew" element={<LocationNew />} />
            <Route path="/locationupdate" element={<LocationUpdate />} />

            <Route path={MENUPATH_SITE} element={<SiteMain />} />
            <Route path="/sitenew" element={<SiteNew />} />
            <Route path="/siteupdate" element={<SiteUpdate />} />

            <Route path={MENUPATH_SPECIES} element={<SpeciesMain />} />
            <Route path="/speciesnew" element={<SpeciesNew />} />
            <Route path="/speciesupdate" element={<SpeciesUpdate />} /> 

            <Route path={MENUPATH_CHANGEPASSWORD} element={<ChangePassword />} /> 
            <Route path={MENUPATH_USER} element={<UserMain />} />
            <Route path="/usernew" element={<UserNew />} /> 
            <Route path="/userupdate" element={<UserUpdate />} /> 

            <Route path={MENUPATH_AMENDSTOCKTRANSACTIONRECORD} element={<AmendedStockTransactionMain />} /> 
            <Route path={"/amendstocktransactionrecordnew"} element={<AmendStockTransactionNew />} /> 
        </Routes>
    </BrowserRouter>
)

export default ACISRouter