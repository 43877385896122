import { Table, Row, Col, Space, Button, Form, Collapse, message, Modal, Popconfirm, InputNumber, Card, Typography, Input, Pagination, DatePicker, Spin } from 'antd'
import { LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, DATETIMEFORMAT, EGGS, KG, LOADING, MEDIAMAXWIDTH, MENUPATH_TRANSFERCOLLECTIVEFISHSOURCE, MENUPATH_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE, PAGESIZE, PAGINATIONSIZE, PERCENT, UNIDATEFORMAT, UNIT, UNTAGGED } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { buildMismatchedCriteriaString, calculateWeightVariancePercentage, numberWithCommas, reportError, storageCapacityCheck } from '../Common/Utility'
import { formLayout, formLayout_2Columns } from '../Common/Layout'
import AcquiringMethodSelect from '../Common/AcquiringMethodSelect'
import BatchTypeSelect from '../Common/BatchTypeSelect'
import { useMediaQuery } from 'react-responsive'
import { showStorageStockSummary } from '../Common/showStorageStockSummary'
import { releaseStorage } from '../Common/releaseStorage'
import ItemTypeUnitOfMeasurementSelect from '../Common/ItemTypeUnitOfMeasurementSelect'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'
import moment from 'moment'

const { Panel } = Collapse
const { confirm } = Modal
const { Title } = Typography

//----------
// Component
//----------
const TransferCollectiveFishDestinationStorage = ({interSite}) => {
    const EXISTING = 0
    const NEW = 1
    const SOURCE = 2

    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()
    const [record, setRecord] = useState(null)
    const navigate = useNavigate()

    const [searchSiteId, setSearchSiteId] = useState(interSite ? -1 : getUserSiteId())
    const [searchStorageId, setSearchStorageId] = useState(0)
    const [searchBatchId, setSearchBatchId] = useState(0)
    const [searchBatchTypeId, setSearchBatchTypeId] = useState(0)
    const [searchAcquiringMethodId, setSearchAcquiringMethodId] = useState(0)
    const [searchLifecycleId, setSearchLifecycleId] = useState(0)
    const [searchAquacultureStageId, setSearchAquacultureStageId] = useState(0)
    const [searchMarineLifeId, setSearchMarineLifeId] = useState(0)
    const [searchSpeciesId, setSearchSpeciesId] = useState(0)

    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isSearching, setIsSearching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [panelKey, setPanelKey] = useState(interSite ? "1" : "0")
    const [stockTransferDateTime, setStockTransferDateTime] = useState(moment(moment(), UNIDATEFORMAT))
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    
    const [itemTypeUomId, setItemTypeUomId] = useState(0)
    const [itemTypeUom, setItemTypeUom] = useState("")

    //-----------------------------------------------------------------------------
    // Create stock transfer to existing batch, with new batch or with source batch
    //-----------------------------------------------------------------------------
    const createStockTransfer = (mode) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        //-------------------------------------------------------------------------------------------------
        // 20220114
        // If item type uom of Kg is selected, need to convert quantity of Kg back to rounded up tail/unit.
        //-------------------------------------------------------------------------------------------------
        let quantity = form.getFieldValue("quantity")
        if(!itemTypeUom.includes(KG)) 
            quantity = Math.ceil(quantity)
        else
            quantity = Math.ceil(quantity * 1000 / parseFloat(urlParams.get("averageWeight")))
                
        //------------------------------
        // Select which mode of transfer
        //------------------------------
        let subURL = ""
        if(mode == EXISTING) 
            subURL = "create"
        else if(mode == NEW)
            subURL = "createwithnewbatch"
        else if(mode == SOURCE)
            subURL = "createwithsourcebatch"

        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}stocktransfer/${subURL}/`, {
                source_batch: urlParams.get("sourceBatchId"),
                source_storage: urlParams.get("sourceStorageId"),
                destination_batch: record.batchId,
                destination_storage: record.pKey,
                quantity: quantity, 
                item_type_uom: itemTypeUomId,
                item_type: urlParams.get("itemTypeId"),
                item_type_name: urlParams.get("itemType"),
                transactedOn: stockTransferDateTime,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Stock transfer was successful.")

                let pathname = MENUPATH_TRANSFERCOLLECTIVEFISHSOURCE
                if(interSite) pathname= MENUPATH_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE

                navigate({ 
                    pathname: pathname
                })

                releaseStorage(urlParams.get("sourceBatchId"), urlParams.get("sourceBatch"), urlParams.get("sourceStorageId"))
            })
            .catch( error => {
                reportError(error, `Failed to transfer stock.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //---------------
    // Search storage
    //---------------
    const searchStorage = (currentPage) => {
        if(interSite && searchSiteId == getUserSiteId()) {
            message.info("Please select site before proceed.")
            return
        }

        setDisableButton("disabled")
        setIsLoading(true)
        
        // If search by storage id, in order to show empty storage, will not be filtered by species.
        if(searchStorageId != 0) {
            message.info("Search by Storage ID would disable species filter.")
        }

        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: { 
                site: searchSiteId,
                marine_life: searchMarineLifeId,
                species: searchStorageId != 0 ? 0 : searchSpeciesId,
                batch: searchBatchId,
                id: searchStorageId,
                acquiring_method: searchAcquiringMethodId,
                aquaculture_stage: searchAquacultureStageId,
                lifecycle: searchLifecycleId,
                batch_type: searchBatchTypeId, 
                exclude: urlParams.get("sourceStorageId"),
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------
    // Load storage
    //-------------
    const getCurrentSiteStorage = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: { 
                site: getUserSiteId(),
                species: urlParams.get("speciesId"), 
                aquaculture_stage: urlParams.get("aquacultureStageId"), 
                lifecycle: urlParams.get("lifecycleId"), 
                batch_type: urlParams.get("batchTypeId"),
                //acquiring_method: urlParams.get("acquiringMethodId"), 
                //free: true,
                exclude: urlParams.get("sourceStorageId"),
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        let data = []
        response.data.results.forEach( storage => {
            data.push({
                key: storage.id,
                pKey: storage.pKey,
                id: storage.name,
                storageType: storage.storage_type_data.name,
                batchId: (storage?.batch_data == null) ? 0 : storage.batch_data.pKey,
                batch: (storage?.batch_data == null) ? "" : storage.batch_data.id,
                acquiringMethod: storage?.batch_data?.acquiring_method_data == null ? "" : storage.batch_data.acquiring_method_data.name,
                acquiringMethodId: storage?.batch_data?.acquiring_method_data == null ? 0 : storage.batch_data.acquiring_method_data.pKey,
                lifecycle: storage?.batch_data?.lifecycle_data == null ? "" : storage.batch_data.lifecycle_data.name,
                lifecycleId: storage?.batch_data?.lifecycle_data == null ? 0 : storage.batch_data.lifecycle_data.pKey,
                batchType: storage?.batch_data?.batch_type_data?.name == null ? "" : storage.batch_data.batch_type_data.name,
                batchTypeId: storage?.batch_data?.batch_type_data?.pKey == null ? 0 : storage.batch_data.batch_type_data.pKey,
                speciesId: storage?.batch_data?.species_data?.pKey == null ? 0 : storage.batch_data.species_data.pKey,
                aquacultureStage: storage?.batch_data?.aquaculture_stage_data.name == null ? "" : storage.batch_data.aquaculture_stage_data.name,
                batchAverageWeight: storage?.batch_data?.average_per_unit_weight_gram == null ? 0 : storage.batch_data.average_per_unit_weight_gram,
                batchAverageLength: storage?.batch_data?.average_per_unit_length_mm == null ? 0 : storage.batch_data.average_per_unit_length_mm,
                ageDays: storage?.batch_data?.age_days == null ? 0 : storage.batch_data.age_days,
                
                batchData: storage?.batch_data != null ? [storage.batch_data] : [],
                status: storage.status_data,
            })
        })

        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        if(!isSearching)
            getCurrentSiteStorage(page)
        else
            searchStorage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        if(searchSiteId == -1) {
            message.info("Please select a site before proceed.")
            return
        }

        setIsSearching(true)
        setCurrentPage(1)
        searchStorage(1)
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                setRecord(record)
                form.submit()
            }
        }
    }
    
    //---------------------------
    // On acquiring method change
    //---------------------------
    const onAcquiringMethodChange = (e, value) => {
        setSearchAcquiringMethodId(e)
    }

    //---------------------
    // On batch type change
    //---------------------
    const onBatchTypeChange = (e, value) => {
        setSearchBatchTypeId(e)
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setStockTransferDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateStockTransferDateTime = (() => {
        if(stockTransferDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Stock transfer date time is required."))
    })

    //------------------
    // Go to create page
    //------------------
    const onSave = () => {
        switch(record.batchId != 0) {
            case true: // Storage has batch assigned.
                // Cannot mix species in a batch.
                if(urlParams.has("speciesId") && record.speciesId != 0 && urlParams.get("speciesId") != record.speciesId) {
                    message.warn("Mixing of species is not allowed.")
                    return
                }

                let mismatchedCriteria = buildMismatchedCriteriaString(urlParams.get("batchTypeId"), record.batchTypeId,
                    urlParams.get("acquiringMethodId"), record.acquiringMethodId, urlParams.get("lifecycleId"), record.lifecycleId)
                    
                if(mismatchedCriteria != "") {
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>{mismatchedCriteria} do not match. Proceed to transfer stock?</p></Space>,
                        onOk() { createStockTransfer(EXISTING) },
                        onCancel() {},
                        centered: true
                    })
                }
                else
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>{`Transfer stock confirmed?`}</p></Space>,
                        onOk() { createStockTransfer(EXISTING) },
                        onCancel() {},
                        centered: true
                    })
                break
            case false: // Storage has no batch assigned.
                if(!interSite) 
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Assign existing batch {urlParams.get("sourceBatch")} to storage {record.id}?</p></Space>,
                        onOk() { createStockTransfer(SOURCE) },
                        onCancel() {
                            confirm({
                                icon: <QuestionCircleOutlined />,
                                content: <Space><p>Selected storage has no batch assigned. Create a new batch for the storage and transfer stock?</p></Space>,
                                onOk() { createStockTransfer(NEW) },
                                onCancel() {},
                                okText: OTHERSYSPARAM("YES"),
                                cancelText: OTHERSYSPARAM("NO"),
                                centered: true
                            })
                        },
                        okText: OTHERSYSPARAM("YES"),
                        cancelText: OTHERSYSPARAM("NO"),
                        centered: true
                    })
                else
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Selected storage has no batch assigned. Create a new batch for the storage and transfer stock?</p></Space>,
                        onOk() { createStockTransfer(NEW) },
                        onCancel() {},
                        okText: OTHERSYSPARAM("YES"),
                        cancelText: OTHERSYSPARAM("NO"),
                        centered: true
                    })
                break
        }
    }

    //-------------------
    // Check overcrowding
    //-------------------
    const checkOvercrowd = async () => {
        // Check for overcrowding by weight in Kg (untagged only, not egg).
        if(urlParams.get("itemType").startsWith(UNTAGGED)) {
            let weightToAddKg = 0
        
            if(itemTypeUom.includes(PERCENT)) 
                weightToAddKg = urlParams.get("averageWeight") * form.getFieldValue("quantity") * urlParams.get("availableQuantity") / 100 / 1000
            else 
                weightToAddKg = urlParams.get("averageWeight") * form.getFieldValue("quantity") / 1000

            try{
                const prompt = await storageCapacityCheck(urlParams.get("marineLifeId"), record.batchId, record.pKey, weightToAddKg)

                if(prompt)
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Overcrowding will happen after transfer. Procced?</p></Space>,
                        onOk() { onSave() },
                        onCancel() {},
                        okText: OTHERSYSPARAM("YES"),
                        cancelText: OTHERSYSPARAM("NO"),
                        centered: true
                    })
                else
                    onSave()
            }
            catch(error) {
                reportError(error, "Failed to get marine life storage type capacity information.")
            }
        }
        else 
            onSave()
    }

    //----------------------
    // Check weight variance
    //----------------------
    const checkWeightVariance = () => {
        let threshold = parseFloat(OTHERSYSPARAM("WEIGHT_VARIANCE_THRESHOLD_PERCENT")) / 100

        // Check average weight variance. Prompt if variance is greater than predefined percentage threshold.
        if(parseFloat(record.batchAverageWeight) != 0 && parseFloat(urlParams.get("averageWeight")) != 0) {
            let weightDiff = calculateWeightVariancePercentage(parseFloat(record.batchAverageWeight), parseFloat(urlParams.get("averageWeight")))
                                        
            if(weightDiff >= threshold) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Weight variance between the two batches exceeded by {(threshold * 100).toFixed(2)}%. Proceed to stock-in?</p></Space>,
                    onOk() { checkOvercrowd() },
                    onCancel() {},
                    centered: true
                })
            }
            else
                checkOvercrowd()
        }
        else
            checkOvercrowd()
    }

    //--------------------------------
    // Check quantity, item type + uom
    //--------------------------------
    const checkQuantityItemTypeUom = () => {
        form.validateFields()
        .then( values => {
            // if(itemTypeUomId == SYSPARAM("EGG_UNIT") && parseInt(values.quantity) < OTHERSYSPARAM("1_ML_EGG_IN_UNIT") / 2) {
            //     message.warn(`At least ${OTHERSYSPARAM("1_ML_EGG_IN_UNIT") / 2} unit of eggs is needed to make up eggs of 1 mL`)
            //     return
            // }

            if(itemTypeUom.includes(PERCENT)) {
                // If percentage is selected. It can't be more than 100.
                if(parseInt(values.quantity) > 100) {
                    message.warn("Transfer quantity cannot exceed 100 percent.")
                    return
                }
                checkWeightVariance()
            }
            else if(itemTypeUom.startsWith(EGGS) && itemTypeUom.includes(UNIT)) {
                // If unit is selected. It can't be more than its mL equivalent.
                // 1 mL = 1000 units.
                const availableUnit = parseInt(urlParams.get("availableQuantity")) * OTHERSYSPARAM(`1_ML_EGG_IN_UNIT_${urlParams.get("marineLifeId")}`)

                if(parseInt(values.quantity) > availableUnit) {
                    message.warn(`Transfer quantity cannot exceed ${availableUnit} unit.`)
                    return
                }
                checkWeightVariance()
            }
            else if(itemTypeUom.includes(KG)) {
                const totalAvailableWeightKg = parseFloat(urlParams.get("averageWeight")) * parseInt(urlParams.get("availableQuantity")) / 1000
                if(parseFloat(values.quantity) > totalAvailableWeightKg) {
                    message.warn(`Transfer weight cannot exceed ${totalAvailableWeightKg} KG.`)
                    return
                }
                checkWeightVariance()
            }
            else {
                // Untagged fish or egg-mL is selected.
                if(values.quantity > urlParams.get("availableQuantity")) {
                    message.warn("Transfer quantity exceeded available quantity.")
                    return
                }
                checkWeightVariance()
            }
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_TRANSFERCOLLECTIVEFISHSOURCE, 
            search: `?interSite=${interSite}`
        }) 
    }

    //----------------------
    // On search site change
    //----------------------
    const onSearchSiteChange = (e) => {
        setSearchSiteId(e)
    }

    //----------------------------------------
    // On item type unit of measurement change
    //----------------------------------------
    const onItemTypeUomChange = (e, value) => {
        setItemTypeUomId(e)
        setItemTypeUom(value.children)
    }

    //----------------------------
    // Redirect callback function
    //----------------------------
    const redirect = (pathname, search) => {
        navigate({
            pathname: pathname,
            search: search
        })
    }

    //----------------------------
    // Show storage stock quantity
    //----------------------------
    const showStoragePopup = (e, record) => {
        e.stopPropagation()
        record.key = record.pKey
        showStorageStockSummary(false, record, redirect)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(!interSite) {
            getCurrentSiteStorage()
        }
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        !isTabletOrMobile ? { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) } : {},
        !isTabletOrMobile ? { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) } : {},
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Status', align: 'center', //dataIndex: 'stockQuantity', key: 'stockQuantity', //sorter: (a, b) => a.quantity.stockQuantity(b.stockQuantity),
            render: (record) => {
                if(record.status == OTHERSYSPARAM("IS_OCCUPIED"))
                    return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => showStoragePopup(e, record)}>{OTHERSYSPARAM("IS_OCCUPIED")}</Button>
                else
                    return OTHERSYSPARAM("IS_EMPTY")
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form form={form} onFinish={checkQuantityItemTypeUom} {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Source Storage: ${urlParams.get("sourceStorage")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("sourceBatch")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                </Card>
            </Form.Item>

            <Form.Item name="stockTransferDateTime" label="Stock Transfer Date Time"
                rules={[
                    { required: true, validator: validateStockTransferDateTime },
                ]}>
                <DatePicker showTime onChange={onDateTimeChange} defaultValue={stockTransferDateTime} format={DATETIMEFORMAT}/>
            </Form.Item>

            <Form.Item label={`Note: Eggs will always be rounded up to the next integral mL.`}>
            </Form.Item>

            <Form.Item label={`Transfer Quantity Out of ${numberWithCommas(urlParams.get("availableQuantity"))} (${urlParams.get("itemType")} (${urlParams.get("uom")}))`}>
                <Input.Group>
                    <Form.Item name="quantity" noStyle
                        rules={[
                            { required: true, message: "Transfer quantity is required." },
                        ]}>
                        <InputNumber min={0.1} max={10000000} step={1} placeholder="Quantity"/>
                    </Form.Item>

                    <Form.Item name="itemTypeUom" noStyle
                        rules={[
                            { required: true, message: "Item type - Unit of Measurement is required." },
                        ]}>
                        <ItemTypeUnitOfMeasurementSelect withBlank={false} marineLifeId={urlParams.get("marineLifeId")} 
                            itemType={urlParams.get("itemType")} onChange={onItemTypeUomChange} />
                    </Form.Item>
                </Input.Group>
            </Form.Item>
        </Form>

        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={[panelKey]}>
                    <Panel header="Search Destination Storage" key="1">
                        <Form form={searchForm} {...formLayout_2Columns}>
                            <CommonSearchFormItem onSiteChange={interSite ? onSearchSiteChange : null} 
                                onMarineLifeChange={setSearchMarineLifeId} onSpeciesChange={setSearchSpeciesId}
                                onBatchChange={setSearchBatchId} 
                                onStorageChange={setSearchStorageId} onAquacultureStageChange={setSearchAquacultureStageId}
                                onLifecycleChange={setSearchLifecycleId} showLockedStorage={false} excludeOwnSite={true}
                                loadBatch={interSite ? false : true} loadStorage={interSite ? false : true} defaultSiteId={searchSiteId}/>

                            <Form.Item name="acquiringMethodId" label="Acquiring Method">
                                <AcquiringMethodSelect withBlank={true} onChange={onAcquiringMethodChange}/>
                            </Form.Item>

                            <Form.Item name="batchTypeId" label="Batch Type">
                                <BatchTypeSelect withBlank={true} onChange={onBatchTypeChange}/>
                            </Form.Item>
                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={24} style={{textAlign: "start"}}>
                <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                    <Button type="primary" htmlType="button" icon={<LeftOutlined />} disabled={disableButton}>Back</Button>
                </Popconfirm>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={storageDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}>
                <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                    <Button type="primary" htmlType="button" icon={<LeftOutlined />} disabled={disableButton}>Back</Button>
                </Popconfirm>
            </Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default TransferCollectiveFishDestinationStorage