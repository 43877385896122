import  { useEffect, useState } from 'react'
import { Button, Col, Row, Space, Form, Table, Card, Typography, message, Tag, Collapse, Modal, Input, Tooltip, Pagination, Spin } from "antd"
import { TagOutlined, LeftOutlined, PrinterOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { formLayout, formLayout_2Columns } from '../Common/Layout'
import axios from 'axios'
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MENUPATH_PHYSICALTAGGING, PAGESIZE, PAGINATIONSIZE } from '../Common/SystemParameter'
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { reportError } from '../Common/Utility'
import { useNavigate } from 'react-router-dom'
import moment from "moment"
import TrueFalseSelect from '../Common/TrueFalseSelect'
import { generatePhysicalTaggingFishTablePdf } from './generatePhysicalTaggingFishTablePdf'

const { Title } = Typography
const { Panel } = Collapse
const { confirm } = Modal

const PhysicalTaggingFishTable = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")

    const [total, setTotal] = useState(0)
    const [tagged, setTagged] = useState(0)
    const [pending, setPending] = useState(0)
    const [physicallyTagged, setPhysicallyTagged] = useState("")
    const [showModal, setShowModal] = useState(false)
    
    const [fishDataSource, setFishDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    
    //let windowObjectReference = null
    let tagId = ""
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //----------------
    // Print checklist
    //----------------
    const printCheckList = (fullList) => {
        if(fullList) 
            generatePhysicalTaggingFishTablePdf(urlParams.get("batchId"), urlParams.get("batch"), urlParams.get("storageId"), urlParams.get("storage"), 
                urlParams.get("species"))
        else
            generatePhysicalTaggingFishTablePdf(urlParams.get("batchId"), urlParams.get("batch"), urlParams.get("storageId"), urlParams.get("storage"), 
                urlParams.get("species"), selectedRowKeys)
    }

    const printFullCheckList = () => { 
        setShowModal(false)
        printCheckList(true) 
    }

    const printSelectedCheckList = () => { 
        setShowModal(false)

        if(selectedRowKeys.length > 0) 
            printCheckList(false) 
        else {
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>No fish selected. Print all fish instead?</p></Space>,
                onOk() { printCheckList(true) },
                onCancel() {},
                centered: true
            })
        }
    }

    //---------
    // On print
    //---------
    const onPrint = () => {
        setShowModal(true)
    }

    const closeModal = () => { setShowModal(false) }

    //-----------
    // Tag a fish
    //-----------
    const tag = (record) => {
        setIsLoading(true)

        axios.patch(`${ACISAPIURL}fish/update/${record.key}/`, {
            tag_id: tagId, 
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            getTagCount()
            message.info(`Tag ID of ${record.id} updated successfully.`)
            
            record.tagId = tagId
            
            let rowIndex = fishDataSource.findIndex(item => item.key == record.key)
            if(rowIndex >= 0) {
                fishDataSource[rowIndex] = record
                setFishDataSource([])
                setFishDataSource(fishDataSource)
            }
        })
        .catch( error => {
            reportError(error, `Failed to record tag ID.`)
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------------
    // Get tag count
    //-------------------------
    const getTagCount = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}fish/tagcount/${urlParams.get("storageId")}/${urlParams.get("batchId")}/`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            setTotal(response.data.tag_count.total)
            setTagged(response.data.tag_count.physically_tagged)
            setPending(parseInt(response.data.tag_count.total) - parseInt(response.data.tag_count.physically_tagged))
        })
        .catch( error => {
            reportError(error, "Failed to get tag count.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------------
    // Get fish
    //-------------------------
    const searchFish = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}fishlite/`, {
            params: { 
                site: getUserSiteId(),
                storage: urlParams.get("storageId"),
                batch: urlParams.get("batchId"),
                active: true,
                physically_tagged: physicallyTagged,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get fish list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( fish => {
            data.push({
                key: fish.pKey,
                id: fish.id,
                isBrood: fish.isBrood,
                tagId: fish.tag_id == null ? "" : fish.tag_id,
                taggedOn: fish.physically_taggedOn == null ? "" : moment(fish.physically_taggedOn).format(DATETIMEFORMAT),
            })
        })

        setFishDataSource(data)
        setSelectedRowKeys(response.data.results.map( fish => fish.pKey ))
        
        // Total pages
        setTotalRecord(response.data.count)
    }

    //------------------
    // Prompt for tag id
    //------------------
    const onTagIdChange = (e) => {
        tagId = e.target.value.trim()
    }

    //------------------
    // Prompt for tag id
    //------------------
    const onTag = (e, record) => {
        e.stopPropagation()

        confirm({
            icon: null,
            title: <Space><p>Please enter RFID for {record.id}.</p></Space>,
            content: <Input maxLength={25} onChange={onTagIdChange}/>,
            onOk() {
                if(tagId == "") {
                    message.warn("Invalid Tag ID.")
                    return
                }

                tag(record)
            },
            onCancel() {},
            centered: true
        }) 
    }

    //------------------
    // Go to import page
    //------------------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_PHYSICALTAGGING, 
        }) 
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFish(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchFish(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //---------------------------
    // On fish selection change
    //---------------------------
    const onSelectionChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectionChange
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getTagCount()
        searchFish(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Tag ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Is Brood', dataIndex: 'isBrood', key: 'isBrood', sorter: (a, b) => a.isBrood - b.isBrood,
            render: (isBrood) => {
                if(isBrood)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        { title: 'RFID', dataIndex: 'tagId', key: 'tagId', sorter: (a, b) => a.tagId.localeCompare(b.tagId) },
        { key: 'action', dataIndex: 'tagId', key: 'tagId', align: 'center',
            render: (tagId, record) => {
                if(tagId == "")
                    return <>
                        <Tooltip title="Press to tag">
                            <Button type="primary" htmlType="button" onClick={(e, id) => onTag(e, record)} icon={<TagOutlined />} />
                        </Tooltip>
                    </>
                else
                    return null
            }
        }
    ]
    
    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Form {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Total Quantity:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{total}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Tagged Quantity:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" >{tagged}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Pending Quantity:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{pending}</Card.Grid>
                        </Card>
                    </Form.Item>
                </Form>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Fish" key="1">
                        <Form {...formLayout_2Columns}>
                            <Form.Item name="physicallyTagged" label="Physically Tagged">
                                <TrueFalseSelect withBlank={true} onChange={setPhysicallyTagged}/>
                            </Form.Item>
                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={12} style={{textAlign: "start"}}>
                <Button type="primary" htmlType="button" onClick={onBack} icon={<LeftOutlined />} disabled={disableButton}>Back</Button>
            </Col>
            <Col span={12} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" onClick={onPrint} icon={<PrinterOutlined />} disabled={disableButton}>Print</Button>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
    
        <Row justify="center">
            <Col span={12}>
                <Table bordered columns={columns} dataSource={fishDataSource} pagination={false} rowSelection={rowSelection}/>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}><Button type="primary" htmlType="button" onClick={onBack} icon={<LeftOutlined />} disabled={disableButton}>Back</Button></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onPrint} icon={<PrinterOutlined />} disabled={disableButton}>Print</Button></Col>
        </Row>

        <Modal
            visible = {showModal}
            icon = {<QuestionCircleOutlined />}
            title = "Print Physical Tagging Checklist"
            centered = {true}
            closable = {false}
            footer = {[
                <Button type="primary" htmlType="button" onClick={closeModal}>Cancel</Button>,
                <Button type="primary" htmlType="button" onClick={printFullCheckList}>All</Button>,
                <Button type="primary" htmlType="button" onClick={printSelectedCheckList}>Selected Only</Button>,
            ]}>
            Please select what to print.
        </Modal>
        </Spin>
        </>
    )
}

export default PhysicalTaggingFishTable