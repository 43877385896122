import { Button, Card, Col, Collapse, Row, Space, Form, Table, Pagination, Spin } from "antd"
import { PrinterOutlined } from "@ant-design/icons"
import { useNavigate } from 'react-router-dom'
import  { useEffect, useState } from 'react'
import { formLayout_2Columns } from "../Common/Layout"
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from "../Common/UserSession"
import { ACISAPIURL, LOADING, PAGESIZE, PAGINATIONSIZE, TAGGED } from "../Common/SystemParameter"
import axios from "axios"
import { numberWithCommas, reportError } from "../Common/Utility"
import { generatePhysicalTaggingFishTablePdf } from "./generatePhysicalTaggingFishTablePdf"
import CommonSearchFormItem from "../Common/CommonSearchFormItem"

const { Panel } = Collapse


const PhysicalTaggingTable = () => {
    const [disableButton, setDisableButton] = useState("")
    const [searchForm] = Form.useForm()

    const navigate = useNavigate()
    const [searchStorageId, setSearchStorageId] = useState(0)
    const [searchBatchId, setSearchBatchId] = useState(0)
    const [searchSpeciesId, setSearchSpeciesId] = useState(0)
    const [searchMarineLifeId, setSearchMarineLifeId] = useState(0)

    const [tagIdSummaryDataSource, setTagIdSummaryDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //----------------
    // Search spawning
    //----------------
    const searchTaggedFishStorage = (currentPage, urlParamSearchStoragId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storagepaginatedbyitemtypewithphysicaltag/`, {
            params: { 
                site: getUserSiteId(),
                batch: searchBatchId,
                id: urlParamSearchStoragId != 0 ? urlParamSearchStoragId : searchStorageId,
                marine_life: searchMarineLifeId,
                species: searchSpeciesId,
                item_types_like: TAGGED,
                empty: false,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search tag IDs summary record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( storage => {
            data.push({
                key: storage.id,
                id: storage.storage_name,
                storageId: storage.storage,
                batchId: storage.batch_data.pKey,
                batch: storage.batch_data.id,
                speciesId: storage?.batch_data?.species_data?.pKey,
                species: storage?.batch_data?.species_data?.short_name,
                total: numberWithCommas(storage.quantity),
                tagged: numberWithCommas(storage.physically_tagged_count),
                pending: numberWithCommas(storage.quantity - storage.physically_tagged_count),
            })
        })
        
        setTagIdSummaryDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/physicaltaggingfish", 
                    search: `?species=${record.species}&batch=${record.batch}&&batchId=${record.batchId}&storageId=${record.storageId}&storage=${record.id}`
                }) 
            }
        }
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchTaggedFishStorage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchTaggedFishStorage(1)
    }

    //---------
    // On print
    //---------
    const onPrint = (e, record) => {
        e.stopPropagation()
        generatePhysicalTaggingFishTablePdf(record.batchId, record.batch, record.storageId, record.id, record.species)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onMarineLifeChange = (e, value) => {
        setSearchMarineLifeId(e)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setSearchStorageId(urlParams.get("storageId"))
            searchTaggedFishStorage(currentPage, urlParams.get("storageId"))
        }
        else
            searchTaggedFishStorage(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Total (Tail)', dataIndex: 'total', key: 'total', align: "right", sorter: (a, b) => a.total - b.total },
        { title: 'Tagged (Tail)', dataIndex: 'tagged', key: 'tagged', align: "right", sorter: (a, b) => a.tagged - b.tagged },
        { title: 'Pending (Tail)', dataIndex: 'pending', key: 'pending', align: "right", sorter: (a, b) => a.pending - b.pending },
        { key: 'action', align: 'center',
            render: (record) => {
                return <Button type="primary" disabled={disableButton} onClick={e => onPrint(e, record)} icon={<PrinterOutlined/>} ></Button>
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row justify="center">
            <Col span={8} style={{textAlign: "center"}}>
                <Card title="Storage for Physical Tagging" style={{textAlign: "left"}}>
                    Storage with taggable marine life and their physical tagging status.
                </Card>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Storage" key="1">
                        <Form form={searchForm} {...formLayout_2Columns}>
                            <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={setSearchSpeciesId} onBatchChange={setSearchBatchId} 
                                onStorageChange={setSearchStorageId} showLockedStorage={false} defaultStorageId={searchStorageId}/>
                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={tagIdSummaryDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default PhysicalTaggingTable