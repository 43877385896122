import { useEffect } from 'react';
import "antd/dist/antd.css";
import "./index.css";
import ACISRouter from './component/Common/ACISRouter';
import "./App.css";
import { getCookie } from './component/Common/Utility';

(async () => {
    if(getCookie('useDarkTheme') != '1') 
        // import module for side effects
        await import("./App.css")
    else
        await import("./AppDarkTheme.css")
})()
  

function App() {
    useEffect(() => {
        document.title = "ACIS"
     }, []);

    return (
        <ACISRouter />
    );
}

export default App;
