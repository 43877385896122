import { Row, Col, Button, Form, Collapse, DatePicker, message, Space, Spin } from 'antd'
import { Line } from '@ant-design/charts'
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, DATETIMENOSECFORMAT, LOADING, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { reportError } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'
import EnvironmentReadingTypeSelect from '../Common/EnvironmentReadingTypeSelect'

const { Panel } = Collapse
const { RangePicker } = DatePicker

//----------
// Component
//----------
const EnvironmentDataHistoryTable = () => {
    const MAXSEARCHDAYS = parseInt(OTHERSYSPARAM("ENV_HISTORY_BACKDATE_DAYS"))

    const [disableButton, setDisableButton] = useState("")
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    
    const [storageId, setStorageId] = useState(0)
    const [environmentReadingTypeIds, setEnvironmentReadingTypeIds] = useState([])

    const [dateRange, setDateRange] = useState([moment((moment().subtract(MAXSEARCHDAYS, "days").add(1, "seconds")), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [lineChart, setLineChart] = useState(null)
        
    //-----------------------
    // Search
    //-----------------------
    const searchEnvironment = (currentPage, urlParamSearchStoragId = 0) => {
        setLoading(true)
        setDisableButton("disabled")
        
        axios.get(`${ACISAPIURL}environmentfull/`, {
            params: { 
                site: getUserSiteId(), 
                fromDate: dateRange[0].format(UNIDATETIMEFORMAT),
                toDate: dateRange[1].format(UNIDATETIMEFORMAT),
                storage: urlParamSearchStoragId != 0 ? urlParamSearchStoragId : storageId,
                reading_type: environmentReadingTypeIds,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 10),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            plotLines(response)
        })
        .catch( error => {
            reportError(error, "Failed to search environment history.")
        })
        .finally(() => {
            setLoading(false)
            setDisableButton("")
            refreshUserSession()
        })
    }

    const plotLines = (response) => {
        const data = []
        response.data.forEach( history => {
            data.push({
                datetime: moment(history[0]).format(DATETIMENOSECFORMAT),
                value: history[1],
                readingType: history[2]
            })
        })
        
        const config = {
            data: data,
            xField: 'datetime',
            yField: 'value',
            seriesField: 'readingType',
            xAxis: {
                //type: 'time',
                nice: true,
                label: {
                    autoRotate: true,
                    formatter: (name) => name,
                },
            },
            /*yAxis: {
                label: {
                    formatter: (v) => `${(v / 10e8).toFixed(1)} B`,
                },
            },*/
            legend: {
                position: 'top',
            },
            smooth: true,
            /*animation: {
                appear: {
                    animation: 'path-in',
                    duration: 3000,
                },
            },*/
        }
        
        setLineChart(<Line {...config} />)
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        if(dateRange[0].diff(dateRange[1], "days") <= MAXSEARCHDAYS * -1 || dateRange[1].diff(dateRange[0], "days") >= MAXSEARCHDAYS) {
            message.info(`Please limit search date range to maximum of ${MAXSEARCHDAYS} days only.`)
            return
        }

        if(storageId == 0) {
            message.info("Please select storage before search.")
            return
        }

        searchEnvironment()
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
    }

    const onSearchEnvironmentReadingTypeChange = (e, value) => {
        setEnvironmentReadingTypeIds(e)
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    /*const disabledDate = current => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') >= parseInt(OTHERSYSPARAM("ENV_HISTORY_BACKDATE_DAYS"));
        const tooEarly = dates[1] && dates[1].diff(current, 'days') >= parseInt(OTHERSYSPARAM("ENV_HISTORY_BACKDATE_DAYS"));
        return tooEarly || tooLate;
    };

    const onOpenChange = open => {
        if(open) {
            setHackValue([]);
            setDates([]);
        } else {
            setHackValue(undefined);
        }
    };*/

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    }, [])

    return(
        <>
        <Spin spinning={loading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Environment Data History" key="0">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range">
                                <RangePicker 
                                    rules={[
                                        { required: true, message: "Date range is required." },
                                    ]}
                                    onChange={onDateRangeChange} 
                                    defaultValue={dateRange} 
                                    format={DATETIMENOSECFORMAT} 
                                    style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}
                                    showTime={{
                                        hideDisabledOptions: true,
                                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                                    }}/>
                            </Form.Item>
                            {/* <Form.Item name="dateRange" label="Date Range">
                                <RangePicker 
                                    rules={[
                                        { required: true, message: "Date range is required." },
                                    ]}
                                    value={hackValue || value}
                                    disabledDate={disabledDate}
                                    onCalendarChange={val => setDates(val)}
                                    onChange={val => setValue(val)}
                                    onOpenChange={onOpenChange}
                                    format={DATETIMENOSECFORMAT}
                                    showTime={{
                                        hideDisabledOptions: true,
                                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                                    }}
                                    style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}
                                    defaultValue={dates} />
                            </Form.Item> */}

                            <CommonSearchFormItem onStorageChange={onSearchStorageChange} showLockedStorage={false} defaultStorageId={storageId}/>

                            <Form.Item name="environmentReadingType" label="Environment Reading Type">
                                <EnvironmentReadingTypeSelect mode="multiple" withBlank={true} onChange={onSearchEnvironmentReadingTypeChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={24} xl={24}>
                {lineChart}
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default EnvironmentDataHistoryTable