import { Table, Row, Col, Space, Button, Form, Collapse, Modal, Popconfirm, message, Card, Typography, Pagination, DatePicker, Spin } from 'antd'
import { QuestionCircleOutlined, LeftOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, DATETIMEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { buildMismatchedCriteriaString, calculateWeightVariancePercentage, reportError, storageCapacityCheck } from '../Common/Utility'
import { formLayout, formLayout_2Columns } from '../Common/Layout'
import BatchTypeSelect from '../Common/BatchTypeSelect'
import AcquiringMethodSelect from '../Common/AcquiringMethodSelect'
import { showStorageStockSummary, } from '../Common/showStorageStockSummary'
import { releaseStorage } from '../Common/releaseStorage'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'
import moment from 'moment'

const { Panel } = Collapse
const { confirm } = Modal
const { Title } = Typography

//----------
// Component
//----------
const TransferFishStorageTable = ({interSite}) => {
    const [disableButton, setDisableButton] = useState("")
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()
    const [fishInfo, setFishInfo] = useState(null)
            
    const [searchBatchId, setSearchBatchId] = useState(0)
    const [searchStorageId, setSearchStorageId] = useState(0)
    const [searchBatchTypeId, setSearchBatchTypeId] = useState(0)
    const [searchAcquiringMethodId, setSearchAcquiringMethodId] = useState(0)
    const [searchLifecycleId, setSearchLifecycleId] = useState(0)
    const [searchSiteId, setSearchSiteId] = useState(getUserSiteId())
    const [searchAquacultureStageId, setSearchAquacultureStageId] = useState(0)
    
    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isSearching, setIsSearching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    //const [fishId, setFishId] = useState("")
    const [species, setSpecies] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [acquiringMethod, setAcquiringMethod] = useState("")
    const [acquiringMethodId, setAcquiringMethodId] = useState(0)
    const [aquacultureStage, setAquacultureStage] = useState("")
    const [aquacultureStageId, setAquacultureStageId] = useState(0)
    const [lifecycle, setLifecycle] = useState("")
    const [lifecycleId, setLifecycleId] = useState(0)
    const [storage, setStorage] = useState("")
    const [sourceStorageId, setSourceStorageId] = useState(0)
    const [sourceBatch, setSourceBatch] = useState("")
    const [sourceBatchId, setSourceBatchId] = useState(0)
    const [batchTypeId, setBatchTypeId] = useState(0)

    const [panelKey, setPanelKey] = useState(interSite ? "1" : "0")

    const [transferDateTime, setTransferDateTime] = useState(moment(moment(), UNIDATEFORMAT))
        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const fishIds = urlParams.get("fishIds").split(",")

    //--------------
    // Transfer Fish
    //--------------
    const transferFish = (record) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.patch(`${ACISAPIURL}fish/transfer/`, {
            batch: record.batchId,
            storage: record.pKey,
            fish: fishIds,
            transactedOn: transferDateTime,
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Tagged marine life transferred to ${record.batch} storage ${record.id}.`, OTHERSYSPARAM("LONG_MSG_DURATION"))
            navigate({ 
              pathname: "/transferfish",
              search: `?storageId=${record.pKey}`
            })

            releaseStorage(fishInfo.batchId, fishInfo.batch, sourceStorageId)
        })
        .catch( error => {
            reportError(error, "Failed to transfer fish.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------------
    // create new batch and transfer fish 
    //---------------------------------
    const transferFishNewBatch = (record) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        axios.patch(`${ACISAPIURL}fish/transferwithnewbatch/`, {
            site: record.siteId,
            species: fishInfo.speciesId,
            acquiring_method: fishInfo.acquiringMethodId,
            aquaculture_stage: fishInfo.aquacultureStageId,
            lifecycle: fishInfo.lifecycleId,
            storage: record.pKey, 
            id: "",
            batch_type: fishInfo.batchTypeId,
            fish: fishIds,
            transactedOn: transferDateTime,
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Tagged marine life transferred to ${response.data.id} storage ${record.id}.`)
            navigate({ 
              pathname: "/transferfish",
              search: `?storageId=${record.pKey}`
            })
            
            releaseStorage(fishInfo.batchId, fishInfo.batch, sourceStorageId)
        })
        .catch( error => {
            reportError(error, "Failed to transfer fish.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------------
    // Assign existing batch and transfer fish 
    //---------------------------------
    const transferFishWithSourceBatch = (record) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.patch(`${ACISAPIURL}fish/transferwithsourcebatch/`, {
            site: record.siteId,
            species: fishInfo.speciesId,
            acquiring_method: fishInfo.acquiringMethodId,
            aquaculture_stage: fishInfo.aquacultureStageId,
            lifecycle: fishInfo.lifecycleId,
            storage: record.pKey, 
            id: "",
            batch: fishInfo.batchId,
            fish: fishIds,
            transactedOn: transferDateTime,
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Tagged marine life transferred to storage ${record.id}.`)
            navigate({ 
              pathname: "/transferfish",
              search: `?storageId=${record.pKey}`
            })
            
            releaseStorage(fishInfo.batchId, fishInfo.batch, sourceStorageId)
        })
        .catch( error => {
            reportError(error, "Failed to transfer fish.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // Search storage
    //---------------
    const searchStorage = (currentPage, fishInfo) => {
        if(interSite && searchSiteId == getUserSiteId()) {
            message.info("Please select a site before proceed.")
            return
        }

        setDisableButton("disabled")
        setIsLoading(true)
        
        // If search by storage id, in order to show empty storage, will not be filtered by species.
        if(searchStorageId != 0) {
            message.info("Search by Storage ID would disable species filter.")
        }
        
        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: { 
                site: searchSiteId,
                //species: searchStorageId != 0 ? 0 : urlParams.get("speciesId"), // Will not show empty storage is specified species.
                batch: searchBatchId,
                id: searchStorageId,
                acquiring_method: searchAcquiringMethodId,
                aquaculture_stage: searchAquacultureStageId,
                lifecycle: searchLifecycleId,
                batch_type: searchBatchTypeId, 
                exclude: urlParams.get("storageId"),
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response, fishInfo)
        })
        .catch( error => {
            reportError(error, "Failed to search storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------
    // Load storage
    //-------------
    const getMatchingStorage = (currentPage, fishInfo) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: { 
                site: getUserSiteId(),
                species: fishInfo.speciesId,
                lifecycle: fishInfo.lifecycleId,
                batch_type: fishInfo.batchTypeId, 
                //acquiring_method: fishInfo.acquiringMethodId,
                //free: true,
                exclude: urlParams.get("storageId"),
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response, fishInfo)
        })
        .catch( error => {
            reportError(error, "Failed to get storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response, fishInfo) => {
        let data = []
        response.data.results.forEach( storage => {
            data.push({
                key: storage.id,
                pKey: storage.pKey,
                siteId: storage.site,
                id: storage.storageid,
                name: storage.name,
                storageType: storage.storage_type_data.name,
                batchId: storage?.batch_data == null ? 0 : storage.batch_data.pKey,
                batch: storage?.batch_data == null ? "" : storage.batch_data.id,
                speciesId: storage?.batch_data?.species_data?.pKey == null ? 0 : storage?.batch_data.species_data.pKey,
                acquiringMethod: storage?.batch_data?.acquiring_method_data == null ? "" : storage.batch_data.acquiring_method_data.name,
                acquiringMethodId: storage?.batch_data?.acquiring_method_data == null ? 0 : storage.batch_data.acquiring_method_data.pKey,
                aquacultureStage: storage?.batch_data?.aquaculture_stage_data == null ? "" : storage.batch_data.aquaculture_stage_data.name,
                aquacultureStageId: storage?.batch_data?.aquaculture_stage_data == null ? 0 : storage.batch_data.aquaculture_stage_data.pKey,
                lifecycle: storage?.batch_data?.lifecycle_data == null ? "" : storage.batch_data.lifecycle_data.name,
                lifecycleId: storage?.batch_data?.lifecycle_data == null ? 0 : storage.batch_data.lifecycle_data.pKey,
                batchType: storage?.batch_data?.batch_type_data?.name == null ? "" : storage.batch_data.batch_type_data.name,
                batchTypeId: storage?.batch_data?.batch_type_data?.pKey == null ? 0 : storage.batch_data.batch_type_data.pKey,
                batchAverageWeight: storage?.batch_data?.average_per_unit_weight_gram == null ? 0 : storage.batch_data.average_per_unit_weight_gram,
                batchAverageLength: storage?.batch_data?.average_per_unit_length_mm == null ? 0 : storage.batch_data.average_per_unit_length_mm,
                ageDays: storage?.batch_data?.age_days == null ? 0 : storage.batch_data.age_days,

                batchData: storage?.batch_data != null ? [storage.batch_data] : [],
                status: storage.status_data,
            })
        })

        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
        //window.location.replace(window.location.href.split('?')[0])
    }

    //--------------
    // Get fish info
    //--------------
    const getFishInfo = async (fishPKey) => {
        setIsLoading(true)

        await axios.get(`${ACISAPIURL}fish/${fishPKey}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const fish = response.data
            
            //setSite(fish.site_data.name)
            //setFishId(fish.id)
            setSpecies(fish.batch_data.species_data.short_name)
            setSpeciesId(fish.batch_data.species_data.pKey)
            setAcquiringMethod(fish.batch_data.acquiring_method_data.name)
            setAcquiringMethodId(fish.batch_data.acquiring_method_data.pKey)
            setAquacultureStage(fish.batch_data.aquaculture_stage_data.name)
            setAquacultureStageId(fish.batch_data.aquaculture_stage_data.pKey)
            setLifecycle(fish.batch_data.lifecycle_data.name)
            setLifecycleId(fish.batch_data.lifecycle_data.pKey)
            setStorage(fish.storage_data.name)
            setSourceStorageId(fish.storage_data.pKey)
            setBatchTypeId(fish.batch_data.batch_type_data.pKey)
            setSourceBatch(fish.batch_data.id)
            setSourceBatchId(fish.batch_data.pKey)

            const fishInfo = {
                fishId: fish.pKey,
                speciesId: fish.batch_data.species_data.pKey,
                acquiringMethodId: fish.batch_data.acquiring_method_data.pKey,
                aquacultureStageId: fish.batch_data.aquaculture_stage_data.pKey,
                lifecycleId: fish.batch_data.lifecycle_data.pKey,
                storageId: fish.storage_data.pKey,
                isBrood: fish.isBrood,
                batchId: fish.batch_data.pKey,
                batchTypeId: fish.batch_data.batch_type_data.pKey,
                marineLifeId: fish.batch_data.species_data.marine_life_data.pKey
            }

            setFishInfo(fishInfo)
            if(!interSite) getMatchingStorage(currentPage, fishInfo)
        })
        .catch( error => {
            reportError(error, "Failed to get fish record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // On transfer fish to storage
    //--------------------------
    const promptTransferFishToStorage = (record) => {
        switch(record.batchId != 0) {
            case true: // Storage has batch assigned.
                // Cannot mix species
                //if(urlParams.has("speciesId") && record.speciesId != 0 && speciesId != record.speciesId) {
                if(record.speciesId != 0 && speciesId != record.speciesId) {
                    message.warn("Mixing species into a storage is not allowed.")
                    return
                }

                let mismatchedCriteria = buildMismatchedCriteriaString(batchTypeId, record.batchTypeId,
                    acquiringMethodId, record.acquiringMethodId, lifecycleId, record.lifecycleId)

                if(mismatchedCriteria != "") {
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>{mismatchedCriteria} do not match. Proceed to transfer fish?</p></Space>,
                        onOk() { transferFish(record) },
                        onCancel() {},
                        centered: true
                    })
                }
                else
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Transfer fish confirmed?</p></Space>,
                        onOk() { transferFish(record) }, 
                        onCancel() {},
                        centered: true
                    })    
                
                break
            case false: // Storage has no batch assigned.
                if(!interSite)
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Assign existing batch {sourceBatch} to storage {record.id}?</p></Space>,
                        onOk() { transferFishWithSourceBatch(record) },
                        onCancel() {
                            confirm({
                                icon: <QuestionCircleOutlined />,
                                content: <Space><p>Selected storage has no batch assigned. Create a new batch for {record.id}?</p></Space>,
                                onOk() { transferFishNewBatch(record) },
                                onCancel() {},
                                okText: OTHERSYSPARAM("YES"),
                                cancelText: OTHERSYSPARAM("NO"),
                                centered: true
                            })
                        },
                        okText: OTHERSYSPARAM("YES"),
                        cancelText: OTHERSYSPARAM("NO"),
                        centered: true
                    })
                else
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Selected storage has no batch assigned. Create a new batch for {record.id}?</p></Space>,
                        onOk() { transferFishNewBatch(record) },
                        onCancel() {},
                        okText: OTHERSYSPARAM("YES"),
                        cancelText: OTHERSYSPARAM("NO"),
                        centered: true
                    })
                
                break
        }
    }

    //-------------------
    // Check overcrowding
    //-------------------
    const checkOvercrowd = async (record) => {
        // Check for overcrowding by weight in Kg.
        let weightToAddKg = 0
    
        weightToAddKg = urlParams.get("totalWeight") / 1000
                
        try{
            const prompt = await storageCapacityCheck(fishInfo.marineLifeId, record.batchId, record.pKey, weightToAddKg)

            if(prompt)
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Overcrowding will happen after transfer. Procced?</p></Space>,
                    onOk() { promptTransferFishToStorage(record) },
                    onCancel() {},
                    okText: OTHERSYSPARAM("YES"),
                    cancelText: OTHERSYSPARAM("NO"),
                    centered: true
                })
            else
                promptTransferFishToStorage(record)
        }
        catch(error) {
            reportError(error, "Failed to get marine life storage type capacity information.")
        }
    }

    //----------------------
    // Check weight variance
    //----------------------
    const checkWeightVariance = (record) => {
        if(record.batchId != 0) {
            let threshold = parseFloat(OTHERSYSPARAM("WEIGHT_VARIANCE_THRESHOLD_PERCENT")) / 100

            if(parseFloat(record.batchAverageWeight) != 0 && parseFloat(urlParams.get("averageWeight")) != 0) {
                // Check average weight variance. Prompt if variance is greater than predefined percentage threshold.
                let weightDiff = calculateWeightVariancePercentage(parseFloat(record.batchAverageWeight), parseFloat(urlParams.get("averageWeight")))
                    
                if(weightDiff >= threshold) {
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Weight variance between the two batches exceeded by {(threshold * 100).toFixed(2)}%. Proceed to stock-in?</p></Space>,
                        onOk() { checkOvercrowd(record) },
                        onCancel() {},
                        centered: true
                    })
                }
                else
                    checkOvercrowd(record)
            }
            else
                checkOvercrowd(record)
        }
        else
            checkOvercrowd(record)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)

        if(isSearching)
            searchStorage(page)
        else
            getMatchingStorage(page, fishInfo) 
            
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //---------------
    // On transfer fish
    //---------------
    const onTransferFish = (record) => {
        checkWeightVariance(record)
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setLoading(true)
        setIsSearching(true)
        setCurrentPage(1)
        searchStorage(1, fishInfo)
        setLoading(false)
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                onTransferFish(record)
            }
        }
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: interSite ? "/transferfishintersite" : "/transferfish", 
            search: interSite ? `?interSite=${interSite}&storageId=${urlParams.get("storageId")}` : `?storageId=${urlParams.get("storageId")}`
        })
    }

    //---------------------------
    // On search criteria change
    //---------------------------
    const onAcquiringMethodChange = (e, value) => {
        setSearchAcquiringMethodId(e)
    }

    const onBatchTypeChange = (e, value) => {
        setSearchBatchTypeId(e)
    }

    //----------------------
    // On search site change
    //----------------------
    const onSearchSiteChange = (e) => {
        setSearchSiteId(e)
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setTransferDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateTransferDateTime = (() => {
        if(transferDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Transfer date time is required."))
    })

    //----------------------------
    // Redirect callback function
    //----------------------------
    const redirect = (pathname, search) => {
        navigate({
            pathname: pathname,
            search: search
        })
    }

    //----------------------------
    // Show storage stock quantity
    //----------------------------
    const showStoragePopup = (e, record) => {
        e.stopPropagation()

        const newRecord = record
        newRecord.key = newRecord.pKey
        showStorageStockSummary(false, newRecord, redirect)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            await getFishInfo(fishIds[0])
            setLoading(false)
        }

        init()
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'name', key: 'name', sorter: (a, b) => a.id.localeCompare(b.name) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) },
        { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) },
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Status', dataIndex: 'stockQuantity', key: 'stockQuantity', //sorter: (a, b) => a.quantity.stockQuantity(b.stockQuantity),
            render: (stockQuantity, record) => {
                if(record.status == OTHERSYSPARAM("IS_OCCUPIED"))
                    return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => showStoragePopup(e, record)}>{OTHERSYSPARAM("IS_OCCUPIED")}</Button>
                else
                    return OTHERSYSPARAM("IS_EMPTY")
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form form={form} {...formLayout}>
            <Form.Item>
                {/* <Card title={<Title level={5}>{`Tag ID: ${fishId}`}</Title>}> */}
                <Card title={<Title level={5}>{`Fish Transfer:`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{sourceBatch}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage ID:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{storage}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{species}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Acquiring Method:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{acquiringMethod}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Aquaculture Stage:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{aquacultureStage}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Lifecycle:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{lifecycle}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Quantity:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{fishIds.length}</Card.Grid>
                </Card>
            </Form.Item>

            <Form.Item name="transferDateTime" label="Transfer Date Time"
                rules={[
                    { required: true, validator: validateTransferDateTime },
                ]}>
                <DatePicker showTime onChange={onDateTimeChange} defaultValue={transferDateTime} format={DATETIMEFORMAT}/>
            </Form.Item>
        </Form>

        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={[panelKey]}>
                    <Panel header="Search Storage" key="1">
                        <Form form={searchForm} {...formLayout_2Columns}>
                            <CommonSearchFormItem onSiteChange={interSite ? onSearchSiteChange : null} onBatchChange={setSearchBatchId} 
                                onStorageChange={setSearchStorageId} onAquacultureStageChange={setSearchAquacultureStageId}
                                onLifecycleChange={setSearchLifecycleId} showLockedStorage={false} excludeOwnSite={true}
                                loadBatch={interSite ? false : true} loadStorage={interSite ? false : true} defaultSiteId={searchSiteId}/>

                            <Form.Item name="acquiringMethodId" label="Acquiring Method">
                                <AcquiringMethodSelect withBlank={true} onChange={onAcquiringMethodChange}/>
                            </Form.Item>

                            <Form.Item name="batchTypeId" label="Batch Type">
                                <BatchTypeSelect withBlank={true} onChange={onBatchTypeChange}/>
                            </Form.Item>

                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={24} style={{textAlign: "start"}}>
                <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                    <Button type="primary" htmlType="button" icon={<LeftOutlined />} disabled={disableButton}>Back</Button>
                </Popconfirm>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered onRow={onRowClick} columns={columns} dataSource={storageDataSource} pagination={false}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}>
                <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                    <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                </Popconfirm>
            </Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default TransferFishStorageTable