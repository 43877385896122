import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, PageHeader, Modal, Descriptions, Spin, Card } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, EGGS, LOADING, MENUPATH_AMENDSTOCKTRANSACTIONRECORD, SEARCHOPTIONVIEWWIDTH, TAGGED, UNIDATETIMEFORMAT, UNTAGGED } from "../Common/SystemParameter"
import { numberWithCommas, reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import moment from 'moment'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal


//----------
// Component
//----------
const AmendStockTransactionNew = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
            
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    const transaction = JSON.parse(urlParams.get('transaction'))
    
    let stockBalance = 0
    if(transaction.item_type_data.name.startsWith(EGGS))
        stockBalance = transaction.egg_balance_quantity
    else if(transaction.item_type_data.name.startsWith(UNTAGGED))
        stockBalance = transaction.untagged_balance_quantity
    else if(transaction.item_type_data.name.startsWith(TAGGED))
        stockBalance = transaction.tagged_balance_quantity
    
    transaction.stockBalance = stockBalance
    
    //-------------------------------
    // Amend stock transaction record
    //-------------------------------
    const amendStockTransactionRecord = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}stocktransaction/amend/${transaction.pKey}/`, {
                new_quantity: values.newQuantity,
                new_average_per_unit_weight_gram: values.newAveragePerUnitWeightGram,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Stock transaction record amended.")
                navigate({ 
                    pathname: MENUPATH_AMENDSTOCKTRANSACTIONRECORD
                })
            })
            .catch( error => {
                reportError(error, `Failed to amend stock transaction record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //--------
    // On next
    //--------
    const onSave = (e) => {
        if(!valid()) return

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Amend stock transaction record confirmed?</p></Space>,
            onOk() { amendStockTransactionRecord() },
            onCancel() {},
            centered: true
        })
    }

    const valid = () => {
        form.validateFields()
        .then( values => {
            if(isNaN(parseFloat(values.newAveragePerUnitWeightGram)) || parseFloat(values.newAveragePerUnitWeightGram) <= 0) {
                message.warning('Invalid new average per unit weight.')
                return false
            }
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })

        return true
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_AMENDSTOCKTRANSACTIONRECORD
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            newQuantity: transaction.quantity,
            newAveragePerUnitWeightGram: transaction.average_per_unit_weight_gram
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onBack()} 
                    title="Amend Stock Transaction">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Change stock transaction untagged quantity and weight</Descriptions.Item>
                    </Descriptions>
                </PageHeader>


                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Stock Transaction Record ID: ${transaction.pKey}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Transacted On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{moment(moment(transaction.transactedOn, "YYYY-MM-DDTHH:mm:ss")).format(DATETIMEFORMAT)}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Transaction Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{transaction.transaction_type_data.name}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{transaction.batch_data.id}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{transaction.storage_data.name}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{transaction.batch_type_data.name}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Tag ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{transaction?.fish_data?.id == null ? OTHERSYSPARAM("NA") : transaction.fish_data.id}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Item Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{transaction.item_type_data.name}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ color: 'red', fontWeight: 'bold' }}>Quantity:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ color: 'red', fontWeight: 'bold' }}>{transaction.quantity}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Balance Quantity:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{transaction.stockBalance}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Unit of Measurement:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{transaction.uom_data.uom}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label" style={{ color: 'red', fontWeight: 'bold' }}>Old Avg/Unit Weight (g):</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{ color: 'red' }}>{numberWithCommas(transaction.average_per_unit_weight_gram)}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Total Weight (Kg):</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas((transaction.quantity2 / 1000).toFixed(2))}</Card.Grid>
                        </Card>
                    </Form.Item>

                    <Form.Item name="newQuantity" label={`New Quantity (${transaction.uom_data.uom})`}
                        rules={[
                            { required: true, message: "New quantity is required."},
                        ]}>
                        <InputNumber step={1} precision={0} style={{width: SEARCHOPTIONVIEWWIDTH}} placeholder={`New Quantity (${transaction.uom_data.uom})`}/>
                    </Form.Item>

                    <Form.Item name="newAveragePerUnitWeightGram" label={`New Avg/Unit Weight (g)`}
                        rules={[
                            { required: true, message: "New average weight is required."},
                        ]}>
                        <InputNumber min={1} step={1} precision={0} style={{width: SEARCHOPTIONVIEWWIDTH}} placeholder="New Average Per Unit Weight (g)"/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Amend Stock Transaction:"
                    subTitle="Change stock transaction untagged quantity and weight"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default AmendStockTransactionNew