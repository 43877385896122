import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL, MEDIAMAXWIDTH } from "./SystemParameter"
import { reportError } from "./Utility"
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./UserSession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select

//----------
// Component
//----------
const BatchSelect = ({withBlank, defaultValue, site, active, onChange}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [batchOption, setBatchOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
            
    //---------
    // Load all
    //---------
    const getAllBatch = () => {
        axios.get(`${ACISAPIURL}batchfull/`, {
            params: {
                site: site,
                active: active
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={""}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( batch => <Option key={batch.pKey}>{batch.id}</Option>))
            setBatchOption(options)
        })
        .catch( error => {
            reportError(error, "<BatchSelect /> Failed to get batch list.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllBatch()
    }, []) 
    
    return(
        <Select defaultValue={defaultValue} style={{width: optionWidth}} onChange={onSelectionChange} allowClear showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {batchOption}
        </Select>
    )
}

export default BatchSelect