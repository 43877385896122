import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Layout, Row, Col, Select, PageHeader, Modal, Input, Descriptions, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE, MENUPATH_BATCH, MEDIAMAXWIDTH, LOADING } from "../Common/SystemParameter"
import { reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserSiteId, getUserAuthToken, SYSPARAM, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import SpeciesSelect from '../Common/SpeciesSelect'
import { useMediaQuery } from 'react-responsive'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Option } = Select
const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { TextArea } = Input

//----------
// Component
//----------
// Batch Type       Acquiring Method                    Aquaculture Stage       Lifecycle
// --------------------------------------------------------------------------------------
// Stock            Breed In-house                      Spawning                Egg
// Stock            Purchase/Loan                       Growout                 Fries, Juveniles, Matured Fish
// Brood            Breed In-house/Purchase/Loan        Brood Maintenance       Matured Fish
// Brood            Mixed                               Brood Maintenance       Fries, Juveniles, Matured Fish

const BatchNew = () => {
    const navigate = useNavigate()
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [batchTypeId, setBatchTypeId] = useState(0)
    const [acquiringMethodId, setAcquiringMethodId] = useState(0)
    const [acquiringMethodOption, setAcquiringMethodOption] = useState([])
    
    const [speciesId, setSpeciesId] = useState(0)
    const [batchTypeOption, setBatchTypeOption] = useState([])
    const [lifecycleId, setLifecycleId] = useState(0)
    const [lifecycleOption, setLifecycleOption] = useState([])
    
    const [aquacultureStageId, setAquacultureStageId] = useState(0)
    const [aquacultureStageOption, setAquacultureStageOption] = useState([]) 

    const [availableStorageOption, setAvailableStorageOption] = useState([])
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [storageIds, setStorageIds] = useState(urlParams.has("storageId") ? [urlParams.get("storageId")] : [])
    // From create new batch during stock in or transfer.
    const [disableReserveStorageSelect, setDisableReserveStorageSelect] = useState(urlParams.has("storageId") ? "disabled" : "")  

    //-----------------
    // Create new batch
    //-----------------
    const createBatch = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.post(`${ACISAPIURL}batch/create/`, {
            site: getUserSiteId(),
            batch_type: batchTypeId,
            species: speciesId,
            storage: storageIds,
            acquiring_method: acquiringMethodId,
            initial_acquiring_method: acquiringMethodId,
            lifecycle: lifecycleId,
            aquaculture_stage: aquacultureStageId,
            id: "",
            status: SYSPARAM("New"),
            remark: form.getFieldValue("remark")
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`New batch ${response.data.id} created.`)

            if(urlParams.has("fromStockIn")) {
                navigate({ 
                    pathname: MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE,
                    search: `?storageId=${urlParams.get("storageId")}`     
                })

                message.info(`New batch created for storage ${urlParams.get("storage")}`, OTHERSYSPARAM("LONG_MSG_DURATION"))
            }
            else if(urlParams.has("fromFishTagging")) {
                navigate({ 
                    pathname: "/fishstoragewithoutsourcebatch",
                    search: `?selectedAcquiringMethodId=${urlParams.get("selectedAcquiringMethodId")}&selectedAcquiringMethod=${urlParams.get("selectedAcquiringMethod")}&storageId=${urlParams.get("storageId")}`
                })

                message.info(`New batch created for storage ${urlParams.get("storage")}`, OTHERSYSPARAM("LONG_MSG_DURATION"))
            }
            else 
                navigate({ pathname: "/batchmain" })
        })
        .catch( error => {
            reportError(error, "Failed to create batch.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------
    // Load lifecycle
    //-------------------
    const getLifecycle = (aquacultureStageId) => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}lifecycle/`, {
            params: {
                species: speciesId,
                batch_type: batchTypeId,
                aquaculture_stage: aquacultureStageId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( lifecycle => <Option key={lifecycle.pKey}>{lifecycle.name}</Option>)
            setLifecycleOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get lifecycle.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------
    // Load aquaculture stage
    //---------------------------
    const getAquacultureStage = (acquiringMethodId) => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}aquaculturestage/`, { 
            params: {
                species: speciesId,
                batch_type: batchTypeId,
                acquiring_method: acquiringMethodId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( stage => <Option key={stage.pKey}>{stage.name}</Option>)
            setAquacultureStageOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get aquaculture stages.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Load acquiring method
    //--------------------------
    const getAcquiringMethod = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}acquiringmethod/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),    
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( method => <Option key={method.pKey}>{method.name}</Option>)
            setAcquiringMethodOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get acquiring methods.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------
    // Load batch type
    //---------------------------
    const getAllBatchType = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}batchtype/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options = options.concat(response.data.results.map( batchType => <Option key={batchType.pKey}>{batchType.name}</Option> ))
            setBatchTypeOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get batch type.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------------------
    // Load available storage
    //-----------------------
    const getAvailableStorage = async (speciesId) => {
        setIsLoading(true)

        return await axios.get(`${ACISAPIURL}storageavailablesuitable/`, {
            params: {
                site: getUserSiteId(),
                species: speciesId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( storage => <Option key={storage.pKey}>{storage.name}</Option>)
            setAvailableStorageOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get available storage.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------
    // Cancel create batch
    //--------------------
    const onCancel = () => {
        if(urlParams.has("fromStockIn"))
            navigate({ pathname: MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE })
        else if(urlParams.has("fromFishTagging")) {
            navigate({ 
                pathname: "/fishstoragewithoutsourcebatch",
                search: `?selectedAcquiringMethodId=${urlParams.get("selectedAcquiringMethodId")}&selectedAcquiringMethod=${urlParams.get("selectedAcquiringMethod")}`
            })
        }
        else
            navigate({ pathname: MENUPATH_BATCH })
    }

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create batch confirmed?</p></Space>,
            onOk() { createBatch() },
            onCancel() {},
            centered: true
        })
    }

    //--------------------
    // On lifecycle change
    //--------------------
    const onLifecycleChange = (e) => {
        setLifecycleId(e)
    }

    //----------------------------
    // On aquaculture state change
    //----------------------------
    const onAquacultureStateChange = (e) => {
        setAquacultureStageId(e)
        
        // Clear
        form.setFieldsValue({ 
            lifecycle: []
        })
        setLifecycleId(0)

        getLifecycle(e)
    }

    //---------------------------
    // On acquiring method change
    //---------------------------
    const onAcquiringMethodChange = (e) => {
        setAcquiringMethodId(e)
        
        // Clear
        form.setFieldsValue({ 
            aquacultureStage: [],
            lifecycle: []
        })
        setAquacultureStageId(0)
        setLifecycleId(0)

        getAquacultureStage(e)
    }

    //---------------------
    // On batch type change
    //---------------------
    const onBatchTypeChange = (e) => {
        setBatchTypeId(e)

        // Clear
        form.setFieldsValue({
            acquiringMethod: [],
            aquacultureStage: [],
            lifecycle: [],
        })
        setAcquiringMethodId(0)
        setAquacultureStageId(0)
        setLifecycleId(0)

        getAcquiringMethod()
    }

    //------------------
    // On species change
    //------------------
    const onSpeciesChange = (e, value) => {
        setSpeciesId(e)

        // Clear
        form.setFieldsValue({
            batchType: [],
            acquiringMethod: [],
            aquacultureStage: [],
            lifecycle: [],
        })

        if(!disableReserveStorageSelect) {
            form.setFieldsValue({
                availableStorage: [],
            })

            setStorageIds([])
            getAvailableStorage(e)
        }

        setBatchTypeId(0)
        setAcquiringMethodId(0)
        setAquacultureStageId(0)
        setLifecycleId(0)
        
        getAllBatchType()
    }

    //----------------------------
    // On available storage change
    //----------------------------
    const onAvailableStorageChange = (e) => {
        setStorageIds(e)
    }

    //------------------
    // Custom validation
    //------------------
    const validateSpecies = (() => {
        if(speciesId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Species is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            remark: ""
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight }}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={() => onCancel()} 
                    title="Batch">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create new batch</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item name="species" label="Species">
                        <SpeciesSelect withBlank={false} onChange={onSpeciesChange}/>
                    </Form.Item>

                    <Form.Item name="batchType" label="Batch Type"
                        rules={[
                            { required: true, message: "Batch type is required." },
                        ]}>
                        <Select onChange={onBatchTypeChange} allowClear showSearch optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {batchTypeOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="acquiringMethod" label="Acquiring Method"
                        rules={[
                            { required: true, message: "Acquiring method is required." },
                        ]}>
                        <Select onChange={onAcquiringMethodChange} allowClear showSearch optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {acquiringMethodOption}
                        </Select>
                    </Form.Item>

                    {/* 20211217 - For implementation only. */}
                    { OTHERSYSPARAM("ISIMPLEMENTATIONPHASE") == 0 && 
                        <>
                        <Form.Item name="aquacultureStage" label="Aquaculture Stage"
                            rules={[
                                { required: true, message: "Aquaculture stage is required."},
                            ]}>
                            <Select onChange={onAquacultureStateChange} allowClear showSearch optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {aquacultureStageOption}    
                            </Select>
                        </Form.Item>

                        <Form.Item name="lifecycle" label="Lifecycle"
                            rules={[
                                { required: true, message: "Lifecycle is required."},
                            ]}>
                            <Select onChange={onLifecycleChange} allowClear showSearch optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {lifecycleOption}    
                            </Select>
                        </Form.Item>
                        </>
                    }

                    {/* 20211217 */}
                    { OTHERSYSPARAM("ISIMPLEMENTATIONPHASE") == 1 && 
                        <CommonSearchFormItem onAquacultureStageChange={onAquacultureStateChange} onLifecycleChange={onLifecycleChange} formLayout={formLayout}/>
                    }
                    
                    <Form.Item name="availableStorage" label="Assign To Storage">
                        <Select mode="multiple" allowClear onChange={onAvailableStorageChange} style={{width: optionWidth}} 
                            disabled={disableReserveStorageSelect} defaultValue={urlParams.has("storage") ? [urlParams.get("storage")] : []} 
                            placeholder="Please select storage"
                            showSearch optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {availableStorageOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="remark" label="Remark">
                        <TextArea rows={3}/>
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Save</Button>
                            <Button type="primary" htmlType="button" disabled={disableButton} onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>
            
            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                    title="Batch:"
                    subTitle="Create new batch"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default BatchNew