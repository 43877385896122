import  { useEffect, useState } from 'react'
import { Button, Col, Collapse, Row, Space, Form, Table, Card, Typography, Pagination, Spin } from "antd"
import { PlusOutlined, LeftOutlined } from "@ant-design/icons"
import { formLayout, formLayout_2Columns } from '../Common/Layout'
import axios from 'axios'
import { ACISAPIURL, DATETIMEFORMAT, MENUPATH_STANDARDGROWTHTABLE, PAGESIZE, PAGINATIONSIZE, LOADING } from '../Common/SystemParameter'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { numberWithCommas, reportError } from '../Common/Utility'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { Title } = Typography

const StandardGrowthTable = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    
    const [lifecycleId, setLifecycleId] = useState(0)
    const [aquacultureStageId, setAquacultureStageId] = useState(0)
    
    const [growthRecordDataSource, setGrowthRecordDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [active, setActive] = useState(false)
    const [activeColor, setActiveColor] = useState("red")
    const [numberOfRecord, setNumberOfRecord] = useState(0)
    const [createdOn, setCreatedOn] = useState(null)
    const [updatedOn, setUpdatedOn] = useState(null)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [archive, setArchive] = useState(urlParams.has("fromArchive") ? "Archive" : "")

    //-------------------------
    // Get growth table
    //-------------------------
    const getGrowthTable = () => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}standardgrowth/table/${urlParams.get("pKey")}/`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            setSpeciesId(response.data.species_data.pKey)
            setSpecies(response.data.species_data.short_name)
            setNumberOfRecord(response.data.record_count)
            setActive(response.data.active ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO"))    
            setActiveColor(response.data.active ? "blue" : "red")
            setCreatedOn(moment(response.data.createdOn).format(DATETIMEFORMAT))
            setUpdatedOn(moment(response.data.updatedOn).format(DATETIMEFORMAT))
        })
        .catch( error => {
            reportError(error, "Failed to get standard growth table.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------
    // Search growth record
    //---------------------
    const searchGrowthRecord = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}standardgrowth/`, {
            params: { 
                standard_growth_table: urlParams.get("pKey"),
                lifecycle: lifecycleId,
                aquaculture_stage: aquacultureStageId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            pushData(response)
        })
        .catch( error => {
            reportError(error, "Failed to search standard growth record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const pushData = (response) => {
        const data = []
        response.data.results.forEach( growth => {
            data.push({
                key: growth.pKey,
                aquacultureStage: growth.aquaculture_stage_data.name,
                aquacultureStageId: growth.aquaculture_stage_data.pKey,
                lifecycle: growth.lifecycle_data.name,
                lifecycleId: growth.lifecycle_data.pKey,
                day: numberWithCommas(growth.day),
                averageWeight: numberWithCommas(growth.average_per_unit_weight_gram),
                averageLength: numberWithCommas(growth.average_per_unit_length_mm),
                mortalityRate: numberWithCommas(growth.mortality_percentage)
            })
        })
        
        setGrowthRecordDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ 
            pathname: "/standardgrowthnew",
            search: `?tablePKey=${urlParams.get("pKey")}&speciesId=${speciesId}`
        })
    }

    //------------------
    // Go to import page
    //------------------
    const onBack = () => {
        if(urlParams.has("fromArchive"))
            navigate({ 
                pathname: "/standardgrowthtablearchive", 
            }) 
        else
            navigate({ 
                pathname: MENUPATH_STANDARDGROWTHTABLE, 
            }) 
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchGrowthRecord(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                let params = new URLSearchParams(`?pKey=${record.key}&aquacultureStageId=${record.aquacultureStageId}&aquacultureStage=${record.aquacultureStage}&lifecycleId=${record.lifecycleId}&day=${record.day}&averageWeight=${record.averageWeight}&averageLength=${record.averageLength}&mortalityPercentage=${record.mortalityRate}&tablePKey=${urlParams.get("pKey")}&speciesId=${speciesId}`) 

                if(urlParams.has("fromArchive")) params.append("fromArchive", true)

                navigate({ 
                    pathname: "/standardgrowthupdate", 
                    search: params.toString()
                }) 
            }
        }
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchGrowthRecord(1)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getGrowthTable()
        searchGrowthRecord(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Day', dataIndex: 'day', key: 'day', sorter: (a, b) => a.day - b.day },
        { title: 'Average Length (mm)', dataIndex: 'averageLength', key: 'averageLength', align: 'right', sorter: (a, b) => a.averageLength - b.averageLength },
        { title: 'Average Weight (g)', dataIndex: 'averageWeight', key: 'averageWeight', align: 'right', sorter: (a, b) => a.averageWeight - b.averageWeight },
        { title: 'Mortality Rate (%)', dataIndex: 'mortalityRate', key: 'mortalityRate', align: 'right', sorter: (a, b) => a.mortalityRate - b.mortalityRate },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Form {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Species: ${species}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">No. of Records In Table:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberOfRecord}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Active:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{color: activeColor}}>{active}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{createdOn}</Card.Grid>
                     
                            { updatedOn != createdOn &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{updatedOn}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Form.Item>
                </Form>

                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Growth Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <CommonSearchFormItem onAquacultureStageChange={setAquacultureStageId} onLifecycleChange={setLifecycleId}/>
                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form> 
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={8} style={{textAlign: "start"}}>
                <Button type="primary" htmlType="button" onClick={onBack} icon={<LeftOutlined />} disabled={disableButton}>Back</Button>
            </Col>
            <Col span={8} style={{textAlign: "center"}}>
                <Card title={`${archive} Standard Growth Daily Record`} style={{textAlign: "left"}}>
                    Daily standard growth record of the species.
                </Card>
            </Col>
            <Col span={8} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />} disabled={disableButton}>New</Button>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={growthRecordDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}>
                <Button type="primary" htmlType="button" onClick={onBack} icon={<LeftOutlined />} disabled={disableButton}>Back</Button>
            </Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />} disabled={disableButton}>New</Button>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default StandardGrowthTable