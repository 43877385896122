import { Table, Modal, Menu, Dropdown, message } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { EGGS, MENUKEY_GROWTHDEV, PAGINATIONSIZE, TAGGED, UNTAGGED} from './SystemParameter'
import { numberWithCommas } from './Utility'
import axios from "axios"
import { ACISAPIURL } from "../Common/SystemParameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { reportError } from "../Common/Utility"
import { inComponentSubMenu, inComponentSubMenuPath } from './InComponentSubMenu'

//--------------------------------------------------
// Plain javascript. Not React functional component.
//--------------------------------------------------
const { confirm } = Modal

export const showBatchStorageStockSummary = async (record, redirect) => {  
    let selectedStorage = null

    const onClick = (e) => {
        const [pathname, destination, searchParams] = inComponentSubMenuPath(e.key)
        let search = `?storageId=${selectedStorage.key}${searchParams}`

        if(parseInt(e.key) == MENUKEY_GROWTHDEV) search = `${search}&batchId=${record.key}` 
        
        modal.destroy()
        message.info(`You have been redirected to ${destination} page.`)
        // Redirect to target page.
        redirect(pathname, search)
    }

    //-----------------------
    // Dropdown menu
    //-----------------------
    const menu = (
        <Menu onClick={onClick} triggerSubMenuAction={"click"}>
            {inComponentSubMenu()}
        </Menu>
    )

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                selectedStorage = record
            }
        }
    }

    //--------------------------
    // Get storage stock summary
    //--------------------------
    let batchStorageStockSummaryDataSource = await generateBatchStorageStockSummaryDataSource(record.key, record.marineLifeId)
    
    // Empty storage will not be included in batchStorageStockSummaryDataSource.
    // Use createBatchStorageStockSummaryDummyDataSource to add those empty storage rows into batchStorageStockSummaryDataSource.
    if(batchStorageStockSummaryDataSource.length < record.storageList.length) {
        const excludeStorageList = batchStorageStockSummaryDataSource.map( storage => storage.key )
        batchStorageStockSummaryDataSource = batchStorageStockSummaryDataSource.concat(await createBatchStorageStockSummaryDummyDataSource(record.storageList, excludeStorageList))
    }

    const averageSummaryColumns = [
        { title: '', dataIndex: 'itemType', key: 'itemType' }, //sorter: (a, b) => a.itemType.localeCompare(b.itemType)},
        { title: 'Total Quantity', dataIndex: 'totalQty', key: 'totalQty', align: 'right' },//sorter: (a, b) => a.totalQty.localeCompare(b.totalQty)},
        { title: 'Average Weight (g)', dataIndex: 'averageWeight', key: 'averageWeight', align: 'right' },//sorter: (a, b) => stringNumberSorter(a.averageWeight, b.averageWeight) },
        { title: 'Average Length (mm)', dataIndex: 'averageLength', key: 'averageLength', align: 'right' },//sorter: (a, b) => stringNumberSorter(a.averageLength, b.averageLength) },
    ]

    const columns = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage)},
        { title: EGGS, dataIndex: 'e', key: 'e', /*align: 'right'*/ }, 
        { title: TAGGED, dataIndex: 'tf', key: 'tf', /*align: 'right'*/ },
        { title: UNTAGGED, dataIndex: 'utf', key: 'utf', /*align: 'right'*/ },
        { title: "Go To", align: 'center',
            render: () => {
                return <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link" >...</a>
                </Dropdown>
            }
        },
    ]

    //--------------
    // Summary Modal
    //--------------
    const showSummary = async () => {
        try {
            const batchSummaryDataSource = await getBatchSummaryDataSource(record)

            confirm({
                title: <>
                        <div><h3>Summary of Batch ID: {record.id}</h3></div>
                        <div>Age (days): {record.ageDays}</div>
                        </>,
                icon: null,
                width: 700,
                onOk() {},
                okText: "Close",
                cancelButtonProps: {style: {display: "none"}},
                centered: true,
                content: <Table bordered columns={averageSummaryColumns} pagination={false} dataSource={batchSummaryDataSource} />
            })
        }
        catch(error) {
        }
    }
    
    //-----------
    // Main modal
    //-----------
    const modal = 
        Modal.info({
        title:
            <div>
                <h3 onClick={showSummary}>
                    Batch ID: {record.id} <InfoCircleOutlined style={{color: "blue", width: "50px"}}/>
                </h3>
            </div>,
        icon: null,
        width: 700,
        centered: true,
        onOk() {},
        okText: "Close",
        cancelButtonProps: {style: {display: "none"}},
        content: 
            <Table bordered columns={columns} onRow={onRowClick} pagination={{size: PAGINATIONSIZE}} dataSource={batchStorageStockSummaryDataSource} />
    })
}

export const getBatchSummaryDataSource = (record) => {
    return new Promise( async (resolve, reject) => {
        try {
            const batchStorageSummary = await getBatchStockSummary(record.key)
            const batchTaggedAverageSummary = await getBatchTaggedAverageSummary(record.key)
            
            const batchSummaryDataSource = []
            batchSummaryDataSource.push({
                itemType: EGGS,
                totalQty: batchStorageSummary.totalEggQty,
                averageWeight: "-",
                averageLength: "-",
            })

            batchSummaryDataSource.push({
                itemType: TAGGED,
                totalQty: batchStorageSummary.totalTaggedQty,
                averageWeight: batchTaggedAverageSummary.averageWeight,
                averageLength: batchTaggedAverageSummary.averageLength,
            })

            batchSummaryDataSource.push({
                itemType: UNTAGGED,
                totalQty: batchStorageSummary.totalUntaggedQty,
                averageWeight: record.batchAverageWeight != 0 ? numberWithCommas(record.batchAverageWeight) : "-",
                averageLength: record.batchAverageLength != 0 ? numberWithCommas(record.batchAverageLength) : "-",
            })

            resolve(batchSummaryDataSource)
        }
        catch(error) {
            reject(error)
        }
    })
}

const getBatchTaggedAverageSummary = (batchId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${ACISAPIURL}batchtaggedaveragesummary/`, { 
            params: {
                batch: batchId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let data = {}

            let averageWeight = "-"
            let averageLength = "-"

            if(response.data.length > 0) {
                averageWeight = response.data[0].averageweight
                averageLength = response.data[0].averagelength
            }

            data = {
                "averageWeight": averageWeight,
                "averageLength": averageLength
            }

            resolve(data)
        })
        .catch( error => {
            reportError(error, "Failed to get batch tagged average summary.")
            reject(error)
        })
        .finally(() => {
            refreshUserSession()
        })
    })
}

const getBatchStockSummary = async (batchId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${ACISAPIURL}batchstocksummary/`, { 
            params: {
                batch: batchId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let data = {}
            
            let totalEggQty = '-'
            const eggRow = response.data.results.filter( summary => summary.item_type_name.startsWith(EGGS))
            if(eggRow.length > 0 && eggRow[0].quantity > 0) totalEggQty = `${numberWithCommas(eggRow[0].quantity)} ${eggRow[0].uom_name}`

            let totalTaggedQty = '-'
            const taggedRow = response.data.results.filter( summary => summary.item_type_name.startsWith(TAGGED))
            if(taggedRow.length > 0 && taggedRow[0].quantity > 0) totalTaggedQty = `${numberWithCommas(taggedRow[0].quantity)} ${taggedRow[0].uom_name}`

            let totalUntaggedQty = '-'
            const untaggedRow = response.data.results.filter( summary => summary.item_type_name.startsWith(UNTAGGED))
            if(untaggedRow.length > 0 && untaggedRow[0].quantity > 0) totalUntaggedQty = `${numberWithCommas(untaggedRow[0].quantity)} ${untaggedRow[0].uom_name}`

            data = {
                "totalEggQty": totalEggQty,
                "totalTaggedQty": totalTaggedQty,
                "totalUntaggedQty": totalUntaggedQty,
            }

            resolve(data)
        })
        .catch( error => {
            reportError(error, "Failed to get batch total quantity and average summary.")
            reject(error)
        })
        .finally(() => {
            refreshUserSession()
        })
    })
}

const generateBatchStorageStockSummaryDataSource = (batchId, marineLifeId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${ACISAPIURL}batchstoragestocksummary/`, { 
            params: {
                batch: batchId,
                marine_life: marineLifeId
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const data = []

            // Compress 3 item types into one row for each storage.
            let storageIdSet = new Set(response.data.results.map( summary => summary.storage))

            storageIdSet.forEach( storageId => {
                const storageRows = response.data.results.filter( row => row.storage == storageId)

                let eggQty = "-"
                const eggRow = storageRows.filter( row => row.item_type_name.startsWith(EGGS))
                if(eggRow.length > 0 && eggRow[0].quantity > 0) eggQty = `${eggRow[0].quantity} ${eggRow[0].uom_name}`

                let taggedQty = "-"
                const taggedRow = storageRows.filter( row => row.item_type_name.startsWith(TAGGED))
                if(taggedRow.length > 0 && taggedRow[0].quantity > 0) taggedQty = `${taggedRow[0].quantity} ${taggedRow[0].uom_name} (≈${taggedRow[0].tagged_average_weight_gram}g)`

                let untaggedQty = "-"
                const untaggedRow = storageRows.filter( row => row.item_type_name.startsWith(UNTAGGED))
                if(untaggedRow.length > 0 && untaggedRow[0].quantity > 0) untaggedQty = `${untaggedRow[0].quantity} ${untaggedRow[0].uom_name} (≈${untaggedRow[0].untagged_average_weight_gram}g)`
                
                data.push({
                    key: storageId,
                    e: numberWithCommas(eggQty),
                    tf: numberWithCommas(taggedQty),
                    utf: numberWithCommas(untaggedQty),
                    //storage: storageRows[0].storageid,
                    storage: storageRows[0].storage_name,
                })
            })

            resolve(data)
        })
        .catch( error => {
            reportError(error, "Failed to get batch storage stock summary.")
            reject(error)
        })
        .finally(() => {
            refreshUserSession()
        })
    })
}

const createBatchStorageStockSummaryDummyDataSource = (storageList, excludeStorageList) => {
    return new Promise((resolve, reject) => {
        let data = []

        storageList.filter( storage => !excludeStorageList.includes(storage.pKey)).forEach( storage => {
            data.push({
                key: storage.pKey,
                e: "-",
                tf: "-",
                utf: "-",
                //storage: storage.id,
                storage: storage.name,
            })
        })

        resolve(data)
    })
}