import { Table, Row, Col, Space, Button, Form, message, Card, Typography, Pagination, Spin } from 'antd'
import { LeftOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, LOADING, MENUPATH_INVENTORYFORECAST, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout } from "../Common/Layout"
import moment from 'moment'

const { Title } = Typography

//----------
// Component
//----------
const InventoryForecastBatchStorageTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
        
    const navigate = useNavigate()
        
    const [batchStorageDataSource, setBatchStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------
    // Search forecast
    //---------------
    const searchInventoryForecast = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)

        axios.get(`${ACISAPIURL}inventory/forecast/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: urlParams.get("fromDate"),
                toDate: urlParams.get("toDate"),
                batch: urlParams.get("searchBatchId"),
                storage: urlParams.get("searchStorageId"),
                marine_life: urlParams.get("marineLifeId"),
                species: urlParams.get("speciesId"),
                batch_type: urlParams.get("batchTypeId"),
                from_weight: urlParams.get("fromWeight"),
                to_weight: urlParams.get("toWeight"),
                fish: urlParams.get("fishId"),
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            pushData(response)
        })
        .catch( error => {
            reportError(error, "Failed to search forecasted data.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const pushData = (response) => {
        const data = []
        response.data.results.forEach( inv => {
            data.push({
                key: inv.pKey,
                batchId: inv.batch_data.pKey,
                batch: inv.batch_data.id,
                storageId: inv.storage_data.pKey,
                storage: inv.storage_data.name,
                batchTypeId: inv.batch_type_data.pKey,
                batchType: inv.batch_type_data.name,
                ageInDays: numberWithCommas(inv.ageindays),
                ageInDate: moment(inv.ageindate).format(DATEFORMAT),
                ageInDateUniFormat: moment(inv.ageindate).format(UNIDATEFORMAT),
                averageLengthMm: numberWithCommas(inv.average_length_mm),
                averageWeightGram: numberWithCommas(inv.average_weight_gram),
                quantity: numberWithCommas(inv.quantity),
                totalWeightKg: numberWithCommas(inv.total_weight_kg),
            })
        })
        
        setBatchStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nSpecies: ${urlParams.get("species")}`
        if(urlParams.get("fromDate") != "") searchCriteria = `${searchCriteria}\nDate >= ${moment(urlParams.get("fromDate")).format(DATEFORMAT)}`
        if(urlParams.get("toDate") != "") searchCriteria = `${searchCriteria}\nDate <= ${moment(urlParams.get("toDate")).format(DATEFORMAT)}`
        if(urlParams.get("marineLifeId") != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${urlParams.get("marineLife")}`
        if(urlParams.get("speciesId") != 0) searchCriteria = `${searchCriteria}\nSpecies: ${urlParams.get("species")}`
        if(urlParams.get("searchBatchId") != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${urlParams.get("searchBatch")}`
        if(urlParams.get("searchStorageId") != 0) searchCriteria = `${searchCriteria}\nStorage: ${urlParams.get("searchStorage")}`
        if(urlParams.get("fishId") != 0) searchCriteria = `${searchCriteria}\nTag ID: ${urlParams.get("fish")}`
        if(urlParams.get("batchTypeId") != 0) searchCriteria = `${searchCriteria}\nBatch Type: ${urlParams.get("batchType")}`
        if(urlParams.get("fromWeight") >= 0) searchCriteria = `${searchCriteria}\nWeight >= ${numberWithCommas(urlParams.get("fromWeight"))}g`
        if(urlParams.get("toWeight") >=0) searchCriteria = `${searchCriteria}\nWeight <= ${numberWithCommas(urlParams.get("toWeight"))}g`
                
        axios.get(`${ACISAPIURL}inventory/forecast/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: urlParams.get("fromDate"),
                toDate: urlParams.get("toDate"),
                batch: urlParams.get("searchBatchId"),
                storage: urlParams.get("searchStorageId"),
                marine_life: urlParams.get("marineLifeId"),
                species: urlParams.get("speciesId"),
                batch_type: urlParams.get("batchTypeId"),
                from_weight: urlParams.get("fromWeight"),
                to_weight: urlParams.get("toWeight"),
                fish: urlParams.get("fishId"),
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Inventory Forecast ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchInventoryForecast(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/inventoryforecastdetail", 
                    search: `?numOfBatches=${urlParams.get("numOfBatches")}&numOfStorages=${urlParams.get("numOfStorages")}&totalQty=${urlParams.get("totalQty")}&totalWeightKg=${urlParams.get("totalWeightKg")}&fromDate=${urlParams.get("fromDate")}&toDate=${urlParams.get("toDate")}&searchBatchId=${urlParams.get("searchBatchId")}&searchBatch=${urlParams.get("searchBatch")}&searchStorageId=${urlParams.get("searchStorageId")}&searchStorage=${urlParams.get("searchStorage")}&batchId=${record.batchId}&batch=${record.batch}&storageId=${record.storageId}&storage=${record.storage}&marineLifeId=${urlParams.get("marineLifeId")}&marineLife=${urlParams.get("marineLife")}&speciesId=${urlParams.get("speciesId")}&species=${urlParams.get("species")}&batchTypeId=${urlParams.get("batchTypeId")}&batchType=${record.batchType}&fromWeight=${urlParams.get("fromWeight")}&toWeight=${urlParams.get("toWeight")}&fishId=${urlParams.get("fishId")}&fish=${urlParams.get("fish")}&averageWeightGram=${record.averageWeightGram}&averageLengthMm=${record.averageLengthMm}&batchQuantity=${record.quantity}&batchTotalWeightKg=${record.totalWeightKg}&ageInDate=${record.ageInDateUniFormat}&ageInDays=${record.ageInDays}`
                }) 
            }
        }
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({
            pathname: MENUPATH_INVENTORYFORECAST
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchInventoryForecast(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        { title: 'Age (date)', dataIndex: 'ageInDate', key: 'ageInDate', sorter: (a, b) => a.ageInDate.localeCompare(b.ageInDate) },
        { title: 'Age (days)', dataIndex: 'ageInDays', key: 'ageInDays', align: 'right', sorter: (a, b) => stringNumberSorter(a.ageInDays, b.ageInDays) },
        { title: 'Avg. Length (mm)', dataIndex: 'averageLengthMm', key: 'averageLengthMm', align: 'right', sorter: (a, b) => stringNumberSorter(a.averageLengthMm, b.averageLengthMm) },
        { title: 'Avg. Weight (gram)', dataIndex: 'averageWeightGram', key: 'averageWeightGram', align: 'right', sorter: (a, b) => stringNumberSorter(a.averageWeightGram, b.averageWeightGram) },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', align: 'right', sorter: (a, b) => stringNumberSorter(a.quantity, b.quantity) },
        { title: 'Total Weight (Kg)', dataIndex: 'totalWeightKg', key: 'totalWeightKg', align: 'right', sorter: (a, b) => stringNumberSorter(a.totalWeightKg, b.totalWeightKg) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Species: ${urlParams.get("species")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Number of Batches:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("numOfBatches")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Number of Storages:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("numOfStorages")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Total Quantity:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("totalQty")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Total Weight (Kg):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("totalWeightKg")}</Card.Grid>

                    { urlParams.get("fromDate") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">From Date:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{moment(urlParams.get("fromDate")).format(DATEFORMAT)}</Card.Grid>
                        </>
                    }      
                    { urlParams.get("toDate") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">To Date:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{moment(urlParams.get("toDate")).format(DATEFORMAT)}</Card.Grid>
                        </>
                    }
                    { urlParams.get("searchBatch") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("searchBatch")}</Card.Grid>
                        </>
                    }
                    { urlParams.get("searchStorage") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("searchStorage")}</Card.Grid>
                        </>
                    }
                    { urlParams.get("batchType") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                        </>
                    }
                    { urlParams.get("marineLife") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Marine Life:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("marineLife")}</Card.Grid>
                        </>
                    }
                    { urlParams.get("fromWeight") != "-1" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">From Weight (g):</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("fromWeight")}</Card.Grid>
                        </>
                    }
                    { urlParams.get("toWeight") != "-1" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">To Weight (g):</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("toWeight")}</Card.Grid>
                        </>
                    }
                    { (urlParams.get("fish") != "undefined" && urlParams.get("fish") != "") &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Tag ID:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("fish")}</Card.Grid>
                        </>
                    }
                </Card>
            </Form.Item>
        </Form>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={12} style={{textAlign: "start"}}><Button type="primary" htmlType="button" disabled={disableButton} onClick={onBack} icon={<LeftOutlined />}>Back</Button></Col>
            <Col span={12} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
       </Col>
            
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={batchStorageDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}><Button type="primary" htmlType="button" disabled={disableButton} onClick={onBack} icon={<LeftOutlined />}>Back</Button></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default InventoryForecastBatchStorageTable
