import { Table, Row, Col, Space, Button, Form, Collapse, message, Layout, Popconfirm, PageHeader, Descriptions, Pagination, Spin } from 'antd'
import { RightOutlined, ProfileOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, LOADING, MEDIAMAXWIDTH, MENUPATH_STOCKTRANSACTION, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT, UNTAGGED } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from '../Common/Utility'
import { formLayout_2Columns, walltiles } from '../Common/Layout'
import AcquiringMethodSelect from '../Common/AcquiringMethodSelect'
import BatchTypeSelect from '../Common/BatchTypeSelect'
import ACISFooter from '../Common/ACISFooter'
import ACISHeader from '../Common/ACISHeader'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const FishSourceBatch = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [disableButton, setDisableButton] = useState("")
    const [searchForm] = Form.useForm()
    const navigate = useNavigate()

    const [searchStorageId, setSearchStorageId] = useState(0)
    const [searchBatchId, setSearchBatchId] = useState(0)
    const [searchBatchTypeId, setSearchBatchTypeId] = useState(0)
    const [searchAcquiringMethodId, setSearchAcquiringMethodId] = useState(0)
    const [searchAquacultureStageId, setSearchAquacultureStageId] = useState(0)
    const [searchLifecycleId, setSearchLifecycleId] = useState(0)
    const [searchSpeciesId, setSearchSpeciesId] = useState(0)
    const [searchMarineLifeId, setSearchMarineLifeId] = useState(0)
            
    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const searchStorageByItemType = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storagepaginatedbyitemtype/`, {
            params: { 
                site: getUserSiteId(),
                batch: searchBatchId,
                id: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : searchStorageId,
                marine_life: searchMarineLifeId,
                species: searchSpeciesId,
                acquiring_method: searchAcquiringMethodId,
                aquaculture_stage: searchAquacultureStageId,
                lifecycle: searchLifecycleId,
                batch_type: searchBatchTypeId, 
                //item_types: `${SYSPARAM("Untagged Fish")}, ${SYSPARAM("Untagged (Crab)")}, ${SYSPARAM("Untagged (Prawn)")}, ${SYSPARAM("Untagged (Sea Cucumber)")}`,
                item_types_like: UNTAGGED,
                empty: false,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPushByItemType(response)
        })
        .catch( error => {
            reportError(error, "Failed to search storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPushByItemType = (response) => {
        let data = []
        response.data.results
            .forEach( storage => {
            data.push({
                key: storage.id,
                id: storage.storage_name,
                storageId: storage.storage,
                storageType: storage.storage_type_data.name,
                batchId: storage.batch_data.pKey,
                batch: storage.batch_data.id,
                batchType: storage?.batch_data?.batch_type_data?.name == null ? "" : storage.batch_data.batch_type_data.name,
                batchTypeId: storage?.batch_data?.batch_type_data?.pKey == null ? 0 : storage.batch_data.batch_type_data.pKey,
                acquiringMethod: storage?.batch_data?.acquiring_method_data == null ? "" : storage.batch_data.acquiring_method_data.name,
                acquiringMethodId: storage?.batch_data?.acquiring_method_data == null ? 0 : storage.batch_data.acquiring_method_data.pKey,
                aquacultureStage: storage?.batch_data?.aquaculture_stage_data.name == null ? "" : storage.batch_data.aquaculture_stage_data.name,
                aquacultureStageId: storage?.batch_data?.aquaculture_stage_data.pKey == null ? 0 : storage.batch_data.aquaculture_stage_data.pKey,
                lifecycle: storage?.batch_data?.lifecycle_data == null ? "" : storage.batch_data.lifecycle_data.name,
                lifecycleId: storage?.batch_data?.lifecycle_data == null ? 0 : storage.batch_data.lifecycle_data.pKey,
                speciesId: storage?.batch_data?.species_data?.pKey,
                species: storage?.batch_data?.species_data?.short_name,
                itemTypeId: storage.item_type,
                itemTypeLabel: `${storage.item_type_name} (${storage.uom_name})`,
                itemType: storage.item_type_name,
                uomId: storage.uom,
                uom: storage.uom_name,
                quantity: numberWithCommas(storage.quantity),
                
                averageWeight: storage.batch_data.average_per_unit_weight_gram,
                averageLength: storage.batch_data.average_per_unit_weight_gram,
                birthDate: moment(storage.batch_data.birth_date).format(UNIDATETIMEFORMAT),
                averageWeightUpdatedOn: moment(storage.batch_data.average_weight_updatedOn).format(UNIDATETIMEFORMAT),
                averageLengthUpdatedOn: moment(storage.batch_data.average_length_updatedOn).format(UNIDATETIMEFORMAT),
                birthDateUpdatedOn: moment(storage.batch_data.birth_date_updatedOn).format(UNIDATETIMEFORMAT),

                marineLifeId: storage?.batch_data?.species_data?.marine_life_data.pKey,
            })
        })

        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStorageByItemType(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStorageByItemType(1)
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                // Source batch must have a storage for deduct of quantity.
                if(record.storageId == 0) {
                    message.warn("Selected source batch has no storage.")
                    return
                }

                // Source batch must have > 0 non fish stock quantity for deduct of quantity.
                if(record.collectiveFishStockQuantity == 0) {
                    message.warn("Selected source storage has zero untagged fish quantity.")
                    return
                }
        
                navigate({ 
                    pathname: "/fishstorage", 
                    search: `?sourceBatchId=${record.batchId}&sourceBatch=${record.batch}&sourceBatchStorageId=${record.storageId}&sourceBatchStorage=${record.id}&speciesId=${record.speciesId}&species=${record.species}&acquiringMethodId=${record.acquiringMethodId}&acquiringMethod=${record.acquiringMethod}&aquacultureStageId=${record.aquacultureStageId}&aquacultureStage=${record.aquacultureStage}&lifecycleId=${record.lifecycleId}&lifecycle=${record.lifecycle}&batchTypeId=${record.batchTypeId}&batchType=${record.batchType}&averageWeight=${record.averageWeight}&averageLength=${record.averageLength}&birthDate=${record.birthDate}&averageWeightUpdatedOn=${record.averageWeightUpdatedOn}&averageLengthUpdatedOn=${record.averageLengthUpdatedOn}&birthDateUpdatedOn=${record.birthDateUpdatedOn}&availableQuantity=${record.quantity.replace(',','')}&uom=${record.uom}&itemTypeId=${record.itemTypeId}&marineLifeId=${record.marineLifeId}`
                }) 
            }
        }
    }
    
    //---------------------------
    // On search criteria change
    //---------------------------
    const onAcquiringMethodChange = (e, value) => {
        setSearchAcquiringMethodId(e)
    }

    const onBatchTypeChange = (e, value) => {
        setSearchBatchTypeId(e)
    }

    const onMarineLifeChange = (e, value) => {
        setSearchMarineLifeId(e)
    }

    //------------------
    // Go to create page
    //------------------
    const onNext = (record) => {
        navigate({ 
            pathname: "/fishstoragewithoutsourcebatch", 
        })
    }

    //------------------------
    // Redirect to listing page
    //------------------------
    const onGoToListing = () => {
        navigate({ pathname: MENUPATH_STOCKTRANSACTION })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setSearchStorageId(urlParams.get("storageId"))
            searchStorageByItemType(currentPage, urlParams.get("storageId"))
        }
        else
            searchStorageByItemType(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        !isTabletOrMobile ? { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) } : {},
        !isTabletOrMobile ? { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) } : {},
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Item Type', dataIndex: 'itemTypeLabel', key: 'itemTypeLabel', sorter: (a, b) => a.itemTypeLabel.localeCompare(b.itemTypeLabel) },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', align: "right", sorter: (a, b) => stringNumberSorter(a.quantity, b.quantity) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={null} 
                    title="New Tag">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Select source batch & storage</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row>
                    <Col span={1}></Col>
                    <Col span={22}>
                        <Collapse defaultActiveKey={["0"]}>
                            <Panel header="Search Source Batch & Storage" key="1">
                                <Form form={searchForm} {...formLayout_2Columns}>
                                    <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={setSearchSpeciesId} onBatchChange={setSearchBatchId} 
                                        onStorageChange={setSearchStorageId} onAquacultureStageChange={setSearchAquacultureStageId}
                                        onLifecycleChange={setSearchLifecycleId} showLockedStorage={false} defaultStorageId={searchStorageId}/>

                                    <Form.Item name="acquiringMethodId" label="Acquiring Method">
                                        <AcquiringMethodSelect withBlank={true} onChange={onAcquiringMethodChange}/>
                                    </Form.Item>

                                    <Form.Item name="batchTypeId" label="Batch Type">
                                        <BatchTypeSelect withBlank={true} onChange={onBatchTypeChange}/>
                                    </Form.Item>

                                    <Row justify="center">
                                        <Col span={6}></Col>
                                        <Col span={12} style={{textAlign: "center"}}>
                                            <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                            <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                        </Col>
                                        <Col span={6}></Col>
                                    </Row>
                                </Form>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={1}></Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={11} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onGoToListing} icon={<ProfileOutlined />}>Transaction Listing</Button></Col>
                    <Col span={11} style={{textAlign: "end"}}>
                        <Popconfirm title="Tag marine life without source batch confirmed?" onConfirm={onNext} okText="Yes" cancelText="No">
                            <Button danger type="primary" htmlType="button" disabled={disableButton} icon={<RightOutlined />}>Skip</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={1}></Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row>
                    <Col span={1}></Col>
                    <Col span={22}>
                        <Table bordered columns={columns} dataSource={storageDataSource} pagination={false} onRow={onRowClick}/>
                    </Col>
                    <Col span={1}></Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={3} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onGoToListing} icon={<ProfileOutlined />}>Transaction Listing</Button></Col>
                    <Col span={16} style={{textAlign: "center"}}>
                        <Pagination
                            size={PAGINATIONSIZE}
                            total={totalRecord}
                            showTotal={showTotal}
                            pageSize={PAGESIZE}
                            current={currentPage}
                            hideOnSinglePage={false}
                            showSizeChanger={false}
                            onChange={onPaginationChange}/>
                    </Col>
                    <Col span={3} style={{textAlign: "end"}}>
                        <Popconfirm title="Tag marine life without source batch confirmed?" onConfirm={onNext} okText="Yes" cancelText="No">
                            <Button danger type="primary" htmlType="button" disabled={disableButton} icon={<RightOutlined />}>Skip</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={1}></Col>
                </Row>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={null} 
                        title="New Tag:"
                        subTitle="Select source batch & storage"/>} />
            </Footer>
        </Layout>
        </Spin>
        </>)
}

export default FishSourceBatch