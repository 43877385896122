import { Row, Col, Space, Typography, Card, Button } from 'antd'
import { CloseOutlined } from "@ant-design/icons"
import { Line } from '@ant-design/charts'
import { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, AMMONIA, DO, EC, ENVDATA_VALUE_DECIMALPLACES, NITRATE, NITRITE, ORP, PH, SALINITY, TDS, TEMPERATURE, TIMENOSECFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { getCookie, reportError } from "../Common/Utility"
import moment from "moment"

const { Title } = Typography


/*
20220405
Due to be able to access from Power BI, this page is made public which required no authentication.
*/


const CentralisedConsoleGraphTable = () => {
    const [doGraph, setDOGraph] = useState([])
    const [orpGraph, setORPGraph] = useState([])
    const [phGraph, setPHGraph] = useState([])
    const [temperatureGraph, setTemperatureGraph] = useState([])
    const [salinityGraph, setSalinityGraph] = useState([])
    const [ammoniaGraph, setAmmoniaGraph] = useState([])
    const [nitriteGraph, setNitriteGraph] = useState([])
    const [nitrateGraph, setNitrateGraph] = useState([])
    const [tdsGraph, setTDSGraph] = useState([])
    const [ecGraph, setECGraph] = useState([])
    
    const [doCard, setDOCard] = useState([])
    const [orpCard, setORPCard] = useState([])
    const [pHCard, setPHCard] = useState([])
    const [temperatureCard, setTemperatureCard] = useState([])
    const [salinityCard, setSalinityCard] = useState([])
    const [ammoniaCard, setAmmoniaCard] = useState([])
    const [nitriteCard, setNitriteCard] = useState([])
    const [nitrateCard, setNitrateCard] = useState([])
    const [tdsCard, setTDSCard] = useState([])
    const [ecCard, setECCard] = useState([])

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const storageName = urlParams.get("storageName")
    const storagePKey = urlParams.get("storagePKey")

    const isDarkTheme = getCookie('useDarkTheme') == '1' ? true : false
    
    //----------------------------------
    // Get all environment reading type.
    //----------------------------------
    const getAllEnvironmentReadingType = () => {
        return new Promise((resolve, reject) => {
            axios.get(`${ACISAPIURL}environmentreadingtype/`, {
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                const data = []
                response.data.results.forEach( type => {
                    data.push({
                        readingType: type.pKey,
                        readingTypeName: type.name,
                        storage: storagePKey,
                        storageName: storageName,
                        uom: type.uom,
                        minSafeValue: type.min_safe_value,
                        maxSafeValue: type.max_safe_value,
                    })
                })

                resolve(data)
            })
            .catch( error => {
                reportError(error, "Failed to get all environment reading types.")
                reject(error)
            })
        })
    }

    //----------------------
    // Load environment data
    //----------------------
    const loadEnvironmentData = (consoleList) => {
        // For each reading type, get storage environment data.
        consoleList.forEach( async consoleItem => {
            try{
                const lineData = await getEnvironmentData(consoleItem.readingType, storagePKey, consoleItem.minSafeValue, consoleItem.maxSafeValue)
                if(lineData.length > 0) {
                    plotGraph(consoleItem.readingTypeName, consoleItem.uom, lineData)
                }
            }
            catch(error) {
                reportError(error)
            }
        })
    }

    const getEnvironmentData = (readingType, storages, minSafeValue, maxSafeValue) => {
        return new Promise((resolve, reject) => {
            axios.get(`${ACISAPIURL}environmentfull/`, {
                params: { 
                    //site: getUserSiteId(),
                    storages: storages,
                    reading_type: [readingType],
                },
                timeout: 30000,
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                const data = []
                const latestData = new Map()
                response.data.forEach( history => {
                    data.push({
                        datetime: moment(history[0]).format(TIMENOSECFORMAT),
                        value: history[1],
                        storageName: history[3]
                    })

                    latestData.set(history[2], `${history[1]} ${history[4]}`)
                })

                let card = <></>
                latestData.forEach((value, key) => {
                    let color = ""
                    if(!isDarkTheme) color = "#e6f7ff"

                    let className = ""
                    if(parseFloat(value) < parseFloat(minSafeValue) || parseFloat(value) > parseFloat(maxSafeValue)) {
                        if(!isDarkTheme) color = "#fff2f0"
                        className = "blink"
                    }

                    // Remove uom and tailing spaces.
                    key = String(key).replace(/ *\([^)]*\) */g, "").trim() 
                    card = <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">{key}:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{backgroundColor: color, textAlign: "center"}}>
                            <div className={className}>
                                {parseFloat(value).toFixed(ENVDATA_VALUE_DECIMALPLACES.get(key))}
                            </div>
                        </Card.Grid>
                        </>

                    if(key == DO) 
                        setDOCard(card)
                    else if(key == ORP)
                        setORPCard(card)
                    else if(key == PH)
                        setPHCard(card)
                    else if(key == TEMPERATURE)
                        setTemperatureCard(card)
                    else if(key == SALINITY)
                        setSalinityCard(card)
                    else if(key == AMMONIA)
                        setAmmoniaCard(card)
                    else if(key == NITRITE)
                        setNitriteCard(card)
                    else if(key == NITRATE)
                        setNitrateCard(card)
                    else if(key == TDS)
                        setTDSCard(card)
                    else if(key == EC)
                        setECCard(card)
                })
                
                resolve(data)
            })
            .catch( error => {
                reportError(error, "Failed to get environment data.")
                reject(error)
            })
            .finally(() => {
                refreshUserSession()
            })
        })
    }

    //-----------
    // Plot graph
    //-----------
    const plotGraph = (readingTypeName, uom, lineData) => {
        const config = {
            data: lineData,
            xField: 'datetime',
            yField: 'value',
            seriesField: 'storageName',
            xAxis: {
                nice: true,
                label: {
                    autoRotate: true,
                    formatter: (name) => name,
                },
            },
            yAxis: {
                label: {
                    formatter: (v) => `${v} ${uom}`,
                },
            },
            // legend: {
            //     position: 'top',
            // },
            legend: false,
            smooth: true,
        }
        
        const graph = <>
            <Title>{`${readingTypeName} ${uom != "" ? "(" + uom + ")" : ""}`}</Title>
            <Line {...config} />
            </>
        
        if(readingTypeName == DO)
            setDOGraph(graph)
        else if(readingTypeName == ORP)
            setORPGraph(graph)
        else if(readingTypeName == PH)
            setPHGraph(graph)
        else if(readingTypeName == TEMPERATURE)
            setTemperatureGraph(graph)
        else if(readingTypeName == SALINITY)
            setSalinityGraph(graph)
        else if(readingTypeName == AMMONIA)
            setAmmoniaGraph(graph)
        else if(readingTypeName == NITRITE)
            setNitriteGraph(graph)
        else if(readingTypeName == NITRATE)
            setNitrateGraph(graph)
        else if(readingTypeName == TDS)
            setTDSGraph(graph)
        else if(readingTypeName == EC)
            setECGraph(graph)
    }

    //--------------------------------------------
    // Retrieve data and refresh on fixed interval
    //--------------------------------------------
    const startDisplayLoop = (consoleList) => {
        setInterval(() => {
            loadEnvironmentData(consoleList)
        }, parseInt(5) * 60000) //parseInt(OTHERSYSPARAM("CONSOLE_REFRESH_MINUTE")) * 60000)
    }

    //---------
    // On close
    //---------
    const onClose = () => {
        window.close()
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            try {
                const consoleList = await getAllEnvironmentReadingType()
                loadEnvironmentData(consoleList)
                startDisplayLoop(consoleList)
            }
            catch(error) {}
        }

        init()
    }, [])

    const columnsTemperature = [
        // { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Min. Value', dataIndex: 'minSafeValue', key: 'minSafeValue'}, //sorter: (a, b) => a.minSafeValue.localeCompare(b.storage) },
        { title: 'Max. Value', dataIndex: 'maxSafeValue', key: 'maxSafeValue'},
        { title: 'Temperature (°C)', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    const columnsDO = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'DO (mg/L)', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    const columnsPH = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'pH', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    const columnsORP = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'ORP (mV)', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    const columnsTDS = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'TDS (ppm)', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    const columnsAmmonia = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Ammonia (mg/L)', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    const columnsSalinity = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Salinity (ppt)', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    const columnsNitrite = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Nitrite (mg/L)', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    const columnsNitrate = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Nitrate (mg/L)', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    const columnsElectricalConductivity = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Electrical Conductivity (mS/cm)', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    const columnsStockQuantity = [
        { title: 'Batch Storage', dataIndex: 'batchStorage', key: 'batchStorage', sorter: (a, b) => a.batchStorage.localeCompare(b.batchStorage) },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', sorter: (a, b) => a.quantity.localeCompare(b.quantity) },
    ]

    return(
        <>
        <Row><Col><Space><div /></Space></Col></Row>
        <Row justify="center">
            <Col style={{textAlign: "left"}} span={24}>
                <Card title={<Title>{storageName}</Title>} 
                    extra={<Button type="primary" htmlType="button" onClick={onClose} icon={<CloseOutlined />} />}
                    style={{ width: '100%', border: "none" }} />
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {temperatureGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {temperatureCard}
            </Col>
            <Col span={2} />
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {doGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {doCard}
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        <Row><Col><Space><div /></Space></Col></Row>
        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {phGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {pHCard}
            </Col>
            <Col span={2} />
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {orpGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {orpCard}
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        <Row><Col><Space><div /></Space></Col></Row>
        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {ecGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {ecCard}
            </Col>
            <Col span={2} />
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {salinityGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {salinityCard}
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        <Row><Col><Space><div /></Space></Col></Row>
        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {tdsGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {tdsCard}
            </Col>
            <Col span={2} />
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {ammoniaGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {ammoniaCard}
            </Col>
        </Row>

        {/*}
        <Row><Col><Space><div /></Space></Col></Row>
        <Row><Col><Space><div /></Space></Col></Row>
        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {nitriteGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {nitriteCard}
            </Col>
            <Col span={2} />
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {nitrateGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {nitrateCard}
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        <Row><Col><Space><div /></Space></Col></Row>
        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={10} xl={10}>
                {salinityGraph}
                <Row><Col><Space><div /></Space></Col></Row>
                {salinityCard}
            </Col>
        </Row>
        */}

        <Row><Col><Space><div /></Space></Col></Row>
        <Row><Col><Space><div /></Space></Col></Row>
        </>
    )
}

export default CentralisedConsoleGraphTable
