import { useEffect } from 'react'
import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import EggDevelopmentTable from './EggDevelopmentTable'
import { OTHERSYSPARAM } from '../Common/UserSession'
import { walltiles } from '../Common/Layout'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const EggDevelopmentMain = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    
    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={null} 
                    title="Egg Development">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Manage egg development</Descriptions.Item>
                    </Descriptions>        
                </PageHeader>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><EggDevelopmentTable /></Col>
                    <Col span={1}></Col>
                </Row>

            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={null} 
                        title="Egg Development:"
                        subTitle="Manage egg development"/>} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default EggDevelopmentMain