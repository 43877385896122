import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Row, Col, Typography, Popconfirm, InputNumber, Card, Modal, Select, Divider, Input, Spin } from 'antd'
import { CloseCircleOutlined, QuestionCircleOutlined, RightOutlined, LeftOutlined, PlusOutlined } from "@ant-design/icons"
import { ACISAPIURL, EGGS, LOADING, MEDIAMAXWIDTH, MENUPATH_STOCKCOUNT } from '../Common/SystemParameter'
import { loadMarineLifeItemTypeUnitOfMeasurementStandardTable, numberWithCommas, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, SYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import TextArea from 'antd/lib/input/TextArea'
import { useMediaQuery } from 'react-responsive'

const { Title } = Typography
const { confirm } = Modal

const StockCountEggTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? 300 : 400)
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    
    const [allTransactionType, setAllTransactionType] = useState([])
    const [stockInTransactionTypeOption, setStockInTransactionTypeOption] = useState([])
    const [stockOutTransactionTypeOption, setStockOutTransactionTypeOption] = useState([])
    const [transactionTypeOption, setTransactionTypeOption] = useState([])
    const [countedStockStatus, setCountedStockStatus] = useState("")
    const [countedStockStatusColor, setCountedStockStatusColor] = useState("")
    const [eggBalance, setEggBalance] = useState(0)
    let initialEggBalance = 0
    const [isSurplus, setIsSurplus] = useState(false)
    const [isDeficit, setIsDeficit] = useState(false)

    const [itemType, setItemType] = useState("")
    const [uom, setUom] = useState("")
            
    let prevSavedUntaggedFishVarianceAdjustment = []
    let untaggedFishCountedStockBalance = 0

    const inputRef = useRef()
        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    let adjustmentQuantity = urlParams.getAll("quantity")
    let adjustmentRemark = urlParams.getAll("remark")
    let adjustmentTransactionType = urlParams.getAll("transactionType")

    // Readonly means viewing a completed stock count. No changes is allowed.
    const [readOnly, setReadOnly] = useState(urlParams.get("completedOn") == "" ? false : true)
    const [hide, setHide] = useState(urlParams.get("completedOn") == "" ? "" : "none")
    const [hideAddButton, setHideAddButton] = useState(urlParams.get("completedOn") == "" && adjustmentQuantity.length == 0 ? "" : "none")

    // Build variance adjustment initial values.
    let prevSavedVarianceAdjustment = []
    for(let i = 0; i < adjustmentQuantity.length; i++) {
        prevSavedVarianceAdjustment.push({
            quantity: adjustmentQuantity[i],
            remark: adjustmentRemark[i],
            adjustment: adjustmentTransactionType[i]
        })
    }

    //--------------------------------
    // Go to untagged fish stock count
    //--------------------------------
    const goToUntaggedFishStockCount = () => {
        setIsLoading(true)

        //-------------------------------------------------------
        // Load existing untagged fish stock count. Lift state up
        //-------------------------------------------------------
        const getUntaggedFishVarianceAdjustment = async () => {
            setDisableButton("disabled")
        
            return await axios.get(`${ACISAPIURL}stockcount/untaggedfish/`, {
                params: { 
                    stock_count: urlParams.get("pKey"),
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                response.data.results.forEach( utfStockCount => {
                    untaggedFishCountedStockBalance = utfStockCount.counted_balance
                        
                    let data = []
                    utfStockCount.variance_adjustment.forEach( adj => {
                        data.push({
                            quantity: adj.quantity,
                            remark: adj.remark,
                            //transactionTypeId: adj.transaction_type_data.pKey,
                            transactionType: adj.transaction_type
                        })
                    })
                    
                    prevSavedUntaggedFishVarianceAdjustment = data
                })
            })
            .catch( error => {
                reportError(error, "Failed to get untagged fish stock count record.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        }
        
        //-------------------------------------------------------
        // Go to next step untagged fish stock count. 
        // Retrieve previously saved untagged fish variance adjustment here due to "lift state up".
        let params = new URLSearchParams(`?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}&marineLifeId=${urlParams.get("marineLifeId")}`)

        getUntaggedFishVarianceAdjustment()
        .then(() => {
            params.append("countedStockBalance", untaggedFishCountedStockBalance)

            prevSavedUntaggedFishVarianceAdjustment.forEach( adj => {
                params.append("quantity", adj.quantity)
                params.append("remark", adj.remark)
                //params.append("transactionTypeId", adj.transactionTypeId)
                params.append("transactionType", adj.transactionType)
            })

            navigate({ 
                pathname: "/stockcountuntaggedfish", 
                search: params.toString()
            }) 
        })
    }

    //-----------------------
    // Create egg stock count
    //-----------------------
    const saveEggStockCount = (variances) => {
        if(readOnly) {
            goToUntaggedFishStockCount()
            return
        }

        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        axios.post(`${ACISAPIURL}stockcount/egg/create/`, {
            stock_count: urlParams.get("pKey"),
            stock_balance: eggBalance,
            counted_balance: form.getFieldValue("countedStockBalance"),
            variance_adjustment: variances
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Egg stock count record saved.")
            goToUntaggedFishStockCount()            
        })
        .catch( error => {
            reportError(error, `Failed to save egg stock count record.`)
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------------------
    // Delete egg stock count
    //-----------------------
    const noVarianceAdjustmentEggStockCount = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.patch(`${ACISAPIURL}stockcount/egg/novarianceadjustment/${urlParams.get("pKey")}/`, {
            stock_balance: eggBalance,
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            goToUntaggedFishStockCount()
        })
        .catch( error => {
            reportError(error, "Failed to save no variance adjustment egg stock count record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Current balance
    //--------------------------
    const getEggBalance = async () => {
        setIsLoading(true)

        return await axios.get(`${ACISAPIURL}stockcount/eggbalance/`, { 
            params: { 
                stock_count: urlParams.get("pKey"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            // 2021-08-21
            // If egg stock balance is 0. Skip this step.
            if(parseInt(response.data.balance) == 0) {
                goToUntaggedFishStockCount()
                return
            }

            setEggBalance(response.data.balance)  
            initialEggBalance = response.data.balance
            inputRef.current.focus()
        })
        .catch( error => {
            reportError(error, "Failed to get egg balance.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Current balance snapshot
    //--------------------------
    const getEggBalanceSnapshot = async () => {
        setIsLoading(true)

        return await axios.get(`${ACISAPIURL}stockcount/egg/`, { 
            params: { 
                stock_count: urlParams.get("pKey"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            // 2021-08-21
            // If egg stock balance is 0. Skip this step.
            if(response.data.results.length == 0) {
                goToUntaggedFishStockCount()
                return
            }

            response.data.results.forEach( snapShot => {
                setEggBalance(snapShot.stock_balance)  
                initialEggBalance = snapShot.stock_balance
                inputRef.current.focus()
            })
        })
        .catch( error => {
            reportError(error, "Failed to get egg balance snapshot.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Load all transaction type
    //--------------------------
    /*const getAllTransactionType = async () => {
        return await axios.get(`${ACISAPIURL}transactiontype/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const data = []
            const excludes = [
                SYSPARAM("Transfer Tagged Fish (In)"),
                SYSPARAM("Transfer Tagged Fish (Out)"),
                SYSPARAM("Transfer Untagged Fish (In)"),
                SYSPARAM("Transfer Untagged Fish (Out)"),
                SYSPARAM("Transfer Egg (In)"),
                SYSPARAM("Transfer Egg (Out)"),
                SYSPARAM("Opening Balance"),
                SYSPARAM("Egg Sampling (Out)"),
                SYSPARAM("Return Loan"),
                SYSPARAM("Hatching (Out)"),
                SYSPARAM("Mortality"),
                SYSPARAM("Sales"),
                SYSPARAM("Stock Adjustment (Out)"),
            ]

            response.data.results = response.data.results.filter( type => !excludes.includes(type.pKey) )
            
            const inOption = response.data.results.filter( type => type.is_stock_in == true ).map( type => <Option key={type.pKey}>{type.name}</Option> )
            setStockInTransactionTypeOption(inOption)

            const outOption = response.data.results.filter( type => type.is_stock_in == false ).map( type => <Option key={type.pKey}>{type.name}</Option> )
            setStockOutTransactionTypeOption(outOption)

            // Initial set of option is updating egg stock count.
            if(prevSavedVarianceAdjustment.length > 0) {
                let countedStockBalance = parseInt(urlParams.get("countedStockBalance"))

                if(countedStockBalance < initialEggBalance) 
                    setTransactionTypeOption(outOption)
                else 
                    setTransactionTypeOption(inOption)
            }

            // Store for validation when save.
            response.data.results.forEach( transactionType => {
                data.push({
                    pKey: transactionType.pKey,
                    isStockIn: transactionType.is_stock_in,
                })
            })
            
            setAllTransactionType(data)
        })
        .catch( error => {
            reportError(error, "Failed to get all transaction types.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }*/

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({
            pathname: MENUPATH_STOCKCOUNT,
        })
    }

    //-----------------------
    // On finish
    //-----------------------
    const onFinish = values => {
        if(readOnly) {
            saveEggStockCount(null)
            return
        }

        const countedStockBalance = parseInt(form.getFieldValue("countedStockBalance"))

        // No variance. Delete egg stock count in db. Go to next step.
        if(eggBalance == countedStockBalance) {
            if(values.variances.length > 0) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Stock balance tally. Variance adjustment entered below will not be saved. Confirmed?</p></Space>,
                    onOk() { noVarianceAdjustmentEggStockCount() },
                    onCancel() {},
                    centered: true
                })
            }
            else 
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Save {itemType} stock count record and proceed to next step?</p></Space>,
                    onOk() { noVarianceAdjustmentEggStockCount() },
                    onCancel() {},
                    centered: true
                })
            
            return
        }

        let stockInFound = false
        let stockOutFound = false
        let valid = true
        let totalAdjustmentVariance = 0
        
        for(let i = 0; i < values.variances.length; i++) {
            /* let transactionType = allTransactionType.filter( type => type.pKey == values.variances[i].adjustment)[0]
            if(transactionType.isStockIn) 
                stockInFound = true
            else
                stockOutFound = true

            if(countedStockBalance < eggBalance) {
                // Must stock out when deficit.
                if(transactionType.isStockIn) {
                    message.warn("Wrong adjustment type. Only stock out adjustment is allowed for deficit. Delete the adjustment then add a stock out adjustment", 
                        OTHERSYSPARAM("LONG_MSG_DURATION"))
                    valid = false
                    break
                }
            }
            // countedStockBalance > eggBalance
            else {
                // Must stock in if surplus.
                if(!transactionType.isStockIn) {
                    message.warn("Wrong adjustment type. Only stock in adjustment is allowed for surplus. Delete the adjustment then add a stock in adjustment",
                        OTHERSYSPARAM("LONG_MSG_DURATION"))
                    valid = false
                    break
                }
            }*/

            totalAdjustmentVariance += parseInt(values.variances[i].quantity)
        }

        if(!valid) return

        // Cannot mix stock in and stock out if more than one variance found.
        if(stockInFound && stockOutFound) {
            message.warn("Stock in and stock out variances at the same time not allowed.")
            return
        }

        // Total adjustment variance must be equal to main stock variance.
        if((isSurplus && countedStockBalance - eggBalance != totalAdjustmentVariance) ||
            (isDeficit && eggBalance - countedStockBalance != totalAdjustmentVariance)) {
            message.warn("Total quantity of adjustment variance is not equal to actual stock variance.", OTHERSYSPARAM("LONG_MSG_DURATION"))
            return
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>{`Save ${itemType} stock count record and proceed to next step?`}</p></Space>,
            onOk() { saveEggStockCount(values.variances) },
            onCancel() {},
            centered: true
        })
    }

    //----------------------------
    // On counted stock lost focus
    //----------------------------
    const onCountedStockChange = () => {
        const countedStockBalance = parseInt(form.getFieldValue("countedStockBalance"))
                
        if(countedStockBalance == eggBalance) {
            setCountedStockStatus(`(${OTHERSYSPARAM("TALLY")})`)
            setCountedStockStatusColor("blue")
        }
        else if(countedStockBalance < eggBalance) {
            setCountedStockStatus(`(${OTHERSYSPARAM("DEFICIT")})`)
            setCountedStockStatusColor("red")
            setIsDeficit(true)
            setIsSurplus(false)
        }
        else if(countedStockBalance > eggBalance) {
            setCountedStockStatus(`(${OTHERSYSPARAM("SURPLUS")})`)
            setCountedStockStatusColor("green")
            setIsSurplus(true)
            setIsDeficit(false)
        }
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            const standardITUOMTable = await loadMarineLifeItemTypeUnitOfMeasurementStandardTable()
            const ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == urlParams.get("marineLifeId") && row.item_type_data.name.startsWith(EGGS))
            setItemType(ituom[0].item_type_data.name)
            setUom(ituom[0].uom_data.uom)

            form.setFieldsValue({
                countedStockBalance: urlParams.get("countedStockBalance") != undefined ? urlParams.get("countedStockBalance") : 0
            })

            if(readOnly)
                getEggBalanceSnapshot()
                .then(() => { 
                    //getAllTransactionType()
                })
            else
                getEggBalance()
                .then(() => { 
                    //getAllTransactionType()
                })
        }

        init()
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Stock Count Storage ID: ${urlParams.get("storage")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Initialised On:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
            
                    { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                        </>
                    }
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">{`${itemType} Stock Balance (${uom}):`}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{color: "blue"}}>{numberWithCommas(eggBalance)}</Card.Grid>
                    { urlParams.get("completedOn") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Completed On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("completedOn")}</Card.Grid>
                        </>
                    }
                </Card>
            </Form.Item>
        </Form>

        <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" >

            <Form.Item label={`${itemType} Counted Balance (${uom})`} {...formLayout} >
                <Input.Group>
                <Form.Item name="countedStockBalance" noStyle
                    rules={[
                        { required: true, message: "Counted egg stock balance is required."},
                    ]}>
                    <InputNumber defaultValue={0} min={0} max={10000000} step={1} placeholder="Counted Egg Stock Balance (mL)" 
                        onChange={onCountedStockChange} onBlur={onCountedStockChange} onFocus={onCountedStockChange} ref={inputRef}
                        readOnly={readOnly}/>
                </Form.Item>
                <Form.Item noStyle>
                    <span style={{color: countedStockStatusColor}}> {countedStockStatus}</span>
                </Form.Item>
                </Input.Group>
            </Form.Item>
            
            <Row justify="center">
                <Col span={isTabletOrMobile ? 10 : 8} style={{textAlign: "center"}}>
                    <Card title={`${itemType} Stock Variance Adjustment`} style={{textAlign: "left"}}>
                        Skip this step if no stock variance. Use stock-IN transaction type for surplus, stock-OUT transaction type for deficit.
                    </Card>
                </Col>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>
            
            <Row justify="center">
                <Col span={isTabletOrMobile ? 13 : 8}>
                    <Form.List name="variances" initialValue={prevSavedVarianceAdjustment}>
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(field => (
                                <Space key={field.key} align="baseline" direction="vertical" /*wrap style={{borderBottomStyle: "dashed", borderBottomColor: "gray", borderBottomWidth: "1px", margin: 5}}*/>
                                    <span>
                                        <Divider orientation="right">
                                            <div>
                                                <CloseCircleOutlined 
                                                    style={{display: hide}}
                                                    onClick={() => {  
                                                        setHideAddButton("")
                                                        remove(field.name) 
                                                    }} />
                                            </div>
                                        </Divider>
                                    
                                        <Form.Item
                                            {...field}
                                            label={`Quantity (${uom})`}
                                            name={[field.name, 'quantity']}
                                            fieldKey={[field.fieldKey, 'quantity']}
                                            rules={[{ required: true, message: 'Variance quantity is required.' }]}>
                                            <InputNumber min={1} max={10000000} style={{width: optionWidth / 2}} readOnly={readOnly} />
                                        </Form.Item>
                                                                        
                                        <Form.Item
                                            {...field}
                                            label="Remark&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                            name={[field.name, 'remark']}
                                            fieldKey={[field.fieldKey, 'remark']}
                                            rules={[{ required: true, message: 'Remark is required.' }]}>
                                            <TextArea rows={1} maxLength={500} style={{width: optionWidth}} readOnly={readOnly}/>
                                        </Form.Item>

                                        {/*<Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, curValues) =>
                                            prevValues.area !== curValues.area || prevValues.sights !== curValues.sights} > 
                            
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="Adjustment&nbsp;&nbsp;&nbsp;&nbsp;"
                                                    name={[field.name, "adjustment"]}
                                                    fieldKey={[field.fieldKey, "adjustment"]}
                                                    rules={[{ required: true, message: "Adjustment of variance is required." }]}>
                                                    <Select style={{width: optionWidth}} disabled={readOnly}>{transactionTypeOption}</Select>
                                                </Form.Item>
                                            )}
                                            </Form.Item>*/}
                                    </span>
                                </Space>
                            ))}

                            <Row justify="center">
                                <Col span={24} style={{textAlign: "center"}}>
                                    <Form.Item>
                                        <Button 
                                            type="dashed" 
                                            block 
                                            icon={<PlusOutlined />} 
                                            style={{width: optionWidth, display: hideAddButton}}
                                            onClick={() => {
                                                //setHideAddButton("none") // Allow only one variance
                                                // Validation
                                                let countedStockBalance = parseInt(form.getFieldValue("countedStockBalance"))
                                                if(countedStockBalance == eggBalance) {
                                                    message.info("Stock balance and counted stock balance are equal. No variance adjustment needed.")
                                                    return
                                                }

                                                if(countedStockBalance < eggBalance)
                                                    setTransactionTypeOption(stockOutTransactionTypeOption)
                                                else 
                                                    setTransactionTypeOption(stockInTransactionTypeOption)

                                                add()
                                            }} 
                                        >
                                            Add stock variance adjustment
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                            </> 
                        )}
                    </Form.List>
                </Col> 
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={10} style={{textAlign: "start"}}>
                    <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={4} />
                <Col span={10} style={{textAlign: "end"}}>
                    <Button type="primary" htmlType="submit" disabled={disableButton} icon={<RightOutlined />}>Next</Button>
                </Col>
            </Row>
        </Form>
        </Spin>
        </>
    )
}

export default StockCountEggTable
