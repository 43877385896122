import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL, MEDIAMAXWIDTH,  } from "./SystemParameter"
import { reportError } from "./Utility"
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./UserSession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select

//----------
// Component
//----------
const LocationSelect = ({withBlank, defaultValue, onChange}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [locationOption, setLocationOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
            
    //------------------
    // Load all location
    //------------------
    const getAllLocation = () => {
        axios.get(`${ACISAPIURL}locationfull/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( location => <Option key={location.pKey}>{location.name}</Option>))
            setLocationOption(options)
        })
        .catch( error => {
            reportError(error, "<LocationSelect /> Failed to get locations.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //-----------------------------------------------
    // Call back to parent component to call onChange
    //-----------------------------------------------
    const onSelectionChange = (e, value) => {
        onChange(e, value)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllLocation()
    }, []) 
    
    return(
        <Select defaultValue={defaultValue} style={{width: optionWidth}} onChange={onSelectionChange} allowClear showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {locationOption}
        </Select>
    )
}

export default LocationSelect