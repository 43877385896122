import { useState } from 'react'
import { Menu, message } from 'antd'
import { DesktopOutlined, SettingOutlined, FundOutlined, DatabaseOutlined, ProfileOutlined, LineChartOutlined, ToolOutlined, PieChartOutlined, 
    UserOutlined, FormOutlined, TagOutlined, FormatPainterOutlined, EditOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { MENUKEY_EGGDEV, MENUKEY_ENVIRONMENT, 
    MENUKEY_FEEDING, MENUKEY_FISH, MENUKEY_GROWTHDEV, MENUKEY_HATCHING, MENUKEY_MORTALITY, MENUKEY_SPAWNING, MENUKEY_TRANSFERCOLLECTIVEFISHSOURCE,
    MENUKEY_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE, MENU_SPAWNING, MENUPATH_EGGDEV, 
    MENU_EGGDEV, MENU_ENVIRONMENT, MENU_FEEDING, MENU_GROWTHDEV, MENU_HATCHING, MENU_MORTALITY, 
    MENU_TRANSFERCOLLECTIVEFISHSOURCE, MENU_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE, MENUPATH_SPAWNING, 
    MENUPATH_HATCHING, MENUPATH_FEEDING, MENUPATH_GROWTHDEV, MENUPATH_ENVIRONMENT, MENUPATH_MORTALITY, MENUPATH_FISH, MENUKEY_NEWTAG, MENUPATH_NEWTAG, 
    MENUPATH_TRANSFERCOLLECTIVEFISHSOURCE, MENUKEY_STORAGE, MENUPATH_STORAGE, MENUPATH_BATCH, MENU_STORAGE, MENU_BATCH, MENU_FISH, MENUKEY_BATCH, 
    MENU_NEWTAG, MENUPATH_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE, MENUKEY_STOCKCOUNT, MENUPATH_STOCKCOUNT, MENU_STOCKCOUNT,
    MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE, MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE,
    MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE, MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE,
    MENU_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE, MENU_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE, 
    MENUKEY_STANDARDGROWTHTABLE, MENU_STANDARDGROWTHTABLE, MENUPATH_STANDARDGROWTHTABLE, MENUKEY_PHYSICALTAGGING, MENU_PHYSICALTAGGING, 
    MENUPATH_PHYSICALTAGGING, MENUKEY_MOONPHASESUPLOAD, MENUPATH_MOONPHASESUPLOAD, MENU_MOONPHASESUPLOAD, MENUKEY_OTHERSYSPARAM, MENU_OTHERSYSPARAM,
    MENUPATH_OTHERSYSPARAM, MENUKEY_STOCKTRANSACTION, MENU_STOCKTRANSACTION, MENUPATH_STOCKTRANSACTION, MENUKEY_STOCKCOUNTREPORT,
    MENU_STOCKCOUNTREPORT, MENUPATH_STOCKCOUNTREPORT, MENUKEY_GROWTHDEVELOPMENTHISTORY, MENU_GROWTHDEVELOPMENTHISTORY,
    MENUPATH_GROWTHDEVELOPMENTHISTORY, MENUKEY_INVENTORYSUMMARY, MENUPATH_INVENTORYSUMMARY, MENU_INVENTORYSUMMARY, MENUKEY_SALESSUMMARY, MENU_SALESSUMMARY,
    MENUPATH_SALESSUMMARY, MENUKEY_PURCHASESSUMMARY, MENU_PURCHASESSUMMARY, MENUPATH_PURCHASESSUMMARY, MENUKEY_FEEDINGSUMMARY, MENU_FEEDINGSUMMARY,
    MENUPATH_FEEDINGSUMMARY, MENUPATH_TRANSFERSUMMARY, MENUKEY_TRANSFERSUMMARY, MENU_TRANSFERSUMMARY, MENUKEY_INVENTORYFORECAST, MENU_INVENTORYFORECAST,
    MENUPATH_INVENTORYFORECAST, MENU_INVENTORYFORECASTING, MENUKEY_INVENTORYFORECASTING,
    MENUPATH_INVENTORYFORECASTING, MENUKEY_DASHBOARD, MENUPATH_DASHBOARD, MENU_DABHBOARD,
    MENUKEY_BATCHGENEALOGY, MENU_BATCHGENEALOGY, MENUPATH_BATCHGENEALOGY,
    MENUKEY_SENSORSTORAGEMAP, 
    MENUPATH_SENSORSTORAGEMAP,
    MENU_STOCKCOUNTVIDEO, MENUKEY_STOCKCOUNTVIDEO, MENUPATH_STOCKCOUNTVIDEO, MENUKEY_ENVDATAHISTORY, MENU_ENVDATAHISTORY, MENUPATH_ENVDATAHISTORY, 
    MENUKEY_CENTRALISEDCONSOLE, MENU_CENTRALISEDCONSOLE, MENUPATH_CENTRALISEDCONSOLE, MENU_SENSORSTORAGEMAP, MENUKEY_DRONEVIEWDASHBOARD, MENUPATH_DRONEVIEWDASHBOARD,
    MENU_DRONEVIEWDASHBOARD, MENUKEY_STOCKCOUNTVIDEOAUTOSTITCH, MENUPATH_STOCKCOUNTVIDEOAUTOSTITCH, MENU_STOCKCOUNTVIDEOAUTOSTITCH,
    MENUKEY_LOCATION, MENU_LOCATION, MENU_SITE, MENUKEY_SITE, MENUPATH_LOCATION, MENUPATH_SITE, MENUKEY_SPECIES, MENUPATH_SPECIES, MENU_SPECIES,
    MENU_CHANGEPASSWORD, MENUKEY_CHANGEPASSWORD, MENUPATH_CHANGEPASSWORD, MENU_USER, MENUKEY_USER, MENUPATH_USER, MENU_HATCHINGCRAB, MENUKEY_HATCHINGCRAB, MENUPATH_HATCHINGCRAB, MENUKEY_THEMESWITCH, MENUKEY_AMENDTRANSACTIONWEIGHT, MENU_AMENDTRANSACTIONWEIGHT, MENUPATH_AMENDTRANSACTIONWEIGHT, MENUKEY_AMENDSTOCKTRANSACTIONRECORD, MENUPATH_AMENDSTOCKTRANSACTIONRECORD, MENU_AMENDSTOCKTRANSACTIONRECORD, MENUKEY_INVENTORYFORECAST2, MENU_INVENTORYFORECAST2, MENUPATH_INVENTORYFORECAST2, MENUKEY_STANDARDGROWTHTABLE2, MENU_STANDARDGROWTHTABLE2, MENUPATH_STANDARDGROWTHTABLE2, MENUKEY_CENTRALISEDCONSOLEWATERQUALITY, MENUPATH_CENTRALISEDCONSOLEWATERQUALITY, MENU_CENTRALISEDCONSOLEWATERQUALITY, MENUKEY_PENDINGSTOCKTRANSACTION, MENU_PENDINGSTOCKTRANSACTION, MENUPATH_PENDINGSTOCKTRANSACTION, MENUKEY_INVENTORYSUMMARYV2, MENUPATH_INVENTORYSUMMARYV2, MENU_INVENTORYSUMMARYV2,
    MENUKEY_FEEDINGPLAN,
    MENU_FEEDINGPLAN,
    MENUPATH_FEEDINGPLAN, } from './SystemParameter'

import { getUserGroups, OTHERSYSPARAM } from './UserSession'
import { getCookie } from './Utility'

const { SubMenu } = Menu

//----------
// Component
//----------
const ACISMenu = ({setDrawerVisible}) => {
    const navigate = useNavigate()

    const [themeLabel, setThemeLabel] = useState(getCookie('useDarkTheme') != '1' ? 'Dark Theme' : 'Light Theme')

    //-------------
    // Handle click
    //-------------
    const onClick = (e) => {
        // Close drawer
        setDrawerVisible(false) 
        
        switch(parseInt(e.key)) {
            case 0:
                navigate({ pathname: MENUPATH_BATCH })
                break
            case MENUKEY_EGGDEV:
                navigate({ pathname: MENUPATH_EGGDEV })
                break
            case MENUKEY_SPAWNING:
                navigate({ pathname: MENUPATH_SPAWNING })
                break
            case MENUKEY_HATCHING:
                navigate({ pathname: MENUPATH_HATCHING })
                break
            case MENUKEY_HATCHINGCRAB:
                navigate({ pathname: MENUPATH_HATCHINGCRAB })
                break
            case MENUKEY_FEEDINGPLAN:
                navigate({ pathname: MENUPATH_FEEDINGPLAN })
                break
            case MENUKEY_FEEDING:
                navigate({ pathname: MENUPATH_FEEDING })
                break
            case MENUKEY_GROWTHDEV:
                navigate({ pathname: MENUPATH_GROWTHDEV })
                break
            case MENUKEY_MORTALITY:
                navigate({ pathname: MENUPATH_MORTALITY })
                break
            case MENUKEY_NEWTAG:
                navigate({ pathname: MENUPATH_NEWTAG })
                break
            case MENUKEY_STOCKCOUNT:
                navigate({ pathname: MENUPATH_STOCKCOUNT })
                break
            case MENUKEY_STOCKCOUNTVIDEO:
                navigate({ pathname: MENUPATH_STOCKCOUNTVIDEO })
                break
            case MENUKEY_STOCKCOUNTVIDEOAUTOSTITCH:
                navigate({ pathname: MENUPATH_STOCKCOUNTVIDEOAUTOSTITCH })
                break

                
            case MENUKEY_TRANSFERCOLLECTIVEFISHSOURCE:
                navigate({ pathname: MENUPATH_TRANSFERCOLLECTIVEFISHSOURCE, search: "?interSite=false" })
                break
            case MENUKEY_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE:
                navigate({ pathname: MENUPATH_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE, search: "?interSite=true" })
                break
            

            case MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE:
                navigate({ pathname: MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE })
                break
            case MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE:
                navigate({ pathname: MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE })
                break

            
            case MENUKEY_PENDINGSTOCKTRANSACTION:
                navigate({ pathname: MENUPATH_PENDINGSTOCKTRANSACTION })
                break
            

            case MENUKEY_STORAGE:
                navigate({ pathname: MENUPATH_STORAGE })
                break
            case MENUKEY_BATCH:
                navigate({ pathname: MENUPATH_BATCH })
                break
            case MENUKEY_FISH:
                navigate({ pathname: MENUPATH_FISH })
                break
            case MENUKEY_PHYSICALTAGGING:
                navigate({ pathname: MENUPATH_PHYSICALTAGGING })
                break
            case MENUKEY_ENVIRONMENT:
                navigate({ pathname: MENUPATH_ENVIRONMENT })
                break
            case MENUKEY_SENSORSTORAGEMAP:
                navigate({ pathname: MENUPATH_SENSORSTORAGEMAP })
                break
            case MENUKEY_STANDARDGROWTHTABLE:
                navigate({ pathname: MENUPATH_STANDARDGROWTHTABLE })
                break
            case MENUKEY_STANDARDGROWTHTABLE2:
                navigate({ pathname: MENUPATH_STANDARDGROWTHTABLE2 })
                break
            case MENUKEY_INVENTORYFORECASTING:
                navigate({ pathname: MENUPATH_INVENTORYFORECASTING })
                break


            case MENUKEY_MOONPHASESUPLOAD:
                navigate({ pathname: MENUPATH_MOONPHASESUPLOAD })
                break
            case MENUKEY_OTHERSYSPARAM:
                navigate({ pathname: MENUPATH_OTHERSYSPARAM })
                break


            case MENUKEY_LOCATION:
                navigate({ pathname: MENUPATH_LOCATION })
                break
            case MENUKEY_SITE:
                navigate({ pathname: MENUPATH_SITE })
                break
            case MENUKEY_SPECIES:
                navigate({ pathname: MENUPATH_SPECIES })
                break
            case MENUKEY_CHANGEPASSWORD:
                navigate({ pathname: MENUPATH_CHANGEPASSWORD })
                break
            case MENUKEY_USER:
                navigate({ pathname: MENUPATH_USER })
                break


            case MENUKEY_GROWTHDEVELOPMENTHISTORY:
                navigate({ pathname: MENUPATH_GROWTHDEVELOPMENTHISTORY })
                break
            case MENUKEY_FEEDINGSUMMARY:
                navigate({ pathname: MENUPATH_FEEDINGSUMMARY })
                break
            case MENUKEY_INVENTORYFORECAST:
                navigate({ pathname: MENUPATH_INVENTORYFORECAST })
                break
            case MENUKEY_INVENTORYFORECAST2:
                navigate({ pathname: MENUPATH_INVENTORYFORECAST2 })
                break
            case MENUKEY_INVENTORYSUMMARY:
                navigate({ pathname: MENUPATH_INVENTORYSUMMARY })
                break
            case MENUKEY_INVENTORYSUMMARYV2:
                navigate({ pathname: MENUPATH_INVENTORYSUMMARYV2 })
                break
            case MENUKEY_PURCHASESSUMMARY:
                navigate({ pathname: MENUPATH_PURCHASESSUMMARY, search: "?sales=false" })
                break
            case MENUKEY_SALESSUMMARY:
                navigate({ pathname: MENUPATH_SALESSUMMARY, search: "?sales=true" })
                break
            case MENUKEY_ENVDATAHISTORY:
                navigate({ pathname: MENUPATH_ENVDATAHISTORY })
                break
            case MENUKEY_STOCKTRANSACTION:
                navigate({ pathname: MENUPATH_STOCKTRANSACTION })
                break
            case MENUKEY_STOCKCOUNTREPORT:
                navigate({ pathname: MENUPATH_STOCKCOUNTREPORT })
                break
            case MENUKEY_TRANSFERSUMMARY:
                navigate({ pathname: MENUPATH_TRANSFERSUMMARY })
                break


            case MENUKEY_DASHBOARD:
                navigate({ pathname: MENUPATH_DASHBOARD })
                break
            case MENUKEY_DRONEVIEWDASHBOARD:
                navigate({ pathname: MENUPATH_DRONEVIEWDASHBOARD })
                break
            case MENUKEY_CENTRALISEDCONSOLE:
                navigate({ pathname: MENUPATH_CENTRALISEDCONSOLE })
                break
            case MENUKEY_CENTRALISEDCONSOLEWATERQUALITY:
                navigate({ pathname: MENUPATH_CENTRALISEDCONSOLEWATERQUALITY })
                break
            case MENUKEY_BATCHGENEALOGY:
                navigate({ pathname: MENUPATH_BATCHGENEALOGY })
                break

            case MENUKEY_THEMESWITCH:
                if(getCookie('useDarkTheme') != '1')
                    document.cookie = `useDarkTheme=1; path=/; max-age=${60 * 60 * 24 * 365}; SameSite='Strict'`
                else
                    document.cookie = `useDarkTheme=0; path=/; max-age=${60 * 60 * 24 * 365}; SameSite='Strict'`

                window.location.reload()
                break

            case MENUKEY_AMENDSTOCKTRANSACTIONRECORD:
                navigate({ pathname: MENUPATH_AMENDSTOCKTRANSACTIONRECORD })
                break

            default: 
                message.error("Page not found.")
        }
    }

    return (
        // <Menu onClick={onClick} mode="inline" /*defaultSelectedKeys={['1']}*/ defaultOpenKeys={["aquacultureProcessMenu", "inventoryMenu", "reportMenu", "setupMenu"]}>
        <Menu onClick={onClick} mode="inline" /*defaultSelectedKeys={['1']}*/ defaultOpenKeys={[]}>
            <Menu.Item key={MENUKEY_DASHBOARD} icon={<PieChartOutlined />}>{MENU_DABHBOARD}</Menu.Item>
            <Menu.Item key={MENUKEY_CENTRALISEDCONSOLE} icon={<FundOutlined />}>{MENU_CENTRALISEDCONSOLE}</Menu.Item>
            <Menu.Item key={MENUKEY_CENTRALISEDCONSOLEWATERQUALITY} icon={<FundOutlined />}>{MENU_CENTRALISEDCONSOLEWATERQUALITY}</Menu.Item>
            
            <SubMenu key="aquacultureProcessMenu" icon={<DesktopOutlined />} title="Aquaculture Process">
                <Menu.Item key={MENUKEY_EGGDEV}>{MENU_EGGDEV}</Menu.Item>
                <Menu.Item key={MENUKEY_SPAWNING}>{MENU_SPAWNING}</Menu.Item>
                <SubMenu title="Hatching">
                    <Menu.Item key={MENUKEY_HATCHING}>{MENU_HATCHING}</Menu.Item>
                    <Menu.Item key={MENUKEY_HATCHINGCRAB}>{MENU_HATCHINGCRAB}</Menu.Item>
                </SubMenu>
                <SubMenu title="Feeding">
                    <Menu.Item key={MENUKEY_FEEDINGPLAN}>{MENU_FEEDINGPLAN}</Menu.Item>
                    <Menu.Item key={MENUKEY_FEEDING}>{MENU_FEEDING}</Menu.Item>
                </SubMenu>
                <Menu.Item key={MENUKEY_GROWTHDEV}>{MENU_GROWTHDEV}</Menu.Item>
                <Menu.Item key={MENUKEY_MORTALITY}>{MENU_MORTALITY}</Menu.Item>
            </SubMenu>
            
            <SubMenu key="inventoryMenu" icon={<DatabaseOutlined />} title="Inventory">
                <Menu.Item key={MENUKEY_PENDINGSTOCKTRANSACTION} style={{fontWeight: "bold"}}>{MENU_PENDINGSTOCKTRANSACTION}</Menu.Item>
                <Menu.Item key={MENUKEY_TRANSFERCOLLECTIVEFISHSOURCE}>{MENU_TRANSFERCOLLECTIVEFISHSOURCE}</Menu.Item>
                <Menu.Item key={MENUKEY_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE}>{MENU_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE}</Menu.Item>
                <Menu.Item key={MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE}>{MENU_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE}</Menu.Item>
                <Menu.Item key={MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE}>{MENU_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE}</Menu.Item>
                <Menu.Item key={MENUKEY_STOCKCOUNT}>{MENU_STOCKCOUNT}</Menu.Item>
                <Menu.Item key={MENUKEY_STOCKCOUNTVIDEOAUTOSTITCH}>{MENU_STOCKCOUNTVIDEOAUTOSTITCH}</Menu.Item>
                <Menu.Item key={MENUKEY_STOCKCOUNTVIDEO}>{MENU_STOCKCOUNTVIDEO}</Menu.Item>
                <Menu.Item key={MENUKEY_INVENTORYFORECASTING}>{MENU_INVENTORYFORECASTING}</Menu.Item>
            </SubMenu>

            <SubMenu key="tagMarineLifeMenu" icon={<TagOutlined />} title="Tag Marine Life">
                <Menu.Item key={MENUKEY_NEWTAG}>{MENU_NEWTAG}</Menu.Item>
                <Menu.Item key={MENUKEY_FISH}>{MENU_FISH}</Menu.Item>
                <Menu.Item key={MENUKEY_PHYSICALTAGGING}>{MENU_PHYSICALTAGGING}</Menu.Item>
            </SubMenu>

            <SubMenu key="waterQualityMenu" icon={<FormOutlined />} title="Water Quality">
                <Menu.Item key={MENUKEY_ENVIRONMENT}>{MENU_ENVIRONMENT}</Menu.Item>
            </SubMenu>
            
            <SubMenu key="reportMenu" icon={<ProfileOutlined />} title="Report & Listing">
                <Menu.Item key={MENUKEY_ENVDATAHISTORY}>{MENU_ENVDATAHISTORY}</Menu.Item>
                <Menu.Item key={MENUKEY_FEEDINGSUMMARY}>{MENU_FEEDINGSUMMARY}</Menu.Item>
                <Menu.Item key={MENUKEY_GROWTHDEVELOPMENTHISTORY}>{MENU_GROWTHDEVELOPMENTHISTORY}</Menu.Item>
                <Menu.Item key={MENUKEY_INVENTORYFORECAST}>{MENU_INVENTORYFORECAST}</Menu.Item>
                <Menu.Item key={MENUKEY_INVENTORYFORECAST2}>{MENU_INVENTORYFORECAST2}</Menu.Item>
                <Menu.Item key={MENUKEY_INVENTORYSUMMARY}>{MENU_INVENTORYSUMMARY}</Menu.Item>
                <Menu.Item key={MENUKEY_INVENTORYSUMMARYV2}>{MENU_INVENTORYSUMMARYV2}</Menu.Item>
                <Menu.Item key={MENUKEY_PURCHASESSUMMARY}>{MENU_PURCHASESSUMMARY}</Menu.Item>
                <Menu.Item key={MENUKEY_SALESSUMMARY}>{MENU_SALESSUMMARY}</Menu.Item>
                <Menu.Item key={MENUKEY_STOCKTRANSACTION}>{MENU_STOCKTRANSACTION}</Menu.Item>
                <Menu.Item key={MENUKEY_STOCKCOUNTREPORT}>{MENU_STOCKCOUNTREPORT}</Menu.Item>
                <Menu.Item key={MENUKEY_TRANSFERSUMMARY}>{MENU_TRANSFERSUMMARY}</Menu.Item>
            </SubMenu>

            <SubMenu key="analyticsMenu" icon={<LineChartOutlined />} title="Analytics">
                <Menu.Item key={MENUKEY_DRONEVIEWDASHBOARD} >{MENU_DRONEVIEWDASHBOARD}</Menu.Item>
                <Menu.Item key={MENUKEY_BATCHGENEALOGY}>{MENU_BATCHGENEALOGY}</Menu.Item>
            </SubMenu>

            <SubMenu key="userMenu" icon={<UserOutlined />} title="Account">
                <Menu.Item key={MENUKEY_CHANGEPASSWORD}>{MENU_CHANGEPASSWORD}</Menu.Item>
                { getUserGroups().includes(OTHERSYSPARAM("SUPERADMIN")) &&
                    <>
                    <Menu.Item key={MENUKEY_USER}>{MENU_USER}</Menu.Item>
                    </>
                }
            </SubMenu>

            { (getUserGroups().includes(OTHERSYSPARAM("ADMIN")) || getUserGroups().includes(OTHERSYSPARAM("SUPERADMIN")) || 
                getUserGroups().includes(OTHERSYSPARAM("DEVELOPER"))) &&
                <>
                <SubMenu key="setupMenu" icon={<SettingOutlined />} title="Setup">
                    <Menu.Item key={MENUKEY_STORAGE}>{MENU_STORAGE}</Menu.Item>
                    <Menu.Item key={MENUKEY_BATCH} style={{fontWeight: "bold"}}>{MENU_BATCH}</Menu.Item>
                    <Menu.Item key={MENUKEY_SENSORSTORAGEMAP}>{MENU_SENSORSTORAGEMAP}</Menu.Item>
                    <Menu.Item key={MENUKEY_STANDARDGROWTHTABLE}>{MENU_STANDARDGROWTHTABLE}</Menu.Item>
                    <Menu.Item key={MENUKEY_STANDARDGROWTHTABLE2}>{MENU_STANDARDGROWTHTABLE2}</Menu.Item>
                    <Menu.Item key={MENUKEY_LOCATION}>{MENU_LOCATION}</Menu.Item>
                    <Menu.Item key={MENUKEY_SITE}>{MENU_SITE}</Menu.Item>
                    <Menu.Item key={MENUKEY_SPECIES}>{MENU_SPECIES}</Menu.Item>
                </SubMenu>
                </>
            }

            { getUserGroups().includes(OTHERSYSPARAM("SUPERADMIN"))  &&
                <>
                <Menu.Item key={MENUKEY_AMENDSTOCKTRANSACTIONRECORD} icon={<EditOutlined />}>{MENU_AMENDSTOCKTRANSACTIONRECORD}</Menu.Item>
                </>
            }

            { getUserGroups().includes(OTHERSYSPARAM("SUPERADMIN")) &&
                <SubMenu key="advancedSetupMenu" icon={<ToolOutlined />} title="Advanced Setup">
                    <Menu.Item key={MENUKEY_MOONPHASESUPLOAD}>{MENU_MOONPHASESUPLOAD}</Menu.Item>
                    <Menu.Item key={MENUKEY_OTHERSYSPARAM}>{MENU_OTHERSYSPARAM}</Menu.Item>
                </SubMenu>
            }

            <Menu.Item key={MENUKEY_THEMESWITCH} icon={<FormatPainterOutlined />}>{themeLabel}</Menu.Item>
        </Menu>
  )
}

export default ACISMenu