import  { useState } from 'react'
import { Button, Col, Row, Space, Tooltip, Modal, message, Upload, Card, Spin } from "antd"
import { UploadOutlined, QuestionCircleOutlined } from "@ant-design/icons"

import { ACISAPIURL, LOADING } from '../Common/SystemParameter'
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM } from '../Common/UserSession'

const { confirm } = Modal

const MoonPhasesUpload = () => {
    const [disableButton, setDisableButton] = useState("")
    const [uploading, setUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    //-----------------------------
    // Upload excel file validation
    //-----------------------------
    const props = {
        name: 'excel_file',
        action: `${ACISAPIURL}spawning/moonphases/upload/${getUserSiteId()}/`,
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        beforeUpload: file => {
            if (file.type !== 'application/vnd.ms-excel' && 
                file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") 
                message.error(`${file.name} is not an excel file`)

            return (file.type === 'application/vnd.ms-excel' || 
                file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")? true : Upload.LIST_IGNORE
        },
        onChange(info) {
            setUploading(true)

            if(info.file.status !== 'uploading') {
                setUploading(false)
                setIsLoading(true)
            }

            if(info.file.status === 'done') {
                setUploading(false)
                setIsLoading(false)

                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>"{info.file.name}"" uploaded successfully.</p></Space>,
                    onOk() {},
                    cancelButtonProps: {style: {display: "none"} },
                    centered: true
                })
            }
            else if(info.file.status === 'error') {
                setUploading(false)
                message.error(`"${info.file.name}" upload failed.`)
                message.error(info.file.response?.detail, OTHERSYSPARAM("LONG_MSG_DURATION"))
            }
        },
    }

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row justify="center">
            <Col span={8} style={{textAlign: "center"}}>
                <Card title="Moon Phases" style={{textAlign: "left"}}>
                    Upload moon phases excel file. The excel file must be in format predefined. Moon phases records uploaded will be attached to location of the current site.
                </Card>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={10} />
            <Col span={4} style={{textAlign: "center"}}>
                <Upload {...props} accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    <Button type="primary" htmlType="button" icon={<UploadOutlined />} loading={uploading}>
                        <Tooltip title="Upload new standard growth table excel file.">
                            Upload Excel
                        </Tooltip>
                    </Button>
                </Upload>
            </Col>
            <Col span={10} />
        </Row>
        </Spin>
        </>
    )
}

export default MoonPhasesUpload