import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import InventoryForecastDetailTable from './InventoryForecastDetailTable'
import { OTHERSYSPARAM } from '../Common/UserSession'
import moment from 'moment'
import { DATEFORMAT } from '../Common/SystemParameter'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const InventoryForecastDetailMain = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    // Build description.
    let from = ""
    if(urlParams.get("fromDate") != "") 
        from = `from ${moment(urlParams.get("fromDate")).format(DATEFORMAT)}`
    else
        from = `from ${moment().add(1, "days").format(DATEFORMAT)}`

    let to = ""
    if(urlParams.get("toDate") != "") 
        to = ` to ${moment(urlParams.get("toDate")).format(DATEFORMAT)}`
    else
        to = " onward"

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: "/inventoryforecastbatchstorage",
            search: `?numOfBatches=${urlParams.get("numOfBatches")}&numOfStorages=${urlParams.get("numOfStorages")}&totalQty=${urlParams.get("totalQty")}&totalWeightKg=${urlParams.get("totalWeightKg")}&fromDate=${urlParams.get("fromDate")}&toDate=${urlParams.get("toDate")}&searchBatchId=${urlParams.get("searchBatchId")}&searchBatch=${urlParams.get("searchBatch")}&searchStorageId=${urlParams.get("searchStorageId")}&searchStorage=${urlParams.get("searchStorage")}&marineLifeId=${urlParams.get("marineLifeId")}&marineLife=${urlParams.get("marineLife")}&speciesId=${urlParams.get("speciesId")}&species=${urlParams.get("species")}&batchTypeId=${urlParams.get("batchTypeId")}&batchType=${urlParams.get("batchType")}&fromWeight=${urlParams.get("fromWeight")}&toWeight=${urlParams.get("toWeight")}&fishId=${urlParams.get("fishId")}&fish=${urlParams.get("fish")}`
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                {<ACISHeader />}
            </Header>

            <Content style={{minHeight: contentHeight}}>

                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={() => onBack()} 
                    title="Inventory Forecast Detail">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">{`Daily Data ${from}${to}`}</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Row justify="center">
                    <Col span={1}></Col>
                        {<Col span={22}><InventoryForecastDetailTable /></Col>}
                    <Col span={1}></Col>
                </Row>
            </Content>

            <Footer>
                {<ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={() => onBack()} 
                        title="Inventory Forecast Detail"
                        subTitle={`Daily Data ${from}${to}`}/>} />}
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default InventoryForecastDetailMain