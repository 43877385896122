import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { OTHERSYSPARAM } from '../Common/UserSession'
import { walltiles } from '../Common/Layout'
import { MENUPATH_STOCKCOUNT } from '../Common/SystemParameter'
import InventorySummaryTable from './InventorySummaryTable'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const InventorySummaryMain = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ pathname: MENUPATH_STOCKCOUNT })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={() => onBack()} 
                    title="Inventory Summary:">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Generate inventory summary report</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><div /></Space></Col></Row>
                
                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><InventorySummaryTable /></Col>
                    <Col span={1}></Col>
                </Row>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={() => onBack()} 
                        title="Inventory Summary:"
                        subTitle="Generate inventory summary report"/>} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default InventorySummaryMain