import axios from "axios"
import { ACISAPIURL, TIMEOUT } from "./SystemParameter"
import { getUserAuthToken, refreshUserSession, storeSystemParameter, SYSPARAM } from "./UserSession"
import { reportError } from "./Utility"

export const loadSystemParameters = async () => {
    return await Promise.all([
        loadSystemParametersByType("acquiringmethod"),
        loadSystemParametersByType("lifecycle"),
        loadSystemParametersByType("batchtype"),
        loadSystemParametersByType("batchstatus"),
        loadSystemParametersByType("aquaculturestage"),
        loadSystemParametersByType("spawningstatus"),
        loadSystemParametersByType("moonstage"),
        loadSystemParametersByType("itemtype"),
        loadSystemParametersByType("itemtypeunitofmeasurement"),
        loadSystemParametersByType("transactiontype"),
        loadSystemParametersByType("feedingtype"),
        loadSystemParametersByType("gender"),
        loadSystemParametersByType("marinelife"),
        loadSystemParametersByType("site"),
        loadSystemParametersByType("stocktransactionstatus"),
        loadUnitOfMeasurementSystemParameters(),
        loadOtherSystemParameters(),
    ])
    
    /*return await new Promise((resolve, reject) => {
        Promise.all([
            loadSystemParametersByType("acquiringmethod"),
            loadSystemParametersByType("lifecycle"),
        ])
        .then(() => { return resolve() })
        .catch( error => {
            // Allow local error handling here.
            return reject()
        })
    })*/
}

const loadSystemParametersByType = async (parameterType) => {
    return await axios.get(`${ACISAPIURL}${parameterType}/`, {
            params: {},
            timeout: TIMEOUT,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.results.forEach( param => {
                if(param.name in SYSPARAM) reportError(`Duplicated system parameter detected: ${param.name}`)
                storeSystemParameter(param.name, param.pKey)
            })
        })
        .catch( error => {
            reportError(error, `Failed to load system parameters: ${parameterType}`)
            throw error
        })
        .finally(() => {
            refreshUserSession()
        })
}

const loadUnitOfMeasurementSystemParameters = async () => {
    return await axios.get(`${ACISAPIURL}unitofmeasurement/`, {
            params: {},
            timeout: TIMEOUT,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.results.forEach( param => {
                if(param.uom in SYSPARAM) reportError(`Duplicated system parameter detected: ${param.uom}`)
                storeSystemParameter(param.uom, param.pKey)
            })
        })
        .catch( error => {
            reportError(error, "Failed to load system parameters: unitofmeasurement")
            throw error
        })
        .finally(() => {
            refreshUserSession()
        })
}

const loadOtherSystemParameters = async () => {
    return await axios.get(`${ACISAPIURL}othersystemparameter/`, {
            params: {},
            timeout: TIMEOUT,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.results.forEach( param => {
                if(param.name in SYSPARAM) reportError(`Duplicated system parameter detected: ${param.name}`)
                storeSystemParameter(param.name, param.value)
            })
        })
        .catch( error => {
            reportError(error, "Failed to load system parameters: othersystemparameter")
            throw error
        })
        .finally(() => {
            refreshUserSession()
        })
}