import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { ACISAPIURL } from "../Common/SystemParameter"
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from "../Common/UserSession"
import { reportError } from "../Common/Utility"
import axios from "axios"
import { FOOTER, PAGEMARGINS, PAGESIZE, reportTitle, TABLESTYLES } from "../Common/PdfMakeLayout"

pdfMake.vfs = pdfFonts.pdfMake.vfs

export const generatePhysicalTaggingFishTablePdf = async (batchId, batch, storageId, storage, species, selectedFish = []) => {
    let total = 0
    let tagged = 0
    let pending = 0
    let errorFound = false
    let fishDataSource = []

    // Push table header row
    fishDataSource.push([
        { text: 'Tag ID', style: TABLESTYLES.tableHeaderLeft },
        { text: 'Is Brood', style: TABLESTYLES.tableHeaderCenter },
        { text: 'RFID', style: TABLESTYLES.tableHeaderCenter },
        { text: 'Tick If Tagged', style: TABLESTYLES.tableHeaderCenter }])

    //-------------------------
    // Get fish
    //-------------------------
    const getFish = async () => {
        let params = {
            site: getUserSiteId(),
            storage: storageId,
            active: true,
            batch: batchId,
        }

        if(selectedFish.length > 0) params["ids"] = selectedFish.toString()
                
        return await axios.get(`${ACISAPIURL}fishlitefull/`, {
            params: params,
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []

        response.data.results.sort((a,b) => (a.pKey > b.pKey) ? 1 : ((b.pKey > a.pKey) ? -1 : 0)).forEach( fish => {
            data.push([
                { text: fish.id, style: TABLESTYLES.tableCellLeft },
                { text: fish.isBrood ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO"), style: TABLESTYLES.tableCellCenter },
                { text: fish.tag_id == null ? "" : fish.tag_id, style: TABLESTYLES.tableCellCenter },
                { text: "",  style: TABLESTYLES.tableCellCenter },
            ])

            total++
            if(fish.tag_id != null) tagged++
        })
        fishDataSource = fishDataSource.concat(data)
        pending = total - tagged
    }

    //---------------------------
    // Fetch data
    //---------------------------
    await getFish()
    .catch( error => {
        errorFound = true
        reportError(error, "Failed to get fish list.")
    })
    
    if(errorFound) return

    //---------------------------
    // Start generate pdf content
    //---------------------------
    const title = await reportTitle("Physical Tagging Checklist")

    const docDefinition = {
        pageSize: PAGESIZE,
        pageMargins: PAGEMARGINS,
        footer: FOOTER,
        
        content: [{
            stack: [    
                title,
                
                {
                    table: {
                        widths: [ '16.66%', '16.66%', '16.66%', '16.66%', '16.66%', '16.66%' ],
                        body: [
                                [
                                    { text: 'Batch ID :', style: TABLESTYLES.tableHeaderRight },
                                    { text: batch, style: TABLESTYLES.tableCellLeft },
                                    { text: 'Storage :', style: TABLESTYLES.tableHeaderRight },
                                    { text: storage, style: TABLESTYLES.tableCellLeft },
                                    { text: 'Species :', style: TABLESTYLES.tableHeaderRight },
                                    { text: species, style: TABLESTYLES.tableCellLeft },
                                ],
                                [
                                    { text: 'Total (Tail) :', style: TABLESTYLES.tableHeaderRight },
                                    { text: total, style: TABLESTYLES.tableCellLeft },
                                    { text: 'Tagged (Tail) :', style: TABLESTYLES.tableHeaderRight },
                                    { text: tagged, style: TABLESTYLES.tableCellLeft },
                                    { text: 'Pending (Tail) :', style: TABLESTYLES.tableHeaderRight },
                                    { text: pending, style: TABLESTYLES.tableCellLeft },
                                ],
                        ]
                    }
                },

                // Empty row
                { text: ' '},

                {
                    //layout: 'headerLineOnly',
                    table: {
                        headerRows: 1,
                        widths: [ '30%', '15%', '35%', '20%' ],
                        body: fishDataSource
                    }
                }
            ]}
        ]
    }

    pdfMake.createPdf(docDefinition).print()
}