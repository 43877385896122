import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, message, Popconfirm, Pagination, Spin } from 'antd'
import { PlusOutlined, DeleteOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT,  LOADING,  PAGESIZE,  PAGINATIONSIZE,  UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import EnvironmentReadingTypeSelect from '../Common/EnvironmentReadingTypeSelect'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { RangePicker } = DatePicker

//----------
// Component
//----------
const EnvironmentTable = () => {
    const MAXSEARCHDAYS = parseInt(OTHERSYSPARAM("ENV_HISTORY_BACKDATE_DAYS"))

    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    //const [siteId, setSiteId] = useState(0)
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const navigate = useNavigate()
    //const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [dateRange, setDateRange] = useState([moment((moment().subtract(MAXSEARCHDAYS, "days").add(1, "days")), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [environmentReadingTypeId, setEnvironmentReadingTypeId] = useState(0)
    const [environmentReadingType, setEnvironmentReadingType] = useState("")
    const [isLoading, setIsLoading] = useState(false)
        
    const [environmentDataSource, setEnvironmentDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //--------------------------
    // Delete environment record
    //--------------------------
    const deleteEnvironmentRecord = (e, record) => {
        axios.delete(`${ACISAPIURL}environment/delete/${record.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Environment record deleted.`)
            setEnvironmentDataSource(environmentDataSource.filter((item) => item.key !== record.key))
        })
        .catch( error => {
            reportError(error, "Failed to delete environment record.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //-------------------
    // Search environment
    //-------------------
    const searchEnvironment = async (currentPage, urlParamSearchStoragId = 0) => {
        setDisableButton("disabled")
                
        await axios.get(`${ACISAPIURL}environment/`, {
            params: { 
                site: getUserSiteId(), 
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                storage: urlParamSearchStoragId != 0 ? urlParamSearchStoragId : storageId,
                reading_type: environmentReadingTypeId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 10),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search environment record.")
        })
        .finally(() => {
            setDisableButton("")
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        let n = 0
        response.data.results.forEach( env => {
            data.push({
                key: env.id,
                storage: env.storage_data.name,
                readingType: env.environment_reading_type_data.name,
                uom: env.environment_reading_type_data.uom,
                value: numberWithCommas(env.state),
                createdOn: moment(env.createdOn).format(DATETIMEFORMAT),
                manual: env.manual
            })
        })
        
        setEnvironmentDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(environmentReadingTypeId != "") searchCriteria = `${searchCriteria}\nEnvirontmnet Reading Type: ${environmentReadingType}`
                
        axios.get(`${ACISAPIURL}environment/download/`, {
            params: { 
                site: getUserSiteId(), 
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                storage: storageId,
                reading_type: environmentReadingTypeId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Water Quality ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)

        setIsLoading(true)
        searchEnvironment(page)
        setIsLoading(false)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = async () => {
        if(dateRange[0].diff(dateRange[1], "days") <= MAXSEARCHDAYS * -1 || dateRange[1].diff(dateRange[0], "days") >= MAXSEARCHDAYS) {
            message.info(`Please limit search date range to maximum of ${MAXSEARCHDAYS} days only.`)
            return
        }

        if(storageId == 0) {
            message.info("Please select a storage before search.")
            return
        }

        setCurrentPage(1)

        setIsLoading(true)
        await searchEnvironment(1)
        setIsLoading(false)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/environmentnew" })
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onSearchEnvironmentReadingTypeChange = (e, value) => {
        setEnvironmentReadingTypeId(e)
        setEnvironmentReadingType(value.children)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        /*if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchEnvironment(currentPage, urlParams.get("storageId"))
        }
        else
            searchEnvironment(currentPage)
        */
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Reading Type', dataIndex: 'readingType', key: 'readingType', sorter: (a, b) => a.readingType.localeCompare(b.readingType) },
        { title: 'Unit Of Measurement', dataIndex: 'uom', key: 'uom', sorter: (a, b) => a.uom.localeCompare(b.uom) },
        { title: 'Value', dataIndex: 'value', key: 'value', align: "right", sorter: (a, b) => stringNumberSorter(a.value, b.value) },
        { title: 'Recorded On', dataIndex: 'createdOn', key: 'createdOn', sorter: (a, b) => a.createdOn.localeCompare(b.createdOn) },
        { key: 'action',
            render: (record) => {
                if(record.manual)
                    return <Popconfirm title="Delete environment record confirmed?" 
                        onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => deleteEnvironmentRecord(e, record)} 
                        okText="Yes" cancelText="No">
                        <Button danger type="primary" disabled={disableButton} icon={<DeleteOutlined/>} />
                    </Popconfirm>
                else
                    return null
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Environment Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onStorageChange={onSearchStorageChange} showLockedStorage={false} defaultStorageId={storageId}/>

                            <Form.Item name="environmentReadingTypeId" label="Environment Reading Type">
                                <EnvironmentReadingTypeSelect withBlank={true} onChange={onSearchEnvironmentReadingTypeChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={environmentDataSource} pagination={false} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default EnvironmentTable