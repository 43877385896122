import { useEffect, useState } from 'react'
import { Row, Col, DatePicker, Button, message, Form, Collapse, Space, Table, Pagination, Spin } from 'antd'
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import { getUserAuthToken, getUserSiteId, getUserSiteName, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { formLayout_2Columns } from '../Common/Layout'
import moment from 'moment'
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT } from '../Common/SystemParameter'
import axios from 'axios'
import { loadMarineLifeItemTypeUnitOfMeasurementStandardTable, numberWithCommas, reportError, stringNumberSorter } from '../Common/Utility'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { RangePicker } = DatePicker 
const { Panel } = Collapse

//----------
// Component
//----------
const StockCountReportTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH") / 2, "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")

    const [stockCountSummaryDataSource, setStockCountSummaryDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    //--------------------------
    // Download report
    //--------------------------
    const downloadReport = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}stockcount/report/download/`, {
            params: { 
                site: getUserSiteId(), 
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                marine_life: marineLifeId,
                species: speciesId,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("No report found.")
            else {
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Stock Count Report ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download report pdf.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Download summary
    //--------------------------
    const downloadSummary = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)

        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
                
        axios.get(`${ACISAPIURL}stockcount/summary/download/`, {
            params: { 
                site: getUserSiteId(), 
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                marine_life: marineLifeId,
                species: speciesId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("No report found.")
            else {
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Stock Count Summary ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download summary pdf.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Search growth development
    //--------------------------
    const searchStockCountSummary = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}stockcount/summary/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                marine_life: marineLifeId,
                species: speciesId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search stock count summary record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = async (response) => {
        const standardITUOMTable = await loadMarineLifeItemTypeUnitOfMeasurementStandardTable()
        const data = []

        response.data.results.forEach( summary => {
            let ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == summary.marine_life_data.pKey && row.item_type_data.pKey == summary.item_type)
            
            data.push({
                key: summary.id,
                batch: summary.batch_data.id,
                storage: summary.storage_data.name,
                completedOn: moment(summary.completedOn).format(DATETIMEFORMAT),
                itemType: summary.item_type_data.name,
                uom: ituom.length > 0 ? ituom[0].uom_data.uom : "",
                stockBalance: numberWithCommas(summary.stock_balance),
                countedBalance: numberWithCommas(summary.counted_balance),
                variance: numberWithCommas(summary.variance),
            })
        })
        
        setStockCountSummaryDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }
    
    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStockCountSummary(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStockCountSummary(1)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchStockCountSummary(currentPage)
    }, [])

    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Completed On', dataIndex: 'completedOn', key: 'completedOn', sorter: (a, b) => a.completedOn.localeCompare(b.completedOn) },
        { title: 'Item Type', dataIndex: 'itemType', key: 'itemType', sorter: (a, b) => a.itemType.localeCompare(b.itemType) },
        { title: 'UoM', dataIndex: 'uom', key: 'uom', sorter: (a, b) => a.uom.localeCompare(b.uom) },
        { title: 'Stock Balance', dataIndex: 'stockBalance', key: 'stockBalance', align: "right", sorter: (a, b) => stringNumberSorter(a.stockBalance, b.stockBalance) },
        { title: 'Counted Balance', dataIndex: 'countedBalance', key: 'countedBalance', align: "right", sorter: (a, b) => stringNumberSorter(a.countedBalance, b.countedBalance) },
        { title: 'Variance', dataIndex: 'variance', key: 'variance', align: "right", sorter: (a, b) => stringNumberSorter(a.variance, b.variance) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Stock Count Summary" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Report Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onSearchMarineLifeChange} onSpeciesChange={onSearchSpeciesChange}/>

                            <Row justify="center">
                                <Col span={24} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadReport("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />}>Report</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadReport("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />}>Report</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSummary("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />}>Summary</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSummary("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />}>Summary</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={stockCountSummaryDataSource} pagination={false}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default StockCountReportTable