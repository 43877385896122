import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, Modal, DatePicker, Input, PageHeader, Card, Switch, Descriptions, Select, Spin } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, CRABMARINELIFENAME, DATETIMEFORMAT, LOADING, MENUPATH_HATCHINGCRAB, UNIDATEFORMAT } from "../Common/SystemParameter"
import { getBatchFullAquacultureStageLifecycle, reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import moment from 'moment'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { Title } = Typography
const { TextArea } = Input
const { Option } = Select

//----------
// Component
//----------
const HatchingNewCrab = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [hatchedOn, setHatchedOn] = useState(moment(moment(), UNIDATEFORMAT))
    const [broodRemovedOn, setBroodRemovedOn] = useState(null)
    const [observedOn, setObservedOn] = useState(moment(moment(), UNIDATEFORMAT))
    const [broodRemoved, setBroodRemoved] = useState(false)
    const [broodCount, setBroodCount] = useState(0)
    const [isolationIterationOption, setIsolationIterationOption] = useState([<Option key={1}>1</Option>, <Option key={2}>2</Option>])
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //----------------
    // Create hatching
    //----------------
    const createHatching = (advanceToNursery) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}hatching/create/crab/`, {
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                days_since_isolation: values.daysSinceIsolation,
                quantity: parseInt(values.quantity),
                isolation_iteration: values.isolationIteration,
                
                brood_removed: broodRemoved,
                remark: values.remark,
                hatchedOn: hatchedOn,
                broodRemovedOn: broodRemovedOn,
                observedOn: observedOn,
                advance_to_nursery: advanceToNursery
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Hatching record saved.")
                message.info("The UNTAGGED weight of this batch and storage is zerorized. Use growth development record to update the weight of UNTAGGED adult crab if needed.", 15)
                navigate({ 
                    pathname: MENUPATH_HATCHINGCRAB
                })
            })
            .catch( error => {
                reportError(error, `Failed to save hatching record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //-----------------------------
    // Calculate days since transfer
    //-----------------------------
    const calculateDaysSinceTransfer = () => {
        if(hatchedOn == null) {
            message.warning("Please enter hatching date time before proceed.")
            return
        }

        setIsLoading(true)

        axios.get(`${ACISAPIURL}hatching/calculatedayssincelastintransaction/`, {
            params: { 
                hatching_date_time: hatchedOn,
                storage: urlParams.get("storageId")
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const duration = response.data.duration
            form.setFieldsValue({
                daysSinceIsolation : duration.days,
            })
        })
        .catch( error => {
            reportError(error, "Failed to calculate days since transfer.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------------------------
    // Get brood number in storage
    //-----------------------------
    const getBroodCount = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}fish/broodcount/`, {
            params: { 
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            setBroodCount(response.data.brood_count)
        })
        .catch( error => {
            reportError(error, "Failed to get brood count.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //----------
    // On next 2
    //----------
    const onSave2 = (toNextAquacultureStage) => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Save hatching record will zerorize this batch and storage UNTAGGED weight. It will also set the batch birth date to today. Proceed?</p></Space>,
            onOk() { createHatching(toNextAquacultureStage) },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On next
    //--------
    const onSave = async (e) => {
        const fullAquacultureStageLifecycle = await getBatchFullAquacultureStageLifecycle(urlParams.get("batchId"))

        // If batch is the FIRST in aquaculture stage and lifecycle.
        let currentAquacultureStageLifecycleIndex = -1
        if(fullAquacultureStageLifecycle[0].aquaculture_stage == urlParams.get("aquacultureStageId") && fullAquacultureStageLifecycle[0].lifecycle == urlParams.get("lifecycleId")) 
            currentAquacultureStageLifecycleIndex = 0

        const nextAquacultureStageLifecycleIndex = currentAquacultureStageLifecycleIndex + 1
        let nextAquacultureStage = null
        if(currentAquacultureStageLifecycleIndex == 0)
            nextAquacultureStage = fullAquacultureStageLifecycle[nextAquacultureStageLifecycleIndex]

        form.validateFields()
        .then( values => {
            if(broodRemoved && broodCount > 0) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Brood found in this storage. Continue to save record with brood removed?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { 
                        if(nextAquacultureStage != null)
                            confirm({
                                icon: <QuestionCircleOutlined />,
                                content: <Space><p>Advance batch aquaculture stage to {nextAquacultureStage.aquaculture_stage_data.name} and lifecycle of {nextAquacultureStage.lifecycle_data.name}?</p></Space>,
                                okText: "Yes",
                                cancelText: "No",
                                onOk() { onSave2(true) },
                                onCancel() { onSave2(false) },
                                centered: true
                            })
                        else
                            confirm({
                                icon: <QuestionCircleOutlined />,
                                content: <Space><p>Save hatching record confirmed?</p></Space>,
                                onOk() { onSave2(false) },
                                onCancel() {},
                                centered: true
                            })
                    },
                    onCancel() {},
                    centered: true
                })
            }
            else {
                //if(urlParams.get("aquacultureStageId") <= SYSPARAM("Incubation"))
                //if(urlParams.get("aquacultureStage").startsWith(SPAWNING) || urlParams.get("aquacultureStage").startsWith(INCUBATION))
                if(nextAquacultureStage != null) 
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Advance batch aquaculture stage to {nextAquacultureStage.aquaculture_stage_data.name} and lifecycle of {nextAquacultureStage.lifecycle_data.name}?</p></Space>,
                        okText: "Yes",
                        cancelText: "No",
                        onOk() { createHatching(true) },
                        onCancel() { createHatching(false) },
                        centered: true
                    })
                else
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Save hatching record confirmed?</p></Space>,
                        onOk() { createHatching(false) },
                        onCancel() {},
                        centered: true
                    })
            }
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: "/hatchingstorage",
            search: `?marineLife=${CRABMARINELIFENAME}`
        })
    }

    //---------------------
    // On date time change
    //---------------------
    const onHatchingDateTimeChange = (datetime) => {
        setHatchedOn(datetime)
    }

    //---------------------
    // On date time change
    //---------------------
    const onBroodRemovalDateTimeChange = (datetime) => {
        setBroodRemovedOn(datetime)
    }

    //---------------------
    // On date time change
    //---------------------
    const onObservationDateTimeChange = (datetime) => {
        setObservedOn(datetime)
    }

    //--------------------
    // On brood removed change
    //--------------------
    const onBroodRemovedChange = (checked) => {
        setBroodRemoved(checked)
        if(checked) {
            setBroodRemovedOn(moment(moment(), UNIDATEFORMAT))
            form.setFieldsValue({
                broodRemovedOn: moment(moment(), UNIDATEFORMAT)
            })
        }
        else {
            setBroodRemovedOn(null)
            form.setFieldsValue({
                broodRemovedOn: null
            })
        }
    }

    //------------------
    // Custom validation
    //------------------
    const validateHatchingDateTime = (() => {
        if(hatchedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Hatching date time is required."))
    })

    const validateObservationDateTime = (() => {
        if(observedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Observation date time is required."))
    })

    const validateBroodRemovalDateTime = (() => {
        if(broodRemoved == false) {
            return Promise.resolve()
        }

        if(broodRemoved == true && broodRemovedOn != null) {
            return Promise.resolve()
        }

        return Promise.reject(new Error("Brood removal date time is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getBroodCount()

        form.setFieldsValue({
            isolationIteration: 1,
            remark: ""
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Hatching">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create hatching record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Brood Count:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{broodCount}</Card.Grid>
                        </Card>
                    </Form.Item>

                    <Form.Item name="hatchedOn" label="Hatching Date Time"
                        rules={[
                            { required: true, validator: validateHatchingDateTime },
                        ]}>
                        <DatePicker showTime onChange={onHatchingDateTimeChange} defaultValue={hatchedOn} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item label="Days Since Isolation">
                        <Input.Group>
                            <Form.Item name="daysSinceIsolation" noStyle
                                rules={[
                                    { required: true, message: "Days since isolation is required."},
                                ]}>
                                <InputNumber min={0} max={10000} step={1} placeholder="Days Since Isolation"/>
                            </Form.Item>
                            <Button onClick={calculateDaysSinceTransfer}>Calculate</Button>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item label="Isolation Number">
                        <Input.Group>
                            <Form.Item name="isolationIteration" noStyle
                                rules={[
                                    { required: true, message: "Number of isolation is required." },
                                ]}>
                                <Select defaultValue={1} showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {isolationIterationOption}
                                </Select>
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                    
                    <Form.Item label={`Successfully Hatched Zoea Quantity (Unit)`}>
                        <Input.Group>
                            <Form.Item name="quantity" noStyle
                                rules={[
                                    { required: true, message: "Hatched zoea quantity is required."},
                                ]}>
                                <InputNumber min={1} max={1000000000} step={100000} placeholder="Zoea Quantity"/>
                            </Form.Item>
                            <span> Unit</span>
                        </Input.Group>
                    </Form.Item>
                    
                    <Form.Item name="broodRemoved" label="Brood Removed" hidden>
                        <Switch defaultChecked={broodRemoved} onChange={onBroodRemovedChange} />
                    </Form.Item>

                    <Form.Item name="broodRemovedOn" label="Brood Removal Date Time" hidden
                        rules={[
                            { required: true, validator: validateBroodRemovalDateTime },
                        ]}>
                        <DatePicker showTime onChange={onBroodRemovalDateTimeChange} defaultValue={broodRemovedOn} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="observedOn" label="Observation Date Time"
                        rules={[
                            { required: true, validator: validateObservationDateTime },
                        ]}>
                        <DatePicker showTime onChange={onObservationDateTimeChange} defaultValue={observedOn} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="remark" label="Observation" >
                        <TextArea rows={3}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Hatching:"
                    subTitle="Create hatching record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default HatchingNewCrab