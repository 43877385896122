import { Table, Row, Col, Space, Button, Form, Collapse, message, Card, Spin, DatePicker, InputNumber } from 'antd'
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, LOADING, MONTHFORMAT, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import WeightRangeInput from '../Common/WeightRangeInput'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse


//----------
// Component
//----------
const InventoryForecastTableV2 = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [forecastMonth, setForecastMonth] = useState(moment().add(1, 'months'), MONTHFORMAT)
            
    const [weightRange, setWeightRange] = useState([-1, -1]) // Cannot use [0, 0] because 0 is valid input.
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    
    const [inventoryForecastDataSource, setInventoryForecastDataSource] = useState([])
    const [inventoryForecastSummaryDataSource, setInventoryForecastSummaryDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    
    //------------------------
    // Search forecast summary
    //------------------------
    const generateInventoryForecast = () => {
        setDisableButton("disabled")
        setIsLoading(true)

        form.validateFields()
        .then( values => {
            axios.get(`${ACISAPIURL}inventory/forecast2/summary/`, {
                params: { 
                    site: getUserSiteId(),
                    forecast_month: moment(forecastMonth).format(UNIDATEFORMAT),
                    species: speciesId,
                    from_weight: weightRange[0],
                    to_weight: weightRange[1],
                    mortality_percent: values.mortalityPercent
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 12),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                pushData(response)
            })
            .catch( error => {
                reportError(error, "Failed to generate inventory forecast.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }
    
    const pushData = (response) => {
        const data = []
        const summaryData = []

        response.data.forEach( summary => {
            const forecast = JSON.parse(summary)
            data.push({
                key: forecast.id,
                species: forecast.species,
                batch: forecast.batch,
                storage: forecast.storage,
                ageDays: numberWithCommas(forecast.age_days),
                quantity: numberWithCommas(forecast.quantity),
                unitWeightGram: numberWithCommas(forecast.unit_weight_gram),
                totalWeightKg: numberWithCommas(forecast.total_weight_kg.toFixed(2)),
                mortalityWeightKg: numberWithCommas((forecast.mortality_weight_kg).toFixed(2)),
                forecastedWeightKg: numberWithCommas(forecast.forecasted_weight_kg.toFixed(2))
            })

            let index = summaryData.findIndex( summary => summary.species === forecast.species)
            
            if(index !== -1) {
                let storedForecast = summaryData.find(summary => summary.species === forecast.species)
                storedForecast.quantity = numberWithCommas(parseInt(storedForecast.quantity.replace(',', '')) + forecast.quantity)
                storedForecast.totalWeightKg = numberWithCommas((parseFloat(storedForecast.totalWeightKg.replace(',', '')) + parseFloat(forecast.total_weight_kg)).toFixed(2))
                storedForecast.mortalityWeightKg = numberWithCommas((parseFloat(storedForecast.mortalityWeightKg.replace(',', '')) + parseFloat(forecast.mortality_weight_kg)).toFixed(2))
                storedForecast.forecastedWeightKg = numberWithCommas((parseFloat(storedForecast.forecastedWeightKg.replace(',', '')) + parseFloat(forecast.total_weight_kg) + parseFloat(forecast.mortality_weight_kg)).toFixed(2))
            }
            else {
                summaryData.push({
                    species: forecast.species,
                    quantity: numberWithCommas(forecast.quantity),
                    totalWeightKg: forecast.total_weight_kg.toFixed(2),
                    mortalityWeightKg: (forecast.mortality_weight_kg).toFixed(2),
                    forecastedWeightKg: (forecast.forecasted_weight_kg).toFixed(2)
                })
            }
        })

        setInventoryForecastDataSource(data)
        setInventoryForecastSummaryDataSource(summaryData)

        // Total pages
        setTotalRecord(response.data.count)
    }
    
    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        form.validateFields()
        .then( values => {
            // Build search criteria string for display in pdf
            let searchCriteria = `Site: ${getUserSiteName()}`
            searchCriteria = `${searchCriteria}\nForecast Month: ${moment(forecastMonth).format(MONTHFORMAT)}`
            if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
            searchCriteria = `${searchCriteria}\nWeight >= ${numberWithCommas(weightRange[0])}g`
            if(weightRange[1] >=0) searchCriteria = `${searchCriteria}\nWeight <= ${numberWithCommas(weightRange[1])}g`
            searchCriteria = `${searchCriteria}\nOne-Off Mortality: ${values.mortalityPercent}%`
                    
            axios.get(`${ACISAPIURL}inventory/forecast2/summary/download/`, {
                params: { 
                    site: getUserSiteId(),
                    forecast_month: moment(forecastMonth).format(UNIDATEFORMAT),
                    species: speciesId,
                    from_weight: weightRange[0],
                    to_weight: weightRange[1],
                    mortality_percent: values.mortalityPercent,
                    search_criteria: searchCriteria,
                    mode: mode
                },
                responseType: "blob",
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                if(response?.data?.type == APPJSON)
                    message.info("Search returns no result.")
                else {
                    const now = moment().format(UNIDATETIMEFORMAT)
                    let ext = "pdf"
                    if(mode == "excel") ext = "xlsx"
                    FileDownload(response.data, `Inventory Forecast ${now}.${ext}`)
                }
            })
            .catch( error => {
                reportError(error, "Failed to download search result.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        if(weightRange[0] == -1) {
            message.info('From weight is required.')
            return
        }

        if(weightRange[1] == -1) {
            message.info('To weight is required.')
            return
        }

        if(weightRange[0] >= weightRange[1]) {
            message.info('\"To\" weight must be greater than \"from\" weight.')
            return
        }

        generateInventoryForecast(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onWeightRangeChange = (fromWeight, toWeight) => {
        setWeightRange([fromWeight, toWeight])
    }

    const onForecastMonthChange = (month) => {
        setForecastMonth(month)
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().add(1, 'months').endOf('day')
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            mortalityPercent: 0
        })
    }, [])

    //--------------
    // Table columns
    //--------------
    const summary_columns = [
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: '±Total Quantity (Tail)', dataIndex: 'quantity', key: 'quantity', align: 'right', sorter: (a, b) => stringNumberSorter(a.quantity, b.quantity) },
        { title: '±Total Weight (Kg)', dataIndex: 'totalWeightKg', key: 'totalWeightKg', align: 'right', sorter: (a, b) => stringNumberSorter(a.totalWeightKg, b.totalWeightKg) },
        { title: '±Total Mortality Weight (Kg)', dataIndex: 'mortalityWeightKg', key: 'mortalityWeightKg', align: 'right', sorter: (a, b) => stringNumberSorter(a.mortalityWeightKg, b.mortalityWeightKg) },
        { title: '±Total Forecasted Weight (Kg)', dataIndex: 'forecastedWeightKg', key: 'forecastedWeightKg', align: 'right', sorter: (a, b) => stringNumberSorter(a.forecastedWeightKg, b.forecastedWeightKg) },
    ]

    const columns = [
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => stringNumberSorter(a.batch, b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', align: "center", sorter: (a, b) => stringNumberSorter(a.storage, b.storage) },
        { title: 'Age (days) on Forecast Month', dataIndex: 'ageDays', key: 'ageDays', align: 'center', sorter: (a, b) => stringNumberSorter(a.ageDays, b.ageDays) },
        { title: '±Quantity (Tail)', dataIndex: 'quantity', key: 'quantity', align: 'right', sorter: (a, b) => stringNumberSorter(a.quantity, b.quantity) },
        { title: '±Unit Weight (g)', dataIndex: 'unitWeightGram', key: 'unitWeightGram', align: 'right', sorter: (a, b) => stringNumberSorter(a.unitWeightGram, b.unitWeightGram) },
        { title: '±Total Weight (Kg)', dataIndex: 'totalWeightKg', key: 'totalWeightKg', align: 'right', sorter: (a, b) => stringNumberSorter(a.totalWeightKg, b.totalWeightKg) },
        { title: '±Mortality Weight (Kg)', dataIndex: 'mortalityWeightKg', key: 'mortalityWeightKg', align: 'right', sorter: (a, b) => stringNumberSorter(a.mortalityWeightKg, b.mortalityWeightKg) },
        { title: '±Forecasted Weight (Kg)', dataIndex: 'forecastedWeightKg', key: 'forecastedWeightKg', align: 'right', sorter: (a, b) => stringNumberSorter(a.forecastedWeightKg, b.forecastedWeightKg) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        
        <Row justify="center">
            <Col span={6} />
            <Col span={12}>
                <Card title="Note on weight range search criteria" style={{textAlign: "left" }} >
                    <>
                    It is crucial to know the standard growth records available in database. In the case of "From Weight (g)". If exact match cannot be found then
                    the next larger weight in growth record will be selected. In the case of "To Weight (g)". If exact match cannot be found then the
                    previous smaller weight in growth record will be selected.
                    <div><br/></div>
                    For example, weight range of 800g to 1200g are entered as search criteria. Yet, 800g growth
                    record is not available but 900g is, so 900g will be selected. Yet again, 1200g growth record is not available but 1100g is, so 1100g will be selected.
                    The search result will then be based on weight range of 900g to 1100g.
                    <div><br/></div>
                    Hence, it is highly recommended to have the exact match of growth records in standard growth table before forecasting.
                    </>
                </Card>
            </Col>
            <Col span={6} />
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Inventory Forecast Data Filter" key="1">
                        <Form form={form} {...formLayout_2Columns} onFinish={onSearch}>
                            <Form.Item name="dateRange" label="Forecast Month">
                                <DatePicker
                                    allowClear={false}
                                    picker="month"
                                    defaultValue={forecastMonth} 
                                    format={MONTHFORMAT}
                                    disabledDate={disabledDate}
                                    onChange={onForecastMonthChange}/>
                            </Form.Item>

                            <CommonSearchFormItem onSpeciesChange={onSpeciesChange} />

                            <Form.Item name="weightRange" label="Weight Range"
                                rules={[
                                    { required: true, message: "Weight range is required."},
                                ]}>
                                <WeightRangeInput /*from={FROMWEIGHT} to={TOWEIGHT}*/ onChange={onWeightRangeChange}/>
                            </Form.Item>

                            <Form.Item name="mortalityPercent" label="One-Off Mortality (%)"
                                rules={[
                                    { required: true, message: "Mortality percent is required."},
                                ]}>
                                <InputNumber min={0.00} max={100} step={1} precision={2} placeholder="Mortality %"/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="submit" disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form> 
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={9} />
            <Col span={6}>
                <Card title="Summary" style={{textAlign: "left" }} >
                    Summation of all, grouped by species.
                </Card>
            </Col>
            <Col span={9} />
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={summary_columns} dataSource={inventoryForecastSummaryDataSource} pagination={false}/>

        <Row><Col><Space><div /></Space></Col></Row>
        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={9} />
            <Col span={6}>
                <Card title="Detail" style={{textAlign: "left" }} >
                    Information of batch and storage that match the criteria.
                </Card>
            </Col>
            <Col span={9} />
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        
        <Table bordered columns={columns} dataSource={inventoryForecastDataSource} 
            pagination={{
                position: ["bottomCenter"],
                size: PAGINATIONSIZE,
                total: totalRecord,
                showTotal: showTotal,
                pageSize: PAGESIZE,
                hideOnSinglePage: false,
                showSizeChanger: false}}
        />

        <Row><Col><Space><div /></Space></Col></Row>
        </Spin>
        </>
    )
}

export default InventoryForecastTableV2
