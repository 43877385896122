import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, message, Pagination, Image, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, FileImageOutlined, FileExcelOutlined } from "@ant-design/icons"
import { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT,  LOADING,  PAGESIZE,  PAGINATIONSIZE,  UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, previewImage, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import SpawningStageSelect from '../Common/SpawningStatusSelect'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { RangePicker } = DatePicker

//----------
// Component
//----------
const EggDevelopmentTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [fishId, setFishId] = useState(0)
    const [fish, setFish] = useState("")
    const [spawningStageId, setSpawningStageId] = useState(0)
    const [spawningStage, setSpawningStage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [eggDevelopmentDataSource, setEggDevelopmentDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //-----------------------
    // Search egg development
    //-----------------------
    const searchEggDevelopment = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}eggdevelopment/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : storageId,
                fish: fishId,
                spawning_status: spawningStageId,
                marine_life: marineLifeId,
                species: speciesId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search egg development record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( egg => {
            data.push({
                key: egg.pKey,
                id: egg.id,
                batch: egg.batch_data.id,
                storage: egg.storage_data.name,
                fish: egg?.fish_data?.id != null ? egg.fish_data.id : "",
                eggSize: numberWithCommas(egg.egg_diameter),
                oilGlobuleSize: numberWithCommas(egg.oil_globule_diameter),
                spawningStatusId: egg.spawning_status_data.pKey,
                spawningStatus: egg.spawning_status_data.name,
                dailySampleNumber: egg.daily_sample_number,
                observationDateTime: moment(egg.observedOn).format(DATETIMEFORMAT),
                observationDateTimeUnformatted: moment(egg.observedOn).format(UNIDATETIMEFORMAT),
                gonadMaturation: egg.gonad_maturation_data.name,
                gonadMaturationId: egg.gonad_maturation_data.pKey,
                
                batchId: egg.batch_data.pKey,
                batchType: egg.batch_data.batch_type_data.name,
                species: egg.batch_data.species_data.short_name,
                acquiringMethod: egg.batch_data.acquiring_method_data.name,
                storageId: egg.storage_data.pKey,
                lifecycle: egg.lifecycle_data.name,
                aquacultureStage: egg.aquaculture_stage_data.name,
                fishId: egg?.fish_data?.pKey != null ? egg.fish_data.pKey : 0,
                remark: egg.remark,
                img: egg.img,
                createdOn: moment(egg.createdOn).format(DATETIMEFORMAT),
                updatedOn: moment(egg.updatedOn).format(DATETIMEFORMAT),

                availableEggQuantity: egg?.eggstocksummary.quantity != null ? egg.eggstocksummary.quantity : 0,
            })
        })

        setEggDevelopmentDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(fishId != 0) searchCriteria = `${searchCriteria}\nTag ID: ${fish}`
        if(spawningStageId != 0) searchCriteria = `${searchCriteria}\nSpawing Stage: ${spawningStage}`
                                
        axios.get(`${ACISAPIURL}eggdevelopment/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                fish: fishId,
                spawning_status: spawningStageId,
                marine_life: marineLifeId,
                species: speciesId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Egg Development ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchEggDevelopment(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchEggDevelopment(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/eggdevelopmentstorage" })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/eggdevelopmentupdate", 
                    search: `?pKey=${record.key}&id=${record.id}&batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&species=${record.species}&acquiringMethod=${record.acquiringMethod}&storageId=${record.storageId}&storage=${record.storage}&aquacultureStage=${record.aquacultureStage}&lifecycle=${record.lifecycle}&fishId=${record.fishId}&fish=${record.fish}&eggDevelopmentStageId=${record.spawningStatusId}&eggDevelopmentStage=${record.spawningStatus}&remark=${encodeURI(String(record.remark).trim())}&observationDateTime=${record.observationDateTimeUnformatted}&eggSize=${record.eggSize.replace(',', '')}&oilGlobuleSize=${record.oilGlobuleSize.replace(',', '')}&quantity=${record.quantity}&createdOn=${record.createdOn}&updatedOn=${record.updatedOn}&availableEggQuantity=${record.availableEggQuantity}&gonadMaturationId=${record.gonadMaturationId}&gonadMaturation=${record.gonadMaturation}&img=${record.img}`
                }) 
            }
        }
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onSearchFishIdChange = (e, value) => {
        setFishId(e)
        setFish(value.children)
    }

    const onSearchSpawningStageChange = (e, value) => {
        setSpawningStageId(e)
        setSpawningStage(value.children)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchEggDevelopment(currentPage, urlParams.get("storageId"))
        }
        else
            searchEggDevelopment(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        //{ title: 'Egg. Dev. ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Tag ID', dataIndex: 'fish', key: 'fish', sorter: (a, b) => a.fish.localeCompare(b.fish) },
        { title: 'Daily Sample No.', dataIndex: 'dailySampleNumber', key: 'dailySampleNumber', align: 'right', sorter: (a, b) => a.dailySampleNumber - b.dailySampleNumber },
        { title: 'Egg Size(mm)', dataIndex: 'eggSize', key: 'eggSize', align: "right", sorter: (a, b) => stringNumberSorter(a.eggSize, b.eggSize) },
        { title: 'Oil Globule Size(mm)', dataIndex: 'oilGlobuleSize', key: 'oilGlobuleSize', align: "right", sorter: (a, b) => stringNumberSorter(a.oilGlobuleSize, b.oilGlobuleSize) },
        { title: 'Spawn Stage', dataIndex: 'spawningStatus', key: 'spawningStatus', sorter: (a, b) => a.spawningStatusId - b.spawningStatusId },
        { title: 'Observed On', dataIndex: 'observationDateTime', key: 'observationDateTime', sorter: (a, b) => a.observationDateTime.localeCompare(b.observationDateTime) },
        { title: 'Images', dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) {
                    return <Button type="primary" htmlType="button" icon={<FileImageOutlined />} 
                        onClick={(e) => previewImage(e, "", img)} />
                }
                else
                    return null
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Egg Development Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} onTagIdChange={onSearchFishIdChange} activeTaggedOnly={true} showLockedStorage={false} defaultStorageId={storageId} showActiveBatchSwitch={true}/>

                            <Form.Item name="spawningStage" label="Spawning Stage">
                                <SpawningStageSelect withBlank={true} onChange={onSearchSpawningStageChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={eggDevelopmentDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default EggDevelopmentTable