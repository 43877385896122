import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, DatePicker, PageHeader, Card, Modal, Descriptions, Input, Upload, Spin } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, CRABMARINELIFENAME, DATETIMEFORMAT, LOADING, MENUPATH_GROWTHDEV, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { getBatchFullAquacultureStageLifecycle, numberWithCommas, reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import moment from 'moment'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal
const { TextArea } = Input

//----------
// Component
//----------
const GrowthDevelopmentNew = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [mouthOpenedDateTime, setMouthOpenedDateTime] = useState(null)
    const [imageFile, setImageFile] = useState([])
    const [uploading, setUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const newObservedOn = moment(urlParams.get("observedOn")).add(1, 'seconds')
    const [observationDateTime, setObservationDateTime] = useState(newObservedOn)
    const [noAgeColor, setNoAgeColor] = useState(urlParams.get("ageDays") != "null" ? "" : "Red")

    const [hideField, setHideField] = useState(false)
                        
    //--------------------------
    // Create growth development
    //--------------------------
    const createGrowthDevelopment = (advanceToGrowout) => {
        // Disable button.
        setDisableButton("disabled")
        setUploading(true)
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}growthdevelopment/create/`, {
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                fish: urlParams.has("fishId") ? urlParams.get("fishId") : null,
                mouth_width: parseFloat(values.mouth_width).toFixed(2),
                weight: parseFloat(values.weight).toFixed(2),
                length: parseFloat(values.length).toFixed(2),
                mouthOpenedOn: mouthOpenedDateTime,
                remark: values.remark,
                observedOn: observationDateTime,
                advance_aquaculture_stage_lifecycle: advanceToGrowout,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                if(response.data.averages_updated == false) message.warn("Back dated growth development record did not update batch average weight and length.")

                const search = `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&observedOn=${moment(observationDateTime).format(UNIDATETIMEFORMAT)}`
                if(imageFile == "") {
                    navigate({ 
                        pathname: "/growthdevelopmenttable", 
                        search: search
                    })
                    return
                }

                let data = new FormData()
                imageFile.forEach( file => {
                    data.append("img", file)
                })
                            
                fetch(`${ACISAPIURL}growthdevelopment/imgupload/${response.data.pKey}/`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Token ${getUserAuthToken()}`,
                    },
                    body: data,
                })
                .then( response => {
                    message.info("Egg development record saved.")
                    navigate({ 
                        pathname: "/growthdevelopmenttable", 
                        search: search
                    })
                })
                .catch( error => {
                    reportError(error, `Failed to upload image file.`)
                })
                .finally(() => {
                    setDisableButton("")
                    setUploading(false)
                    refreshUserSession()
                })
            })
            .catch( error => {
                reportError(error, `Failed to save growth development data.`)
            })
            .finally(() => {
                setDisableButton("")
                setUploading(false)
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //---------------------------------
    // Get first mouth opened date time
    //---------------------------------
    /*const getFirstMounthOpenedDateTime = () => {
        axios.get(`${ACISAPIURL}growthdevelopmentfirstmouthopeneddatetime/`, {
            params: {
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.mouthOpenedOn != null) 
                setMouthOpenedDateTime(moment(moment(response.data.mouthOpenedOn), UNIDATEFORMAT))
                form.setFieldsValue({
                    mouthOpenedDateTime: moment(moment(response.data.mouthOpenedOn), UNIDATEFORMAT)
                })
        })
        .catch( error => {
            reportError(error, "Failed to get mouth first opened date time.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }*/

    //---------------------------------
    // Get batch marine life type
    //---------------------------------
    const getBatchMarineLifeType = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}batch/${urlParams.get("batchId")}`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.species_data.marine_life_data.name == CRABMARINELIFENAME)
                setHideField(true)
        })
        .catch( error => {
            reportError(error, "Failed to get batch marine life type.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = async (e) => {
        const fullAquacultureStageLifecycle = await getBatchFullAquacultureStageLifecycle(urlParams.get("batchId"))
        
        let currentAquacultureStageLifecycleIndex = -1
        for(let aquacultureStageLifecycle of fullAquacultureStageLifecycle) {
            currentAquacultureStageLifecycleIndex += 1
            if(aquacultureStageLifecycle.aquaculture_stage == urlParams.get("aquacultureStageId") && aquacultureStageLifecycle.lifecycle == urlParams.get("lifecycleId"))
                break
        }
        
        const nextAquacultureStageLifecycleIndex = currentAquacultureStageLifecycleIndex + 1
        let nextAquacultureStage = null
        if(fullAquacultureStageLifecycle.length >= nextAquacultureStageLifecycleIndex)
            nextAquacultureStage = fullAquacultureStageLifecycle[nextAquacultureStageLifecycleIndex]
        
        form.validateFields()
        .then( values => {
            if(mouthOpenedDateTime == null && form.getFieldValue("mouth_width") > 0) {
                message.warn("Mouth opened date time is not provided but mouth width is not zero.")
                return
            }

            if(mouthOpenedDateTime != null && !mouthOpenedDateTime._isValid && form.getFieldValue("mouth_width") > 0) {
                message.warn("Mouth opened date time is not provided but mouth width is not zero.")
                return
            }

            if(mouthOpenedDateTime != null && mouthOpenedDateTime._isValid && form.getFieldValue("mouth_width") == 0) {
                message.warn("Mouth opened date time is provided but mouth width is zero.")
                return
            }

            // Any aquaculture stage and lifecycle.
            if(nextAquacultureStage != null) 
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch aquaculture stage to {nextAquacultureStage.aquaculture_stage_data.name} and lifecycle of {nextAquacultureStage.lifecycle_data.name}?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { createGrowthDevelopment(true) },
                    onCancel() { createConfirmation() },
                    centered: true
                })
            else
                createConfirmation()
        })
    }
    /*
    const onSave = async (e) => {
        form.validateFields()
        .then( values => {
            // if(parseFloat(values.mouth_width) == 0 && parseFloat(values.length) == 0) {
            //     message.warn("Both mouth width and length are zero.")
            //     return
            // }

            // Growout should have weight when sampling.
            // if(urlParams.get("aquacultureStageId") == SYSPARAM("Grow Out") && parseFloat(values.weight) == 0) {
            //     message.warn("Zero weight for growout. Please enter weight before proceed.")
            //     return
            // }

            if(mouthOpenedDateTime == null && form.getFieldValue("mouth_width") > 0) {
                message.warn("Mouth opened date time is not provided but mouth width is not zero.")
                return
            }

            if(mouthOpenedDateTime != null && !mouthOpenedDateTime._isValid && form.getFieldValue("mouth_width") > 0) {
                message.warn("Mouth opened date time is not provided but mouth width is not zero.")
                return
            }

            if(mouthOpenedDateTime != null && mouthOpenedDateTime._isValid && form.getFieldValue("mouth_width") == 0) {
                message.warn("Mouth opened date time is provided but mouth width is zero.")
                return
            }

            // Spawning
            if(urlParams.get("aquacultureStage").startsWith(SPAWNING)) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch aquaculture stage to {nextAquacultureStage}?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { createGrowthDevelopment(true) },
                    onCancel() { createConfirmation() },
                    centered: true
                })
            }
            // Incubation
            else if(urlParams.get("aquacultureStage").startsWith(INCUBATION)) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch aquaculture stage to {nextAquacultureStage}?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { createGrowthDevelopment(true) },
                    onCancel() { createConfirmation() },
                    centered: true
                })
            }
            // Non-Feeding Larvae
            else if(urlParams.get("aquacultureStage").startsWith(NURSERY) && urlParams.get("lifecycle").startsWith(NONFEEDINGLARVAE)) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch lifecycle to FEEDING LARVAE?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { createGrowthDevelopment(true) },
                    onCancel() { createConfirmation() },
                    centered: true
                })
            }
            // Feeding Larvae
            else if(urlParams.get("aquacultureStage").startsWith(NURSERY) && urlParams.get("lifecycle").startsWith(FEEDINGLARVAE)) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch aquaculture stage to GROW OUT (FRIES)?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { createGrowthDevelopment(true) },
                    onCancel() { createConfirmation() },
                    centered: true
                })
            }
            else { // Grow Out
                if(urlParams.get("lifecycle").startsWith(FRIES))
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Advance batch lifecycle to JUVENILE?</p></Space>,
                        okText: "Yes",
                        cancelText: "No",
                        onOk() { createGrowthDevelopment(true) },
                        onCancel() { createConfirmation() },
                        centered: true
                    })
                else if(urlParams.get("lifecycle").startsWith(JUVENILE)) 
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Advance batch lifecycle to MATURED?</p></Space>,
                        okText: "Yes",
                        cancelText: "No",
                        onOk() { createGrowthDevelopment(true) },
                        onCancel() { createConfirmation() },
                        centered: true
                    })
                else
                    createConfirmation()
            }
        })
    }*/

    //---------------------------------------
    // Create grwoth development confirmation
    //---------------------------------------
    const createConfirmation = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create growth development record confirmed?</p></Space>,
            onOk() { createGrowthDevelopment(false) },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        const search = `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&observedOn=${urlParams.get("observedOn")}`

        if(urlParams.has("fromMain"))
            navigate({ 
                //pathname: "/growthdevelopmentmain", 
                pathname: "/growthdevelopmenttable", 
                search: search        
            })
        else if(urlParams.has("fromDailyAverage"))
            navigate({ 
                pathname: MENUPATH_GROWTHDEV, 
                search: search        
            })
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setObservationDateTime(datetime)
    }

    const onMouthOpenedDateTimeChange = (datetime) => {
        setMouthOpenedDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateObservationDateTime = (() => {
        if(observationDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Observation date time is required."))
    })

    //----------------------------
    // Upload image file property.
    //----------------------------
    const props = {
        onRemove: file => {
            setImageFile([])
        },
        beforeUpload: file => {
            setImageFile([file])
            return false
        },
        maxCount: 1,
        imageFile,
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            observationDateTime: observationDateTime,
            mouth_width: 0,
            weight: 0,
            length: 0,
            remark: ""
        })

        //getFirstMounthOpenedDateTime()
        getBatchMarineLifeType()
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onBack()} 
                    title="Growth Development">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create growth development record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                            {
                                !urlParams.has("fishId") &&
                                <>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Average Weight (gram):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(urlParams.get("batchAverageWeight"))}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Average Length (mm):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(urlParams.get("batchAverageLength"))}</Card.Grid>        
                                </>
                            }
                            {   urlParams.has("fishId") &&
                                <>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Tagged Marine Life Average Weight (gram):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(urlParams.get("weight"))}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Tagged Marine Life Average Length (mm):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(urlParams.get("length"))}</Card.Grid>        
                                </>
                            }
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Age (days):</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{color: noAgeColor}}>{numberWithCommas(urlParams.get("ageDays")) != "null" ? urlParams.get("ageDays") : OTHERSYSPARAM("NA")}</Card.Grid>
                            {
                                urlParams.has("fishId") &&
                                <>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Tag ID:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("fish")}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Form.Item>

                    <Form.Item name="imageFile" label="Image File">
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                    </Form.Item> 

                    <Form.Item name="observationDateTime" label="Observation Date Time"
                        rules={[
                            { required: true, validator: validateObservationDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="mouthOpenedDateTime" label="Mouth Opened Date Time" hidden={hideField}>
                        <DatePicker showTime onChange={onMouthOpenedDateTimeChange} format={DATETIMEFORMAT}/>
                    </Form.Item>
                    
                    <Form.Item name="mouth_width" label="Mouth Width (mm)" hidden={hideField}
                        rules={[
                            { required: true, message: "Mouth width is required."},
                        ]}>
                        <InputNumber defaultValue={0} min={0} max={100} step={0.10} precision={2} placeholder="Mouth Width(mm)"/>
                    </Form.Item>
                    
                    <Form.Item name="weight" label="Weight (g)"
                        rules={[
                            { required: true, message: "Weight is required."},
                        ]}>
                        <InputNumber defaultValue={0} min={0} max={100000} step={10} precision={2} placeholder="Weight(g)"/>
                    </Form.Item>

                    <Form.Item name="length" label="Length (mm)" hidden={hideField}
                        rules={[
                            { required: true, message: "Length is required."},
                        ]}>
                        <InputNumber defaultValue={0} min={0} max={100000} step={1} precision={2} placeholder="Length(mm)"/>
                    </Form.Item>
                    
                    <Form.Item name="remark" label="Observation" >
                        <TextArea rows={3}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>} loading={uploading}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Growth Development:"
                    subTitle="Create growth development record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default GrowthDevelopmentNew