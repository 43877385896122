import { useState, useEffect } from 'react'
import { Form, Button, message, Row, Col, Select, Modal, Space, Pagination, Table, Popconfirm, Spin } from 'antd'
import { SaveOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons"
import { formLayout } from '../Common/Layout'
import axios from 'axios'
import { ACISAPIURL, LOADING, PAGESIZE, PAGINATIONSIZE } from '../Common/SystemParameter'
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { reportError } from '../Common/Utility'

const { Option } = Select
const { confirm } = Modal

const SensorStorageMapTable = () => {
    const [form] = Form.useForm()    
    const [disableButton, setDisableButton] = useState("")
    const [sensorId, setSensorId] = useState(0)
    const [storageId, setStorageId] = useState(0)

    const [sensorOption, setSensorOption] = useState([])
    const [storageOption, setStorageOption] = useState([])

    const [mappingDataSource, setMappingDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    //--------------------------
    // Delete mapping record
    //--------------------------
    const deleteMappingRecord = (e, record) => {
        setIsLoading(true)

        axios.delete(`${ACISAPIURL}hasensorstoragemap/delete/${record.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Mapping record deleted.`)
            setMappingDataSource(mappingDataSource.filter((item) => item.key !== record.key))
        })
        .catch( error => {
            reportError(error, "Failed to delete environment record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On save
    //--------
    const saveMapping = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.patch(`${ACISAPIURL}hasensorstoragemap/update/`, {
            sensor: sensorId,
            storage: storageId,
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            setCurrentPage(1)
            getSensorStorageMap(1)
            message.info(`Sensor storage mapped.`)
        })
        .catch( error => {
            reportError(error, "Failed to map storage to sensor.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------
    // Load sensor
    //-------------------
    const getSensor = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}hasensor/`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( sensor => <Option key={sensor.id}>{sensor.sensor_name}</Option>)
            setSensorOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get HA sensor list.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------
    // Load storage
    //-------------------
    const getStorage = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}hastorage/`, {
            params: {
                site: getUserSiteId(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( storage => <Option key={storage.id}>{storage.name}</Option>)
            setStorageOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get HA storage list.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------
    // Load mapping
    //-------------------
    const getSensorStorageMap = (currentPage) => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}hasensorstoragemap/`, {
            params: {
                site: getUserSiteId(),
                page: currentPage,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get HA sensor storage map.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( map => {
            data.push({
                key: map.id,
                sensor: map.sensor_data.sensor_name,
                storage: map.storage_data.name
            })
        })

        setMappingDataSource(data)
        
        // Total pages
        setTotalRecord(response.data.count)
    }

    //---------------
    // On map
    //---------------
    const onMap = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Sensor mapping confirmed?</p></Space>,
            onOk() { saveMapping() },
            onCancel() {},
            centered: true
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        getSensorStorageMap(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //--------------------
    // On selection changed
    //--------------------
    const onSensorChange = (e) => {
        setSensorId(e)
    }

    const onStorageChange = (e) => {
        setStorageId(e)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getSensor()
        getStorage()
        getSensorStorageMap(currentPage)
    }, [])

    const columns = [
        { title: 'Sensor ID', dataIndex: 'sensor', key: 'sensor', sorter: (a, b) => a.sensor.localeCompare(b.sensor) },
        { title: 'Storage ID', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { key: 'action', align: 'center',
            render: (record) => {
                return <Popconfirm title="Delete sensor-storage mapping confirmed?" 
                    onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => deleteMappingRecord(e, record)} 
                    okText="Yes" cancelText="No">
                    <Button danger type="primary" disabled={disableButton} icon={<DeleteOutlined/>} />
                </Popconfirm>
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form form={form} onFinish={onMap} {...formLayout}>
            <Form.Item name="sensorId" label="Sensor ID"
                rules={[
                    { required: true, message: "Sensor is required." },
                ]}>
                <Select onChange={onSensorChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {sensorOption}    
                </Select>
            </Form.Item>

            <Form.Item name="storageId" label="Storage ID"
                rules={[
                    { required: true, message: "Storage is required." },
                ]}>
                <Select onChange={onStorageChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {storageOption}    
                </Select>
            </Form.Item>
            
            <Row justify="center">
                <Col span={10}></Col>
                <Col span={4} style={{textAlign: "center"}}>
                    <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Save</Button>
                </Col>
                <Col span={10}></Col>
            </Row>
        </Form>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={12} style={{textAlign: "center"}}>
                <Table bordered columns={columns} dataSource={mappingDataSource} pagination={false}/>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default SensorStorageMapTable