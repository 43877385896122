import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL, MEDIAMAXWIDTH,  } from "./SystemParameter"
import { getUserAuthToken,  OTHERSYSPARAM,  refreshUserSession } from "./UserSession"
import { reportError } from '../Common/Utility'
import { useMediaQuery } from 'react-responsive'

const { Option } = Select

//----------
// Component
//----------
const StorageTypeSelect = ({withBlank, defaultValue, onChange}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const [storageTypeOption, setStorageTypeOption] = useState([])
            
    //---------
    // Load all
    //---------
    const getAllStorageType = () => {
        axios.get(`${ACISAPIURL}storagetype/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>)
            options = options.concat(response.data.results.map( storageType => <Option key={storageType.pKey}>{storageType.name}</Option>))
            setStorageTypeOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get storage types.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllStorageType()
    }, [])

    return(
        <Select defaultValue={defaultValue} style={{width: optionWidth}} onChange={onSelectionChange} allowClear showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {storageTypeOption}
        </Select>
    )
}

export default StorageTypeSelect