import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, message, Pagination, Spin } from 'antd'
import { DownloadOutlined, FileExcelOutlined} from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import FeedingTypeSelect from '../Feeding/FeedingTypeSelect'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { RangePicker } = DatePicker

//----------
// Component
//----------
const FeedingSummaryTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [feedingTypeId, setFeedingTypeId] = useState(0)
    const [feedingType, setFeedingType] = useState("")
        
    const [feedingSummaryDataSource, setFeedingSummaryDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    //-----------------------
    // Search egg development
    //-----------------------
    const searchFeedingSummary = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}feeding/summary/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                feeding_type: feedingTypeId,
                marine_life: marineLifeId,
                species: speciesId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search feeding summary record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( summary => {
            data.push({
                //key: transaction.pKey,
                batch: summary.batch_name,
                feedingType: summary.feeding_type_name,
                uom: summary.uom_name,
                totalQuantity: numberWithCommas((summary.total_quantity / 1000).toFixed(2)),
            })
        })
        
        setFeedingSummaryDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(feedingTypeId != 0) searchCriteria = `${searchCriteria}\nFeeding Type: ${feedingType}`
                                        
        axios.get(`${ACISAPIURL}feeding/summary/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                feeding_type: feedingTypeId,
                marine_life: marineLifeId,
                species: speciesId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Feeding Summary ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFeedingSummary(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchFeedingSummary(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchFeedintTypeChange = (e, value) => {
        setFeedingTypeId(e)
        setFeedingType(value.children)
    }

    const onSearchMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchFeedingSummary(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Feeding Type', dataIndex: 'feedingType', key: 'feedingType', sorter: (a, b) => a.feedingType.localeCompare(b.feedingType)},
        { title: 'UoM', dataIndex: 'uom', key: 'uom', sorter: (a, b) => a.uom.localeCompare(b.uom) },
        { title: 'Total Quantity (Kg or L).', dataIndex: 'totalQuantity', key: 'totalQuantity', align: 'right', sorter: (a, b) => stringNumberSorter(a.totalQuantity, b.totalQuantity) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header={`Search Feeding Summary`} key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onSearchMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                showLockedStorage={true} showActiveBatchSwitch={true} />

                            <Form.Item name="feedingTypeId" label="Feeding Type">
                                <FeedingTypeSelect withBlank={true} onChange={onSearchFeedintTypeChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={feedingSummaryDataSource} pagination={false}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default FeedingSummaryTable