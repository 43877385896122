import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, Card, DatePicker, Input, PageHeader, Modal, Descriptions, Spin } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MENUPATH_FEEDING, TAGGED, UNIDATETIMEFORMAT, UNTAGGED } from "../Common/SystemParameter"
import { reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, SYSPARAM, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import moment from 'moment'
import FeedingTypeSelect from './FeedingTypeSelect'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal

//----------
// Component
//----------
const FeedingNew = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [fedOn, setFedOn] = useState(moment(moment(), UNIDATETIMEFORMAT))
    const [feedingTypeId, setFeedingTypeId] = useState(0)
    const [feedingUom, setFeedingUom]  = useState("")
        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------
    // Create feeding
    //---------------
    const createFeeding = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}feeding/create/`, {
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                feeding_type: feedingTypeId,
                feeding_quantity: values.feedingQuantity,
                feeding_uom: (feedingTypeId != SYSPARAM("Artemia") && feedingTypeId != SYSPARAM("Rotifer")) ? SYSPARAM("g") : SYSPARAM("mL"),
                fish_quantity: values.fishQuantity,
                fedOn: fedOn,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Feeding record saved.")
                navigate({ 
                    pathname: MENUPATH_FEEDING
                })
            })
            .catch( error => {
                reportError(error, `Failed to save feeding record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //----------------------------------
    // Retrieve fish quantity in storage
    //----------------------------------
    const retrieveFishQuantity = () => {
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storage/${urlParams.get("storageId")}/${urlParams.get("batchId")}/`, {
            params: {
                batch: urlParams.get("batchId")
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const storageStockSummary = response.data.storagestocksummary

            let fishQuantity = 0
            storageStockSummary.filter( summary => (summary.item_type_name.startsWith(TAGGED) || summary.item_type_name.startsWith(UNTAGGED)) && summary.quantity > 0).forEach( summary => {
                fishQuantity += summary.quantity
            })

            form.setFieldsValue({
                fishQuantity : fishQuantity,
            })
        })
        .catch( error => {
            reportError(error, "Failed to retrieve storage fish quantity.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = (e) => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Save feeding record confirmed?</p></Space>,
            onOk() { createFeeding() },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: "/feedingstorage"
        })
    }

    //---------------------
    // On date time change
    //---------------------
    const onFeedingDateTimeChange = (datetime) => {
        setFedOn(datetime)
    }

    //-----------------------
    // On feeding type change
    //-----------------------
    const onFeedingTypeChange = (e) => {
        setFeedingTypeId(e)
        
        switch(parseInt(e)) {
            case SYSPARAM("Artemia"):
            case SYSPARAM("Rotifer"):
                setFeedingUom("(mL)")
                break
            default:
                setFeedingUom("(g)")
        }
    }

    //------------------
    // Custom validation
    //------------------
    const validateFeedingDateTime = (() => {
        if(fedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Feeding date time is required."))
    })

    const validateFeedingType = (() => {
        if(feedingTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Feeding type is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        retrieveFishQuantity()

        form.setFieldsValue({
            fedOn: fedOn
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onBack()} 
                    title="Feeding">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create feeding record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>


                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                        </Card>
                    </Form.Item>
                    
                    <Form.Item name="feedingType" label="Feeding Type"
                        rules={[
                            { required: true, validator: validateFeedingType },
                        ]}>
                        <FeedingTypeSelect withBlank={false} onChange={onFeedingTypeChange}/>
                    </Form.Item>

                    <Form.Item name="fedOn" label="Feeding Date Time"
                        rules={[
                            //{ required: true, validator: validateFeedingDateTime },
                            { required: true, message: "Feeding date time is required."},
                        ]}>
                        <DatePicker showTime onChange={onFeedingDateTimeChange} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="feedingQuantity" label={`Feeding Quantity${feedingUom}`}
                        rules={[
                            { required: true, message: "Feeding quantity is required."},
                        ]}>
                        <InputNumber min={1} max={10000} step={1} placeholder="Feeding Quantity"/>
                    </Form.Item>

                    <Form.Item label={`Marine Life Quantity(${urlParams.get("uom")})`}>
                        <Input.Group>
                            <Form.Item name="fishQuantity" noStyle
                                rules={[
                                    { required: true, message: "Marine life quantity is required."},
                                ]}>
                                <InputNumber min={1} max={10000000} step={1} placeholder="Marine Life Quantity" readOnly/>
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                                        
                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Feeding:"
                    subTitle="Create feeding record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default FeedingNew