import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, Modal, DatePicker, Input, Card, PageHeader, Descriptions, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MENUPATH_HATCHING, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { getBatchFullAquacultureStageLifecycle, reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import moment from 'moment'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal
const { TextArea } = Input

moment.updateLocale('en', { invalidDate : "" })

//----------
// Component
//----------
const HatchingUpdate = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [hatchedOn, setHatchedOn] = useState(moment(moment(urlParams.get("hatchedOn")), UNIDATETIMEFORMAT))
    const [observedOn, setObservedOn] = useState(moment(moment(urlParams.get("observedOn")), UNIDATETIMEFORMAT))
    const [broodCount, setBroodCount] = useState(0)
    const [hideButton, setHideButton] = useState("")

    //----------------
    // Update hatching
    //----------------
    const updateHatching = (advanceToNursery) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            let totalQty = parseInt(urlParams.get("availableEggQuantityML")) + parseInt(urlParams.get("eggQuantityML"))

            axios.patch(`${ACISAPIURL}hatching/update/${urlParams.get("pKey")}/`, {
                hours_from_spawning: values.hoursFromSpawning,
                egg_quantity_ml: Math.ceil(values.percentage / 100 * totalQty),
                percentage: values.percentage,
                storage_total_quantity_ml: totalQty,
                remark: values.remark,
                hatchedOn: hatchedOn,
                observedOn: observedOn,
                advance_to_nursery: advanceToNursery
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Hatching record updated.")
                navigate({ 
                    pathname: MENUPATH_HATCHING
                })
            })
            .catch( error => {
                reportError(error, `Failed to update hatching record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //-----------------------------
    // Calculate hours from spawning
    //-----------------------------
    const calculateHoursFromSpawning = () => {
        if(hatchedOn == null) {
            message.warning("Please enter hatching date time before proceed.")
            return
        }

        setIsLoading(true)

        axios.get(`${ACISAPIURL}hatching/calculatehoursfromspawning/`, {
            params: { 
                hatching_date_time: hatchedOn,
                batch: urlParams.get("batchId")
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const duration = response.data.duration
            form.setFieldsValue({
                hoursFromSpawning : duration.hours,
            })
        })
        .catch( error => {
            reportError(error, "Failed to calculate hours from spawning.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //----------------------------------------------------------
    // Retrieve storage protection triggered record count.
    // If count > 0, disable update and delete.
    //----------------------------------------------------------
    const getProtectionTriggeredRecordCount = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}hatching/protectiontriggeredrecordcount/`, {
            params: { 
                pKey: urlParams.get("pKey")
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(parseInt(response.data.record_found) > 0) {
                setHideButton("none")
                message.info("Protection triggered record found. Update and delete not allowed.")
            }
        })
        .catch( error => {
            reportError(error, "Failed to retrieve protection triggered record count.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------------------
    // Delete hatching record
    //-----------------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}hatching/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Hatching record deleted.`)
            navigate({ pathname: MENUPATH_HATCHING })
        })
        .catch( error => {
            reportError(error, "Failed to delete hatching record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------------------------
    // Get brood number in storage
    //-----------------------------
    const getBroodCount = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}fish/broodcount/`, {
            params: { 
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            setBroodCount(response.data.brood_count)
        })
        .catch( error => {
            reportError(error, "Failed to get brood count.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = async (e) => {
        const fullAquacultureStageLifecycle = await getBatchFullAquacultureStageLifecycle(urlParams.get("batchId"))

        // If batch is the SECOND in aquaculture stage and lifecycle.
        let currentAquacultureStageLifecycleIndex = -1
        if(fullAquacultureStageLifecycle[1].aquaculture_stage == urlParams.get("batchAquacultureStageId") && fullAquacultureStageLifecycle[1].lifecycle == urlParams.get("batchLifecycleId")) 
            currentAquacultureStageLifecycleIndex = 1
        
        const nextAquacultureStageLifecycleIndex = currentAquacultureStageLifecycleIndex + 1
        let nextAquacultureStage = null
        if(currentAquacultureStageLifecycleIndex == 1)
            nextAquacultureStage = fullAquacultureStageLifecycle[nextAquacultureStageLifecycleIndex]

        // Get batch next aquaculture stage.
        form.validateFields()
        .then( values => {
            // In percent
            if(values.quantity > 100) {
                message.warn("Hatched egg quantity exceeded 100%.")
                return
            }

            //if(urlParams.get("batchAquacultureStageId") <= SYSPARAM("Incubation"))
            //if(urlParams.get("batchAquacultureStage").startsWith(SPAWNING) || urlParams.get("batchAquacultureStage").startsWith(INCUBATION)) {
            if(nextAquacultureStage != null) 
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch aquaculture stage to {nextAquacultureStage.aquaculture_stage_data.name} and lifecycle of {nextAquacultureStage.lifecycle_data.name}?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { updateHatching(true) },
                    onCancel() { updateHatching(false) },
                    centered: true
                })
            else
                updateHatching(false)    
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_HATCHING
        })
    }

    //---------------------
    // On date time change
    //---------------------
    const onHatchingDateTimeChange = (datetime) => {
        setHatchedOn(datetime)
    }

    //---------------------
    // On date time change
    //---------------------
    const onObservationDateTimeChange = (datetime) => {
        setObservedOn(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateHatchingDateTime = (() => {
        if(hatchedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Hatching date time is required."))
    })

    const validateObservationDateTime = (() => {
        if(observedOn != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Observation date time is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getBroodCount()

        form.setFieldsValue({
            percentage: urlParams.get("percentage"),
            hoursFromSpawning: urlParams.get("hoursFromSpawning"),
            hatchedOn: hatchedOn,
            observedOn: observedOn,
            remark: urlParams.get("remark") != "undefined" ? urlParams.get("remark") : ""
        })

        getProtectionTriggeredRecordCount()
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Hatching">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update hatching record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Hatching ID: ${urlParams.get("id")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("storage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Brood Count:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{broodCount}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
                     
                            { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Form.Item>

                    <Form.Item name="hatchedOn" label="Hatching Date Time"
                        rules={[
                            { required: true, validator: validateHatchingDateTime },
                        ]}>
                        <DatePicker showTime onChange={onHatchingDateTimeChange} defaultValue={hatchedOn} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item label="Hours From First Spawning">
                        <Input.Group>
                            <Form.Item name="hoursFromSpawning" noStyle
                                rules={[
                                    { required: true, message: "Hours from first spawning is required."},
                                ]}>
                                <InputNumber min={0} max={10000} step={1} placeholder="Hours From Spawning"/>
                            </Form.Item>
                            <Button onClick={calculateHoursFromSpawning}>Calculate</Button>
                        </Input.Group>
                    </Form.Item>
                    
                    <Form.Item label={`Note: Hatching quantity was ${urlParams.get("percentage")}% of ${urlParams.get("storageTotalQuantityML")} (mL)`}>
                    </Form.Item>

                    <Form.Item label={`Update Hatched Egg Quantity To`}>
                        <Input.Group>
                            <Form.Item name="percentage" noStyle
                                rules={[
                                    { required: true, message: "Hatched egg percentage is required."},
                                ]}>
                                <InputNumber min={1} max={100} step={1} placeholder="Egg Quantity"/>
                            </Form.Item>
                            <span> % of remaining {parseInt(urlParams.get("availableEggQuantityML")) + parseInt(urlParams.get("eggQuantityML"))} (mL)</span>
                        </Input.Group>
                    </Form.Item>
                    
                    <Form.Item name="observedOn" label="Observation Date Time"
                        rules={[
                            { required: true, validator: validateObservationDateTime },
                        ]}>
                        <DatePicker showTime onChange={onObservationDateTimeChange} defaultValue={observedOn} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="remark" label="Observation" >
                        <TextArea rows={3}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} style={{display: hideButton}} icon={<SaveOutlined/>}>Update</Button>
                            <Popconfirm title="Delete hatching record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} style={{display: hideButton}} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" disabled={disableButton} onClick={onBack} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Hatching:"
                    subTitle="Update hatching record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default HatchingUpdate