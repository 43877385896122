import { Table, Row, Col, Space, Button, Form, DatePicker, Collapse, message, Tag, Pagination, Badge, Avatar, Modal, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, InboxOutlined, InfoCircleOutlined, FileExcelOutlined } from "@ant-design/icons"
import { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName, SYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import BatchStatusSelect from "../Common/BatchStatusSelect"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import BatchTypeSelect from '../Common/BatchTypeSelect'
import AcquiringMethodSelect from '../Common/AcquiringMethodSelect'
import TrueFalseSelect from '../Common/TrueFalseSelect'
import WeightRangeInput from '../Common/WeightRangeInput'
import { showBatchStorageStockSummary } from '../Common/showBatchStorageStockSummary'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'
import AquacultureStagLifecycleSteps from './AquacultureStageLifecycleSteps'
//import { showAquacultureStagLifecycleSteps } from './showAquacultureStageLifecycleSteps'

const { RangePicker } = DatePicker
const { Panel } = Collapse
const { confirm } = Modal

//----------
// Component
//----------
const BatchTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [dateRange, setDateRange] = useState([moment(moment().subtract(parseInt(OTHERSYSPARAM("BACK_DATE_MONTH")) * 40, "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [weightRange, setWeightRange] = useState([-1, -1]) // Cannot use [0, 0] because 0 is valid input.
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [lifecycleId, setLifecycleId] = useState(0)
    const [lifecycle, setLifecycle] = useState("")
    const [acquiringMethodId, setAcquiringMethodId] = useState(0)
    const [acquiringMethod, setAcquiringMethod] = useState("")
    const [aquacultureStageId, setAquacultureStageId] = useState(0)
    const [aquacultureStage, setAquacultureStage] = useState("")
    const [batchStatusId, setBatchStatusId] = useState(0)
    const [batchStatus, setBatchStatus] = useState("")
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [batchTypeId, setBatchTypeId] = useState(0)
    const [batchType, setBatchType] = useState()
    const [isActive, setIsActive] = useState(true)
    const [isActiveLabel, setIsActiveLabel] = useState(OTHERSYSPARAM("YES"))
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
        
    const [batchDataSource, setBatchDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------
    // Search batch
    //---------------
    const searchBatch = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)

        axios.get(`${ACISAPIURL}batch/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                id: batchId,
                storage: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : storageId,
                marine_life: marineLifeId,
                species: speciesId,
                acquiring_method: acquiringMethodId,
                lifecycle: lifecycleId,
                aquaculture_stage: aquacultureStageId,
                status: batchStatusId,
                batch_type: batchTypeId,
                from_weight: weightRange[0],
                to_weight: weightRange[1],
                active: isActive,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            pushData(response)
        })
        .catch( error => {
            reportError(error, "Failed to search batch list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const pushData = (response) => {
        const data = []
        response.data.results.forEach( batch => {
            // let averageWeight = ""
            // if(batch.average_per_unit_weight_gram > 0) averageWeight = `Untagged: ${numberWithCommas(batch.average_per_unit_weight_gram)}`
            // if(batch.tagged_average_weight_data > 0) {
            //     if(averageWeight != "") averageWeight = `${averageWeight}, `
            //     averageWeight = `${averageWeight}Tagged: ${numberWithCommas(batch.tagged_average_weight_data)}`
            // }
            
            data.push({
                key: batch.pKey,
                id: batch.id,
                batchTypeId: batch.batch_type_data.pKey,
                batchType: batch.batch_type_data.name,
                marineLifeId: batch.species_data.marine_life_data.pKey,
                speciesId: batch.species_data.pKey,
                species: batch.species_data.short_name,
                acquiringMethodId: batch.acquiring_method_data.pKey,
                acquiringMethod: batch.acquiring_method_data.name,
                aquacultureStageId: batch.aquaculture_stage_data.pKey,
                aquacultureStage: batch.aquaculture_stage_data.name,
                lifecycleId: batch.lifecycle_data.pKey,
                lifecycle: batch.lifecycle_data.name,
                statusId: batch.status_data.pKey,
                status: batch.status_data.name,
                active: batch.active,
                
                numberOfStorageAssigned: batch?.storage_assigned != "" ? batch.storage.length : 0,

                batchAverageWeight: batch.average_per_unit_weight_gram,
                batchAverageLength: batch.average_per_unit_length_mm,
                ageDays: batch.age_days,
                
                averageWeight: batch.batch_average_weight_data, //averageWeight,
                storageList: batch.storage,
                batchTotalQty: numberWithCommas(batch.batch_total_stock_quantity)
            })
        })
        
        setBatchDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(acquiringMethodId != 0) searchCriteria = `${searchCriteria}\nAcquiring Method: ${acquiringMethod}`
        if(aquacultureStageId != 0) searchCriteria = `${searchCriteria}\nAquaculture Stage: ${aquacultureStage}`
        if(lifecycleId != 0) searchCriteria = `${searchCriteria}\nLifecycle: ${lifecycle}`
        if(batchTypeId != 0) searchCriteria = `${searchCriteria}\nBatch Type: ${batchType}`
        if(batchStatusId != 0) searchCriteria = `${searchCriteria}\nBatch Status: ${batchStatus}`
        if(isActive != "") searchCriteria = `${searchCriteria}\nIs Active: ${isActiveLabel}`
        if(weightRange[0] >= 0) searchCriteria = `${searchCriteria}\nWeight >= ${numberWithCommas(weightRange[0])}g`
        if(weightRange[1] >=0) searchCriteria = `${searchCriteria}\nWeight <= ${numberWithCommas(weightRange[1])}g`
                
        axios.get(`${ACISAPIURL}batch/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                id: batchId,
                storage: storageId,
                marine_life: marineLifeId,
                species: speciesId,
                lifecycle: lifecycleId,
                aquaculture_stage: aquacultureStageId,
                acquiring_method: acquiringMethodId,
                status: batchStatusId,
                batch_type: batchTypeId,
                from_weight: weightRange[0],
                to_weight: weightRange[1],
                active: isActive,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Batch ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //----------------------------
    // Show storage stock quantity
    //----------------------------
    const showAquacultureStageLifecyclePopup = (e, record) => {
        e.stopPropagation()
        
        const modal = Modal.info({
            title: <div><h3>Batch ID: {record.id}</h3></div>,
            icon: null,
            //width: 700,
            centered: true,
            onOk() {},
            okText: "Close",
            cancelButtonProps: {style: {display: "none"}},
            content: <AquacultureStagLifecycleSteps batchPKey={record.key} />
        })
    }

    //----------------------------
    // Redirect callback function
    //----------------------------
    const redirect = (pathname, search) => {
        navigate({
            pathname: pathname,
            search: search
        })
    }

    //----------------------------
    // Show storage stock quantity
    //----------------------------
    const showBatchStoragePopup = (e, record) => {
        e.stopPropagation()
        showBatchStorageStockSummary(record, redirect)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchBatch(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchBatch(1)
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/batchnew" })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/batchupdate", 
                    search: `?pKey=${record.key}&batch=${record.id}&acquiringMethodId=${record.acquiringMethodId}&acquiringMethod=${record.acquiringMethod}&aquacultureStageId=${record.aquacultureStageId}&aquacultureStage=${record.aquacultureStage}&lifecycleId=${record.lifecycleId}&lifecycle=${record.lifecycle}&statusId=${record.statusId}&status=${record.status}&batchTypeId=${record.batchTypeId}&batchType=${record.batchType}&speciesId=${record.speciesId}`
                }) 
            }
        }
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onBatchTypeChange = (e, value) => {
        setBatchTypeId(e)
        setBatchType(value)
    }

    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onSearchAcquiringMethodChange = (e, value) => {
        setAcquiringMethodId(e)
        setAcquiringMethod(value.children)
    }

    const onSearchAquacultureStageChange = (e, value) => {
        setAquacultureStageId(e)
        setAquacultureStage(value.children)
    }

    const onSearchLifecycleChange = (e, value) => {
        setLifecycleId(e)
        setLifecycle(value.children)
    }

    const onSearchBatchStatusChange = (e, value) => {
        setBatchStatusId(e)
        setBatchStatus(value.children)
    }

    const onSearchIsActiveChange = (e, value) => {
        setIsActive(e)
        setIsActiveLabel(value.children)
    }
    
    const onWeightRangeChange = (fromWeight, toWeight) => {
        setWeightRange([fromWeight, toWeight])
    }
    
    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchBatch(currentPage, urlParams.get("storageId"))
        }
        else
            searchBatch(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        { title: 'Acq. Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) },
        { title: 'Aqua. Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle), 
            /*render: (lifecycle, record) => {
                return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => showAquacultureStageLifecyclePopup(e, record)}>
                    {lifecycle}
                </Button>
            } */
            render: (lifecycle, record) => {

                return <div>{lifecycle}<InfoCircleOutlined onClick={(e) => showAquacultureStageLifecyclePopup(e, record)} style={{color: "blue", width: "50px"}}/></div>
            }
        },
        { title: 'Status', dataIndex: 'status', key: 'status', sorter: (a, b) => a.status.localeCompare(b.status) },
        { title: 'Active', dataIndex: 'active', key: 'active', sorter: (a, b) => a.active - b.active,
            render: (active) => {
                if(active)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        { title: 'Total Qty.', dataIndex: 'batchTotalQty', key: 'batchTotalQty', align: 'right', sorter: (a, b) => a.batchTotalQty.localeCompare(b.batchTotalQty) },        
        { title: 'Avg / Unit Weight (g)', dataIndex: 'averageWeight', key: 'averageWeight', sorter: (a, b) => stringNumberSorter(a.averageWeight, b.averageWeight) },        
        { title: 'Storage', dataIndex: 'storage', key: 'storage', align: 'center', //sorter: (a, b) => a.storage.localeCompare(b.storage),
            render: (storage, record) => {
                if(record.numberOfStorageAssigned > 0)
                    // return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => showBatchStoragePopup(e, record)}>
                    //     {record.numberOfStorageAssigned}
                    // </Button>
                    return <Badge count={record.numberOfStorageAssigned} onClick={(e) => showBatchStoragePopup(e, record)}>
                        <Avatar shape="square" size="medium" style={{backgroundColor: "#1890ff", cursor: "pointer", borderRadius: "6px"}} icon={<InboxOutlined />}/>
                        
                    </Badge>
                else
                    return OTHERSYSPARAM("NONE")
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Batch" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={onSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} onAquacultureStageChange={onSearchAquacultureStageChange}
                                onLifecycleChange={onSearchLifecycleChange} showLockedStorage={false} defaultStorageId={storageId} showActiveBatchSwitch={true}/>

                            <Form.Item name="acquiringMethodId" label="Acquiring Method">
                                <AcquiringMethodSelect withBlank={true} onChange={onSearchAcquiringMethodChange}/>
                            </Form.Item>

                            <Form.Item name="batchTypeId" label="Batch Type">
                                <BatchTypeSelect withBlank={true} onChange={onBatchTypeChange}/>
                            </Form.Item>

                            <Form.Item name="status" label="Batch Status">
                                <BatchStatusSelect withBlank={true} onChange={onSearchBatchStatusChange}/>
                            </Form.Item>

                            <Form.Item name="weightRange" label="Avg. Weight Range">
                                <WeightRangeInput onChange={onWeightRangeChange}/>
                            </Form.Item>

                            <Form.Item name="isActive" label="Is Active">
                                <TrueFalseSelect withBlank={true} defaultValue={isActiveLabel} onChange={onSearchIsActiveChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form> 
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={batchDataSource} pagination={false} onRow={onRowClick} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default BatchTable
