import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, message, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, Card, PageHeader, Modal, Descriptions, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { LOADING, MENUPATH_STORAGE } from '../Common/SystemParameter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { ACISAPIURL } from '../Common/SystemParameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import StorageTypeSelect from "../Common/StorageTypeSelect"
import StorageUsageTypeSelect from "../Common/StorageUsageTypeSelect"

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal

//----------
// Component
//----------
const StorageUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
        
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [hideDeleteButton, setHideDeleteButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
                        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [storageTypeId, setStorageTypeId] = useState(urlParams.get("storageTypeId"))
    const [storageUsageTypeId, setStorageUsageTypeId] = useState(urlParams.get("storageUsageTypeId"))

    //---------------
    // Update storage
    //---------------
    const updateStorage = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}storage/update/${urlParams.get("pKey")}/`, {
                storage_type: storageTypeId,
                storage_usage_type: storageUsageTypeId,
                id: values.storage,
                name: values.storageName,
                capacity: values.capacity,
                dimension: values.dimension
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Storage ${values.storageName} updated.`)
                navigate({ pathname: MENUPATH_STORAGE })
            })
            .catch( error => {
                reportError(error, "Failed to update storage.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //---------------
    // Delete storage
    //---------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}storage/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Storage ${urlParams.get("storage")} deleted.`)
            navigate({ pathname: MENUPATH_STORAGE })
        })
        .catch( error => {
            reportError(error, "Failed to delete storage.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------------------------------------
    // Determine if storage has transaction and batch assigned
    //--------------------------------------------------------
    const toHideDeleteButton = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}storagehastransaction/`, {
            params: {
                storage: urlParams.get("pKey"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.has_stock_transaction || urlParams.get("batch") != "")
                setHideDeleteButton(true)
        })
        .catch( error => {
            reportError(error, "Failed to determine if storage has transaction.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }
    

    //--------------------
    // Cancel update storage
    //--------------------
    const onCancel = () => {
        navigate({ pathname: MENUPATH_STORAGE })
    }

    //--------------------
    // On update
    //--------------------
    const onUpdate = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update storage record confirmed?</p></Space>,
            onOk() { updateStorage() },
            onCancel() {},
            centered: true
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        toHideDeleteButton()

        form.setFieldsValue({
            storageType: urlParams.get("storageType"),
            storageUsageType: urlParams.get("storageUsageType"),
            storageName: urlParams.get("storageName"),
            dimension: urlParams.get("dimension"),
            capacity: urlParams.get("capacity"),
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Storage">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update storage record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Storage ID: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch") != "" ? urlParams.get("batch") : OTHERSYSPARAM("NA")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Locked:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("locked")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
                     
                            { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Form.Item>

                    <Form.Item name="storageType" label="Storage Type"
                        rules={[
                            { required: true, message: "Storage type is required."},
                        ]}>
                        <StorageTypeSelect withBlank={false} defaultValue={urlParams.get("storageType")} onChange={setStorageTypeId}/>
                    </Form.Item>

                    <Form.Item name="storageUsageType" label="Storage Usage Type"
                        rules={[
                            { required: true, message: "Storage usage type is required."},
                        ]}>
                        <StorageUsageTypeSelect withBlank={false} defaultValue={urlParams.get("storageUsageType")} onChange={setStorageUsageTypeId}/>
                    </Form.Item>

                    <Form.Item name="storageName" label="Storage Name"
                        rules={[
                            { required: true, message: "Storage name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Storage Name"/>
                    </Form.Item>

                    <Form.Item name="dimension" label="Dimension"
                        rules={[
                            { required: true, message: "Dimension is required."},
                        ]}>
                        <Input maxLength={20} placeholder="Dimension"/>
                    </Form.Item>

                    <Form.Item name="capacity" label="Capacity (L):"
                        rules={[
                            { required: true, message: "Storage capacity is required."},
                        ]}>
                        <InputNumber min={1} max={50000} step={100} placeholder="Capacity"/>
                    </Form.Item>
                
                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete storage confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} hidden={hideDeleteButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} disabled={disableButton} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Storage:"
                        subTitle="Update storage record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default StorageUpdate