import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL } from './SystemParameter'
import { reportError } from './Utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./UserSession"

const { Option } = Select

//----------
// Component
//----------
const SpawningStageSelect = ({withBlank, defaultValue, onChange}) => {
    const [spawningStatusOption, setSpawningStatusOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
        
    //---------
    // Load all
    //---------
    const getAllSpawningStatus = () => {
        axios.get(`${ACISAPIURL}spawningstatus/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( status => <Option key={status.pKey}>{status.name}</Option> ))
            setSpawningStatusOption(options)
        })
        .catch( error => {
            reportError(error, "<SpawningStatusSelect /> Failed to get spawning status.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllSpawningStatus()
    }, []) 
    
    return(
        <Select defaultValue={defaultValue} style={{width: optionWidth}} onChange={(e, value) => onSelectionChange(e, value)}
            allowClear showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {spawningStatusOption}
        </Select>
    )
}

export default SpawningStageSelect