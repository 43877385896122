import { useEffect, useState } from 'react'
import { Row, Col, Button, message, Form, Collapse, Space, InputNumber, Spin } from 'antd'
import { FileExcelOutlined } from "@ant-design/icons"
import { getUserAuthToken, getUserSiteId, getUserSiteName, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { formLayout_2Columns } from '../Common/Layout'
import { ACISAPIURL, APPJSON, LOADING, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import axios from 'axios'
import { reportError } from '../Common/Utility'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import { MEDIAMAXWIDTH } from '../Common/SystemParameter'


const { Panel } = Collapse

//----------
// Component
//----------
const InventorySummaryTableV2 = () => {
    const FileDownload = require('js-file-download')
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [width, setWidth] = useState(isTabletOrMobile ? 160 : 280)

    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    
    //--------------------------
    // Download summary
    //--------------------------
    const downloadSummary = () => {
        setDisableButton("disabled")
        setIsLoading(true)

        form.validateFields()
        .then( values => {
            axios.get(`${ACISAPIURL}inventory/summary/download2/`, {
                params: { 
                    site: getUserSiteId(), 
                    site_name: getUserSiteName(),

                    below_1kg: values._1KgAndBelow,
                    _1Kg_to_2Kg: values._1KgTo2Kg,
                    above_2Kg: values._2KgAndAbove,

                    batch: 0,
                    storage_types: '',
                    storage: 0,
                    marine_life: 0,
                    species: 0,
                    search_criteria: '',
                    from_weight: -1,
                    to_weight: -1,
                    mode: ''
                },
                responseType: 'blob', // Important
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                if(response?.data?.type == APPJSON)
                    message.info("No report found.")
                else {
                    const now = moment().format(UNIDATETIMEFORMAT)
                    FileDownload(response.data, `Inventory Summary Report ${now}.xlsx`)
                }
            })
            .catch( error => {
                reportError(error, "Failed to download report pdf.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            _1KgAndBelow: 100,
            _1KgTo2Kg: 200,
            _2KgAndAbove: 1000
        })
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Inventory Summary Weight Grouping" key="1">
                        <Form form={form} onFinish={downloadSummary} {...formLayout_2Columns}>
                            <Form.Item name="_1KgAndBelow" label="1.00 Kg And Below"
                                rules={[
                                    { required: true, message: "Grouping weight is required."},
                                ]}>
                                <Space>Every<InputNumber defaultValue="100" min={1} max={1000} step={10} precision={0} placeholder="Below 1Kg Weight Group (g)" style={{width: width}} />g</Space>
                            </Form.Item>

                            <Form.Item name="_1KgTo2Kg" label="1.01 Kg to 2.00 Kg"
                                rules={[
                                    { required: true, message: "Grouping weight is required."},
                                ]}>
                                <Space>Every<InputNumber defaultValue="200" min={1} max={1000} step={100} precision={0} placeholder="1Kg to 2 Kg Weight Group (g)" style={{width: width}} />g</Space>
                            </Form.Item>

                            <Form.Item name="_2KgAndAbove" label="2.01 Kg and Above"
                                rules={[
                                    { required: true, message: "Grouping weight is required."},
                                ]}>
                                <Space>Every<InputNumber defaultValue="1000" min={1} max={5000} step={1000} precision={0} placeholder="Above 2 Kg Weight Group (g)" style={{width: width}} />g</Space>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={24} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="submit" disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        </Spin>
        </>
    )
}

export default InventorySummaryTableV2