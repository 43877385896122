import { useEffect } from 'react'
import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import InventoryForecastBatchStorageTable from './InventoryForecastBatchStorageTable'
import { MENUPATH_INVENTORYFORECAST } from '../Common/SystemParameter'
import { useNavigate } from 'react-router-dom'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const InventoryForecastBatchStorageMain = () => {
    const navigate = useNavigate()
    
    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({
            pathname: MENUPATH_INVENTORYFORECAST
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                {<ACISHeader />}
            </Header>

            <Content >

                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={() => onBack()} 
                    title="Inventory Forecast Batch & Storage">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">View forecasted data by batch & storage</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Row justify="center">
                    <Col span={1}></Col>
                        {<Col span={22}><InventoryForecastBatchStorageTable /></Col>}
                    <Col span={1}></Col>
                </Row>
            </Content>

            <Footer>
                {<ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={() => onBack()} 
                        title="Inventory Forecast Batch & Storage:"
                        subTitle="View forecasted data by batch & storage"/>} />}
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default InventoryForecastBatchStorageMain