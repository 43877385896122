import { Table, Row, Col, Space, Button, Form, Collapse, Popconfirm, Typography, Card, Pagination, Spin } from 'antd'
import { LeftOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, LOADING, MEDIAMAXWIDTH, MENUPATH_FEEDING, PAGESIZE, PAGINATIONSIZE, TAGGED, UNTAGGED } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout, formLayout_2Columns } from "../Common/Layout"
import BatchTypeSelect from '../Common/BatchTypeSelect'
import { useMediaQuery } from 'react-responsive'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { Title } = Typography

//----------
// Component
//----------
const FeedingStorageTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()
    const [record, setRecord] = useState(null)
    const [quantity, setQuantity] = useState(null)
    const navigate = useNavigate()

    const [searchStorageId, setSearchStorageId] = useState(0)
    const [searchBatchId, setSearchBatchId] = useState(0)
    const [searchBatchTypeId, setSearchBatchTypeId] = useState(0)
    const [searchAquacultureStageId, setSearchAquacultureStageId] = useState(0)
    const [searchLifecycleId, setSearchLifecycleId] = useState(0)

    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    if(quantity == null && urlParams.has("quantity") && urlParams.get("quantity") != 0) {
        setQuantity(urlParams.get("quantity"))
        form.setFieldsValue({
            quantity: urlParams.get("quantity")
        })
    }

    //---------------
    // Search storage
    //---------------
    const searchStorage = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storagepaginatedbyitemtype/`, {
            params: { 
                site: getUserSiteId(),
                item_types_like: `${TAGGED}, ${UNTAGGED}`,
                empty: false,
                batch: searchBatchId,
                id: searchStorageId,
                aquaculture_stage: searchAquacultureStageId,
                lifecycle: searchLifecycleId,
                batch_type: searchBatchTypeId, 
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    const dataPush = (response) => {
        let data = []
        response.data.results.forEach( storage => {
            data.push({
                key: storage.id,
                id: storage.storage_name,
                storageId: storage.storage,
                storageType: storage.storage_type_data.name,
                batchId: storage.batch_data.pKey,
                batch: storage.batch_data.id,
                batchType: storage.batch_data.batch_type_data.name,
                batchTypeId: storage.batch_data.batch_type_data.pKey,
                acquiringMethod: storage.batch_data.acquiring_method_data.name,
                acquiringMethodId: storage.batch_data.acquiring_method_data.pKey,
                aquacultureStage: storage.batch_data.aquaculture_stage_data.name,
                aquacultureStageId: storage.batch_data.aquaculture_stage_data.pKey,
                lifecycle: storage.batch_data.lifecycle_data.name,
                lifecycleId: storage.batch_data.lifecycle_data.pKey,
                speciesId: storage.batch_data.species_data.pKey,
                species: storage.batch_data.species_data.short_name,
                itemTypeId: storage.item_type,
                itemTypeLabel: `${storage.item_type_name} (${storage.uom_name})`,
                itemType: storage.item_type_name,
                uomId: storage.uom,
                uom: storage.uom_name,
                quantity: numberWithCommas(storage.quantity),
            })
        })

        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStorage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStorage(1)
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                setRecord(record)
                form.submit()
            }
        }
    }

    //---------------------
    // On batch type change
    //---------------------
    const onBatchTypeChange = (e, value) => {
        setSearchBatchTypeId(e)
    }

    //------------------
    // Go to create page
    //------------------
    const onNext = () => {
        navigate({ 
            pathname: "/feedingnew", 
            search: `?batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&species=${record.species}&storageId=${record.storageId}&storage=${record.id}&acquiringMethod=${record.acquiringMethod}&aquacultureStage=${record.aquacultureStage}&lifecycle=${record.lifecycle}&uom=${record.uom}`
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_FEEDING
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchStorage(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        !isTabletOrMobile ? { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) } : {},
        !isTabletOrMobile ? { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) } : {},
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Item Type', dataIndex: 'itemTypeLabel', key: 'itemTypeLabel', sorter: (a, b) => a.itemTypeLabel.localeCompare(b.itemTypeLabel) },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', align: "right", sorter: (a, b) => stringNumberSorter(a.quantity, b.quantity) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>Requirement</Title>}>
                    Storage must have tagged or untagged item.
                </Card>
            </Form.Item>
        </Form>

        <Form form={form} onFinish={onNext} {...formLayout}>
            <Row>
                <Col span={24}>
                    <Collapse defaultActiveKey={["0"]}>
                        <Panel header="Search Storage" key="1">
                            <Form form={searchForm} {...formLayout_2Columns}>
                                <CommonSearchFormItem onBatchChange={setSearchBatchId} onStorageChange={setSearchStorageId} onAquacultureStageChange={setSearchAquacultureStageId}
                                    onLifecycleChange={setSearchLifecycleId} showLockedStorage={false}/>
                                    
                                <Form.Item name="batchTypeId" label="Batch Type">
                                    <BatchTypeSelect withBlank={true} onChange={onBatchTypeChange}/>
                                </Form.Item>
                                
                                <Row justify="center">
                                    <Col span={6}></Col>
                                    <Col span={12} style={{textAlign: "center"}}>
                                        <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                        <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                    </Col>
                                    <Col span={6}></Col>
                                </Row>
                            </Form>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={12} style={{textAlign: "start"}}>
                    <Popconfirm title="Cancel create hatching record confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={12} />
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>

            <Table bordered columns={columns} dataSource={storageDataSource} pagination={false} onRow={onRowClick}/>

            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={3} style={{textAlign: "start"}}>
                    <Popconfirm title="Cancel create hatching record confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={18} style={{textAlign: "center"}}>
                    <Pagination
                        size={PAGINATIONSIZE}
                        total={totalRecord}
                        showTotal={showTotal}
                        pageSize={PAGESIZE}
                        current={currentPage}
                        hideOnSinglePage={false}
                        showSizeChanger={false}
                        onChange={onPaginationChange}/>
                </Col>
                <Col span={3} />
            </Row>
        </Form>
        </Spin>
        </>
    )
}

export default FeedingStorageTable