import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL,  } from "./SystemParameter"
import { reportError } from "./Utility"
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./UserSession"

const { Option } = Select

//----------
// Component
//----------
const EnvironmentReadingTypeSelect = ({withBlank, defaultValue, onChange, mode}) => {
    const [environmentReadingTypeOption, setEnvironmentReadingTypeOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
            
    //---------
    // Load all
    //---------
    const getAllEnvironmentReadingType = () => {
        axios.get(`${ACISAPIURL}environmentreadingtype/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( type => <Option key={type.pKey}>{type.name}</Option> ))
            setEnvironmentReadingTypeOption(options)
        })
        .catch( error => {
            reportError(error, "<EnvironmentReadingTypeSelect /> Failed to get environment reading types.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(mode != "multiple") {
            if(e != undefined && value != undefined)
                onChange(e, value)
            else
                onChange(0, "")
        }
        else {
            if(e != undefined && value != undefined) {
                onChange(e, value)
            }
            else
                onChange([], [])
        }
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllEnvironmentReadingType()
    }, []) 
    
    return(
        <Select mode={mode} defaultValue={defaultValue} style={{width: optionWidth}} onChange={(e, value) => onSelectionChange(e, value)}
            allowClear showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {environmentReadingTypeOption}
        </Select>
    )
}

export default EnvironmentReadingTypeSelect