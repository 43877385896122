import { useEffect } from 'react'
import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { MENUPATH_MORTALITY } from "../Common/SystemParameter"
import MortalityStorage from './MortalityStorage'
import { useNavigate } from 'react-router-dom'
import { OTHERSYSPARAM } from '../Common/UserSession'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const Mortality = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    
    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_MORTALITY
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader onBack={() => onBack()} 
                    title="Mortality">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Select storage and item type</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><MortalityStorage/></Col>
                    <Col span={1}></Col>
                </Row>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Mortality:"
                    subTitle="Select storage and item type"/>} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default Mortality