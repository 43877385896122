import { useEffect, useState } from 'react'
import { Form, Space, Row, Col, Typography, Card, Table, Button, Tooltip, Spin } from 'antd'
import { LeftOutlined, RightOutlined, PrinterOutlined } from "@ant-design/icons"
import { ACISAPIURL, EGGS, LOADING, MENUPATH_STOCKCOUNT, TAGGED, UNTAGGED } from '../Common/SystemParameter'
import { loadMarineLifeItemTypeUnitOfMeasurementStandardTable, numberWithCommas, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import { useNavigate } from 'react-router-dom'
import { generateStockPreCountPdf } from './generateStockPreCountSummaryPdf'

const { Title } = Typography

const StockPreCountSummaryTable = () => {
    const navigate = useNavigate()
    
    const [isLoading, setIsLoading] = useState(false)
    const [itemDataSource, setItemDataSource] = useState([])
    const [marineLifeId, setMarineLifeId] = useState(0)
    
    let prevSavedVarianceAdjustment = []
    let stockBalance = null
    let countedStockBalance = 0
        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [readOnly, setReadOnly] = useState(urlParams.get("completedOn") == "" ? false : true)

    //--------------------------
    // Get stock count summary
    //--------------------------
    const getPreCountSummary = async () => {
        setIsLoading(true)

        const standardITUOMTable = await loadMarineLifeItemTypeUnitOfMeasurementStandardTable()

        return await axios.get(`${ACISAPIURL}stockcount/precount/`, { 
            params: {
                stock_count: urlParams.get("pKey")
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const marineLifeId = response.data.results[0].marine_life
            setMarineLifeId(marineLifeId)
            let itemLabel = ""
            let data = []
            
            //------------
            // Egg balance
            //------------
            let eggs = response.data.results.filter( item => item.item_type_name.startsWith(EGGS))
            let quantity = OTHERSYSPARAM("NA")
            if(eggs != undefined && eggs[0]?.quantity > 0) quantity = eggs[0].quantity
            let ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == marineLifeId && row.item_type_data.name.startsWith(EGGS))
            itemLabel = `${ituom[0] ?.item_type_data.name} (${ituom[0]?.uom_data.uom})`
            data.push({
                item: itemLabel,
                stockBalance: numberWithCommas(quantity)
            })
                        
            //----------------------
            // Untagged fish balance
            //----------------------
            let untaggedFish = response.data.results.filter( item => item.item_type_name.startsWith(UNTAGGED))
            quantity = OTHERSYSPARAM("NA")
            if(untaggedFish != undefined && untaggedFish[0]?.quantity > 0) quantity = untaggedFish[0].quantity
            ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == marineLifeId && row.item_type_data.name.startsWith(UNTAGGED))
            itemLabel = `${ituom[0] ?.item_type_data.name} (${ituom[0]?.uom_data.uom})`
            data.push({
                item: itemLabel,
                stockBalance: numberWithCommas(quantity),
            })
            
            //--------------------
            // Tagged fish balance
            //--------------------
            itemLabel = ""
            let taggedFish = response.data.results.filter( item => item.item_type_name.startsWith(TAGGED))
            quantity = OTHERSYSPARAM("NA")
            if(taggedFish != undefined && taggedFish[0]?.quantity > 0) quantity = taggedFish[0].quantity
            ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == marineLifeId && row.item_type_data.name.startsWith(TAGGED))
            itemLabel = `${ituom[0] ?.item_type_data.name} (${ituom[0]?.uom_data.uom})`
            data.push({
                item: itemLabel,
                stockBalance: numberWithCommas(quantity),
            })
            
            setItemDataSource(data)
        })
        .catch( error => {
            reportError(error, "Failed to get pre-count summary.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Get stock count summary snapshot
    //--------------------------
    const getPreCountSummarySnapshot = async () => {
        setIsLoading(true)

        const standardITUOMTable = await loadMarineLifeItemTypeUnitOfMeasurementStandardTable()
                        
        return await axios.get(`${ACISAPIURL}stockcount/precountsnapshot/`, { 
            params: {
                stock_count: urlParams.get("pKey")
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const marineLifeId = response.data.marine_life
            setMarineLifeId(marineLifeId)
            let itemLabel = ""
            let data = []

            //------------
            // Egg balance
            //------------
            let quantity = OTHERSYSPARAM("NA")
            if(response.data.stock_balance.egg > 0) quantity = response.data.stock_balance.egg
            let ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == marineLifeId && row.item_type_data.name.startsWith(EGGS))
            itemLabel = `${ituom[0] ?.item_type_data.name} (${ituom[0]?.uom_data.uom})`
            data.push({
                item: itemLabel,
                stockBalance: numberWithCommas(quantity)
            })
                        
            //----------------------
            // Untagged fish balance
            //----------------------
            quantity = OTHERSYSPARAM("NA")
            if(response.data.stock_balance.untagged_fish > 0) quantity = response.data.stock_balance.untagged_fish
            ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == marineLifeId && row.item_type_data.name.startsWith(UNTAGGED))
            itemLabel = `${ituom[0] ?.item_type_data.name} (${ituom[0]?.uom_data.uom})`
            data.push({
                item: itemLabel,
                stockBalance: numberWithCommas(quantity),
            })
            
            //--------------------
            // Tagged fish balance
            //--------------------
            quantity = OTHERSYSPARAM("NA")
            if(response.data.stock_balance.tagged_fish > 0) quantity = response.data.stock_balance.tagged_fish
            ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == marineLifeId && row.item_type_data.name.startsWith(TAGGED))
            itemLabel = `${ituom[0] ?.item_type_data.name} (${ituom[0]?.uom_data.uom})`
            data.push({
                item: itemLabel,
                stockBalance: numberWithCommas(quantity),
            })
            
            setItemDataSource(data)
        })
        .catch( error => {
            reportError(error, "Failed to get pre-count summary.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------------------------
    // Load existing egg stock count. Lift state up
    //---------------------------------------------
    const getEggStockCountVarianceAdjustment = async (stockCountPKey) => {
        setIsLoading(true)

        return await axios.get(`${ACISAPIURL}stockcount/egg/`, {
            params: { 
                stock_count: stockCountPKey,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.results.forEach( eggStockCount => {
                stockBalance = eggStockCount.stock_balance
                countedStockBalance = eggStockCount.counted_balance
                    
                let data = []
                eggStockCount.variance_adjustment.forEach( adj => {
                    data.push({
                        quantity: adj.quantity,
                        remark: adj.remark,
                        //transactionTypeId: adj.transaction_type_data.pKey,
                        transactionType: adj.transaction_type
                    })
                })
                
                prevSavedVarianceAdjustment = data
            })
        })
        .catch( error => {
            reportError(error, "Failed to get egg stock count record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //------------------------
    // Print pre count summary
    //------------------------
    const printPreCountSummary = () => {
        generateStockPreCountPdf(
            urlParams.get("pKey"), 
            urlParams.get("batchId"), 
            urlParams.get("batch"), 
            urlParams.get("storagId"), 
            urlParams.get("storage"), 
            urlParams.get("createdOn"),
            marineLifeId)
    }

    //--------
    // On next
    //--------
    const onNext = () => {
        let params = new URLSearchParams(`?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}&marineLifeId=${marineLifeId}`)

        getEggStockCountVarianceAdjustment(urlParams.get("pKey"))
        .then(() => {
            params.append("countedStockBalance", countedStockBalance)

            prevSavedVarianceAdjustment.forEach( adj => {
                params.append("quantity", adj.quantity)
                params.append("remark", adj.remark)
                //params.append("transactionTypeId", adj.transactionTypeId)
                params.append("transactionType", adj.transactionType)
            })

            navigate({ 
                pathname: "/stockcountegg", 
                search: params.toString()
            }) 
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({
            pathname: MENUPATH_STOCKCOUNT,
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(!readOnly)
            getPreCountSummary()
        else
            getPreCountSummarySnapshot()
    }, [])

    //--------------
    // Table columns
    //--------------
    const itemTableColumns = [
        { title: 'Item', dataIndex: 'item', key: 'item', sorter: (a, b) => a.item.localeCompare(b.item) },
        { title: 'Stock Balance', dataIndex: 'stockBalance', key: 'stockBalance', sorter: (a, b) => a.stockBalance - b.stockBalance },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Stock Count Storage: ${urlParams.get("storage")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Initialised On:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
            
                    { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                        </>
                    }
                </Card>

                <Row><Col><Space><div /></Space></Col></Row>

                <Card title="Stock Count Item" style={{textAlign: "left"}}>
                    Detail of stock count item. Please see below for more information.
                </Card>
            </Form.Item>
        </Form>

        
        <Row justify="center">
            <Col span={12} style={{textAlign: "center"}}>
                <Table bordered columns={itemTableColumns} dataSource={itemDataSource} pagination={false} />
                <Row><Col><Space><div /></Space></Col></Row>
            </Col>
        </Row>    
        

        <Row justify="center">
            <Col span={10} style={{textAlign: "start"}}>
                <Button type="primary" htmlType="button" onClick={onBack} icon={<LeftOutlined />}>Back</Button>
            </Col>
            <Col span={4} style={{textAlign: "center"}}>
                { !readOnly &&
                    <Tooltip title="Print stock count form">
                        <Button type="primary" icon={<PrinterOutlined/>} onClick={printPreCountSummary} />
                    </Tooltip>
                }
            </Col>
            <Col span={10} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" onClick={onNext} icon={<RightOutlined />}>Next</Button>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default StockPreCountSummaryTable
