import { Table, Row, Col, Space, Button, Form, Collapse, message, Modal, Pagination, Spin } from 'antd'
import { QuestionCircleOutlined, ProfileOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, EGGS, LOADING, MEDIAMAXWIDTH, MENUPATH_STOCKTRANSACTION, PAGESIZE, PAGINATIONSIZE, TAGGED, UNTAGGED } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from '../Common/Utility'
import { formLayout_2Columns } from '../Common/Layout'
import AcquiringMethodSelect from '../Common/AcquiringMethodSelect'
import BatchTypeSelect from '../Common/BatchTypeSelect'
import { useMediaQuery } from 'react-responsive'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { confirm } = Modal

//----------
// Component
//----------
const CollectiveFishStockTransactionSourceStorageTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [disableButton, setDisableButton] = useState("")
    const [searchForm] = Form.useForm()
    const navigate = useNavigate()

    const [searchStorageId, setSearchStorageId] = useState(0)
    const [searchBatchId, setSearchBatchId] = useState(0)
    const [searchBatchTypeId, setSearchBatchTypeId] = useState(0)
    const [searchAcquiringMethodId, setSearchAcquiringMethodId] = useState(0)
    const [searchAquacultureStageId, setSearchAquacultureStageId] = useState(0)
    const [searchLifecycleId, setSearchLifecycleId] = useState(0)
    const [searchMarineLifeId, setSearchMarineLifeId] = useState(0)
    const [searchSpeciesId, setSearchSpeciesId] = useState(0)
    const [searchItemTypeId, setSearchItemTypeId] = useState(0)
    
    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const searchStorageByItemType = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storagepaginatedbyitemtype/`, {
            params: { 
                site: getUserSiteId(),
                batch: searchBatchId,
                id: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : searchStorageId,
                marine_life: searchMarineLifeId,
                species: searchSpeciesId,
                acquiring_method: searchAcquiringMethodId,
                aquaculture_stage: searchAquacultureStageId,
                lifecycle: searchLifecycleId,
                batch_type: searchBatchTypeId, 
                item_type: searchItemTypeId,
                empty: false,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPushByItemType(response)
        })
        .catch( error => {
            reportError(error, "Failed to search storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPushByItemType = (response) => {
        let data = []
        response.data.results
            .forEach( storage => {
            data.push({
                key: storage.id,
                id: storage.storage_name,
                storageId: storage.storage,
                storageType: storage.storage_type_data.name,
                batchId: storage.batch_data.pKey,
                batch: storage.batch_data.id,
                batchType: storage.batch_data.batch_type_data.name,
                batchTypeId: storage.batch_data.batch_type_data.pKey,
                acquiringMethod: storage.batch_data.acquiring_method_data.name,
                acquiringMethodId: storage.batch_data.acquiring_method_data.pKey,
                aquacultureStage: storage.batch_data.aquaculture_stage_data.name,
                lifecycle: storage.batch_data.lifecycle_data.name,
                lifecycleId: storage.batch_data.lifecycle_data.pKey,
                marineLifeId: storage.batch_data.species_data.marine_life_data.pKey,
                marineLife: storage.batch_data.species_data.marine_life_data.name,
                speciesId: storage.batch_data.species_data.pKey,
                species: storage.batch_data.species_data.short_name,
                itemTypeId: storage.item_type,
                itemTypeLabel: `${storage.item_type_name} (${storage.uom_name})`,
                itemType: storage.item_type_name,
                uomId: storage.uom,
                uom: storage.uom_name,
                quantity: numberWithCommas(storage.quantity),
                batchAverageWeight: storage.batch_data.average_per_unit_weight_gram,
                storageUntaggedAverageWeight: storage.untagged_average_weight_gram,
            })
        })

        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStorageByItemType(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStorageByItemType(1)
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                onNext(record)
            }
        }
    }
    
    //---------------------------
    // On acquiring method change
    //---------------------------
    const onAcquiringMethodChange = (e, value) => {
        setSearchAcquiringMethodId(e)
    }

    //---------------------
    // On batch type change
    //---------------------
    const onBatchTypeChange = (e, value) => {
        setSearchBatchTypeId(e)
    }

    //------------------
    // Go to create page
    //------------------
    const onNext = (record) => {
        if(parseInt(record.quantity) == 0) {
            message.warn("Insuffient quantity to stock-out.")
            return
        }
        
        // If is untagged fish or egg, proceed as usual.
        if(record.itemType.startsWith(EGGS) || record.itemType.startsWith(UNTAGGED))
            navigate({ 
                pathname: "/collectivefishstockout", 
                search: `?storageId=${record.storageId}&storage=${record.id}&batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&species=${record.species}&acquiringMethod=${record.acquiringMethod}&aquacultureStage=${record.aquacultureStage}&lifecycle=${record.lifecycle}&itemTypeUom=${record.itemTypeLabel}&itemTypeId=${record.itemTypeId}&itemType=${record.itemType}&availableQuantity=${record.quantity.replace(',','')}&averageWeight=${record.storageUntaggedAverageWeight}&marineLifeId=${record.marineLifeId}`
            })
        else if(record.itemType.startsWith(TAGGED)) { // If is tagged fish, redirect to Tagged Fish Stock-Out. 
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>You will be redirected to tagged marine life stock-out page. Proceed?</p></Space>,
                okText: "Yes",
                cancelText: "No",
                onOk() { 
                    navigate({ 
                        pathname: "/fishstockout", 
                        search: `?&batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&storageId=${record.storageId}&batchAverageWeight=${record.batchAverageWeight}`
                    })
                },
                onCancel() {},
                centered: true
            })
        }
    }

    //------------------------
    // Redirect to listing page
    //------------------------
    const onGoToListing = () => {
        navigate({ pathname: MENUPATH_STOCKTRANSACTION })
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onMarineLifeChange = (e, value) => {
        setSearchMarineLifeId(e)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setSearchStorageId(urlParams.get("storageId"))
            searchStorageByItemType(currentPage, urlParams.get("storageId"))
        }
        else
            searchStorageByItemType(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        !isTabletOrMobile ? { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) } : {},
        !isTabletOrMobile ? { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) } : {},
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Item Type', dataIndex: 'itemTypeLabel', key: 'itemTypeLabel', sorter: (a, b) => a.itemTypeLabel.localeCompare(b.itemTypeLabel) },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', align: "right", sorter: (a, b) => stringNumberSorter(a.quantity, b.quantity) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Source Storage" key="1">
                        <Form form={searchForm} {...formLayout_2Columns}>
                            <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={setSearchSpeciesId} onBatchChange={setSearchBatchId} 
                                onStorageChange={setSearchStorageId} onAquacultureStageChange={setSearchAquacultureStageId}
                                onLifecycleChange={setSearchLifecycleId} onItemTypeChange={setSearchItemTypeId} showLockedStorage={false} defaultStorageId={searchStorageId}/>

                            <Form.Item name="acquiringMethodId" label="Acquiring Method">
                                <AcquiringMethodSelect withBlank={true} onChange={onAcquiringMethodChange}/>
                            </Form.Item>

                            <Form.Item name="batchTypeId" label="Batch Type">
                                <BatchTypeSelect withBlank={true} onChange={onBatchTypeChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        
        <Row justify="center">
            <Col span={12} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onGoToListing} icon={<ProfileOutlined />}>Transaction Listing</Button></Col>
            <Col span={12} />
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={storageDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onGoToListing} icon={<ProfileOutlined />}>Transaction Listing</Button></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>)
}

export default CollectiveFishStockTransactionSourceStorageTable