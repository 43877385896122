import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button,Space, Layout, Row, Col, Typography, message, Table, Modal, Collapse, Popconfirm, Card, PageHeader, InputNumber, Descriptions, Switch, Pagination, DatePicker, Spin } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MEDIAMAXWIDTH, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT } from '../Common/SystemParameter'
import { buildMismatchedCriteriaString, calculateWeightVariancePercentage, numberWithCommas, reportError, storageCapacityCheck } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserSiteId, getUserAuthToken, SYSPARAM, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout_2Columns, formLayout } from '../Common/Layout'
import { LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { useMediaQuery } from 'react-responsive'
import AcquiringMethodSelect from '../Common/AcquiringMethodSelect'
import BatchTypeSelect from '../Common/BatchTypeSelect'
import { showStorageStockSummary } from '../Common/showStorageStockSummary'
import { releaseStorage } from '../Common/releaseStorage'
import GenderSelect from '../Common/GenderSelect'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'
import moment from 'moment'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal
const { Panel } = Collapse

const FishStorage = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()
    const [record, setRecord] = useState(null)
    const [genderId, setGenderId] = useState(0)
    const [isLoanUnit, setIsLoanUnit] = useState(false)
    
    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isSearching, setIsSearching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    const [searchMarineLifeId, setSearchMarineLifeId] = useState(0)
    const [searchSpeciesId, setSearchSpeciesId] = useState(0)
    const [searchStorageId, setSearchStorageId] = useState(0)
    const [searchAquacultureStageId, setSearchAquacultureStageId] = useState(0)
    const [searchLifecycleId, setSearchLifecycleId] = useState(0)
    const [searchAcquiringMethodId, setSearchAcquiringMethodId] = useState(0)
    const [searchBatchTypeId, setSearchBatchTypeId] = useState(0)
    const [searchBatchId, setSearchBatchId] = useState(0)

    const [tagDateTime, setTagDateTime] = useState(moment(moment(), UNIDATEFORMAT))

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    
    const [acquiringMethodId, setAcquiringMethodId] = useState(
        // selectedAcquiringMethodId is from BatchNew.js
        urlParams.has("acquiringMethodId") ? urlParams.get("acquiringMethodId") : (urlParams.has("selectedAcquiringMethodId") ? urlParams.get("selectedAcquiringMethodId") : 0)
    )
    const [acquiringMethod, setAcquiringMethod] = useState(
        urlParams.has("acquiringMethod") ? urlParams.get("acquiringMethod") : (urlParams.has("selectedAcquiringMethod") ? urlParams.get("selectedAcquiringMethod") : "")
    )

    const [availableQuantity, setAvailableQuantity] = useState(urlParams.has("sourceBatchId") ? parseInt(urlParams.get("availableQuantity")) : 10000000)
    
    //------------
    // Get storage
    //------------
    const searchStorage = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        // If search by storage id, in order to show empty storage, will not be filtered by species.
        if(searchStorageId != 0) {
            message.info("Search by Storage ID would disable species filter.")
        }

        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: {
                site: getUserSiteId(),
                marine_life: searchMarineLifeId,
                species: searchStorageId != 0 ? 0 : searchSpeciesId, 
                batch: searchBatchId,
                id: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : searchStorageId,
                acquiring_method: searchAcquiringMethodId,
                lifecycle: searchLifecycleId,
                batch_type: searchBatchTypeId, 
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get storage.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //------------
    // Get storage
    //------------
    const getMatchingStorage = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: {
                site: getUserSiteId(),
                acquiring_method: urlParams.has("acquiringMethodId") ? urlParams.get("acquiringMethodId") : 0,
                species: urlParams.has("speciesId") ? urlParams.get("speciesId") : 0, 
                lifecycle: urlParams.has("lifecycleId") ? urlParams.get("lifecycleId") : 0, 
                batch_type: urlParams.has("batchTypeId") ? urlParams.get("batchTypeId") : 0, 
                //free: true,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get storage.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        let data = []
        response.data.results.forEach( storage => {
            data.push({
                key: storage.id,
                pKey: storage.pKey,
                id: storage.name,
                storageType: storage.storage_type_data.name,
                batchId: (storage?.batch_data == null) ? 0 : storage.batch_data.pKey,
                batch: (storage?.batch_data == null) ? "" : storage.batch_data.id,
                acquiringMethod: storage?.batch_data?.acquiring_method_data == null ? "" : storage.batch_data.acquiring_method_data.name,
                acquiringMethodId: storage?.batch_data?.acquiring_method_data == null ? 0 : storage.batch_data.acquiring_method_data.pKey,
                aquacultureStage: storage?.batch_data?.aquaculture_stage_data.name == null ? "" : storage.batch_data.aquaculture_stage_data.name,
                lifecycle: storage?.batch_data?.lifecycle_data == null ? "" : storage.batch_data.lifecycle_data.name,
                lifecycleId: storage?.batch_data?.lifecycle_data == null ? 0 : storage.batch_data.lifecycle_data.pKey,
                batchType: storage?.batch_data?.batch_type_data?.name == null ? "" : storage.batch_data.batch_type_data.name,
                batchTypeId: storage?.batch_data?.batch_type_data?.pKey == null ? 0 : storage.batch_data.batch_type_data.pKey,
                species: storage?.batch_data?.species_data?.short_name == null ? "" : storage.batch_data.species_data.short_name,
                speciesId: storage?.batch_data?.species_data?.pKey == null ? 0 : storage.batch_data.species_data.pKey,
                batchAverageWeight: storage?.batch_data?.average_per_unit_weight_gram == null ? 0 : storage.batch_data.average_per_unit_weight_gram,
                batchAverageLength: storage?.batch_data?.average_per_unit_length_mm == null ? 0 : storage.batch_data.average_per_unit_length_mm,
                ageDays: storage?.batch_data?.age_days == null ? 0 : storage.batch_data.age_days,

                batchData: storage?.batch_data != null ? [storage.batch_data] : [],
                status: storage.status_data,
            })
        })

        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //------------------
    // Register new fish
    //------------------
    const registerFish = (record) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}fish/create/`, {
                site: getUserSiteId(),
                quantity: values.quantity,
                gender: values.gender,
                is_loan_unit: isLoanUnit,
                batch: record.batchId,
                storage: record.pKey,
                source_batch: urlParams.get("sourceBatchId"),
                source_storage: urlParams.get("sourceBatchStorageId"),
                item_type: urlParams.get("itemTypeId"),
                transactedOn: tagDateTime,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Tag registered. Please update fish tag status after physically tagged.`, OTHERSYSPARAM("LONG_MSG_DURATION"))
                
                navigate({ 
                    pathname: "/physicaltaggingfish", 
                    search: `?species=${record.species}&batch=${record.batch}&batchId=${record.batchId}&storageId=${record.pKey}&storage=${record.id}`
                })

                if(urlParams.has("sourceBatchStorageId") && parseInt(urlParams.get("sourceBatchStorageId")) > 0 && urlParams.get("acquiringMethodId") == SYSPARAM("Breed In-house"))
                    releaseStorage(record.batchId, record.batch, urlParams.get("sourceBatchStorageId"))
            })
            .catch( error => {
                reportError(error, "Failed to register fish.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //------------------
    // Register new fish
    //------------------
    const registerFishWithSourceBatch = (record) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}fish/createwithsourcebatch/`, {
                site: getUserSiteId(),
                quantity: values.quantity,
                gender: values.gender,
                is_loan_unit: isLoanUnit,
                storage: record.pKey,
                source_batch: urlParams.get("sourceBatchId"),
                source_storage: urlParams.get("sourceBatchStorageId"),
                item_type: urlParams.get("itemTypeId"),
                transactedOn: tagDateTime,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Tag registered. Please update tag status after physically tagged.`, OTHERSYSPARAM("LONG_MSG_DURATION"))
                
                navigate({ 
                    pathname: "/physicaltaggingfish", 
                    search: `?species=${urlParams.get("species")}&batch=${urlParams.get("sourceBatch")}&batchId=${urlParams.get("sourceBatchId")}&storageId=${record.pKey}&storage=${record.id}`
                })

                if(urlParams.has("sourceBatchStorageId") && parseInt(urlParams.get("sourceBatchStorageId")) > 0 && urlParams.get("acquiringMethodId") == SYSPARAM("Breed In-house"))
                    releaseStorage(urlParams.get("sourceBatchId"), urlParams.get("sourceBatch"), urlParams.get("sourceBatchStorageId"))
            })
            .catch( error => {
                reportError(error, "Failed to register fish.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //---------------------------------
    // Register new Fish with new batch
    //---------------------------------
    const registerFishWithNewBatch = (record) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}fish/createwithnewbatch/`, {
                site: getUserSiteId(),
                quantity: values.quantity,
                gender: values.gender,
                is_loan_unit: isLoanUnit,
                storage: record.pKey,
                source_batch: urlParams.get("sourceBatchId"),
                source_storage: urlParams.get("sourceBatchStorageId"),
                item_type: urlParams.get("itemTypeId"),
                transactedOn: tagDateTime,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Tag registered. Please update tag status after physically tagged.`, OTHERSYSPARAM("LONG_MSG_DURATION"))
                
                navigate({ 
                    pathname: "/physicaltaggingfish", 
                    search: `?species=${urlParams.get("species")}&batch=${response.data.id}&batchId=${response.data.pKey}&storageId=${record.pKey}&storage=${record.id}`
                })

                if(urlParams.has("sourceBatchStorageId") && parseInt(urlParams.get("sourceBatchStorageId")) > 0 && urlParams.get("acquiringMethodId") == SYSPARAM("Breed In-house"))
                    releaseStorage(urlParams.get("sourceBatchId"), urlParams.get("sourceBatch"), urlParams.get("sourceBatchStorageId"))
            })
            .catch( error => {
                reportError(error, "Failed to register fish.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //-------------------
    // Check overcrowding
    //-------------------
    const checkOvercrowd = async () => {
        // Check for overcrowding by weight in Kg.
        let weightToAddKg = urlParams.get("averageWeight") * form.getFieldValue("quantity") / 1000
                
        try{
            const prompt = await storageCapacityCheck(urlParams.get("marineLifeId"), record.batchId, record.pKey, weightToAddKg)

            if(prompt)
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Overcrowding will happen after transfer. Procced?</p></Space>,
                    onOk() { onSave() },
                    onCancel() {},
                    okText: OTHERSYSPARAM("YES"),
                    cancelText: OTHERSYSPARAM("NO"),
                    centered: true
                })
            else
                onSave()
        }
        catch(error) {
            reportError(error, "Failed to get marine life storage type capacity information.")
        }
    }

    //----------------------
    // Check weight variance
    //----------------------
    const checkWeightVariance = () => {
        let threshold = parseFloat(OTHERSYSPARAM("WEIGHT_VARIANCE_THRESHOLD_PERCENT")) / 100

        // Check average weight variance. Prompt if variance is greater than predefined percentage threshold.
        switch(urlParams.has("sourceBatchId")) {
            case true:
                if(record.batchId != 0) {
                    if(parseFloat(urlParams.get("averageWeight")) != 0 && parseFloat(record.batchAverageWeight) != 0) {
                        let weightDiff = calculateWeightVariancePercentage(parseFloat(urlParams.get("averageWeight")), parseFloat(record.batchAverageWeight))
                                                                
                        if(weightDiff >= threshold) {
                            confirm({
                                icon: <QuestionCircleOutlined />,
                                content: <Space><p>Weight variance between the two batches exceeded by {(threshold * 100).toFixed(2)}%. Proceed to stock-in?</p></Space>,
                                onOk() { checkOvercrowd() },
                                onCancel() {},
                                centered: true
                            })
                        }
                        else
                            checkOvercrowd()
                    }
                    else
                        checkOvercrowd()
                }
                else
                    checkOvercrowd()

                break
            case false:        
                if(record.batchId != 0) {
                    let weightDiff = calculateWeightVariancePercentage(parseFloat(form.getFieldValue("averagePerUnitWeightGram")), parseFloat(record.batchAverageWeight))
                                        
                    if(weightDiff >= threshold) {
                        confirm({
                            icon: <QuestionCircleOutlined />,
                            content: <Space><p>Weight variance between the two batches exceeded by {(threshold * 100).toFixed(2)}%. Proceed to stock-in?</p></Space>,
                            onOk() { checkOvercrowd() },
                            onCancel() {},
                            centered: true
                        })
                    }
                    else
                        checkOvercrowd()
                }
                else
                    checkOvercrowd()

                break
        }
    }

    //--------
    // On save
    //--------
    const onSave = () => {
        if(parseInt(form.getFieldValue("quantity")) > availableQuantity) {
            message.warn("Tag quantity exceeded available quantity.")
            return
        }

        switch(record.batchId != 0) {
            case true: // Storage has batch assigned.
                // Cannot mix species
                if(urlParams.has("speciesId") && record.speciesId != 0 && urlParams.get("speciesId") != record.speciesId) {
                    message.warn("Mixing species into a storage is not allowed.")
                    return
                }

                let mismatchedCriteria = buildMismatchedCriteriaString(urlParams.get("batchTypeId"), record.batchTypeId,
                    urlParams.get("acquiringMethodId"), record.acquiringMethodId, urlParams.get("lifecycleId"), record.lifecycleId)
                
                if(urlParams.has("sourceBatchStorageId") && mismatchedCriteria != "") {
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>{mismatchedCriteria} do not match. Proceed to stock-in?</p></Space>,
                        onOk() { registerFish(record) },
                        onCancel() {},
                        centered: true
                    })    
                }
                else
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>New tagging confirmed?</p></Space>,
                        onOk() { registerFish(record) },
                        onCancel() {},
                        centered: true
                    })    
                break
            case false: // Storage has no batch assigned.
                if(urlParams.has("sourceBatchStorageId"))
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Assign existing batch {urlParams.get("sourceBatch")} to storage {record.id}?</p></Space>,
                        onOk() { registerFishWithSourceBatch(record) },
                        onCancel() {
                            confirm({
                                icon: <QuestionCircleOutlined />,
                                content: <Space><p>Selected storage has no batch assigned. Create a new batch for {record.id}?</p></Space>,
                                onOk() { registerFishWithNewBatch(record) },
                                onCancel() {},
                                centered: true
                            })
                        },
                        okText: OTHERSYSPARAM("YES"),
                        cancelText: OTHERSYSPARAM("NO"),
                        centered: true
                    })
                else {
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Storage has no batch assigned. Go to Batch module to create one?</p></Space>,
                        onOk() { 
                            navigate({ 
                                pathname: "/batchnew", 
                                search: `?fromFishTagging=true&selectedAcquiringMethodId=${acquiringMethodId}&selectedAcquiringMethod=${acquiringMethod}&storageId=${record.pKey}&storage=${record.id}`
                            }) 
                        },
                        onCancel() {},
                        centered: true
                    })
                }
                break
        }
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: "/fishsourcebatch", 
        })
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                setRecord(record)
                form.submit()
            }
        }
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        if(!isSearching)
            getMatchingStorage(page)
        else {
            searchStorage(page)
        }
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setIsSearching(true)
        setCurrentPage(1)
        searchStorage(1)
    }

    //---------------------------
    // On acquiring method change
    //---------------------------
    const onAcquiringMethodChange = (e, value) => {
        setSearchAcquiringMethodId(e)
    }

    //---------------------
    // On batch type change
    //---------------------
    const onBatchTypeChange = (e, value) => {
        setSearchBatchTypeId(e)
    }

    //---------------------
    // On gender change
    //---------------------
    const onGenderChange = (e, value) => {
        setGenderId(e)
    }

    //-----------------------
    // On is loan unit change
    //-----------------------
    const onIsLoanUnitChange = (e, value) => {
        setIsLoanUnit(e)
    }

    //----------------------------
    // Redirect callback function
    //----------------------------
    const redirect = (pathname, search) => {
        navigate({
            pathname: pathname,
            search: search
        })
    }

    //----------------------------
    // Show storage stock quantity
    //----------------------------
    const showStoragePopup = (e, record) => {
        e.stopPropagation()
        record.key = record.pKey
        showStorageStockSummary(false, record, redirect)
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setTagDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateTagDateTime = (() => {
        if(tagDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Tag date time is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        // if(urlParams.has("storageId")) {
        //     setIsSearching(true)
        //     setSearchStorageId(urlParams.get("storageId"))
        //     searchStorage(currentPage, urlParams.get("storageId"))
        // }
        // else
        getMatchingStorage(currentPage)

        form.setFieldsValue({
            quantity: 1
        })
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        !isTabletOrMobile ? { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) } : {},
        !isTabletOrMobile ? { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) } : {},
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Status', dataIndex: 'stockQuantity', key: 'stockQuantity', align: 'center', //sorter: (a, b) => a.quantity.stockQuantity(b.stockQuantity),
            render: (stockQuantity, record) => {
                if(record.status == OTHERSYSPARAM("IS_OCCUPIED"))
                    return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => showStoragePopup(e, record)}>{OTHERSYSPARAM("IS_OCCUPIED")}</Button>
                else
                    return OTHERSYSPARAM("IS_EMPTY")
            }
        },
    ]

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onBack()} 
                    title="New Tag">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Select destination storage</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={checkWeightVariance} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Source Storage: ${urlParams.get("sourceBatchStorage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Source Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("sourceBatch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                        </Card>
                    </Form.Item>

                    <Form.Item name="tagDateTime" label="Tag Date Time"
                        rules={[
                            { required: true, validator: validateTagDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={tagDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>
            
                    <Form.Item name="quantity" label={`Tag Quantity ${urlParams.has("sourceBatchId") ? `Out of ${numberWithCommas(availableQuantity)} (${urlParams.get("uom")}). Maximum 100 per tagging` : ""}`} 
                        rules={[
                            { required: true, message: "Tag quantity is required."},
                        ]}>
                        <InputNumber min={1} max={100} step={1} placeholder="Quantity"/>
                    </Form.Item>

                    <Form.Item name="gender" label="Gender" 
                        rules={[
                            { required: true, message: "Gender is required."},
                        ]}>
                        <GenderSelect withBlank={false} onChange={onGenderChange}/>
                    </Form.Item>

                    <Form.Item name="isLoanUnit" label="Is Loan Unit">
                        <Switch checked={isLoanUnit} onChange={onIsLoanUnitChange}/>
                    </Form.Item>
                </Form>

                <Row>
                    <Col span={1}></Col>
                        <Col span={22}>
                            <Collapse defaultActiveKey={["0"]}>
                                <Panel header="Search Destination Storage" key="1">
                                    <Form form={searchForm} {...formLayout_2Columns}>
                                        <CommonSearchFormItem onMarineLifeChange={setSearchMarineLifeId} onSpeciesChange={setSearchSpeciesId} onBatchChange={setSearchBatchId} 
                                            onStorageChange={setSearchStorageId} onAquacultureStageChange={setSearchAquacultureStageId}
                                            onLifecycleChange={setSearchLifecycleId} showLockedStorage={false}/>

                                        <Form.Item name="acquiringMethodId" label="Acquiring Method">
                                            <AcquiringMethodSelect withBlank={true} onChange={onAcquiringMethodChange}/>
                                        </Form.Item>

                                        <Form.Item name="batchTypeId" label="Batch Type">
                                            <BatchTypeSelect withBlank={true} onChange={onBatchTypeChange}/>
                                        </Form.Item>
                                        
                                        <Row justify="center">
                                            <Col span={6}></Col>
                                            <Col span={12} style={{textAlign: "center"}}>
                                                <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                                <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                            </Col>
                                            <Col span={6}></Col>
                                        </Row>
                                    </Form>
                                </Panel>
                            </Collapse>
                        </Col>
                    <Col span={1}></Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={2} style={{textAlign: "start"}}>
                        <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                            <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={21}></Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row>
                    <Col span={1}></Col>
                    <Col span={22}>
                        <Table bordered columns={columns} onRow={onRowClick} dataSource={storageDataSource} pagination={false} />
                    </Col>
                    <Col span={1}></Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={2} style={{textAlign: "start"}}>
                        <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                            <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={18} style={{textAlign: "center"}}>
                        <Pagination
                            size={PAGINATIONSIZE}
                            total={totalRecord}
                            showTotal={showTotal}
                            pageSize={PAGESIZE}
                            current={currentPage}
                            hideOnSinglePage={false}
                            showSizeChanger={false}
                            onChange={onPaginationChange}/>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={() => onBack()} 
                        title="New Tag:"
                        subTitle="Select destination storage"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default FishStorage