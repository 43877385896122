import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { ACISAPIURL, EGGS, TAGGED, UNTAGGED } from "../Common/SystemParameter"
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "../Common/UserSession"
import { loadMarineLifeItemTypeUnitOfMeasurementStandardTable, numberWithCommas, reportError } from "../Common/Utility"
import axios from "axios"
import { FOOTER, PAGEMARGINS, PAGESIZE, reportTitle, TABLESTYLES } from "../Common/PdfMakeLayout"
import { message } from "antd"

pdfMake.vfs = pdfFonts.pdfMake.vfs

export const generateStockPreCountPdf = async (stockCountPKey, batchId, batch, storageId, storage, initialisedOn, marineLifeId) => {
    let eggDataSource = []
    let untaggedFishDataSource = []
    let taggedFishDataSource = []
    let showEgg = true
    let showUntaggedFish = true
    let showTaggedFish = true

    let errorFound = false
    let taggedFishPresentFishDataSource = []

    const standardITUOMTable = await loadMarineLifeItemTypeUnitOfMeasurementStandardTable()

    // Push table header row
    let ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == marineLifeId && row.item_type_data.name.startsWith(EGGS))
    let itemLabel = `${ituom[0] ?.item_type_data.name} (${ituom[0]?.uom_data.uom})`
    let uom = `(${ituom[0]?.uom_data.uom})`
    eggDataSource.push([
        { text: `${itemLabel} Stock Balance`,  style: TABLESTYLES.tableCellCenter },
        { text: `${itemLabel} Counted Balance`,  style: TABLESTYLES.tableCellCenter },
        { text: `Variance ${uom}`,  style: TABLESTYLES.tableCellCenter },
    ])

    ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == marineLifeId && row.item_type_data.name.startsWith(UNTAGGED))
    itemLabel = `${ituom[0] ?.item_type_data.name} (${ituom[0]?.uom_data.uom})`
    uom = `(${ituom[0]?.uom_data.uom})`
    untaggedFishDataSource.push([
        { text: `${itemLabel} Stock Balance`,  style: TABLESTYLES.tableCellCenter },
        { text: `${itemLabel} Counted Balance`,  style: TABLESTYLES.tableCellCenter },
        { text: `Variance ${uom}`,  style: TABLESTYLES.tableCellCenter },
    ])

    ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == marineLifeId && row.item_type_data.name.startsWith(UNTAGGED))
    itemLabel = `${ituom[0] ?.item_type_data.name} (${ituom[0]?.uom_data.uom})`
    uom = `(${ituom[0]?.uom_data.uom})`
    taggedFishDataSource.push([
        { text: `${itemLabel} Stock Balance`,  style: TABLESTYLES.tableCellCenter },
        { text: `${itemLabel} Counted Balance`,  style: TABLESTYLES.tableCellCenter },
        { text: `Variance ${uom}`,  style: TABLESTYLES.tableCellCenter },
    ])

    taggedFishPresentFishDataSource.push([
        { text: 'Tag ID:', style: TABLESTYLES.tableHeaderLeft },
        { text: 'Tick If Present', style: TABLESTYLES.tableHeaderCenter }])

    //--------------------------
    // Get stock count summary
    //--------------------------
    const getPreCountSummary = async () => {
        return await axios.get(`${ACISAPIURL}stockcount/precount/`, { 
            params: {
                stock_count: stockCountPKey
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let data = []
            
            //------------
            // Egg balance
            //------------
            let eggs = response.data.results.filter( item => item.item_type_name.startsWith(EGGS))
            if(eggs != undefined && eggs[0]?.quantity > 0) {
                data.push([
                    { text: numberWithCommas(eggs[0].quantity), style: TABLESTYLES.tableCellCenter },
                    { text: "", style: TABLESTYLES.tableCellCenter },
                    { text: "", style: TABLESTYLES.tableCellCenter },
                ])
                eggDataSource = eggDataSource.concat(data)
            }
            else 
                showEgg = false
        
            //----------------------
            // Untagged fish balance
            //----------------------
            data = []
            let untaggedFish = response.data.results.filter( item => item.item_type_name.startsWith(UNTAGGED))
            if(untaggedFish != undefined && untaggedFish[0]?.quantity > 0) {
                data.push([
                    { text: numberWithCommas(untaggedFish[0].quantity), style: TABLESTYLES.tableCellCenter },
                    { text: "", style: TABLESTYLES.tableCellCenter },
                    { text: "", style: TABLESTYLES.tableCellCenter },
                ])
                untaggedFishDataSource = untaggedFishDataSource.concat(data)
            }
            else 
                showUntaggedFish = false

            //--------------------
            // Tagged fish balance
            //--------------------
            data = []
            let taggedFish = response.data.results.filter( item => item.item_type_name.startsWith(TAGGED))
            if(taggedFish != undefined && taggedFish[0]?.quantity > 0) {
                data.push([
                    { text: numberWithCommas(taggedFish[0].quantity), style: TABLESTYLES.tableCellCenter },
                    { text: "", style: TABLESTYLES.tableCellCenter },
                    { text: "", style: TABLESTYLES.tableCellCenter },
                ])
                taggedFishDataSource = taggedFishDataSource.concat(data)
            }
            else 
                showTaggedFish = false
        })
        .catch( error => {
            reportError(error, "Error encountered when retrieving pre-count summary.")
            throw error
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //--------------------------
    // Get stock count summary
    //--------------------------
    const getTaggedFish = async () => {
        return await axios.get(`${ACISAPIURL}fishfull/`, { 
            params: {
                batch: batchId,
                storage: storageId,
                active: true,
                ignore_lock: true
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let data = []
            response.data.results.sort((a,b) => (a.pKey > b.pKey) ? 1 : ((b.pKey > a.pKey) ? -1 : 0)).forEach( fish => {
                // Tagged fish present fish
                data.push([
                    { text: fish.id, style: TABLESTYLES.tableCellLeft },
                    { text: "",  style: TABLESTYLES.tableCellCenter },
                ])
            })
            taggedFishPresentFishDataSource = taggedFishPresentFishDataSource.concat(data)
        })
        .catch( error => {
            reportError(error, "Failed to get tagged marine life list.")
            throw error
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //---------------------------
    // Fetch data
    //---------------------------
    await getPreCountSummary()
    .catch(error => {
        errorFound = true
    })

    await getTaggedFish()
    .catch(error => {
        errorFound = true
    })

    if(errorFound) {
        message.error("Failed to generate stock count form.")
        return
    }

    //---------------------------
    // Start generate pdf content
    //---------------------------
    const title = await reportTitle("Stock Count Form")
    
    const docDefinition = {
        pageSize: PAGESIZE,
        pageMargins: PAGEMARGINS,
        footer: FOOTER,
        
        content: [{
            stack: [    
                title,
                
                {
                    table: {
                        widths: [ '16.66%', '16.66%', '16.66%', '16.66%', '16.66%', '16.66%' ],
                        body: [
                                [
                                    { text: 'Batch ID:', style: TABLESTYLES.tableHeaderRight },
                                    { text: batch, style: TABLESTYLES.tableCellLeft },
                                    { text: 'Storage :', style: TABLESTYLES.tableHeaderRight },
                                    { text: storage, style: TABLESTYLES.tableCellLeft },
                                    { text: 'Initialised On :', style: TABLESTYLES.tableHeaderRight },
                                    { text: initialisedOn, style: TABLESTYLES.tableCellLeft },
                                ],
                        ]
                    }
                },

                // Empty row
                { text: ' '},

                showEgg ? {
                    //layout: 'headerLineOnly',
                    table: {
                        //headerRows: 1,
                        widths: [ '33.33%', '33.33%', '33.33%' ],
                        body: eggDataSource
                    },
                } : "",
                showEgg ? { text: ' ' } : "",

                showUntaggedFish ? {
                    table: {
                        widths: [ '33.33%', '33.33%', '33.33%' ],
                        body: untaggedFishDataSource
                    },
                } : "",
                showUntaggedFish ? { text: ' ' } : "",

                showTaggedFish ? {
                    table: {
                        widths: [ '33.33%', '33.33%', '33.33%' ],
                        body: taggedFishDataSource
                    },
                } : "",
                showTaggedFish ? { text: ' ' } : "",

                showTaggedFish ? {
                    table: {
                        widths: [ '50%', '50%' ],
                        layout: {
                            hLineWidth: function (i, node) {
                                return 0.25
                            },
                            vLineWidth: function (i, node) {
                                return 0.25
                            }
                        },
                        body: taggedFishPresentFishDataSource,
                    },
                } : "",
            ]}
        ]
    }

    pdfMake.createPdf(docDefinition).print()
}