import { Row, Col, Space, Collapse, Button, Form, Select, message, Modal, Table, Spin } from 'antd'
import { PrinterOutlined, InfoCircleOutlined } from "@ant-design/icons"
import { FundFlowGraph } from '@ant-design/charts'
import { useEffect, useState, useRef } from 'react'
import axios from "axios"
import { ACISAPIURL, DATEFORMAT, LOADING, MEDIAMAXWIDTH, TAGGED, UNIDATEFORMAT, UNTAGGED } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { numberWithCommas, reportError } from "../Common/Utility"
import moment from 'moment'
import { formLayout_2Columns } from '../Common/Layout'
import DateRangeInput from '../Common/DateRangeInput'
import { getBatchSummaryDataSource } from '../Common/showBatchStorageStockSummary'
import { useMediaQuery } from 'react-responsive'
import { generateBatchGenealogyPdf } from './generateBatchGenealogyPdf'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { Option } = Select
const { confirm } = Modal

const BatchGenealogyBySpeciesTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
        
    // NOTE: Set limit here.
    const MAXNODE = isTabletOrMobile ? 500 : 1000
    const MAXPRINTPROMPT = 2

    const [optionWidth, setOptionWidth] = useState(OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [siteId, setSiteId] = useState(0)
    const [site, setSite] = useState("")
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [dateRange, setDateRange] = useState([null, null])
    const [isLoading, setIsLoading] = useState(false)
    const [disablePrint, setDisablePrint] = useState("disabled")
    const [printPromptCount, setPrintPromptCount] = useState(0)

    const [batchOption, setBatchOption] = useState([])
    const [speciesOption, setSpeciesOption] = useState([])

    const [fundFlowChart, setFundFlowChart] = useState(null)

    let ref = useRef()
    
    //----------------------------
    // Search batch
    //----------------------------
    const searchRemainingBatch = (excludeBatchPKeys) => {
        // Sanitize date range
        let fromDate = dateRange[0] != null ? moment(dateRange[0]).format(UNIDATEFORMAT) : null
        let toDate = dateRange[1] != null ? moment(dateRange[1]).format(UNIDATEFORMAT) : null

        return new Promise((resolve, reject) => {
            axios.get(`${ACISAPIURL}batchfull2/`, {
                params: { 
                    site: siteId,
                    fromDate: fromDate,
                    toDate: toDate,
                    marine_life: marineLifeId,
                    species: speciesId,
                    batch: batchId,
                    exclude: excludeBatchPKeys
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                resolve(response.data.results)
            })
            .catch( error => {
                reportError(error, "Failed to load remaining batch record.")
                reject(error)
            })
            .finally(() => {
                refreshUserSession()
            })
        })
    }

    //----------------------------
    // Show batch info popup
    //----------------------------
    const showBatchInfo = (batchId) => {
        axios.get(`${ACISAPIURL}batch/${batchId}/`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( async (response) => {
            const averageSummaryColumns = [
                { title: '', dataIndex: 'itemType', key: 'itemType' }, //sorter: (a, b) => a.itemType.localeCompare(b.itemType)},
                { title: 'Total Quantity', dataIndex: 'totalQty', key: 'totalQty', align: 'right' },//sorter: (a, b) => a.totalQty.localeCompare(b.totalQty)},
                { title: 'Average Weight (g)', dataIndex: 'averageWeight', key: 'averageWeight', align: 'right' },//sorter: (a, b) => stringNumberSorter(a.averageWeight, b.averageWeight) },
                { title: 'Average Length (mm)', dataIndex: 'averageLength', key: 'averageLength', align: 'right' },//sorter: (a, b) => stringNumberSorter(a.averageLength, b.averageLength) },
            ]

            //--------------
            // Summary Modal
            //--------------
            try {
                const record = { 
                    key: batchId,
                    id: response.data.id,
                    createdOn: moment(response.data.createdOn).format(DATEFORMAT),
                    batchAverageWeight: response.data.average_per_unit_weight_gram,
                    batchAverageLength: response.data.average_per_unit_length_mm,
                    storageCount: response.data.storage_assigned != "" ? response.data.storage_assigned.split(',').length : 0,
                    storageAssigned: response.data.storage_assigned
                }
                const batchSummaryDataSource = await getBatchSummaryDataSource(record)

                confirm({
                    title: <>
                            <div><h3>Batch ID: {record.id}</h3></div>
                            <div>Created On: {record.createdOn}</div>
                            <div>Number of Storage(s): {record.storageCount}</div>
                            { record.storageCount > 0 &&
                                <div>Storaged Assigned: {record.storageAssigned}</div>
                            }
                            </>,
                    icon: null,
                    width: 700,
                    onOk() {},
                    okText: "Close",
                    cancelButtonProps: {style: {display: "none"}},
                    centered: true,
                    content: <Table bordered columns={averageSummaryColumns} pagination={false} dataSource={batchSummaryDataSource} />
                })
            }
            catch(error) {
            }
        })
        .catch( error => {
            reportError(error, "Failed to load batch info.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //----------------------------
    // Search batch genealogy by species
    //----------------------------
    const searchBatchGenealogy = async () => {
        setDisableButton("disabled")
        setDisablePrint("disabled")
        setIsLoading(true)

        // Sanitize date range
        let fromDate = dateRange[0] != null ? moment(dateRange[0]).format(UNIDATEFORMAT) : null
        let toDate = dateRange[1] != null ? moment(dateRange[1]).format(UNIDATEFORMAT) : null

        await axios.get(`${ACISAPIURL}batch/genealogy/`, {
            params: { 
                site: siteId,
                fromDate: fromDate,
                toDate: toDate,
                marine_life: marineLifeId,
                species: speciesId,
                batch: batchId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to load batch genealogy record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    /*
    [0] to [5]
    select tbl1.from_batch_id, tbl1.from_batch, tbl1.to_batch_id, tbl1.to_batch, tbl1.uom, tbl1.quantity - coalesce(tbl2.quantity, 0) quantity, '
    [6] to [13]
    'coalesce(tbl3.total_untagged_quantity, 0) from_batch_untagged_quantity, '
    'coalesce(tbl3.total_untagged_weight_gram, 0) from_batch_total_untagged_weight_gram, '
    'coalesce(tbl4.total_tagged_quantity, 0) from_batch_tagged_quantity, '
    'coalesce(tbl4.total_tagged_weight_gram, 0) from_batch_total_tagged_weight_gram, '
    'coalesce(tbl5.total_untagged_quantity, 0) to_batch_untagged_quantity, '
    'coalesce(tbl5.total_untagged_weight_gram, 0) to_batch_total_untagged_weight_gram, '
    'coalesce(tbl6.total_tagged_quantity, 0) to_batch_tagged_quantity, '
    'coalesce(tbl6.total_tagged_weight_gram, 0) to_batch_total_tagged_weight_gram '
    */            
    const dataPush = async (response) => {
        const nodes = []
        let edges = []

        response.data.forEach( batch => {
            let fromNode = {
                id: String(batch[0]),
                value: { text: `\n${batch[1]}\nQty.: ${numberWithCommas(batch[6] + batch[8])}\nWt.(Kg): ${numberWithCommas(((batch[7] + batch[9]) / 1000).toFixed(2))}` },
            }

            let node = nodes.filter( node => node.id == fromNode.id)
            if(node.length == 0) nodes.push(fromNode)

            let toNode = {
                id: String(batch[2]),
                value: { text: `\n${batch[3]}\nQty.: ${numberWithCommas(batch[10] + batch[12])}\nWt.(Kg): ${numberWithCommas(((batch[11] + batch[13]) / 1000).toFixed(2))}` },
            }

            node = nodes.filter( node => node.id == toNode.id)
            if(node.length == 0) nodes.push(toNode)

            let edge = edges.filter( edge => edge.source == fromNode.id && edge.target == toNode.id)
            if(edge.length == 0) {
                let text = ""
                let subText = ""
                let extraKey = ""

                // Forward or backward
                if(parseInt(fromNode.id) < parseInt(toNode.id)) 
                    text = `${batch[5] < 1000 ? batch[5] : batch[5]/1000 + "K"} ${batch[4]}`
                else {
                    subText = `${batch[5] < 1000 ? batch[5] : batch[5]/1000 + "K"} ${batch[4]}`
                    extraKey = "B"
                }

                edges.push({
                    source: fromNode.id,
                    target: toNode.id,
                    value: { 
                        text: text, subText: subText, extraKey: extraKey
                    },
                })
            }
            else {
                let text = edge[0].value.text
                let subText = edge[0].value.subText
                
                // Forward or backward
                if(parseInt(fromNode.id) < parseInt(toNode.id))
                    text = `${text}\n${batch[7]}: ${batch[10] < 1000 ? batch[10] : batch[10]/1000 + "K"} ${batch[9]}`
                else 
                    subText = `${subText}\n${batch[7]}: ${batch[10] < 1000 ? batch[10] : batch[10]/1000 + "K"} ${batch[9]}`
                    
                edge[0].value.text = text
                edge[0].value.subText = subText
            }
        })

        if(nodes.length >= MAXNODE) {
            message.warn(`Exceeded maximum number of ${MAXNODE} batches. Please apply filter to reduce the number of batches to be shown.`)
            return
        }

        //----------------------------------------------------------------------------------------------------------------------------------
        // NOTE: Get those existing batches that match the criteria but not in genealogy yet due to not having transaction with other batch.
        //----------------------------------------------------------------------------------------------------------------------------------
        const excludeBatchPKeys = nodes.map( node => node.id )
        const remainingBatches = await searchRemainingBatch(excludeBatchPKeys.join(','))
        remainingBatches.forEach( batch => {
            let untaggedQty = 0
            let untaggedTotalWeightGram = 0
            let taggedQty = 0
            let taggedTotalWeightGram = 0

            let untagged = batch.batchstocksummary.filter( summary => summary.item_type_name.startsWith(UNTAGGED))
            if(untagged.length > 0) {
                untaggedQty = untagged[0].quantity
                untaggedTotalWeightGram = untagged[0].quantity * batch.average_per_unit_weight_gram
            }

            let tagged = batch.batchstocksummary.filter( summary => summary.item_type_name.startsWith(TAGGED))
            if(tagged.length > 0) {
                taggedQty = tagged[0].quantity
                taggedTotalWeightGram = tagged[0].quantity * batch.tagged_average_weight_data
            }

            nodes.push({
                id: String(batch.pKey),
                value: { text: `\n\n${batch.id}\nQty.: ${numberWithCommas(untaggedQty + taggedQty)}\nWt.(Kg): ${numberWithCommas(((untaggedTotalWeightGram + taggedTotalWeightGram) / 1000).toFixed(2))}` },
            })
        })
        
        //----------------------------------------------------------------------------------------------------------------------------------
        const data = {
            nodes: nodes, 
            edges: edges
        }

        const config = {
            data,
            layout: {
                /** Direction for rank nodes. Can be TB, BT, LR, or RL, where T = top, B = bottom, L = left, and R = right. */
                //rankdir: 'LR',
                /** Number of pixels that separate nodes vertically in the layout. */
                //nodesep: 30,
                /** Number of pixels that separate nodes horizontally in the layout. */
                //ranksep: 80,
            },
            nodeCfg: {
                //size: 150,
                label: {
                    style: (node) => {
                        return {
                            fill: node.id != batchId ? "default" : "red"
                        }
                    }
                }
            },
            edgeCfg: {
                // type: 'line',
                label: {
                    style: (edge) => {
                        const { value } = edge
                        return {
                            // For chart with two ways traffic.
                            //textAlign: value.extraKey == "" ? "start" : "end", 
                            //textBaseline: value.extraKey == "" ? "bottom" : "top",
                        }
                    },
                },
            },
            //fitCenter: false
        }

        if(nodes.length > 0) setDisablePrint("")

        setFundFlowChart(<FundFlowGraph 
            {...config} 
            onReady={ (plot) => { 
                ref.current = plot 
                plot.on('node:click', (evt) => {
                    const item = evt.item._cfg
                    showBatchInfo(item.id, item.value)
                })
            }} 
        />)
    }

    //-----------
    // Load batch
    //-----------
    const getSpecies = (marineLifeId) => {
        axios.get(`${ACISAPIURL}species/`, {
            params: {
                marine_life: marineLifeId
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            options.push(<Option key={""}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( species => <Option key={species.pKey}>{species.short_name}</Option>))
            setSpeciesOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get batch list.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    

    //-----------
    // Load batch
    //-----------
    const getBatch = (speciesId) => {
        axios.get(`${ACISAPIURL}batchfull/`, {
            params: {
                site: getUserSiteId(),
                species: speciesId,
                active: true
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            options.push(<Option key={""}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( batch => <Option key={batch.pKey}>{batch.id}</Option>))
            setBatchOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get batch list.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //---------------------
    // On search criteria change
    //---------------------
    const onDateRangeChange = (fromDate, toDate) => {
        setDateRange([fromDate, toDate])

        setFundFlowChart(null)
        setDisablePrint("disabled")
    }

    const clearChart = () => {
        setFundFlowChart(null)
        setDisablePrint("disabled")
    }

    //----------------------
    // On search site change
    //----------------------
    const onSearchSiteChange = (e, value) => {
        setSiteId(e)
        setSite(value.children)

        clearChart()
    }

    const onMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)

        setSpeciesId(0)
        setSpecies("")
        setSpeciesOption([])
        
        setBatchId(0)
        setBatch("")
        setBatchOption([])

        form.setFieldsValue({
            speciesId: "",
            batchId: ""
        })

        clearChart()
        getSpecies(e)
    }

    const onSearchBatchChange = (e, value) => {
        if(e != undefined) {
            setBatchId(e)
            setBatch(value.children)
        }
        else {
            setBatchId(0)
            setBatch("")
        }

        clearChart()
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
        
        setBatchId(0)
        setBatch("")
        setBatchOption([])

        form.setFieldsValue({
            batchId: ""
        })

        getBatch(e)

        clearChart()
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        if(marineLifeId == 0 && speciesId == 0 && batchId == 0) {
            message.info("Please select at least a marine life, species or batch before proceed.")
            return
        }

        searchBatchGenealogy()
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //----------------
    // get base64 data
    //----------------
    const onPrint = () => {
        if(ref.current?.toDataURL() == undefined) {
            message.warn("Batch genealogy chart image file not found.")
            return
        }

        let fromDate = ""
        let toDate = ""
        if(dateRange[0] != null) fromDate = dateRange[0].format(DATEFORMAT)
        if(dateRange[1] != null) toDate = dateRange[1].format(DATEFORMAT)

        if(printPromptCount < MAXPRINTPROMPT) {
            confirm({
                icon: <InfoCircleOutlined />,
                content: <Space><p>You may need to manually adjust the position and scale of batch genealogy chart image in order to fit into the pdf file.</p></Space>,
                onOk() { generateBatchGenealogyPdf(ref.current.toDataURL(), fromDate, toDate, site, marineLife, species, batch) },
                onCancel() {},
                centered: true
            })

            setPrintPromptCount(printPromptCount + 1)
        }
        else
            generateBatchGenealogyPdf(ref.current.toDataURL(), fromDate, toDate, site, marineLife, species, batch)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getSpecies(0)
        getBatch(0)
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search Batch Genealogy" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range">
                                <DateRangeInput 
                                    from={dateRange[0]} 
                                    //disabledFromDate={d => !d || d.isSameOrBefore(moment().add(1, "days").format(UNIDATEFORMAT))} 
                                    //disabledToDate={d => !d || d.isSameOrAfter(moment().add(1, "days").format(UNIDATEFORMAT))} 
                                    onChange={onDateRangeChange}/>
                            </Form.Item>

                            <CommonSearchFormItem onSiteChange={onSearchSiteChange} onMarineLifeChange={onMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} 
                                onBatchChange={onSearchBatchChange}/>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton} isLoading={isLoading}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={onPrint} disabled={disablePrint} isLoading={isLoading} icon={<PrinterOutlined />}/>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form> 
                    </Panel>
                </Collapse>
            </Col>
        </Row>
        </Spin>

        <Space> </Space>

        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={24} xl={24}>
                {fundFlowChart}
            </Col>
        </Row>
        </>
    )
}

export default BatchGenealogyBySpeciesTable