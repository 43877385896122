import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, Tooltip, message, Pagination, Spin } from 'antd'
import { PlusOutlined, InfoCircleOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT,  LOADING,  MEDIAMAXWIDTH,  PAGESIZE,  PAGINATIONSIZE,  UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, showRemark, stringNumberSorter } from '../Common/Utility'
import { formLayout_2Columns } from '../Common/Layout'
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { RangePicker } = DatePicker

//----------
// Component
//----------
const MortalityTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [aquacultureStageId, setAquacultureStageId] = useState(0)
    const [aquacultureStage, setAquacultureStage] = useState("")
    const [lifecycleId, setLifecycleId] = useState(0)
    const [lifecycle, setLifecycle] = useState("")
    const [itemTypeId, setItemTypeId] = useState(0)
    const [itemType, setItemType] = useState("")
    const [tagId, setTagId] = useState(0)
    const [tag, setTag] = useState("")
    const navigate = useNavigate()
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    
    const [mortalityDataSource, setMortalityDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //-----------------
    // Search mortality
    //-----------------
    const searchMortality = (currentPage, urlParamSearchStoragId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}mortality/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: urlParamSearchStoragId != 0 ? urlParamSearchStoragId : storageId,
                marine_life: marineLifeId,
                species: speciesId,
                item_type: itemTypeId,
                tag_id: tagId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search mortality list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( mortality => {
            data.push({
                key: mortality.pKey,
                id: mortality.id,
                batch: mortality.batch_data.id,
                batchId: mortality.batch_data.pKey,
                batchType: mortality.batch_data.batch_type_data.name,
                storage: mortality.storage_data.name,
                storageId: mortality.storage_data.pKey,
                
                itemTypeId: mortality.item_type_data.pKey,
                itemType: mortality.item_type_data.name,
                itemTypeUom: `${mortality.item_type_data.name} (${mortality.uom2_data.uom})`,
                //itemTypeUomId: mortality.item_type_uom.pKey,
                                
                uom: mortality.uom_data.uom,
                uomId: mortality.uom_data.pKey,
                quantity: numberWithCommas(mortality.quantity),

                uom2: mortality.uom2_data.uom,
                uomId2: mortality.uom2_data.pKey,
                quantity2: numberWithCommas(mortality.quantity2),

                //storageTotalQuantity: mortality.storage_total_quantity,
                percentage: mortality.storage_percentage,
                availableQuantity: mortality?.itemtypestockbalance.quantity != null ? mortality.itemtypestockbalance.quantity : 0,
                
                possibleReason: mortality.reason_data.name,
                mortalityReasonId: mortality.reason_data.pKey,
                mortalityDateTime: moment(mortality.mortality_datetime).format(DATETIMEFORMAT),
                mortalityDateTimeUnformatted: moment(mortality.mortality_datetime).format(UNIDATETIMEFORMAT),
                remark: mortality.remark,
                createdOn: moment(mortality.createdOn).format(DATETIMEFORMAT),
                updatedOn: moment(mortality.updatedOn).format(DATETIMEFORMAT),
                species: mortality.batch_data.species_data.short_name,
                lifecycle: mortality.lifecycle_data.name,
                aquacultureStage: mortality.aquaculture_stage_data.name,
                
                marineLife: mortality.batch_data.species_data.marine_life_data.name,
                marineLifeId: mortality.batch_data.species_data.marine_life_data.pKey,

                storageAverageWeightGram: mortality.storage_average_weight_gram
            })
        })
        
        setMortalityDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(aquacultureStageId != 0) searchCriteria = `${searchCriteria}\nAquaculture Stage: ${aquacultureStage}`
        if(lifecycleId != 0) searchCriteria = `${searchCriteria}\nLifecycle: ${lifecycle}`
        if(itemTypeId != 0) searchCriteria = `${searchCriteria}\nItem Type: ${itemType}`
        if(tagId != 0) searchCriteria = `${searchCriteria}\nTag ID: ${tag}`
                                
        axios.get(`${ACISAPIURL}mortality/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                marine_life: marineLifeId,
                species: speciesId,
                item_type: itemTypeId,
                tag_id: tagId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Mortality ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchMortality(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchMortality(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/mortalityupdate", 
                    search: `?pKey=${record.key}&id=${record.id}&batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&storageId=${record.storageId}&storage=${record.storage}&species=${record.species}&aquacultureStage=${record.aquacultureStage}&lifecycle=${record.lifecycle}&mortalityReasonId=${record.mortalityReasonId}&possibleReason=${record.possibleReason}&percentage=${record.percentage}&mortalityDateTime=${record.mortalityDateTimeUnformatted}&remark=${encodeURI(String(record.remark).trim())}&createdOn=${record.createdOn}&updatedOn=${record.updatedOn}&quantity=${record.quantity.replace(',', '')}&itemTypeId=${record.itemTypeId}&itemType=${record.itemType}&uom=${record.uom}&uomId=${record.uomId}&availableQuantity=${record.availableQuantity}&quantity2=${record.quantity2.replace(',', '')}&uom2=${record.uom2}&marineLifeId=${record.marineLifeId}&storageAverageWeight=${record.storageAverageWeightGram}`
                }) 
            }
        }
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onSearchMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onSearchAquacultureStageChange = (e, value) => {
        setAquacultureStageId(e)
        setAquacultureStage(value.children)
    }

    const onSearchLifecycleChange = (e, value) => {
        setLifecycleId(e)
        setLifecycle(value.children)
    }

    const onSearchItemTypeChange = (e, value) => {
        setItemTypeId(e)
        setItemType(value.children)
    }

    const onSearchTagIdChange = (e, value) => {
        setTagId(e)
        setTag(value.children)
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/mortality" })
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchMortality(currentPage, urlParams.get("storageId"))
        }
        else
            searchMortality(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    let desktopColumn1 = [
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
    ]

    let desktopColumn2 = [
        { title: 'Possible Reason', dataIndex: 'possibleReason', key: 'possibleReason', sorter: (a, b) => a.possibleReason.localeCompare(b.possibleReason) },
    ]

    if(isTabletOrMobile) {
        desktopColumn1 = []
        desktopColumn2 = []
    }

    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        ...desktopColumn1,
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Item Type', dataIndex: 'itemType', key: 'itemType', sorter: (a, b) => a.itemType.localeCompare(b.itemType) },
        { title: 'Mortality Qty.', dataIndex: 'quantity2', key: 'quantity2', align: "right", sorter: (a, b) => stringNumberSorter(a.quantity2, b.quantity2) },
        { title: 'UoM', dataIndex: 'uom2', key: 'uom2', sorter: (a, b) => a.uom.localeCompare(b.uom) },
        { title: '%', dataIndex: 'percentage', key: 'percentage', align: "right", sorter: (a, b) => a.percentage - b.percentage },
        ...desktopColumn2,
        { title: 'Mortality Date Time', dataIndex: 'mortalityDateTime', key: 'mortalityDateTime', sorter: (a, b) => a.mortalityDateTime.localeCompare(b.mortalityDateTime) },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', align: 'center', //sorter: (a, b) => a.length - b.length 
            render: (remark) => {
                if(remark.length > 0)
                    return <Tooltip title={remark} >
                            <div onClick={e => showRemark(e, "Remark", remark)}><InfoCircleOutlined style={{ fontSize: '20px', color: "blue"}}/></div>
                        </Tooltip>
                else
                    return null
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Mortality Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onSearchMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} onTagIdChange={onSearchTagIdChange} onAquacultureStageChange={onSearchAquacultureStageChange}
                                onLifecycleChange={onSearchLifecycleChange} onItemTypeChange={onSearchItemTypeChange} showLockedStorage={false}
                                defaultStorageId={storageId} mortalityTaggedOnly={true} showActiveBatchSwitch={true}/>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={mortalityDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default MortalityTable