import { useState } from 'react'
import { Form, Button, message, Row, Col, Spin } from 'antd'
import { SaveOutlined } from "@ant-design/icons"
import { formLayout } from '../Common/Layout'
import axios from 'axios'
import { ACISAPIURL, LOADING, MENUPATH_INVENTORYFORECAST } from '../Common/SystemParameter'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { reportError } from '../Common/Utility'
import { useNavigate } from 'react-router-dom'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const InventoryForecastingTable = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()    
    const [disableButton, setDisableButton] = useState("")
    const [batchId, setBatchId] = useState(0)
    const [storageId, setStorageId] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
        
    // On save
    const onSave = () => {
        if(batchId == 0) {
            message.info("Please select a batch before proceed.")
            return
        }
        
        if(storageId == 0) {
            message.info("Please select a storage before proceed.")
            return
        }

        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.post(`${ACISAPIURL}inventory/forecast/create/`, {
            batch: batchId,
            storage: storageId,
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.forecast_record_count > 1)
                message.info(`Inventory forecasting completed.`)
            else
                message.warn(`Inventory forecasting returns no result.`)
        })
        .catch( error => {
            reportError(error, "Failed to forecast inventory.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
    }

    //-------------------------
    // Go to inventory forecast
    //-------------------------
    const goToQuery = () => {
        navigate({
            pathname: MENUPATH_INVENTORYFORECAST
        })
    }

    //----------------------
    // On search batch change
    //----------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
    }

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form form={form} onFinish={onSave} {...formLayout}>
            <CommonSearchFormItem onBatchChange={onSearchBatchChange} onStorageChange={onSearchStorageChange} showLockedStorage={false}/>

            <Row justify="center">
                <Col span={24} style={{textAlign: "center"}}>
                    <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Generate Forecast</Button>
                    <Button type="primary" htmlType="button" onClick={goToQuery} disabled={disableButton} >View Forecasted Data</Button>
                </Col>
            </Row>
        </Form>
        </Spin>
        </>
    )
}

export default InventoryForecastingTable