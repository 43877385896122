import { Menu } from 'antd'
import { ProfileOutlined, DesktopOutlined, DatabaseOutlined, SettingOutlined, } from '@ant-design/icons'
import { MENUKEY_BATCH, 
    MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE, MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE, 
    MENUKEY_EGGDEV, MENUKEY_ENVIRONMENT, MENUKEY_FEEDING, MENUKEY_FISH, MENUKEY_GROWTHDEV, MENUKEY_HATCHING, MENUKEY_MORTALITY, MENUKEY_NEWTAG, 
    MENUKEY_PHYSICALTAGGING, 
    MENUKEY_SPAWNING, MENUKEY_STOCKCOUNT, MENUKEY_STOCKTRANSACTION, MENUKEY_STORAGE, MENUKEY_TRANSFERCOLLECTIVEFISHSOURCE, MENUKEY_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE, MENUPATH_BATCH, 
    MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE, MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE, MENUPATH_EGGDEV, 
    MENUPATH_ENVIRONMENT, MENUPATH_FEEDING, MENUPATH_FISH, MENUPATH_GROWTHDEV, MENUPATH_HATCHING, MENUPATH_MORTALITY, MENUPATH_NEWTAG, MENUPATH_PHYSICALTAGGING, MENUPATH_SPAWNING, 
    MENUPATH_STOCKCOUNT, 
    MENUPATH_STOCKTRANSACTION, 
    MENUPATH_STORAGE, MENUPATH_TRANSFERCOLLECTIVEFISHSOURCE, MENUPATH_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE, MENU_BATCH, 
    MENU_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE, MENU_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE, MENU_EGGDEV, MENU_ENVIRONMENT, MENU_FEEDING, 
    MENU_FISH, MENU_GROWTHDEV, MENU_HATCHING, MENU_MORTALITY, MENU_NEWTAG, MENU_PHYSICALTAGGING, MENU_SPAWNING, MENU_STOCKCOUNT, MENU_STOCKTRANSACTION, MENU_STORAGE, MENU_TRANSFERCOLLECTIVEFISHSOURCE, 
    MENU_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE } from './SystemParameter'


const { SubMenu } = Menu

export const inComponentSubMenuPath = (menuKey) => {
    let pathname = ""
    let destination = ""
    let searchParams = ""

    switch(parseInt(menuKey)) {
        case MENUKEY_EGGDEV:
            pathname = MENUPATH_EGGDEV
            destination = MENU_EGGDEV
            break
        case MENUKEY_SPAWNING:
            pathname = MENUPATH_SPAWNING
            destination = MENU_SPAWNING
            break
        case MENUKEY_HATCHING:
            pathname = MENUPATH_HATCHING
            destination = MENU_HATCHING
            break
        case MENUKEY_FEEDING:
            pathname = MENUPATH_FEEDING
            destination = MENU_FEEDING
            break
        case MENUKEY_GROWTHDEV:
            pathname = MENUPATH_GROWTHDEV
            destination = MENU_GROWTHDEV
            break
        case MENUKEY_ENVIRONMENT:
            pathname = MENUPATH_ENVIRONMENT
            destination = MENU_ENVIRONMENT
            break
        case MENUKEY_MORTALITY:
            pathname = MENUPATH_MORTALITY
            destination = MENU_MORTALITY
            break
        case MENUKEY_NEWTAG:
            pathname = MENUPATH_NEWTAG
            destination = MENU_NEWTAG
            break
        case MENUKEY_STOCKCOUNT:
            pathname = MENUPATH_STOCKCOUNT
            destination = MENU_STOCKCOUNT
            break

        case MENUKEY_STOCKTRANSACTION:
            pathname = MENUPATH_STOCKTRANSACTION
            destination = MENU_STOCKTRANSACTION
            break

        case MENUKEY_TRANSFERCOLLECTIVEFISHSOURCE:
            pathname = MENUPATH_TRANSFERCOLLECTIVEFISHSOURCE
            destination = MENU_TRANSFERCOLLECTIVEFISHSOURCE
            searchParams = `&interSite=false`
            break
        case MENUKEY_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE:
            pathname = MENUPATH_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE
            destination = MENU_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE
            searchParams = `&interSite=true`
            break
        case MENUKEY_STORAGE:
            pathname = MENUPATH_STORAGE
            destination = MENU_STORAGE
            break
        case MENUKEY_BATCH:
            pathname = MENUPATH_BATCH
            destination = MENU_BATCH
            break
        case MENUKEY_FISH:
            pathname = MENUPATH_FISH
            destination = MENU_FISH
            break
        case MENUKEY_PHYSICALTAGGING:
            pathname = MENUPATH_PHYSICALTAGGING
            destination = MENU_PHYSICALTAGGING
            break

        case MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE:
            pathname = MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE
            destination = MENU_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE
            break
        case MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE:
            pathname = MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE
            destination = MENU_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE
            break
        
        default:
    }

    return [pathname, destination, searchParams]
}

//-----------------------
// Dropdown menu
//-----------------------
export const inComponentSubMenu = () => {
    return [
        <SubMenu key="aquacultureProcess" title="Aquaculture Process" icon={<DesktopOutlined />}>
            {window.location.pathname.startsWith(MENUPATH_EGGDEV) ? null : <Menu.Item key={MENUKEY_EGGDEV}>{MENU_EGGDEV}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_SPAWNING) ? null : <Menu.Item key={MENUKEY_SPAWNING}>{MENU_SPAWNING}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_HATCHING) ? null : <Menu.Item key={MENUKEY_HATCHING}>{MENU_HATCHING}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_FEEDING) ? null : <Menu.Item key={MENUKEY_FEEDING}>{MENU_FEEDING}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_GROWTHDEV) ? null : <Menu.Item key={MENUKEY_GROWTHDEV}>{MENU_GROWTHDEV}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_MORTALITY) ? null : <Menu.Item key={MENUKEY_MORTALITY}>{MENU_MORTALITY}</Menu.Item>}
        </SubMenu>,

        <SubMenu key="inventory" title="Inventory Process" icon={<DatabaseOutlined />}>
            {window.location.pathname.startsWith(MENUPATH_TRANSFERCOLLECTIVEFISHSOURCE) ? null : <Menu.Item key={MENUKEY_TRANSFERCOLLECTIVEFISHSOURCE}>{MENU_TRANSFERCOLLECTIVEFISHSOURCE}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE) ? null : <Menu.Item key={MENUKEY_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE}>{MENU_TRANSFERCOLLECTIVEFISHSOURCEINTERSITE}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE) ? null : <Menu.Item key={MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE}>{MENU_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE) ? null : <Menu.Item key={MENUKEY_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE}>{MENU_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_NEWTAG) ? null :<Menu.Item key={MENUKEY_NEWTAG}>{MENU_NEWTAG}</Menu.Item>}
            <Menu.Item key={MENUKEY_STOCKCOUNT}>{MENU_STOCKCOUNT}</Menu.Item>
        </SubMenu>,

        <SubMenu key="reportMenu" icon={<ProfileOutlined />} title="Report & Listing">
            <Menu.Item key={MENUKEY_STOCKTRANSACTION}>{MENU_STOCKTRANSACTION}</Menu.Item>
        </SubMenu>,
                    
        <SubMenu key="setup" title="Setup" icon={<SettingOutlined />}>
            {window.location.pathname.startsWith(MENUPATH_STORAGE) ? null :<Menu.Item key={MENUKEY_STORAGE}>{MENU_STORAGE}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_BATCH) ? null : <Menu.Item key={MENUKEY_BATCH}>{MENU_BATCH}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_FISH) ? null : <Menu.Item key={MENUKEY_FISH}>{MENU_FISH}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_PHYSICALTAGGING) ? null : <Menu.Item key={MENUKEY_PHYSICALTAGGING}>{MENU_PHYSICALTAGGING}</Menu.Item>}
            {window.location.pathname.startsWith(MENUPATH_ENVIRONMENT) ? null : <Menu.Item key={MENUKEY_ENVIRONMENT}>{MENU_ENVIRONMENT}</Menu.Item>}
        </SubMenu>
    ]
}
