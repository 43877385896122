import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Row, Col, Select, Modal, InputNumber, Popconfirm, Card, Typography, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons"
import { formLayout } from '../Common/Layout'
import axios from 'axios'
import { ACISAPIURL, DATETIMEFORMAT, LOADING } from '../Common/SystemParameter'
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { reportError } from '../Common/Utility'
import moment from 'moment'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { confirm } = Modal
const { Title } = Typography

const StandardGrowthUpdateTable = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [aquacultureStage, setAquacultureStage] = useState("")
    const [species, setSpecies] = useState("")
    const [active, setActive] = useState(false)
    const [activeColor, setActiveColor] = useState("red")
    const [numberOfRecord, setNumberOfRecord] = useState(0)
    const [createdOn, setCreatedOn] = useState(null)
    const [updatedOn, setUpdatedOn] = useState(null)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [aquacultureStageId, setAquacultureStageId] = useState(urlParams.get("aquacultureStageId"))
    const [lifecycleId, setLifecycleId] = useState(urlParams.get("lifecycleId"))

    //------------------------------
    // Update standard growth record
    //------------------------------
    const updateStandardGrowthRecord = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}standardgrowth/update/${urlParams.get("pKey")}/`, {
                site: getUserSiteId(),
                aquaculture_stage: aquacultureStageId,
                lifecycle: lifecycleId,
                day: values.day,
                average_per_unit_weight_gram: values.averagePerUnitWeightGram,
                average_per_unit_length_mm: values.averagePerUnitLengthMm,
                mortality_percentage: values.mortalityPercentage,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Standard growth record updated.")

                let params = new URLSearchParams(`?pKey=${urlParams.get("tablePKey")}`)
                if(urlParams.has("fromArchive")) params.append("fromArchive", true)

                navigate({ 
                    pathname: "/standardgrowth",
                    search: params.toString()
                })
            })
            .catch( error => {
                reportError(error, `Failed to update standard growth record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //------------------------------
    // Delete standard growth record
    //------------------------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}standardgrowth/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Standard growth record deleted.`)
            
            let params = new URLSearchParams(`?pKey=${urlParams.get("tablePKey")}`)
            if(urlParams.has("fromArchive")) params.append("fromArchive", true)

            navigate({ 
                pathname: "/standardgrowth",
                search: params.toString()
            })
        })
        .catch( error => {
            reportError(error, "Failed to delete standard growth record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update standard growth record confirmed?</p></Space>,
            onOk() { updateStandardGrowthRecord() },
            onCancel() {},
            centered: true
        })
    }

    //-------------------------
    // Get growth table
    //-------------------------
    const getGrowthTable = () => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}standardgrowth/table/${urlParams.get("tablePKey")}/`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            setSpecies(response.data.species_data.short_name)
            setNumberOfRecord(response.data.record_count)
            setActive(response.data.active ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO"))    
            setActiveColor(response.data.active ? "blue" : "red")
            setCreatedOn(moment(response.data.createdOn).format(DATETIMEFORMAT))
            setUpdatedOn(moment(response.data.updatedOn).format(DATETIMEFORMAT))
        })
        .catch( error => {
            reportError(error, "Failed to get standard growth table.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //----------------------------
    // On aquaculture state change
    //----------------------------
    const onAquacultureStageChange = (e, value) => {
        setAquacultureStageId(e)
        setAquacultureStage(value.children)
    }

    const onLifecycleChange = (e) => {
        setLifecycleId(e)
    }

    //---------------------------
    // On cancel
    //---------------------------
    const onCancel = () => {
        var params = new URLSearchParams(`?pKey=${urlParams.get("tablePKey")}`)

        if(urlParams.has("fromArchive")) params.append("fromArchive", true)

        navigate({ 
            pathname: "/standardgrowth",
            search: params.toString()
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getGrowthTable()

        form.setFieldsValue({
            aquacultureStage: urlParams.get("aquacultureStageId"),
            lifecycle: urlParams.get("lifecycleId"),
            day: urlParams.get("day"),
            averagePerUnitWeightGram: urlParams.get("averageWeight").replace(',', ''),
            averagePerUnitLengthMm: urlParams.get("averageLength").replace(',', ''),
            mortalityPercentage: urlParams.get("mortalityPercentage")
        })
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                    <Card title={<Title level={5}>{`Species: ${species}`}</Title>}>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">No. of Records In Table:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberOfRecord}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Active:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{color: activeColor}}>{active}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{createdOn}</Card.Grid>
                    
                        { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                            <>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{updatedOn}</Card.Grid>
                            </>
                        }
                    </Card>
            </Form.Item>
        </Form>

        <Form form={form} onFinish={onSave} {...formLayout}>
            {/* <Form.Item name="aquacultureStage" label="Fish Aquaculture Stage"
                rules={[
                    { required: true, message: "Fish aquaculture stage is required."},
                ]}>
                <Select onChange={onAquacultureStateChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {aquacultureStageOption}    
                </Select>
            </Form.Item>

            <Form.Item name="lifecycle" label="Lifecycle"
                rules={[
                    { required: true, message: "Lifecycle is required."},
                ]}>
                <Select allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {lifecycleOption}    
                </Select>
            </Form.Item> */}

            <CommonSearchFormItem onAquacultureStageChange={onAquacultureStageChange} onLifecycleChange={onLifecycleChange} 
                defaultSpeciesId={urlParams.get("speciesId")} formLayout={formLayout} defaultAquacultureStageId={urlParams.get("aquacultureStageId")}
                defaultLifecycleId={urlParams.get("lifecycleId")} />

            <Form.Item name="day" label="Day"
                rules={[
                    { required: true, message: "Day is required."},
                ]}>
                <InputNumber min={0} max={10000000} step={1} placeholder="Day"/>
            </Form.Item>

            <Form.Item name="averagePerUnitWeightGram" label="Average Per Unit Weight (g)"
                rules={[
                    { required: true, message: "Average per unit weight is required."},
                ]}>
                <InputNumber min={0} max={1000000} precision={2} step={1} placeholder="Average Per Unit Weight (g)"/>
            </Form.Item>

            <Form.Item name="averagePerUnitLengthMm" label="Average Per Unit Length (mm)"
                rules={[
                    { required: true, message: "Average per unit length is required."},
                ]}>
                <InputNumber min={0} max={1000000} precision={2} step={1} placeholder="Average Per Unit Length (mm)"/>
            </Form.Item>

            <Form.Item name="mortalityPercentage" label="Mortality Rate (%)"
                rules={[
                    { required: true, message: "Mortality is required."},
                ]}>
                <InputNumber min={0} max={100} precision={2} step={1} placeholder="Mortality Rate (%)"/>
            </Form.Item>

            <Row justify="center">
                <Col span={6}></Col>
                <Col span={12} style={{textAlign: "center"}}>
                    <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Update</Button>
                    <Popconfirm title="Delete standard growth record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                        <Button danger type="primary" htmlType="button" disabled={disableButton} icon={<DeleteOutlined />}>Delete</Button>
                    </Popconfirm>
                    <Button type="primary" htmlType="button" disabled={disableButton} onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                </Col>
                <Col span={6}></Col>
            </Row>
        </Form>
        </Spin>
        </>
    )
}

export default StandardGrowthUpdateTable