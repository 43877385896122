import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Row, Col, Typography, Popconfirm, Card, Modal, Table, Spin } from 'antd'
import { QuestionCircleOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons"
import { ACISAPIURL, LOADING, MEDIAMAXWIDTH, MENUPATH_STOCKCOUNT, PAGINATIONSIZE, TAGGED } from '../Common/SystemParameter'
import { loadMarineLifeItemTypeUnitOfMeasurementStandardTable, numberWithCommas, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import { useMediaQuery } from 'react-responsive'

const { Title } = Typography
const { confirm } = Modal

const StockCountTaggedFishTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [notSelectedRowKeys, setNotSelectedRowKeys] = useState([])
    const [notSelectedRows, setNotSelectedRows] = useState([])
    const [taggedFishBalance, setTaggedFishBalance] = useState(0)
    const [fishDataSource, setFishDataSource] = useState([])
    const [itemType, setItemType] = useState([])
    const [uom, setUom] = useState("")
                
    let prevSavedVarianceAdjustment = []
    let prevSavedSurplus = []

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    // Readonly means viewing a completed stock count. No changes is allowed.
    const [readOnly, setReadOnly] = useState(urlParams.get("completedOn") == "" ? false : true)
    
    //--------------------------------
    // Go to tagged fish surplus
    //--------------------------------
    const goToTaggedFishSurplus = () => {
        // 2021-08-21
        // Skip tagged fish surplus. The adjustment should be made before stock count. Go straight to summary.
        navigate({ 
            pathname: "/stockcountsummary", 
            search: `?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}&marineLifeId=${urlParams.get("marineLifeId")}`
        }) 

        return

        //-------------------------------------------------------
        // Load existing tagged fish surplus. Lift state up
        //-------------------------------------------------------
        const getTaggedFishSurplus = async () => {
            setDisableButton("disabled")
        
            return await axios.get(`${ACISAPIURL}stockcount/taggedfish/surplus/`, {
                params: { 
                    stock_count: urlParams.get("pKey"),
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                let data = []
                response.data.results.forEach( surplus => {
                    data.push({
                        fishPKey: surplus.fish,
                        fish: surplus.fish_data.id,
                        remark: surplus.remark,
                    })
                })
                prevSavedSurplus = data
            })
            .catch( error => {
                reportError(error, "Failed to get tagged item stock count surplus record.")
            })
            .finally(() => {
                setDisableButton("")
                refreshUserSession()
            })
        }
    
        // Go to next step tagged fish variance adjustment. 
        let params = new URLSearchParams(`?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}`)

        // Retrieve previously saved tagged fish surplus
        // Build surplus record and go to surplus page.
        getTaggedFishSurplus()
        .then(() => {
            prevSavedSurplus.forEach( surplus => {
                params.append("fishPKey", surplus.fishPKey)
                params.append("fish", surplus.fish)
                params.append("remark", surplus.remark)
            })

            navigate({ 
                pathname: "/stockcounttaggedfishsurplus", 
                search: params.toString()
            }) 
        })
    }

    //---------------------------------------
    // Load existing tagged fish stock count.
    //---------------------------------------
    const getTaggedFishStockCount = async () => {
        setDisableButton("disabled")
        setIsLoading(true)
    
        return await axios.get(`${ACISAPIURL}stockcount/taggedfish/`, {
            params: { 
                stock_count: urlParams.get("pKey"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.results.length == 0) return
                
            setSelectedRowKeys(response.data.results[0].present_fish.filter( fish => fish.is_present).map( fish => fish.fish ))
            setNotSelectedRowKeys(response.data.results[0].present_fish.filter( fish => !fish.is_present).map( fish => fish.fish ))
            setNotSelectedRows(response.data.results[0].present_fish.filter( fish => !fish.is_present).map( fish => ({ "key": fish.fish_data.pKey, "id": fish.fish_data.id }) ))
        })
        .catch( error => {
            reportError(error, "Failed to get tagged item stock count record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------------
    // Go to untagged fish stock count
    //--------------------------------
    const goToTaggedFishVarianceAdjustment = () => {
        //---------------------------------------
        // Load existing tagged fish variance adjustment.
        //---------------------------------------
        const getTaggedFishVarianceAdjustment = async () => {
            setDisableButton("disabled")
            setIsLoading(true)
        
            return await axios.get(`${ACISAPIURL}stockcount/taggedfish/varianceadjustment/`, {
                params: { 
                    stock_count: urlParams.get("pKey"),
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                // If unselected fish has previously saved variance adjustment record, push that record in.
                // Else, append as new tagged fish stock variance adjustment, without the remark and transaction type.
                let remainingTaggedFish = notSelectedRowKeys.filter( fishPKey => !response.data.results.includes(fishPKey) )
                
                remainingTaggedFish.forEach( remainingFish => {
                    let variance_adjustment = response.data.results.filter( adj => adj.fish == remainingFish )
                    
                    if(variance_adjustment.length != 0) 
                        prevSavedVarianceAdjustment.push({
                            fishPKey: remainingFish,
                            fish: variance_adjustment[0].fish_data.id,
                            remark: variance_adjustment[0].remark,
                            transactionType: variance_adjustment[0].transaction_type})
                    else {
                        prevSavedVarianceAdjustment.push({
                            fishPKey: remainingFish,
                            fish: notSelectedRows.filter( fish => fish.key == remainingFish)[0].id,
                            remark: "",
                            transactionType: ""})
                    }
                })
            })
            .catch( error => {
                reportError(error, "Failed to get tagged item variance adjustment record.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        }

        //-------------------------------------------------------
        // Go to next step tagged fish variance adjustment. 
        let params = new URLSearchParams(`?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}&marineLifeId=${urlParams.get("marineLifeId")}`)

        // Retrieve previously saved variance adjustment that belong to missing fish ids
        // Build variance adjustment record and go to variance adjustment page.
        getTaggedFishVarianceAdjustment()
        .then(() => {
            prevSavedVarianceAdjustment.forEach( adj => {
                params.append("fishPKey", adj.fishPKey)
                params.append("fish", adj.fish)
                params.append("remark", adj.remark)
                params.append("transactionType", adj.transactionType)
            })

            navigate({ 
                pathname: "/stockcounttaggedfishvarianceadjustment", 
                search: params.toString()
            }) 

            if(!readOnly) message.info(`Present tagged item stock count record saved.`)
        })
    }

    //-----------------------------------------------------
    // Delete untagged fish stock count variance adjustment
    //-----------------------------------------------------
    const noVarianceAdjustmentUntaggedFishStockCount = () => {
        if(readOnly) {
            goToTaggedFishSurplus()
            return
        }

        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.patch(`${ACISAPIURL}stockcount/taggedfish/novarianceadjustment/${urlParams.get("pKey")}/`, {
            stock_balance: taggedFishBalance,
            present_fish: selectedRowKeys,
            all_fish: fishDataSource.map( fish => fish.key)
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            goToTaggedFishSurplus()
        })
        .catch( error => {
            reportError(error, "Failed to save no variance adjustment tagged item stock count record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    //------------------
    // Save present fish 
    //------------------
    const savePresentFish = () => {
        if(readOnly) {
            if(taggedFishBalance > selectedRowKeys.length) 
                goToTaggedFishVarianceAdjustment()
            else // Tally
                noVarianceAdjustmentUntaggedFishStockCount() 

            return
        }

        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.post(`${ACISAPIURL}stockcount/taggedfish/create/`, {
            stock_count: urlParams.get("pKey"),
            stock_balance: taggedFishBalance,
            counted_balance: selectedRowKeys.length,
            present_fish: selectedRowKeys,
            all_fish: fishDataSource.map( fish => fish.key)
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(taggedFishBalance > selectedRowKeys.length) 
                goToTaggedFishVarianceAdjustment()
            else // Tally
                noVarianceAdjustmentUntaggedFishStockCount()
        })
        .catch( error => {
            reportError(error, "Failed to save tagged item stock count record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------------------
    // On finish
    //-----------------------
    const onNext = () => {
        if(readOnly) {
            if(fishDataSource.length == 0) 
                goToTaggedFishSurplus()
            else
                savePresentFish()

            return
        }

        if(fishDataSource.length == 0) 
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>No {itemType} found in storage {urlParams.get("storage")}. Redirect to tagged item surplus page now.</p></Space>,
                //onOk() { goToTaggedFishSurplus() },
                onOk() { noVarianceAdjustmentUntaggedFishStockCount() },
                okCancel: false,
                centered: true
            })
        else
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Save {itemType} stock count record and proceed to next step?</p></Space>,
                onOk() { savePresentFish() },
                onCancel() {},
                centered: true
            })
    }

    //---------------
    // Search fish
    //---------------
    const searchFish = async () => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        return await axios.get(`${ACISAPIURL}stockcount/taggedfish/full/`, {
            params: { 
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                active: true,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            // No tagged fish in this storage
            if(response.data.results.length == 0 && !readOnly) {
                /*confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>No tagged marine life found in storage {urlParams.get("storage")}.</p></Space>,
                    onOk() {},
                    okCancel: false,
                    centered: true
                })*/
                navigate({ 
                    pathname: "/stockcountsummary", 
                    search: `?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}&marineLifeId=${urlParams.get("marineLifeId")}`
                }) 
                return
            }

            setTaggedFishBalance(response.data.results.length)
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search item list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( fish => {
            data.push({
                key: fish.pKey,
                id: fish.id,
            })
        })
        
        setFishDataSource(data)

        // 20221111
        // Beware. This is to add firh to not selected before this page has ever been saved.
        // After this page is saved, the next time this page is being visited, data will be retrieved from taggedfish variance adjustment table.
        setNotSelectedRowKeys(data.map( fish => fish.key ))
        setNotSelectedRows(data.map( fish => ({ "key": fish.key, "id": fish.id }) ))
    }

    //---------------
    // Search fish
    //---------------
    const searchFishSnapshot = async () => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        return await axios.get(`${ACISAPIURL}stockcount/taggedfish/snapshot/`, {
            params: { 
                stock_count: urlParams.get("pKey"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.results.length == 0) {
                navigate({ 
                    pathname: "/stockcountsummary", 
                    search: `?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}&marineLifeId=${urlParams.get("marineLifeId")}`
                }) 
                return
            }

            setTaggedFishBalance(response.data.results.length)
            dataPushSnapshot(response)
        })
        .catch( error => {
            reportError(error, "Failed to search item snapshot.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPushSnapshot = (response) => {
        const data = []
        const presentFishData = []
        const notPresentFishData = []
        
        response.data.results.forEach( snapshot => {
            data.push({
                key: snapshot.fish,
                id: snapshot.fish_data.id,
            })

            if(snapshot.is_present)
                presentFishData.push(snapshot.fish)
            else
                notPresentFishData.push(snapshot.fish)
        })
        
        setFishDataSource(data)
                
        setSelectedRowKeys(presentFishData)
        setNotSelectedRowKeys(notPresentFishData)
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({
            pathname: MENUPATH_STOCKCOUNT,
        })
    }

    //---------------------------
    // On fish selection change
    //---------------------------
    const onSelectionChange = (selectedRowKeys, selectedRows) => {
        if(readOnly) return

        setSelectedRowKeys(selectedRowKeys)
        //setSelectedRows(selectedRows)

        setNotSelectedRowKeys(fishDataSource.filter( fish => !selectedRowKeys.includes(fish.key) ).map( fish => fish.key))
        setNotSelectedRows(fishDataSource.filter( fish => !selectedRowKeys.includes(fish.key) ))    
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectionChange
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            const standardITUOMTable = await loadMarineLifeItemTypeUnitOfMeasurementStandardTable()
            const ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == urlParams.get("marineLifeId") && row.item_type_data.name.startsWith(TAGGED))
            setItemType(ituom[0].item_type_data.name)
            setUom(ituom[0].uom_data.uom)
            
            if(readOnly)
                searchFishSnapshot()
            else
                searchFish()
                .then(() => {
                    getTaggedFishStockCount()
                })
        }

        init()
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Tag ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Stock Count Storage ID: ${urlParams.get("storage")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Initialised On:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
                    { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                        </>
                    }
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">{`${itemType} Stock Balance (${uom}):`}:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{color: "blue"}}>{numberWithCommas(taggedFishBalance)}</Card.Grid>
                    { urlParams.get("completedOn") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Completed On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("completedOn")}</Card.Grid>
                        </>
                    }
                </Card>
            </Form.Item>
        </Form>

        <Row justify="center">
            <Col span={isTabletOrMobile ? 10 : 8} style={{textAlign: "center"}}>
                <Card title={`${itemType} Checklist`} style={{textAlign: "left"}}>
                    Check those tagged item that are present. 
                </Card>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={isTabletOrMobile ? 10 : 8} style={{textAlign: "center"}}>
                <Table bordered columns={columns} dataSource={fishDataSource} rowSelection={rowSelection} pagination={{ size: PAGINATIONSIZE }} />
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Form form={form}>
            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={10} style={{textAlign: "start"}}>
                    <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={4} />
                <Col span={10} style={{textAlign: "end"}}>
                    <Button type="primary" htmlType="button" onClick={onNext} disabled={disableButton} icon={<RightOutlined />}>Next</Button>
                </Col>
            </Row>
        </Form>
        </Spin>
        </>
    )
}

export default StockCountTaggedFishTable
