import  { useEffect, useState } from 'react'
import { Button, Col, Row, Space, Table, Tooltip, Modal, message, Upload, Card, Popconfirm, Pagination, Spin } from "antd"
import { PlusOutlined, ImportOutlined, ExportOutlined, UploadOutlined, InfoCircleOutlined, QuestionCircleOutlined, DatabaseOutlined, DownSquareOutlined } from "@ant-design/icons"
import axios from 'axios'
import { ACISAPIURL, DATETIMEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT_FILE } from '../Common/SystemParameter'
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { numberWithCommas, reportError, stringNumberSorter } from '../Common/Utility'
import { useNavigate } from 'react-router-dom'
import BatchSelect from '../Common/BatchSelect'
import moment from 'moment'

const { confirm } = Modal

const StandardGrowthTableTable = () => {
    const navigate = useNavigate()
    const FileDownload = require('js-file-download')
    
    const [disableButton, setDisableButton] = useState("")
    
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
        
    const [growthRecordDataSource, setGrowthRecordDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    let modal = null

    //------------------------------
    // Create standard growth record
    //------------------------------
    const importGrowthDevelopmentAndMortalityRecord = async (selectedBatchId) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        let modal = confirm({
            icon: <InfoCircleOutlined />,
            title: <Space><p>Importing grwoth development and mortality record. Please wait...</p></Space>,
            okButtonProps: {style: {display: "none"} },
            cancelButtonProps: {style: {display: "none"} },
            centered: true,
        })

        return await axios.post(`${ACISAPIURL}standardgrowth/import/`, {
            batch: selectedBatchId,
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Growth develoment and mortality record imported.</p></Space>,
                onOk() { window.location.reload() },
                cancelButtonProps: {style: {display: "none"} },
                centered: true
            })
        })
        .catch( error => {
            reportError(error, `Failed to import growth development and mortality record.`)
        })
        .finally(() => {
            modal.destroy()
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------------
    // Export growth record
    //-------------------------
    const exportStandardGrowthRecordToExcel = () => {
        setDisableButton("disabled")
        setIsLoading(true)

        // Filter zero record species.
        for(let species of selectedRows) {
            if(species.numberOfRecord == 0) {
                message.warn(`${species.species} has zero record and cannot be exported.`)
                setDisableButton("")
                setIsLoading(false)
                return
            }
        }
        
        axios.get(`${ACISAPIURL}standardgrowth/table/download/`, {
            params: { 
                standard_growth_table: selectedRowKeys.toString(),
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let species = selectedRows.map( row => ` ${row.species}`).toString().replace(',', '')
            let now = moment().format(UNIDATETIMEFORMAT_FILE)
        
            FileDownload(response.data, `Standard Growth ${species} ${now}.xlsx`)
        })
        .catch( error => {
            reportError(error, "Failed to export standard growth record to excel file.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //------------------------------
    // Archive standard growth table
    //------------------------------
    const archiveStandardGrowthTable = (e, record) => {
        e.stopPropagation()
        setIsLoading(true)

        axios.patch(`${ACISAPIURL}standardgrowth/table/archive/`, {
            standard_growth_table: record.key, 
            active: false
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Standard growth table archived.")
            window.location.reload()
        })
        .catch( error => {
            reportError(error, `Failed to archive standard growth table.`)
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------------
    // Get latest growth record
    //-------------------------
    const getGrowthTable = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}standardgrowth/table/`, {
            params: { 
                site: getUserSiteId(),
                active: true,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            pushData(response)
        })
        .catch( error => {
            reportError(error, "Failed to search standard growth record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const pushData = (response) => {
        const data = []
        response.data.results.forEach( table => {
            data.push({
                key: table.pKey,
                species: table.species_data.short_name,
                speciesId: table.species_data.pKey,
                numberOfRecord: numberWithCommas(table.record_count),
                createdOn: moment(table.createdOn).format(DATETIMEFORMAT),
                updatedOn: table.createdOn != table.updatedOn ? moment(table.updatedOn).format(DATETIMEFORMAT) : "",
            })
        })
        
        setGrowthRecordDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/standardgrowthtablenew" })
    }

    //------------------
    // On batch change
    //------------------
    const onBatchChange = (e, value) => {
        confirm({
            icon: <QuestionCircleOutlined />,
            title: <Space><p>Import growth development and mortality record from batch {value.children} confirmed?</p></Space>,
            onOk() { 
                modal.destroy()
                importGrowthDevelopmentAndMortalityRecord(e) 
            },
            onCancel() { 
                modal.destroy() 
            },
            centered: true,
        })
    }

    //---------------------------------------------
    // Import from growth development and mortality
    // KEEP!
    //----------------------------------------------
    const onImportFromGrowthDevelopment = () => {
        const batchSelect = <BatchSelect withBlank={false} active={true} site={getUserSiteId()} onChange={onBatchChange}/>

        modal = confirm({
            icon: <InfoCircleOutlined />,
            title: <Space><p>Please select a batch which its growth development and mortality record will be used as the standard growth table record.</p></Space>,
            width: 800,
            okButtonProps: {style: {display: "none"} },
            cancelText: "Close",
            onCancel() {},
            centered: true,
            content: batchSelect
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        getGrowthTable(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //-------------
    // On export
    //-------------
    const onExport = () => {
        if(selectedRowKeys.length == 0) {
            message.info("Please select standard growth table before proceed.")
            return
        }

        exportStandardGrowthRecordToExcel()
    }

    //---------------------------
    // On selection change
    //---------------------------
    const onSelectionChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys)
        setSelectedRows(selectedRows)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectionChange
    }

    //-----------------
    // On view archived
    //-----------------
    const onViewArchived = () => {
        navigate({ pathname: "/standardgrowthtablearchive" })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/standardgrowth", 
                    search: `?pKey=${record.key}`
                }) 
            }
        }
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getGrowthTable(currentPage)
    }, [])

    //-----------------------------
    // Upload excel file validation
    //-----------------------------
    const props = {
        name: 'excel_file',
        action: `${ACISAPIURL}standardgrowth/upload/${getUserSiteId()}/`,
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        beforeUpload: file => {
            if (file.type !== 'application/vnd.ms-excel' && 
                file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") 
                message.error(`${file.name} is not an excel file`)

            return (file.type === 'application/vnd.ms-excel' || 
                file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")? true : Upload.LIST_IGNORE
        },
        onChange(info) {
            if(info.file.status !== 'uploading') 
                setIsLoading(true)

            if(info.file.status === 'done') {
                setIsLoading(false)

                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>"{info.file.name}"" uploaded successfully.</p></Space>,
                    onOk() { window.location.reload() },
                    cancelButtonProps: {style: {display: "none"} },
                    centered: true
                })
            }
            else if(info.file.status === 'error') {
                message.error(`"${info.file.name}" upload failed.`)
                message.error(info.file.response?.detail)
            }
        },
    }

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Number of Records', dataIndex: 'numberOfRecord', key: 'numberOfRecord', align: "right", sorter: (a, b) => stringNumberSorter(a.numberOfRecord, b.numberOfRecord) },
        { title: 'Created On', dataIndex: 'createdOn', key: 'createdOn', sorter: (a, b) => a.createdOn.localeCompare(b.createdOn) },
        { title: 'Updated On', dataIndex: 'updatedOn', key: 'updatedOn', sorter: (a, b) => a.createdOn.localeCompare(b.createdOn) },
        { key: 'action', align: 'center',
            render: (record) => {
                return <>
                    <Popconfirm title="Archive this standard growth table confirmed?" 
                        onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => archiveStandardGrowthTable(e, record)} 
                        okText="Yes" cancelText="No">
                        <Tooltip title="Archive" >
                            <Button type="primary" disabled={disableButton} icon={<DownSquareOutlined/>} />
                        </Tooltip>    
                    </Popconfirm>
                    </>
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row justify="center">
            <Col span={8} style={{textAlign: "center"}}>
                <Card title="Standard Growth Table" style={{textAlign: "left"}}>
                    Currently active standard growth table of each species.
                </Card>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={12}>
                <Button type="primary" htmlType="button" onClick={onExport} icon={<ExportOutlined />} disabled={disableButton}>
                    <Tooltip title="Export standard growth table to excel file.">
                        Export
                    </Tooltip>        
                </Button>
            </Col>
            <Col span={12} style={{textAlign: "end"}}>
                <Row justify="end">
                    <Button type="primary" htmlType="button" onClick={onViewArchived} icon={<DatabaseOutlined />} disabled={disableButton}>View Archived</Button>
                    <Upload {...props} accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                        <Button type="primary" htmlType="button" icon={<UploadOutlined />} disabled={disableButton} loading={isLoading}>
                            <Tooltip title="Upload new standard growth table excel file.">
                                Upload Excel
                            </Tooltip>
                        </Button>
                    </Upload>
                    <Button hidden type="primary" htmlType="button" onClick={onImportFromGrowthDevelopment} icon={<ImportOutlined />} loading={isLoading} disabled={disableButton}>
                        <Tooltip title="Import record from growth development and mortality tables">
                            Import
                        </Tooltip>
                    </Button>
                    <Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />} disabled={disableButton}>New</Button>
                </Row>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={growthRecordDataSource} pagination={false} rowSelection={rowSelection} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={9}>
                <Button type="primary" htmlType="button" onClick={onExport} icon={<ExportOutlined />} disabled={disableButton}>
                    <Tooltip title="Export standard growth table to excel file.">
                        Export
                    </Tooltip>        
                </Button>
            </Col>
            <Col span={6} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={9} style={{textAlign: "end"}}>
                <Row justify="end">
                    <Button type="primary" htmlType="button" onClick={onViewArchived} icon={<DatabaseOutlined />} disabled={disableButton}>View Archived</Button>
                    <Upload {...props} accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                        <Button type="primary" htmlType="button" icon={<UploadOutlined />} disabled={disableButton} loading={isLoading}>
                            <Tooltip title="Upload new standard growth table excel file.">
                                Upload Excel
                            </Tooltip>
                        </Button>
                    </Upload>
                    <Button hidden type="primary" htmlType="button" onClick={onImportFromGrowthDevelopment} icon={<ImportOutlined />} loading={isLoading} disabled={disableButton}>
                        <Tooltip title="Import record from growth development and mortality tables.">
                            Import
                        </Tooltip>
                    </Button>
                    <Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />} disabled={disableButton}>New</Button>
                </Row>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default StandardGrowthTableTable