import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, message, Pagination, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import FeedingTypeSelect from './FeedingTypeSelect'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { RangePicker } = DatePicker

//----------
// Component
//----------
const FeedingTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    //const [siteId, setSiteId] = useState(0)
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [aquacultureStageId, setAquacultureStageId] = useState(0)
    const [aquacultureStage, setAquacultureStage] = useState("")
    const [lifecycleId, setLifecycleId] = useState(0)
    const [lifecycle, setLifecycle] = useState("")
    const [feedingTypeId, setFeedingTypeId] = useState(0)
    const [feedingType, setFeedingType] = useState("")
    const navigate = useNavigate()
    const [dateRange, setDateRange] = useState([moment(moment().subtract(1, "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [isLoading, setIsLoading] = useState(false)
        
    const [feedingDataSource, setFeedingDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------
    // Search feeding
    //---------------
    const searchFeeding = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}feeding/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : storageId,
                aquaculture_stage: aquacultureStageId,
                lifecycle: lifecycleId,
                marine_life: marineLifeId,
                species: speciesId,
                feeding_type: feedingTypeId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search feeding record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( feeding => {
            data.push({
                key: feeding.pKey,
                id: feeding.id,
                batch: feeding.batch_data.id,
                storage: feeding.storage_data.name,
                feedingQuantity: numberWithCommas(feeding.feeding_quantity),
                fishQuantity: feeding.fish_quantity,
                feedingType: feeding.feeding_type_data.name,
                feedingTypeId: feeding.feeding_type_data.pKey,
                feedingUom: feeding.uom_data.uom,
                fedOn: moment(feeding.fedOn).format(DATETIMEFORMAT),
                fedOnUnformatted: moment(feeding.fedOn).format(UNIDATETIMEFORMAT),
                                                
                batchId: feeding.batch_data.pKey,
                batchType: feeding.batch_data.batch_type_data.name,
                species: feeding.batch_data.species_data.short_name,
                storageId: feeding.storage_data.pKey,
                acquiringMethod: feeding.batch_data.acquiring_method_data.name,
                aquacultureStageId: feeding.aquaculture_stage_data.pKey,
                aquacultureStage: feeding.aquaculture_stage_data.name,
                lifecycleId: feeding.lifecycle_data.pKey,
                lifecycle: feeding.lifecycle_data.name,
                aquacultureStage: feeding.aquaculture_stage_data.name,
                createdOn: moment(feeding.createdOn).format(DATETIMEFORMAT),
                updatedOn: moment(feeding.updatedOn).format(DATETIMEFORMAT),

                marineLifeId: feeding.marine_life_data.pKey
            })
        })
        
        setFeedingDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(aquacultureStageId != 0) searchCriteria = `${searchCriteria}\nAquaculture Stage: ${aquacultureStage}`
        if(lifecycleId != 0) searchCriteria = `${searchCriteria}\nLifecycle: ${lifecycle}`
        if(feedingTypeId != 0) searchCriteria = `${searchCriteria}\nFeeding Type: ${feedingType}`
                        
        axios.get(`${ACISAPIURL}feeding/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                aquaculture_stage: aquacultureStageId,
                lifecycle: lifecycleId,
                marine_life: marineLifeId,
                species: speciesId,
                feeding_type: feedingTypeId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Feeding ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFeeding(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchFeeding(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/feedingstorage" })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/feedingupdate", 
                    search: `?pKey=${record.key}&id=${record.id}&batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&species=${record.species}&storageId=${record.storageId}&storage=${record.storage}&acquiringMethod=${record.acquiringMethod}&aquacultureStage=${record.aquacultureStage}&lifecycle=${record.lifecycle}&feedingTypeId=${record.feedingTypeId}&feedingType=${record.feedingType}&feedingQuantity=${record.feedingQuantity.replace(',','')}&fishQuantity=${record.fishQuantity}&fedOn=${record.fedOnUnformatted}&createdOn=${record.createdOn}&updatedOn=${record.updatedOn}&uom=${record.feedingUom}&marineLifeId=${record.marineLifeId}`
                }) 
            }
        }
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onSearchAquacultureStageChange = (e, value) => {
        setAquacultureStageId(e)
        setAquacultureStage(value.children)
    }

    const onSearchLifecycleChange = (e, value) => {
        setLifecycleId(e)
        setLifecycle(value.children)
    }

    const onSearchFeedintTypeChange = (e, value) => {
        setFeedingTypeId(e)
        setFeedingType(value.children)
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchFeeding(currentPage, urlParams.get("storageId"))
        }
        else
            searchFeeding(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        // { title: 'Feeding ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Feeding Type', dataIndex: 'feedingType', key: 'feedingType', sorter: (a, b) => a.feedingType.localeCompare(b.feedingType) },
        { title: 'Quantity', dataIndex: 'feedingQuantity', key: 'feedingQuantity', align: "right", sorter: (a, b) => stringNumberSorter(a.feedingQuantity, b.feedingQuantity) },
        { title: 'Unit of Measurement', dataIndex: 'feedingUom', key: 'feedingUom', sorter: (a, b) => a.feedingUom.localeCompare(b.feedingUom) },
        { title: 'Feeding Date Time', dataIndex: 'fedOn', key: 'fedOn', sorter: (a, b) => a.fedOn.localeCompare(b.fedOn) },
        { title: 'Created On', dataIndex: 'createdOn', key: 'createdOn', sorter: (a, b) => a.fedOn.localeCompare(b.fedOn) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Feeding Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} onAquacultureStageChange={onSearchAquacultureStageChange}
                                onLifecycleChange={onSearchLifecycleChange} showLockedStorage={false} defaultStorageId={storageId} showActiveBatchSwitch={true}/>

                            <Form.Item name="feedingTypeId" label="Feeding Type">
                                <FeedingTypeSelect withBlank={true} onChange={onSearchFeedintTypeChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={feedingDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default FeedingTable