import { Table, Row, Col, Space, Button, Form, Collapse, message, Modal, Pagination, Spin } from 'antd'
import { QuestionCircleOutlined, ProfileOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, LOADING, MEDIAMAXWIDTH, MENUPATH_STOCKTRANSACTION, PAGESIZE, PAGINATIONSIZE } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { reportError } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import AcquiringMethodSelect from '../Common/AcquiringMethodSelect'
import BatchTypeSelect from '../Common/BatchTypeSelect'
import { showStorageStockSummary } from '../Common/showStorageStockSummary'
import { useMediaQuery } from 'react-responsive'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { confirm } = Modal

//----------
// Component
//----------
const CollectiveFishStockTransactionDestinationStorageTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [disableButton, setDisableButton] = useState("")
    const [searchForm] = Form.useForm()
    const navigate = useNavigate()
        
    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isSearching, setIsSearching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    const [searchSiteId, setSearchSiteId] = useState(getUserSiteId())
    const [searchStorageId, setSearchStorageId] = useState(0)
    const [searchBatchId, setSearchBatchId] = useState(0)
    const [searchBatchTypeId, setSearchBatchTypeId] = useState(0)
    const [searchAcquiringMethodId, setSearchAcquiringMethodId] = useState(0)
    const [searchLifecycleId, setSearchLifecycleId] = useState(0)
    const [searchAquacultureStageId, setSearchAquacultureStageId] = useState(0)
    const [searchMarineLifeId, setSearchMarineLifeId] = useState(0)
    const [searchSpeciesId, setSearchSpeciesId] = useState(0)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    
    //---------------
    // Search storage
    //---------------
    const searchStorage = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        // If search by storage id, in order to show empty storage, will not be filtered by species.
        if(searchStorageId != 0) {
            message.info("Search by Storage ID would disable species filter.")
        }
        
        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: { 
                site: searchSiteId,
                species: searchStorageId != 0 ? 0 : urlParams.get("speciesId"), 
                batch: searchBatchId,
                id: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : searchStorageId,
                marine_life: searchMarineLifeId,
                species: searchSpeciesId,
                acquiring_method: searchAcquiringMethodId,
                aquaculture_stage: searchAquacultureStageId,
                lifecycle: searchLifecycleId,
                batch_type: searchBatchTypeId, 
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //-------------
    // Load storage
    //-------------
    const getMatchingStorage = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: { 
                site: getUserSiteId(),
                free: true,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( storage => {
            data.push({
                key: storage.id,
                pKey: storage.pKey,
                id: storage.name,
                storageType: storage.storage_type_data.name,
                batchId: (storage?.batch_data == null) ? 0 : storage.batch_data.pKey,
                batch: (storage?.batch_data == null) ? "" : storage.batch_data.id,
                acquiringMethod: storage?.batch_data?.acquiring_method_data == null ? "" : storage.batch_data.acquiring_method_data.name,
                acquiringMethodId: storage?.batch_data?.acquiring_method_data == null ? 0 : storage.batch_data.acquiring_method_data.pKey,
                lifecycle: storage?.batch_data?.lifecycle_data == null ? "" : storage.batch_data.lifecycle_data.name,
                lifecycleId: storage?.batch_data?.lifecycle_data == null ? 0 : storage.batch_data.lifecycle_data.pKey,
                batchType: storage?.batch_data?.batch_type_data?.name == null ? "" : storage.batch_data.batch_type_data.name,
                batchTypeId: storage?.batch_data?.batch_type_data?.pKey == null ? 0 : storage.batch_data.batch_type_data.pKey,
                aquacultureStage: storage?.batch_data?.aquaculture_stage_data.name == null ? "" : storage.batch_data.aquaculture_stage_data.name,
                species: storage?.batch_data?.species_data.short_name == null ? "" : storage.batch_data.species_data.short_name,
                marineLifeId: storage?.batch_data?.species_data?.marine_life_data == null ? "" : storage.batch_data.species_data.marine_life_data.pKey,
                batchAverageWeight: storage?.batch_data?.average_per_unit_weight_gram == null ? 0 : storage.batch_data.average_per_unit_weight_gram,
                batchAverageLength: storage?.batch_data?.average_per_unit_length_mm == null ? 0 : storage.batch_data.average_per_unit_length_mm,
                ageDays: storage?.batch_data?.age_days == null ? 0 : storage.batch_data.age_days,

                batchData: storage?.batch_data != null ? [storage.batch_data] : [],
                status: storage.status_data,
            })
        })

        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------
    // On next
    //--------
    const onNext = (record) => {
        if(record.batchId == 0) {
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>Storage has no batch assigned. Go to Batch module to create one?</p></Space>,
                onOk() { 
                    navigate({ 
                        pathname: "/batchnew", 
                        search: `?fromStockIn=true&storageId=${record.pKey}&storage=${record.id}`
                    }) 
                },
                onCancel() {},
                centered: true
            })
        }
        else 
            navigate({ 
                pathname: "/collectivefishstockin", 
                search: `?storageId=${record.pKey}&storage=${record.id}&batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&species=${record.species}&acquiringMethod=${record.acquiringMethod}&aquacultureStage=${record.aquacultureStage}&lifecycle=${record.lifecycle}&averageWeight=${record.batchAverageWeight}&marineLifeId=${record.marineLifeId}`
            }) 
    }

    //---------------------------
    // On acquiring method change
    //---------------------------
    const onAcquiringMethodChange = (e, value) => {
        setSearchAcquiringMethodId(e)
    }

    //---------------------
    // On batch type change
    //---------------------
    const onBatchTypeChange = (e, value) => {
        setSearchBatchTypeId(e)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        if(!isSearching)
            getMatchingStorage(page)
        else
            searchStorage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setIsSearching(true)
        setCurrentPage(1)
        searchStorage(1)
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                onNext(record)
            }
        }
    }

    //----------------------------
    // Redirect callback function
    //----------------------------
    const redirect = (pathname, search) => {
        navigate({
            pathname: pathname,
            search: search
        })
    }

    //----------------------------
    // Show storage stock quantity
    //----------------------------
    const showStoragePopup = (e, record) => {
        e.stopPropagation()
        record.key = record.pKey
        showStorageStockSummary(false, record, redirect)
    }

    //------------------------
    // Redirect to listing page
    //------------------------
    const onGoToListing = () => {
        navigate({ pathname: MENUPATH_STOCKTRANSACTION })
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onMarineLifeChange = (e, value) => {
        setSearchMarineLifeId(e)
    }

    const onSpeciesIdChange = (e, value) => {
        setSearchSpeciesId(e)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setIsSearching(true)
            setSearchStorageId(urlParams.get("storageId"))
            searchStorage(currentPage, urlParams.get("storageId"))
        }
        else
            getMatchingStorage(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        !isTabletOrMobile ? { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) } : [],
        { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) },
        { title: !isTabletOrMobile ? 'Aquaculture Stage' : 'Aqua. Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Status', dataIndex: 'stockQuantity', key: 'stockQuantity', align: 'center', //sorter: (a, b) => a.quantity.stockQuantity(b.stockQuantity),
            render: (stockQuantity, record) => {
                if(record.status == OTHERSYSPARAM("IS_OCCUPIED"))
                    return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => showStoragePopup(e, record)}>{OTHERSYSPARAM("IS_OCCUPIED")}</Button>
                else
                    return OTHERSYSPARAM("IS_EMPTY")
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Destination Storage" key="1">
                        <Form form={searchForm} {...formLayout_2Columns}>
                            <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={onSpeciesIdChange} onBatchChange={setSearchBatchId} 
                                onStorageChange={setSearchStorageId} onAquacultureStageChange={setSearchAquacultureStageId}
                                onLifecycleChange={setSearchLifecycleId} showLockedStorage={false} defaultStorageId={searchStorageId}/>

                            <Form.Item name="acquiringMethodId" label="Acquiring Method">
                                <AcquiringMethodSelect withBlank={true} onChange={onAcquiringMethodChange}/>
                            </Form.Item>
                            
                            <Form.Item name="batchTypeId" label="Batch Type">
                                <BatchTypeSelect withBlank={true} onChange={onBatchTypeChange}/>
                            </Form.Item>
                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={12} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onGoToListing} icon={<ProfileOutlined />}>Transaction Listing</Button></Col>
            <Col span={12} />
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row>
            <Col span={24}>
                <Table bordered onRow={onRowClick} columns={columns} dataSource={storageDataSource} pagination={false} />
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onGoToListing} icon={<ProfileOutlined />}>Transaction Listing</Button></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default CollectiveFishStockTransactionDestinationStorageTable