import  { useEffect, useState } from 'react'
import { Button, Col, Row, Space, Table, Tooltip, message, Upload, Card, Popconfirm, Pagination, Collapse, Form, DatePicker, Spin } from "antd"
import { PlayCircleOutlined, UploadOutlined, DeleteOutlined } from "@ant-design/icons"
import axios from 'axios'
import { ACISAPIURL, DATEFORMAT, DATETIMEFORMAT, LOADING, MENUPATH_STOCKCOUNT, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT } from '../Common/SystemParameter'
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { numberWithCommas, reportError, stringNumberSorter } from '../Common/Utility'
import moment from 'moment'
import { formLayout_2Columns } from '../Common/Layout'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'
import TrueFalseSelect from '../Common/TrueFalseSelect'
import { useNavigate } from 'react-router-dom'

const { Panel } = Collapse
const { RangePicker } = DatePicker

const StockCountVideoTable = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [dateRange, setDateRange] = useState([moment(moment().subtract(1, "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [inferenced, setInferenced] = useState("")
        
    const [disableButton, setDisableButton] = useState("")
    const [uploading, setUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [stockCountVideoDataSource, setStockCountVideoDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
        
    //--------------------------
    // Download video
    //--------------------------
    const playVideo = (e, videoUrl) => {
        e.stopPropagation()
        
        setTimeout(() => {
            const response = {
                file: videoUrl,
            };
            // server sent the url to the file!
            // now, let's download:
            window.open(response.file)
            // you could also do:
            // window.location.href = response.file;
          }, 100)
    }
    
    //--------------------------
    // Delete environment record
    //--------------------------
    const deleteVideo = (e, record) => {
        setIsLoading(true)

        axios.delete(`${ACISAPIURL}stockcountvideo/delete/${record.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Video deleted.`)
            setStockCountVideoDataSource(stockCountVideoDataSource.filter((item) => item.key !== record.key))
        })
        .catch( error => {
            reportError(error, "Failed to delete video.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------------
    // Get stock count video
    //-------------------------
    const searchStockCountVideo = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}stockcountvideo/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                inferenced: inferenced,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            pushData(response)
        })
        .catch( error => {
            reportError(error, "Failed to search stock count video record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const pushData = (response) => {
        const data = []
        response.data.results.forEach( video => {
            data.push({
                key: video.pKey,
                batch: video.batch_data.id,
                batchId: video.batch,
                storage: video.storage_data.name,
                storagId: video.storage,
                videoUrl: video.video,
                inferencedVideoUrl: video?.inferenced_video,
                countResult: numberWithCommas(video.count_result),
                createdOn: moment(video.createdOn).format(DATETIMEFORMAT),
                updatedOn: video.createdOn != video.updatedOn ? moment(video.updatedOn).format(DATETIMEFORMAT) : "",
                stockCountedOn: video.stockcountedOn != null ? moment(video.stockcountedOn).format(DATETIMEFORMAT) : "",
            })
        })
        
        setStockCountVideoDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStockCountVideo(1)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStockCountVideo(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------------
    // On change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    const onBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onSearchInferencedChange = (e, value) => {
        setInferenced(e)
        //setInferencedLabel(value.children)
    }
    
    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                /*navigate({ 
                    pathname: "/standardgrowth", 
                    search: `?pKey=${record.key}`
                })*/ 
            }
        }
    }

    //-----------------------
    // Go to stock count page
    //-----------------------
    const goToStockCount = () => {
        navigate({ 
            pathname: MENUPATH_STOCKCOUNT
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchStockCountVideo(currentPage)
    }, [])

    //-----------------------------
    // Upload excel file validation
    //-----------------------------
    const props = {
        name: 'video_files',
        action: `${ACISAPIURL}stockcountvideo/upload/${getUserSiteId()}/`,
        timeout: 0,//parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        multiple: true,
        beforeUpload: file => {
            //if (file.type !== 'video/mp4') 
            //    message.error(`${file.name} is not a video file`)

            return (file.type === 'video/mp4')? true : Upload.LIST_IGNORE
        },
        onChange(info) {
            setUploading(true)
            
            if(info.file.status !== 'uploading') {
                setUploading(false)
                setIsLoading(true)
            }

            if(info.file.status === 'done') {
                setUploading(false)
                setIsLoading(false)
                
                setCurrentPage(1)
                searchStockCountVideo(1)
                
                /*confirm({
                    icon: null,
                    content: <Space><p>Video files upload completed.</p></Space>,
                    onOk() { 
                        //window.location.reload() 
                        setCurrentPage(1)
                        searchStockCountVideo(1)
                    },
                    cancelButtonProps: {style: {display: "none"} },
                    centered: true
                })*/
            }
            else if(info.file.status === 'error') {
                setUploading(false)
                //message.error(`Video file upload failed.`)
                message.error(info.file.response?.detail, OTHERSYSPARAM("LONG_MSG_DURATION"))
            }
        },
    }

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage ID', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Uploaded On', dataIndex: 'createdOn', key: 'createdOn', sorter: (a, b) => a.createdOn.localeCompare(b.createdOn) },
        { title: 'Counted On', dataIndex: 'updatedOn', key: 'updatedOn', sorter: (a, b) => a.updatedOn.localeCompare(b.updatedOn) },
        { title: 'Adjusted Count Result (Tail)', dataIndex: 'countResult', key: 'countResult', align: "right", sorter: (a, b) => stringNumberSorter(a.countResult, b.countResult) },
        { title: 'Stock Count Created On', dataIndex: 'stockCountedOn', key: 'stockCountedOn', sorter: (a, b) => a.stockCountedOn.localeCompare(b.stockCountedOn) },
        { key: 'action', align: 'center',
            render: (record) => {
                return <>
                    <Button type="primary" disabled={disableButton} loading={isLoading} icon={<PlayCircleOutlined/>} onClick={e => playVideo(e, record.videoUrl)} />
                    { (record.inferencedVideoUrl != null && record.inferencedVideoUrl.length > 0) && 
                        <Button style={{backgroundColor: "green"}} type="primary" disabled={disableButton} loading={isLoading} icon={<PlayCircleOutlined/>} onClick={e => playVideo(e, record.inferencedVideoUrl)} />
                    }
                    <Popconfirm title="Delete stock count video confirmed?" 
                        onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => deleteVideo(e, record)} 
                        okText="Yes" cancelText="No">
                        <Button danger type="primary" disabled={disableButton} icon={<DeleteOutlined/>} />
                    </Popconfirm>
                    {/* <a href={record.videoUrl} download>Download</a> */}
                </>
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row justify="center">
            <Col span={8} style={{textAlign: "center"}}>
                <Card title="Stock Count Video" style={{textAlign: "left"}}>
                    Please append batch and storage to video filename before upload. <br />E.g. TNS-FS-SBS-22-01.CD07.original_video_filename.mp4<br /><br />
                    Batch, storage and their relationship will be validated when uploading. Video with invalid batch, storage or relationship will NOT
                    be stored.
                </Card>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Stock Count Video Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onBatchChange={onBatchChange} onStorageChange={onStorageChange} showLockedStorage={true} defaultStorageId={storageId}/>

                            <Form.Item name="inferenced" label="Inferenced">
                                <TrueFalseSelect withBlank={true} onChange={onSearchInferencedChange}/>
                            </Form.Item>
                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={18} />
            <Col span={6} style={{textAlign: "end"}}>
                <Upload {...props} accept="video/mp4">
                    <Button type="primary" htmlType="button" icon={<UploadOutlined />} loading={uploading}>
                        <Tooltip title="Upload new stock count video.">
                            Upload stock count video
                        </Tooltip>
                    </Button>
                </Upload>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={stockCountVideoDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        {/*<Row justify="center">
            <Col span={18} />
            <Col span={6} style={{textAlign: "end"}}>
                <Upload {...props} accept="video/mp4">
                    <Button type="primary" htmlType="button" icon={<UploadOutlined />} loading={uploading}>
                        <Tooltip title="Upload new stock count video.">
                            Upload stock count video
                        </Tooltip>
                    </Button>
                </Upload>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        */}
        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>

        <Row justify="center">
            <Col span={20} />
            <Col span={4} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" disabled={disableButton} onClick={goToStockCount}>Stock Count</Button>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default StockCountVideoTable