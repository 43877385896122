import { Table, Modal, Menu, Dropdown, message  } from 'antd'
import { InfoCircleOutlined } from "@ant-design/icons"
import { EGGS, MENUKEY_GROWTHDEV, MENUPATH_BATCH, MENU_BATCH, TAGGED, UNTAGGED} from './SystemParameter'
import { numberWithCommas } from './Utility'
import axios from "axios"
import { ACISAPIURL } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { reportError } from "../Common/Utility"
import { getBatchSummaryDataSource } from './showBatchStorageStockSummary'
import { inComponentSubMenu, inComponentSubMenuPath } from './InComponentSubMenu'


//--------------------------------------------------
// Plain javascript. Not React functional component.
//--------------------------------------------------
const { confirm } = Modal

export const showStorageStockSummary = async (allowRedirect, record, redirect) => {  
    const onClick = (e) => {
        const [pathname, destination, searchParams] = inComponentSubMenuPath(e.key)
        let search = `?storageId=${record.key}${searchParams}`

        if(parseInt(e.key) == MENUKEY_GROWTHDEV) search = `${search}&batchId=${record.batchData[0].pKey}` 
                
        modal.destroy()
        message.info(`You have been redirected to ${destination} page.`)
        // Redirect to target page.
        redirect(pathname, search)
    }

    //-----------------------
    // Dropdown menu
    //-----------------------
    const menu = (
        <Menu onClick={onClick} triggerSubMenuAction={"click"}>
            {inComponentSubMenu()}
        </Menu>
    )

    //-----------------------
    // On table row selected
    //-----------------------
    const goToBatch = () => {
        //modal.destroy()
        message.info(`You have been redirected to ${MENU_BATCH} page.`)
        redirect(MENUPATH_BATCH, `?storageId=${record.key}`)
    }

    const averageSummaryColumns = [
        { title: '', dataIndex: 'itemType', key: 'itemType', }, //sorter: (a, b) => a.itemType.localeCompare(b.itemType)},
        { title: 'Total Quantity', dataIndex: 'totalQty', key: 'totalQty', align: 'right' },//sorter: (a, b) => a.totalQty.localeCompare(b.totalQty)},
        { title: 'Average Weight (g)', dataIndex: 'averageWeight', key: 'averageWeight', align: 'right' },//sorter: (a, b) => stringNumberSorter(a.averageWeight, b.averageWeight) },
        { title: 'Average Length (mm)', dataIndex: 'averageLength', key: 'averageLength', align: 'right' },//sorter: (a, b) => stringNumberSorter(a.averageLength, b.averageLength) },
    ]

    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', },
        { title: EGGS, dataIndex: 'e', key: 'e',  /*align: 'right'*/ },
        { title: TAGGED, dataIndex: 'tf', key: 'tf',  /*align: 'right'*/ },
        { title: UNTAGGED, dataIndex: 'utf', key: 'utf',  /*align: 'right'*/ },
        { title: "Summary", align: 'center',
            render: (batchId, record) => {
                return <div onClick={e => showBatchAverageSummary(record)}><InfoCircleOutlined style={{ fontSize: '20px', color: "blue"}}/></div>
            }
        },
    ]

    //--------------
    // Summary Modal
    //--------------
    const showBatchAverageSummary = async (record) => {
        try {
            const batchSummaryDataSource = await getBatchSummaryDataSource(record.batchAverageData)
            
            confirm({
                title: <>
                        <div><h3>Summary of Batch ID: {record.batch}</h3></div>
                        <div>Age (days): {record.batchAverageData.ageDays}</div>
                        </>,
                icon: null,
                width: 700,
                onOk() {},
                okText: "Close",
                cancelButtonProps: {style: {display: "none"}},
                centered: true,
                content: <Table bordered columns={averageSummaryColumns} pagination={false} dataSource={batchSummaryDataSource} />
            })
        }
        catch(error) {
        }
    }

    //-----------
    // Main modal
    //-----------
    const storageStockSummaryDataSource = await getStorageStockSummaryDataSource(record)
    
    const modal = Modal.info({
        title: <div><h3>Storage: {record.id} {allowRedirect ? <Dropdown overlay={menu} trigger={['click']}><a className="ant-dropdown-link" > ...</a></Dropdown> : <></>}</h3></div>,
        icon: null,
        width: 700,
        centered: true,
        onOk() {},
        onCancel() {goToBatch()},
        okText: "Close",
        cancelText: "Go to Batch",
        okCancel: true,
        content: <Table bordered columns={columns} pagination={false} dataSource={storageStockSummaryDataSource} /> 
    })
}

const getStorageStockSummaryDataSource = (record) => {
    return new Promise((resolve, reject) => {
        axios.get(`${ACISAPIURL}storagestocksummary/`, { 
            params: {
                storage: record.key
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const data = []

            // Compress 3 item types into one row for each storage.
            let batchIdSet = new Set(response.data.results.map( summary => summary.batch))
            
            batchIdSet.forEach( batchId => {
                // One batch will only have one species hence one marine life.
                const batchMarineLifeId = response.data.results.filter( row => row.batch == batchId)[0].marine_life
                const batchRows = response.data.results.filter( row => row.batch == batchId && row.marine_life == batchMarineLifeId)
                                                
                let eggQty = "-"
                const eggRow = batchRows.filter( row => row.item_type_name.startsWith(EGGS))
                if(eggRow.length > 0 && eggRow[0].quantity > 0) eggQty = `${eggRow[0].quantity} ${eggRow[0].uom_name}`

                let taggedQty = "-"
                const taggedRow = batchRows.filter( row => row.item_type_name.startsWith(TAGGED))
                if(taggedRow.length > 0 && taggedRow[0].quantity > 0) taggedQty = `${taggedRow[0].quantity} ${taggedRow[0].uom_name} (≈${taggedRow[0].tagged_average_weight_gram}g)`

                let untaggedQty = "-"
                const untaggedRow = batchRows.filter( row => row.item_type_name.startsWith(UNTAGGED))
                if(untaggedRow.length > 0 && untaggedRow[0].quantity > 0) untaggedQty = `${untaggedRow[0].quantity} ${untaggedRow[0].uom_name} (≈${untaggedRow[0].untagged_average_weight_gram}g)`
                
                // Need to check for nullness due to the reason that this function may be called from Storage module and other storage selection modules.
                let batchData = record.batchData.filter( rec => rec.pKey == batchId )
                
                if(batchData.length > 0) 
                    data.push({
                        key: batchId,
                        e: numberWithCommas(eggQty),
                        tf: numberWithCommas(taggedQty),
                        utf: numberWithCommas(untaggedQty),
                        batch: batchRows[0].batchid,
                        notCrossSite: record.site == getUserSiteId(),
                        batchAverageData: {
                            key: batchId,
                            ageDays: batchData[0].age_days,
                            batchAverageWeight: batchData[0].average_per_unit_weight_gram,
                            batchAverageLength: batchData[0].average_per_unit_length_mm
                        }
                    })
            })

            resolve(data)
        })
        .catch( error => {
            reportError(error, "Failed to get storage stock summary.")
            reject(error)
        })
        .finally(() => {
            refreshUserSession()
        })
    })
}