import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Layout, Row, Col, Typography, Popconfirm, Input, Table, Select, Card, PageHeader, Modal, Descriptions, Collapse, Spin, DatePicker } from 'antd'
import { SaveOutlined, CloseOutlined, LockOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT,  MENUPATH_BATCH, DATEFORMAT, UNIDATEFORMAT, MEDIAMAXWIDTH, LOADING } from "../Common/SystemParameter"
import { reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, getUserSiteId, SYSPARAM, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import moment from 'moment'
import { lockThisBatch } from '../Common/releaseStorage'
import { useMediaQuery } from 'react-responsive'
import { getBatchSummaryDataSource } from '../Common/showBatchStorageStockSummary'
import { showStorageStockSummary } from '../Common/showStorageStockSummary'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'
import AquacultureStagLifecycleSteps from './AquacultureStageLifecycleSteps'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { TextArea } = Input
const { Option } = Select
const { confirm } = Modal
const { Panel } = Collapse

//----------
// Component
//----------
const BatchUpdate = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const navigate = useNavigate()
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    //const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    //const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [hideUpdateButton, setHideUpdateButton] = useState("")
    const [loading, setLoading] = useState(false)

    //const [hideCriticalComponent, setHideCriticalComponent] = useState(!getUserGroups().includes(OTHERSYSPARAM("DEVELOPER")) ? true : false)

    const [storageIds, setStorageIds] = useState([])
    const [batchStatusId, setBatchStatusId] = useState(0)
    const [batchStatus, setBatchStatus] = useState("")
    const [storageAssignedDataSource, setStorageAssignedDataSource] = useState([])
    const [availableStorageOption, setAvailableStorageOption] = useState([])
    const [averageSummaryDataSource, setAverageSummaryDataSource] = useState([])
            
    // Recall existing values
    const [batch, setBatch] = useState("")
    const [batchType, setBatchType] = useState("")
    const [batchTypeId, setBatchTypeId] = useState(urlParams.get("batchTypeId"))
    const [species, setSpecies] = useState("")
    const [acquiringMethod, setAcquiringMethod] = useState("")
    const [createdOn, setCreatedOn] = useState("")
    const [updatedOn, setUpdatedOn] = useState("")
    const [birthDateForDispaly, setBirthDateForDisplay] = useState("")
    const [ageDays, setAgeDays] = useState("")
        
    const [hideLockButton, setHideLockButton] = useState("none")
      
    const [speciesId, setSpeciesId] = useState(urlParams.get("speciesId"))
    const [batchTypeOption, setBatchTypeOption] = useState([])
    const [acquiringMethodId, setAcquiringMethodId] = useState(urlParams.get("acquiringMethodId"))
    const [acquiringMethodOption, setAcquiringMethodOption] = useState([])
    const [aquacultureStageId, setAquacultureStageId] = useState(urlParams.get("aquacultureStageId"))
    const [aquacultureStage, setAquacultureStage] = useState(urlParams.get("aquacultureStage"))
    const [aquacultureStageOption, setAquacultureStageOption] = useState([]) 
    const [lifecycleId, setLifecycleId] = useState(urlParams.get("lifecycleId"))
    const [lifecycle, setLifecycle] = useState(urlParams.get("lifecycle"))
    const [lifecycleOption, setLifecycleOption] = useState([])

    const [totalQtyLabel, setTotalQtyLabel] = useState(isTabletOrMobile ? "Total Qty." : "Total Quantity")
    const [averageWeightLabel, setAverageWeightLabel] = useState(isTabletOrMobile ? "Avg. Weight (g)" : "Average Weight (g)")
    const [averageLengthLabel, setAverageLengthLabel] = useState(isTabletOrMobile ? "Avg. Length (mm)" : "Average Length (mm)")
    
    //-------------
    // Update batch
    //-------------
    const updateBatch = () => {
        // Disable button.
        setDisableButton("disabled")
        setLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}batch/update/${urlParams.get("pKey")}/`, {
                batch_type: batchTypeId,
                acquiring_method: acquiringMethodId,
                aquaculture_stage: aquacultureStageId,
                lifecycle: lifecycleId,
                storage: storageIds,
                status: batchStatusId,
                average_per_unit_weight_gram: values.averageWeight,
                average_per_unit_length_mm: values.averageLength,
                birth_date: values.birthDate,
                remark: values.remark
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Batch ${response.data.id} updated.`)
                navigate({ pathname: MENUPATH_BATCH })
            })
            .catch( error => {
                reportError(error, "Failed to update batch.")
            })
            .finally(() => {
                setDisableButton("")
                setLoading(false)
                refreshUserSession()
            })
        })
    }

    //-----------
    // Load batch
    //-----------
    const getBatch = async () => {
        setLoading(true)

        // Regardless of item type.
        const getStorageTotalQuantity = (storagePKey, storagestocksummary) => {
            if(storagestocksummary?.length == 0) return 0

            let totalQuantity = 0
            storagestocksummary.filter( summary => summary.storage == storagePKey ).forEach( summary => {
                totalQuantity += summary.quantity
            })

            return totalQuantity
        }

        return await axios.get(`${ACISAPIURL}batch/${urlParams.get("pKey")}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const batch = response.data
            
            setBatch(batch.id)
            setBatchType(batch.batch_type_data.name)
            setBatchTypeId(batch.batch_type_data.pKey)
            setSpecies(batch.species_data.short_name)
            setAcquiringMethod(batch.acquiring_method_data.name)
            setAcquiringMethodId(batch.acquiring_method_data.pKey)
            setAquacultureStageId(batch.aquaculture_stage_data.pKey)
            setLifecycleId(batch.lifecycle_data.pKey)
            setCreatedOn(moment(batch.createdOn).format(DATETIMEFORMAT))
            setUpdatedOn(moment(batch.updatedOn).format(DATETIMEFORMAT))
            setBatchStatusId(batch.status_data.pKey)
            setBatchStatus(batch.status_data.name)
            setBirthDateForDisplay(batch.birth_date != null ? moment(moment(batch.birth_date).format(UNIDATEFORMAT)).format(DATEFORMAT) : OTHERSYSPARAM("NA"))
            setAgeDays(batch.age_days != null ? batch.age_days : OTHERSYSPARAM("NA"))
                        
            form.setFieldsValue({
                averageWeight: batch.average_per_unit_weight_gram,
                averageLength: batch.average_per_unit_length_mm,
                birthDate: moment(moment(batch.birth_date).format(UNIDATEFORMAT)),
                remark: batch.remark
            })

            const record = {
                key: batch.pKey,
                batchAverageWeight: batch.average_per_unit_weight_gram,
                batchAverageLength: batch.average_per_unit_length_mm
            }

            Promise.resolve(getBatchSummaryDataSource(record))
            .then( batchSummaryDataSource => {
                setAverageSummaryDataSource(batchSummaryDataSource)
            })

            if(!batch.active) setHideUpdateButton("none")
            if(batch.active && batch.storage.length == 0) setHideLockButton("")
            
            // Storage assigned
            const batchId = batch.id
            const data = []
            batch.storage.forEach( storage => {
                data.push({
                    key: storage.pKey,
                    id: storage.name,
                    batchId: batchId,
                    isEmpty: getStorageTotalQuantity(storage.pKey, batch.storagestocksummary) == 0,
                    batchData: [batch]
                })
            })

            setStorageAssignedDataSource(data)
        })
        .catch( error => {
            reportError(error, "Failed to get batch.")
        })
        .finally(() => {
            setLoading(false)
            refreshUserSession()
        })
    }

    //------------------
    // Release storage
    //------------------
    const releaseStorage = (record) => {
        if(!record.isEmpty) {
            message.warn("Unable to release storage. It is not empty.")
            return
        }

        // Disable button.
        setDisableButton("disabled")
        setLoading(true)
        
        axios.patch(`${ACISAPIURL}storage/release/`, {
            batch: urlParams.get("pKey"),
            storage: record.key
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Storage ${record.id} released.`)
            window.location.reload()
        })
        .catch( error => {
            reportError(error, "Failed to release storage.")
        })
        .finally(() => {
            setDisableButton("")
            setLoading(false)
            refreshUserSession()
        })
    }

    //-----------------------
    // Load available storage
    //-----------------------
    const getAvailableStorage = async () => {
        setLoading(true)

        return await axios.get(`${ACISAPIURL}storageavailablesuitable/`, {
            params: {
                site: getUserSiteId(),
                species: speciesId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( storage => <Option key={storage.pKey}>{storage.name}</Option>)
            setAvailableStorageOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get available storage.")
        })
        .finally(() => {
            setLoading(false)
            refreshUserSession()
        })
    }

    //-------------------
    // Load lifecycle
    //-------------------
    const getLifecycle = (aquacultureStageId) => {
        setLoading(true)

        axios.get(`${ACISAPIURL}lifecycle/`, {
            params: {
                species: speciesId,
                batch_type: batchTypeId,
                aquaculture_stage: aquacultureStageId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( lifecycle => <Option key={lifecycle.pKey}>{lifecycle.name}</Option>)
            setLifecycleOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get lifecycle.")
        })
        .finally(() => {
            setLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------
    // Load aquaculture stage
    //---------------------------
    const getAquacultureStage = (acquiringMethodId) => {
        setLoading(true)

        axios.get(`${ACISAPIURL}aquaculturestage/`, { 
            params: {
                species: speciesId,
                batch_type: batchTypeId,
                acquiring_method: acquiringMethodId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( stage => <Option key={stage.pKey}>{stage.name}</Option>)
            setAquacultureStageOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get aquaculture stages.")
        })
        .finally(() => {
            setLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Load acquiring method
    //--------------------------
    const getAcquiringMethod = () => {
        setLoading(true)

        axios.get(`${ACISAPIURL}acquiringmethod/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),    
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( method => <Option key={method.pKey}>{method.name}</Option>)
            setAcquiringMethodOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get acquiring methods")
        })
        .finally(() => {
            setLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------
    // Load batch type
    //---------------------------
    const getAllBatchType = () => {
        setLoading(true)

        axios.get(`${ACISAPIURL}batchtype/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options = options.concat(response.data.results.map( batchType => <Option key={batchType.pKey}>{batchType.name}</Option> ))
            setBatchTypeOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get batch type.")
        })
        .finally(() => {
            setLoading(false)
            refreshUserSession()
        })
    }

    //----------------------------
    // On available storage change
    //----------------------------
    const onAvailableStorageChange = (e) => {
        setStorageIds(e)
    }

    //--------------------
    // Cancel update batch
    //--------------------
    const onCancel = () => {
        navigate({ pathname: MENUPATH_BATCH })
    }

    //--------------------
    // Lock batch
    //--------------------
    const onLock = () => {
        lockThisBatch(urlParams.get("pKey"), batch)
        .then(() => {
            navigate({
                pathname: MENUPATH_BATCH
            })
        })
        .catch(error => {
            reportError(error, "Failed to lock batch.")
        })
    }

    //--------------------
    // On lifecycle change
    //--------------------
    const onLifecycleChange = (e) => {
        setLifecycleId(e)
    }

    //----------------------------
    // On aquaculture state change
    //----------------------------
    const onAquacultureStateChange = (e) => {
        setAquacultureStageId(e)
        
        // Clear
        form.setFieldsValue({ 
            lifecycle: []
        })
        setLifecycleId(0)

        getLifecycle(e)
    }

    //---------------------------
    // On acquiring method change
    //---------------------------
    const onAcquiringMethodChange = (e) => {
        setAcquiringMethodId(e)
        
        // Clear
        form.setFieldsValue({ 
            aquacultureStage: [],
            lifecycle: []
        })
        setAquacultureStageId(0)
        setLifecycleId(0)

        getAquacultureStage(e)
    }

    //---------------------
    // On batch type change
    //---------------------
    const onBatchTypeChange = (e) => {
        setBatchTypeId(e)

        // Clear
        form.setFieldsValue({
            acquiringMethod: [],
            aquacultureStage: [],
            lifecycle: [],
        })
        setAcquiringMethodId(0)
        setAquacultureStageId(0)
        setLifecycleId(0)

        getAcquiringMethod()
    }

    //---------------------
    // On update
    //---------------------
    const onUpdate = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update batch record confirmed?</p></Space>,
            onOk() { updateBatch() },
            onCancel() {},
            centered: true
        })
    }

    //----------------------------
    // Redirect callback function
    //----------------------------
    const redirect = (pathname, search) => {
        navigate({
            pathname: pathname,
            search: search
        })
    }

    //----------------------------
    // Show storage stock quantity
    //----------------------------
    const showStoragePopup = (e, record) => {
        e.stopPropagation()
        showStorageStockSummary(true, record, redirect)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            if(urlParams.get("statusId") == SYSPARAM("New")) {
                getAllBatchType()
                getAcquiringMethod()
                getAquacultureStage(aquacultureStageId)
                getLifecycle(aquacultureStageId)

                form.setFieldsValue({
                    batchType: batchTypeId,
                    acquiringMethod: acquiringMethodId,
                    aquacultureStage: aquacultureStage,
                    lifecycle: lifecycle
                })
            }

            await getAvailableStorage()
            await getBatch()
        }

        init()
    }, [])

    //--------------
    // Table columns
    //--------------
    const averageSummaryColumns = [
        { title: '', dataIndex: 'itemType', key: 'itemType', }, //sorter: (a, b) => a.itemType.localeCompare(b.itemType)},
        { title: totalQtyLabel, dataIndex: 'totalQty', key: 'totalQty', align: 'right' }, //sorter: (a, b) => a.totalQty.localeCompare(b.totalQty)},
        { title: averageWeightLabel, dataIndex: 'averageWeight', key: 'averageWeight', align: 'right' }, //sorter: (a, b) => a.averageWeight - b.averageWeight },
        { title: averageLengthLabel, dataIndex: 'averageLength', key: 'averageLength', align: 'right' }, //sorter: (a, b) => a.averageLength - b.averageLength },
    ]

    const columns = [
        { title: 'Storage ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Status', dataIndex: 'stockQuantity', key: 'stockQuantity', align: 'center', //sorter: (a, b) => a.quantity.stockQuantity(b.stockQuantity),
            render: (stockQuantity, record) => {
                if(!record.isEmpty)
                    return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => showStoragePopup(e, record)}>{OTHERSYSPARAM("IS_OCCUPIED")}</Button>
                else
                    return OTHERSYSPARAM("IS_EMPTY")
            }
        },
        { title: 'Action', key: 'release', align: 'center',
            render: (record) => {
                let disabled = ""
                if(!record.isEmpty) disabled = "disabled"
                
                return <Popconfirm title="Release storage from batch confirmed?" onConfirm={() => releaseStorage(record)} okText="Yes" cancelText="No">
                    <Button danger type="primary" disabled={disabled} style={{margin: "0px"}}>Release</Button>
                </Popconfirm>
            }
        },
    ]

    return(
        <Spin spinning={loading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight }}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={() => onCancel()} 
                    title="Batch">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update batch record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
            
                <Form form={form} onFinish={onUpdate} {...formLayout}>
                    <Form.Item>
                        <Collapse defaultActiveKey={["0"]}>
                            <Panel header={`Batch ${urlParams.get("batch")} Summary`} key="1">
                                <Card title={<Title level={5}>{`Batch ID: ${batch}`}</Title>}>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{batchType}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{species}</Card.Grid>
                                    
                                    {urlParams.get("statusId") != SYSPARAM("New") && 
                                        <>
                                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{acquiringMethod}</Card.Grid>
                                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{aquacultureStage}</Card.Grid>
                                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{lifecycle}</Card.Grid>
                                        </>
                                    }

                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{createdOn}</Card.Grid>

                                    { updatedOn != createdOn &&
                                        <>
                                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{updatedOn}</Card.Grid>
                                        </>
                                    }

                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Status:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{batchStatus}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Birth Date:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{birthDateForDispaly}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Age (days):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{ageDays}</Card.Grid>
                                </Card>
                        
                                <Row><Col><Space><div /></Space></Col></Row>
                        
                                <Table bordered columns={averageSummaryColumns} pagination={false} dataSource={averageSummaryDataSource} />

                                <Row><Col><Space><div /></Space></Col></Row>

                                <h3>Batch Aquaculture Stage & Lifecycle</h3>
                                <AquacultureStagLifecycleSteps batchPKey={urlParams.get("pKey")}/>
                            </Panel>
                        </Collapse>
                    </Form.Item>

                    {/* Let user change this if batch status is NEW */}
                    {urlParams.get("statusId") == SYSPARAM("New") &&
                        <>
                        <Form.Item name="batchType" label="Batch Type"
                            rules={[
                                { required: true, message: "Batch type is required." },
                            ]}>
                            <Select onChange={onBatchTypeChange} /*defaultValue={urlParams.get("batchType")}*/ allowClear showSearch optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {batchTypeOption}
                            </Select>
                        </Form.Item>
                    
                        <Form.Item name="acquiringMethod" label="Acquiring Method"
                            rules={[
                                { required: true, message: "Acquiring method is required." },
                            ]}>
                            <Select onChange={onAcquiringMethodChange} /*defaultValue={urlParams.get("acquiringMethod")}*/ allowClear style={{width: optionWidth}} showSearch optionFilterProp="children"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {acquiringMethodOption}
                            </Select>
                        </Form.Item>
                    
                        {/* 20211217 */}
                        { OTHERSYSPARAM("ISIMPLEMENTATIONPHASE") == 0 &&
                            <>
                            <Form.Item name="aquacultureStage" label="Aquaculture Stage"
                                rules={[
                                    { required: true, message: "Aquaculture stage is required." },
                                ]}>
                                <Select onChange={onAquacultureStateChange} /*defaultValue={urlParams.get("aquacultureStage")}*/ allowClear value={aquacultureStageId} style={{width: optionWidth}} showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {aquacultureStageOption}    
                                </Select>
                            </Form.Item>
                        
                            <Form.Item name="lifecycle" label="Lifecycle"
                                rules={[
                                    { required: true, message: "Lifecycle is required." },
                                ]}>
                                <Select onChange={onLifecycleChange} /*defaultValue={urlParams.get("lifecycle")}*/ allowClear style={{width: optionWidth}} showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {lifecycleOption}    
                                </Select>
                            </Form.Item>
                            </>
                        }

                        {/* 20211217 - For implementation only. */}
                        { OTHERSYSPARAM("ISIMPLEMENTATIONPHASE") == 1 && 
                            <CommonSearchFormItem onAquacultureStageChange={onAquacultureStateChange} onLifecycleChange={onLifecycleChange} formLayout={formLayout}/>
                        }
                        </>
                    }

                    {/* 20211217 - For implementation only. */}
                    { (urlParams.get("statusId") == SYSPARAM("In Progress") && OTHERSYSPARAM("ISIMPLEMENTATIONPHASE") == 1) &&
                        <>
                        <CommonSearchFormItem onAquacultureStageChange={onAquacultureStateChange} onLifecycleChange={onLifecycleChange} formLayout={formLayout}/>

                        { /* 20221102 */ }
                        <Form.Item name="birthDate" label="Birth Date">
                            <DatePicker format={DATEFORMAT}/>
                        </Form.Item>
                        </>
                    }

                    <Form.Item name="remark" label="Remark" >
                        <TextArea rows={3} />
                    </Form.Item>

                    <Form.Item name="storage" label="Assign Additional Storage">
                        <Select mode="multiple" allowClear onChange={onAvailableStorageChange} style={{width: optionWidth}} placeholder="Please select storage"
                            showSearch optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {availableStorageOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="assignedStorage" label="Storage Assigned To This Batch">
                        <Table bordered columns={columns} dataSource={storageAssignedDataSource} pagination={false} />
                    </Form.Item>
            
                    <Row><Col><Space><div /></Space></Col></Row>
                    
                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} style={{display: hideUpdateButton}} icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Batch lock is irreversible. Confirmed?" onConfirm={onLock} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} style={{display: hideLockButton}} icon={<LockOutlined />}>Lock</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" disabled={disableButton} onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>
            
            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Batch:"
                        subTitle="Update batch record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default BatchUpdate

