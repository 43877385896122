import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import StandardGrowthTable from './StandardGrowthTable'
import { OTHERSYSPARAM } from '../Common/UserSession'
import { useNavigate } from 'react-router-dom'
import { MENUPATH_STANDARDGROWTHTABLE } from '../Common/SystemParameter'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const StandardGrowth = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //--------
    // On back
    //--------
    const onBack = () => {
        if(urlParams.has("fromArchive")) 
            navigate({ 
                pathname: "/standardgrowthtablearchive",
            })
        else
            navigate({ 
                pathname: MENUPATH_STANDARDGROWTHTABLE,
            })
    }

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={() => onBack()} 
                    title="Standard Growth Table">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Manage individual growth record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><div /></Space></Col></Row>
                
                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><StandardGrowthTable /></Col>
                    <Col span={1}></Col>
                </Row>

            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={() => onBack()} 
                        title="Standard Growth Table:"
                        subTitle="Manage individual growth record"/>} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default StandardGrowth