import { useState } from 'react'
import { InputNumber, Space } from "antd"
import { useMediaQuery } from 'react-responsive'
import { MEDIAMAXWIDTH } from './SystemParameter'

const WeightRangeInput = ({from, to, onChange}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [width, setWidth] = useState(isTabletOrMobile ? 160 : 280)
    const [fromWeight, setFromWeight] = useState(from == undefined ? -1 : from)
    const [toWeight, setToWeight] = useState(to == undefined ? -1 : to)

    const onFromWeightChange = (e) => {
        if(e == null) e = -1
        setFromWeight(e)
        onChange(e, toWeight)
    }

    const onToWeightChange = (e) => {
        if(e == null) e = -1
        setToWeight(e)
        onChange(fromWeight, e)
    }

    return(
        <Space>
            <Space><InputNumber defaultValue={from} min={0} max={1000000} step={100} precision={0} onChange={onFromWeightChange} placeholder="From Weight(g)" style={{width: width}}/>(g)</Space> to 
            <Space><InputNumber defaultValue={to} min={0} max={1000000} step={100} precision={0} onChange={onToWeightChange} placeholder="To Weight(g)" style={{width: width}}/>(g)</Space>
        </Space>
    )
}

export default WeightRangeInput