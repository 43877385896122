import { useEffect, useState } from 'react'
import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import TransferFishStorageTable from './TransferFishStorageTable'
import { useNavigate } from 'react-router-dom'
import { OTHERSYSPARAM } from '../Common/UserSession'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const TransferFishStorage = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [interSite, setInterSite] = useState(urlParams.get("interSite") == "true")
        
    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: interSite ? "/transferfishintersite" : "/transferfish", 
            search: interSite ? `?interSite=${interSite}` : ""
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={() => onBack()} 
                    title={`Tagged Fish Transfer ${!interSite ? OTHERSYSPARAM("WITHIN_SITE") : OTHERSYSPARAM("INTER_SITE")}:`}>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Select destination storage</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><TransferFishStorageTable fishPKey={urlParams.get("pKey")} interSite={interSite}/></Col>
                    <Col span={1}></Col>
                </Row>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={() => onBack()} 
                        title={`Tagged Fish Transfer ${!interSite ? OTHERSYSPARAM("WITHIN_SITE") : OTHERSYSPARAM("INTER_SITE")}:`}
                        subTitle="Select destination storage"/>} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default TransferFishStorage