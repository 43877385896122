import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Input, PageHeader, Descriptions, Select, Spin, Switch } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined, LockOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, LOADING, MEDIAMAXWIDTH, MENUPATH_USER } from '../Common/SystemParameter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import SiteSelect from '../Common/SiteSelect'
import UserGroupSelect from '../Common/UserGroupSelect'
import { useMediaQuery } from 'react-responsive'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { Option } = Select

//----------
// Component
//----------
const UserNew = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))

    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [defaultSiteId, setDefaultSiteId] = useState(0)
    const [userGroupId, setUserGroupId] = useState(0)
    const [allowedSiteIds, setAllowedSiteIds] = useState([])
    const [allowedSitesOption, setAllowedSitesOption] = useState([])
    const [isPendingTransactionApprover, setIsPendingTransactionApprover] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
        
    //-------------------
    // Create new user
    //-------------------
    const createUser = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}user/create/`, {
                username: values.username,
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                whatsapp_contact: values.whatsappContact,
                password: values.password,
                default_site: defaultSiteId,
                allowed_sites: allowedSiteIds,
                user_group: userGroupId,
                is_pending_transaction_approver: isPendingTransactionApprover
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`New user ${values.username} created.`)
                navigate({ pathname: MENUPATH_USER })
            })
            .catch( error => {
                reportError(error, "Failed to create new user.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //-----------
    // Load sites
    //-----------
    const getAllowableSitesOption = (defaultSiteId) => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}site/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            const data = response.data.results.filter(site => site.pKey != defaultSiteId)
            let options = data.map( site => <Option key={site.pKey}>{site.name}</Option>)
            setAllowedSitesOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get site(s).")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = async (e) => {
        form.validateFields()
        .then( values => {
            if(values.password.trim().length < 8) {
                message.warn("Invalid password.")
                return
            }

            if(values.confirmPassword.trim().length < 8) {
                message.warn("Invalid confirm password.")
                return
            }

            if(values.password != values.confirmPassword) {
                message.warn("Password do not match.")
                return
            }
            else {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Create user confirmed?</p></Space>,
                    onOk() { createUser() },
                    onCancel() {},
                    centered: true
                })
            }
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_USER
        })
    }

    //---------------------
    // On change
    //---------------------
    const onDefaultSiteChange = (e, value) => {
        setAllowedSitesOption([])
        form.setFieldsValue({
            allowedSites: []
        })
        
        if(e != undefined) {
            setDefaultSiteId(e)
            getAllowableSitesOption(e)
        }
        else 
            setDefaultSiteId(0)
    }

    const onAllowedSitesChange = (e) => {
        if(e != undefined) 
            setAllowedSiteIds(e)
        else 
            setDefaultSiteId([])
    }

    const onUserGroupChange = (e, value) => {
        if(e != undefined) 
            setUserGroupId(e)
        else 
            setUserGroupId(0)
    }

    const onIsPendingTransactionApproverChange = (checked) => {
        setIsPendingTransactionApprover(checked)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="User">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create user</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item name="username" label="Username"
                        rules={[
                            { required: true, message: "Username is required."},
                        ]}>
                        <Input maxLength={150} placeholder="Username"/>
                    </Form.Item>

                    <Form.Item name="firstName" label="First Name"
                        rules={[
                            { required: true, message: "First name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="First Name"/>
                    </Form.Item>

                    <Form.Item name="lastName" label="Last Name"
                        rules={[
                            { required: true, message: "Last name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Last Name"/>
                    </Form.Item>

                    <Form.Item name="email" label="Email"
                        rules={[
                            { required: true, type: "email", message: "Email is required."},
                        ]}>
                        <Input maxLength={254} placeholder="Email"/>
                    </Form.Item>

                    <Form.Item name="whatsappContact" label="Whatsapp Contact">
                        <Input maxLength={100} placeholder="Whatsapp Contact"/>
                    </Form.Item>

                    <Form.Item name="password" label="Password"
                        rules={[
                            { required: true, message: "Password is required."},
                        ]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="New Password"/>
                    </Form.Item>

                    <Form.Item name="confirmPassword" label="Confirm Password"
                        rules={[
                            { required: true, message: "Confirm password is required." },
                        ]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="Confirm New Password"/>
                    </Form.Item>

                    <Form.Item name="defaultSite" label="Default Site"
                        rules={[
                            { required: true, message: "Default site is required." },
                        ]}>
                        <SiteSelect withBlank={false} onChange={onDefaultSiteChange}/>
                    </Form.Item>

                    <Form.Item name="allowedSites" label="Other Allowed Site(s)">
                        <Select style={{width: optionWidth}} onChange={onAllowedSitesChange} allowClear showSearch optionFilterProp="children" 
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} mode="multiple">
                            {allowedSitesOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="userGroup" label="User Group"
                        rules={[
                            { required: true, message: "User Group is required." },
                        ]}>
                        <UserGroupSelect withBlank={false} onChange={onUserGroupChange}/>
                    </Form.Item>

                    <Form.Item name="isPendingTransactionApprover" label="Is Pending Transaction Approver">
                        <Switch defaultChecked={isPendingTransactionApprover} onChange={onIsPendingTransactionApproverChange} />
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="User:"
                    subTitle="Create user"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default UserNew