import { Table, Row, Col, Space, Button, Form, message, Tag, Modal, Popconfirm, InputNumber, Typography, Input, Pagination, DatePicker } from 'antd'
import { LeftOutlined, MinusOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { useNavigate } from 'react-router-dom'
import { ACISAPIURL, DATETIMEFORMAT, MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, SYSPARAM } from "../Common/UserSession"
import { reportError } from '../Common/Utility'
import { formLayout } from '../Common/Layout'
import TransactionTypeSelect from '../Common/TransactionTypeSelect'
import { releaseStorage } from '../Common/releaseStorage'
import { BatchColumn, DesktopColumns, RFIDColumn } from '../Common/CommonTableColumns'
import moment from 'moment'

const { confirm } = Modal
const { TextArea } = Input
const { Title } = Typography

//----------
// Component
//----------
const FishStockOutTable = () => {
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const navigate = useNavigate()
    
    const [isActive, setIsActive] = useState(true)
    const [storageId, setStorageId] = useState(0)
            
    const [fishId, setFishId] = useState(0)
    const [transactionTypeId, setTransactionTypeId] = useState(0)
    const [transactionType, setTransactionType] = useState("")
    
    const [selectedFishKeys, setSelectedFishKeys] = useState([])
    //const [selectedFish, setSelectedFish] = useState([])
                    
    const [fishDataSource, setFishDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const [stockOutDateTime, setStockOutDateTime] = useState(moment(moment(), UNIDATEFORMAT))
        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //------------------------------
    // Create stock-out transaction
    //------------------------------
    const createStockOut = () => {
        // Disable button.
        setDisableButton("disabled")
        
        const selectedFish = fishDataSource
            .filter(fish => selectedFishKeys.includes(fish.key))
            .map(fish => ({pKey: fish.key, weight: fish.averageWeight}))
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}stocktransaction/createindividualfishtockout/`, {
                fish: selectedFish,
                transaction_type: transactionTypeId,
                average_per_unit_weight_gram: values.averagePerUnitWeightGram,
                remark: values.remark,
                transactedOn: stockOutDateTime,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Stock-Out transaction was successful.")
                navigate({ 
                    pathname: MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE
                })

                releaseStorage(urlParams.get("batchId"), urlParams.get("batch"), urlParams.get("storageId"))
            })
            .catch( error => {
                reportError(error, `Failed to create stock-out transaction.`)
            })
            .finally(() => {
                setDisableButton("")
                refreshUserSession()
            })
        })
    }

    //---------------
    // Search fish
    //---------------
    const searchFish = (currentPage, targetFishId = 0, targetStorageId = 0) => {
        setDisableButton("disabled")
        
        axios.get(`${ACISAPIURL}fish/`, {
            params: { 
                site: getUserSiteId(),
                id: targetFishId != 0 ? targetFishId : fishId,
                storage: targetStorageId != 0 ? targetStorageId : storageId,
                active: isActive,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search fish list.")
        })
        .finally(() => {
            setDisableButton("")
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( fish => {
            data.push({
                key: fish.pKey,
                id: fish.id,
                species: fish.batch_data.species_data.short_name,
                acquiringMethod: fish.batch_data.acquiring_method_data.name,
                aquacultureStage: fish.aquaculture_stage_data.name,
                lifecycle: fish.lifecycle_data.name,
                storage: fish.storage_data.id,
                sourceStorageId: fish.storage_data.pKey,
                weight: fish.average_per_unit_weight_gram,
                gender: fish.gender_data.name,
                genderId: fish.gender_data.pKey,
                tagId: fish.tag_id != null ? fish.tag_id : "",
                isBrood: fish.isBrood,
                batch: fish.batch_data.id,
                active: fish.active,
                averageWeight: fish.average_per_unit_weight_gram
            })
        })
        
        setFishDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFish(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //--------
    // On next
    //--------
    const onNext = () => {
        if(selectedFishKeys.length == 0) {
            message.warn("Please select fish to proceed.")
            return
        }

        let msg = "Stock out confirmed?"
        if(String(transactionType.children).startsWith("Stock Adjustment"))
        msg = <Title level={5}>
            Stock adjustment transaction require approval from the management before it is committed. You may need to contact the mangement to explain what this adjustment is about.
            <br/><br/>You can view pending stock transaction under "Inventory/Pending Stock Transaction" in the menu.
            <br/><br/>Stock out confirmed?
            </Title>

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>{msg}</p></Space>,
            onOk() { createStockOut() },
            onCancel() {},
            centered: true
        })
    }

    //---------------------------
    // On transaction type change
    //---------------------------
    const onTransactionTypeChange = (e, value) => {
        setTransactionTypeId(e)
        setTransactionType(value)
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setStockOutDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateStockOutDateTime = (() => {
        if(stockOutDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Stock-Out date time is required."))
    })

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONSOURCESTORAGE
        })
    }

    //--------------------------------------------------------
    // Update total selected quantity and total average weight
    //--------------------------------------------------------
    const updateQuantityAndTotalAverageWeight = (selectedFishKeys, fishDataSource) => {
        // Calculate average weight of selected fish.
        let averageWeight = 0
        if(selectedFishKeys.length > 0) {
            const selectedRows = fishDataSource.filter(fish => selectedFishKeys.includes(fish.key))
            const allWeight = selectedRows.map( fish => parseFloat(fish.averageWeight) >= 0 ? parseFloat(fish.averageWeight) : 0)
            const reducer = (accumulator, curr) => accumulator + curr
            averageWeight = allWeight.reduce(reducer) / selectedRows.length
        }
                        
        form.setFieldsValue({
            averagePerUnitWeightGram: averageWeight,
            quantity: selectedFishKeys.length
        })
    }

    //---------------------------
    // On fish selection change
    //---------------------------
    const onSelectionChange = (selectedRowKeys, selectedRows) => {
        setSelectedFishKeys(selectedRowKeys)
        //setSelectedFish(selectedRows)

        updateQuantityAndTotalAverageWeight(selectedRowKeys, fishDataSource)
    }

    const rowSelection = {
        selectedFishKeys,
        onChange: onSelectionChange
    }

    //------------------
    // Custom validation
    //------------------
    const validateTransactionType = (() => {
        if(transactionTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Transaction type is required."))
    })

    //-----------------
    // On weight change
    //-----------------
    const onWeightChange = (e, record) => {
        record.averageWeight = e
        
        let rowIndex = fishDataSource.findIndex(item => item.key == record.key)
        if(rowIndex >= 0) {
            fishDataSource[rowIndex] = record
            setFishDataSource([])
            setFishDataSource(fishDataSource)
        }

        updateQuantityAndTotalAverageWeight(selectedFishKeys, fishDataSource)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            //averagePerUnitWeightGram: Math.round(urlParams.get("batchAverageWeight")),
            remark: ""
        })

        if(urlParams.has("fishId")) { // From Fish Update page - Stock-Out
            setFishId(urlParams.get("fishId"))
            searchFish(currentPage, urlParams.get("fishId"))
        }
        else if(urlParams.has("storageId")) {// From Inventory Stock-Out page
            setStorageId(urlParams.get("storageId"))
            searchFish(currentPage, 0, urlParams.get("storageId"))
        }
        else
            searchFish(currentPage, )

        if(parseInt(urlParams.get("transactionTypeId")) > 0) setTransactionTypeId(urlParams.get("transactionTypeId"))
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        BatchColumn(),
        { title: 'Storage ID', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Tag ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        ...DesktopColumns(),
        { title: 'Weight (g)', dataIndex: 'weight', key: 'weight', //sorter: (a, b) => a.weight - b.weight },
            render: (weight, record) => {
                return <InputNumber defaultValue={weight} min={0.01} max={1000000} step={10} precision={2} placeholder="Weight(g)" onChange={e => onWeightChange(e, record)}/>
            }
        },
        { title: 'Gender', dataIndex: 'genderId', key: 'genderId', sorter: (a, b) => a.genderId - b.genderId,
            render: (genderId, record) => {
                if(genderId == SYSPARAM("Male"))
                    return <Tag color="blue">{record.gender}</Tag>
                else if(genderId == SYSPARAM("Female"))
                    return <Tag color="red">{record.gender}</Tag>
                else 
                    return <Tag color="green">{record.gender}</Tag>
            }
        },
        { title: 'Is Brood', dataIndex: 'isBrood', key: 'isBrood', sorter: (a, b) => a.isBrood - b.isBrood,
            render: (isBrood) => {
                if(isBrood)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        RFIDColumn()
    ]

    return(
        <>
        <Form form={form} onFinish={onNext} {...formLayout}>
            <Form.Item name="stockOutDateTime" label="Stock-Out Date Time"
                rules={[
                    { required: true, validator: validateStockOutDateTime },
                ]}>
                <DatePicker showTime onChange={onDateTimeChange} defaultValue={stockOutDateTime} format={DATETIMEFORMAT}/>
            </Form.Item>
            
            <Form.Item name="transactionType" label="Transaction Type"
                rules={[
                    { required: true, validator: validateTransactionType },
                ]}>
                <TransactionTypeSelect 
                    defaultValue={urlParams.get("transactionType")} 
                    withBlank={false} 
                    value={transactionType} 
                    type={OTHERSYSPARAM("STOCK_OUT")} 
                    onChange={onTransactionTypeChange} 
                    excludes={[SYSPARAM("Mortality"), SYSPARAM("Stock Count Variance Adjustment (Out)")]} />
            </Form.Item>

            <Form.Item name="quantity" label="Quantity (Tail)">
                <InputNumber min={1} max={1000000} step={1} precision={0} placeholder="Quantity (tail)" readOnly={true} style={{width: parseInt(OTHERSYSPARAM("DATE_PICKER_WIDTH"))}}/>
            </Form.Item>

            <Form.Item name="averagePerUnitWeightGram" label="Average Per Unit Weight (g)">
                <InputNumber min={1} max={1000000} step={1} precision={2} placeholder="Average Per Unit Weight (g)" readOnly={true} style={{width: parseInt(OTHERSYSPARAM("DATE_PICKER_WIDTH"))}}/>
            </Form.Item>

            <Form.Item name="remark" label="Remark"
                rules={[
                    { required: true, message: "Remark is required."},
                ]}>
                <TextArea rows={3} maxLength={500} />
            </Form.Item>
        
            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={12} style={{textAlign: "start"}}>
                    <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={12} style={{textAlign: "right"}}>
                    <Button danger type="primary" htmlType="submit" icon={<MinusOutlined />}>Stock-Out</Button>
                </Col>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>

            <Table bordered columns={columns} dataSource={fishDataSource} pagination={false} rowSelection={rowSelection} /*onRow={onRowClick}*/ />

            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={3} style={{textAlign: "start"}}>
                    <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={18} style={{textAlign: "center"}}>
                    <Pagination
                        size={PAGINATIONSIZE}
                        total={totalRecord}
                        showTotal={showTotal}
                        pageSize={PAGESIZE}
                        current={currentPage}
                        hideOnSinglePage={false}
                        showSizeChanger={false}
                        onChange={onPaginationChange}/>
                </Col>
                <Col span={3} style={{textAlign: "right"}}>
                    <Button danger type="primary" htmlType="submit" icon={<MinusOutlined />}>Stock-Out</Button>
                </Col>
            </Row>
        </Form>
        </>
    )
}

export default FishStockOutTable