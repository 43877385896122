import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import StockCountTaggedFishSurplusTable from './StockCountTaggedFishSurplusTable'
import { OTHERSYSPARAM } from '../Common/UserSession'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const StockCountTaggedFishSurplus = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={null} 
                    title="Stock Count">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Tagged fish surplus</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><div /></Space></Col></Row>
                
                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><StockCountTaggedFishSurplusTable /></Col>
                    <Col span={1}></Col>
                </Row>

            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={null} 
                        title="Stock Count:"
                        subTitle="Tagged fish surplus"/>} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default StockCountTaggedFishSurplus