import { message, Modal, Space } from "antd"
import axios from "axios"
import { ACISAPIURL } from "./SystemParameter"
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./UserSession"
import { reportError } from "./Utility"
import { QuestionCircleOutlined } from "@ant-design/icons"
const { confirm } = Modal

//-------------------------------------------------
// Check if storage is empty and reservation status
//-------------------------------------------------
export const releaseStorage = (batchId, batch, storageId) => {
    axios.get(`${ACISAPIURL}storage/${storageId}/${batchId}/`, {
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
        headers: {"Authorization": `Token ${getUserAuthToken()}`}
    })
    .then( response => {
        const storage = response.data
                
        let isEmpty = true
        storage.storagestocksummary.forEach( summary => {
            if(summary.quantity > 0) isEmpty = false
        })

        if(isEmpty) 
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>{`Storage ${storage.name} is now empty. Release the storage?`}</p></Space>,
                onOk() { releaseThisStorage(storageId, batchId, batch) },
                onCancel() {},
                okText: OTHERSYSPARAM("YES"),
                cancelText: OTHERSYSPARAM("NO"),
                centered: true
            })
    })
    .catch( error => {
        reportError(error, "Attempt to release storage failed.")
    })
    .finally(() => {
        refreshUserSession()
    })

    //---------------------------
    // Release storage from batch
    //---------------------------
    const releaseThisStorage = (storageId, batchId, batch) => {
        axios.patch(`${ACISAPIURL}storage/release/`, {
            batch: batchId,
            storage: storageId
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Empty storage released.`, 5)
            lockBatch(batchId, batch)
        })
        .catch( error => {
            reportError(error, "<releaseThisStorage> Failed to release storage.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
}

export const lockBatch = (batchId, batch) => {
    axios.get(`${ACISAPIURL}storageassigned/`, {
        params: { 
            batch: batchId
        },
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
        headers: {"Authorization": `Token ${getUserAuthToken()}`}
    })
    .then( response => {
        if(response.data.results.length == 0) {
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>{`Batch ${batch} has no more storage assigned. Lock the batch?`}</p></Space>,
                onOk() { 
                    lockThisBatch(batchId, batch)
                    .then(() => {
                        // Refresh screen to avoid locked batch appear on screen.
                        window.location.reload()
                    })
                    .catch(error => {
                        reportError(error, "Failed to lock batch.")
                    })
                },
                onCancel() {},
                okText: OTHERSYSPARAM("YES"),
                cancelText: OTHERSYSPARAM("NO"),
                centered: true
            })
        }
    })
    .catch( error => {
        reportError(error, "Attempt to lock batch failed.")
    })
    .finally(() => {
        refreshUserSession()
    })
}

//-----------
// Lock batch
//-----------
export const lockThisBatch = async (batchId, batch) => {
    return await axios.patch(`${ACISAPIURL}batch/lock/${batchId}/`, 
        {}, { 
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
        headers: {"Authorization": `Token ${getUserAuthToken()}`}
    })
    .then( response => {
        message.info(`Batch ${batch} locked.`, OTHERSYSPARAM("LONG_MSG_DURATION"))
    })
    .catch( error => {
        reportError(error, "<lockThisBatch> Failed to lock batch.")
    })
    .finally(() => {
        refreshUserSession()
    })
}