import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL, MEDIAMAXWIDTH,  } from '../Common/SystemParameter'
import { reportError } from '../Common/Utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "../Common/UserSession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select

//----------
// Component
//----------
const MortalityReasonSelect = ({withBlank, defaultValue, onChange}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [mortalityReasonOption, setMortalityReasonOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    
    //---------
    // Load all
    //---------
    const getAllMortalityReason = () => {
        axios.get(`${ACISAPIURL}mortalityreason/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( reason => <Option key={reason.pKey}>{reason.name}</Option> ))
            setMortalityReasonOption(options)
        })
        .catch( error => {
            reportError(error, "<MortalityReasonSelect /> Failed to get mortality reasons.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllMortalityReason()
    }, []) 
    
    return(
        <Select defaultValue={defaultValue} style={{width: optionWidth}} onChange={(e, value) => onSelectionChange(e, value)} allowClear showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {mortalityReasonOption}
        </Select>
    )
}

export default MortalityReasonSelect