import { useEffect } from 'react'
import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import GrowthDevelopmentDailyAverageByStorageTable from './GrowthDevelopmentDailyAverageByStorageTable'
import { OTHERSYSPARAM } from '../Common/UserSession'
import { walltiles } from '../Common/Layout'
import { MENUPATH_GROWTHDEV } from '../Common/SystemParameter'
import { useNavigate } from 'react-router-dom'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const GrowthDevelopmentDailyAverageByStorage = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    
    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_GROWTHDEV, 
            search: `?batchId=${urlParams.get("batchId")}`
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return (
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{ minHeight: contentHeight, ...walltiles }}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader
                    onBack={() => onBack()}
                    title="Growth Development">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Manage growth development by storage</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><GrowthDevelopmentDailyAverageByStorageTable /></Col>
                    <Col span={1}></Col>
                </Row>

            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader
                        onBack={() => onBack()}
                        title="Growth Development:"
                        subTitle="Manage growth development by storage" />} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default GrowthDevelopmentDailyAverageByStorage