import { Row, Col, Space, Button, Form, Collapse, message, Card, Typography, Spin } from 'antd'
import { useEffect, useState, useRef } from 'react'
import axios from "axios"
import { ACISAPIURL, DATETIMEFORMAT, ENVDATA_VALUE_DECIMALPLACES, LOADING, UNTAGGED } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { getCookie, reportError } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import StorageWithWaterQualitySensorSelect from '../Common/StorageWithWaterQualitySensorSelect'

const { Panel } = Collapse
const { Title } = Typography

//----------
// Component
//----------
const CentralisedConsoleWaterQualityTable = () => {
    const MAXSTORAGE = OTHERSYSPARAM("CONSOLE_MAX_STORAGE")
    const LASTNMINUTE = OTHERSYSPARAM("CONSOLE_ENV_HISTORY_LAST_N_MINUTE")
        
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [readingType, setReadingType] = useState([])
    const [storageIds, setStorageIds] = useState([])
    const [storageNames, setStorageNames] = useState([])
    const [storageCards, setStorageCards] = useState([])
    
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
        
    let intervalIdRef = useRef()

    const isDarkTheme = getCookie('useDarkTheme') == '1' ? true : false

    //------------
    // Main search
    //------------
    const bulkInfoSearch = async () => {
        setDisableButton("disabled")
        setIsLoading(true)

        const storageData = []
        const lastEnvironmentData = []
        
        //--------
        // Storage
        //--------
        //await axios.get(`${ACISAPIURL}storagefull/`, {
        await axios.get(`${ACISAPIURL}storagewithwqsensorfull/`, {
            params: { 
                site: getUserSiteId(),
                ids: storageIds.toString(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 10),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.results.forEach( storage => {
                // Storage info
                storageData.push({
                    pKey: storage.pKey,
                    name: storage.name,
                    type: storage.storage_type_data.name,
                })
            })
        })
        .catch( error => {
            reportError(error, "Failed to search storage information.")
        })
        
        //------------------------
        // Latest environment data
        //------------------------
        await axios.get(`${ACISAPIURL}lastenvironment/`, {
            params: { 
                site: getUserSiteId(),
                storages: storageIds.toString(),
            },
            timeout: 60 * 1000,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.forEach( history => {
                lastEnvironmentData.push({
                    //datetime: moment(history[0]).format(DATETIMEFORMAT),
                    datetime: moment(history[0]),
                    value: history[1],
                    readingType: history[2],
                    storage: history[3],
                    uom: history[4]
                })
            })
        })
        .catch( error => {
            reportError(error, "Failed to search environment data.")
        })

        //------------
        // Build cards
        //------------
        try {
            const allCards = []
            
            //--------
            // Storage
            //--------
            storageData.forEach( storage => {
                //-----------------
                // Environment data
                //-----------------
                const envData = lastEnvironmentData.filter( env => env.storage == storage.name)
                
                const environmentDataCards = []
                readingType.forEach( type => {
                    const reading = envData.filter(env => env.readingType.startsWith(type.name))

                    environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc" style={{fontStyle: "italic"}}>{type.name}:</Card.Grid>)

                    if(reading.length > 0) {
                        let color = ""
                        if(!isDarkTheme) color = "#e6f7ff"
                        
                        let className = ""
                        if(reading[0].value < type.minSafeValue || reading[0].value > type.maxSafeValue || 
                            // Older than last n minutes
                            moment(reading[0].datetime).add(LASTNMINUTE, 'minutes').isBefore(moment())) {
                            if(!isDarkTheme) color = "#fff2f0"
                            className = "blink"
                        }

                        const value = `${parseFloat(reading[0].value).toFixed(ENVDATA_VALUE_DECIMALPLACES.get(type.name))} ${reading[0].uom} @ ${reading[0].datetime.format(DATETIMEFORMAT)}`
                        environmentDataCards.push(
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc" style={{backgroundColor: color}} onClick={(e) => showGraph(e, storage.name, storage.pKey)}>
                                <div className={className}>
                                    {value}
                                </div>
                            </Card.Grid>)
                    }
                    else
                        environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">-</Card.Grid>)
                })

                // To fully occupied one row.
                environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc" style={{fontStyle: "italic"}}><br /></Card.Grid>)
                environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc"><br /></Card.Grid>)
                environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc" style={{fontStyle: "italic"}}><br /></Card.Grid>)
                environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc"><br /></Card.Grid>)
                    
                //----------
                // Main card
                //----------    
                const mainCard = <>
                    <Row><Col><Space><div /></Space></Col></Row>
                    <Row justify="center">
                        <Col style={{textAlign: "left"}} span={24}>
                            <Card title={<Title>{storage.name}</Title>} style={{ width: '100%' }}>
                                {environmentDataCards}
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Storage Type:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-83pc">{storage.type}</Card.Grid>
                            </Card>
                        </Col>
                    </Row>
                    </>

                allCards.push(mainCard)
            })

            setStorageCards(allCards)
        }
        catch(error) {
            message.error(error.toString())
        }
        finally {
            // Restore user interface.
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()

            return Promise.resolve()
        }
    }

    //-----------------------------
    // Show environment data graphs
    //-----------------------------
    const showGraph = (e, storageName, storagePKey) => {
        window.open(
            window.location.origin + `/centralisedconsolegraph/?storageName=${storageName}&storagePKey=${storagePKey}`, 
            storageName,
            //"resizable,scrollbars,status");
            "popup");
    }

    //----------------------------------
    // Get all environment reading type.
    //----------------------------------
    const getAllEnvironmentReadingType = () => {
        axios.get(`${ACISAPIURL}environmentreadingtype/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const data = []
            response.data.results.forEach( type => {
                data.push({
                    name: type.name,
                    uom: type.uom,
                    minSafeValue: type.min_safe_value,
                    maxSafeValue: type.max_safe_value,
                })
            })

            setReadingType(data)
        })
        .catch( error => {
            reportError(error, "Failed to get all environment reading types.")
        })
    }

    //--------------------------------------------
    // Retrieve data and refresh on fixed interval
    //--------------------------------------------
    const startDisplayLoop = () => {
        clearInterval(intervalIdRef.current)
        bulkInfoSearch()
        
        intervalIdRef.current = setInterval(() => {
            bulkInfoSearch()
        }, parseInt(OTHERSYSPARAM("CONSOLE_REFRESH_MINUTE")) * 60000)
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        if(storageIds.length == 0 || storageIds.length == undefined) {
            message.info(`Please select storage before search.`)
            return
        }

        if(storageIds.length > MAXSTORAGE) {
            message.info(`Maximum number of storages that can be selected are ${MAXSTORAGE} only. ${storageIds.length} were selected.`)
            return
        }

        startDisplayLoop()
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchStorageChange = (e, value) => {
        setStorageIds(e)
        if(value.length > 0) setStorageNames(value.map( v => v.children))
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllEnvironmentReadingType()

        // ComponentWillUnmount
        return () => {
            clearInterval(intervalIdRef.current)
        }
    }, [])

    //--------------
    // Table columns
    //--------------
    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search Batch" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="storage" label="Storage" >
                                <StorageWithWaterQualitySensorSelect onChange={onSearchStorageChange} storageSelectMode="multiple"/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton} isLoading={isLoading}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form> 
                    </Panel>
                </Collapse>
            </Col>
        </Row>
        {storageCards}
        </Spin>
        </>
    )
}

export default CentralisedConsoleWaterQualityTable

