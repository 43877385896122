import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Row, Col, Select, Modal, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { formLayout } from '../Common/Layout'
import SpeciesSelect from '../Common/SpeciesSelect'
import axios from 'axios'
import { ACISAPIURL, LOADING, MENUPATH_STANDARDGROWTHTABLE } from '../Common/SystemParameter'
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { reportError } from '../Common/Utility'

const { Option } = Select
const { confirm } = Modal

const StandardGrowthTableNewTable = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [speciesId, setSpeciesId] = useState(0)
    
    //------------------------------
    // Create standard growth record
    //------------------------------
    const createStandardGrowthTable = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}standardgrowth/table/create/`, {
                site: getUserSiteId(),
                species: speciesId,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Standard growth table created.")

                navigate({ 
                    pathname: MENUPATH_STANDARDGROWTHTABLE
                })
            })
            .catch( error => {
                reportError(error, `Failed to create standard growth table.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Currently active table will be archived. This newly created table will become the new de facto. Confirmed?</p></Space>,
            onOk() { createStandardGrowthTable() },
            onCancel() {},
            centered: true
        })
    }

    //---------------------------
    // On cancel
    //---------------------------
    const onCancel = () => {
        navigate({ pathname: MENUPATH_STANDARDGROWTHTABLE })
    }

    const onSpeciesChange = (e, value) => {
        setSpeciesId(e)
    }

    //------------------
    // Custom validation
    //------------------
    const validateSpecies = (() => {
        if(speciesId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Species is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form form={form} onFinish={onSave} {...formLayout}>
            <Form.Item name="species" label="Species"
                rules={[
                    { required: true, validator: validateSpecies },
                ]}>
                <SpeciesSelect withBlank={false} width={"30.5vw"} onChange={onSpeciesChange}/>
            </Form.Item>

            <Row justify="center">
                <Col span={6}></Col>
                <Col span={12} style={{textAlign: "center"}}>
                    <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Save</Button>
                    <Button type="primary" htmlType="button" disabled={disableButton} onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                </Col>
                <Col span={6}></Col>
            </Row>
        </Form>
        </Spin>
    )
}

export default StandardGrowthTableNewTable