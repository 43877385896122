import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, message, Image, Layout, Row, Col, Spin, Popover, Modal, Space, Typography } from 'antd'
import { UserOutlined, LockOutlined, InfoCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import { ACISURL, ALLOWED_SITES, BAYUAQUAURL, LOADING, LOGO, MEDIAMAXWIDTH, MENUPATH_DASHBOARD, MENUPATH_PENDINGSTOCKTRANSACTION, TIMEOUT, WALLPAPER } from "./SystemParameter"
import { clearUserSession, createUserSession, getUserSiteId, OTHERSYSPARAM, setUserSite, storeSystemParameter, userIsPendingTransactionApprover } from "./UserSession"
import ACISFooter from './ACISFooter'
import { getPendingStockTransactionCount, reportError } from "./Utility"
import { formLayout } from "./Layout"
import { useMediaQuery } from 'react-responsive'
import { loadSystemParameters } from "./loadSystemParameters"

const { Content, Footer } = Layout
const DEFAULTBREADCRUMB = "ACIS (Aquaculture Information System) 2022"
const { confirm } = Modal
const { Title } = Typography

//----------
// Component
//----------
const Login = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    //const [showSpin, setShowSpin] = useState("none")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
              
    //--------------
    // To login
    //--------------
    const onSubmit = () => {
        // Disable login button.
        setDisableButton("disabled")
        //setShowSpin("")
        setLoading(true)

        form.validateFields()
        .then( values => {
            axios.post(`${ACISURL}rest-auth/login/`, {
                username: values.username,
                password: values.password
            }, {
                timeout: parseInt(TIMEOUT) * 2
            })
            .then( response => {
                const defaultSite = response.data.allowed_sites.filter(site => site.default == true)
                storeSystemParameter(ALLOWED_SITES, response.data.allowed_sites.map(site => site.site))
                     
                createUserSession(
                    values.username, 
                    response.data.user_groups.map( group => group.name), 
                    defaultSite[0].site_data.pKey,
                    defaultSite[0].site_data.name,
                    response.data.key,
                    response.data.other_info.is_pending_transaction_approver)

                // Load system parameters.
                loadSystemParameters()
                .then( async () => {
                    if(isTabletOrMobile) message.info("For Tablet or iPad, please kindly rotate to landscape mode.", OTHERSYSPARAM("LONG_MSG_DURATION"))

                    // Get pending stock transaction sites.
                    const pendingStockTransactionSiteNameArray = await getPendingStockTransactionCount()
                    
                    if(userIsPendingTransactionApprover() == 1 && pendingStockTransactionSiteNameArray.length > 0) {
                        const siteNameArray = pendingStockTransactionSiteNameArray.map( site => site.name)

                        confirm({
                            icon: <InfoCircleOutlined />,
                            content: <Space><p>There is stock transaction pending your approval. Do you want to view the transaction now?<br/><br/>
                                Site(s): <Title level={5} style={{ color: "blue"}}>{siteNameArray.join(", ")}</Title><br/>
                                Note: Please select the specific site where the pending stock transaction is being requested. You can navigate to Pending Stock Transaction page later under "Inventory/Pending Stock Transaction" in menu.</p>
                                </Space>,
                            okText: "Yes",
                            cancelText: "No",
                            onOk() {  
                                const sitePKeyArray = pendingStockTransactionSiteNameArray.map( site => site.pKey )
                                if(!sitePKeyArray.includes(getUserSiteId())) {
                                    setUserSite(sitePKeyArray[0])
                                }
                                
                                // Enter pending stock transaction page.
                                navigate({ pathname: MENUPATH_PENDINGSTOCKTRANSACTION })
                            },
                            onCancel() {
                                // Enter system main page.
                                navigate({ pathname: MENUPATH_DASHBOARD })
                            },
                            centered: true
                        })
                    }
                    else
                        // Enter system main page.
                        navigate({ pathname: MENUPATH_DASHBOARD })
                })
                .catch(error => {
                    clearUserSession()
                    message.error("System anomaly detected.")
                })
            })
            .catch( error => {
                if(String(error).includes("timeout")) message.error("Login timeout.")
                reportError(error)
            })
            .finally(() => {
                setDisableButton("")
                //setShowSpin("none")
                setLoading(false)
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        clearUserSession()    
    }, [])

    //-----
    // Form
    //-----
    return(
        <Spin spinning={loading} size="large" tip={LOADING}>
        <Layout>
            <Content style={{
                backgroundImage: `url(${WALLPAPER})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'}}>

                <Row justify='center'>
                    <Col>
                        <Popover content="Click here to know more about us.">
                            <Image width={150} preview={false} src="logo_bayu.png" onClick={() => window.open(BAYUAQUAURL, "_blank")}/>
                        </Popover>
                    </Col>
                </Row>
                <Row justify='center' style={{height: "calc(100vh - 170px)", alignContent: "center"}}>
                    <Col xs={24} xl={8}>
                        <Row justify="center">
                            <Col>
                                <Image src={LOGO} preview={false}/>
                            </Col>
                        </Row>
                    
                        <Form form={form} onFinish={onSubmit} {...formLayout}>
                            <Form.Item name="username" label=""
                                rules={[
                                    { required: true, message: "Username is required."},
                                ]}>
                                <Input prefix={<UserOutlined type="user" />} placeholder="Username"/>
                            </Form.Item>

                            <Form.Item name="password" label="" 
                                rules={[
                                    { required: true, message: "Password is required."},
                                ]}>
                                <Input.Password prefix={<LockOutlined type="lock" />} placeholder="Password"/>
                            </Form.Item>
                        
                            <Row justify="center">
                                <Col span={8}></Col>
                                <Col span={8} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="submit" disabled={disableButton} loading={loading} style={{ background: "#f6402d", borderColor: "white" }}>Login</Button>
                                    {/* <Spin style={{display: showSpin}} size="medium"/> */}
                                </Col>
                                <Col span={8}></Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Content>
            <Footer>
                <ACISFooter breadCrumb={DEFAULTBREADCRUMB}/>
            </Footer>
        </Layout>
        </Spin>
    )
}

export default Login