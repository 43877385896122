import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL, MEDIAMAXWIDTH,  } from "./SystemParameter"
import { reportError } from './Utility'
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from "./UserSession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select

//----------
// Component
//----------
const StorageWithWaterQualitySensorSelect = ({withBlank, defaultValue, onChange}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [storageOption, setStorageOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
        
    //---------
    // Load all
    //---------
    const getAllStorage = () => {
        axios.get(`${ACISAPIURL}storagewithwqsensorfull/`, {
            params: {
                site: getUserSiteId(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( storage => <Option key={storage.pKey}>{storage.name}</Option>))
            setStorageOption(options)
        })
        .catch( error => {
            reportError(error, "<StorageWithWaterQualitySensorSelect /> Failed to get storage with water quality sensor.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllStorage()
    }, []) 
    
    return(
        <Select defaultValue={defaultValue} style={{width: optionWidth}} onChange={onSelectionChange} allowClear showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} mode='multiple'>
            {storageOption}
        </Select>
    )
}

export default StorageWithWaterQualitySensorSelect