import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import PhysicallyTaggingFishTable from './PhysicalTaggingFishTable'
import { OTHERSYSPARAM } from '../Common/UserSession'
import { useNavigate } from 'react-router-dom'
import { MENUPATH_PHYSICALTAGGING } from '../Common/SystemParameter'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const PhysicalTaggingFish = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_PHYSICALTAGGING,
        })
    }

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={() => onBack()} 
                    title="Physical Tagging">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Checklist</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><div /></Space></Col></Row>
                
                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><PhysicallyTaggingFishTable /></Col>
                    <Col span={1}></Col>
                </Row>

            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={() => onBack()} 
                        title="Physical Tagging:"
                        subTitle="Checklist"/>} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default PhysicalTaggingFish