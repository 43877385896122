import  { useEffect, useState } from 'react'
import { Button, Col, Row, Space, Table, Card, Popconfirm, message, Tooltip, Pagination, Spin } from "antd"
import { LeftOutlined, DeleteOutlined, UpSquareOutlined } from "@ant-design/icons"
import axios from 'axios'
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MENUPATH_STANDARDGROWTHTABLE, PAGESIZE, PAGINATIONSIZE } from '../Common/SystemParameter'
import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { reportError } from '../Common/Utility'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const StandardGrowthTableArchiveTable = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    
    const [growthRecordDataSource, setGrowthRecordDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    //------------------------------
    // Unarchive standard growth table
    //------------------------------
    const unarchiveStandardGrowthTable = (e, record) => {
        e.stopPropagation()
        setIsLoading(true)

        axios.patch(`${ACISAPIURL}standardgrowth/table/unarchive/`, {
            standard_growth_table: record.key, 
        }, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Standard growth table unarchived.")

            navigate({ 
                pathname: MENUPATH_STANDARDGROWTHTABLE,
            })
        })
        .catch( error => {
            reportError(error, `Failed to unarchive standard growth table.`)
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    //--------------------------
    // Delete stock count record
    //--------------------------
    const deleteStandardGrowthTable = (e, record) => {
        e.stopPropagation()
        setIsLoading(true)

        axios.delete(`${ACISAPIURL}standardgrowth/table/delete/${record.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Standard growth table deleted.`)
            setGrowthRecordDataSource(growthRecordDataSource.filter((item) => item.key !== record.key))
        })
        .catch( error => {
            reportError(error, "Failed to delete standard growth table.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------------
    // Get latest growth record
    //-------------------------
    const getGrowthTable = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}standardgrowth/table/`, {
            params: { 
                site: getUserSiteId(),
                active: false,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            pushData(response)
        })
        .catch( error => {
            reportError(error, "Failed to search standard growth record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const pushData = (response) => {
        const data = []
        response.data.results.forEach( table => {
            data.push({
                key: table.pKey,
                species: table.species_data.short_name,
                speciesId: table.species_data.pKey,
                numberOfRecord: table.record_count,                
                createdOn: moment(table.createdOn).format(DATETIMEFORMAT),
                updatedOn: table.createdOn != table.updatedOn ? moment(table.updatedOn).format(DATETIMEFORMAT) : "",
            })
        })
        
        setGrowthRecordDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        getGrowthTable(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/standardgrowth", 
                    search: `?pKey=${record.key}&fromArchive=true`
                }) 
            }
        }
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_STANDARDGROWTHTABLE
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getGrowthTable(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Number of Records', dataIndex: 'numberOfRecord', key: 'numberOfRecord', sorter: (a, b) => a.numberOfRecord - b.numberOfRecord },
        { title: 'Created On', dataIndex: 'createdOn', key: 'createdOn', sorter: (a, b) => a.createdOn.localeCompare(b.createdOn) },
        { title: 'Updated On', dataIndex: 'updatedOn', key: 'updatedOn', sorter: (a, b) => a.createdOn.localeCompare(b.createdOn) },
        { key: 'action', align: 'center',
            render: (record) => {
                return <>
                    <Popconfirm title="Unarchive standard growth table will replace the active table but no record will be deleted. Confirmed?" 
                        onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => unarchiveStandardGrowthTable(e, record)} 
                        okText="Yes" cancelText="No">
                        <Tooltip title="Unarchive" >
                            <Button type="primary" disabled={disableButton} icon={<UpSquareOutlined/>} />
                        </Tooltip>    
                    </Popconfirm>

                    <Popconfirm title="Delete standard growth table confirmed?" 
                        onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => deleteStandardGrowthTable(e, record)} 
                        okText="Yes" cancelText="No">
                        <Button danger type="primary" disabled={disableButton} icon={<DeleteOutlined/>} />
                    </Popconfirm>
                    </>
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row justify="center">
            <Col span={8} style={{textAlign: "center"}}>
                <Card title="Archived Standard Growth Table" style={{textAlign: "left"}}>
                    Archived standard growth table of each species.
                </Card>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={12} style={{textAlign: "start"}}>
                <Button type="primary" htmlType="button" onClick={onBack} icon={<LeftOutlined />} disabled={disableButton}>Back</Button>
            </Col>
            <Col span={12}></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={growthRecordDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}>
                <Button type="primary" htmlType="button" onClick={onBack} icon={<LeftOutlined />} disabled={disableButton}>Back</Button>
            </Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3}></Col>
        </Row>
        </Spin>
        </>
    )
}

export default StandardGrowthTableArchiveTable