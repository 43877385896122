import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Layout, Row, Col, Typography, Popconfirm, InputNumber, DatePicker, Card, PageHeader, Input, Modal, Descriptions, Table, Tag, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, EGGS, KG, LOADING, MENUPATH_MORTALITY, PERCENT, PRAWNMARINELIFEPKEY, TAGGED, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { numberWithCommas, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, SYSPARAM, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import moment from 'moment'
import MortalityReasonSelect from './MortalityReasonSelect'
import TextArea from 'antd/lib/input/TextArea'
import { releaseStorage } from '../Common/releaseStorage'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal

//----------
// Component
//----------
const MortalityUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    
    const [mortalityReasonId, setMortalityReasonId] = useState(parseInt(urlParams.get("mortalityReasonId")))
    const [mortalityDateTime, setMortalityDateTime] = useState(moment(moment(urlParams.get("mortalityDateTime")).add(1, "seconds"), UNIDATETIMEFORMAT))
    const [remark, setRemark] = useState(null)
    const [hideButton, setHideButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [fishDataSource, setFishDataSource] = useState([])
    
    if(remark == null && urlParams.has("remark") && urlParams.get("remark") != "") {
        setRemark(urlParams.get("remark"))
        form.setFieldsValue({
            remark: urlParams.get("remark") == "undefined" ? "" : urlParams.get("remark")
        })
    }

    //-----------------
    // Update mortality
    //-----------------
    const updateMortalityRecord = () => {
        // Disable button
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.patch(`${ACISAPIURL}mortality/update/${urlParams.get("pKey")}/`, {
            quantity: form.getFieldValue("quantity"),
            reason: mortalityReasonId,
            mortality_datetime: mortalityDateTime,
            remark: form.getFieldValue("remark")
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Mortality record ${urlParams.get("id")} updated.`)
            navigate({ pathname: MENUPATH_MORTALITY })

            releaseStorage(urlParams.get("batchId"), urlParams.get("batch"), urlParams.get("storageId"))
        })
        .catch( error => {
            reportError(error, "Failed to update mortality record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //------------------------
    // Delete mortality record
    //------------------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}mortality/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Mortality record ${urlParams.get("id")} deleted.`)
            navigate({ pathname: MENUPATH_MORTALITY })
        })
        .catch( error => {
            reportError(error, "Failed to delete mortality record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // Search fish
    //---------------
    const getTaggedFish = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}mortality/taggedfish/`, {
            params: {
                mortality: urlParams.get("pKey")
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get tagged marine life list.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( fish => {
            data.push({
                key: fish.fish_data.pKey,
                id: fish.fish_data.id,
                species: fish.fish_data.batch_data.species_data.short_name,
                acquiringMethod: fish.fish_data.batch_data.acquiring_method_data.name,
                storage: fish.fish_data.storage_data.name,
                sourceStorageId: fish.fish_data.storage_data.pKey,
                weight: fish.fish_data.average_per_unit_weight_gram,
                isBrood: fish.fish_data.isBrood,
                batch: fish.fish_data.batch_data.id,
                active: fish.fish_data.active
            })
        })
        setFishDataSource(fishDataSource.concat(data))
    }

    //----------------------------------------------------------
    // Retrieve storage protection triggered record count.
    // If count > 0, disable update and delete.
    //----------------------------------------------------------
    const getProtectionTriggeredRecordCount = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}mortality/protectiontriggeredrecordcount/`, {
            params: { 
                pKey: urlParams.get("pKey")
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(parseInt(response.data.record_found) > 0) {
                setHideButton("none")
                message.info("Protection triggered record found. Update not allowed.")
            }
        })
        .catch( error => {
            reportError(error, "Failed to retrieve protection triggered record count.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //----------
    // On update
    //----------
    const onUpdate = () => {
        form.validateFields()
        .then( values => {
            //if(urlParams.get("itemTypeUom").includes(PERCENT)) {
            if(urlParams.get("uom").includes(PERCENT)) {
                // If percentage is selected. It can't be more than 100.
                if(parseInt(values.quantity) > 100) {
                    message.warn("Mortality quantity cannot exceeded 100 percent.")
                    return
                }
                updateConfirmation()
                
            }
            else if(urlParams.get("itemType").startsWith(EGGS) && urlParams.get("uomId")== SYSPARAM("Unit")) {
                // If unit is selected. It can't be more than its mL equivalent.
                // 1 mL = 1000 units.
                const availableUnit = (parseInt(urlParams.get("availableQuantity")) + parseInt(urlParams.get("quantity2"))) * OTHERSYSPARAM(`1_ML_EGG_IN_UNIT_${urlParams.get("marineLifeId")}`)

                if(parseInt(values.quantity) > availableUnit) {
                    message.warn(`Mortality quantity cannot exceeded ${availableUnit} unit.`)
                    return
                }
                updateConfirmation()
            }
            else if(urlParams.get("uom").includes(KG)) {
                const totalAvailableWeightKg = parseFloat(urlParams.get("storageAverageWeight")) * (parseInt(urlParams.get("quantity2").replace(',','')) + parseInt(urlParams.get("availableQuantity"))) / 1000

                if(parseFloat(values.quantity) > totalAvailableWeightKg) {
                    message.warn(`Mortality weight cannot exceeded ${totalAvailableWeightKg} KG.`)
                    return
                }
                updateConfirmation()
            }
            else {
                // Untagged fish or egg-mL is selected.
                if(values.quantity > (parseInt(urlParams.get("quantity2")) + parseInt(urlParams.get("availableQuantity")))) {
                    message.warn("Mortality quantity exceeded available quantity.")
                    return
                }
                updateConfirmation()
            }
        })
    }

    //--------------------
    // Update confirmation
    //--------------------
    const updateConfirmation = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update mortality record confirmed?</p></Space>,
            onOk() { updateMortalityRecord() },
            onCancel() {},
            centered: true
        })
    }

    //------------------------
    // Cancel update mortality
    //------------------------
    const onCancel = () => {
        navigate({ pathname: MENUPATH_MORTALITY })
    }

    //---------------------------
    // On mortality reason change
    //---------------------------
    const onMortalityReasonChange = (e, value) => {
        setMortalityReasonId(e)
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setMortalityDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateMortalityReason = (() => {
        if(mortalityReasonId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Mortality reason is required."))
    })

    const validateMortalityDateTime = (() => {
        if(mortalityDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Mortality date time is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            quantity: urlParams.get("quantity").split(" ")[0],
            remark: urlParams.get("remark") == "undefined" ? "" : urlParams.get("remark")
        })

        if(urlParams.get("itemType").startsWith(TAGGED)) {
            getTaggedFish()
            message.info("Update and delete of tagged marine life mortality is not allowed.")   
        }

        getProtectionTriggeredRecordCount()
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Tag ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) },
        { title: 'Weight (g)', dataIndex: 'weight', key: 'weight', sorter: (a, b) => a.weight - b.weight },
        { title: 'Is Brood', dataIndex: 'isBrood', key: 'isBrood', sorter: (a, b) => a.isBrood - b.isBrood,
            render: (isBrood) => {
                if(isBrood)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        /*{ title: 'Status', dataIndex: 'active', key: 'active', 
            render: (active) => {
                if(active)
                    return <Tag color="blue">{OTHERSYSPARAM("ACTIVE")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("INACTIVE")}</Tag>
            }
        },*/
    ]

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader onBack={() => onCancel()} 
                    title="Mortality">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update mortality record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onUpdate} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Mortality ID: ${urlParams.get("id")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("storage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
                     
                            { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Form.Item>

                    { urlParams.get("itemType").startsWith(EGGS) &&
                        <Form.Item label={`Note: Eggs will always be rounded up to the next integer in mL`}>
                        </Form.Item>
                    }

                    { !urlParams.get("itemType").startsWith(TAGGED) &&
                        <Form.Item label={`Update Mortality Quantity To`}>
                            <Input.Group>
                                <Form.Item name="quantity" noStyle
                                    rules={[
                                        { required: true, message: "Mortality quantity is required."},
                                    ]}>
                                    <InputNumber min={1} max={10000000} step={1} placeholder="Quantity" />
                                </Form.Item>
                                <span>
                                    {` ${urlParams.get("uom")} of remaining ${numberWithCommas(parseInt(urlParams.get("quantity2")) + parseInt(urlParams.get("availableQuantity")))} ${urlParams.get("uom2")} 
                                    ${urlParams.get("marineLifeId") != PRAWNMARINELIFEPKEY ? '' : '(' + parseFloat(urlParams.get("storageAverageWeight")) * (parseInt(urlParams.get("quantity2").replace(',', '')) + parseInt(urlParams.get("availableQuantity"))) / 1000 + 'KG)'} `}</span>
                            </Input.Group>
                        </Form.Item>
                    }
                    
                    <Form.Item name="mortalityReason" label="Possible Mortality Reason"
                        rules={[
                            { required: true, validator: validateMortalityReason },
                        ]}>
                        <MortalityReasonSelect withBlank={false} defaultValue={urlParams.get("possibleReason")} onChange={onMortalityReasonChange} />
                    </Form.Item>

                    <Form.Item name="mortalityDateTime" label="Mortality Date Time"
                        rules={[
                            { required: true, validator: validateMortalityDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={mortalityDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>
                    
                    <Form.Item name="remark" label="Remark"
                        rules={[
                            { required: true, message: "Remark is required." },
                        ]}>
                        <TextArea rows={3} maxLength={500}/>
                    </Form.Item>

                    { urlParams.get("itemType").startsWith(TAGGED) &&
                        <>
                        <Row><Col><Space><div /></Space></Col></Row>
                        <Row justify="center">
                            <Col span={22}>
                                <Table bordered columns={columns} dataSource={fishDataSource} pagination={false} />
                            </Col>
                        </Row>
                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            { !urlParams.get("itemType").startsWith(TAGGED) &&
                                <>
                                <Button type="primary" htmlType="submit" disabled={disableButton} style={{display: hideButton}} icon={<SaveOutlined />}>Update</Button>
                                <Popconfirm title="Delete mortality record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                    <Button danger type="primary" htmlType="button" style={{display: hideButton}} disabled={disableButton} icon={<DeleteOutlined />}>Delete</Button>
                                </Popconfirm>
                                </>
                            }
                            <Button type="primary" htmlType="button" onClick={onCancel} disabled={disableButton} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                    title="Mortality:"
                    subTitle="Update mortality record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default MortalityUpdate