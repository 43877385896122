import { useState } from 'react'
import { DatePicker, Space } from "antd"
import { useMediaQuery } from 'react-responsive'
import { DATEFORMAT, MEDIAMAXWIDTH } from './SystemParameter'

const DateRangeInput = ({from, to, disabledFromDate, disabledToDate, onChange}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [width, setWidth] = useState(isTabletOrMobile ? 188 : 305)
    const [fromDate, setFromDate] = useState(from == undefined ? null : from)
    const [toDate, setToDate] = useState(to == undefined ? null : to)

    const onFromDateChange = (e) => {
        setFromDate(e)
        onChange(e, toDate)
    }

    const onToDateChange = (e) => {
        setToDate(e)
        onChange(fromDate, e)
    }

    return(
        <Space>
            <Space>
                <DatePicker 
                    showTime={false} 
                    onChange={onFromDateChange} 
                    defaultValue={from} 
                    format={DATEFORMAT} 
                    placeholder="From Date" 
                    style={{width: width}}
                    disabledDate={disabledFromDate}/>
            </Space> 
            to 
            <Space>
                <DatePicker 
                    showTime={false} 
                    onChange={onToDateChange} 
                    defaultValue={to} 
                    format={DATEFORMAT} 
                    placeholder="To Date" 
                    style={{width: width}}
                    disabledDate={disabledToDate}/>
            </Space>
        </Space>
    )
}

export default DateRangeInput