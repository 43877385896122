import { Table, Row, Col, Space, Button, Form, Collapse, message, Modal, Pagination, Spin } from 'antd'
import { QuestionCircleOutlined, LeftOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MEDIAMAXWIDTH, MENUPATH_STOCKCOUNT, PAGESIZE, PAGINATIONSIZE } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { reportError } from '../Common/Utility'
import { formLayout_2Columns } from '../Common/Layout'
import { showStorageStockSummary } from '../Common/showStorageStockSummary'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { confirm } = Modal

//----------
// Component
//----------
const StockCountStorageTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [searchForm] = Form.useForm()
    const navigate = useNavigate()
        
    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
        
    const [searchStorageId, setSearchStorageId] = useState(0)
    const [searchBatchId, setSearchBatchId] = useState(0)
    
    //---------------
    // Lock storage
    //---------------
    const createStockCount = (batchId, storageId) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.post(`${ACISAPIURL}stockcount/create/`, {
            batch: batchId,
            storage: storageId,
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Stock count initialized.")
            navigate({ 
                pathname: "/stockprecountsummary",
                search: `?pKey=${response.data.pKey}&batchId=${response.data.batch_data.pKey}&batch=${response.data.batch_data.id}&storageId=${response.data.storage_data.pKey}&storage=${response.data.storage_data.name}&createdOn=${moment(response.data.createdOn).format(DATETIMEFORMAT)}&updatedOn=${moment(response.data.updatedOn).format(DATETIMEFORMAT)}&completedOn=`
            })
        })
        .catch( error => {
            reportError(error, `Failed to initialize stock count.`)
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // Search storage
    //---------------
    const searchStorage = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)

        // If search by storage id, in order to show empty storage, will not be filtered by species.
        //if(searchStorageId != 0) {
        //    message.info("Search by Storage ID would disable species filter.")
        //}
        
        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: { 
                site: getUserSiteId(),
                batch: searchBatchId,
                id: searchStorageId,
                //empty: false,
                free: false,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []

        response.data.results.forEach( storage => {
            data.push({
                key: storage.id,
                pKey: storage.pKey,
                id: storage.name,
                storageType: storage.storage_type_data.name,
                batchId: (storage?.batch_data == null) ? 0 : storage.batch_data.pKey,
                batch: (storage?.batch_data == null) ? "" : storage.batch_data.id,
                acquiringMethod: storage?.batch_data?.acquiring_method_data == null ? "" : storage.batch_data.acquiring_method_data.name,
                lifecycle: storage?.batch_data?.lifecycle_data == null ? "" : storage.batch_data.lifecycle_data.name,
                batchType: storage?.batch_data?.batch_type_data?.name == null ? "" : storage.batch_data.batch_type_data.name,
                aquacultureStage: storage?.batch_data?.aquaculture_stage_data.name == null ? "" : storage.batch_data.aquaculture_stage_data.name,
                species: storage?.batch_data?.species_data.short_name == null ? "" : storage.batch_data.species_data.short_name,
                batchAverageWeight: storage?.batch_data?.average_per_unit_weight_gram == null ? 0 : storage.batch_data.average_per_unit_weight_gram,
                batchAverageLength: storage?.batch_data?.average_per_unit_length_mm == null ? 0 : storage.batch_data.average_per_unit_length_mm,

                batchData: storage?.batch_data != null ? [storage.batch_data] : [],
                status: storage.status_data,
            })
        })

        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //--------
    // On next
    //--------
    const onNext = (record) => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Storage will be locked for stock count. Confirmed?</p></Space>,
            onOk() { createStockCount(record.batchId, record.pKey) },
            onCancel() {},
            centered: true
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStorage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStorage(1)
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                if(record.status == OTHERSYSPARAM("IS_EMPTY")) {
                    message.info("Storage is empty.")
                    return
                }
                
                onNext(record)
            }
        }
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ pathname: MENUPATH_STOCKCOUNT })    
    }

    //----------------------------
    // Redirect callback function
    //----------------------------
    const redirect = (pathname, search) => {
        navigate({
            pathname: pathname,
            search: search
        })
    }

    //----------------------------
    // Show storage stock quantity
    //----------------------------
    const showStoragePopup = (e, record) => {
        e.stopPropagation()
        record.key = record.pKey
        showStorageStockSummary(false, record, redirect)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchStorage(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        !isTabletOrMobile ? { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) } : [],
        { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) },
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Status', dataIndex: 'stockQuantity', key: 'stockQuantity', align: 'center', //sorter: (a, b) => a.quantity.stockQuantity(b.stockQuantity),
            render: (stockQuantity, record) => {
                if(record.status == OTHERSYSPARAM("IS_OCCUPIED"))
                    return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => showStoragePopup(e, record)}>{OTHERSYSPARAM("IS_OCCUPIED")}</Button>
                else
                    return OTHERSYSPARAM("IS_EMPTY")
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search Stock Count Storage" key="1">
                        <Form form={searchForm} {...formLayout_2Columns}>
                            <CommonSearchFormItem onBatchChange={setSearchBatchId} onStorageChange={setSearchStorageId} showLockedStorage={false} />

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={12} style={{textAlign: "start"}}>
                <Button type="primary" htmlType="button" onClick={onBack} disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
            </Col>
            <Col span={12} />
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row>
            <Col span={24}>
                <Table bordered columns={columns} dataSource={storageDataSource} pagination={false} onRow={onRowClick} />
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}>
                <Button type="primary" htmlType="button" onClick={onBack} disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
            </Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default StockCountStorageTable