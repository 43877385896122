import { useState, useEffect } from 'react'
import { Form, Select, Switch } from 'antd'

import { getUserAuthToken, getUserSiteId, OTHERSYSPARAM, refreshUserSession, SYSPARAM } from "./UserSession"
import axios from 'axios'
import { ACISAPIURL, ALLOWED_SITES, MEDIAMAXWIDTH } from './SystemParameter'
import { useMediaQuery } from 'react-responsive'
import { reportError } from './Utility'
import { formLayout_2Columns } from './Layout'

const { Option } = Select

const CommonSearchFormItem = ({onSiteChange, onMarineLifeChange, onSpeciesChange, onBatchChange, onStorageTypeChange, onStorageUsageTypeChange, onStorageChange, 
    onTagIdChange, onAquacultureStageChange, onLifecycleChange, onItemTypeChange, showLockedStorage, activeTaggedOnly, mortalityTaggedOnly, excludeOwnSite, 
    loadBatch = true, loadStorage = true, loadTagged = true, defaultSiteId = getUserSiteId(), defaultStorageId = 0, defaultBatchId = 0, defaultMarineLifeId = 0, 
    defaultSpeciesId = 0, defaultSpecies = "", defaultAquacultureStageId = 0, defaultLifecycleId = 0, formLayout = formLayout_2Columns, showActiveBatchSwitch = false, 
    storageSelectMode = "", storageTypeSelectMode = ""}) => {

    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const [commonSearchForm] = Form.useForm()

    const [siteOption, setSiteOption] = useState([])
    const [marineLifeOption, setMarineLifeOption] = useState([])
    const [speciesOption, setSpeciesOption] = useState([])
    const [batchOption, setBatchOption] = useState([])
    const [storageTypeOption, setStorageTypeOption] = useState([])
    const [storageUsageTypeOption, setStorageUsageTypeOption] = useState([])
    const [storageOption, setStorageOption] = useState([])
    const [tagIdOption, setTagIdOption] = useState([])
    const [aquacultureStageOption, setAquacultureStageOption] = useState([])
    const [lifecycleOption, setLifecycleOption] = useState([])
    const [itemTypeOption, setItemTypeOption] = useState([])
    
    const [siteId, setSiteId] = useState(defaultSiteId)
    const [marineLifeId, setMarineLifeId] = useState(defaultMarineLifeId)
    const [speciesId, setSpeciesId] = useState(defaultSpeciesId)
    const [species, setSpecies] = useState(defaultSpecies)
    const [batchId, setBatchId] = useState(defaultBatchId)
    const [storageTypeId, setStorageTypeId] = useState(0)
    const [storageTypeIds, setStorageTypeIds] = useState([])
    const [storageUsageTypeId, setStorageUsageTypeId] = useState(0)
    const [storageUsageTypeIds, setStorageUsageTypeIds] = useState([])
    const [storageId, setStorageId] = useState(defaultStorageId)
    const [tagId, setTagId] = useState(0)
    const [aquacultureStageId, setAquacultureStageId] = useState(defaultAquacultureStageId)
    const [lifecycleId, setLifecycleId] = useState(defaultLifecycleId)
    const [itemTypeId, setItemTypeId] = useState(0)

    const showSite = onSiteChange != null
    const showMarineLife = onMarineLifeChange != null
    const showSpecies = onSpeciesChange != null
    const showBatch = onBatchChange != null
    const showStorageType = onStorageTypeChange != null
    const showStorageUsageType = onStorageUsageTypeChange != null
    const showStorage = onStorageChange != null
    const showTagId = onTagIdChange != null
    const showAquacultureStage = onAquacultureStageChange != null
    const showLifecycle = onLifecycleChange != null
    const showItemType = onItemTypeChange != null

    const [activeBatch, setActiveBatch] = useState(true)

    //---------------------
    // Load site
    //---------------------
    const getSite = () => {
        axios.get(`${ACISAPIURL}site/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            
            const filteredSites = response.data.results.filter( site => String(SYSPARAM(ALLOWED_SITES)).includes(site.pKey))
            options = options.concat(filteredSites.map( site => <Option key={site.pKey}>{site.name}</Option>))
            
            if(excludeOwnSite) options = options = options.filter( option => option.key != getUserSiteId())
            setSiteOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get site.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //---------------------
    // Load marine life
    //---------------------
    const getMarineLife = () => {
        axios.get(`${ACISAPIURL}marinelife/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( marineLife => <Option key={marineLife.pKey}>{marineLife.name}</Option>))
            setMarineLifeOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get marine life.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //-------------
    // Load species
    //-------------
    const getSpecies = (marineLifeId) => {
        axios.get(`${ACISAPIURL}species/`, { 
            params: { 
                marine_life: marineLifeId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( species => <Option key={species.pKey}>{species.short_name}</Option>))
            setSpeciesOption(options)
        })
        .catch( error => {
            reportError(error, "<SpeciesSelect /> Failed to get species.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //-----------
    // Load batch
    //-----------
    const getBatch = (siteId, marineLifeId, speciesId, activeBatch) => {
        axios.get(`${ACISAPIURL}batchfull/`, {
            params: {
                site: siteId,
                marine_life: marineLifeId,
                species: speciesId,
                active: activeBatch
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            options.push(<Option key={""}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( batch => <Option key={batch.pKey}>{batch.id}</Option>))
            setBatchOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get batch list.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //------------------
    // Load storage type
    //------------------
    const getStorageType = () => {
        axios.get(`${ACISAPIURL}storagetype/`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( type => <Option key={type.pKey}>{type.name}</Option>))
            setStorageTypeOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get storage type.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //------------------------
    // Load storage usage type
    //------------------------
    const getStorageUsageType = () => {
        axios.get(`${ACISAPIURL}storageusagetype/`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( type => <Option key={type.pKey}>{type.name}</Option>))
            setStorageUsageTypeOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get storage usage type.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //-------------
    // Load storage
    //-------------
    const getStorage = (siteId, marineLifeId, speciesId, batchId, storageTypeId, storageUsageTypeId) => {
        axios.get(`${ACISAPIURL}storagefull/`, {
            params: {
                site: siteId,
                marine_life: marineLifeId,
                species: speciesId,
                batch: batchId,
                storage_type: storageTypeSelectMode != 'multiple' ? storageTypeId : 0,
                storage_types: storageTypeSelectMode == 'multiple' ? Array(storageTypeId).toString() : "",
                storage_usage_type: storageUsageTypeId,
                show_locked: showLockedStorage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(storageSelectMode != "multiple") {
                let options = []
                options.push(<Option key={0}>{" "}</Option>) // Blank
                options = options.concat(response.data.results.map( storage => <Option key={storage.pKey}>{storage.name}</Option>))
                setStorageOption(options)
            }
            else {
                const options = response.data.results.map( storage => <Option key={storage.pKey}>{storage.name}</Option>)
                setStorageOption(options)
            }
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get storage.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //------------
    // Load tagged
    //------------
    const getTagged = (siteId, marineLifeId, speciesId, batchId, storageId) => {
        axios.get(`${ACISAPIURL}fishfull/`, {
            params: {
                site: siteId,
                marine_life: marineLifeId,
                species: speciesId,
                batch: batchId,
                storage: storageId,
                active: activeTaggedOnly == true ? true : null
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( fish => <Option key={fish.pKey}>{fish.id}</Option>))
            setTagIdOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get tagged items.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const getMortalityTagged = (siteId, marineLifeId, speciesId, batchId, storageId) => {
        axios.get(`${ACISAPIURL}mortality/taggedfish/`, {
            params: {
                site: siteId,
                marine_life: marineLifeId,
                species: speciesId,
                batch: batchId,
                storage: storageId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( fish => <Option key={fish.fish_data.pKey}>{fish.fish_data.id}</Option>))
            setTagIdOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get mortality tagged items.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //-----------------------
    // Load aquaculture stage
    //-----------------------
    const getAquacultureStage = (marineLifeId, speciesId, batchId, storageId, tagId) => {
        axios.get(`${ACISAPIURL}aquaculturestage/`, { 
            params: {
                marine_life: marineLifeId,
                species: speciesId,
                batch: batchId,
                storage: storageId,
                tag_id: tagId
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( stage => <Option key={stage.pKey}>{stage.name}</Option>))
            setAquacultureStageOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get aquaculture stages.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------
    // Load lifecycle
    //---------------
    const getLifecycle = (marineLifeId, speciesId, batchId, storageId, tagId, aquacultureStageId) => {
        axios.get(`${ACISAPIURL}lifecycle/`, { 
            params: {
                marine_life: marineLifeId,
                species: speciesId,
                batch: batchId,
                storage: storageId,
                tag_id: tagId,
                aquaculture_stage: aquacultureStageId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( lifecycle => <Option key={lifecycle.pKey}>{lifecycle.name}</Option>))
            setLifecycleOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get lifecycle.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------
    // Load item type
    //---------------
    const getItemType = (marineLifeId, speciesId, batchId, storageId, tagId, aquacultureStageId, lifecycleId) => {
        axios.get(`${ACISAPIURL}itemtype/`, { 
            params: {
                marine_life: marineLifeId,
                species: speciesId,
                batch: batchId,
                storage: storageId,
                tag_id: tagId,
                aquaculture_stage: aquacultureStageId,
                lifecycle: lifecycleId
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( it => <Option key={it.pKey}>{it.name}</Option> ))
            setItemTypeOption(options)
        })
        .catch( error => {
            reportError(error, "<CommonSearchFormItem /> Failed to get item type.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //----------
    // On change
    //----------
    const siteChange = (e, value) => {
        if(e != null) {
            setSiteId(e)
            onSiteChange(e, value)
        }
        else {
            onSiteChange(0, "")
            setSiteId(0)
        }

        if(showMarineLife) onMarineLifeChange(0, "")
        if(showSpecies) onSpeciesChange(0, "")
        if(showBatch) onBatchChange(0, "")
        if(showStorage) onStorageChange(0, "")
        if(showTagId) onTagIdChange(0, "")
        if(showAquacultureStage) onAquacultureStageChange(0, "")
        if(showLifecycle) onLifecycleChange(0, "")
        if(showItemType) onItemTypeChange(0, "")

        commonSearchForm.setFieldsValue({
            marineLife: "",
            species: "",
            batch: "",
            storage: storageSelectMode != "multiple" ? "" : [],
            tagId: "",
            aquacultureStage: "",
            lifecycle: "",
            itemType: ""
        })

        setMarineLifeId(0)
        setSpeciesId(0)
        setBatchId(0)
        setStorageId(0)
        setTagId(0)
        setAquacultureStageId(0)
        setLifecycleId(0)
        setItemTypeId(0)

        if(showMarineLife) getMarineLife()
        if(showSpecies) getSpecies(0)
        if(showBatch) getBatch(e, 0, 0, activeBatch)
        if(showStorage) getStorage(e, 0, 0, 0)

        if(showTagId) 
            if(mortalityTaggedOnly == true) 
                getMortalityTagged(e, 0, 0, 0, 0)
            else
                getTagged(e, 0, 0, 0, 0)

        if(showAquacultureStage) getAquacultureStage(0, 0, 0, 0, 0)
        if(showLifecycle) getLifecycle(0, 0, 0, 0, 0, 0)
        if(showItemType) getItemType(0, 0, 0, 0, 0, 0, 0)
    }

    const marineLifeChange = (e, value) => {
        if(e != null) {
            setMarineLifeId(e)
            onMarineLifeChange(e, value)
        }
        else {
            onMarineLifeChange(0, "")
            setMarineLifeId(0)
        }

        if(showSpecies) onSpeciesChange(0, "")
        if(showBatch) onBatchChange(0, "")
        if(showStorage) onStorageChange(0, "")
        if(showTagId) onTagIdChange(0, "")
        if(showAquacultureStage) onAquacultureStageChange(0, "")
        if(showLifecycle) onLifecycleChange(0, "")
        if(showItemType) onItemTypeChange(0, "")

        commonSearchForm.setFieldsValue({
            species: "",
            batch: "",
            storage: storageSelectMode != "multiple" ? "" : [],
            tagId: "",
            aquacultureStage: "",
            lifecycle: "",
            itemType: ""
        })

        setSpeciesId(0)
        setBatchId(0)
        setStorageId(0)
        setTagId(0)
        setAquacultureStageId(0)
        setLifecycleId(0)
        setItemTypeId(0)

        if(showSpecies) getSpecies(e)
        if(showBatch) getBatch(siteId, e, 0, activeBatch)
        if(showStorage) getStorage(siteId, e, 0, 0)

        if(showTagId) 
            if(mortalityTaggedOnly == true)
                getMortalityTagged(siteId, e, 0, 0, 0)
            else
                getTagged(siteId, e, 0, 0, 0)
            
        if(showAquacultureStage) getAquacultureStage(e, 0, 0, 0, 0)
        if(showLifecycle) getLifecycle(e, 0, 0, 0, 0, 0)
        if(showItemType) getItemType(e, 0, 0, 0, 0, 0, 0)
    }

    const speciesChange = (e, value, activeBatch = true) => {
        if(e != null) {
            onSpeciesChange(e, value)
            setSpeciesId(e)
            setSpecies(value)
        }
        else {
            onSpeciesChange(0, "")
            setSpeciesId(0)
            setSpecies("")
        }

        if(showBatch) onBatchChange(0, "")
        if(showStorage) onStorageChange(0, "")
        if(showTagId) onTagIdChange(0, "")
        if(showAquacultureStage) onAquacultureStageChange(0, "")
        if(showLifecycle) onLifecycleChange(0, "")
        if(showItemType) onItemTypeChange(0, "")

        commonSearchForm.setFieldsValue({
            batch: "",
            storage: storageSelectMode != "multiple" ? "" : [],
            tagId: "",
            aquacultureStage: "",
            lifecycle: "",
            itemType: ""
        })

        setBatchId(0)
        setStorageId(0)
        setTagId(0)
        setAquacultureStageId(0)
        setLifecycleId(0)
        setItemTypeId(0)

        if(showBatch) getBatch(siteId, marineLifeId, e, activeBatch)
        if(showStorage) getStorage(siteId, marineLifeId, e, 0)

        if(showTagId) 
            if(mortalityTaggedOnly == true)
                getMortalityTagged(siteId, marineLifeId, e, 0, 0)
            else
                getTagged(siteId, marineLifeId, e, 0, 0)

        if(showAquacultureStage) getAquacultureStage(marineLifeId, e, 0, 0, 0)
        if(showLifecycle) getLifecycle(marineLifeId, e, 0, 0, 0, 0)
        if(showItemType) getItemType(marineLifeId, e, 0, 0, 0, 0, 0)
    }

    const batchChange = (e, value) => {
        if(e != null) {
            onBatchChange(e, value)
            setBatchId(e)  
        }
        else {
            onBatchChange(0, "")
            setBatchId(0)
        }

        if(showStorageType) onStorageTypeChange(0, "")
        if(showStorage) onStorageChange(0, "")
        if(showTagId) onTagIdChange(0, "")
        if(showAquacultureStage) onAquacultureStageChange(0, "")
        if(showLifecycle) onLifecycleChange(0, "")
        if(showItemType) onItemTypeChange(0, "")

        commonSearchForm.setFieldsValue({
            storageType: "",
            storageUsageType: "",
            storage: storageSelectMode != "multiple" ? "" : [],
            tagId: "",
            aquacultureStage: "",
            lifecycle: "",
            itemType: ""
        })

        setStorageTypeId(0)
        setStorageUsageTypeId(0)
        setStorageId(0)
        setTagId(0)
        setAquacultureStageId(0)
        setLifecycleId(0)
        setItemTypeId(0)

        //if(showStorageType) getStorageType()

        if(showStorage) getStorage(siteId, marineLifeId, speciesId, e, 0)
        
        if(showTagId) 
            if(mortalityTaggedOnly == true)
                getMortalityTagged(siteId, marineLifeId, speciesId, e, 0)
            else
                getTagged(siteId, marineLifeId, speciesId, e, 0)

        if(showAquacultureStage) getAquacultureStage(marineLifeId, speciesId, e, 0, 0)
        if(showLifecycle) getLifecycle(marineLifeId, speciesId, e, 0, 0, 0)
        if(showItemType) getItemType(marineLifeId, speciesId, e, 0, 0, 0, 0)
    }

    // 20221201 - Add multiple select mode to storage type.
    const storageTypeChange = (e, value) => {
        // In multiple mode, e and value are always array []. e is null can only happen in single mode.
        if(e != null) { 
            onStorageTypeChange(e, value)
            setStorageTypeId(e)  
        }
        else { // Only can happen to single mode.
            onStorageTypeChange(0, "")
            setStorageTypeId(0)
        }

        if(showStorage) {
            if(storageSelectMode != "multiple") 
                onStorageChange(0, "")
            else
                onStorageChange([], [])
        }

        if(showStorageUsageType) onStorageUsageTypeChange(0, "")
        if(showTagId) onTagIdChange(0, "")
        if(showAquacultureStage) onAquacultureStageChange(0, "")
        if(showLifecycle) onLifecycleChange(0, "")
        if(showItemType) onItemTypeChange(0, "")

        commonSearchForm.setFieldsValue({
            storage: storageSelectMode != "multiple" ? "" : [],
            storageUsageType: "",
            tagId: "",
            aquacultureStage: "",
            lifecycle: "",
            itemType: ""
        })

        setStorageUsageTypeId(0)
        setStorageId(0)
        setTagId(0)
        setAquacultureStageId(0)
        setLifecycleId(0)
        setItemTypeId(0)

        if(showStorage) getStorage(siteId, marineLifeId, speciesId, batchId, e)
                    
        if(showTagId) 
            if(mortalityTaggedOnly == true)
                getMortalityTagged(siteId, marineLifeId, speciesId, e, 0)
            else
                getTagged(siteId, marineLifeId, speciesId, e, 0)

        if(showAquacultureStage) getAquacultureStage(marineLifeId, speciesId, e, 0, 0)
        if(showLifecycle) getLifecycle(marineLifeId, speciesId, e, 0, 0, 0)
        if(showItemType) getItemType(marineLifeId, speciesId, e, 0, 0, 0, 0)
    }

    const storageUsageTypeChange = (e, value) => {
        if(e != null) {
            onStorageUsageTypeChange(e, value)
            setStorageUsageTypeId(e)  
        }
        else {
            onStorageUsageTypeChange(0, "")
            setStorageUsageTypeId(0)
        }

        if(showStorage) {
            if(storageSelectMode != "multiple") 
                onStorageChange(0, "")
            else
                onStorageChange([], [])
        }
        
        if(showTagId) onTagIdChange(0, "")
        if(showAquacultureStage) onAquacultureStageChange(0, "")
        if(showLifecycle) onLifecycleChange(0, "")
        if(showItemType) onItemTypeChange(0, "")

        commonSearchForm.setFieldsValue({
            storage: storageSelectMode != "multiple" ? "" : [],
            tagId: "",
            aquacultureStage: "",
            lifecycle: "",
            itemType: ""
        })

        setStorageId(0)
        setTagId(0)
        setAquacultureStageId(0)
        setLifecycleId(0)
        setItemTypeId(0)

        if(showStorage) getStorage(siteId, marineLifeId, speciesId, batchId, storageTypeId, e)
                    
        if(showTagId) 
            if(mortalityTaggedOnly == true)
                getMortalityTagged(siteId, marineLifeId, speciesId, e, 0)
            else
                getTagged(siteId, marineLifeId, speciesId, e, 0)

        if(showAquacultureStage) getAquacultureStage(marineLifeId, speciesId, e, 0, 0)
        if(showLifecycle) getLifecycle(marineLifeId, speciesId, e, 0, 0, 0)
        if(showItemType) getItemType(marineLifeId, speciesId, e, 0, 0, 0, 0)
    }

    const storageChange = (e, value) => {
        if(storageSelectMode != "multiple") {
            if(e != null) {
                onStorageChange(e, value)
                setStorageId(e)
            }
            else {
                onStorageChange(0, "")
                setStorageId(0)
            }
        }
        else {
            if(e != undefined && value != undefined) {
                onStorageChange(e, value)
            }
            else
                onStorageChange([], [])
        }

        if(showTagId) onTagIdChange(0, "")
        if(showAquacultureStage) onAquacultureStageChange(0, "")
        if(showLifecycle) onLifecycleChange(0, "")
        if(showItemType) onItemTypeChange(0, "")

        commonSearchForm.setFieldsValue({
            tagId: "",
            aquacultureStage: "",
            lifecycle: "",
            itemType: ""
        })

        setTagId(0)
        setAquacultureStageId(0)
        setLifecycleId(0)
        setItemTypeId(0)

        if(showTagId) 
            if(mortalityTaggedOnly == true)
                getMortalityTagged(siteId, marineLifeId, speciesId, batchId, e)
            else
                getTagged(siteId, marineLifeId, speciesId, batchId, e)

        if(showAquacultureStage) getAquacultureStage(marineLifeId, speciesId, batchId, e, 0)
        if(showLifecycle) getLifecycle(marineLifeId, speciesId, batchId, e, 0, 0)
        if(showItemType) getItemType(marineLifeId, speciesId, batchId, e, 0, 0, 0)
    }

    const tagIdChange = (e, value) => {
        if(e != null) {
            onTagIdChange(e, value)
            setTagId(e)
        }
        else {
            onTagIdChange(0, "")
            setTagId(0)
        }

        if(showAquacultureStage) onAquacultureStageChange(0, "")
        if(showLifecycle) onLifecycleChange(0, "")
        if(showItemType) onItemTypeChange(0, "")

        commonSearchForm.setFieldsValue({
            aquacultureStage: "",
            lifecycle: "",
            itemType: ""
        })

        setAquacultureStageId(0)
        setLifecycleId(0)
        setItemTypeId(0)

        if(showAquacultureStage) getAquacultureStage(marineLifeId, speciesId, batchId, storageId, e)
        if(showLifecycle) getLifecycle(marineLifeId, speciesId, batchId, storageId, e, 0)
        if(showItemType) getItemType(marineLifeId, speciesId, batchId, storageId, e, 0, 0)
    }

    const aquacultureStageChange = (e, value) => {
        if(e != null) {
            onAquacultureStageChange(e, value)
            setAquacultureStageId(e)
        }
        else {
            onAquacultureStageChange(0, "")
            setAquacultureStageId(0)
        }

        if(showLifecycle) onLifecycleChange(0, "")
        if(showItemType) onItemTypeChange(0, "")

        commonSearchForm.setFieldsValue({
            lifecycle: "",
            itemType: ""
        })

        setLifecycleId(0)
        setItemTypeId(0)

        if(showLifecycle) getLifecycle(marineLifeId, speciesId, batchId, storageId, tagId, e)
        if(showItemType) getItemType(marineLifeId, speciesId, batchId, storageId, tagId, e, 0)
    }

    const lifecycleChange = (e, value) => {
        if(e != null) {
            onLifecycleChange(e, value)
            setLifecycleId(e)
        }
        else {
            onLifecycleChange(0, "")
            setLifecycleId(0)
        }

        if(showItemType) onItemTypeChange(0, "")

        commonSearchForm.setFieldsValue({
            itemType: ""
        })

        setItemTypeId(0)

        if(showItemType) getItemType(marineLifeId, speciesId, batchId, storageId, tagId, aquacultureStageId, e)
    }

    const itemTypeChange = (e, value) => {
        if(e != null) {
            onItemTypeChange(e, value)
            setItemTypeId(e)
        }
        else {
            onItemTypeChange(0, "")
            setItemTypeId(0)
        }
    }

    const onShowActiveBatchOnlyChange = (checked) => {
        setActiveBatch(checked)
        speciesChange(speciesId, species, checked) 
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(showSite) getSite()
        if(showMarineLife) getMarineLife()
        if(showSpecies) getSpecies(marineLifeId)
        if(showBatch && loadBatch) getBatch(siteId, marineLifeId, 0, activeBatch)
        if(showStorageType) getStorageType()
        if(showStorageUsageType) getStorageUsageType()
        if(showStorage && loadStorage) getStorage(siteId, marineLifeId, 0, defaultBatchId)
        
        if(showTagId && loadTagged) 
            if(mortalityTaggedOnly == true)
                getMortalityTagged(siteId, marineLifeId, 0, defaultBatchId, defaultStorageId)
            else
                getTagged(siteId, marineLifeId, 0, defaultBatchId, defaultStorageId)

        if(showAquacultureStage) getAquacultureStage(marineLifeId, defaultSpeciesId, defaultBatchId, defaultStorageId, 0)
        if(showLifecycle) getLifecycle(marineLifeId, defaultSpeciesId, defaultBatchId, defaultStorageId, 0, 0)
        if(showItemType) getItemType(marineLifeId, 0, defaultBatchId, defaultStorageId, 0, 0, 0)

        commonSearchForm.setFieldsValue({
            batch: defaultBatchId == 0 ? "" : defaultBatchId,
            storage: storageSelectMode != "multiple" ? (defaultStorageId == 0 ? "" : defaultStorageId) : [],
            aquacultureStage: defaultAquacultureStageId == 0 ? "" : defaultAquacultureStageId,
            lifecycle: defaultLifecycleId == 0 ? "" : defaultLifecycleId
        })
    }, [])

    return (
        <>
        <Form form={commonSearchForm} {...formLayout}>
            <Form.Item name="site" label="Site" hidden={!showSite}>
                <Select style={{width: optionWidth}} onChange={siteChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {siteOption}    
                </Select>
            </Form.Item>
            
            <Form.Item name="marineLife" label="Marine Life" hidden={!showMarineLife}>
                <Select style={{width: optionWidth}} onChange={marineLifeChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {marineLifeOption}    
                </Select>
            </Form.Item>

            <Form.Item name="species" label="Species" hidden={!showSpecies}>
                <Select style={{width: optionWidth}} onChange={speciesChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {speciesOption}    
                </Select>
            </Form.Item>

            { showActiveBatchSwitch && 
                <Form.Item name="showActiveBatchOnly" label="Active Batch">
                    <Switch defaultChecked={activeBatch} onChange={onShowActiveBatchOnlyChange} />
                </Form.Item>
            }

            <Form.Item name="batch" label="Batch ID" hidden={!showBatch}>
                <Select style={{width: optionWidth}} onChange={batchChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {batchOption}    
                </Select>
            </Form.Item>

            <Form.Item name="storageType" label="Storage Type" hidden={!showStorageType}>
                <Select style={{width: optionWidth}} onChange={storageTypeChange} mode={storageTypeSelectMode} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {storageTypeOption}
                </Select>
            </Form.Item>
            
            <Form.Item name="storageUsageType" label="Storage Usage Type" hidden={!showStorageUsageType}>
                <Select style={{width: optionWidth}} onChange={storageUsageTypeChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {storageUsageTypeOption}
                </Select>
            </Form.Item>
            
            <Form.Item name="storage" label="Storage" hidden={!showStorage}>
                <Select style={{width: optionWidth}} onChange={storageChange} mode={storageSelectMode} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {storageOption}
                </Select>
            </Form.Item>

            <Form.Item name="tagId" label="Tag ID" hidden={!showTagId}>
                <Select style={{width: optionWidth}} onChange={tagIdChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {tagIdOption}    
                </Select>
            </Form.Item>

            <Form.Item name="aquacultureStage" label="Aquaculture Stage" hidden={!showAquacultureStage}>
                <Select style={{width: optionWidth}} onChange={aquacultureStageChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {aquacultureStageOption}    
                </Select>
            </Form.Item>

            <Form.Item name="lifecycle" label="Lifecycle" hidden={!showLifecycle}>
                <Select style={{width: optionWidth}} onChange={lifecycleChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {lifecycleOption}    
                </Select>
            </Form.Item>

            <Form.Item name="itemType" label="Item Type" hidden={!showItemType}>
                <Select style={{width: optionWidth}} onChange={itemTypeChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {itemTypeOption}    
                </Select>
            </Form.Item>
        </Form>
        </>
    )
}

export default CommonSearchFormItem
