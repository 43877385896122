import { Table, Row, Col, Space, Button, Form, Collapse, Tag, message, Pagination, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATETIMEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { reportError } from '../Common/Utility'
import { formLayout_2Columns } from '../Common/Layout'
import TrueFalseSelect from "../Common/TrueFalseSelect"
import { showStorageStockSummary } from '../Common/showStorageStockSummary'
import moment from 'moment'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse

//----------
// Component
//----------
const StorageTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageTypeId, setStorageTypeId] = useState(0)
    const [storageType, setStorageType] = useState("")
    const [storageUsageTypeId, setStorageUsageTypeId] = useState(0)
    const [storageUsageType, setStorageUsageType] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [batchAssigned, setBatchAssigned] = useState("true")
    const [batchAssignedLabel, setBatchAssignedLabel] = useState(OTHERSYSPARAM("YES"))
    const [locked, setLocked] = useState("")
    const [lockedLabel, setLockedLabel] = useState("")
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------
    // Search storage
    //---------------
    const searchStorage = async (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storage/`, {
            params: { 
                site: getUserSiteId(),
                batch: batchId,
                storage_type: storageTypeId,
                storage_usage_type: storageUsageTypeId,
                marine_life: marineLifeId,
                species: speciesId,
                id: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : storageId,
                batch_assigned: batchAssigned,
                locked: locked,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( storage => {
            data.push({
                key: storage.pKey,
                site: storage.site,
                name: storage.name,
                id: storage.id,
                storageType: storage.storage_type_data.name,
                storageTypeId: storage.storage_type_data.pKey,
                usageType: storage.storage_usage_type_data.name,
                usageTypeId: storage.storage_usage_type_data.pKey,
                batch: storage?.batch_ids == null ? "" : storage.batch_ids,
                dimension: storage.dimension,
                capacity: storage.capacity,
                locked: storage.locked == false ? OTHERSYSPARAM("NO") : OTHERSYSPARAM("YES"),
                createdOn: moment(storage.createdOn).format(DATETIMEFORMAT),
                updatedOn: moment(storage.updatedOn).format(DATETIMEFORMAT),
                
                batchData: storage?.batch_data != null ? storage.batch_data : [],
                status: storage.status_data,
            })
        })
        
        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}\n`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage ID: ${storage}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(storageTypeId != 0) searchCriteria = `${searchCriteria}\nStorage Type: ${storageType}`
        if(storageUsageTypeId != 0) searchCriteria = `${searchCriteria}\nUsage Type: ${storageUsageType}`
        if(batchAssigned != "") searchCriteria = `${searchCriteria}\nBatch Assigned: ${batchAssignedLabel}`
        if(locked != "") searchCriteria = `${searchCriteria}\nLocked: ${lockedLabel}`
                
        axios.get(`${ACISAPIURL}storage/download/`, {
            params: { 
                site: getUserSiteId(),
                marine_life: marineLifeId,
                batch: batchId,
                storage_type: storageTypeId,
                storage_usage_type: storageUsageTypeId,
                species: speciesId,
                id: storageId,
                batch_assigned: batchAssigned,
                locked: locked,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Storage ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStorage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStorage(1)
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/storageupdate", 
                    search: `?pKey=${record.key}&storage=${record.id}&batch=${record.batch}&storageTypeId=${record.storageTypeId}&storageUsageTypeId=${record.usageTypeId}&storageType=${record.storageType}&storageUsageType=${record.usageType}&storageName=${record.name}&dimension=${record.dimension}&capacity=${record.capacity}&createdOn=${record.createdOn}&updatedOn=${record.updatedOn}&locked=${record.locked}` 
                }) 
            }
        }
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/storagenew" })
    }

    //----------------------------
    // Redirect callback function
    //----------------------------
    const redirect = (pathname, search) => {
        navigate({
            pathname: pathname,
            search: search
        })
    }

    //----------------------------
    // Show storage stock quantity
    //----------------------------
    const showStoragePopup = (e, record) => {
        e.stopPropagation()
        record.id = record.name
        showStorageStockSummary(true, record, redirect) 
    }

    //---------------------
    // On search criteria change
    //---------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onSearchMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onSearchStorageTypeChange = (e, value) => {
        setStorageTypeId(e)
        setStorageType(value.children)
    }

    const onSearchStorageUsageTypeChange = (e, value) => {
        setStorageUsageTypeId(e)
        setStorageUsageType(value.children)
    }

    const onSearchBatchAssignedChange = (e, value) => {
        setBatchAssigned(e)
        setBatchAssignedLabel(value.children)
    }

    const onSearchLockedChange = (e, value) => {
        setLocked(e)
        setLockedLabel(value.children)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchStorage(currentPage, urlParams.get("storageId"))
        }
        else
            searchStorage(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Storage ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Storage Name', dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
        { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) },
        { title: 'Usage Type', dataIndex: 'usageType', key: 'usageType', sorter: (a, b) => a.usageType.localeCompare(b.usageType) },
        { title: 'Locked', dataIndex: 'locked', key: 'locked', sorter: (a, b) => a.locked.localeCompare(b.locked),
            render: (locked) => {
                if(locked == OTHERSYSPARAM("NO"))
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
                else
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
            }
        },
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Status', align: 'center',
            render: (record) => {
                if(record.status == OTHERSYSPARAM("IS_OCCUPIED"))
                    return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => showStoragePopup(e, record)}>{OTHERSYSPARAM("IS_OCCUPIED")}</Button>
                else
                    return OTHERSYSPARAM("IS_EMPTY")
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Storage" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <CommonSearchFormItem onMarineLifeChange={onSearchMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageTypeChange={onSearchStorageTypeChange} onStorageUsageTypeChange={onSearchStorageUsageTypeChange} onStorageChange={onSearchStorageChange} showLockedStorage={true} defaultStorageId={storageId} />
                            
                            <Form.Item name="batchAssigned" label="Batch Assigned">
                                <TrueFalseSelect withBlank={true} defaultValue={batchAssignedLabel} onChange={onSearchBatchAssignedChange}/>
                            </Form.Item>

                            <Form.Item name="locked" label="Locked">
                                <TrueFalseSelect withBlank={true} onChange={onSearchLockedChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />} >New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={storageDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default StorageTable