import { getUserSiteId, SYSPARAM } from '../Common/UserSession'

const DroneViewPowerBIDashboard = () => {

    const getPowerBIEmbedLink = (site) => {
        if(getUserSiteId() == SYSPARAM("Tuaran New Site"))
            return <iframe title="Report Section" width="1780" height="860" src="https://app.powerbi.com/view?r=eyJrIjoiYjdiNGFkODAtODQyZC00N2FhLWFkMGUtMGM3MGYyODU0NjViIiwidCI6IjI0YjZkMmI4LTU1OTAtNGY4Yi1hMTFiLWRlZjBiZmFlZGYxNiIsImMiOjEwfQ%3D%3D" frameborder="0" allowFullScreen="true" />
        else if(getUserSiteId() == SYSPARAM("Tuaran Old Site"))
            return <iframe title="Report Section" width="1780" height="860" src="https://app.powerbi.com/view?r=eyJrIjoiMjhkZTRmYTQtM2IzMi00MzhmLTgzZjgtZWYwZmZlZGMwMThmIiwidCI6IjI0YjZkMmI4LTU1OTAtNGY4Yi1hMTFiLWRlZjBiZmFlZGYxNiIsImMiOjEwfQ%3D%3D" frameborder="0" allowFullScreen="true" />
        else if(getUserSiteId() == SYSPARAM("Pulau Gaya"))
            return <iframe title="Report Section" width="1780" height="860" src="https://app.powerbi.com/view?r=eyJrIjoiNzdlNDRiZGItN2RmMy00NmM3LWJjY2ItYjcxZmNjYWU5Yjc3IiwidCI6IjI0YjZkMmI4LTU1OTAtNGY4Yi1hMTFiLWRlZjBiZmFlZGYxNiIsImMiOjEwfQ%3D%3D" frameborder="0" allowFullScreen="true" />
    }

    return(
        <>
        {getPowerBIEmbedLink()}
        </>
    )

}

export default DroneViewPowerBIDashboard