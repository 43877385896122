import { Table, Row, Col, Space, Button, message, Image, Form, Card, Typography, Pagination, Spin } from 'antd'
import { LeftOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, ACISIMGURL, APPJSON, DATEFORMAT, FALLBACK, INVENTORYFORECASTIMGFOLDER, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import moment from 'moment'
import { formLayout } from '../Common/Layout'

const { Title } = Typography

//----------
// Component
//----------
const InventoryForecastTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [graphImageSource, setGraphImageSource] = useState("none")
    const [graphImageWidth, setGraphImageWidth] = useState(200)
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
                
    const [batchStorageDataSource, setBatchStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------
    // Search batch
    //---------------
    const searchInventoryForecastDetail = async (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)

        // Sanitize date range
        let fromDate = urlParams.get("fromDate") != null ? urlParams.get("fromDate") : null
        let toDate = urlParams.get("toDate") != null ? urlParams.get("toDate") : null

        await axios.get(`${ACISAPIURL}inventory/forecast/detail/`, {
            params: { 
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                fish: urlParams.get("fishId"),
                fromDate: fromDate,
                toDate: toDate,
                ageInDays: urlParams.get("ageInDays"),
                ageInDate: urlParams.get("ageInDate"),
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
            headers: {
                "Authorization": `Token ${getUserAuthToken()}`,
            }
        })
        .then( response => {
            pushData(response)
            showGraph()
        })
        .catch( error => {
            reportError(error, "Failed to search batch list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const pushData = (response) => {
        const data = []
        response.data.results.forEach( inv => {
            data.push({
                key: inv.pKey,
                ageInDays: numberWithCommas(inv.ageindays),
                ageInDate: moment(inv.ageindate).format(DATEFORMAT),
                averageLengthMm: numberWithCommas(inv.average_length_mm),
                averageWeightGram: numberWithCommas(inv.average_weight_gram),
                quantity: numberWithCommas(inv.quantity),
                totalWeightKg: numberWithCommas(inv.total_weight_kg),
            })
        })
        
        setBatchStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Sanitize date range
        let fromDate = urlParams.get("fromDate") != null ? urlParams.get("fromDate") : null
        let toDate = urlParams.get("toDate") != null ? urlParams.get("toDate") : null

        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        if(fromDate != "") searchCriteria = `${searchCriteria}\nDate >= ${moment(fromDate).format(DATEFORMAT)}`
        if(toDate != "") searchCriteria = `${searchCriteria}\nDate <= ${moment(toDate).format(DATEFORMAT)}`
        if(urlParams.get("marineLifeId") != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${urlParams.get("marineLife")}`
        if(urlParams.get("species") != 0) searchCriteria = `${searchCriteria}\nSpecies: ${urlParams.get("species")}`
        if(urlParams.get("batchId") != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${urlParams.get("batch")}`
        if(urlParams.get("storageId") != 0) searchCriteria = `${searchCriteria}\nStorage ID: ${urlParams.get("storage")}`
        if(urlParams.get("fishId") != 0) searchCriteria = `${searchCriteria}\nTag ID: ${urlParams.get("fish")}`
        if(urlParams.get("batchType") != "") searchCriteria = `${searchCriteria}\nBatch Type: ${urlParams.get("batchType")}`
        if(urlParams.get("fromWeight") >= 0) searchCriteria = `${searchCriteria}\nWeight >= ${numberWithCommas(urlParams.get("fromWeight"))}g`
        if(urlParams.get("toWeight") >=0) searchCriteria = `${searchCriteria}\nWeight <= ${numberWithCommas(urlParams.get("toWeight"))}g`
                        
        axios.get(`${ACISAPIURL}inventory/forecast/detail/download/`, {
            params: { 
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                fish: urlParams.get("fishId"),
                fromDate: fromDate,
                toDate: toDate,
                ageInDays: urlParams.get("ageInDays"),
                ageInDate: urlParams.get("ageInDate"),
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Inventory Forecast Full List ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchInventoryForecastDetail(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //-----------
    // Reset page
    //-----------
    const showGraph = () => {
        setGraphImageWidth(800)
        setGraphImageSource(`${ACISIMGURL}${INVENTORYFORECASTIMGFOLDER}/${urlParams.get("batchId")}_${urlParams.get("storageId")}${urlParams.get("fishId") != 0 ? '_' + urlParams.get("fishId") : ''}.png?now=${moment().valueOf()}`)
    }

    //------------------
    // Go to create page
    //------------------
    const onBack = () => {
        navigate({ 
            pathname: "/inventoryforecastbatchstorage",
            search: `?numOfBatches=${urlParams.get("numOfBatches")}&numOfStorages=${urlParams.get("numOfStorages")}&totalQty=${urlParams.get("totalQty")}&totalWeightKg=${urlParams.get("totalWeightKg")}&fromDate=${urlParams.get("fromDate")}&toDate=${urlParams.get("toDate")}&searchBatchId=${urlParams.get("searchBatchId")}&searchBatch=${urlParams.get("searchBatch")}&searchStorageId=${urlParams.get("searchStorageId")}&searchStorage=${urlParams.get("searchStorage")}&marineLifeId=${urlParams.get("marineLifeId")}&marineLife=${urlParams.get("marineLife")}&speciesId=${urlParams.get("speciesId")}&species=${urlParams.get("species")}&batchTypeId=${urlParams.get("batchTypeId")}&batchType=${urlParams.get("batchType")}&fromWeight=${urlParams.get("fromWeight")}&toWeight=${urlParams.get("toWeight")}&fishId=${urlParams.get("fishId")}&fish=${urlParams.get("fish")}`
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            await searchInventoryForecastDetail(currentPage)    
        }

        init()
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Age (date)', dataIndex: 'ageInDate', key: 'ageInDate', sorter: (a, b) => a.ageInDate.localeCompare(b.ageInDate) },
        { title: 'Age (days)', dataIndex: 'ageInDays', key: 'ageInDays', align: 'right', sorter: (a, b) => stringNumberSorter(a.ageInDays, b.ageInDays) },
        { title: 'Avg. Length (mm)', dataIndex: 'averageLengthMm', key: 'averageLengthMm', align: 'right', sorter: (a, b) => stringNumberSorter(a.averageLengthMm, b.averageLengthMm) },
        { title: 'Avg. Weight (gram)', dataIndex: 'averageWeightGram', key: 'averageWeightGram', align: 'right', sorter: (a, b) => stringNumberSorter(a.averageWeightGram, b.averageWeightGram) },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', align: 'right', sorter: (a, b) => stringNumberSorter(a.quantity, b.quantity) },
        { title: 'Total Weight (Kg)', dataIndex: 'totalWeightKg', key: 'totalWeightKg', align: 'right', sorter: (a, b) => stringNumberSorter(a.totalWeightKg, b.totalWeightKg) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Species: ${urlParams.get("species")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("storage")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Average Length (mm):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("averageLengthMm")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Average Weight (g):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("averageWeightGram")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Quantity:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchQuantity")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Total Weight (Kg):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchTotalWeightKg")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Age In Days:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("ageInDays")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Age In Date:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{moment(urlParams.get("ageInDate")).format(DATEFORMAT)}</Card.Grid>
                
                    { urlParams.get("batchType") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                        </>
                    }
                    { urlParams.get("marineLife") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Marine Life:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("marineLife")}</Card.Grid>
                        </>
                    }
                    {/* urlParams.get("fromWeight") != "-1" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">From Weight (g):</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("fromWeight")}</Card.Grid>
                        </>
                    */}
                    {/* urlParams.get("toWeight") != "-1" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">To Weight (g):</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("toWeight")}</Card.Grid>
                        </>
                    */}
                    { (urlParams.get("fish") != "undefined" && urlParams.get("fish") != "") &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Tag ID:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("fish")}</Card.Grid>
                        </>
                    }
                </Card>
            </Form.Item>
        </Form>

        <Row justify="center">
            <Col span={22} style={{textAlign: "center"}}>
                <Image 
                    width={graphImageWidth} 
                    src={graphImageSource}
                    fallback={FALLBACK}
                    preview={false}
                />
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={11} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onBack} icon={<LeftOutlined />}>Back</Button></Col>
            <Col span={2}></Col>
            <Col span={11} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} pagination={false} dataSource={batchStorageDataSource} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onBack} icon={<LeftOutlined />}>Back</Button></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default InventoryForecastTable
