import { Table, Row, Col, Space, Button, Form, Collapse, Tag, Modal, message, Pagination, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, SYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from '../Common/Utility'
import { formLayout_2Columns } from '../Common/Layout'
import TrueFalseSelect from "../Common/TrueFalseSelect"
import GenderSelect from '../Common/GenderSelect'
import AcquiringMethodSelect from '../Common/AcquiringMethodSelect'
import moment from 'moment'
import { BatchColumn, DesktopColumns, RFIDColumn } from '../Common/CommonTableColumns'
import WeightRangeInput from '../Common/WeightRangeInput'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse

//----------
// Component
//----------
const FishTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [acquiringMethodId, setAcquiringMethodId] = useState(0)
    const [acquiringMethod, setAcquiringMethod] = useState("")
    const [aquacultureStageId, setAquacultureStageId] = useState(0)
    const [aquacultureStage, setAquacultureStage] = useState("")
    const [lifecycleId, setLifecycleId] = useState(0)
    const [lifecycle, setLifecycle] = useState("")
    const [isBrood, setIsBrood] = useState("")
    const [isBroodLabel, setIsBroodLabel] = useState("")
    const [isLoanUnit, setIsLoanUnit] = useState("")
    const [isLoanUnitLabel, setIsLoanUnitLabel] = useState("")
    const [isActive, setIsActive] = useState(true)
    const [isActiveLabel, setIsActiveLabel] = useState(OTHERSYSPARAM("YES"))
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [fishId, setFishId] = useState(0)
    const [fish, setFish] = useState("")
    const [genderId, setGenderId] = useState(0)
    const [gender, setGender] = useState("")
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [weightRange, setWeightRange] = useState([-1, -1]) // Cannot use [0, 0] because 0 is valid input.
        
    const [fishDataSource, setFishDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------
    // Search fish
    //---------------
    const searchFish = async (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}fish/`, {
            params: { 
                site: getUserSiteId(),
                id: fishId,
                marine_life: marineLifeId,
                species: speciesId,
                acquiring_method: acquiringMethodId,
                aquaculture_stage: aquacultureStageId,
                lifecycle: lifecycleId,
                storage: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : storageId,
                gender: genderId,
                isBrood: isBrood,
                is_loan_unit: isLoanUnit,
                batch: batchId,
                active: isActive,
                from_weight: weightRange[0],
                to_weight: weightRange[1],
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search fish list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( fish => {
            data.push({
                key: fish.pKey,
                id: fish.id,
                species: fish.species_data.short_name,
                acquiringMethod: fish.batch_data.acquiring_method_data.name,
                aquacultureStage: fish.aquaculture_stage_data.name,
                storage: fish.storage_data.name,
                lifecycleId: fish.lifecycle_data.pKey,
                lifecycle: fish.lifecycle_data.name,
                gender: fish.gender_data.name,
                genderId: fish.gender_data.pKey,
                isBrood: fish.isBrood,
                isLoanUnit: fish.is_loan_unit,
                tagId: fish.tag_id != null ? fish.tag_id : "",
                batch: fish.batch_data.id,
                sourceBatch: (fish.source_batch_data?.id == null) ? "" : fish.source_batch_data.id,
                active: fish.active,
                weight: numberWithCommas(fish.average_per_unit_weight_gram),
                length: numberWithCommas(fish.average_per_unit_length_mm),
                birthDate: moment(fish.birth_date).format(UNIDATEFORMAT),
                birthDateFormatted: moment(fish.birth_date).format(DATEFORMAT),
                createdOn: moment(fish.createdOn).format(DATETIMEFORMAT),
                updatedOn: moment(fish.updatedOn).format(DATETIMEFORMAT),
            })
        })
        
        setFishDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}\n`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(fishId != 0) searchCriteria = `${searchCriteria}\nTag ID: ${fish}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(acquiringMethodId != 0) searchCriteria = `${searchCriteria}\nAcquiring Method: ${acquiringMethod}`
        if(aquacultureStageId != 0) searchCriteria = `${searchCriteria}\nAquaculture Stage: ${aquacultureStage}`
        if(lifecycleId != 0) searchCriteria = `${searchCriteria}\nLifecycle: ${lifecycle}`
        if(genderId != 0) searchCriteria = `${searchCriteria}\nGender: ${gender}`
        if(isBrood != "") searchCriteria = `${searchCriteria}\nIs Brood: ${isBroodLabel}`
        if(isLoanUnit != "") searchCriteria = `${searchCriteria}\nIs Loan Unit: ${isLoanUnitLabel}`
        if(isActive != "") searchCriteria = `${searchCriteria}\nIs Active: ${isActiveLabel}`
        if(weightRange[0] >= 0) searchCriteria = `${searchCriteria}\nWeight >= ${numberWithCommas(weightRange[0])}g`
        if(weightRange[1] >=0) searchCriteria = `${searchCriteria}\nWeight <= ${numberWithCommas(weightRange[1])}g`
                
        axios.get(`${ACISAPIURL}fish/download/`, {
            params: { 
                site: getUserSiteId(),
                id: fishId,
                marine_life: marineLifeId,
                species: speciesId,
                acquiring_method: acquiringMethodId,
                aquaculture_stage: aquacultureStageId,
                lifecycle: lifecycleId,
                storage: storageId,
                gender: genderId,
                isBrood: isBrood,
                is_loan_unit: isLoanUnit,
                batch: batchId,
                active: isActive,
                from_weight: weightRange[0],
                to_weight: weightRange[1],
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Tagged Marine Life ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFish(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchFish(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/fishupdate", 
                    search: `?pKey=${record.key}&id=${record.id}&batch=${record.batch}&species=${record.species}&storage=${record.storage }&acquiringMethod=${record.acquiringMethod}&aquacultureStage=${record.aquacultureStage}&lifecycleId=${record.lifecycleId}&lifecycle=${record.lifecycle}&isBrood=${record.isBrood}&sourceBatch=${record.sourceBatch}&active=${record.active}&averageWeight=${record.weight}&averageLength=${record.length}&birthDate=${record.birthDate}&birthDateFormatted=${record.birthDateFormatted}&createdOn=${record.createdOn}&updatedOn=${record.updatedOn}&tagId=${record.tagId}&genderId=${record.genderId}&gender=${record.gender}&isLoanUnit=${record.isLoanUnit}`
                }) 
            }
        }
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/fishsourcebatch" })
    }

    //---------------------------
    // On search criteria change
    //---------------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onSearchFishIdChange = (e, value) => {
        setFishId(e)
        setFish(value.children)
    }

    const onMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onSearchAcquiringMethodChange = (e, value) => {
        setAcquiringMethodId(e)
        setAcquiringMethod(value.children)
    }

    const onSearchLifecycleChange = (e, value) => {
        setLifecycleId(e)
        setLifecycle(value.children)
    }

    const onSearchGenderChange = (e, value) => {
        setGenderId(e)
        setGender(value.children)
    }
    
    const onSearchIsBroodChange = (e, value) => {
        setIsBrood(e)
        setIsBroodLabel(value.children)
    }

    const onSearchIsLoanUnitChange = (e, value) => {
        setIsLoanUnit(e)
        setIsLoanUnitLabel(value.children)
    }

    const onSearchIsActiveChange = (e, value) => {
        setIsActive(e)
        setIsActiveLabel(value.children)
    }

    const onSearchAquacultureStageChange = (e, value) => {
        setAquacultureStageId(e)
        setAquacultureStage(value.children)
    }

    const onWeightRangeChange = (fromWeight, toWeight) => {
        setWeightRange([fromWeight, toWeight])
    }
    
    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchFish(currentPage, urlParams.get("storageId"))
        }
        else
            searchFish(currentPage)

        form.setFieldsValue({
            tagId: urlParams.get("tagId")
        })
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        //{ title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        BatchColumn(),
        { title: 'Storage ID', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Tag ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        ...DesktopColumns(),
        { title: 'Weight (g)', dataIndex: 'weight', key: 'weight', align: "right", sorter: (a, b) => stringNumberSorter(a.weight, b.weight) },
        { title: 'Gender', dataIndex: 'genderId', key: 'genderId', sorter: (a, b) => a.genderId - b.genderId,
            render: (genderId, record) => {
                if(genderId == SYSPARAM("Male"))
                    return <Tag color="blue">{record.gender}</Tag>
                else if(genderId == SYSPARAM("Female"))
                    return <Tag color="red">{record.gender}</Tag>
                else 
                    return <Tag color="green">{record.gender}</Tag>
            }
        },
        { title: 'Is Brood', dataIndex: 'isBrood', key: 'isBrood', sorter: (a, b) => a.isBrood - b.isBrood,
            render: (isBrood) => {
                if(isBrood)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        //{ title: 'Source Batch', dataIndex: 'sourceBatch', key: 'sourceBatch', sorter: (a, b) => a.sourceBatch.localeCompare(b.sourceBatch) },
        { title: 'Status', dataIndex: 'active', key: 'active', sorter: (a, b) => a.active - b.sourceBatch,
            render: (active) => {
                if(active)
                    return <Tag color="blue">{OTHERSYSPARAM("ACTIVE")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("INACTIVE")}</Tag>
            }
        },
        RFIDColumn()
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Tagged Marine Life" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} onTagIdChange={onSearchFishIdChange} onAquacultureStageChange={onSearchAquacultureStageChange}
                                onLifecycleChange={onSearchLifecycleChange} activeTaggedOnly={true} showLockedStorage={false} defaultStorageId={storageId}/>

                            <Form.Item name="acquiringMethodId" label="Acquiring Method">
                                <AcquiringMethodSelect withBlank={true} onChange={onSearchAcquiringMethodChange}/>
                            </Form.Item>

                            <Form.Item name="gender" label="Gender">
                                <GenderSelect withBlank={true} onChange={onSearchGenderChange}/>
                            </Form.Item>

                            <Form.Item name="weightRange" label="Weight Range">
                                <WeightRangeInput onChange={onWeightRangeChange}/>
                            </Form.Item>

                            <Form.Item name="isBrood" label="Is Brood">
                                <TrueFalseSelect withBlank={true} onChange={onSearchIsBroodChange}/>
                            </Form.Item>

                            <Form.Item name="isLoanUnit" label="Is Loan Unit">
                                <TrueFalseSelect withBlank={true} onChange={onSearchIsLoanUnitChange}/>
                            </Form.Item>

                            <Form.Item name="isActive" label="Is Active">
                                <TrueFalseSelect withBlank={true} defaultValue={isActiveLabel} onChange={onSearchIsActiveChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New Tag</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={fishDataSource} pagination={false} onRow={onRowClick} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New Tag</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default FishTable