import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Row, Col, Typography, Popconfirm, Card, Modal, Table, Tag, DatePicker, Spin } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined, VerticalAlignTopOutlined } from "@ant-design/icons"
import { ACISAPIURL, DATETIMEFORMAT, EGGS, LOADING, MENUPATH_STOCKCOUNT, PAGINATIONSIZE, TAGGED, UNIDATEFORMAT, UNTAGGED } from '../Common/SystemParameter'
import { loadMarineLifeItemTypeUnitOfMeasurementStandardTable, numberWithCommas, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import moment from 'moment'

const { Title } = Typography
const { confirm } = Modal

const StockCountSummaryTable = () => {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [eggDataSource, setEggDataSource] = useState([])
    const [eggVarianceAdjustmentDataSource, setEggVarianceAdjustmentDataSource] = useState([])
    const [untaggedFishDataSource, setUntaggedFishDataSource] = useState([])
    const [untaggedFishVarianceAdjustmentDataSource, setUntaggedFishVarianceAdjustmentDataSource] = useState([])
    const [taggedFishDataSource, setTaggedFishDataSource] = useState([])
    const [taggedFishPresentFishDataSource, setTaggedFishPresentFishDataSource] = useState([])
    const [taggedFishVarianceAdjustmentDataSource, setTaggedFishVarianceAdjustmentDataSource] = useState([])
    const [taggedFishSurplusDataSource, setTaggedFishSurplusDataSource] = useState([])

    const [hideEgg, setHideEgg] = useState(false)
    const [hideUntaggedFish, setHideUntaggedFish] = useState(false)
    const [hideTaggedFish, setHideTaggedFish] = useState(false)

    const [eggItemType, setEggItemType] = useState([])
    const [eggUom, setEggUom] = useState("")
    const [untaggedItemType, setUntaggedItemType] = useState([])
    const [untaggedUom, setUntaggedUom] = useState("")
    const [taggedItemType, setTaggedItemType] = useState([])
    const [taggedUom, setTaggedUom] = useState("")

    const [completedDateTime, setCompletedDateTime] = useState(moment(moment(), UNIDATEFORMAT))
    const inputRef = useRef()
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    // Readonly means viewing a completed stock count. No changes is allowed.
    const [readOnly, setReadOnly] = useState(urlParams.get("completedOn") == "" ? false : true)

    //-----------------------
    // Post stock transaction
    //-----------------------
    const postStockCountStockTransaction = () => { 
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        axios.post(`${ACISAPIURL}stockcount/summary/poststocktransaction/${urlParams.get("pKey")}/`, {
                completedOn: completedDateTime
            }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Stock count variance adjustment stock transaction posted. Storage ${urlParams.get("storage")} has been unlocked.`, OTHERSYSPARAM("LONG_MSG_DURATION"))
            navigate({ 
                pathname: MENUPATH_STOCKCOUNT, 
            }) 
        })
        .catch( error => {
            reportError(error, `Failed to post stock count variance adjustment stock transaction.`)
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(true)
            refreshUserSession()
        })
    }

    //--------------------------
    // Get stock count summary
    //--------------------------
    const getStockCountSummary = async () => {
        setIsLoading(true)

        return await axios.get(`${ACISAPIURL}stockcount/summary/${urlParams.get("pKey")}`, { 
            params: { 
                stock_count: urlParams.get("pKey"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let data = []

            //------------
            // Egg balance
            //------------
            // Hide if zero stock balance
            if(response.data.egg.length == 0) setHideEgg(true)

            response.data.egg.forEach( egg => {
                let status = 0
                if(egg.stock_balance > egg.counted_balance)
                    status = -1
                else if(egg.stock_balance < egg.counted_balance)
                    status = 1
                
                data.push({
                    stockBalance: numberWithCommas(egg.stock_balance),
                    countedBalance: numberWithCommas(egg.counted_balance),
                    variance: numberWithCommas(-(egg.stock_balance - egg.counted_balance)),
                    status: status
                })

                // Egg variance adjustment
                let adjustment_data = []
                egg.variance_adjustment.forEach( adj => {
                    adjustment_data.push({
                        quantity: numberWithCommas(adj.quantity),
                        remark: adj.remark,
                        adjustment: adj.transaction_type_data.name
                    })
                })
                setEggVarianceAdjustmentDataSource(adjustment_data)
            })
            
            setEggDataSource(data)

            //----------------------
            // Untagged fish balance
            //----------------------
            // Hide if zero stock balance
            if(response.data.untagged_fish.length == 0) setHideUntaggedFish(true)

            data = []
            response.data.untagged_fish.forEach( untaggedFish => {
                let status = 0
                if(untaggedFish.stock_balance > untaggedFish.counted_balance)
                    status = -1
                else if(untaggedFish.stock_balance < untaggedFish.counted_balance)
                    status = 1

                data.push({
                    stockBalance: numberWithCommas(untaggedFish.stock_balance),
                    countedBalance: numberWithCommas(untaggedFish.counted_balance),
                    variance: numberWithCommas(-(untaggedFish.stock_balance - untaggedFish.counted_balance)),
                    status: status
                })

                // Untagged fish variance adjustment
                let adjustment_data = []
                untaggedFish.variance_adjustment.forEach( adj => {
                    adjustment_data.push({
                        quantity: numberWithCommas(adj.quantity),
                        remark: adj.remark,
                        adjustment: adj.transaction_type_data.name
                    })
                })
                setUntaggedFishVarianceAdjustmentDataSource(adjustment_data)
            })
            
            setUntaggedFishDataSource(data)

            //--------------------
            // Tagged fish balance
            //--------------------
            // Hide if zero stock balance
            if(response.data.tagged_fish.length == 0) setHideTaggedFish(true)

            data = []
            response.data.tagged_fish.forEach( taggedFish => {
                if(taggedFish.stock_balance == 0) setHideTaggedFish(true)

                let status = 0
                if(taggedFish.stock_balance > taggedFish.counted_balance)
                    status = -1
                else if(taggedFish.stock_balance < taggedFish.counted_balance)
                    status = 1

                data.push({
                    stockBalance: numberWithCommas(taggedFish.stock_balance),
                    countedBalance: numberWithCommas(taggedFish.counted_balance),
                    variance: numberWithCommas(-(taggedFish.stock_balance - taggedFish.counted_balance)),
                    status: status
                })

                // Tagged fish present fish
                let presentFish_data = []
                taggedFish.present_fish.filter( fish => fish.is_present).forEach( fish => {
                    presentFish_data.push({
                        fishId: fish.fish_data.id,
                    })
                })
                setTaggedFishPresentFishDataSource(presentFish_data)

                // Tagged fish variance adjustment
                let adjustment_data = []
                taggedFish.variance_adjustment.forEach( adj => {
                    adjustment_data.push({
                        fishId: adj.fish_data.id,
                        remark: adj.remark,
                        adjustment: adj.transaction_type_data.name
                    })
                })
                setTaggedFishVarianceAdjustmentDataSource(adjustment_data)

                // Tagged fish variance adjustment
                let surplus_data = []
                taggedFish.surplus.forEach( s => {
                    surplus_data.push({
                        fishId: s.fish_data.id,
                        remark: s.remark,
                    })
                })
                setTaggedFishSurplusDataSource(surplus_data)
            })
            
            setTaggedFishDataSource(data)
            
        })
        .catch( error => {
            reportError(error, "Failed to get stock count summary.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //----------
    // On finish
    //----------
    const onFinish = () => {
        if(readOnly) {
            navigate({
                pathname: MENUPATH_STOCKCOUNT,
            })
            return
        }

        if(completedDateTime == null) {
            inputRef.current.focus()
            message.warn(`Completed date time is required.`)
            return
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Post variance adjustment stock transaction (if any) and complete this stock count?</p></Space>,
            onOk() { postStockCountStockTransaction() },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        //navigate({
        //    pathname: "/stockcounttaggedfish",
        //    search: `?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}`
        //})
        navigate({
            pathname: MENUPATH_STOCKCOUNT,
        })
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setCompletedDateTime(datetime)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            const standardITUOMTable = await loadMarineLifeItemTypeUnitOfMeasurementStandardTable()
            
            let ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == urlParams.get("marineLifeId") && row.item_type_data.name.startsWith(EGGS))
            setEggItemType(ituom[0].item_type_data.name)
            setEggUom(ituom[0].uom_data.uom)
            
            ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == urlParams.get("marineLifeId") && row.item_type_data.name.startsWith(UNTAGGED))
            setUntaggedItemType(ituom[0].item_type_data.name)
            setUntaggedUom(ituom[0].uom_data.uom)

            ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == urlParams.get("marineLifeId") && row.item_type_data.name.startsWith(TAGGED))
            setTaggedItemType(ituom[0].item_type_data.name)
            setTaggedUom(ituom[0].uom_data.uom)
            
            getStockCountSummary()
        }

        init()
    }, [])

    //--------------
    // Table columns
    //--------------
    const eggTableColumns = [
        { title: `${eggItemType} Stock Balance (${eggUom})`, dataIndex: 'stockBalance', key: 'stockBalance', sorter: (a, b) => a.stockBalance - b.stockBalance },
        { title: `${eggItemType} Counted Balance (${eggUom})`, dataIndex: 'countedBalance', key: 'countedBalance', sorter: (a, b) => a.countedBalance - b.countedBalance },
        { title: `Variance (${eggUom})`, dataIndex: 'variance', key: 'variance', sorter: (a, b) => a.variance - b.variance },
        { title: 'Status', dataIndex: 'status', key: 'status', 
            render: (status) => {
                if(status == 0)
                    return <Tag color="blue">{OTHERSYSPARAM("TALLY")}</Tag>
                else if(status == 1)
                    return <Tag color="green">{OTHERSYSPARAM("SURPLUS")}</Tag>
                else if(status == -1)
                    return <Tag color="red">{OTHERSYSPARAM("DEFICIT")}</Tag>
            }
        },
    ]

    const eggVarianceAdjustmentTableColumns = [
        { title: `${eggItemType} Variance Quantity (${eggUom})`, dataIndex: 'quantity', key: 'quantity', sorter: (a, b) => a.quantity - b.quantity },
        { title: 'Adjustment Type', dataIndex: 'adjustment', key: 'adjustment', sorter: (a, b) => a.adjustment.localeCompare(b.adjustment) },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', sorter: (a, b) => a.remark.localeCompare(b.remark) },
    ]

    const untaggedFishTableColumns = [
        { title: `${untaggedItemType} Stock Balance (${untaggedUom})`, dataIndex: 'stockBalance', key: 'stockBalance', sorter: (a, b) => a.stockBalance - b.stockBalance },
        { title: `${untaggedItemType} Counted Balance (${untaggedUom})`, dataIndex: 'countedBalance', key: 'countedBalance', sorter: (a, b) => a.countedBalance - b.countedBalance },
        { title: `Variance (${untaggedUom})`, dataIndex: 'variance', key: 'variance', sorter: (a, b) => a.variance - b.variance },
        { title: 'Status', dataIndex: 'status', key: 'status', 
            render: (status) => {
                if(status == 0)
                    return <Tag color="blue">{OTHERSYSPARAM("TALLY")}</Tag>
                else if(status == 1)
                    return <Tag color="green">{OTHERSYSPARAM("SURPLUS")}</Tag>
                else if(status == -1)
                    return <Tag color="red">{OTHERSYSPARAM("DEFICIT")}</Tag>
            }
        },
    ]

    const untaggedFishVarianceAdjustmentTableColumns = [
        { title: `${untaggedItemType} Variance Quantity (${untaggedUom})`, dataIndex: 'quantity', key: 'quantity', sorter: (a, b) => a.quantity - b.quantity },
        { title: 'Adjustment Type', dataIndex: 'adjustment', key: 'adjustment', sorter: (a, b) => a.adjustment.localeCompare(b.adjustment) },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', sorter: (a, b) => a.remark.localeCompare(b.remark) },
    ]

    const taggedFishTableColumns = [
        { title: `${taggedItemType} Item Stock Balance (${taggedUom})`, dataIndex: 'stockBalance', key: 'stockBalance', sorter: (a, b) => a.stockBalance - b.stockBalance },
        { title: `${taggedItemType} Counted Balance (${taggedUom})`, dataIndex: 'countedBalance', key: 'countedBalance', sorter: (a, b) => a.countedBalance - b.countedBalance },
        { title: `Variance (${taggedUom})`, dataIndex: 'variance', key: 'variance', sorter: (a, b) => a.variance - b.variance },
        { title: 'Status', dataIndex: 'status', key: 'status', 
            render: (status) => {
                if(status == 0)
                    return <Tag color="blue">{OTHERSYSPARAM("TALLY")}</Tag>
                else if(status == 1)
                    return <Tag color="green">{OTHERSYSPARAM("SURPLUS")}</Tag>
                else if(status == -1)
                    return <Tag color="red">{OTHERSYSPARAM("DEFICIT")}</Tag>
            }
        },
    ]

    const taggedFishPresentFishTableColumns = [
        { title: 'Tag ID (Present)', dataIndex: 'fishId', key: 'fishId', sorter: (a, b) => a.fishId.localeCompare(b.fishId) },
    ]

    const taggedFishVarianceAdjustmentTableColumns = [
        { title: 'Tag ID (Deficit)', dataIndex: 'fishId', key: 'fishId', sorter: (a, b) => a.fishId.localeCompare(b.fishId) },
        { title: "Adjustment Type", dataIndex: 'adjustment', key: 'adjustment', sorter: (a, b) => a.adjustment.localeCompare(b.adjustment) },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', sorter: (a, b) => a.remark.localeCompare(b.remark) },
    ]

    const taggedFishSurplusTableColumns = [
        { title: 'Tagged Item ID (Surplus)', dataIndex: 'fishId', key: 'fishId', sorter: (a, b) => a.fishId.localeCompare(b.fishId) },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', sorter: (a, b) => a.remark.localeCompare(b.remark) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Stock Count Storage: ${urlParams.get("storage")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Initialised On:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
            
                    { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                        </>
                    }
                    { urlParams.get("completedOn") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Completed On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("completedOn")}</Card.Grid>
                        </>
                    }
                </Card>
            </Form.Item>

            { urlParams.get("completedOn") == "" &&
                <Form.Item name="completedDateTime" label="Stock Count Completed Date Time">
                    <DatePicker showTime onChange={onDateTimeChange} defaultValue={completedDateTime} format={DATETIMEFORMAT} ref={inputRef}/>
                </Form.Item>
            }
        </Form>

        <Form form={form} onFinish={onFinish}>
            <Row justify="center">
                <Col span={12} style={{textAlign: "center"}}>
                    <Card title="Stock Count Summary" style={{textAlign: "left"}}>
                        Complete the storage {urlParams.get("storage")} stock count by posting the relevant stock transactions (if any). Once posted, no changes is allowed.
                    </Card>
                </Col>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={12} style={{textAlign: "center"}}>
                    <Card title={`${eggItemType} Stock Count Summary`} style={{textAlign: "left"}} hidden={hideEgg}>
                        Detail of egg stock count. Please see below for more information.
                    </Card>
        
                    <Row><Col><Space><div /></Space></Col></Row>
            
                    {/* Egg */}
                    { !hideEgg &&
                        <>
                        <Table bordered columns={eggTableColumns} dataSource={eggDataSource} pagination={false} hidden={hideEgg} />
                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }
                    
                    { eggVarianceAdjustmentDataSource.length > 0 &&
                        <>
                        <Table bordered columns={eggVarianceAdjustmentTableColumns} dataSource={eggVarianceAdjustmentDataSource} pagination={false} />
                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }

                    <Card title={`${untaggedItemType} Stock Count Summary`} style={{textAlign: "left"}} hidden={hideUntaggedFish}>
                        Detail of untagged item stock count. Please see below for more information.
                    </Card>

                    <Row><Col><Space><div /></Space></Col></Row>
            
                    {/* Untagged Fish */}
                    { !hideUntaggedFish &&
                        <>
                        <Table bordered columns={untaggedFishTableColumns} dataSource={untaggedFishDataSource} pagination={false} />
                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }

                    { untaggedFishVarianceAdjustmentDataSource.length > 0 &&
                        <>
                        <Table bordered columns={untaggedFishVarianceAdjustmentTableColumns} dataSource={untaggedFishVarianceAdjustmentDataSource} pagination={false} />
                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }

                    <Card title={`${taggedItemType} Stock Count Summary`} style={{textAlign: "left"}} hidden={hideTaggedFish}>
                        Detail of tagged item stock count. Please see below for more information.
                    </Card>
            
                    <Row><Col><Space><div /></Space></Col></Row>
            
                    {/* Tagged Fish */}
                    { !hideTaggedFish &&
                        <>
                        <Table bordered columns={taggedFishTableColumns} dataSource={taggedFishDataSource} pagination={false} />
                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }

                    { taggedFishPresentFishDataSource.length > 0 &&
                        <>
                        <Table bordered columns={taggedFishPresentFishTableColumns} dataSource={taggedFishPresentFishDataSource} pagination={{size: PAGINATIONSIZE}} />
                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }

                    { taggedFishVarianceAdjustmentDataSource.length > 0 &&
                        <>
                        <Table bordered columns={taggedFishVarianceAdjustmentTableColumns} dataSource={taggedFishVarianceAdjustmentDataSource} pagination={false} />
                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }

                    { taggedFishSurplusDataSource.length > 0 &&
                        <>
                        <Table bordered columns={taggedFishSurplusTableColumns} dataSource={taggedFishSurplusDataSource} pagination={false} />
                        <Row><Col><Space><div /></Space></Col></Row>
                        </>
                    }

                </Col>
            </Row>    

            <Row justify="center">
                <Col span={10} style={{textAlign: "start"}}>
                    <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={4} />
                <Col span={10} style={{textAlign: "end"}}>
                    { !readOnly ?
                        <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Post</Button> :
                        <Button type="primary" htmlType="submit" disabled={disableButton} icon={<VerticalAlignTopOutlined />}>Stock Count Main</Button>
                    }
                </Col>
            </Row>
        </Form>
        </Spin>
        </>
    )
}

export default StockCountSummaryTable
