import { Table, Row, Col, Space, Button, Form, Collapse, Pagination, message, Select, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, LOADING, MEDIAMAXWIDTH, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { reportError } from '../Common/Utility'
import { formLayout_2Columns } from '../Common/Layout'
import moment from 'moment'
import LocationSelect from '../Common/LocationSelect'
import { useMediaQuery } from 'react-responsive'

const { Panel } = Collapse
const { Option } = Select

//----------
// Component
//----------
const SiteTable = () => {
    
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))

    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [siteId, setSiteId] = useState(0)
    const [site, setSite] = useState("")
    const [locationId, setLocationId] = useState(0)
    const [location, setLocation] = useState("")
    const [siteOption, setSiteOption] = useState([])
    const [siteDataSource, setSiteDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    //----------------
    // Search spawning
    //----------------
    const searchSite = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}sitepaginated/`, {
            params: { 
                location: locationId,
                site: siteId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search site.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( site => {
            data.push({
                key: site.pKey,
                locationId: site.location_data.pKey,
                location: site.location_data.name,
                name: site.name,
                id: site.id
            })
        })
        
        setSiteDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                                
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        if(locationId != 0) searchCriteria = `${searchCriteria}\nLocation: ${location}`
        if(siteId != 0) searchCriteria = `${searchCriteria}\nSite Name: ${site}`
                                
        axios.get(`${ACISAPIURL}site/download/`, {
            params: { 
                location: locationId,
                site: siteId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Site ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------
    // Load site
    //-------------------
    const getSite = (locationId) => {
        axios.get(`${ACISAPIURL}site/`, {
            params: {
                location: locationId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( site => <Option key={site.pKey}>{site.name}</Option>)
            setSiteOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get site(s).")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchSite(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchSite(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/sitenew" })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/siteupdate", 
                    search: `?pKey=${record.key}&locationId=${record.locationId}&location=${record.location}&name=${record.name}&id=${record.id}`
                }) 
            }
        }
    }

    //----------------------
    // On search change
    //----------------------
    const onSearchLocationChange = (e, value) => {
        if(e != undefined) {
            setLocationId(e)
            setLocation(value.children)
        }
        else {
            setLocationId(0)
            setLocation("")
        }
        
        setSiteId(0)
        setSite("")

        form.setFieldsValue({
            "siteName": ""
        })

        setSiteOption([])
        getSite(e)
    }

    //---------------
    // On site change
    //---------------
    const onSiteChange = (e, value) => {
        if(e != undefined) {
            setSiteId(e)
            setSite(value.children)
        }
        else {
            setSiteId(0)
            setSite("")
        }
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getSite()
        searchSite(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Site Name', dataIndex: 'name', key: 'name', sorter: (a, b) => a.name.localeCompare(b.name) },
        { title: 'Location', dataIndex: 'location', key: 'location', sorter: (a, b) => a.location.localeCompare(b.location) },
        { title: 'Site ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Site" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="locationName" label="Location Name">
                                <LocationSelect withBlank={true} onChange={onSearchLocationChange}/>
                            </Form.Item>

                            <Form.Item name="siteName" label="Site Name">
                                <Select onChange={onSiteChange} style={{width: optionWidth}} allowClear showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {siteOption}    
                                </Select>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={siteDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default SiteTable