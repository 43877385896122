import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { OTHERSYSPARAM } from '../Common/UserSession'
import StandardGrowthTableTable from './StandardGrowthTableTable'
import { walltiles } from '../Common/Layout'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const StandardGrowthMain = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    
    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight, ...walltiles}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={null} 
                    title="Standard Growth Table">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Manage standard growth table</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><div /></Space></Col></Row>
                
                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={22}><StandardGrowthTableTable /></Col>
                    <Col span={1}></Col>
                </Row>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={null} 
                        title="Standard Growth Table:"
                        subTitle="Manage standard growth table"/>} />
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default StandardGrowthMain