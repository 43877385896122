import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL, ALLOWED_SITES, MEDIAMAXWIDTH, MENUPATH_DASHBOARD } from "./SystemParameter"
import { reportError } from "./Utility"
import { getUserAuthToken, getUserSiteId, setUserSite, refreshUserSession, OTHERSYSPARAM, SYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const { Option } = Select

//----------
// Component
//----------
const ACISSiteSelect = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const navigate = useNavigate()
    const [siteOption, setSiteOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
                
    //-------------------
    // Handle site change
    //-------------------
    const onSiteChange = (siteId) => {
        const whichSite = siteOption.filter( option => option.key == siteId )
        setUserSite(siteId, whichSite[0].props.children)
        navigate({ pathname: MENUPATH_DASHBOARD })
        window.location.reload()
    }

    //---------------
    // Load all sites
    //---------------
    const getAllSite = () => {
        axios.get(`${ACISAPIURL}site/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            // To add user's allowed sites ONLY.
            const data = response.data.results.filter( site => String(SYSPARAM(ALLOWED_SITES)).includes(site.pKey) )

            const options = data.map( site => <Option key={site.pKey}>{site.name}</Option> )
            setSiteOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get site(s)")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllSite()
    }, []) // []] is for oldValue, newValue comparison. If equal, will not re-render.
    
    return(
        <Select style={{width: optionWidth}} defaultValue={getUserSiteId()} onChange={onSiteChange}>
            {siteOption}
        </Select>
    )
}

export default ACISSiteSelect