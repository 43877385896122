import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, PageHeader, Card, DatePicker, Input, Modal, Descriptions, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MENUPATH_FEEDING, UNIDATETIMEFORMAT, UNTAGGED, KG, G } from "../Common/SystemParameter"
import { loadMarineLifeItemTypeUnitOfMeasurementStandardTable, reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, SYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import moment from 'moment'
import FeedingTypeSelect from './FeedingTypeSelect'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal

moment.updateLocale('en', { invalidDate : "" })

//----------
// Component
//----------
const FeedingUpdate = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [form] = Form.useForm()
    const [fedOn, setFedOn] = useState(moment(moment(urlParams.get("fedOn")), UNIDATETIMEFORMAT))
    const [feedingTypeId, setFeedingTypeId] = useState(urlParams.get("feedingTypeId"))
    const [marineLifeUom, setMarineLifeUom]  = useState("")
    const [feedingUom, setFeedingUom] = useState(`${urlParams.get("uom")}`)
                
    //---------------
    // Update feeding
    //---------------
    const updateFeeding = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}feeding/update/${urlParams.get("pKey")}/`, {
                feeding_quantity: values.feedingQuantity,
                fish_quantity: values.fishQuantity,
                feeding_type: feedingTypeId,
                feeding_uom: (feedingTypeId != SYSPARAM("Artemia") && feedingTypeId != SYSPARAM("Rotifer")) ? SYSPARAM("g") : SYSPARAM("mL"),
                fedOn: fedOn,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Feeding record updated.")
                navigate({ 
                    pathname: MENUPATH_FEEDING
                })
            })
            .catch( error => {
                reportError(error, `Failed to update feeding record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //----------------------
    // Delete feeding record
    //----------------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}feeding/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Feeding record deleted.`)
            navigate({ pathname: MENUPATH_FEEDING })
        })
        .catch( error => {
            reportError(error, "Failed to delete feeding record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = (e) => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update feeding record confirmed?</p></Space>,
            onOk() { updateFeeding() },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_FEEDING
        })
    }

    //---------------------
    // On date time change
    //---------------------
    const onFeedingDateTimeChange = (datetime) => {
        setFedOn(datetime)
    }

    //-----------------------
    // On feeding type change
    //-----------------------
    const onFeedingTypeChange = (e) => {
        setFeedingTypeId(e)
        
        switch(parseInt(e)) {
            case SYSPARAM("Artemia"):
            case SYSPARAM("Rotifer"):
                setFeedingUom("mL")
                break
            default:
                setFeedingUom("g")
        }
    }

    //------------------
    // Custom validation
    //------------------
    const validateFeedingType = (() => {
        if(feedingTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Feeding type is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            const standardITUOMTable = await loadMarineLifeItemTypeUnitOfMeasurementStandardTable()
            // It is ok to use tagged or untagged
            let ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == urlParams.get("marineLifeId") && row.item_type_data.name.startsWith(UNTAGGED))
            setMarineLifeUom(ituom[0].uom_data.uom)

            form.setFieldsValue({
                feedingQuantity: urlParams.get("feedingQuantity"),
                fishQuantity: urlParams.get("fishQuantity"),
                fedOn: fedOn
            })

            if(urlParams.get("uom") == KG) {
                form.setFieldsValue({
                    feedingQuantity: urlParams.get("feedingQuantity") * 1000,
                })

                setFeedingUom(G)
            }
        }
        init()
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Feeding">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update feeding record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Hatching ID: ${urlParams.get("id")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("storage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
                     
                            { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Form.Item>

                    <Form.Item name="feedingType" label="Feeding Type"
                        rules={[
                            { required: true, validator: validateFeedingType },
                        ]}>
                        <FeedingTypeSelect withBlank={false} defaultValue={urlParams.get("feedingType")} onChange={onFeedingTypeChange}/>
                    </Form.Item>

                    <Form.Item name="fedOn" label="Feeding Date Time"
                        rules={[
                            //{ required: true, validator: validateFeedingDateTime },
                            { required: true, message: "Feeding date time is required."},
                        ]}>
                        <DatePicker showTime onChange={onFeedingDateTimeChange} defaultValue={fedOn} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="feedingQuantity" label={`Feeding Quantity(${feedingUom})`}
                        rules={[
                            { required: true, message: "Feeding quantity is required."},
                        ]}>
                        <InputNumber min={0} max={10000} step={1} placeholder="Feeding Quantity"/>
                    </Form.Item>

                    <Form.Item label={`Marine Life Quantity(${marineLifeUom})`}>
                        <Input.Group>
                            <Form.Item name="fishQuantity" noStyle
                                rules={[
                                    { required: true, message: "Marine life quantity is required."},
                                ]}>
                                <InputNumber min={0} max={10000000} step={1} placeholder="Marine Life Quantity" readOnly/>
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>
                                        
                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>}>Update</Button>
                            <Popconfirm title="Delete feeding record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" disabled={disableButton} onClick={onBack} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Feeding:"
                    subTitle="Update feeding record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default FeedingUpdate