import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, DatePicker, Card, PageHeader, Input, Modal, Descriptions, Spin } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, EGGS, KG, LOADING, MENUPATH_MORTALITY, PERCENT, PRAWNMARINELIFEPKEY, UNIDATETIMEFORMAT, UNIT, } from '../Common/SystemParameter'
import { numberWithCommas, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import TextArea from 'antd/lib/input/TextArea'
import MortalityReasonSelect from './MortalityReasonSelect'
import moment from 'moment'
import { releaseStorage } from '../Common/releaseStorage'
import ItemTypeUnitOfMeasurementSelect from '../Common/ItemTypeUnitOfMeasurementSelect'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal

//----------
// Component
//----------
const MortalityNew = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()

    const [mortalityReasonId, setMortalityReasonId] = useState(0)
    const [mortalityDateTime, setMortalityDateTime] = useState(moment(), UNIDATETIMEFORMAT) 
                   
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [itemTypeUomId, setItemTypeUomId] = useState(0)
    const [itemTypeUom, setItemTypeUom] = useState("")

    //-----------------
    // Create mortality
    //-----------------
    const createMortalityRecord = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        axios.post(`${ACISAPIURL}mortality/create/`, {
            batch: urlParams.get("batchId"),
            storage: urlParams.get("storageId"),

            item_type: urlParams.get("itemTypeId"),
            item_type_name: urlParams.get("itemType"),
            item_type_uom: itemTypeUomId,
            quantity: form.getFieldValue("quantity"),
            
            reason: mortalityReasonId,
            mortality_datetime: mortalityDateTime,
            remark: form.getFieldValue("remark"),
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Mortality record saved.")

            navigate({ 
                pathname: MENUPATH_MORTALITY
            })

            releaseStorage(urlParams.get("batchId"), urlParams.get("batch"), urlParams.get("storageId"))
        })
        .catch( error => {
            reportError(error, `Failed to save mortality record.`)
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = (e) => {
        form.validateFields()
        .then( values => {
            if(itemTypeUom.includes(PERCENT)) {
                // If percentage is selected. It can't be more than 100.
                if(parseInt(values.quantity) > 100) {
                    message.warn("Mortality quantity cannot exceeded 100 percent.")
                    return
                }
                saveConfirmation()
            }
            else if(itemTypeUom.startsWith(EGGS) && itemTypeUom.includes(UNIT)) {
                // If unit is selected. It can't be more than its mL equivalent.
                // 1 mL = 1000 units.
                const availableUnit = parseInt(urlParams.get("availableQuantity")) * OTHERSYSPARAM(`1_ML_EGG_IN_UNIT_${urlParams.get("marineLifeId")}`)

                if(parseInt(values.quantity) > availableUnit) {
                    message.warn(`Mortality quantity cannot exceeded ${availableUnit} unit.`)
                    return
                }
                saveConfirmation()
            }
            else if(itemTypeUom.includes(KG)) {
                const totalAvailableWeightKg = parseFloat(urlParams.get("storageAverageWeight")) * parseInt(urlParams.get("availableQuantity")) / 1000

                if(parseFloat(values.quantity) > totalAvailableWeightKg) {
                    message.warn(`Mortality weight cannot exceeded ${totalAvailableWeightKg} KG.`)
                    return
                }
                saveConfirmation()
            }
            else {
                // Untagged fish or egg-mL is selected.
                if(values.quantity > urlParams.get("availableQuantity")) {
                    message.warn("Mortality quantity exceeded available quantity.")
                    return
                }
                saveConfirmation()
            }
        })
    }

    //-------------
    // Confirm save
    //-------------
    const saveConfirmation = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Save mortality record confirmed?</p></Space>,
            onOk() { createMortalityRecord() },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: "/mortality"
        })
    }

    //---------------------------
    // On mortality reason change
    //---------------------------
    const onMortalityReasonChange = (e, value) => {
        setMortalityReasonId(e)
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setMortalityDateTime(datetime)
    }

    //----------------------------------------
    // On item type unit of measurement change
    //----------------------------------------
    const onItemTypeUomChange = (e, value) => {
        setItemTypeUomId(e)
        setItemTypeUom(value.children)
    }

    //------------------
    // Custom validation
    //------------------
    const validateMortalityReason = (() => {
        if(mortalityReasonId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Mortality reason is required."))
    })

    const validateMortalityDateTime = (() => {
        if(mortalityDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Mortality date time is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            remark: ""
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Mortality">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create mortality record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                        </Card>
                    </Form.Item>

                    { urlParams.get("itemType").startsWith(EGGS) &&
                        <Form.Item label={`Note: Eggs will always be rounded up to the next integral mL`}>
                        </Form.Item>
                    }
                    
                    <Form.Item label={`Mortality Quantity Out of ${numberWithCommas(urlParams.get("availableQuantity"))} (${urlParams.get("itemTypeUom")}) ${urlParams.get("marineLifeId") != PRAWNMARINELIFEPKEY ? '' : '(' + parseFloat(urlParams.get("storageAverageWeight").replace(',', '')) * parseInt(urlParams.get("availableQuantity").replace(',', '')) / 1000 + 'KG)'} `}>
                        <Input.Group>
                            <Form.Item name="quantity" noStyle
                                rules={[
                                    { required: true, message: "Mortality quantity is required." },
                                ]}>
                                <InputNumber min={1} max={10000000} step={1} placeholder="Quantity"/>
                            </Form.Item>
                                
                            <Form.Item name="itemTypeUom" noStyle
                                rules={[
                                    { required: true, message: "Item type - Unit of Measurement is required." },
                                ]}>
                                <ItemTypeUnitOfMeasurementSelect withBlank={false} marineLifeId={urlParams.get("marineLifeId")} 
                                    itemType={urlParams.get("itemType").split(' ')[0]} onChange={onItemTypeUomChange} />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item name="mortalityReason" label="Possible Mortality Reason"
                        rules={[
                            { required: true, validator: validateMortalityReason },
                        ]}>
                        <MortalityReasonSelect withBlank={false} onChange={onMortalityReasonChange} />
                    </Form.Item>

                    <Form.Item name="mortalityDateTime" label="Mortality Date Time"
                        rules={[
                            { required: true, validator: validateMortalityDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={mortalityDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>
                    
                    <Form.Item name="remark" label="Remark"
                        rules={[
                            { required: true, message: "Remark is required." },
                        ]}>
                        <TextArea rows={3} maxLength={500}/>
                    </Form.Item>
                    
                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Mortality:"
                    subTitle="Create mortality record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default MortalityNew