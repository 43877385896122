import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Input, Popconfirm, message, Card, PageHeader, Modal, Descriptions, DatePicker, Spin } from 'antd'
import { LeftOutlined, SaveOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, EGGS, KG, LOADING, MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE, MENUPATH_NEWTAG, PERCENT, TAGGED, UNIDATEFORMAT, UNIDATETIMEFORMAT, UNTAGGED } from "../Common/SystemParameter"
import { calculateWeightVariancePercentage, reportError, storageCapacityCheck } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, SYSPARAM, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import ItemTypeUnitOfMeasurementSelect from '../Common/ItemTypeUnitOfMeasurementSelect'
import TransactionTypeSelect from '../Common/TransactionTypeSelect'
import moment from 'moment'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal
const { TextArea } = Input

//----------
// Component
//----------
const CollectiveFishStockIn = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()

    const [transactionTypeId, setTransactionTypeId] = useState(0)
    const [transactionType, setTransactionType] = useState("")

    const [stockInDateTime, setStockInDateTime] = useState(moment(moment(), UNIDATEFORMAT))
        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [itemTypeUomId, setItemTypeUomId] = useState(urlParams.has("itemTypeUomId") ? urlParams.get("itemTypeUomId") : 0)
    const [itemTypeUom, setItemTypeUom] = useState(urlParams.has("itemTypeUom") ? urlParams.get("itemTypeUom") : "")
            
    //------------------------------
    // Create stock-in transaction
    //------------------------------
    const createStockIn = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        //-------------------------------------------------------------------------------------------------
        // 20220114
        // If item type uom of Kg is selected, need to convert quantity of Kg back to rounded up tail/unit.
        //-------------------------------------------------------------------------------------------------
        let quantity = form.getFieldValue("quantity")
        if(!itemTypeUom.includes(KG)) 
            quantity = Math.ceil(quantity)
        else
            quantity = Math.ceil(quantity * 1000 / parseFloat(form.getFieldValue("averagePerUnitWeightGram")))

        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}stocktransaction/in/create/`, {
                storage: urlParams.get("storageId"),
                batch: urlParams.get("batchId"),
                quantity: quantity,
                transaction_type: transactionTypeId,
                item_type_uom: itemTypeUomId,
                average_per_unit_weight_gram: values.averagePerUnitWeightGram,
                average_per_unit_length_mm: values.averagePerUnitLengthMm,
                birth_date: moment(moment().subtract(values.ageDays, "days"), UNIDATETIMEFORMAT),
                remark: values.remark,
                transactedOn: stockInDateTime,
                marine_life: urlParams.get("marineLifeId")
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Stock-In transaction was successful.")

                if(transactionTypeId != SYSPARAM("Temporary Loan"))
                    navigate({ 
                        pathname: MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE
                    })
                else {
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>You have stock in some loan units. It is highly recommended that you tag these fish now. Go to New Tag page to create tag?</p></Space>,
                        onOk() { 
                            navigate({ 
                                pathname: MENUPATH_NEWTAG,
                                search: `?storageId=${urlParams.get("storageId")}`
                            })
                        },
                        onCancel() {},
                        centered: true
                    })
                }
            })
            .catch( error => {
                reportError(error, `Failed to create stock-in transaction.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //-------------
    // Confirmation
    //-------------
    const stockInConfirmation = () => {
        let msg = "Stock in confirmed?"
        if(String(transactionType.children).startsWith("Stock Adjustment"))
            msg = <Title level={5}>
                Stock adjustment requires approval from the management. The mangement may contact you to provide the reason for this adjustment.
                <br/><br/>You can view pending stock transaction in menu "Inventory/Pending Stock Transaction".
                <br/><br/>Do you want to proceed with this adjustment?
                </Title>
        
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>{msg}</p></Space>,
            onOk() { createStockIn() },
            onCancel() {},
            centered: true
        })
    }

    //-------------------
    // Check overcrowding
    //-------------------
    const checkOvercrowd = async () => {
        if(itemTypeUom.startsWith(EGGS)) {
            stockInConfirmation()
            return
        }

        // Check for overcrowding by weight in Kg (untagged only, not egg).
        let weightToAddKg = form.getFieldValue("quantity") * form.getFieldValue("averagePerUnitWeightGram") / 1000
                
        try{
            const prompt = await storageCapacityCheck(urlParams.get("marineLifeId"), urlParams.get("batchId"), urlParams.get("storageId"), weightToAddKg)

            if(prompt)
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Overcrowding will happen after transfer. Procced?</p></Space>,
                    onOk() { stockInConfirmation() },
                    onCancel() {},
                    okText: OTHERSYSPARAM("YES"),
                    cancelText: OTHERSYSPARAM("NO"),
                    centered: true
                })
            else
                stockInConfirmation()
        }
        catch(error) {
            reportError(error, "Failed to get marine life storage type capacity information.")
        }
    }

    //----------------------
    // Check weight variance
    //----------------------
    const checkWeightVariance = () => {
        let threshold = parseFloat(OTHERSYSPARAM("WEIGHT_VARIANCE_THRESHOLD_PERCENT")) / 100

        if(parseFloat(form.getFieldValue("averagePerUnitWeightGram")) != 0 && parseFloat(urlParams.get("averageWeight")) != 0) {
            let weightDiff = calculateWeightVariancePercentage(parseFloat(form.getFieldValue("averagePerUnitWeightGram")), parseFloat(urlParams.get("averageWeight")))
                                        
            if(weightDiff >= threshold) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Weight variance between the two batches exceeded by {(threshold * 100).toFixed(2)}%. Proceed to stock-in?</p></Space>,
                    onOk() { checkOvercrowd() },
                    onCancel() {},
                    centered: true
                })
            }
            else
                checkOvercrowd()
        }
        else
            checkOvercrowd()
    }

    //--------
    // On save
    //--------
    const onNext = () => {
        form.validateFields()
        .then( values => {
            if(itemTypeUom.startsWith(UNTAGGED)) {
                if(parseFloat(values.averagePerUnitWeightGram) == 0) {
                    message.warn("Zero average per unit weight. Please enter the weight before proceed.")
                    return
                }

                // 20211217
                if(OTHERSYSPARAM("ISIMPLEMENTATIONPHASE") == 0) {
                    if(parseFloat(values.averagePerUnitLengthMm) == 0) {
                        message.warn("Zero average per unit length. Please enter the length before proceed.")
                        return
                    }

                    if(parseInt(values.ageDays) == 0) {
                        message.warn("Zero age. Please enter the age before proceed.")
                        return
                    }
                }
            }

            // if(itemTypeUomId == SYSPARAM("EGG_UNIT") && parseInt(values.quantity) < OTHERSYSPARAM("1_ML_EGG_IN_UNIT") / 2) {
            //     message.warn(`At least ${OTHERSYSPARAM("1_ML_EGG_IN_UNIT") / 2} unit of eggs is needed to make up eggs of 1 mL`)
            //     return
            // }

            checkWeightVariance()
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_COLLECTIVEFISHSTOCKTRANSACTIONDESTINATIONSTORAGE
        })
    }
    
    //----------------------------------------
    // On item type unit of measurement change
    //----------------------------------------
    const onItemTypeUomChange = (e, value) => {
        setItemTypeUomId(e)
        setItemTypeUom(value.children)
    }

    //---------------------------
    // On transaction type change
    //---------------------------
    const onTransactionTypeChange = (e, value) => {
        setTransactionTypeId(e)
        setTransactionType(value)
    }
    
    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setStockInDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateTransactionType = (() => {
        if(transactionTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Transaction type is required."))
    })

    const validateStockInDateTime = (() => {
        if(stockInDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Stock-In date time is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            averagePerUnitWeightGram: 0,
            averagePerUnitLengthMm: 0,
            ageDays: 0,
            remark: ""
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onBack()} 
                    title="Stock-In">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create stock-in record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onNext} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Destination Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                        </Card>
                    </Form.Item>

                    <Form.Item name="stockInDateTime" label="Stock In Date Time"
                        rules={[
                            { required: true, validator: validateStockInDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={stockInDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="transactionType" label="Transaction Type"
                        rules={[
                            { required: true, validator: validateTransactionType },
                        ]}>
                        <TransactionTypeSelect 
                            withBlank={false} 
                            value={transactionType} 
                            type={OTHERSYSPARAM("STOCK_IN")} 
                            excludes={[SYSPARAM("Stock Count Variance Adjustment (In)")]}
                            onChange={onTransactionTypeChange} />
                    </Form.Item>

                    <Form.Item label={`Note: Eggs will always be rounded up to the next integral mL`}>
                    </Form.Item>

                    <Form.Item label="Stock-In Quantity">
                        <Input.Group>
                            <Form.Item name="quantity" noStyle
                                rules={[
                                    { required: true, message: "Stock-In quantity is required."},
                                ]}>
                                <InputNumber min={0.1} max={100000} step={1} placeholder="Quantity"/>
                            </Form.Item>
                            <Form.Item name="itemTypeUom" noStyle
                                rules={[
                                    { required: true, message: "Item type - Unit of Measurement is required." },
                                ]}>
                                <ItemTypeUnitOfMeasurementSelect withBlank={false} marineLifeId={urlParams.get("marineLifeId")} 
                                    excludeItemType={TAGGED} excludeUom={PERCENT} onChange={onItemTypeUomChange} />
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item name="averagePerUnitWeightGram" label="Average Per Unit Weight (g)"
                        rules={[
                            { required: true, message: "Average per unit weight is required."},
                        ]}>
                        <InputNumber min={0} max={1000000} precision={2} step={1} placeholder="Average Per Unit Weight (g)"/>
                    </Form.Item>

                    <Form.Item name="averagePerUnitLengthMm" label="Average Per Unit Length (mm)"
                        rules={[
                            { required: true, message: "Average per unit length is required."},
                        ]}>
                        <InputNumber min={0} max={1000000} precision={2} step={1} placeholder="Average Per Unit Length (mm)"/>
                    </Form.Item>

                    <Form.Item name="ageDays" label="Age (days)"
                        rules={[
                            { required: true, message: "Age is required."},
                        ]}>
                        <InputNumber min={0} max={1000000} step={1} placeholder="Age (days)"/>
                    </Form.Item>

                    <Form.Item name="remark" label="Remark"
                        rules={[
                            { required: true, message: "Remark is required."},
                        ]}>
                        <TextArea rows={3} maxLength={500}/>
                    </Form.Item>
                    
                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title={`Stock-In:`}
                    subTitle="Create stock-in record "/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default CollectiveFishStockIn