import { Table, Row, Col, Space, Button, Form, Collapse, message, Tag, Popconfirm, Select, Pagination, Spin } from 'antd'
import { LeftOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, LOADING, MENUPATH_GROWTHDEV, PAGESIZE, PAGINATIONSIZE } from "../Common/SystemParameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, SYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import { BatchColumn, DesktopColumns, RFIDColumn } from '../Common/CommonTableColumns'

const { Panel } = Collapse
const { Option } = Select

//----------
// Component
//----------
const GrowthDevelopmentFishTable = () => {
    const [optionWidth, setOptionWidth] = useState(OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    const navigate = useNavigate()

    const [searchFishId, setSearchFishId] = useState(0)
    const [fishIdOption, setFishIdOption] = useState([])
                
    const [fishDataSource, setFishDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //------------
    // Search fish
    //------------
    const searchFish = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}fish/`, {
            params: { 
                id: searchFishId,
                storage: urlParams.get("storageId"), 
                active: true,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search fish.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( fish => {
            data.push({
                key: fish.pKey,
                id: fish.id,
                species: fish.batch_data.species_data.short_name,
                speciesId: fish.batch_data.species_data.pKey,
                acquiringMethod: fish.batch_data.acquiring_method_data.name,
                storage: fish.storage_data.name,
                storageId: fish.storage_data.pKey,
                lifecycle: fish.lifecycle_data.name,
                lifecycleId: fish.lifecycle_data.pKey,
                gender: fish.gender_data.name,
                genderId: fish.gender_data.pKey,
                tagId: fish.tag_id != null ? fish.tag_id : "",
                isBrood: fish.isBrood,
                batch: fish.batch_data.id,
                batchId: fish.batch_data.pKey,
                active: fish.active,
                batchType: fish.batch_data.batch_type_data.name,
                aquacultureStage: fish.aquaculture_stage_data.name,
                aquacultureStageId: fish.aquaculture_stage_data.pKey,
                batchAquacultureStage: fish.batch_data.aquaculture_stage_data.name,
                batchAquacultureStageId: fish.batch_data.aquaculture_stage_data.pKey,
                weight: numberWithCommas(fish.average_per_unit_weight_gram),
                length: fish.average_per_unit_length_mm,
                ageDays: fish.age_days,
            })
        })
        
        setFishDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------
    // Get fish for Select
    //--------------------
    const getFish = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}fishfull/`, {
            params: {
                storage: urlParams.get("storageId"),
                active: true
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( fish => <Option key={fish.pKey}>{fish.id}</Option>)
            setFishIdOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get fish list.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFish(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchFish(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onNext = (record) => {
        if(record.active == false) {
            message.warn("Unable to sample inactive fish.")
            return
        }

        let searchParams = `?batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&species=${record.species}&storageId=${record.storageId}&storage=${record.storage}&acquiringMethod=${record.acquiringMethod}&aquacultureStageId=${record.batchAquacultureStageId}&aquacultureStage=${record.batchAquacultureStage}&lifecycleId=${record.lifecycleId}&lifecycle=${record.lifecycle}&fishId=${record.key}&fish=${record.id}&weight=${record.weight.replace(',', '')}&length=${record.length}&ageDays=${record.ageDays}&observedOn=${urlParams.get("observedOn")}`
        if(urlParams.has("fromMain"))
            searchParams = `${searchParams}&fromMain=true`
        else if(urlParams.has("fromDailyAverage"))
            searchParams = `${searchParams}&fromDailyAverage=true`
            
        navigate({ 
            pathname: "/growthdevelopmentnew", 
            search: searchParams
        }) 
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                onNext(record)
            }
        }
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        const search = `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&observedOn=${urlParams.get("observedOn")}`
        if(urlParams.has("fromMain"))
            navigate({ 
                //pathname: "/growthdevelopmentmain", 
                pathname: "/growthdevelopmenttable", 
                search: search        
            })
        else if(urlParams.has("fromDailyAverage"))
            navigate({ 
                pathname: MENUPATH_GROWTHDEV, 
                search: search        
            })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getFish()
        searchFish(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        BatchColumn(),
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Tag ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        ...DesktopColumns(),
        { title: 'Weight (g)', dataIndex: 'weight', key: 'weight', align: "right", sorter: (a, b) => stringNumberSorter(a.weight, b.weight) },
        { title: 'Gender', dataIndex: 'genderId', key: 'genderId', sorter: (a, b) => a.genderId - b.genderId,
            render: (genderId, record) => {
                if(genderId == SYSPARAM("Male"))
                    return <Tag color="blue">{record.gender}</Tag>
                else if(genderId == SYSPARAM("Female"))
                    return <Tag color="red">{record.gender}</Tag>
                else 
                    return <Tag color="green">{record.gender}</Tag>
            }
        },
        { title: 'Is Brood', dataIndex: 'isBrood', key: 'isBrood', sorter: (a, b) => a.isBrood - b.isBrood,
            render: (isBrood) => {
                if(isBrood)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        RFIDColumn()
        /*{ title: 'Status', dataIndex: 'active', key: 'active', 
            render: (active) => {
                if(active)
                    return <Tag color="blue">{OTHERSYSPARAM("ACTIVE")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("INACTIVE")}</Tag>
            }
        },*/
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Fish" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="id" label="Tag ID">
                                <Select style={{width: optionWidth}} onChange={setSearchFishId} allowClear showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {fishIdOption}
                                </Select>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={12} style={{textAlign: "start"}}>
                <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                    <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                </Popconfirm>
            </Col>
            <Col span={12} />
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={fishDataSource} pagination={false} onRow={onRowClick} />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}>
                <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                    <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                </Popconfirm>
            </Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default GrowthDevelopmentFishTable