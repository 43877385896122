import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Row, Col, Modal, InputNumber, PageHeader, Layout, Descriptions, BackTop, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { formLayout } from '../Common/Layout'
import axios from 'axios'
import { ACISAPIURL, LOADING, MENUPATH_STANDARDGROWTHTABLE2 } from '../Common/SystemParameter'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession, SYSPARAM } from '../Common/UserSession'
import { reportError } from '../Common/Utility'
import SpeciesSelect from '../Common/SpeciesSelect'

const { Header, Footer, Content } = Layout
const { confirm } = Modal


const StandardGrowthNewTable2 = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [speciesId, setSpeciesId] = useState(0)
    
    //------------------------------
    // Create standard growth record
    //------------------------------
    const createStandardGrowthRecord = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}standardgrowth2/create/`, {
                species: speciesId,
                day: values.day,
                weight_gram: values.weightGram
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Standard growth record saved.")

                navigate({ 
                    pathname: MENUPATH_STANDARDGROWTHTABLE2,
                })
            })
            .catch( error => {
                reportError(error, `Failed to save standard growth record.`)
            })
            .finally(() => {
                setIsLoading(false)
                setDisableButton("")
                refreshUserSession()
            })
        })
    }

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create standard growth record confirmed?</p></Space>,
            onOk() { createStandardGrowthRecord() },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_STANDARDGROWTHTABLE2,
        })
    }

    //------------------
    // Custom validation
    //------------------
    const validateSpecies = (() => {
        if(speciesId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Species is required."))
    })

    //----------------------------
    // On aquaculture state change
    //----------------------------
    const onSpeciesChange = (e) => {
        setSpeciesId(e)
    }

    //---------------------------
    // On cancel
    //---------------------------
    const onCancel = () => {
        navigate({ 
            pathname: MENUPATH_STANDARDGROWTHTABLE2,
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={() => onBack()} 
                    title="Standard Growth Table 2">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create new standard growth record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Row><Col><Space><div /></Space></Col></Row>
        
                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item name="species" label="Species"
                        rules={[
                            { required: true, validator: validateSpecies },
                        ]}>
                        <SpeciesSelect onChange={onSpeciesChange} withBlank={false} />
                    </Form.Item>

                    <Form.Item name="day" label="Day"
                        rules={[
                            { required: true, message: "Day is required."},
                        ]}>
                        <InputNumber min={0} max={10000000} step={1} placeholder="Day"/>
                    </Form.Item>

                    <Form.Item name="weightGram" label="Weight (g)"
                        rules={[
                            { required: true, message: "Weight is required."},
                        ]}>
                        <InputNumber min={0} max={1000000} precision={2} step={1} placeholder="Weight (g)"/>
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Save</Button>
                            <Button type="primary" htmlType="button" disabled={disableButton} onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={() => onBack()} 
                        title="Standard Growth Table 2:"
                        subTitle="Create new standard growth record"/>} />
            </Footer>

            <BackTop />
        </Spin>
        </>
    )
}

export default StandardGrowthNewTable2