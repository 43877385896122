import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, message, Pagination, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from '../Common/Utility'
import { formLayout_2Columns } from '../Common/Layout'
import moment from 'moment'
import MoonStageSelect from './MoonStageSelect'
import FertilizationTypeSelect from './FertilizationTypeSelect'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { RangePicker } = DatePicker

//----------
// Component
//----------
const SpawningTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [moonStageId, setMoonStageId] = useState(0)
    const [moonStage, setMoonStage] = useState("")
    const [fertilizationTypeId, setFertilizationTypeId] = useState(0)
    const [fertilizationType, setFertilizationType] = useState("")
    const navigate = useNavigate()
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
        
    const [spawningDataSource, setSpawningDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    
    //----------------
    // Search spawning
    //----------------
    const searchSpawning = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}spawning/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : storageId,
                marine_life: marineLifeId,
                species: speciesId,
                moon_stage: moonStageId,
                fertilization_type: fertilizationTypeId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search spawning record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( spawning => {
            let durationFromMoonStage = spawning.duration_from_moon_stage
            // Append '0 ' if no day at the front.
            if(durationFromMoonStage.indexOf(' ') == -1) durationFromMoonStage = `0 ${durationFromMoonStage}`

            data.push({
                key: spawning.pKey,
                id: spawning.id,
                batch: spawning.batch_data.id,
                storage: spawning.storage_data.name,
                moonStageId: spawning.moon_stage_data.pKey,
                moonStage: spawning.moon_stage_data.name,
                fertilizationTypeId: spawning.fertilization_type_data.pKey,
                fertilizationType: spawning.fertilization_type_data.name,
                quantity: numberWithCommas(spawning.quantity_ml),
                spawningDateTime: moment(spawning.spawnedOn).format(DATETIMEFORMAT),
                spawningDateTimeUnformatted: moment(spawning.spawnedOn).format(UNIDATETIMEFORMAT),
                
                batchId: spawning.batch_data.pKey,
                batchType: spawning.batch_data.batch_type_data.name,
                species: spawning.batch_data.species_data.short_name,
                storageId: spawning.storage_data.pKey,
                acquiringMethod: spawning.batch_data.acquiring_method_data.name,
                lifecycle: spawning.lifecycle_data.name,
                aquacultureStage: spawning.aquaculture_stage_data.name,
                aquacultureStageId: spawning.aquaculture_stage_data.pKey,
                
                remark: spawning.remark,
                useEggCollector: spawning.use_egg_collector,
                durationFromMoonStage: durationFromMoonStage,
                createdOn: moment(spawning.createdOn).format(DATETIMEFORMAT),
                updatedOn: moment(spawning.updatedOn).format(DATETIMEFORMAT),

                batchAquacultureStageId: spawning.batch_data.aquaculture_stage_data.pKey,
                batchAquacultureStage: spawning.batch_data.aquaculture_stage_data.name,
                batchLifecycleId: spawning.batch_data.lifecycle_data.pKey,
                batchLifecycle: spawning.batch_data.lifecycle_data.name,
            })
        })
        
        setSpawningDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(moonStageId != 0) searchCriteria = `${searchCriteria}\nMoon Stage: ${moonStage}`
        if(fertilizationTypeId != 0) searchCriteria = `${searchCriteria}\nFertilization Type: ${fertilizationType}`
                        
        axios.get(`${ACISAPIURL}spawning/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                moon_stage: moonStageId,
                fertilization_type: fertilizationTypeId,
                marine_life: marineLifeId,
                species: speciesId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Spawning ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchSpawning(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchSpawning(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/spawningstorage" })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/spawningupdate", 
                    search: `?pKey=${record.key}&id=${record.id}&batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&species=${record.species}&storageId=${record.storageId}&storage=${record.storage}&acquiringMethod=${record.acquiringMethod}&aquacultureStageId=${record.aquacultureStageId}&aquacultureStage=${record.aquacultureStage}&lifecycle=${record.lifecycle}&remark=${encodeURI(String(record.remark).trim())}&spawningDateTime=${record.spawningDateTimeUnformatted}&quantity=${record.quantity.replace(',', '')}&useEggCollector=${record.useEggCollector}&moonStageId=${record.moonStageId}&moonStage=${record.moonStage}&duration=${record.durationFromMoonStage}&fertilizationTypeId=${record.fertilizationTypeId}&fertilizationType=${record.fertilizationType}&createdOn=${record.createdOn}&updatedOn=${record.updatedOn}&batchAquacultureStageId=${record.batchAquacultureStageId}&batchAquacultureStage=${record.batchAquacultureStage}&batchLifecycleId=${record.batchLifecycleId}&batchLifecycle=${record.batchLifecycle}`
                }) 
            }
        }
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    const onSearchMoonStageChange = (e, value) => {
        setMoonStageId(e)
        setMoonStage(value.children)
    }

    const onSearchFertilizationTypeChange = (e, value) => {
        setFertilizationTypeId(e)
        setFertilizationType(value.children)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchSpawning(currentPage, urlParams.get("storageId"))
        }
        else
            searchSpawning(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        //{ title: 'Spawning ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Moon Stage', dataIndex: 'moonStage', key: 'moonstage', sorter: (a, b) => a.moonstage.localeCompare(b.moonstage)},
        { title: 'Fertilization Type', dataIndex: 'fertilizationType', key: 'fertilizationType', sorter: (a, b) => a.fertilizationType.localeCompare(b.fertilizationType) },
        { title: 'Egg Quantity(mL)', dataIndex: 'quantity', key: 'quantity', align: "right", sorter: (a, b) => stringNumberSorter(a.quantity, b.quantity) },
        { title: 'Spawning Date Time', dataIndex: 'spawningDateTime', key: 'spawningDateTime', sorter: (a, b) => a.spawningDateTime.localeCompare(b.spawningDateTime) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Spawning Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} showLockedStorage={false} defaultStorageId={storageId} showActiveBatchSwitch={true}/>

                            <Form.Item name="moonStageId" label="Moon Stage">
                                <MoonStageSelect withBlank={true} onChange={onSearchMoonStageChange}/>
                            </Form.Item>

                            <Form.Item name="fertilizationTypeId" label="Fertilization Type">
                                <FertilizationTypeSelect withBlank={true} onChange={onSearchFertilizationTypeChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={spawningDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default SpawningTable