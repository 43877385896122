import { Table, Row, Col, Space, Button, Form, Collapse, Pagination, message, Tag, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { reportError } from '../Common/Utility'
import { formLayout_2Columns } from '../Common/Layout'
import moment from 'moment'
import SiteSelect from '../Common/SiteSelect'
import UserSelect from '../Common/UserSelect'

const { Panel } = Collapse

//----------
// Component
//----------
const UserTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()

    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [siteId, setSiteId] = useState(0)
    const [site, setSite] = useState("")
    const [userId, setUserId] = useState(0)
    const [user, setUser] = useState("")
    const [userDataSource, setUserDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    //----------------
    // Search spawning
    //----------------
    const searchUser = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}userpaginated/`, {
            params: { 
                default_site: siteId,
                id: userId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search user.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( user => {
            data.push({
                key: user.id,
                username: user.username,
                email: user.email,
                whatsappContact: user.other_info.whatsapp_contact,
                isActive: user.is_active,
                dateJoined: moment(user.date_joined).format(DATEFORMAT),
                groupId: user.group.id,
                group: user.group.name,
                defaultSiteId: user.default_site.site_data.pKey,
                defaultSite: user.default_site.site_data.name,
                isPendingTransactionApprover: user.other_info.is_pending_transaction_approver
            })
        })

        setUserDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                        
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        if(siteId != 0) searchCriteria = `${searchCriteria}\nSite: ${site}`
        if(userId != 0) searchCriteria = `${searchCriteria}\nUser: ${user}`
                                
        axios.get(`${ACISAPIURL}user/download/`, {
            params: { 
                default_site: siteId,
                id: userId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `User ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchUser(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchUser(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/usernew" })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/userupdate", 
                    search: `?id=${record.key}&username=${record.username}&defaultSiteId=${record.defaultSiteId}&defaultSite=${record.defaultSite}&groupId=${record.groupId}&group=${record.group}&isActive=${record.isActive}&isPendingTransactionApprover=${record.isPendingTransactionApprover}`
                }) 
            }
        }
    }

    //----------------------
    // On search change
    //----------------------
    const onSearchSiteChange = (e, value) => {
        if(e != undefined) {
            setSiteId(e)
            setSite(value.children)
        }
        else {
            setSiteId(0)
            setSite("")
        }
    }

    const onSearchUserChange = (e, value) => {
        if(e != undefined) {
            setUserId(e)
            setUser(value.children)
        }
        else {
            setUserId(0)
            setUser("")
        }
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchUser(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Username', dataIndex: 'username', key: 'username', sorter: (a, b) => a.username.localeCompare(b.username) },
        { title: 'email', dataIndex: 'email', key: 'email', sorter: (a, b) => a.email.localeCompare(b.email) },
        { title: 'Group', dataIndex: 'group', key: 'group', sorter: (a, b) => a.group.localeCompare(b.group) },
        { title: 'Is Active', dataIndex: 'isActive', key: 'isActive', sorter: (a, b) => a.isActive - b.isActive,
            render: (isActive) => {
                if(isActive)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        { title: 'Default Site', dataIndex: 'defaultSite', key: 'defaultSite', sorter: (a, b) => a.defaultSite.localeCompare(b.defaultSite) },
        { title: 'Date Joined', dataIndex: 'dateJoined', key: 'dateJoined', sorter: (a, b) => a.dateJoined.localeCompare(b.dateJoined) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search User" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="defaultSite" label="Default Site">
                                <SiteSelect withBlank={true} onChange={onSearchSiteChange}/>
                            </Form.Item>

                            <Form.Item name="username" label="Username">
                                <UserSelect withBlank={true} onChange={onSearchUserChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={userDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default UserTable