import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, message, Tooltip, Pagination, Switch, Spin } from 'antd'
import { DownloadOutlined, InfoCircleOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT,  EGGS,  LOADING,  MEDIAMAXWIDTH,  PAGESIZE,  PAGINATIONSIZE,  TAGGED,  UNIDATEFORMAT, UNIDATETIMEFORMAT, UNTAGGED } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName, SYSPARAM } from "../Common/UserSession"
import { numberWithCommas, reportError, showRemark } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import TransactionTypeSelect from '../Common/TransactionTypeSelect'
import BatchTypeSelect from '../Common/BatchTypeSelect'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { RangePicker } = DatePicker

//----------
// Component
//----------
const StockTransactionHistoryTable = () => {
    const navigate = useNavigate()
    const FileDownload = require('js-file-download')
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [fishId, setFishId] = useState(0)
    const [fish, setFish] = useState("")
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH") / OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [isLoading, setIsLoading] = useState(false)
    const [transactionTypeId, setTransactionTypeId] = useState(0)
    const [transactionType, setTransactionType] = useState("")
    const [itemTypeId, setItemTypeId] = useState(0)
    const [itemType, setItemType] = useState("")
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [batchTypeId, setBatchTypeId] = useState(0)
    const [batchType, setBatchType] = useState("")
            
    const [stockTransactionDataSource, setStockTransactionDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const [isNegativeBalance, setIsNegativeBalance] = useState(false)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //-----------------------
    // Search egg development
    //-----------------------
    const searchStockTransaction = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}stocktransaction/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : storageId,
                transaction_type: transactionTypeId,
                item_type: itemTypeId,
                fish: fishId,
                batch_type: batchTypeId,
                marine_life: marineLifeId,
                species: speciesId,
                negative_balance: isNegativeBalance,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search stock transaction record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( transaction => {
            let stockBalance = 0
            if(transaction.item_type_data.name.startsWith(EGGS))
                stockBalance = transaction.egg_balance_quantity
            else if(transaction.item_type_data.name.startsWith(UNTAGGED))
                stockBalance = transaction.untagged_balance_quantity
            else if(transaction.item_type_data.name.startsWith(TAGGED))
                stockBalance = transaction.tagged_balance_quantity

            data.push({
                key: transaction.pKey,
                dateTime: moment(transaction.transactedOn).format(DATETIMEFORMAT),
                transactionType: transaction.transaction_type_data.name,
                batch: transaction.batch_data.id,
                batchType: transaction.batch_type_data.name,
                storage: transaction.storage_data.name,
                fish: transaction?.fish_data?.id == null ? "" : transaction.fish_data.id,
                itemType: transaction.item_type_data.name,
                qty: numberWithCommas(transaction.quantity),
                stockBalance: numberWithCommas(stockBalance),
                uom: transaction.uom_data.uom,
                qty2: numberWithCommas((transaction.quantity2 / 1000).toFixed(2)),
                averagePerUnitWeight: numberWithCommas(transaction.average_per_unit_weight_gram),
                remark: transaction.remark,
                isBackdated: transaction.is_backdated,
            })
        })

        setStockTransactionDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(batchTypeId != 0) searchCriteria = `${searchCriteria}\nBatch Type: ${batchType}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(transactionTypeId != 0) searchCriteria = `${searchCriteria}\nTransaction Type: ${transactionType}`
        if(itemTypeId != 0) searchCriteria = `${searchCriteria}\nItem Type: ${itemType}`
        if(fishId != 0) searchCriteria = `${searchCriteria}\nTag ID: ${fish}`
        if(isNegativeBalance == true) searchCriteria = `${searchCriteria}\nNegative Balance Only: ${OTHERSYSPARAM("YES")}`
                                
        axios.get(`${ACISAPIURL}stocktransaction/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                transaction_type: transactionTypeId,
                item_type: itemTypeId,
                fish: fishId,
                batch_type: batchTypeId,
                marine_life: marineLifeId,
                species: speciesId,
                negative_balance: isNegativeBalance,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Stock Transaction ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStockTransaction(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStockTransaction(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onSearchFishChange = (e, value) => {
        setFishId(e)
        setFish(value.children)
    }

    const onTransactionTypeChange = (e, value) => {
        setTransactionTypeId(e)
        setTransactionType(value.children)
    }

    const onItemTypeChange = (e, value) => {
        setItemTypeId(e)
        setItemType(value.children)
    }
    
    const onSearchBatchTypeChange = (e, value) => {
        setBatchTypeId(e)
        setBatchType(value.children)
    }
    
    const onSearchMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onIsNegativeBalanceChange = (checked) => {
        setIsNegativeBalance(checked)
    }
    
    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchStockTransaction(currentPage, urlParams.get("storageId"))
        }
        else
            searchStockTransaction(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    let desktopColumn1 = [
        { title: 'Avg/Unit Weight (g)', dataIndex: 'averagePerUnitWeight', key: 'averagePerUnitWeight', align: "right", },//sorter: (a, b) => stringNumberSorter(a.averagePerUnitWeight, b.averagePerUnitWeight) }
    ]

    let desktopColumn2 = [
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', },//sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
    ]

    if(isTabletOrMobile) {
        desktopColumn1 = []
        desktopColumn2 = []
    }

    const columns = [
        { title: 'Record ID', dataIndex: 'key', key: 'key', },
        { title: 'Transacted On', dataIndex: 'dateTime', key: 'dateTime', 
            render: (dateTime, record) => {
                if(!record.isBackdated)
                    return <div>{dateTime}</div>
                else
                    return <div style={{ color: "red"}}>{dateTime}</div>
            }
        },
        { title: 'Trans. Type', dataIndex: 'transactionType', key: 'transactionType', },//sorter: (a, b) => a.transactionType.localeCompare(b.transactionType) },
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', },//sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', },//sorter: (a, b) => a.storage.localeCompare(b.storage) },
        ...desktopColumn2,
        { title: 'Tag ID', dataIndex: 'fish', key: 'fish', },//sorter: (a, b) => a.fish.localeCompare(b.fish) },
        { title: 'Item Type', dataIndex: 'itemType', key: 'itemType', },//sorter: (a, b) => a.itemType.localeCompare(b.itemType)},
        { title: 'Qty.', dataIndex: 'qty', key: 'qty', align: 'right'},//sorter: (a, b) => stringNumberSorter(a.qty, b.qty) },
        { title: 'Balance Qty.', dataIndex: 'stockBalance', key: 'stockBalance', align: 'right'},//sorter: (a, b) => stringNumberSorter(a.qty, b.qty) },
        { title: 'UoM', dataIndex: 'uom', key: 'uom', },//sorter: (a, b) => a.uom.localeCompare(b.uom) },
        ...desktopColumn1,
        { title: 'Total Weight (Kg)', dataIndex: 'qty2', key: 'qty2', align: "right", },//sorter: (a, b) => stringNumberSorter(a.qty2, b.qty2) },
        { title: 'Remark', dataIndex: 'remark', key: 'remark', align: 'center', //sorter: (a, b) => a.length - b.length 
            render: (remark) => {
                if(remark.length > 0)
                    return <Tooltip title={remark} >
                            <div onClick={e => showRemark(e, "Remark", remark)}><InfoCircleOutlined style={{ fontSize: '20px', color: "blue"}}/></div>
                        </Tooltip>
                else
                    return null
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Stock Transaction" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onSearchMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} onTagIdChange={onSearchFishChange} onItemTypeChange={onItemTypeChange} showLockedStorage={true} 
                                activeTaggedOnly={false} defaultStorageId={storageId} loadTagged={false} showActiveBatchSwitch={true}/>

                            <Form.Item name="batchTypeId" label="Batch Type">
                                <BatchTypeSelect withBlank={true} defaultValue={batchType} onChange={onSearchBatchTypeChange}/>
                            </Form.Item>

                            <Form.Item name="transactionTypeId" label="Transaction Type">
                                <TransactionTypeSelect
                                    withBlank={true} 
                                    type={OTHERSYSPARAM("STOCK_IN_OUT")}
                                    excludes={[SYSPARAM("Egg Sampling (In)"), SYSPARAM("Egg Sampling (Out)")]}
                                    customWidth={OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}
                                    onChange={onTransactionTypeChange} />
                            </Form.Item>

                            <Form.Item name="isNegativeBalance" label="Negative Balance Qty. Only">
                                <Switch defaultChecked={isNegativeBalance} onChange={onIsNegativeBalanceChange} />
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={stockTransactionDataSource} pagination={false}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default StockTransactionHistoryTable