import { WALLTILE } from "./SystemParameter"

export const formLayout = {
    labelCol: { 
        xs: {offset: 7, span: 10}, // Phone Potrait mode
        sm: {offset: 7, span: 10},  // Phone Landscape mode
        md: {offset: 7, span: 10},  // Desktop medium screen
        lg: {offset: 8, span: 8},  // Deskop large screen
    },
    wrapperCol: { 
        xs: {offset: 7, span: 10}, 
        sm: {offset: 7, span: 10}, // Phone Landscape mode
        md: {offset: 7, span: 10},
        lg: {offset: 8, span: 8}
    },
}

export const formLayout_2Columns = {
    labelCol: { 
        xs: {offset: 1, span: 22}, 
        sm: {offset: 3, span: 6},  
        md: {offset: 3, span: 6},  
        lg: {offset: 6, span: 3},  
    },
    wrapperCol: { 
        xs: {offset: 1, span: 22},
        sm: {offset: 1, span: 3},
        md: {offset: 1, span: 3},
        lg: {offset: 1, span: 3}
    },
}

export const walltiles = {
    backgroundImage: `url(${WALLTILE})`,
    //backgroundPosition: 'center',
    //backgroundSize: 'cover',
    backgroundRepeat: 'repeat'
}