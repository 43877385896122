import { Row, Col, Table, Space, Typography, Select, message, Button, Tooltip, Spin } from 'antd'
import { DownloadOutlined } from "@ant-design/icons"
import { Pie } from '@ant-design/charts'
import { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, FISHMARINELIFEPKEY, LOADING, MEDIAMAXWIDTH, PAGINATIONSIZE, UNIDATETIMEFORMAT_FILE } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import MarineLifeSelect from '../Common/MarineLifeSelect'
import moment from 'moment'
//import ReactPlayer from 'react-player'


const { Title } = Typography
const { Option } = Select

const InventoryChartBySpeciesTable = () => {
    const FileDownload = require('js-file-download')
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(parseInt(OTHERSYSPARAM("DATE_PICKER_WIDTH")) / 2)
    const [disableButton, setDisableButton] = useState("")
    const [loading, setLoading] = useState(true)

    const [marineLifeId, setMarineLifeId] = useState(FISHMARINELIFEPKEY)
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [speciesOption, setSpeciesOption] = useState([])

    const navigate = useNavigate()
    const [leftPies, setLeftPies] = useState(null)
    const [rightPies, setRightPies] = useState(null)
    const [totalDataSource, setTotalDataSource] = useState([])

    const [video, setVideo] = useState("")
    const [videos, setVideos] = useState([])
    const [videoIndex, setVideoIndex] = useState(0)
    const [hideVideo, setHideVideo] = useState(false)
    const [loopVideo, setLoopVideo] = useState(false)
            
    //-------------------------
    // Get stock count video
    //-------------------------
    const searchStockCountVideo = (currentPage) => {
        setDisableButton("disabled")
                
        axios.get(`${ACISAPIURL}stockcountvideo/`, {
            params: { 
                site: getUserSiteId(),
                inferenced: true,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            pushData(response)
            if(response.data.count == 0) setHideVideo(true)
            if(response.data.count == 1) setLoopVideo(true)
        })
        .catch( error => {
            reportError(error, "Failed to search stock count video record.")
        })
        .finally(() => {
            setDisableButton("")
            refreshUserSession()
        })
    }

    const pushData = (response) => {
        const data = []
        response.data.results.forEach( video => {
            data.push(`${video.inferenced_video}?now=${moment().valueOf()}`)
        })
        
        setVideos(data)
        if(data.length > 0) setVideo(data[0])
    }

    //-------------------------
    // Export growth record
    //-------------------------
    const downloadSiteCageMap = () => {
        setDisableButton("disabled")
        
        axios.get(`${ACISAPIURL}inventory/cagesmap/download/`, {
            params: { 
                site: getUserSiteId()
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let now = moment().format(UNIDATETIMEFORMAT_FILE)
            FileDownload(response.data, `${getUserSiteName()} Cages Map ${now}.xlsx`)
        })
        .catch( error => {
            reportError(error, "Failed to download site cages map excel file.")
        })
        .finally(() => {
            setDisableButton("")
            refreshUserSession()
        })
    }

    //----------------------------
    // Search inventory by species
    //----------------------------
    const searchInventoryBySpecies = async (marineLifeId, speciesId) => {
        await axios.get(`${ACISAPIURL}inventory/species/`, {
            params: { 
                site: getUserSiteId(),
                marine_life: marineLifeId,
                species: speciesId
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            plotPies(response)
        })
        .catch( error => {
            reportError(error, "Failed to load inventory by species record.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //----------------------------
    // No batch storage count
    //----------------------------
    const getNoBatchStorageCount = () => {
        return new Promise((resolve, reject) => {
            axios.get(`${ACISAPIURL}storagewithnobatchcount/`, {
                params: { 
                    site: getUserSiteId(),
                    batch_assigned: false
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                resolve(response.data.no_batch_storage_count)
            })
            .catch( error => {
                reportError(error, "Failed to get no batch storage count.")
                reject(error)
            })
            .finally(() => {
                refreshUserSession()
            })
        })
    }

    //-------------
    // Load species
    //-------------
    const getSpecies = async (marineLifeId) => {
        await axios.get(`${ACISAPIURL}species/`, { 
            params: { 
                marine_life: marineLifeId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( species => <Option key={species.pKey}>{species.short_name}</Option>))
            setSpeciesOption(options)
        })
        .catch( error => {
            reportError(error, "<SpeciesSelect /> Failed to get species.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    const plotPies = async (response) => {
        let qtyData = []
        let weightData = []
        let batchCountData = []
        let storageCountData = []
        let tableRow = []
        let piesLeft = []
        let piesRight = []
        let index = 0
        let grandTotalQty = 0
        let grandTotalWeight = 0
        let grandTotalBatchCount = 0
        let grandTotalStorageCount = 0

        response.data.results.forEach( summary => {
            grandTotalQty += summary.total_quantity
            grandTotalWeight += summary.total_weight_gram / 1000
            grandTotalBatchCount += summary.num_of_batches
            grandTotalStorageCount += summary.num_of_storages
            let speciesTotalQuantity = numberWithCommas(summary.total_quantity)
            let speciesTotalWeightKg = numberWithCommas((summary.total_weight_gram / 1000).toFixed(2))
            
            qtyData.push({
                type: `${summary.species_name}`,
                value: summary.total_quantity,
                species: summary.species,
                speciesName: summary.species_name,
                totalQuantity: speciesTotalQuantity,
                totalWeightKg: speciesTotalWeightKg,
            })

            weightData.push({
                type: `${summary.species_name}`,
                value: summary.total_weight_gram / 1000,
                species: summary.species,
                speciesName: summary.species_name,
                totalQuantity: speciesTotalQuantity,
                totalWeightKg: speciesTotalWeightKg,
            })

            batchCountData.push({
                type: `${summary.species_name}`,
                value: summary.num_of_batches,
                species: summary.species,
                speciesName: summary.species_name,
                totalQuantity: speciesTotalQuantity,
                totalWeightKg: speciesTotalWeightKg,
            })

            storageCountData.push({
                type: `${summary.species_name}`,
                value: summary.num_of_storages,
                species: summary.species,
                speciesName: summary.species_name,
                totalQuantity: speciesTotalQuantity,
                totalWeightKg: speciesTotalWeightKg,
            })

            tableRow.push({
                number: `${++index}.`,
                species: summary.species_name,
                speciesId: summary.species,
                totalQuantity: speciesTotalQuantity,
                totalWeightKg: speciesTotalWeightKg,
                numOfBatches: summary.num_of_batches,
                numOfStorages: summary.num_of_storages,
            })
        })

        // Get storage count with no batch assigned.
        // Add to storageCountData
        const noBatchStorageCount = await getNoBatchStorageCount()
        storageCountData.push({
            type: OTHERSYSPARAM("IS_EMPTY"),
            value: noBatchStorageCount,
            species: 0,
            speciesName: "",
            totalQuantity: 0,
            totalWeightKg: 0,
        })

        grandTotalStorageCount += noBatchStorageCount

        const BindEvent = (plot) => {
            plot.on('plot:click', (evt) => {
                const { x, y } = evt
                // KEEP!
                //const { xField } = plot.options
                const tooltipData = plot.chart.getTooltipItems({ x, y })
                // tooltipData is information of a particular sector.
                if(tooltipData.length > 0) {
                    if(tooltipData[0].data.species != 0)
                        navigate({ 
                            pathname: "/dashboardspeciesweightrange",
                            search: `?speciesId=${tooltipData[0].data.species}&species=${tooltipData[0].data.speciesName}&totalQuantity=${tooltipData[0].data.totalQuantity}&totalWeightKg=${tooltipData[0].data.totalWeightKg}`
                        })
                    else {
                        message.info("Further information not available.")
                        return
                    }
                }
            })
        }

        //-----------------------------------------------
        // Total quantity pie
        //-----------------------------------------------
        const qtyConfig = {
            //autoFit: false,
            //width: 600,
            //height: 600,
            data: qtyData,
            angleField: 'value',
            radius: 0.75,
            label: {
                type: 'spider',
                labelHeight: 28,
                content: (value) => {
                    return `${numberWithCommas(value.value)} Tail/Unit (${(value.value / grandTotalQty * 100).toFixed(2)}%) (${value.type})`
                }
            },
            tooltip: {
                customContent: (title, items) => {
                  return (
                    <>
                        <h5 style={{ marginTop: 16 }}>{title}</h5>
                        <ul style={{ paddingLeft: 0 }}>
                            {items?.map((item, index) => {
                                const { name, value, color } = item
                                return (
                                    <li
                                    key={item.type}
                                    className="g2-tooltip-list-item"
                                    data-index={index}
                                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                                    >
                                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                                    <span style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}>
                                        {/* <span style={{ margiRight: 16 }}>{name}:</span> */}
                                        <span className="g2-tooltip-list-item-value">{value} Tail/Unit</span>
                                    </span>
                                    </li>
                                )
                            })}
                        </ul>
                        {/* { items?.map((item, index) => {
                            const { name, value, color } = item
                            return (<>
                                {name}: {value} Tail/Unit
                            </>)
                        })} */}
                    </>)
                },
            },
                        
            colorField: 'type',
            interactions: [{ type: 'element-selected' }, { type: 'element-active' }, {type: 'pie-legend-active'},],
        }

        piesLeft.push(
            <>
            <Title level={5}>Quantity</Title>
            <Pie {...qtyConfig} onReady={BindEvent}/>
            </>)

        //-----------------------------------------------
        // Total weight pie
        //-----------------------------------------------
        const weightConfig = {
            //autoFit: false,
            //width: 600,
            //height: 600,
            data: weightData,
            angleField: 'value',
            radius: 0.75,
            label: {
                type: 'spider',
                labelHeight: 28,
                content: (value) => {
                    //return `${numberWithCommas(value.value)} Kg (${(value.value / grandTotalWeight * 100).toFixed(2)}%) (${value.type})`
                    return `${numberWithCommas(value.value.toFixed(2))} Kg (${(value.value / grandTotalWeight * 100).toFixed(2)}%) (${value.type})`
                }
            },
            tooltip: {
                customContent: (title, items) => {
                  return (
                    <>
                        <h5 style={{ marginTop: 16 }}>{title}</h5>
                        <ul style={{ paddingLeft: 0 }}>
                            {items?.map((item, index) => {
                                const { name, value, color } = item
                                return (
                                    <li
                                    key={item.type}
                                    className="g2-tooltip-list-item"
                                    data-index={index}
                                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                                    >
                                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                                    <span style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}>
                                        {/* <span style={{ margiRight: 16 }}>{name}:</span> */}
                                        <span className="g2-tooltip-list-item-value">{value} Kg</span>
                                    </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </>)
                },
            },
            
            colorField: 'type',
            interactions: [{ type: 'element-selected' }, { type: 'element-active' }, {type: 'pie-legend-active'},],
            //legend: false
        }

        piesLeft.push(
            <>
            <Title level={5}>Weight</Title>
            <Pie {...weightConfig} onReady={BindEvent}/>
            </>)

        //-----------------------------------------------
        // Total batch pie
        //-----------------------------------------------
        const batchCountConfig = {
            //autoFit: false,
            //width: 600,
            //height: 600,
            data: batchCountData,
            angleField: 'value',
            radius: 0.75,
            label: {
                type: 'spider',
                labelHeight: 28,
                content: (value) => {
                    return `${value.value} (${(value.value / grandTotalBatchCount * 100).toFixed(2)}%) (${value.type})`
                }
            },
            tooltip: {
                customContent: (title, items) => {
                  return (
                    <>
                        <h5 style={{ marginTop: 16 }}>{title}</h5>
                        <ul style={{ paddingLeft: 0 }}>
                            {items?.map((item, index) => {
                                const { name, value, color } = item
                                return (
                                    <li
                                    key={item.type}
                                    className="g2-tooltip-list-item"
                                    data-index={index}
                                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                                    >
                                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                                    <span style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}>
                                        {/* <span style={{ margiRight: 16 }}>{name}:</span> */}
                                        <span className="g2-tooltip-list-item-value">{value} Batch(es)</span>
                                    </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </>)
                },
            },
            
            colorField: 'type',
            interactions: [{ type: 'element-selected' }, { type: 'element-active' }, {type: 'pie-legend-active'},],
            //legend: false
        }

        piesRight.push(
            <>
            <Title level={5}>Number of Batches</Title>
            <Pie {...batchCountConfig} onReady={BindEvent}/>
            </>)

        //-----------------------------------------------
        // Total storage pie
        //-----------------------------------------------
        const storageCountConfig = {
            //autoFit: false,
            //width: 600,
            //height: 600,
            data: storageCountData,
            angleField: 'value',
            radius: 0.75,
            label: {
                type: 'spider',
                labelHeight: 28,
                content: (value) => {
                    return `${value.value} (${(value.value / grandTotalStorageCount * 100).toFixed(2)}%) (${value.type})`
                }
            },
            tooltip: {
                customContent: (title, items) => {
                  return (
                    <>
                        <h5 style={{ marginTop: 16 }}>{title}</h5>
                        <ul style={{ paddingLeft: 0 }}>
                            {items?.map((item, index) => {
                                const { name, value, color } = item
                                return (
                                    <li
                                    key={item.type}
                                    className="g2-tooltip-list-item"
                                    data-index={index}
                                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                                    >
                                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                                    <span style={{ display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}>
                                        {/* <span style={{ margiRight: 16 }}>{name}:</span> */}
                                        <span className="g2-tooltip-list-item-value">{value} Storage(s)</span>
                                    </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </>)
                },
            },
            
            colorField: 'type',
            interactions: [{ type: 'element-selected' }, { type: 'element-active' }, {type: 'pie-legend-active'},],
            //legend: false
        }

        piesRight.push(
            <>
            <Title level={5}>Number of Storages</Title>
            <Pie {...storageCountConfig} onReady={BindEvent}/>
            </>)

        //-----------------------------------------------
        setLeftPies(piesLeft)
        setRightPies(piesRight)

        setTotalDataSource(tableRow)
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/dashboardspeciesweightrange",
                    search: `?speciesId=${record.speciesId}&species=${record.species}&totalQuantity=${record.totalQuantity}&totalWeightKg=${record.totalWeightKg}`
                }) 
            }
        }
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onMarineLifeChange = (e, value) => {
        setLoading(true)
        setMarineLifeId(e)
        
        setSpeciesId(0)
        setSpecies("")
        getSpecies(e)

        searchInventoryBySpecies(e, speciesId)
        setLoading(false)
    }

    const onSpeciesChange = (e, value) => {
        setLoading(true)
        setSpeciesId(e)
        setSpecies(value.children)

        searchInventoryBySpecies(marineLifeId, e)
        setLoading(false)
    }

    //----------------
    // Play next video
    //----------------
    const playNext = () => {
        const nextIndex = videoIndex + 1

        if (nextIndex >= videos.length) {
            setVideo(videos[0])
            setVideoIndex(0)
        } 
        else {
            setVideo(videos[nextIndex])
            setVideoIndex(nextIndex)
        }
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            await getSpecies(marineLifeId)
            await searchInventoryBySpecies(marineLifeId, speciesId)
            setLoading(false)
            
            //searchStockCountVideo(1)
        }

        init()
    }, [])

    const columns = [
        { title: 'No.', dataIndex: 'number', key: 'number', sorter: (a, b) => a.number - b.number },
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'No. of Batch(es)', dataIndex: 'numOfBatches', key: 'numOfBatches', align: "right", sorter: (a, b) => a.numOfBatches - b.numOfBatches },
        { title: 'No. of Storage(s)', dataIndex: 'numOfStorages', key: 'numOfStorages', align: "right", sorter: (a, b) => a.numOfStorages - b.numOfStorages },
        { title: 'Total Quantity (Tail / Unit)', dataIndex: 'totalQuantity', key: 'totalQuantity', align: "right", sorter: (a, b) => stringNumberSorter(a.totalQuantity, b.totalQuantity) },
        { title: 'Total Weight (Kg)', dataIndex: 'totalWeightKg', key: 'totalWeightKg', align: "right", sorter: (a, b) => stringNumberSorter(a.totalWeightKg, b.totalWeightKg ) },
    ]

    return(
        <>
        <Spin spinning={loading} size="large" tip={LOADING}>
        <Row>
            <Col span={12} style={{textAlign: "left"}}>
                <Button type="primary" htmlType="button" onClick={downloadSiteCageMap} icon={<DownloadOutlined />} disabled={disableButton}>
                    <Tooltip title="Download cages map excel file.">
                        Cages Map
                    </Tooltip>        
                </Button>
            </Col>
            <Col span={12} style={{textAlign: "right"}}>
                <MarineLifeSelect withBlank={false} allowClear={false} defaultValue={"Fish"} defaultWidth={parseInt(OTHERSYSPARAM("DATE_PICKER_WIDTH")) / 2} onChange={onMarineLifeChange}/>
                &nbsp;
                <Select style={{width: optionWidth}} onChange={onSpeciesChange} showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} value={species}>
                    {speciesOption}    
                </Select>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        
        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={12} xl={12}>
                {leftPies}
            </Col>
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={12} xl={12}>
                {rightPies}
            </Col>
        </Row>

        <Row justify="center">
            <Col style={{textAlign: "center"}} span={isTabletOrMobile ? 24: 16} >
                <Table bordered columns={columns} dataSource={totalDataSource} pagination={{size: PAGINATIONSIZE}} onRow={onRowClick}/>
            </Col>        
        </Row>

        {/* Disabled stock count video playback.
        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center" hidden={hideVideo}>
            <Col style={{textAlign: "center"}} span={isTabletOrMobile ? 22: 16} >
                <div className='player-wrapper'>
                    <ReactPlayer
                        className='react-player'
                        url={video}
                        width='100%'
                        height='100%'
                        playing='true'
                        controls='true'
                        loop={loopVideo}
                        onEnded={playNext} />
                </div>
            </Col>
        </Row>*/}

        <Row><Col><Space><div /></Space></Col></Row>
        </Spin>
        </>
    )
}

export default InventoryChartBySpeciesTable
