import { useEffect, useState } from 'react'
import { Row, Col, Button, message, Form, Collapse, Space, Table, Pagination, Spin } from 'antd'
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import { getUserAuthToken, getUserSiteId, getUserSiteName, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { formLayout_2Columns } from '../Common/Layout'
import { ACISAPIURL, APPJSON, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import axios from 'axios'
import { numberWithCommas, reportError, stringNumberSorter } from '../Common/Utility'
import moment from 'moment'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'
import WeightRangeInput from '../Common/WeightRangeInput'

const { Panel } = Collapse

//----------
// Component
//----------
const InventorySummaryTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [storageTypeIds, setStorageTypeIds] = useState([])
    const [storageTypes, setStorageTypes] = useState([])
    const [weightRange, setWeightRange] = useState([-1, -1]) // Cannot use [0, 0] because 0 is valid input.

    const [inventorySummaryDataSource, setInventorySummaryDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    //--------------------------
    // Download summary
    //--------------------------
    const downloadSummary = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)

        let searchCriteria = `Site: ${getUserSiteName()}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageTypeIds != 0) searchCriteria = `${searchCriteria}\nStorage Type: ${storageTypes.toString()}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(weightRange[0] >= 0) searchCriteria = `${searchCriteria}\nWeight >= ${numberWithCommas(weightRange[0])}g`
        if(weightRange[1] >=0) searchCriteria = `${searchCriteria}\nWeight <= ${numberWithCommas(weightRange[1])}g`

        axios.get(`${ACISAPIURL}inventory/summary/download/`, {
            params: { 
                site: getUserSiteId(), 
                batch: batchId,
                storage_types: storageTypeIds.toString(),
                storage: storageId,
                marine_life: marineLifeId,
                species: speciesId,
                search_criteria: searchCriteria,
                from_weight: weightRange[0],
                to_weight: weightRange[1],
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("No report found.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Inventory Summary Report ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download report pdf.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------------------
    // Search growth development
    //-----------------------
    const searchInventorySummary = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}inventory/summary/`, {
            params: { 
                site: getUserSiteId(),
                batch: batchId,
                storage_types: storageTypeIds.toString(),
                storage: storageId,
                marine_life: marineLifeId,
                species: speciesId,
                from_weight: weightRange[0],
                to_weight: weightRange[1],
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search inventory summary record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( summary => {
            data.push({
                key: summary.pKey,
                batch: summary.batchid,
                storage: summary.storage_name,
                itemType: summary.item_type_name,
                uom: summary.uom_name,
                quantity: numberWithCommas(summary.quantity),
                averageWeight: numberWithCommas(summary.average_weight),
                totalWeight: numberWithCommas((summary.quantity * summary.average_weight / 1000).toFixed(2))
            })
        })
        
        setInventorySummaryDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchInventorySummary(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchInventorySummary(1)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageTypeChange = (e, value) => {
        setStorageTypeIds(e)
        setStorageTypes( value.map( storageType => { return storageType.children} ))
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onWeightRangeChange = (fromWeight, toWeight) => {
        setWeightRange([fromWeight, toWeight])
    }
    
    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchInventorySummary(currentPage)
    }, [])

    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Item Type', dataIndex: 'itemType', key: 'itemType', sorter: (a, b) => a.itemType.localeCompare(b.itemType) },
        { title: 'UoM', dataIndex: 'uom', key: 'uom', sorter: (a, b) => a.uom.localeCompare(b.uom) },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', align: "right", sorter: (a, b) => stringNumberSorter(a.quantity, b.quantity) },
        { title: 'Avg/Unit Weight (g)', dataIndex: 'averageWeight', key: 'averageWeight', align: "right", sorter: (a, b) => stringNumberSorter(a.averageWeight, b.averageWeight) },
        { title: 'Total Weight (Kg)', dataIndex: 'totalWeight', key: 'totalWeight', align: "right", sorter: (a, b) => stringNumberSorter(a.totalWeight, b.totalWeight) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Inventory Summary" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <CommonSearchFormItem onMarineLifeChange={onSearchMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageTypeChange={onSearchStorageTypeChange} onStorageChange={onSearchStorageChange} showLockedStorage={true} storageTypeSelectMode="multiple" />

                            <Form.Item name="weightRange" label="Avg. Weight Range">
                                <WeightRangeInput onChange={onWeightRangeChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={24} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSummary("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSummary("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={inventorySummaryDataSource} pagination={false}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default InventorySummaryTable