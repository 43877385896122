import { Row, Col, Dropdown, Menu, message, Descriptions, Form, Card, Typography, Space, Spin } from 'antd'
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { Bar } from '@ant-design/charts'
import { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, EGGS, LOADING, TAGGED, UNTAGGED} from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, SYSPARAM } from "../Common/UserSession"
import { numberWithCommas, reportError } from "../Common/Utility"
import { useNavigate } from 'react-router-dom'
import { inComponentSubMenu, inComponentSubMenuPath } from '../Common/InComponentSubMenu'
import { formLayout } from '../Common/Layout'

const { Title } = Typography

const InventoryChartByBatchStorageTable = () => {
    const [barsLeft, setBarsLeft] = useState([])
    const [barsRight, setBarsRight] = useState([])

    const navigate = useNavigate()
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(true)

    let targetStorageId = 0

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const onClick = (e) => {
        const [pathname, destination, searchParams] = inComponentSubMenuPath(e.key)
        const search = `?storageId=${targetStorageId}${searchParams}`

        // Redirect to target page.
        message.info(`You have been redirected to ${destination} page.`)
        navigate({
            pathname: pathname,
            search: search
        })
    }

    //-----------------------
    // Dropdown menu
    //-----------------------
    const menu = (
        <Menu onClick={onClick} triggerSubMenuAction={"click"}>
            {inComponentSubMenu()}
        </Menu>
    )

    //----------------------------
    // Search inventory by species
    //----------------------------
    const searchInventoryByBatchStorage = async () => {
        await axios.get(`${ACISAPIURL}inventory/batchstorage/`, {
            params: { 
                site: getUserSiteId(),
                species: urlParams.get("speciesId"),
                batchIds: urlParams.get("batchIds"),
                storageIds: urlParams.get("storageIds"),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to load inventory by species record.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const onElipsesClicked = (e, storageId) => {
            targetStorageId = storageId
        }

        const leftBars = []
        const rightBars = []
        let counter = 0
                        
        response.data.results.forEach( summary => {
            let data = []
            let batchStorage = ""
            let storageId = 0
            let totalQuantity = 0
            let totalWeightGram = 0
            
            if(summary.egg_quantity > 0) {
                batchStorage = `${summary.egg_batchid} - ${summary.egg_storage_name}`
                storageId = summary.egg_storage
                data.push({
                    label: `${summary.egg_item_type_name}`,
                    type: `${summary.egg_item_type_name} (${summary.egg_uom_name})`,
                    value: summary.egg_quantity,
                })
            }

            if(summary.tagged_quantity > 0) {
                batchStorage = `${summary.tagged_batchid} - ${summary.tagged_storage_name}`
                storageId = summary.tagged_storage
                data.push({
                    label: `${summary.tagged_item_type_name}`,
                    type: `${summary.tagged_item_type_name} (${summary.tagged_uom_name})`,
                    value: summary.tagged_quantity,
                })
                
                totalQuantity += summary.tagged_quantity
                totalWeightGram += summary.tagged_quantity * summary.tagged_average_weight_gram
            }

            if(summary.untagged_quantity > 0) {
                batchStorage = `${summary.untagged_batchid} - ${summary.untagged_storage_name}`
                storageId = summary.untagged_storage
                data.push({
                    label: `${summary.untagged_item_type_name}`,
                    type: `${summary.untagged_item_type_name} (${summary.untagged_uom_name})`,
                    value: summary.untagged_quantity,
                })
                
                totalQuantity += summary.untagged_quantity
                totalWeightGram += summary.untagged_quantity * summary.untagged_average_weight_gram
            }

            const config = {
                autoFit: false,
                //width: 400,
                height: 250,
                data: data.reverse(),
                isGroup: true,
                xField: 'value',
                yField: 'label',
                seriesField: 'type',
                marginRatio: 0,
                label: {
                  position: 'right',
                  offset: 4,
                  content: ({ value, type }) => {
                    let uom = ""
                    if(String(type).startsWith(EGGS)) 
                        uom = summary.egg_uom_name
                    else if(String(type).startsWith(TAGGED))
                        uom = summary.tagged_uom_name
                    else if(String(type).startsWith(UNTAGGED))
                        uom = summary.untagged_uom_name

                    return `${value} (${uom})`
                },
                },
                barStyle: {
                  radius: [2, 2, 0, 0],
                },
                    
                colorField: 'type',
                color: ({ type }) => {
                    if(String(type).startsWith(EGGS)) 
                        return '#657798'
                    else if(String(type).startsWith(TAGGED))
                        return '#62daab'
                    else if(String(type).startsWith(UNTAGGED))
                        return '#6395fa'
                },

                minBarWidth: 20,
                maxBarWidth: 20,
                appendPadding: [0, 100, 75, 0],
                legend: false,
            }

            let avgWeight = parseInt(summary.untagged_average_weight_gram)
            let avgLength = parseInt(summary.untagged_average_length_mm)
            let ageDays = summary.batch_data.age_days != null ? summary.batch_data.age_days : "-"
                        
            if(summary.batch_type_data.pKey == SYSPARAM("Brood")) {
                avgWeight = "-"
                avgLength = "-"
                ageDays = "-"
            }

            let overCrowdingIcon = ""
            let overCrowdingColor = "green"
            if(totalWeightGram / 1000 >= summary.optimal_capacity_kg) overCrowdingColor = "orange"
            if(totalWeightGram / 1000 >= summary.max_capacity_kg) {
                overCrowdingColor = "red"
                overCrowdingIcon = <ExclamationCircleOutlined />
            }
            
            const bar = (<>
                <Descriptions title={<>{batchStorage} <Dropdown overlay={menu} trigger={['click']} onClick={(e) => onElipsesClicked(e, storageId)}><a className="ant-dropdown-link" >...</a></Dropdown></>}>
                    <Descriptions.Item label="Untagged Average Weight (g)">{numberWithCommas(avgWeight)}</Descriptions.Item>
                    <Descriptions.Item label="Untagged Average Length (mm)">{avgLength}</Descriptions.Item>
                    <Descriptions.Item label="Age (days)">{ageDays}</Descriptions.Item>
                    
                    { parseInt(summary.tagged_average_weight_data) != 0 &&
                        <Descriptions.Item label="Tagged Average Weight (g)">{numberWithCommas(parseInt(summary.tagged_average_weight_gram))}</Descriptions.Item>
                    }
                    <Descriptions.Item label="Batch Type">{summary.batch_type_data.name}</Descriptions.Item>
                    <Descriptions.Item label="Total Quantity">{numberWithCommas(totalQuantity)}</Descriptions.Item>
                    <Descriptions.Item label="Total Weight (Kg)" contentStyle={{color: overCrowdingColor}}>{numberWithCommas((totalWeightGram / 1000).toFixed(2))}<div>&nbsp;{overCrowdingIcon}</div></Descriptions.Item>
                    <Descriptions.Item label="Optimal Capacity (Kg)">{summary.optimal_capacity_kg.toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="Max. Capacity (Kg)">{summary.max_capacity_kg.toFixed(2)}</Descriptions.Item>
                    <Descriptions.Item label="Total Capacity (%)" contentStyle={{color: overCrowdingColor}}>{(totalWeightGram /1000 / summary.max_capacity_kg * 100).toFixed(2)}<div>&nbsp;{overCrowdingIcon}</div></Descriptions.Item>
                </Descriptions>
                <Bar {...config} />
                </>)
                
            if(counter % 2 == 0)
                leftBars.push(bar)
            else 
                rightBars.push(bar)

            counter++
        })

        setBarsLeft(leftBars)
        setBarsRight(rightBars)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //---------------
    // On page change
    //---------------
    /*const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchInventoryByBatchStorage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} storage(s)`
    }*/

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            await searchInventoryByBatchStorage()
            setLoading(false)
        }

        init()
    }, [])

    return(
        <>
        <Spin spinning={loading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Species: ${urlParams.get("species")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Weight Range:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("weightRange")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Total Quantity (Tail/Unit):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("totalQuantity")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Total Weight (Kg):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("totalWeightKg")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">No. of Batch(es):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchIds").split(',').length}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">No. of Storage(s):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("storageIds").split(',').length}</Card.Grid>
                </Card>
            </Form.Item>
        </Form>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={12} xl={12}>
                {barsLeft}
            </Col>
            <Col style={{textAlign: "center"}} xs={24} sm={24} md={24} lg={12} xl={12}>
                {barsRight}
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default InventoryChartByBatchStorageTable