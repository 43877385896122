import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, message, Space, Layout, Row, Col, Popconfirm, PageHeader, Modal, Descriptions, InputNumber, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { isFloat, reportError } from '../Common/Utility'
import axios from "axios"
import { ACISAPIURL, LOADING, MENUPATH_SPECIES } from '../Common/SystemParameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import MarineLifeSelect from '../Common/MarineLifeSelect'

const { Header, Footer, Content } = Layout
const { confirm } = Modal

//----------
// Component
//----------
const SpeciesUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
            
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [hideDeleteButton, setHideDeleteButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
                           
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    const [marineLifeId, setMarineLifeId] = useState(urlParams.get("marineLifeId"))
    const [minWeightedLengthCM, setMinWeightedLengthCM] = useState(urlParams.get("minWeightedLengthCM"))

    //---------------
    // Update location
    //---------------
    const updateSpecies = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}species/update/${urlParams.get("pKey")}/`, {
                marine_life: marineLifeId,
                id: values.speciesId,
                scientific_name: values.scientificName,
                general_name: values.generalName,
                short_name: values.shortName,
                min_weighted_length_cm: minWeightedLengthCM,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Species ${urlParams.get("shortName")} updated.`)
                navigate({ pathname: MENUPATH_SPECIES })
            })
            .catch( error => {
                reportError(error, "Failed to update species.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //---------------
    // Delete location
    //---------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}species/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Species ${urlParams.get("shortName")} deleted.`)
            navigate({ pathname: MENUPATH_SPECIES })
        })
        .catch( error => {
            reportError(error, "Failed to delete species.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------------------------------------
    // Determine if storage has transaction and batch assigned
    //--------------------------------------------------------
    const toHideDeleteButton = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}specieshasbatch/${urlParams.get("pKey")}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.has_batch)
                setHideDeleteButton(true)
        })
        .catch( error => {
            reportError(error, "Failed to determine if species have batch.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------
    // Cancel
    //--------------------
    const onCancel = () => {
        navigate({ pathname: MENUPATH_SPECIES })
    }

    //--------------------
    // On update
    //--------------------
    const onUpdate = () => {
        if(isFloat(form.getFieldValue("minWeightedLengthCM"))) {
            message.warn("Minimum weighted length accepts integer only.")
            return
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update species confirmed?</p></Space>,
            onOk() { updateSpecies() },
            onCancel() {},
            centered: true
        })
    }

    //---------------------
    // On location change
    //---------------------
    const onMarineLifeChange = (e, value) => {
        if(e != undefined) 
            setMarineLifeId(e)
        else 
            setMarineLifeId(0)
    }

    const onMinWeightedLengthCMChange = (e) => {
        if(e != undefined)
            setMinWeightedLengthCM(e)
        else
            setMinWeightedLengthCM(0)
    }

    //------------------
    // Custom validation
    //------------------
    const validateMarineLife = (() => {
        if(marineLifeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Marine life is required."))
    })

    const validateMinWeightedLengthCM = (() => {
        if(minWeightedLengthCM > 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Min. weighted length is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        toHideDeleteButton()
        
        form.setFieldsValue({
            speciesId: urlParams.get("id"),
            shortName: urlParams.get("shortName"),
            generalName: urlParams.get("generalName"),
            scientificName: urlParams.get("scientificName"),
            minWeightedLengthCM: urlParams.get("minWeightedLengthCM")
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Species">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update species</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} {...formLayout}>
                    <Form.Item name="marineLife" label="Marine Life"
                        rules={[
                            { required: true, validator: validateMarineLife },
                        ]}>
                        <MarineLifeSelect withBlank={false} defaultValue={urlParams.get("marineLife")} onChange={onMarineLifeChange}/>
                    </Form.Item>

                    <Form.Item name="shortName" label="Short Name"
                        rules={[
                            { required: true, message: "Short name is required."},
                        ]}>
                        <Input maxLength={30} placeholder="Short Name"/>
                    </Form.Item>

                    <Form.Item name="speciesId" label="Species ID"
                        rules={[
                            { required: true, message: "Species ID is required."},
                        ]}>
                        <Input maxLength={3} minLength={3} placeholder="Species ID (3 alphanumeric)"/>
                    </Form.Item>

                    <Form.Item name="generalName" label="General Name"
                        rules={[
                            { required: true, message: "General name is required."},
                        ]}>
                        <Input maxLength={30} placeholder="General Name"/>
                    </Form.Item>

                    <Form.Item name="scientificName" label="Scientific Name"
                        rules={[
                            { required: true, message: "Scientific name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Scientific Name"/>
                    </Form.Item>

                    <Form.Item name="minWeightedLengthCM" label="Min. Weighted Length (cm)"
                         rules={[
                            { required: true, validator: validateMinWeightedLengthCM },
                        ]}>
                        <InputNumber defaultValue={minWeightedLengthCM} min={1} max={100} step={1} onChange={onMinWeightedLengthCMChange} placeholder="Min. weighted length (cm)"/>
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete species confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} hidden={hideDeleteButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} disabled={disableButton} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Species:"
                        subTitle="Update species"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default SpeciesUpdate