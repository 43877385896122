import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, message, Tooltip, Pagination, Spin } from 'antd'
import { DownloadOutlined, InfoCircleOutlined, FileImageOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT,  LOADING,  MEDIAMAXWIDTH,  PAGESIZE,  PAGINATIONSIZE,  UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { localDateTimeSorter, numberWithCommas, previewImage, reportError, showRemark, stringNumberSorter } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { RangePicker } = DatePicker

//----------
// Component
//----------
const GrowthDevelopmentHistoryTable = () => {
    const FileDownload = require('js-file-download')
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [fishId, setFishId] = useState(0)
    const [fish, setFish] = useState("")
    //const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH") / OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH") * 2, "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [isLoading, setIsLoading] = useState(false)
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
            
    const [stockTransactionDataSource, setStockTransactionDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const [observationLabel, setObservationLabel] = useState(isTabletOrMobile ? "Obs" : "Observation")
    const [observedLabel, setObservedLabel] = useState(isTabletOrMobile ? "Obs. On" : "Observed On")
    const [imageLabel, setImageLabel] = useState(isTabletOrMobile ? "Img" : "Image")
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //-----------------------
    // Search growth development
    //-----------------------
    const searchStockTransaction = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}growthdevelopment/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : storageId,
                fish: fishId,
                marine_life: marineLifeId,
                species: speciesId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search growth development record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( growth => {
            data.push({
                key: growth.pKey,
                id: growth.id,
                batch: growth.batch_data.id,
                storage: growth.storage_data.name,
                fish: growth?.fish_data?.id != null ? growth.fish_data.id : "",
                fishId: growth?.fish_data?.pKey != null ? growth.fish_data.pKey : 0,
                ageDays: numberWithCommas(growth.age_days),
                mouthWidth: numberWithCommas(growth.mouth_width),
                weight: numberWithCommas(growth.weight),
                length: numberWithCommas(growth.length),

                mouthOpenedDateTime: moment(growth.mouthOpenedOn).format(DATETIMEFORMAT),
                mouthOpenedDateTimeUnformatted: moment(growth.mouthOpenedOn).format(UNIDATETIMEFORMAT),
                observationDateTime: moment(growth.observedOn).format(DATETIMEFORMAT),
                observationDateTimeUnformatted: moment(growth.observedOn).format(UNIDATETIMEFORMAT),
                dailySampleNumber: growth.daily_sample_number,
                                
                remark: growth.remark,
                img: growth.img,
            })
        })
        
        setStockTransactionDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(fishId != 0) searchCriteria = `${searchCriteria}\nTag ID: ${fish}`
                                
        axios.get(`${ACISAPIURL}growthdevelopment/history/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                fish: fishId,
                marine_life: marineLifeId,
                species: speciesId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Growth Development History ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStockTransaction(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStockTransaction(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onSearchFishChange = (e, value) => {
        setFishId(e)
        setFish(value.children)
    }

    const onSearchMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }
    
    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchStockTransaction(currentPage, urlParams.get("storageId"))
        }
        else
            searchStockTransaction(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    let desktopColumn = [
        { title: 'Daily Sample No.', dataIndex: 'dailySampleNumber', key: 'dailySampleNumber', align: "right", sorter: (a, b) => a.dailySampleNumber - b.dailySampleNumber },
        { title: 'Mouth Opened On', dataIndex: 'mouthOpenedDateTime', key: 'mouthOpenedDateTime', sorter: (a, b) => localDateTimeSorter(a.mouthOpenedDateTime, b.mouthOpenedDateTime) },
    ]

    if(isTabletOrMobile) desktopColumn = []

    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Tag ID', dataIndex: 'fish', key: 'fish', sorter: (a, b) => a.fish.localeCompare(b.fish) },
        { title: `${observedLabel}`, dataIndex: 'observationDateTime', key: 'observationDateTime', sorter: (a, b) => localDateTimeSorter(a.observationDateTime, b.observationDateTime) },
        { title: 'Age (days)', dataIndex: 'ageDays', key: 'ageDay', align: "right", sorter: (a, b) => stringNumberSorter(a.ageDays, b.ageDays) },
        ...desktopColumn,
        { title: 'Mouth Width (mm)', dataIndex: 'mouthWidth', key: 'mouthWidth', align: "right", sorter: (a, b) => stringNumberSorter(a.mouthWidth, b.mouthWidth) },
        { title: 'Weight (g)', dataIndex: 'weight', key: 'weight', align: "right", sorter: (a, b) => stringNumberSorter(a.weight, b.weight) },
        { title: 'Length (mm)', dataIndex: 'length', key: 'length', align: "right", sorter: (a, b) => stringNumberSorter(a.length, b.length) },
        { title: `${observationLabel}`, dataIndex: 'remark', key: 'remark', align: 'center', 
            render: (remark) => {
                if(remark.length > 0)
                    return <Tooltip title={remark} >
                            <div onClick={e => showRemark(e, "Observation", remark)}><InfoCircleOutlined style={{ fontSize: '20px', color: "blue"}}/></div>
                        </Tooltip>
                else
                    return null
            }
        },
        { title: `${imageLabel}`, dataIndex: 'img', key: 'img', align: 'center',
            render: (img, record) => {
                if(img != null) {
                    return <Button type="primary" htmlType="button" style={{margin: "0px"}} icon={<FileImageOutlined />} 
                        onClick={(e) => previewImage(e, "", img)} />
                }
                else
                    return null
            }
        },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Growth Development Samples" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onSearchMarineLifeChange} onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} onTagIdChange={onSearchFishChange} activeTaggedOnly={true} showLockedStorage={true} showActiveBatchSwitch={true} />

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={stockTransactionDataSource} pagination={false}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default GrowthDevelopmentHistoryTable