import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Row, Col, Modal, InputNumber, Popconfirm, PageHeader, Layout, Descriptions, BackTop, Spin, Card, Typography } from 'antd'
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { formLayout } from '../Common/Layout'
import axios from 'axios'
import { ACISAPIURL, LOADING, MENUPATH_STANDARDGROWTHTABLE2 } from '../Common/SystemParameter'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from '../Common/UserSession'
import { reportError } from '../Common/Utility'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal


const StandardGrowthUpdate2 = () => {
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    
    const [speciesId, setSpeciesId] = useState(urlParams.get("speciesId"))
    
    //------------------------------
    // Update standard growth record
    //------------------------------
    const updateStandardGrowthRecord = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}standardgrowth2/update/${urlParams.get("pKey")}/`, {
                day: values.day,
                weight_gram: values.weightGram,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Standard growth record updated.")

                navigate({ 
                    pathname: MENUPATH_STANDARDGROWTHTABLE2,
                })
            })
            .catch( error => {
                reportError(error, `Failed to update standard growth record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //------------------------------
    // Delete standard growth record
    //------------------------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}standardgrowth2/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Standard growth record deleted.`)
            
            navigate({ 
                pathname: MENUPATH_STANDARDGROWTHTABLE2,
            })
        })
        .catch( error => {
            reportError(error, "Failed to delete standard growth record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------
    // On save
    //---------------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update standard growth record confirmed?</p></Space>,
            onOk() { updateStandardGrowthRecord() },
            onCancel() {},
            centered: true
        })
    }

    const onSpeciesChange = (e) => {
        setSpeciesId(e)
    }

    //------------------
    // Custom validation
    //------------------
    const validateSpecies = (() => {
        if(speciesId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Species is required."))
    })

    //---------------------------
    // On cancel
    //---------------------------
    const onCancel = () => {
        navigate({ 
            pathname: MENUPATH_STANDARDGROWTHTABLE2,
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            day: urlParams.get("day"),
            weightGram: urlParams.get("weightGram").replace(',', ''),
        })
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
            <ACISHeader />
        </Header>

        <Content style={{minHeight: contentHeight}}>
            <Row><Col><Space><div /></Space></Col></Row>
            <Row><Col><Space><div /></Space></Col></Row>
            <Row><Col><Space><div /></Space></Col></Row>

            <PageHeader 
                onBack={() => onCancel()} 
                title="Standard Growth Table 2">
                <Descriptions size="small" column={1}>
                    <Descriptions.Item label="Description">Update standard growth record</Descriptions.Item>
                </Descriptions>
            </PageHeader>

            <Row><Col><Space><div /></Space></Col></Row>

            <Form {...formLayout}>
                <Form.Item>
                    <Card title={<Title level={5}>{`Species: ${urlParams.get("species")}`}</Title>}>
                        Update standard growth day and weight. Update of standard growth record's species is not allowed.
                    </Card>
                </Form.Item>
            </Form>

            <Row><Col><Space><div /></Space></Col></Row>

            <Form form={form} onFinish={onSave} {...formLayout}>
                <Form.Item name="day" label="Day"
                    rules={[
                        { required: true, message: "Day is required."},
                    ]}>
                    <InputNumber min={0} max={10000000} step={1} placeholder="Day"/>
                </Form.Item>

                <Form.Item name="weightGram" label="Weight (g)"
                    rules={[
                        { required: true, message: "Weight is required."},
                    ]}>
                    <InputNumber min={0} max={1000000} precision={2} step={1} placeholder="Weight (g)"/>
                </Form.Item>

                <Row justify="center">
                    <Col span={6}></Col>
                    <Col span={12} style={{textAlign: "center"}}>
                        <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Update</Button>
                        <Popconfirm title="Delete standard growth record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                            <Button danger type="primary" htmlType="button" disabled={disableButton} icon={<DeleteOutlined />}>Delete</Button>
                        </Popconfirm>
                        <Button type="primary" htmlType="button" disabled={disableButton} onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                    </Col>
                    <Col span={6}></Col>
                </Row>
            </Form>

            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={() => onCancel()} 
                        title="Standard Growth Table 2:"
                        subTitle="Update standard growth record"/>} />
            </Footer>

            <BackTop />
        </Spin>
        </>
    )
}

export default StandardGrowthUpdate2