import { getUserAuthToken, refreshUserSession } from "./UserSession"
import { message, Space, Modal, Image } from 'antd'
import { InfoCircleOutlined } from "@ant-design/icons"
import axios from "axios"
import { ACISAPIURL, DATETIMEFORMAT, FALLBACK, TIMEOUT } from "./SystemParameter"
import moment from 'moment'

const { confirm } = Modal


export const getPendingStockTransactionCount = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${ACISAPIURL}stocktransaction/pending/site/`, {
            params: {},
            timeout: TIMEOUT,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            resolve(response.data.results)
        })
        .catch( error => {
            reportError(error, "Failed to get pending stock transaction site.")
            reject(error)
        })
        .finally(() => {
            refreshUserSession()
        })
    })
}

export const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
}

export const previewImage = async (e, title, uri) => {
    e.stopPropagation()

    let width = 350
    let height = 450

    // Determine width and height of image.
    const img = document.createElement('img')
    img.src = `${uri}?now=${moment().valueOf()}`

    img.onload = function() {
        if(img.naturalWidth > img.naturalHeight) 
            [width, height] = [height, width]
        else if(img.naturalWidth == img.naturalHeight)
            width = height

        confirm({
            icon: null,
            width: width,
            height: height,
            okButtonProps: {style: {display: "none"}},
            cancelButtonProps: {style: {display: "none"}},
            centered: true,
            closable: true,
            content: <>
                <Space><p>{title}</p></Space>
                <Image 
                    width={width - 60} 
                    height = {height - 60}
                    src={`${uri}?now=${moment().valueOf()}`}
                    fallback={FALLBACK}
                    preview={true} />
            </>
        })
    }
}

export const storageCapacityCheck = (marineLifeId, destinationBatchId, destinationStorageId, weightToAddKg) => {
    return new Promise((resolve, reject) => {
        axios.get(`${ACISAPIURL}storagecapacitycheck/`, {
            params: {
                marine_life: marineLifeId,
                destination_batch: destinationBatchId,
                destination_storage: destinationStorageId,
                weight_to_add_kg: weightToAddKg
            },
            timeout: TIMEOUT,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            resolve(response.data.prompt)
        })
        .catch( error => {
            reportError(error, "Failed to get marine life storage type capacity information.")
            reject(error)
        })
        .finally(() => {
            refreshUserSession()
        })
    })
}

export const buildMismatchedCriteriaString = (batchTypeId1, batchTypeId2, acquiringMethodId1, acquiringMethodId2, lifecycleId1, lifecycleId2) => {
    let mismatchedCriteria = ""
    
    // Compare batch type
    if(parseInt(batchTypeId1) != parseInt(batchTypeId2)) 
        mismatchedCriteria = "Batch Type"
    
    // Compare acquiring method
    if(parseInt(acquiringMethodId1) != parseInt(acquiringMethodId2)) {
        if(mismatchedCriteria != "") mismatchedCriteria = `${mismatchedCriteria}, `
        mismatchedCriteria = `${mismatchedCriteria}Acquiring Method`
    }
    // Compare lifecycle
    if(parseInt(lifecycleId1) != parseInt(lifecycleId2)) {
        if(mismatchedCriteria != "") mismatchedCriteria = `${mismatchedCriteria}, `
        mismatchedCriteria = `${mismatchedCriteria}Lifecycle`
    }

    return mismatchedCriteria
}

export const loadMarineLifeItemTypeUnitOfMeasurementStandardTable = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${ACISAPIURL}marinelifeitemtypeunitofmeasurementstandardtable/`, {
            params: {},
            timeout: TIMEOUT,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            resolve(response.data.results)
        })
        .catch( error => {
            reportError(error, "Failed to load marine life item type unit of measurement standard table")
            reject(error)
        })
        .finally(() => {
            refreshUserSession()
        })
    })
}

export const getBatchFullAquacultureStageLifecycle = (batch) => {
    return new Promise((resolve, reject) => {
        axios.get(`${ACISAPIURL}aquaculturestagelifecycle/`, {
            params: {
                batch: batch
            },
            timeout: TIMEOUT,
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            resolve(response.data.results)
        })
        .catch( error => {
            reportError(error, "Failed to load batch full aquaculture stage and lifecycle.")
            reject(error)
        })
        .finally(() => {
            refreshUserSession()
        })
    })
}

//------------
// Show remark
//------------
export const showRemark = (e, title, content) => {
    e.stopPropagation()
    
    confirm({
        icon: <InfoCircleOutlined />,
        title: <Space><p>{title}</p></Space>,
        content: content,
        onOk() {},
        okText: "Close",
        cancelButtonProps: {style: {display: "none"}},
        centered: true
    })
}

export const reportError = (error, customMessage="") => {
    // Error message duration in seconds
    const MAXLEN = 200
    const second = 5

    // non_field_errors
    const obj = error?.response?.data?.non_field_errors
    if(obj !== undefined) {
        //alert(0)
        message.error(obj.join(" "))
    }
    else {
        // Other error
        const otherErrorMsg = error?.response?.data
        //console.log(otherErrorMsg)

        if(otherErrorMsg !== undefined) {
            if(otherErrorMsg?.detail != undefined)  {
                //alert(1)
                message.error(otherErrorMsg.detail, second)
            }
            else if(typeof(otherErrorMsg) === "object") {
                //alert(2)
                let msg = ""
                for(var key in otherErrorMsg) msg = `${msg} ${otherErrorMsg[key]}`
                message.error(msg, second)
            }
            else {
                //alert(otherErrorMsg)
                if(otherErrorMsg.length < MAXLEN)
                    message.error(otherErrorMsg, second)
                //else
                    //message.error("Error message is too long to be displayed.")
            }
        }
    }

    //message.error(error.message)
    if(customMessage !== "") message.error(customMessage)
}

//----------------------------------------------------------
// Standardization of item type - unit of measure ment label
//----------------------------------------------------------
export const standardItemTypeUnitOfMeasurementLabel = (itemTypeUom) => {
    return `${itemTypeUom.item_type_data.name} (${itemTypeUom.uom_data.uom})`
}

export const calculateWeightVariancePercentage = (weight1, weight2) => {
    let denominator1 = weight1 > 0 ? weight1 : 1
    let denominator2 = weight2 > 0 ? weight2 : 1
    return weight1 > weight2 ? (weight1 - weight2) / denominator2 : (weight2 - weight1) / denominator1
}

export const numberWithCommas = (number) => {
    if(number != null)
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    else
        return "0"
}

export const stringNumberSorter = (a, b) => {
    return parseFloat(a.replace(',', '')) - parseFloat(b.replace(',', ''))
}

export const localDateTimeSorter = (a, b) => {
    let aMoment = moment(a, DATETIMEFORMAT)
    let bMoment = moment(b, DATETIMEFORMAT)
    return aMoment.unix() - bMoment.unix()
}

export const compressImage = (src, newX, newY) => {
    return new Promise((res, rej) => {
        const img = new Image()
        img.src = src
        img.onload = () => {
            const elem = document.createElement('canvas')
            elem.width = newX
            elem.height = newY
            const ctx = elem.getContext('2d')
            ctx.drawImage(img, 0, 0, newX, newY)
            const data = ctx.canvas.toDataURL()
            res(data)
        }
        img.onerror = error => rej(error)
    })
}

export const getCookie = (name) => {
	let value = `; ${document.cookie}`;
	let parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(';').shift();
}