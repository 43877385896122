import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Input, PageHeader, Descriptions, Spin } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, LOADING, MENUPATH_SITE } from '../Common/SystemParameter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import LocationSelect from '../Common/LocationSelect'

const { Header, Footer, Content } = Layout
const { confirm } = Modal

//----------
// Component
//----------
const SiteNew = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [locationId, setLocationId] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
        
    //-------------------
    // Create new site
    //-------------------
    const createSite = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}site/create/`, {
                location: locationId,
                name: values.siteName,
                id: values.siteId
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`New site ${values.siteName} created.`)
                navigate({ pathname: MENUPATH_SITE })
            })
            .catch( error => {
                reportError(error, "Failed to create new site.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(true)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //--------
    // On next
    //--------
    const onSave = async (e) => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create site confirmed?</p></Space>,
            onOk() { createSite() },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_SITE
        })
    }

    //---------------------
    // On location change
    //---------------------
    const onLocationChange = (e, value) => {
        if(e != undefined) 
            setLocationId(e)
        else 
            setLocationId(0)
    }

    //------------------
    // Custom validation
    //------------------
    const validateLocation = (() => {
        if(locationId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Location is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Site">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create site</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item name="location" label="Location"
                        rules={[
                            { required: true, validator: validateLocation },
                        ]}>
                        <LocationSelect withBlank={false} onChange={onLocationChange}/>
                    </Form.Item>

                    <Form.Item name="siteName" label="New Site Name"
                        rules={[
                            { required: true, message: "Site name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Site Name"/>
                    </Form.Item>

                    <Form.Item name="siteId" label="New Site ID"
                        rules={[
                            { required: true, message: "Site ID is required."},
                        ]}>
                        <Input maxLength={3} minLength={3} placeholder="Site ID (3 alphanumeric)"/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Site:"
                    subTitle="Create site"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default SiteNew