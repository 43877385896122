import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, message, Space, Layout, Row, Col, Popconfirm, PageHeader, Modal, Descriptions, Select, Switch, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined, LockOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { ACISAPIURL, LOADING, MEDIAMAXWIDTH, MENUPATH_USER } from '../Common/SystemParameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import { useMediaQuery } from 'react-responsive'
import SiteSelect from '../Common/SiteSelect'
import UserGroupSelect from '../Common/UserGroupSelect'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { Option } = Select

//----------
// Component
//----------
const UserUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
            
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [hideDeleteButton, setHideDeleteButton] = useState(false)
    const [allowedSiteIds, setAllowedSiteIds] = useState([])
    const [allowedSitesOption, setAllowedSitesOption] = useState([])
                                   
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [defaultSiteId, setDefaultSiteId] = useState(urlParams.get("defaultSiteId"))
    const [userGroupId, setUserGroupId] = useState(urlParams.get("groupId"))

    const [allowedSitesBuffer, setAllowedSitesBuffer] = useState({})
    const [isActive, setIsActive] = useState(urlParams.get("isActive") == "true" ? true: false)
    const [isPendingTransactionApprover, setIsPendingTransactionApprover] = useState(urlParams.get("isPendingTransactionApprover") == "true" ? true: false)
    const [isLoading, setIsLoading] = useState(false)
    
    //---------------
    // Update user
    //---------------
    const updateUser = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}user/update/${urlParams.get("id")}/`, {
                username: values.username,
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                whatsapp_contact: values.whatsappContact,
                password: values.password,
                default_site: defaultSiteId,
                allowed_sites: allowedSiteIds,
                user_group: userGroupId,
                is_active: isActive,
                is_pending_transaction_approver: isPendingTransactionApprover
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`User ${urlParams.get("username")} updated.`)
                navigate({ pathname: MENUPATH_USER })
            })
            .catch( error => {
                reportError(error, "Failed to update user.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //---------------
    // Delete user
    //---------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}user/delete/${urlParams.get("id")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`User ${urlParams.get("username")} deleted.`)
            navigate({ pathname: MENUPATH_USER })
        })
        .catch( error => {
            reportError(error, "Failed to delete user.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------
    // Load sites
    //-----------
    const getAllowableSitesOption = (defaultSiteId) => {
        setIsLoading(true)

        return new Promise((resolve, reject) => {
            axios.get(`${ACISAPIURL}site/`, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}})
            .then( response => {
                const data = response.data.results.filter(site => site.pKey != defaultSiteId)
                let options = data.map( site => <Option key={site.pKey}>{site.name}</Option>)
                setAllowedSitesOption(options)

                let dict = {}
                data.forEach( site => {
                    dict[site.name] = site.pKey
                })
                setAllowedSitesBuffer(dict)

                resolve()
            })
            .catch( error => {
                reportError(error, "Failed to get site(s).")
                reject()
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //-----------
    // Get user
    //-----------
    const getUserInfo = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}userpaginated/`, {
            params: {
                id: urlParams.get("id")
            }, 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            setIsPendingTransactionApprover(response.data.results[0].other_info.is_pending_transaction_approver)

            form.setFieldsValue({
                username: response.data.results[0].username,
                firstName: response.data.results[0].name.first_name,
                lastName: response.data.results[0].name.last_name,
                email: response.data.results[0].email,
                whatsappContact: response.data.results[0].other_info.whatsapp_contact,
                isPendingTransactionApprover: response.data.results[0].other_info.is_pending_transaction_approver,
            })    

            const allowed_sites = response.data.results[0].allowed_sites.filter(site => site.default == false)
            if(allowed_sites.length > 0) {
                setAllowedSiteIds(allowed_sites.map(site => site.site_data.pKey))
                form.setFieldsValue({
                     allowedSites: allowed_sites.map(site => site.site_data.name)
                })
            }
        })
        .catch( error => {
            reportError(error, "Failed to get site(s).")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------------------------------------
    // Determine if storage has transaction and batch assigned
    //--------------------------------------------------------
    const toHideDeleteButton = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}userhaslog/${urlParams.get("id")}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.has_log)
                setHideDeleteButton(true)
        })
        .catch( error => {
            reportError(error, "Failed to determine if user has log.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------
    // Cancel
    //--------------------
    const onCancel = () => {
        navigate({ pathname: MENUPATH_USER })
    }

    //--------------------
    // On update
    //--------------------
    const onUpdate = () => {
        form.validateFields()
        .then( values => {
            if(String(values.password).trim() != "" && String(values.password).trim().length < 8) {
                message.warning("Invalid reset password.")
                return
            }

            if(String(values.confirmPassword).trim() != "" && String(values.confirmPassword).trim().length < 8) {
                message.warning("Invalid confirm password.")
                return
            }

            if(String(values.password).trim() != "" && values.password != values.confirmPassword) {
                message.warning("Password do not match.")
                return
            }
            else {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Update user confirmed?</p></Space>,
                    onOk() { updateUser() },
                    onCancel() {},
                    centered: true
                })
            }
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //------------------
    // Custom validation
    //------------------
    const validateDefaultSite = (() => {
        if(defaultSiteId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Default site is required."))
    })

    const validateUserGroup = (() => {
        if(userGroupId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("User group is required."))
    })

    //---------------------
    // On change
    //---------------------
    const onDefaultSiteChange = (e, value) => {
        setAllowedSitesOption([])
        setAllowedSiteIds([])

        form.setFieldsValue({
            allowedSites: []
        })
        
        if(e != undefined) {
            setDefaultSiteId(e)
            getAllowableSitesOption(e)
        }
        else 
            setDefaultSiteId(0)
    }

    const onAllowedSitesChange = (e) => {
        let filteredIds = []
        e.forEach( i => {
            if(allowedSitesBuffer[i] === undefined)
                filteredIds.push(parseInt(i))
            else 
                filteredIds.push(allowedSitesBuffer[i])
        })

        filteredIds = Array.from(new Set(filteredIds))
        setAllowedSiteIds(filteredIds)
    }

    const onUserGroupChange = (e, value) => {
        if(e != undefined) 
            setUserGroupId(e)
        else 
            setUserGroupId(0)
    }

    const onIsActiveChange = (checked) => {
        setIsActive(checked)
    }

    const onIsPendingTransactionApproverChange = (checked) => {
        setIsPendingTransactionApprover(checked)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            await getAllowableSitesOption(urlParams.get("defaultSiteId"))
            getUserInfo()
            toHideDeleteButton()
        }

        init()
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="User">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update user</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} {...formLayout}>
                    <Form.Item name="username" label="Username"
                        rules={[
                            { required: true, message: "Username is required."},
                        ]}>
                        <Input maxLength={150} placeholder="Username"/>
                    </Form.Item>

                    <Form.Item name="firstName" label="First Name"
                        rules={[
                            { required: true, message: "First name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="First Name"/>
                    </Form.Item>

                    <Form.Item name="lastName" label="Last Name"
                        rules={[
                            { required: true, message: "Last name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Last Name"/>
                    </Form.Item>

                    <Form.Item name="email" label="Email"
                        rules={[
                            { required: true, type: "email", message: "Email is required."},
                        ]}>
                        <Input maxLength={254} placeholder="Email"/>
                    </Form.Item>

                    <Form.Item name="whatsappContact" label="Whatsapp Contact">
                        <Input maxLength={100} placeholder="Whatsapp Contact"/>
                    </Form.Item>

                    <Form.Item name="password" label="Reset Password">
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="New Password"/>
                    </Form.Item>

                    <Form.Item name="confirmPassword" label="Confirm Reset Password">
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="Confirm New Password"/>
                    </Form.Item>

                    <Form.Item name="defaultSite" label="Default Site"
                        rules={[
                            { required: true, validator: validateDefaultSite },
                        ]}>
                        <SiteSelect withBlank={false} onChange={onDefaultSiteChange} defaultValue={urlParams.get("defaultSite")}/>
                    </Form.Item>

                    <Form.Item name="allowedSites" label="Other Allowed Site(s)">
                        <Select mode="multiple"style={{width: optionWidth}} onChange={onAllowedSitesChange} allowClear showSearch optionFilterProp="children" 
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} >
                            {allowedSitesOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="userGroup" label="User Group"
                        rules={[
                            { required: true, validator: validateUserGroup },
                        ]}>
                        <UserGroupSelect withBlank={false} onChange={onUserGroupChange} defaultValue={urlParams.get("group")}/>
                    </Form.Item>

                    <Form.Item name="isPendingTransactionApprover" label="Is Pending Transaction Approver">
                        <Switch defaultChecked={isPendingTransactionApprover} onChange={onIsPendingTransactionApproverChange} />
                    </Form.Item>

                    <Form.Item name="isActive" label="Is Active">
                        <Switch defaultChecked={isActive} onChange={onIsActiveChange} />
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete user confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} hidden={hideDeleteButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} disabled={disableButton} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="User:"
                        subTitle="Update user"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default UserUpdate