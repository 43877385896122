import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, Select, DatePicker, PageHeader, Card, Modal, Input, Descriptions, Image, Upload, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, MENUPATH_EGGDEV, UNIDATETIMEFORMAT, FALLBACK, IMAGEWIDTH, IMAGEHEIGHT, ACISIMGURL, EGGDEVELOPMENTIMGFOLDER, MEDIAMAXWIDTH, LOADING } from "../Common/SystemParameter"
import { reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, SYSPARAM, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import GonadMaturationSelect from '../Spawning/GonadMaturationSelect'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { Option } = Select
const { confirm } = Modal
const { TextArea } = Input

//----------
// Component
//----------
const EggDevelopmentUpdate = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const [form] = Form.useForm()
    const [fishIdOption, setFishIdOption] = useState([])
    const [eggImageFile, setEggImageFile] = useState([])
    const [uploading, setUploading] = useState(false)
                
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [eggImageFileURI, setEggImageFileURI] = useState(`${urlParams.get("img")}?now=${moment().valueOf()}`)
    const [hideRemoveImageButton, setHideRemoveImageButton] = useState(urlParams.get("img") == "null" ?  true : false)
    
    const [eggDevelopmentStageId, setEggDevelopmentStageId] = useState(urlParams.get("eggDevelopmentStageId"))
    const [eggDevelopmentStage, setEggDevelopmentStage] = useState(urlParams.get("eggDevelopmentStage"))
    const [hideFishSelect, setHideFishSelect] = useState(urlParams.get("eggDevelopmentStageId") == SYSPARAM("Post Spawning"))
    const [fishId, setFishId] = useState(parseInt(urlParams.get("fishId")))
    const [observationDateTime, setObservationDateTime] = useState(moment(moment(urlParams.get("observationDateTime")), UNIDATETIMEFORMAT))
    const [gonadMaturationId, setGonadMaturationId] = useState(urlParams.get("gonadMaturationId"))

    const [hideButton, setHideButton] = useState("")

    //-----------------------
    // Remove image
    //-----------------------
    const removeEggDevelopmentImage = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.patch(`${ACISAPIURL}eggdevelopment/imgremove/${urlParams.get("pKey")}/`, {}, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Egg development image removed.")
            setHideRemoveImageButton(true)
            setEggImageFile([])
        })
        .catch( error => {
            reportError(error, `Failed to remove egg development image.`)
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    //-----------------------
    // Update egg development
    //-----------------------
    const updateEggDevelopment = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}eggdevelopment/update/${urlParams.get("pKey")}/`, {
                fish: fishId,
                spawning_status: eggDevelopmentStageId,
                egg_diameter: parseFloat(values.eggSize).toFixed(2),
                oil_globule_diameter: parseFloat(values.oilGlobuleSize).toFixed(2),
                gonad_maturation: gonadMaturationId,
                remark: values.remark,
                observedOn: observationDateTime
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Egg development record updated.")
                navigate({ 
                    pathname: MENUPATH_EGGDEV
                })
            })
            .catch( error => {
                reportError(error, `Failed to update egg development record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //----------------------------
    // Delete egg development data
    //----------------------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}eggdevelopment/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Egg development record deleted.`)
            navigate({ pathname: MENUPATH_EGGDEV })
        })
        .catch( error => {
            reportError(error, "Failed to delete egg development record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //------------
    // Search fish
    //------------
    const getFishInStorage = () => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}fishfull/`, {
            params: { 
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                gender: SYSPARAM("Female"),
                isBrood: true,
                active: true,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options = options.concat(response.data.results.map( fish => <Option key={fish.pKey}>{fish.id}</Option> ))
            setFishIdOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get fish id.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = () => {
        form.validateFields()
        .then( values => {
            if(urlParams.get("eggDevelopmentStageId") == SYSPARAM("Post Spawning") && values.quantity > parseInt(urlParams.get("availableEggQuantity"))) {
                message.warn("Sample quantity exceeded available quantity.")
                return
            }

            if(urlParams.get("eggDevelopmentStageId") == SYSPARAM("Post Spawning") && values.quantity == parseInt(urlParams.get("availableEggQuantity")))
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Sample quantity is all of available quantity. Create record confirmed?</p></Space>,
                    onOk() { updateEggDevelopment() },
                    onCancel() {},
                    centered: true
                })
            else {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Update egg development record confirmed?</p></Space>,
                    onOk() { updateEggDevelopment() },
                    onCancel() {},
                    centered: true
                })
            }
        })
    }

    //----------------------------------------------------------
    // Retrieve storage protection triggered record count.
    // If count > 0, disable update and delete.
    //----------------------------------------------------------
    /*const getProtectionTriggeredRecordCount = () => {
        axios.get(`${ACISAPIURL}eggdevelopment/protectiontriggeredrecordcount/`, {
            params: { 
                pKey: urlParams.get("pKey")
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(parseInt(response.data.record_found) > 0) {
                setHideButton("none")
                message.info("Protection triggered record found. Update and delete not allowed.")
            }
        })
        .catch( error => {
            reportError(error, "Failed to retrieve protection triggered record count.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }*/

    //--------
    // On back
    //--------
    const onCancel = () => {
        navigate({ 
            pathname: MENUPATH_EGGDEV
        })
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setObservationDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateFishId = (() => {
        if((eggDevelopmentStageId == SYSPARAM("Pre Spawning") && fishId != 0) || eggDevelopmentStageId != SYSPARAM("Pre Spawning")) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Tag ID is required."))
    })

    const validateObservationDateTime = (() => {
        if(observationDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Observation date time is required."))
    })

    const validateGonadMaturation = (() => {
        if(gonadMaturationId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Gonad Maturation is required."))
    })

    //------------------------
    // Upload image properties
    //------------------------
    const props = {
        name: "img",
        action: `${ACISAPIURL}eggdevelopment/imgupload/${urlParams.get("pKey")}/`,
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        fileList: eggImageFile,
        onRemove: file => {
            setEggImageFile([])
            removeEggDevelopmentImage()
            setHideRemoveImageButton(true)
        },
        beforeUpload: file => {
            setEggImageFile([file])
        },
        onChange(info) {
            setUploading(true)

            if(info.file.status !== 'uploading') {
                setUploading(false)
                setIsLoading(true)
            }

            if(info.file.status === 'done') {
                setUploading(false)
                setIsLoading(false)
                
                const fileArray = info.file.name.split('.')
                const fileExtention = fileArray[fileArray.length - 1]
                setEggImageFileURI(`${ACISIMGURL}${EGGDEVELOPMENTIMGFOLDER}/${urlParams.get("pKey")}.${fileExtention}?now=${moment().valueOf()}`)
                setHideRemoveImageButton(false)
                message.info("Image uploaded.")
            }
            else if(info.file.status === 'error') {
                setUploading(false)
                message.error(`"${info.file.name}" upload failed.`)
                message.error(info.file.response?.detail)
            }
        },
        maxCount: 1,
        //eggImageFile,
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getFishInStorage()

        form.setFieldsValue({
            eggSize: urlParams.get("eggSize"),
            oilGlobuleSize: urlParams.get("oilGlobuleSize"),
            observationDateTime: observationDateTime,
            remark: urlParams.get("remark")
        })

        // A post spawning record
        //if(urlParams.get("fishId") == 0 ) getProtectionTriggeredRecordCount()
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onCancel()} 
                    title="Egg Development">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update egg development record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Egg Development ID: ${urlParams.get("id")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("storage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Egg Development Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{eggDevelopmentStage}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
                     
                            { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Form.Item>

                    <Row>
                        <Col span={6} />
                            <Col span={12} style={{textAlign: "center"}}>
                                <Image 
                                    width={IMAGEWIDTH} 
                                    height = {IMAGEHEIGHT}
                                    src={eggImageFileURI}
                                    fallback={FALLBACK}
                                    preview={true} 
                                    hidden={hideRemoveImageButton}/>
                            </Col>
                        <Col span={6} />
                    </Row>

                    <Form.Item name="imageFile" label="Image File">
                        <Input.Group>
                            <Button onClick={removeEggDevelopmentImage} icon={<DeleteOutlined />} hidden={hideRemoveImageButton}/>                                                        
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />} loading={uploading}>Select File</Button>
                            </Upload>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item name="fishId" label={"Marine Life Tag ID"} hidden={hideFishSelect}
                        rules={[
                            { required: true, validator: validateFishId },
                        ]}>
                        <Select style={{width: optionWidth}} onChange={setFishId} defaultValue={urlParams.get("fishId") != 0 ? urlParams.get("fish") : ""}>
                            {fishIdOption}
                        </Select>
                    </Form.Item>

                    <Form.Item name="observationDateTime" label="Observation Date Time"
                        rules={[
                            { required: true, validator: validateObservationDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="eggSize" label="Egg Size(mm)"
                        rules={[
                            { required: true, message: "Egg size is required."},
                        ]}>
                        <InputNumber min={0.00} max={10000} step={0.10} placeholder="Egg Size" />
                    </Form.Item>

                    <Form.Item name="oilGlobuleSize" label="Oil Globule Size(mm)"
                        rules={[
                            { required: true, message: "Oil globule size is required."},
                        ]}>
                        <InputNumber min={0.00} max={10000} step={0.10} placeholder="Oil Globule"/>
                    </Form.Item>

                    <Form.Item name="gonadMaturation" label="Gonad Maturation"
                        rules={[
                            { required: true, validator: validateGonadMaturation },
                        ]}>
                        <GonadMaturationSelect onChange={setGonadMaturationId} defaultValue={urlParams.get("gonadMaturation")}/>
                    </Form.Item>

                    <Form.Item name="remark" label="Observation" >
                        <TextArea rows={3}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} style={{display: hideButton}} icon={<SaveOutlined/>}>Update</Button>
                            <Popconfirm title="Delete egg development record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} style={{display: hideButton}} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" disabled={disableButton} onClick={onCancel} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                    title="Egg Development:"
                    subTitle="Update egg development record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default EggDevelopmentUpdate