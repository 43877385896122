import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { ACISAPIURL } from "./SystemParameter"
import axios from 'axios'
import { reportError } from '../Common/Utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./UserSession"

const { Option } = Select

//----------
// Component
//----------
const TransactionTypeSelect = ({withBlank, defaultValue, type, onChange, onBlur, customWidth, excludes}) => {
    const [transactionTypeOption, setTransactionTypeOption] = useState([])
        
    //---------
    // Load all
    //---------
    const getAllTransactionType = () => {
        axios.get(`${ACISAPIURL}transactiontype/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank

            if(excludes != undefined) response.data.results = response.data.results.filter( type => !excludes.includes(type.pKey) )

            if(type == OTHERSYSPARAM("STOCK_IN"))
                response.data.results = response.data.results.filter( type => !type.is_system_param && (type.is_stock_in == true))
            else if(type == OTHERSYSPARAM("STOCK_OUT"))
                response.data.results = response.data.results.filter( type => !type.is_system_param && (type.is_stock_in == false))
                    
            options = options.concat(response.data.results.map( type => <Option key={type.pKey}>{type.name}</Option> ))
            setTransactionTypeOption(options)
        })
        .catch( error => {
            reportError(error, "<TransactionTypeSelect /> Failed to get transaction types.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllTransactionType()
    }, []) 
    
    return(
        <Select defaultValue={defaultValue} onChange={(e, value) => onSelectionChange(e, value)} style={{width: customWidth}}
            onBlur={onBlur} allowClear showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {transactionTypeOption}
        </Select>
    )
}

export default TransactionTypeSelect