import { useMediaQuery } from 'react-responsive'
import { Space, Tooltip } from 'antd'
import { InfoCircleOutlined } from "@ant-design/icons"
import { showRemark } from './Utility'
import { MEDIAMAXWIDTH } from './SystemParameter'


export const BatchColumn = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })

    let batchColumns = { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) }
    
    if(isTabletOrMobile) {
        batchColumns = { title: 'Batch ID', dataIndex: 'batch', key: 'batch',
            render: (batch, record) => {
                return <Tooltip title={batch} >
                        <div onClick={e => showRemark(e, `Batch ID: ${batch}`, <>
                            <Space><p>Species: {record.species}</p></Space><br />
                            <Space><p>Acquiring Method: {record.acquiringMethod}</p></Space><br />
                            <Space><p>Aquaculture Stage: {record.aquacultureStage}</p></Space><br />
                            <Space><p>Lifecycle: {record.lifecycle}</p></Space></>)}>
                            <a>{batch}</a>
                        </div>
                    </Tooltip>
            }
        }
    }
    
                            
    return batchColumns
}

export const RFIDColumn = () => {
    let rfidColumn = { title: 'RFID', dataIndex: 'tagId', key: 'tagId', //sorter: (a, b) => a.length - b.length 
        render: (tagId, record) => {
            if(tagId.length > 0)
                return <Tooltip title={tagId} >
                        <div onClick={e => showRemark(e, `${record.id} RFID`, tagId)}><InfoCircleOutlined style={{ fontSize: '20px', color: "blue"}}/></div>
                    </Tooltip>
            else
                return null
        }
    }

    return rfidColumn
}

export const DesktopColumns = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })

    let desktopColumns = [
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Acq. Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) },
        { title: 'Aqua. Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
    ]

    if(isTabletOrMobile) desktopColumns = []

    return desktopColumns
}