import { Table, Row, Col, Space, Button, Form, Collapse, message, Modal, DatePicker, Card, Select, Typography, Pagination, Spin } from 'antd'
import { PlusOutlined, InfoCircleOutlined, LeftOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, CRABMARINELIFEPKEY, DATEFORMAT, LOADING, MENUPATH_GROWTHDEV, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout, formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'

const { Panel } = Collapse
const { confirm } = Modal
const { Option } = Select
const { Title } = Typography

//----------
// Component
//----------
const GrowthDevelopmentDailyAverageByStorageTable = () => {
    const FileDownload = require('js-file-download')
    const [optionWidth, setOptionWidth] = useState(OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
            
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [storageOption, setStorageOption] = useState([])
    //const [observationDateTime, setObservationDateTime] = useState(moment())
        
    const [dailyAverageDataSource, setDailyAverageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    let modal = null

    //--------------
    // Table columns
    //--------------
    const col0 = { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) }
    const col1 = { title: 'Aqua. Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) }
    const col2 = { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) }
    const col3 = { title: 'Obs. On', dataIndex: 'observationDate', key: 'observationDate', sorter: (a, b) => a.observationDate.localeCompare(b.observationDate) }
    const col4 = { title: 'Age (days)', dataIndex: 'ageDays', key: 'ageDay', align: "right", sorter: (a, b) => stringNumberSorter(a.ageDays, b.ageDays) }
    const col5 = { title: 'Daily Samples Count', dataIndex: 'dailySamples', key: 'dailySamples', align: "right", sorter: (a, b) => a.dailySamples - b.dailySamples }
    const col6 = { title: 'Avg. Mouth Width (mm)', dataIndex: 'averageMouthWidth', key: 'averageMouthWidth', align: "right", sorter: (a, b) => stringNumberSorter(a.averageMouthWidth, b.averageMouthWidth) }
    const col7 = { title: 'Avg. Weight (g)', dataIndex: 'averageWeight', key: 'averageWeight', align: "right", sorter: (a, b) => stringNumberSorter(a.averageWeight, b.averageWeight) }
    const col8 = { title: 'Avg. Length (mm)', dataIndex: 'averageLength', key: 'averageLength', align: "right", sorter: (a, b) => stringNumberSorter(a.averageLength, b.averageLength) }

    const defaultColumns = [col0, col1, col2, col3, col4, col5, col6, col7, col8]
    const defaultColumnsCrab = [col0, col1, col2, col3, col4, col5, col8]
    const [columns, setColumns] = useState(defaultColumns)

    //--------------------------
    // Search growth development
    //--------------------------
    const searchDailyAverage = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        // Sanitize search criteria
        axios.get(`${ACISAPIURL}growthdevelopment/dailyaveragesummarybystorage/`, {
            params: { 
                batch: urlParams.get("batchId"),
                storage: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : storageId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search growth development daily average.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        // 20221031
        // Get marine life. Hide average mouth width and average length columns if is crab marine life.
        if(response.data.results.length > 0) {
            if(response.data.results[0].species_data.marine_life_data.pKey == CRABMARINELIFEPKEY) 
                setColumns(defaultColumnsCrab)
            else
                setColumns(defaultColumns)
        }

        const data = []
        response.data.results.forEach( dailyAverage => {
            data.push({
                key: dailyAverage.id,
                batch: dailyAverage.batchid,
                batchId: dailyAverage.batch_id,
                storage: dailyAverage.storage_name,
                storageId: dailyAverage.storage_id,
                aquacultureStage: dailyAverage.aquaculture_stage_data.name,
                lifecycle: dailyAverage.lifecycle_data.name,
                observationDate: moment(dailyAverage.growthdevelopmentobservationdate).format(DATEFORMAT),
                observationDateUnformatted: moment(dailyAverage.growthdevelopmentobservationdate).format(UNIDATETIMEFORMAT),
                ageDays: numberWithCommas(dailyAverage.ageindays),
                dailySamples: dailyAverage.dailysamples,
                averageMouthWidth: numberWithCommas(dailyAverage.dailyaveragemouthwidth),
                averageWeight: numberWithCommas(dailyAverage.dailyaverageweight),
                averageLength: numberWithCommas(dailyAverage.dailyaveragelength),
            })
        })
        
        setDailyAverageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        if(storageId == 0) {
            message.info("Please select a storage before proceed.")
            return
        }

        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}growthdevelopment/dailyaveragesummarybystorage/download/`, {
            params: { 
                batch: urlParams.get("batchId"),
                storage: storageId,
                search_criteria: `Site: ${getUserSiteName()}\nBatch ID: ${urlParams.get("batch")}\nStorage: ${storage}`,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Growth Development ${urlParams.get("batch")} ${storage} ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------
    // Load Storage
    //-------------
    const getStorage = (batchId) => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}storageassigned/`, {
            params: {
                batch: batchId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( storage => <Option key={storage.pKey}>{storage.name}</Option>))
            setStorageOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get storage assigned.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchDailyAverage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        if(storageId == 0) {
            message.info("Please select a storage before proceed.")
            return
        }

        setCurrentPage(1)
        searchDailyAverage(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------------
    // On birth date change
    //---------------------
    const onObservationDateChange = (datetime) => {
        if(datetime == null) {
            message.warn("Please select a date before proceed.")
            return
        }

        modal.destroy()

        navigate({ 
            pathname: "/growthdevelopmentstorage",
            search: `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&observedOn=${moment(datetime).format(UNIDATETIMEFORMAT)}&fromDailyAverage=true`
        })
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        if(storageId == 0) {
            message.info("Please select a storage before proceed.")
            return
        }

        modal = confirm({
            icon: <InfoCircleOutlined />,
            title: <Space><p>Please select an observation date.</p></Space>,
            onOk() {
                navigate({ 
                    pathname: "/growthdevelopmentstorage",
                    search: `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${storageId}&storage=${storage}&observedOn=${moment().format(UNIDATETIMEFORMAT)}&fromDailyAverage=true`
                })
            },
            onCancel() {},
            centered: true,
            content: <DatePicker onChange={onObservationDateChange} defaultValue={moment()} format={DATEFORMAT} style={{width: parseInt(OTHERSYSPARAM("DATE_PICKER_WIDTH"))}}/>
        })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    //pathname: "/growthdevelopmentmain", 
                    pathname: "/growthdevelopmenttable", 
                    search: `?batchId=${record.batchId}&batch=${record.batch}&storageId=${storageId}&storage=${storage}&observedOn=${record.observationDateUnformatted}`
                }) 
            }
        }
    }

    //----------------
    // On storage change
    //----------------
    const onStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    //------------------
    // On back
    //------------------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_GROWTHDEV,
            search: `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}`
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getStorage(urlParams.get("batchId"))

        message.info("Please select a storage to search or add new record.", OTHERSYSPARAM("LONG_MSG_DURATION"))
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form form={form} {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Batch ID: ${urlParams.get("batch")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Average Mouth Width (mm):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("averageMouthWidth")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Average Weight (g):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("averageWeight")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Average Length (mm):</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("averageLength")}</Card.Grid>
                </Card>
            </Form.Item>
        </Form>

        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search Growth Development By Storage" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="storageId" label="Storage">
                                <Select style={{width: optionWidth}} onChange={onStorageChange} allowClear showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {storageOption}
                                </Select>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={6}></Col>
            <Col span={12} style={{textAlign: "center"}}>
                <Card title="Growth Development History By Storage" style={{textAlign: "left"}} >
                    Daily average of growth development samples grouped by storage, aquaculture stage, lifecycle and date. After searched, click on one of the samples to view records belong to a storage on a particular day.
                </Card>
            </Col>
            <Col span={6}/>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={11} style={{textAlign: "start"}}>
                <Button type="primary" htmlType="button" disabled={disableButton} onClick={onBack} icon={<LeftOutlined />}>Back</Button>
            </Col>
            <Col span={2} />
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={dailyAverageDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}>
                <Button type="primary" htmlType="button" disabled={disableButton} onClick={onBack} icon={<LeftOutlined />}>Back</Button>
            </Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default GrowthDevelopmentDailyAverageByStorageTable