import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, message, Space, Layout, Row, Col, PageHeader, Modal, Descriptions, Spin } from 'antd'
import { SaveOutlined, LockOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { ACISAPIURL, LOADING } from '../Common/SystemParameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal

//----------
// Component
//----------
const ChangePassword = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    
    //---------------
    // Update passowrd
    //---------------
    const updatePassword = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}user/changepassword/`, {
                current_password: values.currentPassword,
                new_password: values.newPassword
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Password changed.`)
            })
            .catch( error => {
                reportError(error, "Failed to change password.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //--------------------
    // On update
    //--------------------
    const onUpdate = () => {
        form.validateFields()
        .then( values => {
            if(values.newPassword.trim().length < 8) {
                message.warn("Invalid new password.")
                return
            }

            if(values.confirmNewPassword.trim().length < 8) {
                message.warn("Invalid confirm new password.")
                return
            }

            if(values.newPassword != values.confirmNewPassword) {
                message.warn("New password do not match.")
                return
            }
            else {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Change password confirmed?</p></Space>,
                    onOk() { updatePassword() },
                    onCancel() {},
                    centered: true
                })
            }
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader  
                    title="Change Password">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Change your password</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} {...formLayout}>
                    <Form.Item name="currentPassword" label="Current Password"
                        rules={[
                            { required: true, message: "Current password is required." },
                        ]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="Current Password"/>
                    </Form.Item>

                    <Form.Item name="newPassword" label="New Password"
                        rules={[
                            { required: true, message: "New password is required."},
                        ]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="New Password"/>
                    </Form.Item>

                    <Form.Item name="confirmNewPassword" label="Confirm New Password"
                        rules={[
                            { required: true, message: "Confirm new password is required."},
                        ]}>
                        <Input.Password prefix={<LockOutlined type="lock" />} minLength={8} maxLength={20} placeholder="Confirm New Password"/>
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Change</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader
                        title="Change Password:"
                        subTitle="Change your password"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default ChangePassword