import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL, MEDIAMAXWIDTH,  } from "./SystemParameter"
import { reportError } from './Utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./UserSession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select

//----------
// Component
//----------
const MarineLifeSelect = ({withBlank, defaultValue, disabled, onChange, defaultWidth, allowClear = true}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [marineLifeOption, setMarineLifeOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(defaultWidth != undefined ? defaultWidth : (isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")))
        
    //---------
    // Load all
    //---------
    const getAllMarineLife = () => {
        axios.get(`${ACISAPIURL}marinelife/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( type => <Option key={type.pKey}>{type.name}</Option>))
            setMarineLifeOption(options)
        })
        .catch( error => {
            reportError(error, "<MarineLifeSelect /> Failed to get marine life.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllMarineLife()
    }, []) 
    
    return(
        <Select 
            defaultValue={defaultValue} disabled={disabled} style={{width: optionWidth}} onChange={(e, value) => onSelectionChange(e, value)} 
            allowClear={allowClear} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {marineLifeOption}
        </Select>
    )
}

export default MarineLifeSelect