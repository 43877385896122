import { Row, Col, Space, Button, Form, Collapse, message, Card, Typography, Spin } from 'antd'
import { useEffect, useState, useRef } from 'react'
import axios from "axios"
import { ACISAPIURL, DATETIMEFORMAT, ENVDATA_VALUE_DECIMALPLACES, LOADING, UNTAGGED } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { getCookie, reportError } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { Title } = Typography

//----------
// Component
//----------
const CentralisedConsoleTable = () => {
    const MAXSTORAGE = OTHERSYSPARAM("CONSOLE_MAX_STORAGE")
    const LASTNMINUTE = OTHERSYSPARAM("CONSOLE_ENV_HISTORY_LAST_N_MINUTE")
        
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [readingType, setReadingType] = useState([])
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [speciesId, setSpeciesId] = useState(0)
    const [batchId, setBatchId] = useState(0)

    const [storageTypeId, setStorageTypeId] = useState([])
    const [storageIds, setStorageIds] = useState([])
    const [storageNames, setStorageNames] = useState([])
    const [storageCards, setStorageCards] = useState([])
    
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
        
    let intervalIdRef = useRef()

    const isDarkTheme = getCookie('useDarkTheme') == '1' ? true : false

    //------------
    // Main search
    //------------
    const bulkInfoSearch = async () => {
        setDisableButton("disabled")
        setIsLoading(true)

        const storageData = []
        const batchData = []
        const stockSummaryData = []
        const lastEnvironmentData = []
        const lastFeedingData = []
        const lastGrowthDevelopmentData = []
        const lastStockCountData = []
        const totalMortalityData = []

        //------------------------------
        // Storage, batch, stock summary
        //------------------------------
        await axios.get(`${ACISAPIURL}storagepaginatedwithstoragestocksummaryfull/`, {
            params: { 
                site: getUserSiteId(),
                ids: storageIds.toString(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 10),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.results.forEach( storage => {
                // Get storage average weight and length.
                let averageWeightGram = "0.00"
                let averageLengthMm = "0.00"
                const untagged_stock_summary = storage.storagestocksummary.filter( summary => summary.item_type_name.startsWith(UNTAGGED))
                if(untagged_stock_summary.length > 0) {
                    averageWeightGram = untagged_stock_summary[0].untagged_average_weight_gram
                    averageLengthMm = untagged_stock_summary[0].untagged_average_length_mm
                }

                // Storage info
                storageData.push({
                    pKey: storage.pKey,
                    name: storage.name,
                    type: storage.storage_type_data.name,
                    averagePerUnitWeightGram: averageWeightGram,
                    averagePerUnitLengthMm: averageLengthMm,
                    batch: storage?.batch_data?.id,
                })
                
                // Batch info
                if(storage.batch_data != null)
                    batchData.push({
                        pKey: storage.batch_data.pKey,
                        id: storage.batch_data.id,
                        species: storage.batch_data.species_data.short_name,
                        batchType: storage.batch_data.batch_type_data.name,
                        acquiringMethod: storage.batch_data.acquiring_method_data.name,
                        aquacultureStage: storage.batch_data.aquaculture_stage_data.name,
                        lifecycle: storage.batch_data.lifecycle_data.name,
                        //averagePerUnitWeightGram: storage.batch_data.average_per_unit_weight_gram,
                        //averagePerUnitLengthMm: storage.batch_data.average_per_unit_length_mm,
                        ageDays: storage.batch_data.age_days != null ? storage.batch_data.age_days : "-",
                        storage: storage.name,
                    })
                
                // Stock summary info
                storage.storagestocksummary.forEach( summary => {
                    stockSummaryData.push({
                        itemTypeName: summary.item_type_name,
                        uom: summary.uom_name,
                        quantity: summary.quantity,
                        batch: summary.batchid,
                        storage: summary.storage_name,
                    })
                })
            })
        })
        .catch( error => {
            reportError(error, "Failed to search storage information.")
        })
        
        //------------------------
        // Latest environment data
        //------------------------
        /*
        await axios.get(`${ACISAPIURL}lastenvironment/`, {
            params: { 
                site: getUserSiteId(),
                storages: storageIds.toString(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 20),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.forEach( history => {
                lastEnvironmentData.push({
                    //datetime: moment(history[0]).format(DATETIMEFORMAT),
                    datetime: moment(history[0]),
                    value: history[1],
                    readingType: history[2],
                    storage: history[3],
                    uom: history[4]
                })
            })
        })
        .catch( error => {
            reportError(error, "Failed to search environment data.")
        })
        */

        //------------------
        // Last feeding data
        //------------------
        await axios.get(`${ACISAPIURL}lastfeeding/`, {
            params: { 
                storages: storageIds.toString(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 10),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.forEach( feeding => {
                lastFeedingData.push({
                    datetime: moment(feeding[0]).format(DATETIMEFORMAT), 
                    type: feeding[1],
                    quantity: feeding[2],
                    uom: feeding[3],
                    storage: feeding[4],
                })
            })
        })
        .catch( error => {
            reportError(error, "Failed to search environment data.")
        })

        //------------------------
        // Last growth development
        //------------------------
        await axios.get(`${ACISAPIURL}lastgrowthdevelopment/`, {
            params: { 
                storages: storageIds.toString(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 10),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.forEach( growth => {
                lastGrowthDevelopmentData.push({
                    datetime: moment(growth[0]).format(DATETIMEFORMAT), 
                    storage: growth[1],
                })
            })
        })
        .catch( error => {
            reportError(error, "Failed to search growth development data.")
        })

        //------------------------
        // Last stock count
        //------------------------
        await axios.get(`${ACISAPIURL}laststockcount/`, {
            params: { 
                storages: storageIds.toString(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 10),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.forEach( stockcount => {
                lastStockCountData.push({
                    datetime: moment(stockcount[0]).format(DATETIMEFORMAT), 
                    storage: stockcount[1],
                })
            })
        })
        .catch( error => {
            reportError(error, "Failed to search growth stock count data.")
        })

        //------------------------
        // Total mortality
        //------------------------
        await axios.get(`${ACISAPIURL}totalmortality/`, {
            params: { 
                batches: batchData.map( batch => batch.pKey).toString(),
                storages: storageIds.toString(),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 10),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            response.data.forEach( mortality => {
                totalMortalityData.push({
                    batch: mortality[0],
                    storage: mortality[1],
                    itemType: mortality[2],
                    uom: mortality[3],
                    quantity: mortality[4],
                })
            })
        })
        .catch( error => {
            reportError(error, "Failed to search mortality data.")
        })
        
        //------------
        // Build cards
        //------------
        try {
            const allCards = []
            
            //--------
            // Storage
            //--------
            storageData.forEach( storage => {
                let lastDateTimeCard = <></>

                //--------
                // Batches
                //--------
                const batches = batchData.filter( batch => batch.id == storage.batch )
                let batchCards = []
                if(batches.length == 0) {
                    batchCards.push(
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Batch ID:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-83pc">-</Card.Grid>
                        </>
                    )
                }

                batches.forEach( batch => {
                    const batchCard = 
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Batch ID:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{batch.id}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Species:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{batch.species}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Batch Type:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{batch.batchType}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Acquiring Method:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{batch.acquiringMethod}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Aquaculture Stage:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{batch.aquacultureStage}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Lifecycle:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{batch.lifecycle}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Average Weight (g):</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{storage.averagePerUnitWeightGram}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Average Length (mm):</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{storage.averagePerUnitLengthMm}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Age (days):</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{batch.ageDays}</Card.Grid>
                        </>
                    batchCards.push(batchCard)

                    //--------------
                    // Stock summary
                    //--------------
                    let stockSummaryCard = <></>
                    const stockSummaries = stockSummaryData.filter( summary => summary.batch == batch.id && summary.storage == storage.name )
                    let stockItem = ""
                    stockSummaries.forEach( summary => {
                        if(summary.quantity > 0) {
                            if(stockItem != "") stockItem = `${stockItem}, `
                            stockItem = `${stockItem}${summary.itemTypeName} ${summary.quantity} ${summary.uom}`
                        }
                    })

                    if(stockItem == "") stockItem = "-"
                    stockSummaryCard = <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Stock Summary</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-83pc">{`${stockItem}`}</Card.Grid>
                        </>
                    batchCards.push(stockSummaryCard)

                    //----------
                    // Mortality
                    //----------
                    let mortalitySummaryCard = <></>
                    const totalMortality = totalMortalityData.filter( mortality => mortality.batch == batch.id && mortality.storage == storage.name )
                    let mortalityItem = ""
                    totalMortality.forEach( mortality => {
                        if(mortality.quantity > 0) {
                            if(mortalityItem != "") mortalityItem = `${mortalityItem}, `
                            mortalityItem = `${mortalityItem}${mortality.itemType} ${mortality.quantity} ${mortality.uom}`
                        }
                    })

                    if(mortalityItem == "") mortalityItem = "-"
                    mortalitySummaryCard = <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Mortality Summary</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-83pc">{`${mortalityItem}`}</Card.Grid>
                        </>
                    batchCards.push(mortalitySummaryCard)

                    //--------------------------------------------------------
                    // Last feeding, growth development sampling, stock count.
                    //--------------------------------------------------------
                    const feeding = lastFeedingData.filter( i => i.storage == storage.name)
                    let feedingDate = "-"
                    if(feeding.length == 1) feedingDate = feeding[0].datetime

                    const growthDev = lastGrowthDevelopmentData.filter( i => i.storage == storage.name)
                    let growthDevDate = "-"
                    if(growthDev.length == 1) growthDevDate = growthDev[0].datetime

                    const stockCount = lastStockCountData.filter( i => i.storage == storage.name)
                    let stockCountDate = "-"
                    if(stockCount.length == 1) stockCountDate = stockCount[0].datetime

                    lastDateTimeCard = <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Last Feeding:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{feedingDate}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Last Growth Dev. Sampling:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{growthDevDate}</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Last Stock Count:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">{stockCountDate}</Card.Grid>
                        </>
                })

                //-----------------
                // Environment data
                //-----------------
                const envData = lastEnvironmentData.filter( env => env.storage == storage.name)
                
                const environmentDataCards = []
                readingType.forEach( type => {
                    const reading = envData.filter(env => env.readingType.startsWith(type.name))

                    environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc" style={{fontStyle: "italic"}}>{type.name}:</Card.Grid>)

                    if(reading.length > 0) {
                        let color = ""
                        if(!isDarkTheme) color = "#e6f7ff"
                        
                        let className = ""
                        if(reading[0].value < type.minSafeValue || reading[0].value > type.maxSafeValue || 
                            // Older than last n minutes
                            moment(reading[0].datetime).add(LASTNMINUTE, 'minutes').isBefore(moment())) {
                            if(!isDarkTheme) color = "#fff2f0"
                            className = "blink"
                        }

                        const value = `${parseFloat(reading[0].value).toFixed(ENVDATA_VALUE_DECIMALPLACES.get(type.name))} ${reading[0].uom} @ ${reading[0].datetime.format(DATETIMEFORMAT)}`
                        environmentDataCards.push(
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc" style={{backgroundColor: color}} onClick={(e) => showGraph(e, storage.name, storage.pKey)}>
                                <div className={className}>
                                    {value}
                                </div>
                            </Card.Grid>)
                    }
                    else
                        environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc">-</Card.Grid>)
                })

                // To fully occupied one row.
                environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc" style={{fontStyle: "italic"}}><br /></Card.Grid>)
                environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc"><br /></Card.Grid>)
                environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc" style={{fontStyle: "italic"}}><br /></Card.Grid>)
                environmentDataCards.push(<Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-16pc"><br /></Card.Grid>)
                    
                //----------
                // Main card
                //----------    
                const mainCard = <>
                    <Row><Col><Space><div /></Space></Col></Row>
                    <Row justify="center">
                        <Col style={{textAlign: "left"}} span={24}>
                            <Card title={<Title>{storage.name}</Title>} style={{ width: '100%' }}>
                                {/* {environmentDataCards} */}
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label-16pc">Storage Type:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper-83pc">{storage.type}</Card.Grid>
                                {batchCards}
                                {lastDateTimeCard}
                            </Card>
                        </Col>
                    </Row>
                    </>

                allCards.push(mainCard)
            })

            setStorageCards(allCards)
        }
        catch(error) {
            message.error(error.toString())
        }
        finally {
            // Restore user interface.
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()

            return Promise.resolve()
        }
    }

    //-----------------------------
    // Show environment data graphs
    //-----------------------------
    const showGraph = (e, storageName, storagePKey) => {
        window.open(
            window.location.origin + `/centralisedconsolegraph/?storageName=${storageName}&storagePKey=${storagePKey}`, 
            storageName,
            //"resizable,scrollbars,status");
            "popup");
    }

    //----------------------------------
    // Get all environment reading type.
    //----------------------------------
    /*const getAllEnvironmentReadingType = () => {
        axios.get(`${ACISAPIURL}environmentreadingtype/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const data = []
            response.data.results.forEach( type => {
                data.push({
                    name: type.name,
                    uom: type.uom,
                    minSafeValue: type.min_safe_value,
                    maxSafeValue: type.max_safe_value,
                })
            })

            setReadingType(data)
        })
        .catch( error => {
            reportError(error, "Failed to get all environment reading types.")
        })
    }*/

    //--------------------------------------------
    // Retrieve data and refresh on fixed interval
    //--------------------------------------------
    const startDisplayLoop = () => {
        clearInterval(intervalIdRef.current)
        bulkInfoSearch()
        
        intervalIdRef.current = setInterval(() => {
            bulkInfoSearch()
        }, parseInt(OTHERSYSPARAM("CONSOLE_REFRESH_MINUTE")) * 60000)
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        if(storageIds.length == 0 || storageIds.length == undefined) {
            message.info(`Please select storage before search.`)
            return
        }

        if(storageIds.length > MAXSTORAGE) {
            message.info(`Maximum number of storages that can be selected are ${MAXSTORAGE} only. ${storageIds.length} were selected.`)
            return
        }

        startDisplayLoop()
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
    }

    const onSpeciesChange = (e, value) => {
        setSpeciesId(e)
    }

    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
    }

    const onSearchStorageTypeChange = (e, value) => {
        setStorageTypeId(e)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageIds(e)
        if(value.length > 0) setStorageNames(value.map( v => v.children))
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        //getAllEnvironmentReadingType()

        // ComponentWillUnmount
        return () => {
            clearInterval(intervalIdRef.current)
        }
    }, [])

    //--------------
    // Table columns
    //--------------
    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search Batch" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={onSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageTypeChange={onSearchStorageTypeChange} onStorageChange={onSearchStorageChange} showLockedStorage={false} showActiveBatchSwitch={false} storageSelectMode="multiple"/>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton} isLoading={isLoading}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} >Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form> 
                    </Panel>
                </Collapse>
            </Col>
        </Row>
        {storageCards}
        </Spin>
        </>
    )
}

export default CentralisedConsoleTable

