import { useEffect } from 'react'
import { Layout, Row, Col, Space, BackTop, PageHeader, Descriptions } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import BatchTable from './BatchTable'
import { walltiles } from '../Common/Layout'

const { Header, Footer, Content } = Layout

//----------
// Component
//----------
const BatchMain = () => {
    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                {<ACISHeader />}
            </Header>

            <Content style={{...walltiles}}>

                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader 
                    onBack={null} 
                    title="Batch">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Manage batch</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Row justify="center">
                    <Col span={1}></Col>
                        {<Col span={22}><BatchTable /></Col>}
                    <Col span={1}></Col>
                </Row>
            </Content>

            <Footer>
                {<ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={null} 
                        title="Batch"
                        subTitle="Manage batch"/>} />}
            </Footer>

            <BackTop />
        </Layout>
    )
}

export default BatchMain