import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Popconfirm, message, Modal, Input, PageHeader, Descriptions, InputNumber, Spin } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, LOADING, MENUPATH_SPECIES } from '../Common/SystemParameter'
import { isFloat, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import MarineLifeSelect from '../Common/MarineLifeSelect'

const { Header, Footer, Content } = Layout
const { confirm } = Modal

//----------
// Component
//----------
const SpeciesNew = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [minWeightedLengthCM, setMinWeightedLengthCM] = useState(10)
    const [isLoading, setIsLoading] = useState(false)
        
    //-------------------
    // Create new site
    //-------------------
    const createSpecies = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}species/create/`, {
                marine_life: marineLifeId,
                id: values.speciesId.toUpperCase(),
                scientific_name: values.scientificName,
                general_name: values.generalName,
                short_name: values.shortName,
                min_weighted_length_cm: minWeightedLengthCM,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`New species ${values.shortName} created.`)
                navigate({ pathname: MENUPATH_SPECIES })
            })
            .catch( error => {
                reportError(error, "Failed to create new species.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //--------
    // On next
    //--------
    const onSave = async (e) => {
        if(isFloat(form.getFieldValue("minWeightedLengthCM"))) {
            message.warn("Minimum weighted length accepts integer only.")
            return
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create species confirmed?</p></Space>,
            onOk() { createSpecies() },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_SPECIES
        })
    }

    //---------------------
    // On marine life change
    //---------------------
    const onMarineLifeChange = (e, value) => {
        if(e != undefined) 
            setMarineLifeId(e)
        else 
            setMarineLifeId(0)
    }

    //------------------
    // Custom validation
    //------------------
    const validateMarineLife = (() => {
        if(marineLifeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Marine life is required."))
    })

    const validateMinWeightedLengthCM = (() => {
        if(minWeightedLengthCM > 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Min. weighted length is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Species">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create species</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item name="marineLife" label="Marine Life"
                        rules={[
                            { required: true, validator: validateMarineLife },
                        ]}>
                        <MarineLifeSelect withBlank={false} onChange={onMarineLifeChange}/>
                    </Form.Item>

                    <Form.Item name="shortName" label="Short Name"
                        rules={[
                            { required: true, message: "Short name is required."},
                        ]}>
                        <Input maxLength={30} placeholder="Short Name"/>
                    </Form.Item>

                    <Form.Item name="speciesId" label="Species ID"
                        rules={[
                            { required: true, message: "Species ID is required."},
                        ]}>
                        <Input maxLength={3} minLength={3} placeholder="Species ID (3 alphanumeric)"/>
                    </Form.Item>

                    <Form.Item name="generalName" label="General Name"
                        rules={[
                            { required: true, message: "General name is required."},
                        ]}>
                        <Input maxLength={30} placeholder="General Name"/>
                    </Form.Item>

                    <Form.Item name="scientificName" label="Scientific Name"
                        rules={[
                            { required: true, message: "Scientific name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Scientific Name"/>
                    </Form.Item>

                    <Form.Item name="minWeightedLengthCM" label="Minimum Weighted Length (cm)"
                         rules={[
                            { required: true, validator: validateMinWeightedLengthCM },
                        ]}>
                        <InputNumber defaultValue={minWeightedLengthCM} min={1} max={100} step={1} placeholder="Minimum weighted length (cm)"/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Species:"
                    subTitle="Create species"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default SpeciesNew