import { Table, Row, Col, Space, Button, Form, Collapse, message, Modal, DatePicker, Card, Pagination, Spin, Select } from 'antd'
import { PlusOutlined, InfoCircleOutlined, DownloadOutlined, ProfileOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL,  APPJSON,  CRABMARINELIFEPKEY,  DATEFORMAT, LOADING, MENUPATH_GROWTHDEVELOPMENTHISTORY, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserSiteName, getUserSiteId } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'

const { Panel } = Collapse
const { confirm } = Modal
const { Option } = Select

//----------
// Component
//----------
const GrowthDevelopmentDailyAverageByBatchTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
            
    const [dailyAverageDataSource, setDailyAverageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const [newBatchOption, setNewBatchOption] = useState([])
    const [searchBatchOption, setSearchBatchOption] = useState([])
        
    //--------------
    // Table columns
    //--------------
    const col0 = { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) }
    const col1 = { title: 'Aqua. Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) }
    const col2 = { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) }
    const col3 = { title: 'Obs. On', dataIndex: 'observationDate', key: 'observationDate', sorter: (a, b) => a.observationDate.localeCompare(b.observationDate) }
    const col4 = { title: 'Age (days)', dataIndex: 'ageDays', key: 'ageDay', align: "right", sorter: (a, b) => stringNumberSorter(a.ageDays, b.ageDays) }
    const col5 = { title: 'Daily Samples Count', dataIndex: 'dailySamples', key: 'dailySamples', align: "right", sorter: (a, b) => a.dailySamples - b.dailySamples }
    const col6 = { title: 'Avg. Mouth Width (mm)', dataIndex: 'averageMouthWidth', key: 'averageMouthWidth', align: "right", sorter: (a, b) => stringNumberSorter(a.averageMouthWidth, b.averageMouthWidth) }
    const col7 = { title: 'Avg. Length (mm)', dataIndex: 'averageLength', key: 'averageLength', align: "right", sorter: (a, b) => stringNumberSorter(a.averageLength, b.averageLength) }
    const col8 = { title: 'Avg. Weight (g)', dataIndex: 'averageWeight', key: 'averageWeight', align: "right", sorter: (a, b) => stringNumberSorter(a.averageWeight, b.averageWeight) }

    const defaultColumns = [col0, col1, col2, col3, col4, col5, col6, col7, col8]
    const defaultColumnsCrab = [col0, col1, col2, col3, col4, col5, col8]
    const [columns, setColumns] = useState(defaultColumns)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [batchId, setBatchId] = useState(urlParams.has("batchId") ? urlParams.get("batchId") : 0)
    const [batch, setBatch] = useState(urlParams.has("batch") ? urlParams.get("batch") : "")

    let modal = null
    
    //--------------------------
    // Search growth development
    //--------------------------
    const searchDailyAverage = (currentPage, urlParamSearchBatchId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        // Sanitize search criteria
        axios.get(`${ACISAPIURL}growthdevelopment/dailyaveragesummary/`, {
            params: { 
                batch: urlParamSearchBatchId != 0 ? urlParamSearchBatchId : batchId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search growth development daily average.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        // 20221031
        // Get marine life. Hide average mouth width and average length columns if is crab marine life.
        if(response.data.results.length > 0) {
            if(response.data.results[0].species_data.marine_life_data.pKey == CRABMARINELIFEPKEY) 
                setColumns(defaultColumnsCrab)
            else
                setColumns(defaultColumns)
        }

        const data = []
        response.data.results.forEach( dailyAverage => {
            data.push({
                key: dailyAverage.id,
                batch: dailyAverage.batchid,
                batchId: dailyAverage.batch_id,
                aquacultureStage: dailyAverage.aquaculture_stage_data.name,
                lifecycle: dailyAverage.lifecycle_data.name,
                observationDate: moment(dailyAverage.growthdevelopmentobservationdate).format(DATEFORMAT),
                observationDateUnformatted: moment(dailyAverage.growthdevelopmentobservationdate).format(UNIDATETIMEFORMAT),
                ageDays: numberWithCommas(dailyAverage.ageindays),
                dailySamples: dailyAverage.dailysamples,
                averageMouthWidth: numberWithCommas(dailyAverage.dailyaveragemouthwidth),
                averageWeight: numberWithCommas(dailyAverage.dailyaverageweight),
                averageLength: numberWithCommas(dailyAverage.dailyaveragelength),
            })
        })
        
        setDailyAverageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        if(batchId == 0) {
            message.info("Please select a batch before proceed.")
            return
        }

        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}growthdevelopment/dailyaveragesummary/download/`, {
            params: { 
                batch: batchId,
                search_criteria: `Site: ${getUserSiteName()}\nBatch ID: ${batch}`,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Growth Development ${batch} ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------
    // Load batch
    //-----------
    const getAllBatch = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}batchfull/`, {
            params: {
                site: getUserSiteId(),
                active: true,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            options.push(<Option key={""}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( batch => <Option key={batch.pKey}>{batch.id}</Option>))
            setNewBatchOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get full batch list for new growth development.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const getAllBatchWithGrowthDev = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}batchfull/`, {
            params: {
                site: getUserSiteId(),
                active: true,
                with_growth_dev: true
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            //options.push(<Option key={""}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( batch => <Option key={batch.pKey}>{batch.id}</Option>))
            setSearchBatchOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get full batch list for new growth development.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchDailyAverage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        if(batchId == 0) {
            message.info("Please select a batch before proceed.")
            return
        }

        setCurrentPage(1)
        searchDailyAverage(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------------
    // On birth date change
    //---------------------
    const onObservationDateChange = (datetime, newGrowthDevBatchId) => {
        if(newGrowthDevBatchId == 0) {
            message.info("Please select a batch before proceed.")
            return
        }

        if(datetime == null) {
            message.warn("Please select a date before proceed.")
            return
        }

        modal.destroy()

        navigate({ 
            pathname: "/growthdevelopmentstorage",
            search: `?batchId=${newGrowthDevBatchId}&batch=${batch}&observedOn=${moment(datetime).format(UNIDATETIMEFORMAT)}&fromDailyAverage=true`
        })
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = async () => {
        let newGrowthDevBatchId = batchId
        let newGrowthDevBatch = batch

        const onNewBatchChange = (e, value) => {
            newGrowthDevBatchId = e
            newGrowthDevBatch = value
        }

        modal = confirm({
            icon: <InfoCircleOutlined />,
            title: <Space><p>Please select batch ID and observation date.</p></Space>,
            onOk() {
                if(newGrowthDevBatchId != 0) 
                    navigate({ 
                        pathname: "/growthdevelopmentstorage",
                        search: `?batchId=${newGrowthDevBatchId}&batch=${newGrowthDevBatch}&observedOn=${moment().format(UNIDATETIMEFORMAT)}&fromDailyAverage=true`
                    })
                else 
                    message.info("Please select a batch before proceed.")
            },
            onCancel() {},
            centered: true,
            content: 
                <>
                <div>
                    Batch ID:
                </div>
                <div>
                <Select style={{width: parseInt(OTHERSYSPARAM("DATE_PICKER_WIDTH"))}} onChange={onNewBatchChange} allowClear showSearch optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    defaultValue={newGrowthDevBatch}>
                    {newBatchOption}    
                </Select>
                </div>
                <br />
                <div>
                    Observation Date:
                </div>
                <div>
                <DatePicker onChange={(datetime) => onObservationDateChange(datetime, newGrowthDevBatchId)} defaultValue={moment()} format={DATEFORMAT} style={{width: parseInt(OTHERSYSPARAM("DATE_PICKER_WIDTH"))}} />
                </div>
                </>
        })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/growthdevelopmentdailyaveragebystorage", 
                    search: `?batchId=${record.batchId}&batch=${record.batch}&observedOn=${record.observationDateUnformatted}&averageWeight=${record.averageWeight}&averageLength=${record.averageLength}&averageMouthWidth=${record.averageMouthWidth}`
                }) 
            }
        }
    }

    //----------------
    // On batch change
    //----------------
    const onBatchChange = (e, value) => {
        setDailyAverageDataSource([])
        setBatchId(e)
        setBatch(value.children)
    }

    //-------------------
    // Redirect to report
    //-------------------
    const onGoToReport = () => {
        navigate({ pathname: MENUPATH_GROWTHDEVELOPMENTHISTORY })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllBatch()
        getAllBatchWithGrowthDev()

        if(urlParams.has("batchId")) {
            searchDailyAverage(currentPage, urlParams.get("batchId"))
        }

        message.info("Please select a batch to search or add new record.", OTHERSYSPARAM("LONG_MSG_DURATION"))
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search Growth Development By Batch" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="batchId" label="Batch ID">
                                <Select onChange={onBatchChange} showSearch optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    defaultValue={urlParams.get("batchId")}>
                                    {searchBatchOption}    
                                </Select>
                            </Form.Item>
                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={6} />
            <Col span={12} style={{textAlign: "center"}}>
                <Card title="Growth Development History By Batch" style={{textAlign: "left"}} >
                    Daily average of growth development samples grouped by batch, aquaculture stage, lifecycle and date. After searched, click on one of the samples to view samples belong to storage assigned to batch.
                </Card>
            </Col>
            <Col span={6} />
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={11} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onGoToReport} icon={<ProfileOutlined />}>History</Button></Col>
            <Col span={2} />
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
 
        <Table bordered columns={columns} dataSource={dailyAverageDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onGoToReport} icon={<ProfileOutlined />}>History</Button></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default GrowthDevelopmentDailyAverageByBatchTable
