import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, Select, DatePicker, PageHeader, Card, Modal, Input, Descriptions, Upload, Spin } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, EGGS, LOADING, MEDIAMAXWIDTH, MENUPATH_EGGDEV,  UNIDATEFORMAT } from "../Common/SystemParameter"
import { reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, SYSPARAM, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import GonadMaturationSelect from '../Spawning/GonadMaturationSelect'


const { Header, Footer, Content } = Layout
const { Title } = Typography
const { Option } = Select
const { confirm } = Modal
const { TextArea } = Input

//----------
// Component
//----------
const EggDevelopmentNew = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const [form] = Form.useForm()
    const [fishIdOption, setFishIdOption] = useState([])
    const [fishId, setFishId] = useState(0)
    const [hideFishSelect, setHideFishSelect] = useState(false)
    const [observationDateTime, setObservationDateTime] = useState(moment(moment(), UNIDATEFORMAT))
    const [gonadMaturationId, setGonadMaturationId] = useState(0)
    const [eggImageFile, setEggImageFile] = useState([])
    const [uploading, setUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [eggDevelopmentStageId, setEggDevelopmentStageId] = useState(urlParams.get("itemType").startsWith(EGGS) ? SYSPARAM("Post Spawning") : SYSPARAM("Pre Spawning"))
    const [eggDevelopmentStage, setEggDevelopmentStage] = useState(urlParams.get("itemType").startsWith(EGGS) ? OTHERSYSPARAM("POST_SPAWN_TXT") : OTHERSYSPARAM("PRE_SPAWN_TXT"))

    //-----------------------
    // Create egg development
    //-----------------------
    const createEggDevelopment = () => {
        // Disable button.
        setDisableButton("disabled")
        setUploading(true)
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}eggdevelopment/create/`, {
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                fish: fishId,
                spawning_status: eggDevelopmentStageId,
                egg_diameter: parseFloat(values.eggSize).toFixed(2),
                oil_globule_diameter: parseFloat(values.oilGlobuleSize).toFixed(2),
                gonad_maturation: gonadMaturationId,
                remark: values.remark,
                observedOn: observationDateTime

            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: { "Authorization": `Token ${getUserAuthToken()}`, }
            })
            .then( response => {
                if(eggImageFile == "") {
                    navigate({ 
                        pathname: MENUPATH_EGGDEV
                    })
                    return
                }

                let data = new FormData()
                eggImageFile.forEach( file => {
                    data.append("img", file)
                })
                            
                // Upload image if found.
                /*axios({
                    method: "post",
                    url: `${ACISAPIURL}eggdevelopment/imgupload/${response.data.pKey}/`,
                    data: data,
                    headers: {
                        "Authorization": `Token ${getUserAuthToken()}`,
                        "Content-Type": "multipart/form-data"
                    }
                })*/
                fetch(`${ACISAPIURL}eggdevelopment/imgupload/${response.data.pKey}/`, {
                    method: 'POST',
                    headers: {
                        "Authorization": `Token ${getUserAuthToken()}`,
                    },
                    body: data,
                })
                .then( response => {
                    message.info("Egg development record saved.")
                    navigate({ 
                        pathname: MENUPATH_EGGDEV
                    })
                })
                .catch( error => {
                    reportError(error, `Failed to upload image file.`)
                })
                .finally(() => {
                    setDisableButton("")
                    setUploading(false)
                    refreshUserSession()
                })
            })
            .catch( error => {
                reportError(error, `Failed to save egg development data.`)
            })
            .finally(() => {
                setDisableButton("")
                setUploading(false)
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //------------
    // Search fish
    //------------
    const getBroodInStorage = () => {
        setDisableButton("disabled")
        setIsLoading(true)

        axios.get(`${ACISAPIURL}fishfull/`, {
            params: { 
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                gender: SYSPARAM("Female"),
                isBrood: true,
                active: true,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options = options.concat(response.data.results.map( fish => <Option key={fish.pKey}>{fish.id}</Option> ))
            setFishIdOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get fish id.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = (e) => {
        form.validateFields()
        .then( values => {
            if(urlParams.get("itemType").startsWith(EGGS) && values.quantity > urlParams.get("availableEggQuantity")) {
                message.warn("Sample quantity exceeded available quantity.")
                return
            }

            if(urlParams.get("itemType").startsWith(EGGS) && values.quantity == urlParams.get("availableEggQuantity")) 
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Sample quantity is all of available quantity. Save egg development record confirmed?</p></Space>,
                    onOk() { createEggDevelopment() },
                    onCancel() {},
                    centered: true
                })
            else {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Save egg development record confirmed?</p></Space>,
                    onOk() { createEggDevelopment() },
                    onCancel() {},
                    centered: true
                })
            }
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: "/eggdevelopmentstorage"
        })
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setObservationDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateFishId = (() => {
        if((eggDevelopmentStageId == SYSPARAM("Pre Spawning") && fishId != 0) || eggDevelopmentStageId != SYSPARAM("Pre Spawning")) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Tag ID is required."))
    })

    const validateObservationDateTime = (() => {
        if(observationDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Observation date time is required."))
    })

    const validateGonadMaturation = (() => {
        if(gonadMaturationId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Gonad Maturation is required."))
    })

    //----------------------------
    // Upload image file property.
    //----------------------------
    const props = {
        onRemove: file => {
            setEggImageFile([])
        },
        beforeUpload: file => {
            setEggImageFile([file])
            return false
        },
        maxCount: 1,
        eggImageFile,
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getBroodInStorage()

        form.setFieldsValue({
            remark: ""
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onBack()} 
                    title="Egg Development">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create egg development record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Egg Development Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{eggDevelopmentStage}</Card.Grid>
                        </Card>
                    </Form.Item>

                    <Form.Item name="imageFile" label="Image File">
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Select File</Button>
                        </Upload>
                    </Form.Item> 

                    { eggDevelopmentStageId == SYSPARAM("Pre Spawning") ?
                        <Form.Item name="fishId" label={"Brood Tag ID"} hidden={hideFishSelect}
                            rules={[
                                { required: true, validator: validateFishId },
                            ]}>
                            <Select style={{width: optionWidth}} onChange={setFishId}>
                                {fishIdOption}
                            </Select>
                        </Form.Item> :
                        null
                    }

                    <Form.Item name="observationDateTime" label="Observation Date Time"
                        rules={[
                            { required: true, validator: validateObservationDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={observationDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="eggSize" label="Egg Size (mm)"
                        rules={[
                            { required: true, message: "Egg size is required."},
                        ]}>
                        <InputNumber min={0.00} max={10000} step={0.10} placeholder="Egg Size"/>
                    </Form.Item>

                    <Form.Item name="oilGlobuleSize" label="Oil Globule Size (mm)"
                        rules={[
                            { required: true, message: "Oil globule size is required."},
                        ]}>
                        <InputNumber min={0.00} max={10000} step={0.10} placeholder="Oil Globule Size"/>
                    </Form.Item>

                    <Form.Item name="gonadMaturation" label="Gonad Maturation"
                        rules={[
                            { required: true, validator: validateGonadMaturation },
                        ]}>
                        <GonadMaturationSelect onChange={setGonadMaturationId}/>
                    </Form.Item>

                    <Form.Item name="remark" label="Observation" >
                        <TextArea rows={3}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>} loading={uploading}>Save</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Egg Development:"
                    subTitle="Create egg development record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default EggDevelopmentNew