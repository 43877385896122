import { Row, Col } from 'antd'

//----------
// Component
//----------
const ACISFooter = ({breadCrumb}) => {
    return(
        <Row justify="center">
            <Col>{breadCrumb}</Col>
        </Row>
    )
}

export default ACISFooter