import { Layout, Table, Row, Col, Space, Button, Form, Popconfirm, Card, Typography, Modal, DatePicker, message, Tooltip, PageHeader, Descriptions, BackTop, Pagination, Spin } from 'antd'
import { PlusOutlined, LeftOutlined, InfoCircleOutlined, QuestionCircleOutlined, DownloadOutlined, FileImageOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, CRABMARINELIFEPKEY, DATEFORMAT, DATETIMEFORMAT, LOADING, MEDIAMAXWIDTH, MENUPATH_GROWTHDEV, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, previewImage, reportError, showRemark, stringNumberSorter } from "../Common/Utility"
import { formLayout } from "../Common/Layout"
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal

//----------
// Component
//----------
const GrowthDevelopmentTable = () => {
    const FileDownload = require('js-file-download')
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    const [observationLabel, setObservationLabel] = useState(isTabletOrMobile ? "Obs" : "Observation")
    const [observedLabel, setObservedLabel] = useState(isTabletOrMobile ? "Obs." : "Observed")
    const [imageLabel, setImageLabel] = useState(isTabletOrMobile ? "Img" : "Image")
    
    const navigate = useNavigate()
    
    const [batch, setBatch] = useState("")
    const [ageDays, setAgeDays] = useState(0)
    const [dailySamples, setDailySamples] = useState(0)
    const [averageMouthWidth, setAverageMouthWidth] = useState(0)
    const [averageWeight, setAverageWeight] = useState(0)
    const [averageLength, setAverageLength] = useState(0)

    const [batchAverageMouthWidth, setBatchAverageMouthWidth] = useState(0)
    const [batchAverageWeight, setBatchAverageWeight] = useState(0)
    const [batchAverageLength, setBatchAverageLength] = useState(0)
                
    const [growthDevelopmentDataSource, setGrowthDevelopmentDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [observationDateTime, setObservationDateTime] = useState(moment(urlParams.get("observedOn")))
    const [noAgeColor, setNoAgeColor] = useState("")

    let modal = null
    let datePicker = null

    //--------------
    // Table columns
    //--------------
    let desktopColumn = [
        { title: 'Daily Sample No.', dataIndex: 'dailySampleNumber', key: 'dailySampleNumber', sorter: (a, b) => a.dailySampleNumber - b.dailySampleNumber }
    ]

    if(isTabletOrMobile) desktopColumn = []
    const col0 = { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) }
    const col1 = { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) }
    const col2 = { title: 'Tag ID', dataIndex: 'fish', key: 'fish', sorter: (a, b) => a.fish.localeCompare(b.fish) }
    const col3 = { title: 'Mouth Opened On', dataIndex: 'mouthOpenedDateTime', key: 'mouthOpenedDateTime', sorter: (a, b) => a.mouthOpenedDateTime.localeCompare(b.mouthOpenedDateTime) }
    const col4 = { title: 'Mouth Width (mm)', dataIndex: 'mouthWidth', key: 'mouthWidth', align: "right", sorter: (a, b) => stringNumberSorter(a.mouthWidth, b.mouthWidth) }
    const col5 = { title: 'Length (mm)', dataIndex: 'length', key: 'length', align: "right", sorter: (a, b) => stringNumberSorter(a.length, b.length) }
    const col6 = { title: 'Weight (g)', dataIndex: 'weight', key: 'weight', align: "right", sorter: (a, b) => stringNumberSorter(a.weight, b.weight) }
    const col7 = { title: `${observedLabel} On`, dataIndex: 'observationDateTime', key: 'observationDateTime', sorter: (a, b) => a.observationDateTime.localeCompare(b.observationDateTime) }
    const col8 = { title: observationLabel, dataIndex: 'remark', key: 'remark', align: 'center', //sorter: (a, b) => a.length - b.length 
        render: (remark) => {
            if(remark.length > 0)
                return <Tooltip title={remark} >
                        <div onClick={e => showRemark(e, "Observation", remark)}><InfoCircleOutlined style={{ fontSize: '20px', color: "blue"}}/></div>
                    </Tooltip>
            else
                return null
        }
    }
    const col9 = { title: `${imageLabel}`, dataIndex: 'img', key: 'img', align: 'center',
        render: (img, record) => {
            if(img != null) {
                return <Button type="primary" htmlType="button" style={{margin: "0px"}} icon={<FileImageOutlined />} 
                    onClick={(e) => previewImage(e, "", img)} />
            }
            else
                return null
        }
    }

    const defaultColumns = [col0, col1, col2, ...desktopColumn, col3, col4, col5, col6, col7, col8, col9]
    const defaultColumnsCrab = [col0, col1, col2, ...desktopColumn, col6, col7, col8, col9]
    const [columns, setColumns] = useState(defaultColumns)

    //--------------------------
    // Search growth development
    //--------------------------
    const searchGrowthDevelopment = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}growthdevelopment/`, {
            params: { 
                batch: urlParams.get("batchId"),
                observedOn: moment(urlParams.get("observedOn")).format(UNIDATEFORMAT),
                storage: urlParams.get("storageId"),
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search growth development record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        // 20221031
        // Get marine life. Hide average mouth width and average length columns if is crab marine life.
        if(response.data.results.length > 0) {
            if(response.data.results[0].species_data.marine_life_data.pKey == CRABMARINELIFEPKEY) 
                setColumns(defaultColumnsCrab)
            else
                setColumns(defaultColumns)
        }

        const data = []
        response.data.results.forEach( growth => {
            data.push({
                key: growth.pKey,
                id: growth.id,
                batch: growth.batch_data.id,
                storage: growth.storage_data.name,
                fish: growth?.fish_data?.id != null ? growth.fish_data.id : "",
                fishId: growth?.fish_data?.pKey != null ? growth.fish_data.pKey : 0,
                mouthWidth: numberWithCommas(growth.mouth_width),
                weight: numberWithCommas(growth.weight),
                length: numberWithCommas(growth.length),
                mouthOpenedDateTime: moment(growth.mouthOpenedOn).format(DATETIMEFORMAT),
                mouthOpenedDateTimeUnformatted: moment(growth.mouthOpenedOn).format(UNIDATETIMEFORMAT),
                observationDateTime: moment(growth.observedOn).format(DATETIMEFORMAT),
                observationDateTimeUnformatted: moment(growth.observedOn).format(UNIDATETIMEFORMAT),
                dailySampleNumber: growth.daily_sample_number,
                
                batchId: growth.batch_data.pKey,
                batchType: growth.batch_data.batch_type_data.name,
                species: growth.batch_data.species_data.short_name,
                storageId: growth.storage_data.pKey,
                lifecycle: growth.lifecycle_data.name,
                lifecycleId: growth.lifecycle_data.pKey,
                aquacultureStage: growth.aquaculture_stage_data.name,
                aquacultureStageId: growth.aquaculture_stage_data.pKey,
                createdOn: moment(growth.createdOn).format(DATETIMEFORMAT),
                updatedOn: moment(growth.updatedOn).format(DATETIMEFORMAT),
                remark: growth.remark,       
                img: growth.img,                         

                batchAquacultureStageId: growth.batch_data.aquaculture_stage_data.pKey,
                batchAquacultureStage: growth.batch_data.aquaculture_stage_data.name,
                batchLifecycleId: growth.batch_data.lifecycle_data.pKey,
                batchLifecycle: growth.batch_data.lifecycle_data.name,

                // If is growth dev. record for tagged fish, use tagged fish information. Else, use batch infomration.
                batchAverageWeight: growth.fish_data == null ? growth.batch_data.average_per_unit_weight_gram : growth.fish_data.average_per_unit_weight_gram,
                batchAverageLength: growth.fish_data == null ? growth.batch_data.average_per_unit_length_mm : growth.fish_data.average_per_unit_length_mm,
                ageDays: growth.fish_data == null ? growth.batch_data.age_days : growth.fish_data.age_days,
            })

            setBatchAverageMouthWidth(parseFloat(growth.mouth_width).toFixed(2))
            setBatchAverageLength(growth.batch_data.average_per_unit_length_mm)
            setBatchAverageWeight(growth.batch_data.average_per_unit_weight_gram)
        })
        
        setGrowthDevelopmentDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        axios.get(`${ACISAPIURL}growthdevelopment/download/`, {
            params: { 
                batch: urlParams.get("batchId"),
                observedOn: moment(urlParams.get("observedOn")).format(UNIDATEFORMAT),
                storage: urlParams.get("storageId"),
                search_criteria: `Site: ${getUserSiteName()}\nBatch ID: ${urlParams.get("batch")}\nStorage: ${urlParams.get("storage")}\nSample Date: ${moment(urlParams.get("observedOn")).format(DATEFORMAT)}`,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Growth Development Daily Samples ${urlParams.get("batch")} ${urlParams.get("storage")} ${urlParams.get("observedOn")} ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------------
    // Load daily average
    //-------------------
    const getDailyAverageInfo = () => {
        setIsLoading(true)

        //axios.get(`${ACISAPIURL}growthdevelopment/dailyaveragesummary/`, {
        axios.get(`${ACISAPIURL}growthdevelopment/dailyaveragesummarybystorage/`, {
            params: {
                batch: urlParams.get("batchId"),
                storage: urlParams.get("storageId"),
                observedOn: moment(urlParams.get("observedOn")).format(UNIDATEFORMAT),
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.results.length == 0) {
                navigate({ 
                    pathname: MENUPATH_GROWTHDEV,
                })
                return
            }

            // 20221029
            // Should sum and average all records due to same day may have different aquaculture stage and/or lifecycle records.
            // const dailyAverage = response.data.results[0]
            // setBatch(dailyAverage.batchid)       
            // setAgeDays(dailyAverage.ageindays)
            // setDailySamples(dailyAverage.dailysamples)
            // setAverageMouthWidth(dailyAverage.dailyaveragemouthwidth)
            // setAverageWeight(dailyAverage.dailyaverageweight)
            // setAverageLength(dailyAverage.dailyaveragelength)
            let recordCount = response.data.results.length
            let localDailySamples = 0
            let localAverageMouthWidth = 0
            let localAverageWeight = 0
            let localAverageLength = 0
            let localAgeInDays = null

            response.data.results.forEach( dailyAverage => {
                setBatch(dailyAverage.batchid)       
                setAgeDays(dailyAverage.ageindays)
                localAgeInDays = dailyAverage.ageindays

                localDailySamples += parseInt(dailyAverage.dailysamples)
                localAverageMouthWidth += parseFloat(dailyAverage.dailyaveragemouthwidth)
                localAverageWeight += parseFloat(dailyAverage.dailyaverageweight)
                localAverageLength += parseFloat(dailyAverage.dailyaveragelength)
            })

            setDailySamples(localDailySamples)
            setAverageMouthWidth((localAverageMouthWidth / recordCount).toFixed(2))
            setAverageWeight((localAverageWeight / recordCount).toFixed(2))
            setAverageLength((localAverageLength / recordCount).toFixed(2))
            
            if(localAgeInDays == null) setNoAgeColor("Red")
        })
        .catch( error => {
            reportError(error, "Failed to get storage assigned.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchGrowthDevelopment(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //---------------------
    // On birth date change
    //---------------------
    const onObservationDateTimeChange = (datetime) => {
        if(datetime == null) {
            message.warn("Please select a date before proceed.")
            return
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            title: <Space><p>Observation date has changed. Proceed?</p></Space>,
            onOk() { 
                modal.destroy()
                navigate({ 
                    pathname: "/growthdevelopmentstorage",
                    search: `?batchId=${urlParams.get("batchId")}&observedOn=${moment(datetime).format(UNIDATETIMEFORMAT)}&fromMain=true`
                })        
            },
            onCancel() { modal.destroy() },
            centered: true,
        })
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        const pathname = "/growthdevelopmentstorage"
        const search = `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&observedOn=${moment(urlParams.get("observedOn")).format(UNIDATETIMEFORMAT)}&fromMain=true`
        datePicker = <DatePicker onChange={onObservationDateTimeChange} defaultValue={moment(urlParams.get("observedOn"))} format={DATEFORMAT} style={{width: parseInt(OTHERSYSPARAM("DATE_PICKER_WIDTH"))}}/>

        if(OTHERSYSPARAM("ALLOW_GROWTH_DEV_SAMPLE_CHANGE_DATE") == 1)
            modal = confirm({
                icon: <InfoCircleOutlined />,
                title: <Space><p>Please select an observation date.</p></Space>,
                onOk() { navigate({ pathname: pathname, search: search }) },
                onCancel() {},
                centered: true,
                content: datePicker
            })
        else
            navigate({ pathname: pathname, search: search })
    }

    //------------------
    // On back
    //------------------
    const onBack = () => {
        navigate({ 
            //pathname: MENUPATH_GROWTHDEV,
            pathname: "/growthdevelopmentdailyaveragebystorage",
            search: `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&observedOn=${urlParams.get("observedOn")}&averageMouthWidth=${batchAverageMouthWidth}&averageLength=${batchAverageLength}&averageWeight=${batchAverageWeight}`
        })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/growthdevelopmentupdate", 
                    search: `?pKey=${record.key}&id=${record.id}&batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&species=${record.species}&storageId=${record.storageId}&storage=${record.storage}&aquacultureStageId=${record.aquacultureStageId}&aquacultureStage=${record.aquacultureStage}&lifecycleId=${record.lifecycleId}&lifecycle=${record.lifecycle}&fishId=${record.fishId}&fish=${record.fish}&mouthOpenedOn=${record.mouthOpenedDateTimeUnformatted}&observedOn=${record.observationDateTimeUnformatted}&mouthWidth=${record.mouthWidth.replace(',', '')}&weight=${record.weight.replace(',', '')}&length=${record.length.replace(',', '')}&createdOn=${record.createdOn}&updatedOn=${record.updatedOn}&batchAquacultureStageId=${record.batchAquacultureStageId}&batchAquacultureStage=${record.batchAquacultureStage}&batchLifecycleId=${record.batchLifecycleId}&batchLifecycle=${record.batchLifecycle}&batchAverageWeight=${record.batchAverageWeight}&batchAverageLength=${record.batchAverageLength}&ageDays=${record.ageDays}&remark=${encodeURI(String(record.remark).trim())}&dailySampleNumber=${record.dailySampleNumber}&img=${record.img}`
                }) 
            }
        }
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getDailyAverageInfo()
        searchGrowthDevelopment(currentPage)
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Growth Development">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update growth development record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{batch}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Sampled Age (days):</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{color: noAgeColor}}>{ageDays != null ? numberWithCommas(ageDays) : OTHERSYSPARAM("NA")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Daily Samples Count:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{dailySamples}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Daily Average Mouth Width(mm) by Aquaculture. Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(averageMouthWidth)}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Daily Average Weight(g) by Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(averageWeight)}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Daily Average Length(mm) by Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(averageLength)}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Observation Date:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{moment(observationDateTime).format(DATEFORMAT)}</Card.Grid>
                        </Card>
                    </Form.Item>
                </Form>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={1} />
                    <Col span={7} style={{textAlign: "start"}}>
                        <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                            <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={8} style={{textAlign: "center"}}>
                        <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                        <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                    </Col>
                    <Col span={7} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
                    <Col span={1} />
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={8} />
                    <Col span={8} style={{textAlign: "center"}}>
                        <Card title="Growth Development Samples" style={{textAlign: "left"}}>
                            {`Date: ${moment(observationDateTime).format(DATEFORMAT)}`}
                        </Card>
                    </Col>
                    <Col span={8} />
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={22}>
                        <Table bordered columns={columns} dataSource={growthDevelopmentDataSource} pagination={false} onRow={onRowClick}/>
                    </Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={1} />
                    <Col span={3} style={{textAlign: "start"}}>
                        <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                            <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={16} style={{textAlign: "center"}}>
                        <Pagination
                            size={PAGINATIONSIZE}
                            total={totalRecord}
                            showTotal={showTotal}
                            pageSize={PAGESIZE}
                            current={currentPage}
                            hideOnSinglePage={false}
                            showSizeChanger={false}
                            onChange={onPaginationChange}/>
                    </Col>
                    <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
                    <Col span={1} />
                </Row>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader
                        onBack={() => onBack()}
                        title="Growth Development:"
                        subTitle="Growth development samples" />} />
            </Footer>

            <BackTop />
        </Layout>
        </Spin>
        </>
    )
}

export default GrowthDevelopmentTable