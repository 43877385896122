import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, message, Space, Layout, Row, Col, InputNumber, PageHeader, Modal, Descriptions, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { LOADING, MENUPATH_STORAGE } from '../Common/SystemParameter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { ACISAPIURL } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import StorageTypeSelect from '../Common/StorageTypeSelect'
import StorageUsageTypeSelect from '../Common/StorageUsageTypeSelect'
import { formLayout } from '../Common/Layout'

const { Header, Footer, Content } = Layout
const { confirm } = Modal

//----------
// Component
//----------
const StorageNew = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [storageTypeId, setStorageTypeId] = useState(0)
    const [storageUsageTypeId, setStorageUsageTypeId] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    
    //-------------------
    // Create new storage
    //-------------------
    const createStorage = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}storage/create/`, {
                site: getUserSiteId(),
                storage_type: storageTypeId,
                storage_usage_type: storageUsageTypeId,
                id: "",
                name: values.storageName,
                capacity: values.capacity,
                dimension: values.dimension,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`New storage ${values.storageName} created.`)
                navigate({ pathname: MENUPATH_STORAGE })
            })
            .catch( error => {
                reportError(error, "Failed to create new storage.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //----------------------
    // Cancel create storage
    //----------------------
    const onCancel = () => {
        navigate({ pathname: MENUPATH_STORAGE })
    }

    //----------------------
    // Cancel create storage
    //----------------------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Create storage confirmed?</p></Space>,
            onOk() { createStorage() },
            onCancel() {},
            centered: true
        })
    }

    //-------------------------------
    // Storage type custom validation
    //-------------------------------
    const validateStorageType = (() => {
        if (storageTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Storage type is required."))
    })

    const validateStorageUsageType = (() => {
        if (storageUsageTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Storage usage type is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
    })

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Storage">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create new storage</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                <Form.Item name="storageType" label="Storage Type"
                        rules={[
                            { required: true, validator: validateStorageType },
                        ]}>
                        <StorageTypeSelect withBlank={false} onChange={setStorageTypeId}/>
                    </Form.Item>

                    <Form.Item name="storageUsageType" label="Usage Type"
                        rules={[
                            { required: true, validator: validateStorageUsageType },
                        ]}>
                        <StorageUsageTypeSelect withBlank={false} onChange={setStorageUsageTypeId}/>
                    </Form.Item>

                    <Form.Item name="storageName" label="New Storage Name"
                        rules={[
                            { required: true, message: "Storage name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Storage Name"/>
                    </Form.Item>

                    <Form.Item name="dimension" label="Dimension"
                        rules={[
                            { required: true, message: "Dimension is required."},
                        ]}>
                        <Input maxLength={20} placeholder="Dimension"/>
                    </Form.Item>

                    <Form.Item name="capacity" label="Capacity (L):"
                        rules={[
                            { required: true, message: "Storage capacity is required."},
                        ]}>
                        <InputNumber min={1} max={50000} step={100} placeholder="Capacity"/>
                    </Form.Item>
                
                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Save</Button>
                            <Button type="primary" htmlType="button" onClick={onCancel} disabled={disableButton} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Storage:"
                        subTitle="Create new storage"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default StorageNew