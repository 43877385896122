import { Table, Row, Col, Space, Button, Form, Collapse, message, Tag, Modal, Pagination, Spin } from 'antd'
import { RightOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, LOADING, PAGESIZE, PAGINATIONSIZE } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, SYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError } from '../Common/Utility'
import { formLayout_2Columns } from '../Common/Layout'
import TrueFalseSelect from "../Common/TrueFalseSelect"
import GenderSelect from '../Common/GenderSelect'
import { BatchColumn, DesktopColumns, RFIDColumn } from '../Common/CommonTableColumns'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { confirm } = Modal

//----------
// Component
//----------
const TransferFishTable = ({interSite}) => {
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [genderId, setGenderId] = useState(0)
    const [isBrood, setIsBrood] = useState("")
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [speciesId, setSpeciesId] = useState(0)
    const [storageId, setStorageId] = useState(0)
    const [batchId, setBatchId] = useState(0)
    const [fishId, setFishId] = useState(0)
    const navigate = useNavigate()
    const [selectedFishKeys, setSelectedFishKeys] = useState([])
    const [selectedFish, setSelectedFish] = useState([])
                
    const [fishDataSource, setFishDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------
    // Search fish
    //---------------
    const searchFish = async (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)

        axios.get(`${ACISAPIURL}fish/`, {
            params: { 
                site: getUserSiteId(),
                marine_life: marineLifeId,
                species: speciesId,
                id: fishId,
                storage: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : storageId,
                gender: genderId,
                isBrood: isBrood,
                batch: batchId,
                active: true,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search fish list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( fish => {
            data.push({
                key: fish.pKey,
                id: fish.id,
                species: fish.species_data.short_name,
                speciesId: fish.species_data.pKey,
                acquiringMethod: fish.batch_data.acquiring_method_data.name,
                aquacultureStage: fish.aquaculture_stage_data.name,
                storage: fish.storage_data.name,
                storageId: fish.storage_data.pKey,
                lifecycle: fish.lifecycle_data.name,
                weight: numberWithCommas(fish.average_per_unit_weight_gram),
                genderId: fish.gender_data.pKey,
                gender: fish.gender_data.name,
                tagId: fish.tag_id != null ? fish.tag_id : "",
                isBrood: fish.isBrood,
                batch: fish.batch_data.id,
                active: fish.active,
                averageWeight: fish.average_per_unit_weight_gram,
            })
        })
        
        setFishDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFish(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        if(marineLifeId == 0 && speciesId == 0 && batchId == 0 && storageId == 0) {
            message.info("Please select a marine life, species, batch or storage before search.")
            return
        }

        setCurrentPage(1)
        searchFish(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //--------
    // On next
    //--------
    const onNext = () => {
        if(selectedFishKeys.length == 0) {
            message.warn("Please select fish to proceed.")
            return
        }

        // Must not select more than one species.
        let speciesSet = new Set(selectedFish.map( fish => fish.speciesId))
        if(speciesSet.size > 1) {
            message.warn("Mixing species into a storage is not allowed.")
            return
        }

        // Must not select from more than one batch.
        let batchSet = new Set(selectedFish.map( fish => fish.batchId))
        if(batchSet.size > 1) {
            message.warn("Please do not select tagged marine life from more than one batch.")
            return
        }

        // Must not select from more than one storage.
        let storageSet = new Set(selectedFish.map( fish => fish.storageId))
        if(storageSet.size > 1) {
            message.warn("Please do not select tagged marine life from more than one storage.")
            return
        }

        let allWeight = selectedFish.map( fish => parseInt(fish.averageWeight) >= 0 ? parseInt(fish.averageWeight) : 0)
        const reducer = (accumulator, curr) => accumulator + curr
        let averageWeight = allWeight.reduce(reducer) / selectedFish.length

        const search = `?fishIds=${selectedFishKeys}&interSite=${interSite}&averageWeight=${averageWeight}&storageId=${selectedFish[0].storageId}&totalWeight=${allWeight.reduce(reducer)}`
        
        // If mixing acquiring method, aquaculture stage or lifecycle. Prompt.
        let mixingFound = ""
        let acquiringMethodSet = new Set(selectedFish.map( fish => fish.acquiringMethodId))
        if(acquiringMethodSet.size > 1) mixingFound = "Acquiring Method"

        let aquacultureStageSet = new Set(selectedFish.map( fish => fish.aquacultureStageId))
        if(aquacultureStageSet.size > 1) {
            if(mixingFound.length > 0) mixingFound = `${mixingFound}, `
            mixingFound = `${mixingFound} Aquaculture Stage`
        }

        let lifecycleSet = new Set(selectedFish.map( fish => fish.lifecycleId))
        if(lifecycleSet.size > 1) {
            if(mixingFound.length > 0) mixingFound = `${mixingFound}, `
            mixingFound = `${mixingFound} Lifecycle`
        }

        if(mixingFound.length > 0) 
            confirm({
                icon: <QuestionCircleOutlined />,
                content: <Space><p>{`Mixing of ${mixingFound} detected. Proceed?`}</p></Space>,
                onOk() { 
                    navigate({ 
                        pathname: "/transferfishstorage", 
                        search: search
                    }) 
                },
                onCancel() {},
                okText: OTHERSYSPARAM("YES"),
                cancelText: OTHERSYSPARAM("NO"),
                centered: true
            })
        else
            navigate({ 
                pathname: "/transferfishstorage", 
                search: search
            }) 
    }

    //---------------------------
    // On fish selection change
    //---------------------------
    const onSelectionChange = (selectedRowKeys, selectedRows) => {
        setSelectedFishKeys(selectedRowKeys)
        setSelectedFish(selectedRows)
    }

    const rowSelection = {
        selectedFishKeys,
        onChange: onSelectionChange
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        // This page always needs pass in storageId in URL search params
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchFish(currentPage, urlParams.get("storageId"))
        }
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        BatchColumn(),
        { title: 'Storage ID', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Tag ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        ...DesktopColumns(),
        { title: 'Weight (g)', dataIndex: 'weight', key: 'weight', align: "right", sorter: (a, b) => a.weight - b.weight },
        { title: 'Gender', dataIndex: 'genderId', key: 'genderId', sorter: (a, b) => a.genderId - b.genderId,
            render: (genderId, record) => {
                if(genderId == SYSPARAM("Male"))
                    return <Tag color="blue">{record.gender}</Tag>
                else if(genderId == SYSPARAM("Female"))
                    return <Tag color="red">{record.gender}</Tag>
                else 
                    return <Tag color="green">{record.gender}</Tag>
            }
        },
        { title: 'Is Brood', dataIndex: 'isBrood', key: 'isBrood', sorter: (a, b) => a.isBrood - b.isBrood,
            render: (isBrood) => {
                if(isBrood)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        RFIDColumn()
        /*{ title: 'Status', dataIndex: 'active', key: 'active', 
            render: (active) => {
                if(active)
                    return <Tag color="blue">{OTHERSYSPARAM("ACTIVE")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("INTIVE")}</Tag>
            }
        },*/
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Fish Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <CommonSearchFormItem onMarineLifeChange={setMarineLifeId} onSpeciesChange={setSpeciesId} onBatchChange={setBatchId} 
                                onStorageChange={setStorageId} onTagIdChange={setFishId} activeTaggedOnly={true} showLockedStorage={false} defaultStorageId={storageId}/>

                            <Form.Item name="gender" label="Gender">
                                <GenderSelect withBlank={true} onChange={setGenderId}/>
                            </Form.Item>

                            <Form.Item name="isBrood" label="Is Brood">
                                <TrueFalseSelect withBlank={true} onChange={setIsBrood}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row>
            <Col span={12} />
            <Col span={12} style={{textAlign: "right"}}>
                <Button type="primary" htmlType="button" onClick={onNext} icon={<RightOutlined />}>Next</Button>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={fishDataSource} pagination={false} rowSelection={rowSelection} /*onRow={onRowClick}*/ />

        <Row><Col><Space><div /></Space></Col></Row>

        <Row>
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "right"}}>
                <Button type="primary" htmlType="button" onClick={onNext} icon={<RightOutlined />}>Next</Button>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default TransferFishTable