import { Table, Row, Col, Space, Button, Form, Collapse, Upload, DatePicker, Tag, message, Popconfirm, Tooltip, Pagination, Modal, Spin } from 'antd'
import { PlusOutlined, DeleteOutlined, PrinterOutlined, ProfileOutlined, UploadOutlined, QuestionCircleOutlined, FileExcelOutlined, DownloadOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT,  LOADING,  MENUPATH_STOCKCOUNTREPORT,  PAGESIZE,  PAGINATIONSIZE,  UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { reportError } from '../Common/Utility'
import { formLayout_2Columns } from '../Common/Layout'
import moment from 'moment'
import { generateStockPreCountPdf } from './generateStockPreCountSummaryPdf'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { RangePicker } = DatePicker
const { confirm } = Modal

//----------
// Component
//----------
const StockCountTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const navigate = useNavigate()
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    
    const [stockCountDataSource, setStockCountDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //-------------------
    // Search stock count
    //-------------------
    const searchStockCount = (currentPage, urlParamSearchStoragId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}stockcount/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: urlParamSearchStoragId != 0 ? urlParamSearchStoragId : storageId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search stock count list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Common push data to table
    //--------------------------
    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( stockCount => {
            data.push({
                key: stockCount.pKey,
                batch: stockCount.batch_data.id,
                batchId: stockCount.batch_data.pKey,
                storage: stockCount.storage_data.name,
                storageId: stockCount.storage_data.pKey,
                
                completedOn: stockCount.completedOn == null ? "" : moment(stockCount.completedOn).format(DATETIMEFORMAT),
                completed: stockCount.completedOn != null ? true : false,
                createdOn: moment(stockCount.createdOn).format(DATETIMEFORMAT),
                updatedOn: moment(stockCount.updatedOn).format(DATETIMEFORMAT),

                marineLifeId: stockCount.marine_life_data.pKey,
            })
        })
        
        setStockCountDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
                                
        axios.get(`${ACISAPIURL}stockcount/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Stock Count ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Delete stock count record
    //--------------------------
    const deleteStockCountRecord = (e, record) => {
        e.stopPropagation()
        setIsLoading(true)

        axios.delete(`${ACISAPIURL}stockcount/delete/${record.key}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Stock count record deleted.`)
            //setStockCountDataSource(stockCountDataSource.filter((item) => item.key !== record.key))
            window.location.reload()
        })
        .catch( error => {
            reportError(error, "Failed to delete stock count record.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //------------------------
    // Print pre count summary
    //------------------------
    const printPreCountSummary = (e, record) => {
        e.stopPropagation()
        generateStockPreCountPdf(record.key, record.batchId, record.batch, record.storagId, record.storage, record.createdOn)
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStockCount(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStockCount(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        let params = new URLSearchParams(`?pKey=${record.key}&batchId=${record.batchId}&batch=${record.batch}&storageId=${record.storageId}&storage=${record.storage}&createdOn=${record.createdOn}&updatedOn=${record.updatedOn}&completedOn=${record.completedOn}&marineLifeId=${record.marineLifeId}`)
        let pathname = "/stockprecountsummary"
        if(record.completedOn != "") pathname = "/stockcountsummary"

        return {
            onClick: () => { 

                navigate({ pathname: pathname, search: params.toString() }) 
            }
        }
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ pathname: "/stockcountstorage" })
    }

    //---------------------
    // On change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    const onBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    //------------------------
    // Redirect to report page
    //------------------------
    const onGoToReport = () => {
        navigate({ pathname: MENUPATH_STOCKCOUNTREPORT })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchStockCount(currentPage, urlParams.get("storageId"))
        }
        else
            searchStockCount(currentPage)
    }, [])

    //-----------------------------
    // Upload json file validation
    //-----------------------------
    const props = {
        name: 'json_file',
        action: `${ACISAPIURL}stockcount/json/upload/`,
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        beforeUpload: file => {
            if (file.type !== "application/json") 
                message.error(`${file.name} is not a json file.`)

            return (file.type === "application/json")? true : Upload.LIST_IGNORE
        },
        onChange(info) {
            setUploading(true)

            if(info.file.status !== 'uploading') {
                setUploading(false)
                setIsLoading(true)
            }

            if(info.file.status === 'done') {
                setUploading(false)
                setIsLoading(false)

                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>"{info.file.name}" uploaded successfully. {info.file.response.detail}</p></Space>,
                    onOk() { window.location.reload() },
                    cancelButtonProps: {style: {display: "none"} },
                    centered: true
                })
            }
            else if(info.file.status === 'error') {
                setUploading(false)
                message.error(`"${info.file.name}" upload failed.`)
                message.error(info.file.response?.detail)
            }
        },
    }

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage ID', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Created On', dataIndex: 'createdOn', key: 'createdOn', sorter: (a, b) => a.createdOn.localeCompare(b.createdOn) },
        { title: 'Updated On', dataIndex: 'updatedOn', key: 'updatedOn', sorter: (a, b) => a.updatedOn.localeCompare(b.updatedOn) },
        { title: 'Completed On', dataIndex: 'completedOn', key: 'completedOn', sorter: (a, b) => a.completedOn.localeCompare(b.completedOn) },
        { title: 'Status', dataIndex: 'completed', key: 'completed', sorter: (a, b) => a.completed - b.completed,
            render: (completedOn) => {
                if(completedOn != "")
                    return <Tag color="blue">Completed</Tag>
                else
                    return <Tag color="red">In Progress</Tag>
            }
        },
        { key: 'action', align: 'center',
            render: (record) => {
                if(record.completedOn != "")
                    return null
                else
                    return <>
                        <Tooltip title="Print stock count form">
                            <Button type="primary" disabled={disableButton} icon={<PrinterOutlined/>} onClick={e => printPreCountSummary(e, record)} />
                        </Tooltip>
                        <Popconfirm title="Delete in-progress stock count record confirmed?" 
                            onClick={e => e.stopPropagation()} onCancel={e => e.stopPropagation()} onConfirm={e => deleteStockCountRecord(e, record)} 
                            okText="Yes" cancelText="No">
                            <Button danger type="primary" disabled={disableButton} icon={<DeleteOutlined/>} />
                        </Popconfirm>
                    </>
            }
        }
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Stock Count Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onBatchChange={onBatchChange} onStorageChange={onStorageChange} showLockedStorage={true} defaultStorageId={storageId}/>
                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={8} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onGoToReport} icon={<ProfileOutlined />}>Report</Button></Col>
            <Col span={16} style={{textAlign: "end"}}>
                <Row justify="end">
                    <Upload {...props} accept="application/json">
                        <Button type="primary" htmlType="button" icon={<UploadOutlined />} disabled={disableButton} loading={uploading}>
                            <Tooltip title="Upload fish count json file.">
                                Upload Json File
                            </Tooltip>
                        </Button>
                    </Upload>
                    <Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button>
                </Row>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={stockCountDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={8} style={{textAlign: "start"}}><Button type="primary" htmlType="button" onClick={onGoToReport} icon={<ProfileOutlined />}>Report</Button></Col>
            <Col span={8} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={8} style={{textAlign: "end"}}>
            <Row justify="end">
                    <Upload {...props} accept="application/json">
                        <Button type="primary" htmlType="button" icon={<UploadOutlined />} disabled={disableButton} loading={uploading}>
                            <Tooltip title="Upload fish count json file.">
                                Upload Json File
                            </Tooltip>
                        </Button>
                    </Upload>
                    <Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button>
                </Row>    
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default StockCountTable