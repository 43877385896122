import { Table, Row, Col, Space, Button, Form, Collapse, message, Pagination, Select, Spin } from 'antd'
import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import BatchTypeSelect from '../Common/BatchTypeSelect'
import WeightRangeInput from '../Common/WeightRangeInput'
import DateRangeInput from '../Common/DateRangeInput'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { Option } = Select

//----------
// Component
//----------
const InventoryForecastTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [dateRange, setDateRange] = useState([moment(moment().add(1, "days"), UNIDATEFORMAT), null])
    const [committedDateRange, setCommittedDateRange] = useState([moment(moment().add(1, "days"), UNIDATEFORMAT), null])
        
    const [weightRange, setWeightRange] = useState([-1, -1]) // Cannot use [0, 0] because 0 is valid input.
    const [marineLifeId, setMarineLifeId] = useState(0)
    const [marineLife, setMarineLife] = useState("")
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [batchTypeId, setBatchTypeId] = useState(200)
    const [batchType, setBatchType] = useState("Stock")
    const [fishId, setFishId] = useState(0)
    const [fish, setFish] = useState("")
    const [committedFishId, setCommittedFishId] = useState(0)
    const [committedFish, setCommittedFish] = useState("")
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    
    const [speciesSummaryDataSource, setSpeciesSummaryDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const [storageOption, setStorageOption] = useState([])
    
    //------------------------
    // Search forecast summary
    //------------------------
    const searchInventoryForecastSummary = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)

        // Sanitize date range
        let fromDate = dateRange[0] != null ? moment(dateRange[0]).format(UNIDATEFORMAT) : null
        let toDate = dateRange[1] != null ? moment(dateRange[1]).format(UNIDATEFORMAT) : null

        axios.get(`${ACISAPIURL}inventory/forecast/summary/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: fromDate,
                toDate: toDate,
                batch: batchId,
                storage: storageId,
                marine_life: marineLifeId,
                species: speciesId,
                batch_type: batchTypeId,
                from_weight: weightRange[0],
                to_weight: weightRange[1],
                fish: fishId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            pushData(response)

            // Commit query data
            setCommittedDateRange(dateRange)
            setCommittedFishId(fishId)
            setCommittedFish(fish)
        })
        .catch( error => {
            reportError(error, "Failed to search forecasted summary.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    const pushData = (response) => {
        const data = []
        response.data.results.forEach( summary => {
            data.push({
                species: summary.species_name,
                speciesId: summary.species_pKey,
                numOfBatches: numberWithCommas(summary.num_of_batches),
                numOfStorages: numberWithCommas(summary.num_of_storages),
                totalQty: numberWithCommas(summary.total_quantity),
                totalWeightKg: numberWithCommas(summary.total_weight_kg),
            })
        })
        
        setSpeciesSummaryDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }
    
    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        if(dateRange[0] != null) searchCriteria = `${searchCriteria}\nDate >= ${moment(dateRange[0]).format(DATEFORMAT)}`
        if(dateRange[1] != null) searchCriteria = `${searchCriteria}\nDate <= ${moment(dateRange[1]).format(DATEFORMAT)}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(fishId != 0) searchCriteria = `${searchCriteria}\nTag ID: ${fish}`
        if(batchTypeId != 0) searchCriteria = `${searchCriteria}\nBatch Type: ${batchType}`
        if(weightRange[0] >= 0) searchCriteria = `${searchCriteria}\nWeight >= ${numberWithCommas(weightRange[0])}g`
        if(weightRange[1] >=0) searchCriteria = `${searchCriteria}\nWeight <= ${numberWithCommas(weightRange[1])}g`
                
        // Sanitize date range
        let fromDate = dateRange[0] != null ? moment(dateRange[0]).format(UNIDATEFORMAT) : null
        let toDate = dateRange[1] != null ? moment(dateRange[1]).format(UNIDATEFORMAT) : null

        axios.get(`${ACISAPIURL}inventory/forecast/summary/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: fromDate,
                toDate: toDate,
                batch: batchId,
                storage: storageId,
                marine_life: marineLifeId,
                species: speciesId,
                batch_type: batchTypeId,
                from_weight: weightRange[0],
                to_weight: weightRange[1],
                fish: fishId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Inventory Forecast ${now}.${ext}`)

                // Commit date range
                setCommittedDateRange(dateRange)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-------------
    // Load Storage
    //-------------
    const getStorage = (batchId) => {
        axios.get(`${ACISAPIURL}storagefull/`, {
            params: {
                site: getUserSiteId(),
                batch: batchId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( storage => <Option key={storage.pKey}>{storage.id}</Option>))
            setStorageOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get storage.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchInventoryForecastSummary(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchInventoryForecastSummary(1)
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (fromDate, toDate) => {
        setDateRange([fromDate, toDate])
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        let fromDate = committedDateRange[0] != null ? moment(committedDateRange[0]).format(UNIDATEFORMAT) : ''
        let toDate = committedDateRange[1] != null ? moment(committedDateRange[1]).format(UNIDATEFORMAT) : ''
        
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/inventoryforecastbatchstorage", 
                    search: `?species=${record.species}&numOfBatches=${record.numOfBatches}&numOfStorages=${record.numOfStorages}&totalQty=${record.totalQty}&totalWeightKg=${record.totalWeightKg}&fromDate=${fromDate}&toDate=${toDate}&searchBatchId=${batchId}&searchBatch=${batch}&searchStorageId=${storageId}&searchStorage=${storage}&marineLifeId=${marineLifeId}&marineLife=${marineLife}&speciesId=${record.speciesId}&species=${record.species}&batchTypeId=${batchTypeId}&batchType=${batchType}&fromWeight=${weightRange[0]}&toWeight=${weightRange[1]}&fishId=${committedFishId}&fish=${committedFish}`
                }) 
            }
        }
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    const onBatchTypeChange = (e, value) => {
        setBatchTypeId(e)
        setBatchType(value)
    }

    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)

        getStorage(e)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onWeightRangeChange = (fromWeight, toWeight) => {
        setWeightRange([fromWeight, toWeight])
    }
    
    const onSearchFishIdChange = (e, value) => {
        setFishId(e)
        setFish(value.children)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        //getStorage(0)
        //searchInventoryForecastSummary(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const summary_columns = [
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Number of Batches', dataIndex: 'numOfBatches', key: 'numOfBatches', align: 'right', sorter: (a, b) => stringNumberSorter(a.numOfBatches, b.numOfBatches) },
        { title: 'Number of Storages', dataIndex: 'numOfStorages', key: 'numOfStorages', align: 'right', sorter: (a, b) => stringNumberSorter(a.numOfStorages, b.numOfStorages) },
        { title: 'Total Qty.', dataIndex: 'totalQty', key: 'totalQty', align: 'right', sorter: (a, b) => stringNumberSorter(a.totalQty, b.totalQty) },
        { title: 'Total Weight (Kg)', dataIndex: 'totalWeightKg', key: 'totalWeightKg', align: 'right', sorter: (a, b) => stringNumberSorter(a.totalWeightKg, b.totalWeightKg) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Inventory Forecast Data Filter" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range">
                                <DateRangeInput 
                                    from={dateRange[0]} 
                                    disabledFromDate={d => !d || d.isSameOrBefore(moment().add(1, "days").format(UNIDATEFORMAT))} 
                                    disabledToDate={d => !d || d.isSameOrBefore(moment().add(1, "days").format(UNIDATEFORMAT))} 
                                    onChange={onDateRangeChange}/>
                            </Form.Item>

                            <CommonSearchFormItem onMarineLifeChange={onMarineLifeChange} onSpeciesChange={onSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} onTagIdChange={onSearchFishIdChange} activeTaggedOnly={true} showLockedStorage={true}/>

                            <Form.Item name="batchTypeId" label="Batch Type">
                                <BatchTypeSelect withBlank={true} defaultValue={batchType} onChange={onBatchTypeChange}/>
                            </Form.Item>

                            <Form.Item name="weightRange" label="Weight Range">
                                <WeightRangeInput /*from={FROMWEIGHT} to={TOWEIGHT}*/ onChange={onWeightRangeChange}/>
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form> 
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        
        <Table bordered columns={summary_columns} dataSource={speciesSummaryDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} />
        </Row>
        </Spin>
        </>
    )
}

export default InventoryForecastTable
