import { Table, Row, Col, Space  } from 'antd'
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL } from "../Common/SystemParameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { reportError } from "../Common/Utility"


//----------
// Component
//----------
const OtherSystemParameterTable = () => {
    const [otherSystemDataSource, setOtherSystemDataSource] = useState([])
    
    const getOtherSystemParameter = () => {
        //-------------
        // Load other system parameter
        //-------------
        axios.get(`${ACISAPIURL}othersystemparameter/`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let data = []
            let num = 0
            response.data.results.forEach( param => {
                num++
                data.push({
                    key: param.pKey,
                    number: `${num}.`,
                    paramName: param.name, 
                    value: param.value
                })
            })
            
            setOtherSystemDataSource(data)
        })
        .catch( error => {
            reportError(error, "Failed to load system parameters: othersystemparameter")
            throw error
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getOtherSystemParameter()
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'No.', dataIndex: 'number', key: 'number', sorter: (a, b) => a.number.localeCompare(b.number) },
        { title: 'Parameter Name', dataIndex: 'paramName', key: 'paramName', sorter: (a, b) => a.paramName.localeCompare(b.paramName) },
        { title: 'String Value', dataIndex: 'value', key: 'value', sorter: (a, b) => a.value.localeCompare(b.value) },
    ]

    return(
        <>
        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={24}>
                <Table bordered columns={columns} dataSource={otherSystemDataSource} pagination={false} />
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>
        </>
    )
}

export default OtherSystemParameterTable