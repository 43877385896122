import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { reportError, standardItemTypeUnitOfMeasurementLabel } from "./Utility"
import axios from 'axios'
import { ACISAPIURL } from './SystemParameter'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from './UserSession'

const { Option } = Select

//----------
// Component
//----------
const ItemTypeUnitOfMeasurementSelect = ({withBlank, defaultValue, marineLifeId, itemType, excludeItemType, excludeUom, onChange}) => {
    const [itemTypeUnitOfMeasurementOption, setItemTypeUnitOfMeasurementOption] = useState([])
    
    //---------
    // Load all
    //---------
    const getAllItemTypeUnitOfMeasurement = () => {
        axios.get(`${ACISAPIURL}itemtypeunitofmeasurement/`, { 
            params: {
                marine_life: marineLifeId,
                item_type: itemType,    // String: one word only. Tagged, Untagged or Egg
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(excludeItemType != undefined) response.data.results = response.data.results.filter( ituom => !ituom.item_type_data.name.startsWith(excludeItemType) )
            if(excludeUom != undefined) response.data.results = response.data.results.filter( ituom => !ituom.uom_data.uom.includes(excludeUom) )
            
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( ituom => <Option key={ituom.pKey}>{standardItemTypeUnitOfMeasurementLabel(ituom)}</Option> ))
            setItemTypeUnitOfMeasurementOption(options)
        })
        .catch( error => {
            reportError(error, "<ItemTypeUnitOfMeasurementSelect /> Failed to get item type - unit of measurement.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }
    
    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
        if(e != undefined && value != undefined)
            onChange(e, value)
        else
            onChange(0, "")
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllItemTypeUnitOfMeasurement()
    }, []) 
    
    return(
        <Select defaultValue={defaultValue} style={{width: parseInt(OTHERSYSPARAM("IT_UOM_OPTION_WIDTH"))}} onChange={(e, value) => onSelectionChange(e, value)}
            allowClear showSearch optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {itemTypeUnitOfMeasurementOption}
        </Select>
    )
}

export default ItemTypeUnitOfMeasurementSelect