import moment from "moment"
import { getBase64ImageFromURL } from "./getBase64ImageFromURL"
import { DATETIMEFORMAT, LOGO } from "./SystemParameter"


export const PAGESIZE = "A4"
export const PAGEMARGINS = [ 30, 30, 30, 30 ]

export const FOOTER = (currentPage, pageCount) => { 
    return { 
        layout: 'noBorders',
        table: {
            widths: [ '3%', '47%', '47%', '3%'],
            body: [
                [ 
                    { text: ''},
                    { text: `${moment().format(DATETIMEFORMAT)}`, alignment: "left", fontSize: 8, },
                    { text: currentPage.toString() + ' of ' + pageCount, alignment: "right", alignment: "right", fontSize: 8, },
                    { text: ''}
                ]
            ]
        }
    }
}

export const TABLESTYLES = {
    reportTitle: {
        fontSize: 14,
        bold: true,
        italics: true,
        //decoration: "underline",
        alignment: "right",
        margin: [0, 15, 0, 15]
    },

    tableHeaderRight: {
        fontSize: 9,
        bold: true,
        alignment: "right",
        margin: [0, 3, 0, 3],
    },

    tableHeaderCenter: {
        fontSize: 9,
        bold: true,
        alignment: "center",
        margin: [0, 3, 0, 3]
    },

    tableHeaderLeft: {
        fontSize: 9,
        bold: true,
        alignment: "left",
        margin: [0, 3, 0, 3]
    },

    tableCellLeft: {
        fontSize: 9,
        bold: false,
        alignment: "left",
        margin: [0, 3, 0, 3]
    },

    tableCellCenter: {
        fontSize: 9,
        bold: false,
        alignment: "center",
        margin: [0, 3, 0, 3]
    },
}

export const reportTitle = async (title) => {
    let img = await getBase64ImageFromURL(`${LOGO}`)

    return ({
        layout: 'noBorders',
        table: {
            widths: [ '50%', '50%'],
            body: [
                [ 
                    { 
                        image: img,
                        height: 20,
                        width: 60,
                        alignment: "left",
                        margin: [0, 0, 0, 10]
                    },
                    { 
                        text: title, 
                        style: TABLESTYLES.reportTitle, 
                        margin: [0, 0, 0, 0]
                    },
                ]
            ]
        },
    })
}