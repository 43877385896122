import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, message, Space, Layout, Row, Col, Popconfirm, PageHeader, Modal, Descriptions, Spin } from 'antd'
import { SaveOutlined, CloseOutlined, DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { LOADING, MENUPATH_SITE } from '../Common/SystemParameter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { ACISAPIURL } from '../Common/SystemParameter'
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import LocationSelect from '../Common/LocationSelect'

const { Header, Footer, Content } = Layout
const { confirm } = Modal

//----------
// Component
//----------
const SiteUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
            
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [hideDeleteButton, setHideDeleteButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
                           
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    const [locationId, setLocationId] = useState(urlParams.get("locationId"))

    //---------------
    // Update location
    //---------------
    const updateSite = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}site/update/${urlParams.get("pKey")}/`, {
                location: locationId,
                name: values.siteName,
                id: values.siteId,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Site ${urlParams.get("name")} updated.`)
                navigate({ pathname: MENUPATH_SITE })
            })
            .catch( error => {
                reportError(error, "Failed to update site.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warn("Required field validation failed.")
            return
        })
    }

    //---------------
    // Delete location
    //---------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}site/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Site ${urlParams.get("name")} deleted.`)
            navigate({ pathname: MENUPATH_SITE })
        })
        .catch( error => {
            reportError(error, "Failed to delete site.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------------------------------------
    // Determine if storage has transaction and batch assigned
    //--------------------------------------------------------
    const toHideDeleteButton = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}sitehasbatchorstorage/${urlParams.get("pKey")}/`, {
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.has_batch_or_storage)
                setHideDeleteButton(true)
        })
        .catch( error => {
            reportError(error, "Failed to determine if site has batch or storage.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------
    // Cancel update storage
    //--------------------
    const onCancel = () => {
        navigate({ pathname: MENUPATH_SITE })
    }

    //--------------------
    // On update
    //--------------------
    const onUpdate = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update site confirmed?</p></Space>,
            onOk() { updateSite() },
            onCancel() {},
            centered: true
        })
    }

    //---------------------
    // On location change
    //---------------------
    const onLocationChange = (e, value) => {
        if(e != undefined) 
            setLocationId(e)
        else 
            setLocationId(0)
    }

    //------------------
    // Custom validation
    //------------------
    const validateLocation = (() => {
        if(locationId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Location is required."))
    })

    

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        toHideDeleteButton()
        
        form.setFieldsValue({
            siteName: urlParams.get("name"),
            siteId: urlParams.get("id"),
        })
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onCancel()} 
                    title="Site">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update site</Descriptions.Item>
                    </Descriptions>
                </PageHeader>
        
                <Form form={form} onFinish={onUpdate} {...formLayout}>
                    <Form.Item name="location" label="Location"
                        rules={[
                            { required: true, validator: validateLocation },
                        ]}>
                        <LocationSelect withBlank={false} defaultValue={urlParams.get("location")} onChange={onLocationChange}/>
                    </Form.Item>

                    <Form.Item name="siteName" label="New Site Name"
                        rules={[
                            { required: true, message: "Site name is required."},
                        ]}>
                        <Input maxLength={50} placeholder="Site Name"/>
                    </Form.Item>

                    <Form.Item name="siteId" label="New Site ID"
                        rules={[
                            { required: true, message: "Site ID is required."},
                        ]}>
                        <Input maxLength={3} minLength={3} placeholder="Site ID (3 alphanumeric)"/>
                    </Form.Item>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Update</Button>
                            <Popconfirm title="Delete site confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} hidden={hideDeleteButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" onClick={onCancel} disabled={disableButton} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Site:"
                        subTitle="Update site"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default SiteUpdate