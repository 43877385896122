import { useEffect, useState } from 'react'
import { Select } from 'antd'
import axios from "axios"
import { ACISAPIURL, MEDIAMAXWIDTH,  } from './SystemParameter'
import { reportError } from './Utility'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession } from "./UserSession"
import { useMediaQuery } from 'react-responsive'

const { Option } = Select

//----------
// Component
//----------
const UserSelect = ({withBlank, defaultValue, onChange}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [userOption, setUserOption] = useState([])
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
        
    //---------
    // Load all
    //---------
    const getAllUser = () => {
        axios.get(`${ACISAPIURL}user/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}})
        .then( response => {
            let options = []
            if(withBlank) options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( user => <Option key={user.id}>{user.username}</Option>))
            setUserOption(options)
        })
        .catch( error => {
            reportError(error, "<UserSelect /> Failed to get user(s).")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //---------------------------------------------------------------
    // Call back to parent component to set selected option key value
    //---------------------------------------------------------------
    const onSelectionChange = (e, value) => {
         onChange(e, value)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getAllUser()
    }, []) 
    
    return(
        <Select style={{width: optionWidth}} onChange={onSelectionChange} allowClear showSearch optionFilterProp="children" defaultValue={defaultValue}
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {userOption}
        </Select>
    )
}

export default UserSelect