import  { useEffect, useState } from 'react'
import { Button, Col, Collapse, Row, Space, Form, Table, Pagination, Spin, message } from "antd"
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import { formLayout_2Columns } from '../Common/Layout'
import axios from 'axios'
import { ACISAPIURL, PAGESIZE, PAGINATIONSIZE, LOADING, UNIDATETIMEFORMAT, APPJSON } from '../Common/SystemParameter'
import { getUserAuthToken, OTHERSYSPARAM, refreshUserSession, SYSPARAM } from '../Common/UserSession'
import { numberWithCommas, reportError, stringNumberSorter } from '../Common/Utility'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import SpeciesSelect from '../Common/SpeciesSelect'

const { Panel } = Collapse


const StandardGrowthTable2 = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    
    const [growthRecordDataSource, setGrowthRecordDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    
    //---------------------
    // Search growth record
    //---------------------
    const searchGrowthRecord = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}standardgrowth2/`, {
            params: { 
                species: speciesId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            pushData(response)
        })
        .catch( error => {
            reportError(error, "Failed to search standard growth record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const pushData = (response) => {
        const data = []
        response.data.results.forEach( growth => {
            data.push({
                key: growth.pKey,
                species: growth.species_data.short_name,
                speciesId: growth.species_data.pKey,
                day: numberWithCommas(growth.day),
                weightGram: numberWithCommas(growth.weight_gram),
            })
        })
        
        setGrowthRecordDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = ""
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
                                
        axios.get(`${ACISAPIURL}standardgrowth2/download/`, {
            params: { 
                species: speciesId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Standard Growth ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        navigate({ 
            pathname: "/standardgrowthnew2"
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchGrowthRecord(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                let params = new URLSearchParams(`?pKey=${record.key}&speciesId=${record.speciesId}&species=${record.species}&day=${record.day}&weightGram=${record.weightGram}`) 

                navigate({ 
                    pathname: "/standardgrowthupdate2", 
                    search: params.toString()
                }) 
            }
        }
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchGrowthRecord(1)
    }

    const onSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchGrowthRecord(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Species', dataIndex: 'species', key: 'species', sorter: (a, b) => a.species.localeCompare(b.species) },
        { title: 'Day', dataIndex: 'day', key: 'day', align: 'center', sorter: (a, b) => stringNumberSorter(a.day, b.day) },
        { title: 'Weight (g)', dataIndex: 'weightGram', key: 'weightGram', align: 'right', sorter: (a, b) => stringNumberSorter(a.weightGram, b.weightGram) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Search Growth Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="species" label="Species">
                                <SpeciesSelect onChange={onSpeciesChange} withBlank={true} />
                            </Form.Item>
                            
                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form> 
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={12} />
            <Col span={12} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />} disabled={disableButton}>New</Button>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={growthRecordDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}>
                <Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />} disabled={disableButton}>New</Button>
            </Col>
        </Row>
        </Spin>
        </>
    )
}

export default StandardGrowthTable2