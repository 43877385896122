import { useEffect, useState } from 'react'
import { Spin, Steps } from 'antd'
import axios from "axios"
import { ACISAPIURL, LOADING } from "../Common/SystemParameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM, SYSPARAM } from "../Common/UserSession"
import { reportError } from "../Common/Utility"

const { Step } = Steps

const AquacultureStagLifecycleSteps = ({batchPKey, direction="horizontal"}) => {  
    const [steps, setSteps] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    
    //-------------------------------------
    // Get aquaculture stage and lifecycle.
    //-------------------------------------
    const getAquacultureStageLifecycle = (batchPKey) => {
        setIsLoading(true)

        return new Promise( async (resolve, reject) => {
            axios.get(`${ACISAPIURL}aquaculturestagelifecycle/`, {
                params: {
                    batch: batchPKey
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                resolve(response.data)
            })
            .catch( error => {
                reportError(error, "Failed to load aquaculture stage lifecycle data.")
                reject(error)
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }
    
    //----------
    // Get batch
    //----------
    const getBatch = (batchPKey) => {
        setIsLoading(true)

        return new Promise( async (resolve, reject) => {
            axios.get(`${ACISAPIURL}batch/${batchPKey}/`, {
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                resolve(response.data)
            })
            .catch( error => {
                reportError(error, "Failed to load batch.")
                reject(error)
            })
            .finally(() => {
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    useEffect( () => {
        const init = async () => {
            const aquacultureLifeycle = await getAquacultureStageLifecycle(batchPKey)
            const batch = await getBatch(batchPKey)
            
            let current = -1
            let counter = 0
            const children = []
            const totalStep = aquacultureLifeycle.results.length - 1

            // 20221029
            // Need to skip the last item, brood maintenance.
            for( let i of aquacultureLifeycle.results) {
                children.push(<Step title={i.aquaculture_stage_data.name} description={i.lifecycle_data.name} />)

                // Get batch current aquaculture stage and lifecycle step.
                if(batch.aquaculture_stage_data.pKey >= i.aquaculture_stage_data.pKey && batch.lifecycle_data.pKey >= i.lifecycle_data.pKey)
                    current = counter

                counter += 1

                if(counter == totalStep) break
            }

            const stepComponent = <Steps progressDot size="small" direction="vertical" current={current}>
                {children}
                </Steps>

            setSteps(stepComponent)
        }
        
        init()
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        {steps}
        </Spin>
        </>
    )
}

export default AquacultureStagLifecycleSteps



