import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, Pagination, Spin, message } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT,  EGGS,  LOADING,  MEDIAMAXWIDTH,  PAGESIZE,  PAGINATIONSIZE,  TAGGED,  UNIDATEFORMAT, UNIDATETIMEFORMAT, UNTAGGED } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, SYSPARAM, getUserSiteName } from "../Common/UserSession"
import { numberWithCommas, reportError,  } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import TransactionTypeSelect from '../Common/TransactionTypeSelect'
import SearchStockTransactionByID from './SearchStockTransactionId'

const { Panel } = Collapse
const { RangePicker } = DatePicker


//----------
// Component
//----------
const AmendedStockTransactionTable = () => {
    const FileDownload = require('js-file-download')
    const navigate = useNavigate()
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    
    const [dateRange, setDateRange] = useState([moment(moment().subtract(1, "years"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
    const [isLoading, setIsLoading] = useState(false)
    const [transactionTypeId, setTransactionTypeId] = useState(0)
    const [transactionType, setTransactionType] = useState("")
            
    const [stockTransactionDataSource, setStockTransactionDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const [showSearchTransaction, setShowSearchTransaction] = useState(false)

    //-----------------------
    // Search egg development
    //-----------------------
    const searchAmendedStockTransaction = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}amendedstocktransaction/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                transaction_type: transactionTypeId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search amended stock transaction record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( transaction => {
            /*let stockBalance = 0
            if(transaction.item_type_data.name.startsWith(EGGS))
                stockBalance = transaction.egg_balance_quantity
            else if(transaction.item_type_data.name.startsWith(UNTAGGED))
                stockBalance = transaction.untagged_balance_quantity
            else if(transaction.item_type_data.name.startsWith(TAGGED))
                stockBalance = transaction.tagged_balance_quantity
            */
            data.push({
                key: transaction.pKey,
                recordId: transaction.transaction_pkey,
                dateTime: moment(transaction.transactedOn).format(DATETIMEFORMAT),
                transactionType: transaction.transaction_type_data.name,
                batch: transaction.batch_data.id,
                batchType: transaction.batch_type_data.name,
                storage: transaction.storage_data.name,
                itemType: transaction.item_type_data.name,
                oldQty: numberWithCommas(transaction.old_quantity),
                newQty: numberWithCommas(transaction.new_quantity),
                oldStockBalance: numberWithCommas(transaction.old_untagged_balance_quantity),
                newStockBalance: numberWithCommas(transaction.new_untagged_balance_quantity),
                uom: transaction.uom_data.uom,
                oldAveragePerUnitWeight: numberWithCommas(transaction.old_average_per_unit_weight_gram),
                old_qty2: numberWithCommas((transaction.old_quantity2 / 1000).toFixed(2)),
                newAveragePerUnitWeight: numberWithCommas(transaction.new_average_per_unit_weight_gram),
                new_qty2: numberWithCommas((transaction.new_quantity2 / 1000).toFixed(2)),
                remark: transaction.remark,
                isBackdated: transaction.is_backdated,
                createdOn: moment(transaction.createdOn).format(DATETIMEFORMAT),
            })
        })

        setStockTransactionDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(transactionTypeId != 0) searchCriteria = `${searchCriteria}\nTransaction Type: ${transactionType}`
                                        
        axios.get(`${ACISAPIURL}amendedstocktransaction/download/`, {
            params: { 
                site: getUserSiteId(),
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                tranaction_type: transactionTypeId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: "blob",
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 3),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Amended Stock Transaction ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        setShowSearchTransaction(true)
    }

    const navigateTo = (transaction) => {
        navigate({
            pathname: '/amendstocktransactionrecordnew',
            search: `?transaction=${JSON.stringify(transaction)}`
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchAmendedStockTransaction(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchAmendedStockTransaction(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onTransactionTypeChange = (e, value) => {
        setTransactionTypeId(e)
        setTransactionType(value)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        searchAmendedStockTransaction(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    let desktopColumn1 = [
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', },//sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
    ]

    if(isTabletOrMobile) {
        desktopColumn1 = []
    }

    const columns = [
        { title: 'Record ID', dataIndex: 'recordId', key: 'recordId'},
        { title: 'Amended On', dataIndex: 'createdOn', key: 'createdOn'}, 
        { title: 'Transacted On', dataIndex: 'dateTime', key: 'dateTime', 
            render: (dateTime, record) => {
                if(!record.isBackdated)
                    return <div>{dateTime}</div>
                else
                    return <div style={{ color: "red"}}>{dateTime}</div>
            }
        },
        { title: 'Trans. Type', dataIndex: 'transactionType', key: 'transactionType', },//sorter: (a, b) => a.transactionType.localeCompare(b.transactionType) },
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', },//sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', },//sorter: (a, b) => a.storage.localeCompare(b.storage) },
        ...desktopColumn1,
        { title: 'Item Type', dataIndex: 'itemType', key: 'itemType', },//sorter: (a, b) => a.itemType.localeCompare(b.itemType)},
        { title: 'UoM', dataIndex: 'uom', key: 'uom', },//sorter: (a, b) => a.uom.localeCompare(b.uom) },
        { title: 'Old Qty.', dataIndex: 'oldQty', key: 'oldQty', align: 'right'}, //sorter: (a, b) => stringNumberSorter(a.qty, b.qty) },
        { title: 'Old Avg/Unit Weight (g)', dataIndex: 'oldAveragePerUnitWeight', key: 'oldAveragePerUnitWeight', align: "right", },//sorter: (a, b) => stringNumberSorter(a.averagePerUnitWeight, b.averagePerUnitWeight) },
        { title: 'Old Total Weight (Kg)', dataIndex: 'old_qty2', key: 'old_qty2', align: "right", },//sorter: (a, b) => stringNumberSorter(a.qty2, b.qty2) },
        { title: 'Old Balance Qty.', dataIndex: 'oldStockBalance', key: 'oldStockBalance', align: 'right'},//sorter: (a, b) => stringNumberSorter(a.qty, b.qty) },
        { title: 'New Qty.', dataIndex: 'newQty', key: 'newQty', align: 'right'}, 
        { title: 'New Avg/Unit Weight (g)', dataIndex: 'newAveragePerUnitWeight', key: 'newAveragePerUnitWeight', align: "right", },//sorter: (a, b) => stringNumberSorter(a.averagePerUnitWeight, b.averagePerUnitWeight) },
        { title: 'New Total Weight (Kg)', dataIndex: 'new_qty2', key: 'new_qty2', align: "right", },//sorter: (a, b) => stringNumberSorter(a.qty2, b.qty2) },
        { title: 'New Balance Qty.', dataIndex: 'newStockBalance', key: 'newStockBalance', align: 'right'},
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Amended Stock Transaction" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <Form.Item name="transactionTypeId" label="Transaction Type">
                                <TransactionTypeSelect
                                    withBlank={true} 
                                    type={OTHERSYSPARAM("STOCK_IN_OUT")}
                                    excludes={[SYSPARAM("Egg Sampling (In)"), SYSPARAM("Egg Sampling (Out)")]}
                                    customWidth={OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}
                                    onChange={onTransactionTypeChange} />
                            </Form.Item>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={stockTransactionDataSource} pagination={false}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3} />
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <SearchStockTransactionByID visible={showSearchTransaction} toClose={() => setShowSearchTransaction(false)} navigationCallBack={navigateTo} />
        </Spin>
        </>
    )
}

export default AmendedStockTransactionTable