import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button,Space, Layout, Row, Col, message, Table, Modal, Collapse, Popconfirm, PageHeader, InputNumber, Descriptions, Pagination, DatePicker, Spin, Typography } from 'antd'
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, LOADING, MEDIAMAXWIDTH, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { calculateWeightVariancePercentage, reportError, storageCapacityCheck } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserSiteId, getUserAuthToken, SYSPARAM, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout_2Columns, formLayout } from '../Common/Layout'
import { LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { useMediaQuery } from 'react-responsive'
import AcquiringMethodSelect from '../Common/AcquiringMethodSelect'
import BatchTypeSelect from '../Common/BatchTypeSelect'
import { showStorageStockSummary } from '../Common/showStorageStockSummary'
import moment from 'moment'
import GenderSelect from '../Common/GenderSelect'
import TransactionTypeSelect from '../Common/TransactionTypeSelect'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Header, Footer, Content } = Layout
const { confirm } = Modal
const { Panel } = Collapse
const { Title } = Typography

const FishStorageWithoutSourceBatch = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()
    const [record, setRecord] = useState(null)
    const [genderId, setGenderId] = useState(0)
        
    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [isSearching, setIsSearching] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    
    const [searchBatchId, setSearchBatchId] = useState(0)
    const [searchStorageId, setSearchStorageId] = useState(0)
    const [searchAquacultureStageId, setSearchAquacultureStageId] = useState(0)
    const [searchLifecycleId, setSearchLifecycleId] = useState(0)
    const [searchAcquiringMethodId, setSearchAcquiringMethodId] = useState(0)
    const [searchBatchTypeId, setSearchBatchTypeId] = useState(0)
    const [searchMarineLifeId, setSearchMarineLifeId] = useState(0)
    const [searchSpeciesId, setSearchSpeciesId] = useState(0)

    const [transactionTypeId, setTransactionTypeId] = useState(0)
    const [transactionType, setTransactionType] = useState("")

    const [tagDateTime, setTagDateTime] = useState(moment(moment(), UNIDATEFORMAT))

    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    
    const [acquiringMethodId, setAcquiringMethodId] = useState(
        // selectedAcquiringMethodId is from BatchNew.js
        urlParams.has("selectedAcquiringMethodId") ? urlParams.get("selectedAcquiringMethodId") : 0
    )
    const [acquiringMethod, setAcquiringMethod] = useState(
        urlParams.has("selectedAcquiringMethod") ? urlParams.get("selectedAcquiringMethod") : ""
    )

    const [availableQuantity, setAvailableQuantity] = useState(urlParams.has("sourceBatchId") ? parseInt(urlParams.get("availableQuantity")) : 10000000)
    
    //------------
    // Get storage
    //------------
    const searchStorage = (currentPage, urlParamSearchStorageId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        // If search by storage id, in order to show empty storage, will not be filtered by species.
        if(searchStorageId != 0) {
            message.info("Search by Storage ID would disable species filter.")
        }

        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: {
                site: getUserSiteId(),
                marine_life: searchMarineLifeId,
                species: searchStorageId != 0 ? 0 : searchSpeciesId, 
                batch: searchBatchId,
                id: urlParamSearchStorageId != 0 ? urlParamSearchStorageId : searchStorageId,
                acquiring_method: searchAcquiringMethodId,
                aquaculture_stage: searchAquacultureStageId,
                lifecycle: searchLifecycleId,
                batch_type: searchBatchTypeId, 
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get storage.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //------------
    // Get storage
    //------------
    const getMatchingStorage = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storagepaginated/`, {
            params: {
                site: getUserSiteId(),
                //acquiring_method: urlParams.has("acquiringMethodId") ? urlParams.get("acquiringMethodId") : 0,
                //species: urlParams.has("speciesId") ? urlParams.get("speciesId") : 0, 
                //lifecycle: urlParams.has("lifecycleId") ? urlParams.get("lifecycleId") : 0, 
                //batch_type: urlParams.has("batchTypeId") ? urlParams.get("batchTypeId") : 0, 
                //free: true,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to get storage.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        let data = []
        response.data.results.forEach( storage => {
            data.push({
                key: storage.id,
                pKey: storage.pKey,
                id: storage.name,
                storageType: storage.storage_type_data.name,
                batchId: (storage?.batch_data == null) ? 0 : storage.batch_data.pKey,
                batch: (storage?.batch_data == null) ? "" : storage.batch_data.id,
                acquiringMethod: storage?.batch_data?.acquiring_method_data == null ? "" : storage.batch_data.acquiring_method_data.name,
                acquiringMethodId: storage?.batch_data?.acquiring_method_data == null ? 0 : storage.batch_data.acquiring_method_data.pKey,
                aquacultureStage: storage?.batch_data?.aquaculture_stage_data.name == null ? "" : storage.batch_data.aquaculture_stage_data.name,
                lifecycle: storage?.batch_data?.lifecycle_data == null ? "" : storage.batch_data.lifecycle_data.name,
                lifecycleId: storage?.batch_data?.lifecycle_data == null ? 0 : storage.batch_data.lifecycle_data.pKey,
                batchType: storage?.batch_data?.batch_type_data?.name == null ? "" : storage.batch_data.batch_type_data.name,
                batchTypeId: storage?.batch_data?.batch_type_data?.pKey == null ? 0 : storage.batch_data.batch_type_data.pKey,
                species: storage?.batch_data?.species_data?.short_name == null ? "" : storage.batch_data.species_data.short_name,
                speciesId: storage?.batch_data?.species_data?.pKey == null ? 0 : storage.batch_data.species_data.pKey,
                batchAverageWeight: storage?.batch_data?.average_per_unit_weight_gram == null ? 0 : storage.batch_data.average_per_unit_weight_gram,
                batchAverageLength: storage?.batch_data?.average_per_unit_length_mm == null ? 0 : storage.batch_data.average_per_unit_length_mm,
                ageDays: storage?.batch_data?.age_days == null ? 0 : storage.batch_data.age_days,

                batchData: storage?.batch_data != null ? [storage.batch_data] : [],
                status: storage.status_data,

                marineLifeId: storage?.batch_data?.species_data?.marine_life_data.pKey
            })
        })

        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //------------------
    // Register new fish
    //------------------
    const registerFish = (record) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}fish/createwithoutsourcebatch/`, {
                site: getUserSiteId(),
                quantity: values.quantity,
                gender: values.gender,
                transaction_type: transactionTypeId,
                batch: record.batchId,
                storage: record.pKey,
                average_per_unit_weight_gram: values.averagePerUnitWeightGram,
                average_per_unit_length_mm: values.averagePerUnitLengthMm,
                birth_date: moment(moment().subtract(values.ageDays, "days"), UNIDATETIMEFORMAT),
                transactedOn: tagDateTime,
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info(`Fish registered. Please update fish tag status after fish are physically tagged.`, OTHERSYSPARAM("LONG_MSG_DURATION"))
                
                navigate({ 
                    pathname: "/physicaltaggingfish", 
                    search: `?species=${record.species}&batch=${record.batch}&batchId=${record.batchId}&storageId=${record.pKey}&storage=${record.id}`
                })
            })
            .catch( error => {
                reportError(error, "Failed to register fish.")
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
    }

    //-------------------
    // Check overcrowding
    //-------------------
    const checkOvercrowd = async () => {
        setIsLoading(true)

        // Check for overcrowding by weight in Kg.
        let weightToAddKg = form.getFieldValue("averagePerUnitWeightGram") * form.getFieldValue("quantity") / 1000
                        
        try{
            const prompt = await storageCapacityCheck(record.marineLifeId, record.batchId, record.pKey, weightToAddKg)

            if(prompt)
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Overcrowding will happen after transfer. Procced?</p></Space>,
                    onOk() { onSave() },
                    onCancel() {},
                    okText: OTHERSYSPARAM("YES"),
                    cancelText: OTHERSYSPARAM("NO"),
                    centered: true
                })
            else
                onSave()
        }
        catch(error) {
            reportError(error, "Failed to get marine life storage type capacity information.")
        }
        finally {
            setIsLoading(false)
        }
    }

    //----------------------
    // Check weight variance
    //----------------------
    const checkWeightVariance = () => {
        let threshold = parseFloat(OTHERSYSPARAM("WEIGHT_VARIANCE_THRESHOLD_PERCENT")) / 100

        // Check average weight variance. Prompt if variance is greater than predefined percentage threshold.
        switch(urlParams.has("sourceBatchId")) {
            case true:
                if(record.batchId != 0) {
                    if(parseFloat(urlParams.get("averageWeight")) != 0 && parseFloat(record.batchAverageWeight) != 0) {
                        let weightDiff = calculateWeightVariancePercentage(parseFloat(urlParams.get("averageWeight")), parseFloat(record.batchAverageWeight))
                                                                
                        if(weightDiff >= threshold) {
                            confirm({
                                icon: <QuestionCircleOutlined />,
                                content: <Space><p>Weight variance between the two batches exceeded by {(threshold * 100).toFixed(2)}%. Proceed to stock-in?</p></Space>,
                                onOk() { checkOvercrowd() },
                                onCancel() {},
                                centered: true
                            })
                        }
                        else
                            checkOvercrowd()
                    }
                    else    
                        checkOvercrowd()
                }
                else
                    checkOvercrowd()

                break
            case false:        
                if(record.batchId != 0) {
                    let weightDiff = calculateWeightVariancePercentage(parseFloat(form.getFieldValue("averagePerUnitWeightGram")), parseFloat(record.batchAverageWeight))
                                        
                    if(weightDiff >= threshold) {
                        confirm({
                            icon: <QuestionCircleOutlined />,
                            content: <Space><p>Weight variance between the two batches exceeded by {(threshold * 100).toFixed(2)}%. Proceed to stock-in?</p></Space>,
                            onOk() { checkOvercrowd() },
                            onCancel() {},
                            centered: true
                        })
                    }
                    else
                        checkOvercrowd()
                }
                else
                    checkOvercrowd()

                break
        }
    }

    //--------
    // On save
    //--------
    const onSave = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>New tagging confirmed?</p></Space>,
            onOk() { registerFish(record) },
            onCancel() {},
            centered: true
        })    
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: "/fishsourcebatch", 
        })
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                if(record.batchId == 0) {
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Storage has no batch assigned. Go to Batch module to create one?</p></Space>,
                        onOk() { 
                            navigate({ 
                                pathname: "/batchnew", 
                                search: `?fromFishTagging=true&selectedAcquiringMethodId=${acquiringMethodId}&selectedAcquiringMethod=${acquiringMethod}&storageId=${record.pKey}&storage=${record.id}`
                            }) 
                        },
                        onCancel() {},
                        centered: true
                    })
                }
                else {
                    setRecord(record)
                    form.submit()
                }
            }
        }
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        //window.location.reload()
        window.location.replace(window.location.href.split('?')[0])
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        if(!isSearching)
            getMatchingStorage(page)
        else {
            searchStorage(page)
        }
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setIsSearching(true)
        setCurrentPage(1)
        searchStorage(1)
    }

    //---------------------------
    // On acquiring method change
    //---------------------------
    const onAcquiringMethodChange = (e, value) => {
        setSearchAcquiringMethodId(e)
    }

    //---------------------
    // On batch type change
    //---------------------
    const onBatchTypeChange = (e, value) => {
        setSearchBatchTypeId(e)
    }

    //---------------------
    // On gender change
    //---------------------
    const onGenderChange = (e, value) => {
        setGenderId(e)
    }

    //---------------------------
    // On transaction type change
    //---------------------------
    const onTransactionTypeChange = (e, value) => {
        setTransactionTypeId(e)
        setTransactionType(value)
    }
    
    //----------------------------
    // Redirect callback function
    //----------------------------
    const redirect = (pathname, search) => {
        navigate({
            pathname: pathname,
            search: search
        })
    }

    //----------------------------
    // Show storage stock quantity
    //----------------------------
    const showStoragePopup = (e, record) => {
        e.stopPropagation()
        showStorageStockSummary(false, record, redirect)
    }

    //------------------
    // Custom validation
    //------------------
    const validateTransactionType = (() => {
        if(transactionTypeId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Transaction type is required."))
    })

    const validateTagDateTime = (() => {
        if(tagDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Tag date time is required."))
    })

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setTagDateTime(datetime)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setIsSearching(true)
            setSearchStorageId(urlParams.get("storageId"))
            searchStorage(currentPage, urlParams.get("storageId"))
        }
        else
            getMatchingStorage(currentPage)

        form.setFieldsValue({
            quantity: 1
        })
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        !isTabletOrMobile ? { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) } : {},
        !isTabletOrMobile ? { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) } : {},
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Status', dataIndex: 'stockQuantity', key: 'stockQuantity', align: 'center', //sorter: (a, b) => a.quantity.stockQuantity(b.stockQuantity),
            render: (stockQuantity, record) => {
                if(record.status == OTHERSYSPARAM("IS_OCCUPIED"))
                    return <Button type="primary" htmlType="button" style={{margin: "0px"}} onClick={(e) => showStoragePopup(e, record)}>{OTHERSYSPARAM("IS_OCCUPIED")}</Button>
                else
                    return OTHERSYSPARAM("IS_EMPTY")
            }
        },
    ]

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onBack()} 
                    title="New Tag">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Select destination storage</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={checkWeightVariance} {...formLayout}>
                    <Form.Item name="tagDateTime" label="Tag Date Time"
                        rules={[
                            { required: true, validator: validateTagDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={tagDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="quantity" label={`Tag Quantity ${urlParams.has("sourceBatchId") ? `Out of ${availableQuantity} (Tail). Maximum 100 per tagging` : ""}`} 
                        rules={[
                            { required: true, message: "Tag quantity is required."},
                        ]}>
                        <InputNumber min={1} max={100} step={1} placeholder="Quantity"/>
                    </Form.Item>

                    <Form.Item name="gender" label="Gender" 
                        rules={[
                            { required: true, message: "Gender is required."},
                        ]}>
                        <GenderSelect withBlank={false} onChange={onGenderChange}/>
                    </Form.Item>

                    <Form.Item name="transactionType" label="Transaction Type"
                        rules={[
                            { required: true, validator: validateTransactionType },
                        ]}>
                        <TransactionTypeSelect 
                            withBlank={false} 
                            value={transactionType} 
                            type={OTHERSYSPARAM("STOCK_IN")} 
                            excludes={[SYSPARAM("Stock Count Variance Adjustment (In)"), SYSPARAM("Stock Adjustment (In)")]}
                            onChange={onTransactionTypeChange} />
                    </Form.Item>
                                    
                    <Form.Item name="averagePerUnitWeightGram" label="Average Per Unit Weight (g)" 
                        rules={[
                            { required: true, message: "Average per unit weight is required."},
                        ]}>
                        <InputNumber min={1} max={1000000} precision={2} step={1} placeholder="Average Per Unit Weight (g)"/>
                    </Form.Item>

                    <Form.Item name="averagePerUnitLengthMm" label="Average Per Unit Length (mm)" 
                        rules={[
                            { required: true, message: "Average per unit length is required."},
                        ]}>
                        <InputNumber min={1} max={1000000} precision={2} step={1} placeholder="Average Per Unit Length (mm)"/>
                    </Form.Item>

                    <Form.Item name="ageDays" label="Age (days)" 
                        rules={[
                            { required: true, message: "Age is required."},
                        ]}>
                        <InputNumber min={1} max={1000000} step={1} placeholder="Age (days)"/>
                    </Form.Item>
                </Form>

                <Row>
                    <Col span={1}></Col>
                        <Col span={22}>
                            <Collapse defaultActiveKey={["0"]}>
                                <Panel header="Search Destination Storage" key="1">
                                    <Form form={searchForm} {...formLayout_2Columns}>
                                        <CommonSearchFormItem onMarineLifeChange={setSearchMarineLifeId} onSpeciesChange={setSearchSpeciesId} onBatchChange={setSearchBatchId} 
                                            onStorageChange={setSearchStorageId} onAquacultureStageChange={setSearchAquacultureStageId}
                                            onLifecycleChange={setSearchLifecycleId} showLockedStorage={false} defaultStorageId={searchStorageId}/>

                                        <Form.Item name="acquiringMethodId" label="Acquiring Method">
                                            <AcquiringMethodSelect withBlank={true} onChange={onAcquiringMethodChange}/>
                                        </Form.Item>

                                        <Form.Item name="batchTypeId" label="Batch Type">
                                            <BatchTypeSelect withBlank={true} onChange={onBatchTypeChange}/>
                                        </Form.Item>
                                        
                                        <Row justify="center">
                                            <Col span={6}></Col>
                                            <Col span={12} style={{textAlign: "center"}}>
                                                <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                                <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                            </Col>
                                            <Col span={6}></Col>
                                        </Row>
                                    </Form>
                                </Panel>
                            </Collapse>
                        </Col>
                    <Col span={1}></Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={2} style={{textAlign: "start"}}>
                        <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                            <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={21}></Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row>
                    <Col span={1}></Col>
                    <Col span={22}>
                        <Table bordered columns={columns} onRow={onRowClick} dataSource={storageDataSource} pagination={false} />
                    </Col>
                    <Col span={1}></Col>
                </Row>

                <Row><Col><Space><div /></Space></Col></Row>

                <Row justify="center">
                    <Col span={1}></Col>
                    <Col span={2} style={{textAlign: "start"}}>
                        <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                            <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                        </Popconfirm>
                    </Col>
                    <Col span={18} style={{textAlign: "center"}}>
                        <Pagination
                            size={PAGINATIONSIZE}
                            total={totalRecord}
                            showTotal={showTotal}
                            pageSize={PAGESIZE}
                            current={currentPage}
                            hideOnSinglePage={false}
                            showSizeChanger={false}
                            onChange={onPaginationChange}/>
                    </Col>
                    <Col span={3}></Col>
                </Row>
                
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader 
                        onBack={() => onBack()} 
                        title="New Tag:"
                        subTitle="Select destination storage"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default FishStorageWithoutSourceBatch