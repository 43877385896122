import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Layout, Row, Col, Typography, Select, PageHeader, Card, Modal, Descriptions, Switch, Collapse, Input } from 'antd'
import { SaveOutlined, CloseOutlined, MinusOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, MENUPATH_FISH } from '../Common/SystemParameter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, SYSPARAM, OTHERSYSPARAM, getUserGroups } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import moment from 'moment'
import GenderSelect from '../Common/GenderSelect'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { Option } = Select
const { confirm } = Modal
const { Panel } = Collapse

//----------
// Component
//----------
const FishUpdate = () => {
    const navigate = useNavigate()
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT") 
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")

    const [hideCriticalComponent, setHideCriticalComponent] = useState(!getUserGroups().includes(OTHERSYSPARAM("DEVELOPER")) ? true : false)
    
    const [lifecycleId, setLifecycleId] = useState(0)
    const [lifecycleOption, setLifecycleOption] = useState([])
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)
    
    const [isBrood, setIsBrood] = useState(urlParams.get("isBrood") == "true" ? true : false)
    const [isLoanUnit, setIsLoanUnit] = useState(urlParams.get("isLoanUnit") == "true" ? true : false)
    const [physicallyTagged, setPhysicallyTagged] = useState(urlParams.get("physicallyTagged") == "true" ? true : false)
    
    let birthDate = moment(urlParams.get("birthDate"))
    const [ageDays, setAgeDays] = useState(birthDate._isValid == false ? OTHERSYSPARAM("NA") : moment().diff(birthDate, "days"))

    const [genderId, setGenderId] = useState(urlParams.get("genderId"))

    //------------
    // Update fish
    //------------
    const updateFish = () => {
        // Disable button
        setDisableButton("disabled")
        
        axios.patch(`${ACISAPIURL}fish/update/${urlParams.get("pKey")}/`, {
            tag_id: form.getFieldValue("tagId"),
            lifecycle: lifecycleId,
            physically_tagged: physicallyTagged,
            gender: genderId,
            isBrood: isBrood,
            is_loan_unit: isLoanUnit,
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Tagged marine life ${urlParams.get("id")} updated.`)
            navigate({ pathname: MENUPATH_FISH })
        })
        .catch( error => {
            reportError(error, "Failed to update tagged marine life.")
        })
        .finally(() => {
            setDisableButton("")
            refreshUserSession()
        })
    }

    const onUnregister = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>You will be redirected to tagged marine life stock-out page. Proceed?</p></Space>,
            okText: "Yes",
            cancelText: "No",
            onOk() { 
                navigate({ 
                    pathname: "/fishstockout",
                    search: `?fishId=${urlParams.get("pKey")}&batchAverageWeight=${urlParams.get("averageWeight")}`
                })
            },
            onCancel() {},
            centered: true
        })
    }

    //-------------------
    // Load all lifecycle
    //-------------------
    const getLifecycle = async () => {
        return await axios.get(`${ACISAPIURL}lifecycle/`, 
        { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const options = response.data.results.map( lifecycle => <Option key={lifecycle.pKey}>{lifecycle.name}</Option>)
            setLifecycleOption(options.filter( option => option.key >= SYSPARAM("Fries")))
        })
        .catch( error => {
            reportError(error, "Failed to get lifecycle.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }

    //-------------------
    // Cancel update fish
    //-------------------
    const onCancel = () => {
        navigate({ pathname: MENUPATH_FISH })
    }

    //-------------------
    // On update
    //-------------------
    const onUpdate = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update fish record confirmed?</p></Space>,
            onOk() { updateFish() },
            onCancel() {},
            centered: true
        })
    }

    //--------------------
    // On lifecycle change
    //--------------------
    const onLifecycleChange = (e) => {
        setLifecycleId(e)
    }

    //--------------------
    // On is brood change
    //--------------------
    const onIsBroodChange = (checked) => {
        setIsBrood(checked)
    }

    //--------------------
    // On is load unit change
    //--------------------
    const onIsLoanUnitChange = (checked) => {
        setIsLoanUnit(checked)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        Promise.resolve()
        .then(() => { getLifecycle() })
        .then(() => { 
            setLifecycleId(urlParams.get("lifecycleId"))
            form.setFieldsValue({
                lifecycle: urlParams.get("lifecycle"),
                tagId: urlParams.get("tagId")
            })
         })
    }, [])

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>

                <PageHeader onBack={() => onCancel()} 
                    title="Tagged Fish">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update tagged marine life record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onUpdate} {...formLayout}>
                    <Form.Item>
                        <Collapse defaultActiveKey={["0"]}>
                            <Panel header={`Tagged Marine Life ${urlParams.get("id")} Summary`} key="1">
                                <Card title={<Title level={5}>{`Tag ID: ${urlParams.get("id")}`}</Title>}>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("storage")}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Acquiring Method:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("acquiringMethod")}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Source Batch:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("sourceBatch") != "" ? urlParams.get("sourceBatch") : OTHERSYSPARAM("NA")}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Weight (g):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("averageWeight")}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Length (mm):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("averageLength")}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Age (days):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{ageDays}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Birth Date:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("birthDateFormatted")}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>

                                    { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                                        <>
                                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                                        </>
                                    }
                                </Card>
                            </Panel>
                        </Collapse>
                    </Form.Item>

                    {urlParams.get("active") == "true" ?
                        <Form.Item name="lifecycle" label="Lifecycle" hidden={hideCriticalComponent}
                            rules={[
                                { required: true, message: "Lifecycle is required."},
                            ]}>
                            <Select onChange={onLifecycleChange} allowClear showSearch optionFilterProp="children" defaultValue={urlParams.get("lifecycle")}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {lifecycleOption}    
                            </Select>
                        </Form.Item> :
                        <Form.Item label="Lifecycle">
                            <h2 style={{margin: 0}}>{urlParams.get("lifecycle")}</h2>
                        </Form.Item>
                    }

                    {urlParams.get("active") == "true" ?
                        <Form.Item name="isLoanUnit" label="Is Loan Unit">
                            <Switch defaultChecked={isLoanUnit} onChange={onIsLoanUnitChange} />
                        </Form.Item> :
                        <Form.Item label="Is Loan Unit">
                            <h2 style={{margin: 0}}>{urlParams.get("isLoanUnit") == "true" ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}</h2>
                        </Form.Item>
                    }

                    {urlParams.get("active") == "true" ?
                        <Form.Item name="isBrood" label="Is Brood">
                            <Switch defaultChecked={isBrood} onChange={onIsBroodChange} />
                        </Form.Item> :
                        <Form.Item label="Is Brood Fish">
                            <h2 style={{margin: 0}}>{urlParams.get("isBrood") == "true" ? OTHERSYSPARAM("YES") : OTHERSYSPARAM("NO")}</h2>
                        </Form.Item>
                    }

                    {urlParams.get("active") == "true" ?
                        <Form.Item name="gender" label="Gender">
                            <GenderSelect withBlank={false} defaultValue={urlParams.get("gender")} onChange={setGenderId}/>
                        </Form.Item> :
                        <Form.Item label="Gender">
                            <h2 style={{margin: 0}}>{urlParams.get("gender")}</h2>
                        </Form.Item>
                    }

                    {urlParams.get("active") == "true" ?
                        <Form.Item name="tagId" label="RFID">
                            <Input maxLength={25} placeholder="RFID"/>
                        </Form.Item> :
                        <Form.Item label="RFID">
                            <h2 style={{margin: 0}}>{urlParams.get("tagId")}</h2>
                        </Form.Item>
                    }
                    
                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            {urlParams.get("active") == "true" ?
                                <>
                                <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined />}>Update</Button>
                                <Button type="primary" htmlType="button" disabled={disableButton} onClick={onUnregister} icon={<MinusOutlined />}>Stock-Out</Button>
                                </> : 
                                null
                            }
                            <Button type="primary" htmlType="button" onClick={onCancel} disabled={disableButton} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onCancel()} 
                        title="Tagged Marine Life:"
                        subTitle="Update tagged marine life record"/>} />
            </Footer>
        </Layout>
    )
}

export default FishUpdate