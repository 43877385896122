import { Table, Row, Col, Space, Button, Form, Collapse, DatePicker, message, Pagination, Modal, Spin } from 'antd'
import { PlusOutlined, DownloadOutlined, FileExcelOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, APPJSON, DATEFORMAT, DATETIMEFORMAT, FISHMARINELIFENAME, FISHMARINELIFEPKEY, LOADING, PAGESIZE, PAGINATIONSIZE, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { refreshUserSession, getUserSiteId, getUserAuthToken, OTHERSYSPARAM, getUserSiteName } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout_2Columns } from "../Common/Layout"
import moment from 'moment'
import CommonSearchFormItem from '../Common/CommonSearchFormItem'

const { Panel } = Collapse
const { RangePicker } = DatePicker
const { confirm } = Modal

//----------
// Component
//----------
const HatchingTable = () => {
    const FileDownload = require('js-file-download')
    const [disableButton, setDisableButton] = useState("")
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    
    //const [siteId, setSiteId] = useState(0)
    const [batchId, setBatchId] = useState(0)
    const [batch, setBatch] = useState("")
    const [storageId, setStorageId] = useState(0)
    const [storage, setStorage] = useState("")
    const [marineLifeId, setMarineLifeId] = useState(FISHMARINELIFEPKEY)
    const [marineLife, setMarineLife] = useState(FISHMARINELIFENAME)
    const [speciesId, setSpeciesId] = useState(0)
    const [species, setSpecies] = useState("")
    const navigate = useNavigate()
    const [dateRange, setDateRange] = useState([moment(moment().subtract(OTHERSYSPARAM("BACK_DATE_MONTH"), "months"), UNIDATEFORMAT), moment(moment(), UNIDATEFORMAT)])
        
    const [hatchingDataSource, setHatchingDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //----------------
    // Search hatching
    //----------------
    const searchHatching = (currentPage, urlParamSearchStoragId = 0) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}hatching/`, {
            params: { 
                site: getUserSiteId(), 
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: urlParamSearchStoragId != 0 ? urlParamSearchStoragId : storageId,
                marine_life: marineLifeId,
                species: speciesId,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search hatching record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( hatching => {
            data.push({
                key: hatching.pKey,
                id: hatching.id,
                batch: hatching.batch_data.id,
                storage: hatching.storage_data.name,
                
                percentage: hatching.percentage,
                eggQuantityML: numberWithCommas(hatching.egg_quantity_ml),
                storageTotalQuantityML: hatching.storage_total_quantity_ml,
                
                hatchingDateTime: moment(hatching.hatchedOn).format(DATETIMEFORMAT),
                hoursFromSpawning: hatching.hours_from_spawning,
                
                batchId: hatching.batch_data.pKey,
                batchType: hatching.batch_data.batch_type_data.name,
                species: hatching.batch_data.species_data.short_name,
                storageId: hatching.storage_data.pKey,
                acquiringMethod: hatching.batch_data.acquiring_method_data.name,
                lifecycle: hatching.lifecycle_data.name,
                lifecycleId: hatching.lifecycle_data.pKey,
                aquacultureStage: hatching.aquaculture_stage_data.name,
                aquacultureStageId: hatching.aquaculture_stage_data.pKey,
                remark: hatching.remark,
                
                hatchedOn: moment(hatching.hatchedOn).format(UNIDATETIMEFORMAT),
                observedOn: moment(hatching.observedOn).format(UNIDATETIMEFORMAT),
                createdOn: moment(hatching.createdOn).format(DATETIMEFORMAT),
                updatedOn: moment(hatching.updatedOn).format(DATETIMEFORMAT),

                availableEggQuantityML: hatching?.eggstocksummary.quantity != null ? hatching.eggstocksummary.quantity : 0,
                batchAquacultureStageId: hatching.batch_data.aquaculture_stage_data.pKey,
                batchAquacultureStage: hatching.batch_data.aquaculture_stage_data.name,
                batchLifecycleId: hatching.batch_data.lifecycle_data.pKey,
                batchLifecycle: hatching.batch_data.lifecycle_data.name
            })
        })
        
        setHatchingDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //--------------------------
    // Download search pdf
    //--------------------------
    const downloadSearchResult = (mode) => {
        setDisableButton("disabled")
        setIsLoading(true)
                
        // Build search criteria string for display in pdf
        let searchCriteria = `Site: ${getUserSiteName()}`
        searchCriteria = `${searchCriteria}\nDate Range: ${dateRange[0].format(DATEFORMAT)} to ${dateRange[1].format(DATEFORMAT)}`
        if(batchId != 0) searchCriteria = `${searchCriteria}\nBatch ID: ${batch}`
        if(storageId != 0) searchCriteria = `${searchCriteria}\nStorage: ${storage}`
        if(marineLifeId != 0) searchCriteria = `${searchCriteria}\nMarine Life: ${marineLife}`
        if(speciesId != 0) searchCriteria = `${searchCriteria}\nSpecies: ${species}`
                                        
        axios.get(`${ACISAPIURL}hatching/download/`, {
            params: { 
                site: getUserSiteId(), 
                fromDate: dateRange[0].format(UNIDATEFORMAT),
                toDate: dateRange[1].format(UNIDATEFORMAT),
                batch: batchId,
                storage: storageId,
                marine_life: marineLifeId,
                species: speciesId,
                search_criteria: searchCriteria,
                mode: mode
            },
            responseType: 'blob', // Important
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS") * 2),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response?.data?.type == APPJSON)
                message.info("Search returns no result.")
            else {
                const now = moment().format(UNIDATETIMEFORMAT)
                let ext = "pdf"
                if(mode == "excel") ext = "xlsx"
                FileDownload(response.data, `Hatching ${now}.${ext}`)
            }
        })
        .catch( error => {
            reportError(error, "Failed to download search result.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchHatching(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchHatching(1)
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.replace(window.location.href.split('?')[0])
    }

    //------------------
    // Go to create page
    //------------------
    const onCreateNew = () => {
        // 20221027
        // Prompt for marine life of choice.
        // Fish, prawn and seacucumber: under one group. Require batch aquaculture stage of INCUBATION or NURSERY and storage must contain eggs in order to hatch.
        // Crab: Require batch aquaculture stage of LARVAE and storage does not need to have any item type.
        navigate({ pathname: "/hatchingstorage", search: `?marineLife=${FISHMARINELIFENAME}` })
    }

    //-----------------------
    // On table row selected
    //-----------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                navigate({ 
                    pathname: "/hatchingupdate", 
                    search: `?pKey=${record.key}&id=${record.id}&batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&species=${record.species}&storageId=${record.storageId}&storage=${record.storage}&acquiringMethod=${record.acquiringMethod}&aquacultureStageId=${record.aquacultureStageId}&aquacultureStage=${record.aquacultureStage}&lifecycleId=${record.lifecycleId}&lifecycle=${record.lifecycle}&remark=${encodeURI(String(record.remark).trim())}&hatchedOn=${record.hatchedOn}&hoursFromSpawning=${record.hoursFromSpawning}&eggQuantityML=${record.eggQuantityML.replace(',', '')}&percentage=${record.percentage}&observedOn=${record.observedOn}&createdOn=${record.createdOn}&updatedOn=${record.updatedOn}&availableEggQuantityML=${record.availableEggQuantityML}&batchAquacultureStageId=${record.batchAquacultureStageId}&batchAquacultureStage=${record.batchAquacultureStage}&storageTotalQuantityML=${record.storageTotalQuantityML}&batchLifecycleId=${record.batchLifecycleId}&batchLifecycle=${record.batchLifecycle}`
                }) 
            }
        }
    }

    //---------------------
    // On date range change
    //---------------------
    const onDateRangeChange = (dateRange) => {
        setDateRange(dateRange)
    }

    //--------------------------
    // On search criteria change
    //--------------------------
    const onSearchBatchChange = (e, value) => {
        setBatchId(e)
        setBatch(value.children)
    }

    const onSearchStorageChange = (e, value) => {
        setStorageId(e)
        setStorage(value.children)
    }

    const onMarineLifeChange = (e, value) => {
        setMarineLifeId(e)
        setMarineLife(value.children)
    }

    const onSearchSpeciesChange = (e, value) => {
        setSpeciesId(e)
        setSpecies(value.children)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        if(urlParams.has("storageId")) {
            setStorageId(urlParams.get("storageId"))
            searchHatching(currentPage, urlParams.get("storageId"))
        }
        else
            searchHatching(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        //{ title: 'Hatching ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Hours From Spawning', dataIndex: 'hoursFromSpawning', key: 'hoursFromSpawning', align: "right", sorter: (a, b) => a.hoursFromSpawning - b.hoursFromSpawning },
        { title: 'Successful Pct.(%)', dataIndex: 'percentage', key: 'percentage', align: "right", sorter: (a, b) => a.percentage - b.percentage },
        { title: 'Egg Qty.(mL)', dataIndex: 'eggQuantityML', key: 'eggQuantityML', align: "right", sorter: (a, b) => stringNumberSorter(a.eggQuantityML,  b.eggQuantityML) },
        { title: 'Hatching Date Time', dataIndex: 'hatchingDateTime', key: 'hatchingDateTime', sorter: (a, b) => a.hatchingDateTime.localeCompare(b.hatchingDateTime) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Row>
            <Col span={24}>
                <Collapse defaultActiveKey={["0"]}>
                    <Panel header="Search Hatching Record" key="1">
                        <Form form={form} {...formLayout_2Columns}>
                            <Form.Item name="dateRange" label="Date Range"
                                rules={[
                                    { required: true, message: "Date range is required." },
                                ]}>
                                <RangePicker onChange={onDateRangeChange} defaultValue={dateRange} format={DATEFORMAT} style={{width: OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH")}}/>
                            </Form.Item>

                            <CommonSearchFormItem onSpeciesChange={onSearchSpeciesChange} onBatchChange={onSearchBatchChange} 
                                onStorageChange={onSearchStorageChange} showLockedStorage={false} defaultStorageId={storageId} showActiveBatchSwitch={true}
                                defaultMarineLifeId={marineLifeId}/>

                            <Row justify="center">
                                <Col span={6}></Col>
                                <Col span={12} style={{textAlign: "center"}}>
                                    <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("pdf")} disabled={disableButton} loading={isLoading} icon={<DownloadOutlined />} />
                                    <Button type="primary" htmlType="button" onClick={() => downloadSearchResult("excel")} disabled={disableButton} loading={isLoading} icon={<FileExcelOutlined />} />
                                    <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                </Col>
                                <Col span={6}></Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={13}></Col>
            <Col span={11} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>

        <Row><Col><Space><div /></Space></Col></Row>

        <Table bordered columns={columns} dataSource={hatchingDataSource} pagination={false} onRow={onRowClick}/>

        <Row><Col><Space><div /></Space></Col></Row>

        <Row justify="center">
            <Col span={3}></Col>
            <Col span={18} style={{textAlign: "center"}}>
                <Pagination
                    size={PAGINATIONSIZE}
                    total={totalRecord}
                    showTotal={showTotal}
                    pageSize={PAGESIZE}
                    current={currentPage}
                    hideOnSinglePage={false}
                    showSizeChanger={false}
                    onChange={onPaginationChange}/>
            </Col>
            <Col span={3} style={{textAlign: "end"}}><Button type="primary" htmlType="button" onClick={onCreateNew} icon={<PlusOutlined />}>New</Button></Col>
        </Row>
        </Spin>
        </>
    )
}

export default HatchingTable