import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, message, Space, Row, Col, Typography, Popconfirm, Card, Modal, Select, Divider, Input, Spin } from 'antd'
import { QuestionCircleOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons"
import { ACISAPIURL, LOADING, MEDIAMAXWIDTH, TAGGED } from '../Common/SystemParameter'
import { loadMarineLifeItemTypeUnitOfMeasurementStandardTable, reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import TextArea from 'antd/lib/input/TextArea'
import { useMediaQuery } from 'react-responsive'

const { Title } = Typography
const { confirm } = Modal
const { Option } = Select

const StockCountTaggedFishVarianceAdjustmentTable = () => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? 350 : 450)
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [itemType, setItemType] = useState([])
    const [uom, setUom] = useState("")
    
    const [transactionTypeOption, setTransactionTypeOption] = useState([])
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    // Readonly means viewing a completed stock count. No changes is allowed.
    const [readOnly, setReadOnly] = useState(urlParams.get("completedOn") == "" ? false : true)
    
    let prevSavedSurplus = []

    let adjustmentFishPKey = urlParams.getAll("fishPKey")
    let adjustmentFish = urlParams.getAll("fish")
    let adjustmentRemark = urlParams.getAll("remark")
    let adjustmentTransactionType = urlParams.getAll("transactionType")
    
    // Build variance adjustment initial values.
    let prevSavedVarianceAdjustment = []
    for(let i = 0; i < adjustmentFishPKey.length; i++) {
        prevSavedVarianceAdjustment.push({
            fishPKey: adjustmentFishPKey[i],
            fish: adjustmentFish[i],
            remark: adjustmentRemark[i],
            adjustment: adjustmentTransactionType[i]
        })
    }

    //--------------------------------
    // Go to tagged fish surplus
    //--------------------------------
    const goToTaggedFishSurplus = () => {
        // 2021-08-21
        // Skip tagged fish surplus. The adjustment should be made before stock count. Go straight to summary.
        navigate({ 
            pathname: "/stockcountsummary", 
            search: `?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}&marineLifeId=${urlParams.get("marineLifeId")}`
        }) 

        return

        //-------------------------------------------------------
        // Load existing tagged fish surplus. Lift state up
        //-------------------------------------------------------
        const getTaggedFishSurplus = async () => {
            setDisableButton("disabled")
        
            return await axios.get(`${ACISAPIURL}stockcount/taggedfish/surplus/`, {
                params: { 
                    stock_count: urlParams.get("pKey"),
                },
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                let data = []
                response.data.results.forEach( surplus => {
                    data.push({
                        fishPKey: surplus.fish,
                        fish: surplus.fish_data.id,
                        remark: surplus.remark,
                    })
                })
                prevSavedSurplus = data
            })
            .catch( error => {
                reportError(error, "Failed to get tagged marine life stock count surplus record.")
            })
            .finally(() => {
                setDisableButton("")
                refreshUserSession()
            })
        }

        //-------------------------------------------------------
        // Go to next step tagged fish variance adjustment. 
        let params = new URLSearchParams(`?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}&marineLifeId=${urlParams.get("marineLifeId")}`)

        // Retrieve previously saved tagged fish surplus
        // Build surplus record and go to surplus page.
        getTaggedFishSurplus()
        .then(() => {
            prevSavedSurplus.forEach( surplus => {
                params.append("fishPKey", surplus.fishPKey)
                params.append("fish", surplus.fish)
                params.append("remark", surplus.remark)
            })

            navigate({ 
                pathname: "/stockcounttaggedfishsurplus", 
                search: params.toString()
            }) 
        })
    }

    //---------------------------------------
    // Create tagged fish variance adjustment
    //---------------------------------------
    const saveTaggedFishVarianceAdjustment = (variances) => {
        if(readOnly) {
            goToTaggedFishSurplus()
            return
        }

        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)

        axios.post(`${ACISAPIURL}stockcount/taggedfish/varianceadjustment/create/`, {
            stock_count: urlParams.get("pKey"),
            variance_adjustment: variances
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Tagged item stock count variance adjustment record saved.")
            goToTaggedFishSurplus()
        })
        .catch( error => {
            reportError(error, `Failed to save tagged item stock count variance adjustment record.`)
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //--------------------------
    // Load all transaction type
    //--------------------------
    /*const getAllTransactionType = async () => {
        return await axios.get(`${ACISAPIURL}transactiontype/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            const excludes = [
                SYSPARAM("Transfer Egg (In)"),
                SYSPARAM("Transfer Egg (Out)"),
                SYSPARAM("Transfer Untagged Fish (In)"),
                SYSPARAM("Transfer Untagged Fish (Out)"),
                SYSPARAM("Transfer Tagged Fish (Out)"),
                SYSPARAM("Hatching (In)"),
                SYSPARAM("Hatching (Out)"),
                SYSPARAM("Egg Sampling (In)"),
                SYSPARAM("Egg Sampling (Out)"),
                SYSPARAM("Spawning (In)"),
                SYSPARAM("Return Loan"),
                SYSPARAM("Mortality"),
                SYSPARAM("Sales"),
                SYSPARAM("Stock Adjustment (Out)"),
            ]

            response.data.results = response.data.results.filter( type => !excludes.includes(type.pKey) )
            
            setTransactionTypeOption(response.data.results.filter( type => type.is_stock_in == false ).map( type => <Option key={type.pKey}>{type.name}</Option> ))
        })
        .catch( error => {
            reportError(error, "Failed to get all transaction types.")
        })
        .finally(() => {
            refreshUserSession()
        })
    }*/

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({
            pathname: "/stockcounttaggedfish",
            search: `?pKey=${urlParams.get("pKey")}&batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&createdOn=${urlParams.get("createdOn")}&updatedOn=${urlParams.get("updatedOn")}&completedOn=${urlParams.get("completedOn")}&marineLifeId=${urlParams.get("marineLifeId")}`
        })
    }

    //-----------------------
    // On finish
    //-----------------------
    const onFinish = values => {
        if(readOnly) {
            saveTaggedFishVarianceAdjustment()
            return
        }

        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Save {itemType} stock count variance adjustment record and proceed to next step?</p></Space>,
            onOk() { saveTaggedFishVarianceAdjustment(values.variances) },
            onCancel() {},
            centered: true
        })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        const init = async () => {
            const standardITUOMTable = await loadMarineLifeItemTypeUnitOfMeasurementStandardTable()
            const ituom = standardITUOMTable.filter( row => row.marine_life_data.pKey == urlParams.get("marineLifeId") && row.item_type_data.name.startsWith(TAGGED))
            setItemType(ituom[0].item_type_data.name)
            setUom(ituom[0].uom_data.uom)
        }

        init()
    }, [])

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>{`Stock Count Storage ID: ${urlParams.get("storage")}`}</Title>}>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Initialised On:</Card.Grid>
                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
            
                    { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                        </>
                    }
                    { urlParams.get("completedOn") != "" &&
                        <>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-label">Completed On:</Card.Grid>
                        <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("completedOn")}</Card.Grid>
                        </>
                    }
                </Card>
            </Form.Item>
        </Form>

        <Form form={form} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off" >
            <Row justify="center">
                <Col span={isTabletOrMobile ? 10 : 8} style={{textAlign: "center"}}>
                    <Card title={<>{itemType} <span style={{color: "red"}}>{OTHERSYSPARAM("DEFICIT")}</span> Stock Variance Adjustment</>} style={{textAlign: "left"}}>
                        Use stock-OUT transaction type for missing tagged item.
                    </Card>
                </Col>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>
            
            <Row justify="center">
                <Col span={isTabletOrMobile ? 13 : 8}>
                    <Form.List name="variances" initialValue={prevSavedVarianceAdjustment}>
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(field => (
                                <Space key={field.key} align="baseline" direction="vertical" /*wrap style={{borderBottomStyle: "dashed", borderBottomColor: "gray", borderBottomWidth: "1px", margin: 5}}*/>
                                    <span>
                                        <Divider orientation="right">
                                            {/* <div><CloseCircleOutlined onClick={() => remove(field.name)} /></div> */}
                                        </Divider>
                                    
                                        <Form.Item hidden={true}
                                            {...field}
                                            label="Fish PKey"
                                            name={[field.name, 'fishPKey']}
                                            fieldKey={[field.fieldKey, 'fishPKey']}>
                                            {/* rules={[{ required: true, message: 'Variance quantity is required.' }]}> */}
                                            <Input style={{width: optionWidth}}/>
                                        </Form.Item>

                                        <Form.Item
                                            {...field}
                                            label="Tag ID&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                            name={[field.name, 'fish']}
                                            fieldKey={[field.fieldKey, 'fish']}
                                            rules={[{ required: true, message: 'Variance quantity is required.' }]}>
                                            <Input readOnly={true} style={{width: optionWidth}} />
                                        </Form.Item>
                                                                        
                                        <Form.Item
                                            {...field}
                                            label="Remark&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                                            name={[field.name, 'remark']}
                                            fieldKey={[field.fieldKey, 'remark']}
                                            rules={[{ required: true, message: 'Remark is required.' }]}>
                                            <TextArea rows={1} maxLength={500} style={{width: optionWidth}} readOnly={readOnly} />
                                        </Form.Item>
                                        
                                        {/*<Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, curValues) =>
                                            prevValues.area !== curValues.area || prevValues.sights !== curValues.sights} >
                            
                                            {() => (
                                                <Form.Item
                                                    {...field}
                                                    label="Adjustment&nbsp;&nbsp;&nbsp;&nbsp;"
                                                    name={[field.name, "adjustment"]}
                                                    fieldKey={[field.fieldKey, "adjustment"]}
                                                    rules={[{ required: true, message: "Adjustment of variance is required." }]}>
                                                    <Select style={{width: optionWidth}} disabled={readOnly}>{transactionTypeOption}</Select>
                                                </Form.Item>
                                            )}
                                            </Form.Item>*/}
                                    </span>
                                </Space>
                            ))}
                            </> 
                        )}
                    </Form.List>
                </Col> 
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={10} style={{textAlign: "start"}}>
                    <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={4} />
                <Col span={10} style={{textAlign: "end"}}>
                    <Button type="primary" htmlType="submit" disabled={disableButton} icon={<RightOutlined />}>Next</Button>
                </Col>
            </Row>
        </Form>
        </Spin>
        </>
    )
}

export default StockCountTaggedFishVarianceAdjustmentTable
