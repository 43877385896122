import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, InputNumber, Popconfirm, message, DatePicker, PageHeader, Card, Modal, Descriptions, Input, Image, Upload, Spin } from 'antd'
import { SaveOutlined, QuestionCircleOutlined, DeleteOutlined, CloseOutlined, UploadOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, ACISIMGURL, CRABMARINELIFENAME, DATETIMEFORMAT, FALLBACK, GROWTHDEVELOPMENTIMGFOLDER, IMAGEHEIGHT, IMAGEWIDTH, LOADING, UNIDATEFORMAT, UNIDATETIMEFORMAT } from "../Common/SystemParameter"
import { getBatchFullAquacultureStageLifecycle, numberWithCommas, reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout } from "../Common/Layout"
import moment from 'moment'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal
const { TextArea } = Input

//----------
// Component
//----------
const GrowthDevelopmentUpdate = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()
    const [imageFile, setImageFile] = useState([])
    const [uploading, setUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
        
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [imageFileURI, setImageFileURI] = useState(`${urlParams.get("img")}?now=${moment().valueOf()}`)
    const [hideRemoveImageButton, setHideRemoveImageButton] = useState(urlParams.get("img") == "null" ?  true : false)

    const [observationDateTime, setObservationDateTime] = useState(moment(moment(urlParams.get("observedOn")), UNIDATEFORMAT))
    const [mouthOpenedDateTime, setMouthOpenedDateTime] = useState(moment(moment(urlParams.get("mouthOpenedOn")), UNIDATEFORMAT))
    const [noAgeColor, setNoAgeColor] = useState(urlParams.get("ageDays") != "null" ? "" : "Red")

    const [hideField, setHideField] = useState(false)

    //--------------------------
    // Update growth development
    //--------------------------
    const updateGrowthDevelopment = (advanceToGrowout) => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        form.validateFields()
        .then( values => {
            axios.patch(`${ACISAPIURL}growthdevelopment/update/${urlParams.get("pKey")}/`, {
                mouth_width: parseFloat(values.mouth_width).toFixed(2),
                weight: parseFloat(values.weight).toFixed(2),
                length: parseFloat(values.length).toFixed(2),
                mouthOpenedOn: mouthOpenedDateTime,
                remark: values.remark,
                observedOn: observationDateTime,
                advance_aquaculture_stage_lifecycle: advanceToGrowout,
                //fish: urlParams.get("fishId")
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Growth development record updated.")

                if(response.data.averages_updated == false) message.warn("Back dated growth development record did not update batch average weight and length.")

                navigate({ 
                    //pathname: "/growthdevelopmentmain",
                    pathname: "/growthdevelopmenttable", 
                    search: `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&observedOn=${moment(observationDateTime).format(UNIDATETIMEFORMAT)}`
                })
            })
            .catch( error => {
                reportError(error, `Failed to update growth development record.`)
            })
            .finally(() => {
                setDisableButton("")
                setIsLoading(false)
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //-----------------------
    // Remove image
    //-----------------------
    const removeImage = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.patch(`${ACISAPIURL}growthdevelopment/imgremove/${urlParams.get("pKey")}/`, {}, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Growth development image removed.")
            setHideRemoveImageButton(true)
            setImageFile([])
        })
        .catch( error => {
            reportError(error, `Failed to remove growth development image.`)
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }
    
    //--------
    // On next
    //--------
    const onSave = async (e) => {
        const fullAquacultureStageLifecycle = await getBatchFullAquacultureStageLifecycle(urlParams.get("batchId"))
        
        let currentAquacultureStageLifecycleIndex = -1
        for(let aquacultureStageLifecycle of fullAquacultureStageLifecycle) {
            currentAquacultureStageLifecycleIndex += 1
            if(aquacultureStageLifecycle.aquaculture_stage == urlParams.get("batchAquacultureStageId") && aquacultureStageLifecycle.lifecycle == urlParams.get("batchLifecycleId"))
                break
        }
        
        const nextAquacultureStageLifecycleIndex = currentAquacultureStageLifecycleIndex + 1
        let nextAquacultureStage = null
        if(fullAquacultureStageLifecycle.length >= nextAquacultureStageLifecycleIndex)
            nextAquacultureStage = fullAquacultureStageLifecycle[nextAquacultureStageLifecycleIndex]

        form.validateFields()
        .then( values => {
            if(mouthOpenedDateTime == null && form.getFieldValue("mouth_width") > 0) {
                message.warn("Mouth opened date time is not provided but mouth width is not zero.")
                return
            }

            if(mouthOpenedDateTime != null && !mouthOpenedDateTime._isValid && form.getFieldValue("mouth_width") > 0) {
                message.warn("Mouth opened date time is not provided but mouth width is not zero.")
                return
            }

            if(mouthOpenedDateTime != null && mouthOpenedDateTime._isValid && form.getFieldValue("mouth_width") == 0) {
                message.warn("Mouth opened date time is provided but mouth width is zero.")
                return
            }

            // Any aquaculture stage and lifecycle.
            if(nextAquacultureStage != null) 
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch aquaculture stage to {nextAquacultureStage.aquaculture_stage_data.name} and lifecycle of {nextAquacultureStage.lifecycle_data.name}?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { updateGrowthDevelopment(true) },
                    onCancel() { updateConfirmation() },
                    centered: true
                })
            else
                updateConfirmation()
        })
    }
    /*const onSave = (e) => {
        form.validateFields()
        .then( values => {
            // if(parseFloat(values.mouth_width) == 0 && parseFloat(values.length) == 0) {
            //     message.warn("Both mouth width and length are zero.")
            //     return
            // }

            // Growout should have weight when sampling.
            // if(urlParams.get("batchAquacultureStageId") == SYSPARAM("Grow Out") && parseFloat(values.weight) == 0) {
            //     message.warn("Zero weight for growout. Please enter weight before proceed.")
            //     return
            // }

            if(mouthOpenedDateTime == null && form.getFieldValue("mouth_width") > 0) {
                message.warn("Mouth opened date time is not provided but mouth width is not zero.")
                return
            }

            if(mouthOpenedDateTime != null && !mouthOpenedDateTime._isValid && form.getFieldValue("mouth_width") > 0) {
                message.warn("Mouth opened date time is not provided but mouth width is not zero.")
                return
            }

            if(mouthOpenedDateTime != null && mouthOpenedDateTime._isValid && form.getFieldValue("mouth_width") == 0) {
                message.warn("Mouth opened date time is provided but mouth width is zero.")
                return
            }

            // Spawning
            if(urlParams.get("batchAquacultureStage").startsWith(SPAWNING)) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch aquaculture stage to INCUBATION?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { updateGrowthDevelopment(true) },
                    onCancel() { updateConfirmation() },
                    centered: true
                })
            }
            // Incubation
            else if(urlParams.get("batchAquacultureStage").startsWith(INCUBATION)) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch aquaculture stage to NON-FEEDING LARVAE?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { updateGrowthDevelopment(true) },
                    onCancel() { updateConfirmation() },
                    centered: true
                })
            }
            // Non-Feeding Larvae
            else if(urlParams.get("batchAquacultureStage").startsWith(NURSERY) && urlParams.get("lifecycle").startsWith(NONFEEDINGLARVAE)) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch aquaculture stage to FEEDING LARVAE?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { updateGrowthDevelopment(true) },
                    onCancel() { updateConfirmation() },
                    centered: true
                })
            }
            // Feeding Larvae
            else if(urlParams.get("batchAquacultureStage").startsWith(NURSERY) && urlParams.get("lifecycle").startsWith(FEEDINGLARVAE)) {
                confirm({
                    icon: <QuestionCircleOutlined />,
                    content: <Space><p>Advance batch aquaculture stage to GROW OUT (FRIES)?</p></Space>,
                    okText: "Yes",
                    cancelText: "No",
                    onOk() { updateGrowthDevelopment(true) },
                    onCancel() { updateConfirmation() },
                    centered: true
                })
            }
            else { // Grow Out
                if(urlParams.get("batchLifecycle").startsWith(FRIES))
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Advance batch lifecycle to JUVENILE?</p></Space>,
                        okText: "Yes",
                        cancelText: "No",
                        onOk() { updateGrowthDevelopment(true) },
                        onCancel() { updateConfirmation() },
                        centered: true
                    })
                else if(urlParams.get("batchLifecycle").startsWith(JUVENILE)) 
                    confirm({
                        icon: <QuestionCircleOutlined />,
                        content: <Space><p>Advance batch lifecycle to MATURED?</p></Space>,
                        okText: "Yes",
                        cancelText: "No",
                        onOk() { updateGrowthDevelopment(true) },
                        onCancel() { updateConfirmation() },
                        centered: true
                    })
                else
                    updateConfirmation()
            }
        })
    }*/

    //-------------------------------
    // Delete growth development data
    //-------------------------------
    const onDelete = () => {
        // Disable button.
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.delete(`${ACISAPIURL}growthdevelopment/delete/${urlParams.get("pKey")}/`, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info(`Growth development record deleted.`)
            navigate({ 
                //pathname: "/growthdevelopmentmain",
                pathname: "/growthdevelopmenttable", 
                search: `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&observedOn=${urlParams.get("observedOn")}`
            })
        })
        .catch( error => {
            reportError(error, "Failed to delete growth development record.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------------
    // Get batch marine life type
    //---------------------------------
    const getBatchMarineLifeType = () => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}batch/${urlParams.get("batchId")}`, {
            params: {},
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            if(response.data.species_data.marine_life_data.name == CRABMARINELIFENAME)
                setHideField(true)
        })
        .catch( error => {
            reportError(error, "Failed to get batch marine life type.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------------------------------
    // Update grwoth development confirmation
    //---------------------------------------
    const updateConfirmation = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Update growth development record confirmed?</p></Space>,
            onOk() { updateGrowthDevelopment(false) },
            onCancel() {},
            centered: true
        })
    }
    
    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            //pathname: "/growthdevelopmentmain",
            pathname: "/growthdevelopmenttable", 
            search: `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&storageId=${urlParams.get("storageId")}&storage=${urlParams.get("storage")}&observedOn=${urlParams.get("observedOn")}`
        })
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setObservationDateTime(datetime)
    }

    const onMouthOpenedDateTimeChange = (datetime) => {
        setMouthOpenedDateTime(datetime)
    }

    //------------------
    // Custom validation
    //------------------
    const validateObservationDateTime = (() => {
        if(observationDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Observation date time is required."))
    })

    //------------------------
    // Upload image properties
    //------------------------
    const props = {
        name: "img",
        action: `${ACISAPIURL}growthdevelopment/imgupload/${urlParams.get("pKey")}/`,
        timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")) * 10,
        headers: {
            Authorization: `Token ${getUserAuthToken()}`,
        },
        fileList: imageFile,
        onRemove: file => {
            setImageFile([])
            removeImage()
            setHideRemoveImageButton(true)
        },
        beforeUpload: file => {
            setImageFile([file])
        },
        onChange(info) {
            setUploading(true)

            if(info.file.status !== 'uploading') {
                setUploading(false)
                setIsLoading(true)
            }

            if(info.file.status === 'done') {
                setUploading(false)
                setIsLoading(false)

                const fileArray = info.file.name.split('.')
                const fileExtention = fileArray[fileArray.length - 1]
                setImageFileURI(`${ACISIMGURL}${GROWTHDEVELOPMENTIMGFOLDER}/${urlParams.get("pKey")}.${fileExtention}?now=${moment().valueOf()}`)
                setHideRemoveImageButton(false)
                message.info("Image uploaded.")
            }
            else if(info.file.status === 'error') {
                setUploading(false)
                message.error(`"${info.file.name}" upload failed.`)
                message.error(info.file.response?.detail)
            }
        },
        maxCount: 1,
        imageFile,
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        form.setFieldsValue({
            mouth_width: urlParams.get("mouthWidth"),
            weight: urlParams.get("weight"),
            length: urlParams.get("length"),
            remark: urlParams.get("remark"),
        })

        getBatchMarineLifeType()
    }, [])

    return(
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Growth Development">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Update growth development record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Growth Development ID: ${urlParams.get("id")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Storage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("storage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Record Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                            {
                                parseInt(urlParams.get("fishId")) == 0 &&
                                <>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Average Weight (gram):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(urlParams.get("batchAverageWeight"))}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Average Length (mm):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(urlParams.get("batchAverageLength"))}</Card.Grid>
                                </>
                            }
                            {
                                parseInt(urlParams.get("fishId")) != 0 &&
                                <>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Tagged Marine Life Average Weight (gram):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(urlParams.get("batchAverageWeight"))}</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Tagged Marine Life Average Length (mm):</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{numberWithCommas(urlParams.get("batchAverageLength"))}</Card.Grid>
                                </>
                            }
                            
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Daily Sample No.:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("dailySampleNumber")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Age (days):</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper" style={{color: noAgeColor}}>{urlParams.get("ageDays") != "null" ? numberWithCommas(urlParams.get("ageDays")) : OTHERSYSPARAM("NA")}</Card.Grid>
                            {
                                parseInt(urlParams.get("fishId")) != 0 &&
                                <>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-label">Tag ID:</Card.Grid>
                                    <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("fish")}</Card.Grid>
                                </>
                            }
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Created On:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("createdOn")}</Card.Grid>
                     
                            { urlParams.get("updatedOn") != urlParams.get("createdOn") &&
                                <>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-label">Updated On:</Card.Grid>
                                <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("updatedOn")}</Card.Grid>
                                </>
                            }
                        </Card>
                    </Form.Item>

                    <Row>
                        <Col span={6} />
                            <Col span={12} style={{textAlign: "center"}}>
                                <Image 
                                    width={IMAGEWIDTH} 
                                    height = {IMAGEHEIGHT}
                                    src={imageFileURI}
                                    fallback={FALLBACK}
                                    preview={true} 
                                    hidden={hideRemoveImageButton}/>
                            </Col>
                        <Col span={6} />
                    </Row>

                    <Form.Item name="imageFile" label="Image File">
                        <Input.Group>
                            <Button onClick={removeImage} icon={<DeleteOutlined />} hidden={hideRemoveImageButton}/>                                                        
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />} loading={uploading}>Select File</Button>
                            </Upload>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item name="observationDateTime" label="Observation Date Time"
                        rules={[
                            { required: true, validator: validateObservationDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={observationDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="mouthOpenedDateTime" label="Mouth Opened Date Time" hidden={hideField}>
                        <DatePicker showTime onChange={onMouthOpenedDateTimeChange} defaultValue={mouthOpenedDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="mouth_width" label="Mouth Width (mm)" hidden={hideField}
                        rules={[
                            { required: true, message: "Mouth width is required."},
                        ]}>
                        <InputNumber defaultValue={0} min={0} max={100} step={0.10} precision={2} placeholder="Mouth Width(mm)"/>
                    </Form.Item>

                    <Form.Item name="weight" label="Weight (g)"
                        rules={[
                            { required: true, message: "Weight is required."},
                        ]}>
                        <InputNumber defaultValue={0} min={0} max={100000} step={10} precision={2} placeholder="Weight(g)"/>
                    </Form.Item>

                    <Form.Item name="length" label="Length (mm)" hidden={hideField}
                        rules={[
                            { required: true, message: "Length is required."},
                        ]}>
                        <InputNumber defaultValue={0} min={0} max={100000} step={1} precision={2} placeholder="Length(mm)"/>
                    </Form.Item>

                    <Form.Item name="remark" label="Observation" >
                        <TextArea rows={3}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={6}></Col>
                        <Col span={12} style={{textAlign: "center"}}>
                            <Button type="primary" htmlType="submit" disabled={disableButton} icon={<SaveOutlined/>}>Update</Button>
                            <Popconfirm title="Delete growth development record confirmed?" onConfirm={onDelete} okText="Yes" cancelText="No">
                                <Button danger type="primary" htmlType="button" disabled={disableButton} icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Button type="primary" htmlType="button" disabled={disableButton} onClick={onBack} icon={<CloseOutlined />}>Cancel</Button>
                        </Col>
                        <Col span={6}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Growth Development:"
                    subTitle="Update growth development record"/>} />
            </Footer>
        </Layout>
        </Spin>
    )
}

export default GrowthDevelopmentUpdate