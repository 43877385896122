import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, Typography, Tag, Table, Popconfirm, message, DatePicker, Card, PageHeader, Modal, Descriptions, Pagination } from 'antd'
import { SaveOutlined, LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, MENUPATH_MORTALITY, PAGESIZE, PAGINATIONSIZE, UNIDATETIMEFORMAT } from '../Common/SystemParameter'
import { reportError } from '../Common/Utility'
import axios from "axios"
import { refreshUserSession, getUserAuthToken, SYSPARAM, OTHERSYSPARAM, getUserSiteId } from "../Common/UserSession"
import { formLayout } from '../Common/Layout'
import TextArea from 'antd/lib/input/TextArea'
import MortalityReasonSelect from './MortalityReasonSelect'
import moment from 'moment'
import { releaseStorage } from '../Common/releaseStorage'
import { BatchColumn, DesktopColumns, RFIDColumn } from '../Common/CommonTableColumns'

const { Header, Footer, Content } = Layout
const { Title } = Typography
const { confirm } = Modal

//----------
// Component
//----------
const MortalityTaggedFishNew = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const [form] = Form.useForm()

    const [mortalityReasonId, setMortalityReasonId] = useState(0)
    const [mortalityDateTime, setMortalityDateTime] = useState(moment(), UNIDATETIMEFORMAT) 

    const [selectedFishKeys, setSelectedFishKeys] = useState([])
    const [fishDataSource, setFishDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
                       
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    //---------------
    // Search fish
    //---------------
    //const searchFish = (batchId, storageId) => {
    const searchFish = (storageId, currentPage) => {
        setDisableButton("disabled")
        
        axios.get(`${ACISAPIURL}fish/`, {
            params: { 
                site: getUserSiteId(),
                //batch: batchId,
                storage: storageId,
                active: true,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search fish list.")
        })
        .finally(() => {
            setDisableButton("")
            refreshUserSession()
        })
    }

    const dataPush = (response) => {
        const data = []
        response.data.results.forEach( fish => {
            data.push({
                key: fish.pKey,
                id: fish.id,
                species: fish.batch_data.species_data.short_name,
                acquiringMethod: fish.batch_data.acquiring_method_data.name,
                aquacultureStage: fish.aquaculture_stage_data.name,
                lifecycle: fish.lifecycle_data.name,
                storage: fish.storage_data.name,
                sourceStorageId: fish.storage_data.pKey,
                weight: fish.average_per_unit_weight_gram,
                gender: fish.gender_data.name,
                genderId: fish.gender_data.pKey,
                tagId: fish.tag_id != null ? fish.tag_id : "",
                isBrood: fish.isBrood,
                batch: fish.batch_data.id,
                active: fish.active
            })
        })
        
        setFishDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //-----------------
    // Create mortality
    //-----------------
    const createMortalityRecord = async () => {
        // Disable button.
        setDisableButton("disabled")
        
        axios.post(`${ACISAPIURL}mortality/taggedfish/create/`, {
            batch: urlParams.get("batchId"),
            storage: urlParams.get("storageId"),

            item_type: urlParams.get("itemTypeId"),
            item_type_name: urlParams.get("itemType"),
            quantity: selectedFishKeys.length,
            
            reason: mortalityReasonId,
            mortality_datetime: mortalityDateTime,
            remark: form.getFieldValue("remark"),

            fish: selectedFishKeys,
            uom: urlParams.get("uomId")
        }, { 
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            message.info("Mortality record saved.")

            navigate({ 
                pathname: MENUPATH_MORTALITY
            })

            releaseStorage(urlParams.get("batchId"), urlParams.get("batch"), urlParams.get("storageId"))
        })
        .catch( error => {
            reportError(error, `Failed to save mortality record.`)
        })
        .finally(() => {
            setDisableButton("")
            refreshUserSession()
        })
    }

    //--------
    // On next
    //--------
    const onSave = (e) => {
        if(selectedFishKeys.length == 0) {
            message.warn("Please select tagged marine life before proceed.")
            return
        }

        saveConfirmation()
    }

    //-------------
    // Confirm save
    //-------------
    const saveConfirmation = () => {
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Mortality for tagged marine life is irreversible. Confirmed?</p></Space>,
            onOk() { createMortalityRecord() },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: "/mortality"
        })
    }

    //---------------------------
    // On mortality reason change
    //---------------------------
    const onMortalityReasonChange = (e, value) => {
        setMortalityReasonId(e)
    }

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setMortalityDateTime(datetime)
    }

    //---------------------------
    // On fish selection change
    //---------------------------
    const onSelectionChange = (selectedRowKeys, selectedRows) => {
        setSelectedFishKeys(selectedRowKeys)
    }

    const rowSelection = {
        selectedFishKeys,
        onChange: onSelectionChange
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchFish(urlParams.get("storageId"), page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //------------------
    // Custom validation
    //------------------
    const validateMortalityReason = (() => {
        if(mortalityReasonId != 0) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Mortality reason is required."))
    })

    const validateMortalityDateTime = (() => {
        if(mortalityDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Mortality date time is required."))
    })

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        //searchFish(urlParams.get("batchId"), urlParams.get("storageId"), currentPage)
        searchFish(urlParams.get("storageId"), currentPage)

        form.setFieldsValue({
            remark: ""
        })
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        BatchColumn(),
        { title: 'Storage ID', dataIndex: 'storage', key: 'storage', sorter: (a, b) => a.storage.localeCompare(b.storage) },
        { title: 'Tag ID', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        ...DesktopColumns(),
        { title: 'Weight (g)', dataIndex: 'weight', key: 'weight', sorter: (a, b) => a.weight - b.weight },
        { title: 'Gender', dataIndex: 'genderId', key: 'genderId', sorter: (a, b) => a.genderId - b.genderId,
            render: (genderId, record) => {
                if(genderId == SYSPARAM("Male"))
                    return <Tag color="blue">{record.gender}</Tag>
                else if(genderId == SYSPARAM("Female"))
                    return <Tag color="red">{record.gender}</Tag>
                else 
                    return <Tag color="green">{record.gender}</Tag>
            }
        },
        { title: 'Is Brood', dataIndex: 'isBrood', key: 'isBrood', sorter: (a, b) => a.isBrood - b.isBrood,
            render: (isBrood) => {
                if(isBrood)
                    return <Tag color="blue">{OTHERSYSPARAM("YES")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("NO")}</Tag>
            }
        },
        RFIDColumn()
        /*{ title: 'Status', dataIndex: 'active', key: 'active', 
            render: (active) => {
                if(active)
                    return <Tag color="blue">{OTHERSYSPARAM("ACTIVE")}</Tag>
                else
                    return <Tag color="red">{OTHERSYSPARAM("INACTIVE")}</Tag>
            }
        },*/
    ]

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader onBack={() => onBack()} 
                    title="Mortality">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create mortality record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout}>
                    <Form.Item>
                        <Card title={<Title level={5}>{`Storage: ${urlParams.get("storage")}`}</Title>}>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch ID:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batch")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Batch Type:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("batchType")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Species:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("species")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Aquaculture Stage:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("aquacultureStage")}</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-label">Lifecycle:</Card.Grid>
                            <Card.Grid hoverable={false} className="infocard-gridstyle-wrapper">{urlParams.get("lifecycle")}</Card.Grid>
                        </Card>
                    </Form.Item>

                    <Form.Item name="mortalityReason" label="Possible Mortality Reason"
                        rules={[
                            { required: true, validator: validateMortalityReason },
                        ]}>
                        <MortalityReasonSelect withBlank={false} onChange={onMortalityReasonChange} />
                    </Form.Item>

                    <Form.Item name="mortalityDateTime" label="Mortality Date Time"
                        rules={[
                            { required: true, validator: validateMortalityDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={mortalityDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>
                    
                    <Form.Item name="remark" label="Remark"
                        rules={[
                            { required: true, message: "Remark is required." },
                        ]}>
                        <TextArea rows={3} maxLength={500}/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={1} />
                        <Col span={11} style={{textAlign: "start"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                        </Col>
                        <Col span={11} style={{textAlign: "end"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={1} />
                    </Row>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={22}>
                            <Table bordered columns={columns} dataSource={fishDataSource} pagination={false} rowSelection={rowSelection} /*onRow={onRowClick}*/ />
                        </Col>
                    </Row>

                    <Row><Col><Space><div /></Space></Col></Row>
                    
                    <Row justify="center">
                        <Col span={1} />
                        <Col span={3} style={{textAlign: "start"}}>
                            <Popconfirm title="Your selection will be lost. Confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                                <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                            </Popconfirm>
                        </Col>
                        <Col span={16} style={{textAlign: "center"}}>
                            <Pagination
                                size={PAGINATIONSIZE}
                                total={totalRecord}
                                showTotal={showTotal}
                                pageSize={PAGESIZE}
                                current={currentPage}
                                hideOnSinglePage={false}
                                showSizeChanger={false}
                                onChange={onPaginationChange}/>
                        </Col>
                        <Col span={3} style={{textAlign: "end"}}>
                            <Button type="primary" htmlType="submit" icon={<SaveOutlined/>}>Save</Button>
                        </Col>
                        <Col span={1} />
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Mortality:"
                    subTitle="Create mortality record"/>} />
            </Footer>
        </Layout>
    )
}

export default MortalityTaggedFishNew