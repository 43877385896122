import { Table, Row, Col, Space, Button, Form, Collapse, Popconfirm, message, Select, Card, Typography, Pagination, Spin } from 'antd'
import { LeftOutlined } from "@ant-design/icons"
import  { useEffect, useState } from 'react'
import axios from "axios"
import { ACISAPIURL, LOADING, MEDIAMAXWIDTH, MENUPATH_GROWTHDEV, PAGESIZE, PAGINATIONSIZE, TAGGED, UNTAGGED } from "../Common/SystemParameter"
import { refreshUserSession, getUserAuthToken, OTHERSYSPARAM } from "../Common/UserSession"
import { useNavigate } from 'react-router-dom'
import { numberWithCommas, reportError, stringNumberSorter } from "../Common/Utility"
import { formLayout, formLayout_2Columns } from "../Common/Layout"
import { useMediaQuery } from 'react-responsive'

const { Panel } = Collapse
const { Option } = Select
const { Title } = Typography

//----------
// Component
//----------
const GrowthDevelopmentStorageTable = () => {
    const [optionWidth, setOptionWidth] = useState(OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [disableButton, setDisableButton] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    const [searchForm] = Form.useForm()
    const navigate = useNavigate()
    const [storageOption, setStorageOption] = useState([])
        
    const [storageDataSource, setStorageDataSource] = useState([])
    const [totalRecord, setTotalRecord] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    
    // Unpack url search parameters
    const urlParams = new URLSearchParams(window.location.search)

    const [searchStorageId, setSearchStorageId] = useState(urlParams.has("storageId") ? urlParams.get("storageId") : 0)

    //---------------
    // Search storage
    //---------------
    const searchStorage = (currentPage) => {
        setDisableButton("disabled")
        setIsLoading(true)
        
        axios.get(`${ACISAPIURL}storagepaginatedbyitemtype/`, {
            params: { 
                batch: urlParams.get("batchId"),
                id: searchStorageId,
                item_types_like: `${TAGGED}, ${UNTAGGED}`,
                empty: false,
                page: currentPage
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            dataPush(response)
        })
        .catch( error => {
            reportError(error, "Failed to search storage list.")
        })
        .finally(() => {
            setDisableButton("")
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //-----------
    // Reset page
    //-----------
    const onReset = () => {
        window.location.reload()
    }

    const dataPush = (response) => {
        let data = []
        response.data.results.forEach( storage => {
            data.push({
                key: storage.id,
                id: storage.storage_name,
                storageId: storage.storage,
                storageType: storage.storage_type_data.name,
                batchId: storage.batch_data.pKey,
                batch: storage.batch_data.id,
                batchType: storage.batch_data.batch_type_data.name,
                batchTypeId: storage.batch_data.batch_type_data.pKey,
                acquiringMethod: storage.batch_data.acquiring_method_data.name,
                acquiringMethodId: storage.batch_data.acquiring_method_data.pKey,
                aquacultureStage: storage.batch_data.aquaculture_stage_data.name,
                aquacultureStageId: storage.batch_data.aquaculture_stage_data.pKey,
                lifecycle: storage.batch_data.lifecycle_data.name,
                lifecycleId: storage.batch_data.lifecycle_data.pKey,
                speciesId: storage.batch_data.species_data?.pKey,
                species: storage.batch_data.species_data?.short_name,
                itemTypeId: storage.item_type,
                itemTypeLabel: `${storage.item_type_name} (${storage.uom_name})`,
                itemType: storage.item_type_name,
                uomId: storage.uom,
                uom: storage.uom_name,
                quantity: numberWithCommas(storage.quantity),
                batchAverageWeight: storage.batch_data.average_per_unit_weight_gram,
                batchAverageLength: storage.batch_data.average_per_unit_length_mm,
                ageDays: storage.batch_data.age_days,
            })
        })

        setStorageDataSource(data)

        // Total pages
        setTotalRecord(response.data.count)
    }

    //-------------
    // Load Storage
    //-------------
    const getStorage = (batchId) => {
        setIsLoading(true)

        axios.get(`${ACISAPIURL}storageassigned/`, {
            params: {
                batch: batchId,
            },
            timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
            headers: {"Authorization": `Token ${getUserAuthToken()}`}
        })
        .then( response => {
            let options = []
            options.push(<Option key={0}>{" "}</Option>) // Blank
            options = options.concat(response.data.results.map( storage => <Option key={storage.pKey}>{storage.name}</Option>))
            setStorageOption(options)
        })
        .catch( error => {
            reportError(error, "Failed to get storage assigned.")
        })
        .finally(() => {
            setIsLoading(false)
            refreshUserSession()
        })
    }

    //---------------
    // On page change
    //---------------
    const onPaginationChange = (page) => {
        setCurrentPage(page)
        searchStorage(page)
    }

    const showTotal = (total) => {
        return `Total ${total} record(s)`
    }

    //----------
    // On search
    //----------
    const onSearch = () => {
        setCurrentPage(1)
        searchStorage(1)
    }

    //------------------------------
    // On table row selection change
    //------------------------------
    const onRowClick = (record, rowIndex) => {
        return {
            onClick: () => { 
                onNext(record)
            }
        }
    }

    //------------------
    // Go to create page
    //------------------
    const onNext = (record) => {
        let searchParams = `?batchId=${record.batchId}&batch=${record.batch}&batchType=${record.batchType}&species=${record.species}&storageId=${record.storageId}&storage=${record.id}&acquiringMethod=${record.acquiringMethod}&aquacultureStageId=${record.aquacultureStageId}&aquacultureStage=${record.aquacultureStage}&lifecycleId=${record.lifecycleId}&lifecycle=${record.lifecycle}&batchAverageWeight=${record.batchAverageWeight}&batchAverageLength=${record.batchAverageLength}&ageDays=${record.ageDays}&observedOn=${urlParams.get("observedOn")}`
        if(urlParams.has("fromMain"))
            searchParams = `${searchParams}&fromMain=true`
        else if(urlParams.has("fromDailyAverage"))
            searchParams = `${searchParams}&fromDailyAverage=true`

        // If is untagged fish, proceed as usual.
        if(record.itemType.startsWith(UNTAGGED))
            navigate({ 
                pathname: "/growthdevelopmentnew", 
                search: searchParams
            })
        else if(record.itemType.startsWith(TAGGED)) { // If is tagged marine life, redirect to fish id growth development selection. 
            message.info("You have been redirected to Tagged Marine Life Growth Development page.")

            navigate({ 
                pathname: "/growthdevelopmentfish", 
                search: searchParams
            })
        }
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        const search = `?batchId=${urlParams.get("batchId")}&batch=${urlParams.get("batch")}&observedOn=${urlParams.get("observedOn")}`

        if(urlParams.has("fromMain"))
            navigate({ 
                //pathname: "/growthdevelopmentmain", 
                pathname: "/growthdevelopmenttable", 
                search: search        
            })
        else if(urlParams.has("fromDailyAverage"))
            navigate({ 
                pathname: MENUPATH_GROWTHDEV, 
                search: search        
            })
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        getStorage(urlParams.get("batchId"))
        searchStorage(currentPage)
    }, [])

    //--------------
    // Table columns
    //--------------
    const columns = [
        { title: 'Batch ID', dataIndex: 'batch', key: 'batch', sorter: (a, b) => a.batch.localeCompare(b.batch) },
        { title: 'Storage', dataIndex: 'id', key: 'id', sorter: (a, b) => a.id.localeCompare(b.id) },
        { title: 'Batch Type', dataIndex: 'batchType', key: 'batchType', sorter: (a, b) => a.batchType.localeCompare(b.batchType) },
        !isTabletOrMobile ? { title: 'Storage Type', dataIndex: 'storageType', key: 'storageType', sorter: (a, b) => a.storageType.localeCompare(b.storageType) } : {},
        !isTabletOrMobile ? { title: 'Acquiring Method', dataIndex: 'acquiringMethod', key: 'acquiringMethod', sorter: (a, b) => a.acquiringMethod.localeCompare(b.acquiringMethod) } : {},
        { title: 'Aquaculture Stage', dataIndex: 'aquacultureStage', key: 'aquacultureStage', sorter: (a, b) => a.aquacultureStage.localeCompare(b.aquacultureStage) },
        { title: 'Lifecycle', dataIndex: 'lifecycle', key: 'lifecycle', sorter: (a, b) => a.lifecycle.localeCompare(b.lifecycle) },
        { title: 'Item Type', dataIndex: 'itemTypeLabel', key: 'itemTypeLabel', sorter: (a, b) => a.itemTypeLabel.localeCompare(b.itemTypeLabel) },
        { title: 'Quantity', dataIndex: 'quantity', key: 'quantity', align: 'right', sorter: (a, b) => stringNumberSorter(a.quantity, b.quantity) },
    ]

    return(
        <>
        <Spin spinning={isLoading} size="large" tip={LOADING}>
        <Form {...formLayout}>
            <Form.Item>
                <Card title={<Title level={5}>Requirement</Title>}>
                    Storage must have tagged or untagged fish. Select untagged fish item type to create growth development record that represent batch average. 
                    Select tagged marine life to create growth development record that belong to that particular tagged marine life only.
                </Card>
            </Form.Item>
        </Form>

        <Form form={form} onFinish={onNext} {...formLayout}>
            <Row>
                <Col span={24}>
                    <Collapse defaultActiveKey={["0"]}>
                        <Panel header={`Search Storage In ${urlParams.get("batch")}`} key="1">
                            <Form form={searchForm} {...formLayout_2Columns}>
                                <Form.Item name="storageId" label="Storage ID">
                                    <Select style={{width: optionWidth}} onChange={setSearchStorageId} allowClear showSearch optionFilterProp="children"
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {storageOption}
                                    </Select>
                                </Form.Item>
                                
                                <Row justify="center">
                                    <Col span={6}></Col>
                                    <Col span={12} style={{textAlign: "center"}}>
                                        <Button type="primary" htmlType="button" onClick={onSearch} disabled={disableButton}>Search</Button>
                                        <Button danger type="primary" htmlType="button" onClick={onReset} disabled={disableButton}>Reset</Button>
                                    </Col>
                                    <Col span={6}></Col>
                                </Row>
                            </Form>
                        </Panel>
                    </Collapse>
                </Col>
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={12} style={{textAlign: "start"}}>
                    <Popconfirm title="Cancel create growth development record confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={12} />
            </Row>

            <Row><Col><Space><div /></Space></Col></Row>

            <Table bordered columns={columns} dataSource={storageDataSource} pagination={false} onRow={onRowClick}/>

            <Row><Col><Space><div /></Space></Col></Row>

            <Row justify="center">
                <Col span={3} style={{textAlign: "start"}}>
                    <Popconfirm title="Cancel create growth development record confirmed?" onConfirm={onBack} okText="Yes" cancelText="No">
                        <Button type="primary" htmlType="button" disabled={disableButton} icon={<LeftOutlined />}>Back</Button>
                    </Popconfirm>
                </Col>
                <Col span={18} style={{textAlign: "center"}}>
                    <Pagination
                        size={PAGINATIONSIZE}
                        total={totalRecord}
                        showTotal={showTotal}
                        pageSize={PAGESIZE}
                        current={currentPage}
                        hideOnSinglePage={false}
                        showSizeChanger={false}
                        onChange={onPaginationChange}/>
                </Col>
                <Col span={3} />
            </Row>
        </Form>
        </Spin>
        </>
    )
}

export default GrowthDevelopmentStorageTable