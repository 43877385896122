import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Space, Layout, Row, Col, InputNumber, message, PageHeader, Modal, Descriptions, DatePicker } from 'antd'
import { LeftOutlined, QuestionCircleOutlined, SaveOutlined } from "@ant-design/icons"
import ACISHeader from '../Common/ACISHeader'
import ACISFooter from '../Common/ACISFooter'
import { ACISAPIURL, DATETIMEFORMAT, MEDIAMAXWIDTH, MENUPATH_ENVIRONMENT, UNIDATEFORMAT } from "../Common/SystemParameter"
import { reportError } from "../Common/Utility"
import axios from "axios"
import { refreshUserSession, getUserAuthToken, getUserSiteId, OTHERSYSPARAM } from "../Common/UserSession"
import { formLayout_2Columns } from "../Common/Layout"
import StorageSelect from '../Common/StorageSelect'
import { useMediaQuery } from 'react-responsive'
import moment from 'moment'

const { Header, Footer, Content } = Layout
const { confirm } = Modal

//----------
// Component
//----------
const EnvironmentNew = () => {
    const navigate = useNavigate()
    const [disableButton, setDisableButton] = useState("")
    const contentHeight = OTHERSYSPARAM("NON_MOBILE_DEVICE_CONTENT_HEIGHT")
    const isTabletOrMobile = useMediaQuery({ maxWidth: MEDIAMAXWIDTH })
    const [optionWidth, setOptionWidth] = useState(isTabletOrMobile ? OTHERSYSPARAM("MOBILE_DEVICE_OPTION_WIDTH") : OTHERSYSPARAM("NON_MOBILE_DEVICE_OPTION_WIDTH"))
    const [form] = Form.useForm()
    
    const [storageId, setStorageId] = useState(0)
    const [envReadingDateTime, setEnvReadingDateTime] = useState(moment(moment(), UNIDATEFORMAT))
                
    //--------------------------
    // Create environment record
    //--------------------------
    const createEnvironmentRecord = () => {
        // Disable button.
        setDisableButton("disabled")
        
        form.validateFields()
        .then( values => {
            axios.post(`${ACISAPIURL}environment/create/`, {
                storage: storageId,
                createdOn: envReadingDateTime,
                do: parseFloat(values.do).toFixed(2),
                orp: parseFloat(values.orp).toFixed(2),
                pH: parseFloat(values.pH).toFixed(2),
                temperature: parseFloat(values.temperature).toFixed(2),
                salinity: parseFloat(values.salinity).toFixed(2),
                ammonia: parseFloat(values.ammonia).toFixed(2),
                nitrite: parseFloat(values.nitrite).toFixed(2),
                nitrate: parseFloat(values.nitrate).toFixed(2),
                tds: parseFloat(values.tds).toFixed(2),
            }, { 
                timeout: parseInt(OTHERSYSPARAM("TIMEOUT_MS")),
                headers: {"Authorization": `Token ${getUserAuthToken()}`}
            })
            .then( response => {
                message.info("Environment record saved.")
                navigate({ 
                    pathname: MENUPATH_ENVIRONMENT
                })
            })
            .catch( error => {
                reportError(error, `Failed to save environment data.`)
            })
            .finally(() => {
                setDisableButton("")
                refreshUserSession()
            })
        })
        .catch( error => {
            message.warning("Required field validation failed.")
            return
        })
    }

    //--------
    // On next
    //--------
    const onSave = (e) => {
        if(storageId == 0) {
            message.warn("Please select a storage before proceed.")
            return
        }

        if(form.getFieldValue("do") == undefined &&
            form.getFieldValue("orp") == undefined &&
            form.getFieldValue("pH") == undefined &&
            form.getFieldValue("temperature") == undefined &&
            form.getFieldValue("salinity") == undefined &&
            form.getFieldValue("ammonia") == undefined &&
            form.getFieldValue("nitrite") == undefined &&
            form.getFieldValue("nitrate") == undefined &&
            form.getFieldValue("tds") == undefined) {
            message.warn("Please enter at least one reading before proceed.")
            return
        }
        
        confirm({
            icon: <QuestionCircleOutlined />,
            content: <Space><p>Save environment data confirmed?</p></Space>,
            onOk() { createEnvironmentRecord() },
            onCancel() {},
            centered: true
        })
    }

    //--------
    // On back
    //--------
    const onBack = () => {
        navigate({ 
            pathname: MENUPATH_ENVIRONMENT
        })
    }

    //------------------
    // Custom validation
    //------------------
    const validateEnvReadingDateTime = (() => {
        if(envReadingDateTime != null) {
            return Promise.resolve()
        }
        return Promise.reject(new Error("Observation date time is required."))
    })

    //---------------------
    // On date time change
    //---------------------
    const onDateTimeChange = (datetime) => {
        setEnvReadingDateTime(datetime)
    }

    //---------------------
    // On componentDidMount
    //---------------------
    useEffect(() => {
        message.info("Storage must have water quality sensor mapped. Consult administrator if unclear.")
    }, [])

    return(
        <Layout>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                <ACISHeader />
            </Header>

            <Content style={{minHeight: contentHeight}}>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                <Row><Col><Space><div /></Space></Col></Row>
                
                <PageHeader 
                    onBack={() => onBack()} 
                    title="Water Quality">
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Description">Create water quality record</Descriptions.Item>
                    </Descriptions>
                </PageHeader>

                <Form form={form} onFinish={onSave} {...formLayout_2Columns}>
                    <Form.Item name="storage" label="Storage">
                        <StorageSelect withBlank={true} site={getUserSiteId()} onChange={setStorageId}/>
                    </Form.Item>

                    <Form.Item name="envReadingDateTime" label="Env. Reading Date Time"
                        rules={[
                            { required: true, validator: validateEnvReadingDateTime },
                        ]}>
                        <DatePicker showTime onChange={onDateTimeChange} defaultValue={envReadingDateTime} format={DATETIMEFORMAT}/>
                    </Form.Item>

                    <Form.Item name="ammonia" label="Ammonia (mg/L)">
                        <InputNumber min={0} max={10000} step={1} style={{width: optionWidth}} precision={2} placeholder="Ammonia (mg/L)"/>
                    </Form.Item>
                    
                    <Form.Item name="do" label="Dissolved Oxygen (mg/L)">
                        <InputNumber min={0} max={10000} step={1} style={{width: optionWidth}} precision={2} placeholder="Dissolved Oxygen (mg/L)"/>
                    </Form.Item>

                    <Form.Item name="nitrate" label="Nitrate (mg/L)">
                        <InputNumber min={0} max={10000} step={1} style={{width: optionWidth}} precision={2} placeholder="Nitrate (mg/L)"/>
                    </Form.Item>

                    <Form.Item name="nitrite" label="Nitrite (mg/L)">
                        <InputNumber min={0} max={10000} step={1} style={{width: optionWidth}} precision={2} placeholder="Nitrite (mg/L)"/>
                    </Form.Item>

                    <Form.Item name="orp" label="Oxidation Reduction Potential (mV)">
                        <InputNumber min={0} max={10000} step={1} style={{width: optionWidth}} precision={2} placeholder="Oxidation Reduction Potential (mV)"/>
                    </Form.Item>

                    <Form.Item name="pH" label="pH">
                        <InputNumber min={0} max={10000} step={1} style={{width: optionWidth}} precision={1} placeholder="pH"/>
                    </Form.Item>

                    <Form.Item name="salinity" label="Salinity (ppt)">
                        <InputNumber min={0} max={10000} step={1} style={{width: optionWidth}} precision={0} placeholder="Salinity (ppt)"/>
                    </Form.Item>

                    <Form.Item name="temperature" label="Temperature (°C)">
                        <InputNumber min={0} max={10000} step={1} style={{width: optionWidth}} precision={1} placeholder="Temperature (°C)"/>
                    </Form.Item>

                    <Form.Item name="tds" label="Total Dissolved Solid (ppm)">
                        <InputNumber min={0} max={10000} step={1} style={{width: optionWidth}} precision={1} placeholder="Total Dissolved Solid (ppm)"/>
                    </Form.Item>

                    <Row><Col><Space><div /></Space></Col></Row>

                    <Row justify="center">
                        <Col span={1}></Col>
                        <Col span={11} style={{textAlign: "start"}}>
                            <Button type="primary" htmlType="button" disabled={disableButton} onClick={onBack} icon={<LeftOutlined />}>Back</Button>
                        </Col>
                        <Col span={11} style={{textAlign: "end"}}>
                            <Button type="primary" htmlType="button" disabled={disableButton} onClick={onSave} icon={<SaveOutlined />}>Save</Button>
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                </Form>
            </Content>

            <Footer>
                <ACISFooter breadCrumb={
                    <PageHeader onBack={() => onBack()} 
                    title="Water Quality:"
                    subTitle="Create water quality record"/>} />
            </Footer>
        </Layout>
    )
}

export default EnvironmentNew