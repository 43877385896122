import { filter } from "@antv/util"
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
import { FOOTER, PAGEMARGINS, PAGESIZE, reportTitle, TABLESTYLES } from "../Common/PdfMakeLayout"

pdfMake.vfs = pdfFonts.pdfMake.vfs

export const generateBatchGenealogyPdf = async (batchGenealogyBase64Image, fromDate, toDate, site, marineLife, species, batch) => {
    //---------------------------
    // Start generate pdf content
    //---------------------------
    let filter = ""
    
    if(fromDate != "") filter = `From Date: ${fromDate} `
    if(toDate != "") filter = `${filter}\tTo Date: ${toDate}`
    if(site != "") filter = `${filter}\tSite: ${site}`
    if(marineLife != "") filter = `${filter}\tMarine Life: ${marineLife}`
    if(species != "") filter = `${filter}\tSpecies: ${species}`
    if(batch != "") filter = `${filter}\tBatch ID: ${batch}`
    
    const title = await reportTitle("Batch Genealogy")

    const docDefinition = {
        pageSize: PAGESIZE,
        pageMargins: PAGEMARGINS,
        pageOrientation: 'landscape',
        footer: FOOTER,
        
        content: [{
            stack: [    
                title,
                
                {
                    layout: 'noBorders',
                    style: {
                        alignment: "right",
                    },
                    table: {
                        widths: "100%",
                        body: [
                            [ {text: filter, style: TABLESTYLES.tableHeaderRight } ]
                        ]
                    }
                },

                { 
                    image: batchGenealogyBase64Image,
                    height: 450,
                    width: 1250,
                    alignment: "center",
                    margin: [0, 0, 0, 0]
                },
            ]}
        ]
    }

    pdfMake.createPdf(docDefinition).open()
}